import { LayoutTop } from "../layout/top";
import { Autoplay, Navigation, Pagination } from "swiper";

import { Footer } from "../layout/footer";
import { ToTheTop } from "../components/to-the-top";
import { useParams } from "react-router";
import { useEffect } from "react";
import {
  GetProductInput,
  CanBuyInput,
  ListProductViewInput,
} from "../__generated__/globalTypes";
import {
  listProductView,
  listProductView_listProductView_productViews,
} from "../__generated__/listProductView";
import { getProduct } from "../__generated__/getProduct";

import { useGetProduct } from "../hooks/useGetProduct";
import { useListProductView } from "../hooks/useListProductView";
import { useCanBuy } from "../hooks/useCanBuy";
import { ProductThemeCurrForm } from "../routes/inst/mypage/enroll/parts/currParts/currUpForm";
import ReactQuill from "react-quill";
import { Swiper, SwiperSlide } from "swiper/react";

export const SubCommonDetailPlace = () => {
  const params = useParams();
  const onCompleted = (data: getProduct) => {};

  const onListProductViewCompleted = (data: listProductView) => {};

  const [getProduct, { data, loading }] = useGetProduct(onCompleted);

  const [
    listProductView,
    { data: listProductViewData, loading: listProductViewLoading },
  ] = useListProductView(onListProductViewCompleted);
  useEffect(() => {
    if (params.key) {
      const listProductViewInput: ListProductViewInput = {
        productId: +params.key,
      };
      listProductView({ variables: { input: listProductViewInput } });
      const getProductInput: GetProductInput = {
        productId: +params.key,
      };
      getProduct({ variables: { input: getProductInput } });
      const canBuyInput: CanBuyInput = {
        productId: +params.key,
      };
    }
  }, []);

  return (
    <>
      <LayoutTop title={"nothing"} />
      <main id="main">
        <ToTheTop posi={`bottom-5 sm:bottom-10 right-5 sm:bottom-10`} />
        {!loading && data?.getProduct.product && (
          <div className="wrapper">
            <div className="max-w-screen-yl mx-auto lg:pt-8 relative">
              <div className="max-w-[736px] mx-auto px-4 lg:px-0">
                <div className="Topper">
                  <Swiper
                    modules={[Navigation, Autoplay, Pagination]}
                    slidesPerView={1}
                    pagination={{
                      clickable: true,
                      el: ".swiper-pagination",
                      type: "bullets",
                    }}
                    autoplay={{ delay: 5000 }}
                    className="relative"
                  >
                    <div className="swiper-pagination"></div>
                    {listProductViewData?.listProductView.productViews
                      ?.filter((productView) => productView.view)
                      .map(
                        (
                          productView: listProductView_listProductView_productViews
                        ) => (
                          <SwiperSlide
                            key={`swiper-key-${productView.view?.url}`}
                            className="w-full relative overflow-hidden"
                          >
                            <img
                              id={`view-${productView.index}`}
                              src={productView.view?.url}
                              alt={`썸네일`}
                              draggable="false"
                              className={`w-full aspect-detailMain object-cover`}
                            />
                          </SwiperSlide>
                        )
                      )}
                  </Swiper>

                  <div className="py-6 lg:py-20 border-b border-grays">
                    <p className="text-center text-2xl gangwon ">
                      {data.getProduct.product.productInfo?.title}
                    </p>
                    <p className="pt-8 lg:pt-10 text-text">
                      {data.getProduct.product.productInfo?.summary}
                    </p>
                  </div>
                  <div className="pt-5 pb-10 lg:pb-20 grid grid-cols-1 sm:grid-cols-2 gap-x-10 gap-y-5 px-4 sm:px-10 ">
                    <p className="flex flex-wrap">
                      <span className="mr-4 text-text text-lg sm:text-sm">
                        진행일자
                      </span>
                      <span className="text-xl sm:text-sm flex-1">
                        {data.getProduct.product.productInfo?.playTime}
                      </span>
                    </p>
                    <p className="flex flex-wrap">
                      <span className="mr-4 text-text text-lg sm:text-sm">
                        진행장소
                      </span>
                      <span className="text-xl sm:text-sm flex-1">
                        {data.getProduct.product.productInfo?.place}
                      </span>
                    </p>
                    <p className="flex flex-wrap">
                      <span className="mr-8 sm:mr-8 text-text text-lg sm:text-sm">
                        이용료
                      </span>
                      <span className="text-xl sm:text-sm flex-1">
                        {data.getProduct.product.productInfo?.fee}
                      </span>
                    </p>
                  </div>
                </div>
                <div>
                  {data?.getProduct.product?.productPage?.page && (
                    <div id={`class_intro`}>
                      <ReactQuill
                        value={data?.getProduct.product?.productPage?.page}
                        readOnly={true}
                        theme={`bubble`}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </main>
      <Footer></Footer>
    </>
  );
};
