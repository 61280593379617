import { useEffect, useState } from "react";
import { pageIndicator } from "../apollo";
import { SubTop } from "../layout/subTop";
import { AsideAdminComponent } from "../layout/aside-admin";
import { Footer } from "../layout/footer";
import { createBrowserHistory } from "history";
import { SubAdminUpPlaceInfo } from "../sub-routes/sub-admin-up-place-info";
import { SubAdminUpPlacePoster } from "../sub-routes/sub-admin-up-place-poster";
import { SubAdminUpPlacePage } from "../sub-routes/sub-admin-up-place-page";
import { SubAdminUpPlacePreview } from "../sub-routes/sub-admin-up-place-preview";

export const AdminUpPlace = () => {
  const history = createBrowserHistory();

  const [tabs, setTabs] = useState(0);

  useEffect(() => {
    pageIndicator("place");
  }, []);

  return (
    <div className="bg-main bg-opacity-50 w-full h-full min-h-screen">
      <div className="w-[1440px] mx-auto h-full min-h-screen  bg-white">
        <SubTop title={"top"} children={"관리자모드"} />
        <main id="main">
          <div className="grid grid-cols-[300px_1fr]">
            <AsideAdminComponent />
            <div className="mypages ">
              <div className="lectureWrap">
                <div className="h-20">
                  <div className="h-full flex-wrap flex justify-between px-10 content-center">
                    <div className="subtitle h-full flex flex-wrap content-end gap-x-3">
                      <div
                        className={`inline-block  w-44 ${
                          tabs === 0 && "border-main border-b-2"
                        }`}
                      >
                        <button
                          className={`text-xl leading-normal font-bold hover:opacity-60 w-full py-1  ${
                            tabs === 0 ? "text-main" : "text-gray-400"
                          }`}
                          onClick={() =>
                            setTabs(() => {
                              return 0;
                            })
                          }
                        >
                          정보
                        </button>
                      </div>
                      <div
                        className={`inline-block  w-44 ${
                          tabs === 1 && "border-main border-b-2"
                        }`}
                      >
                        <button
                          className={`text-xl leading-normal font-bold hover:opacity-60 w-full py-1  ${
                            tabs === 1 ? "text-main" : "text-gray-400"
                          }`}
                          onClick={() =>
                            setTabs(() => {
                              return 1;
                            })
                          }
                        >
                          이미지
                        </button>
                      </div>
                      <div
                        className={`inline-block  w-44 ${
                          tabs === 2 && "border-main border-b-2"
                        }`}
                      >
                        <button
                          className={`text-xl leading-normal font-bold hover:opacity-60 w-full py-1  ${
                            tabs === 2 ? "text-main" : "text-gray-400"
                          }`}
                          onClick={() =>
                            setTabs(() => {
                              return 2;
                            })
                          }
                        >
                          페이지
                        </button>
                      </div>
                      <div
                        className={`inline-block  w-44 ${
                          tabs === 3 && "border-main border-b-2"
                        }`}
                      >
                        {/* <button
                          className={`text-xl leading-normal font-bold hover:opacity-60 w-full py-1  ${
                            tabs === 3 ? "text-main" : "text-gray-400"
                          }`}
                          onClick={() =>
                            setTabs(() => {
                              return 3;
                            })
                          }
                        >
                          미리보기
                        </button> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className=" bg-gray-100 h-fix-content relative">
                  <div className={`w-full h-full px-10 py-10`}>
                    {/* 여기 */}
                    {tabs === 0 && <SubAdminUpPlaceInfo />}
                    {tabs === 1 && <SubAdminUpPlacePoster />}
                    {tabs === 2 && <SubAdminUpPlacePage />}
                    {/* {tabs === 3 && <SubAdminUpPlacePreview />} */}
                    <Footer></Footer>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
