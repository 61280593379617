import React from "react";
import { gql, useLazyQuery, useQuery } from "@apollo/client";
import {
  listReceiptMineOnline,
  listReceiptMineOnlineVariables,
} from "../__generated__/listReceiptMineOnline";

const LIST_RECEIPT_MINE_ONLINE_QUERY = gql`
  query listReceiptMineOnline($input: ListReceiptMineOnlineInput!) {
    listReceiptMineOnline(input: $input) {
      ok
      err
      receipts {
        id
        scheduledAt
        orderStatus
        createdAt
        pgOption {
          pay_method
          name
        }
        product {
          id
          productInfo {
            scheduledAt
            title
            price
          }
          inst {
            id
            atelier {
              name
            }
            user {
              name
            }
          }
          createdAt
          productStatus
          productType
          productKit {
            id
            view {
              url
            }
            desc
          }
          productViews {
            index
            is_rep
            id
            view {
              url
            }
          }
        }
      }
    }
  }
`;

export const useListReceiptMineOnline = (
  onCompleted?: (data: listReceiptMineOnline) => void
) => {
  return useLazyQuery<listReceiptMineOnline, listReceiptMineOnlineVariables>(
    LIST_RECEIPT_MINE_ONLINE_QUERY,
    {
      onCompleted,
      fetchPolicy: `network-only`,
    }
  );
};
