import { ApolloError, gql, useMutation } from "@apollo/client";
import React from "react";
import { UpMagazineInput, UpPlaceInput } from "../__generated__/globalTypes";
import { upPlace, upPlaceVariables } from "../__generated__/upPlace";

export interface IUpPlaceFormProps extends UpPlaceInput {}

const UP_PLACE_MUTATION = gql`
  mutation upPlace($input: UpPlaceInput!) {
    upPlace(input: $input) {
      ok
      err
    }
  }
`;

export const useUpPlace = (
  onCompleted?: (data: upPlace) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<upPlace, upPlaceVariables>(UP_PLACE_MUTATION, {
    onCompleted,
    onError,
  });
};
