import { ApolloError, gql, useMutation } from "@apollo/client";
import React from "react";
import { crBanner, crBannerVariables } from "../__generated__/crBanner";
import { CrBannerInput } from "../__generated__/globalTypes";

export interface ICrBannerFormProps extends CrBannerInput {}

const CR_BANNER_MUTATION = gql`
  mutation crBanner($input: CrBannerInput!) {
    crBanner(input: $input) {
      ok
      err
    }
  }
`;

export const useCrBanner = (
  onCompleted?: (data: crBanner) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<crBanner, crBannerVariables>(CR_BANNER_MUTATION, {
    onCompleted,
    onError,
  });
};
