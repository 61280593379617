import React from "react";
import { ApolloError, gql, useMutation } from "@apollo/client";
import { crConsult, crConsultVariables } from "../__generated__/crConsult";
import { CrConsultInput } from "../__generated__/globalTypes";

const CR_CONSULT_MUTATION = gql`
  mutation crConsult($input: CrConsultInput!) {
    crConsult(input: $input) {
      ok
      err
    }
  }
`;

export const useCrConsult = (
  onCompleted?: (data: crConsult) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<crConsult, crConsultVariables>(CR_CONSULT_MUTATION, {
    onCompleted,
    onError,
  });
};

export interface ICrConsultFormProps extends CrConsultInput {}
