import { useEffect, useState } from "react";
import { pageIndicator } from "../apollo";
import { AsideAdminComponent } from "../layout/aside-admin";
import { Footer } from "../layout/footer";
import { Link, useLocation } from "react-router-dom";
import { createBrowserHistory } from "history";
import { useParams } from "react-router";
import {
  DelDoorInput,
  DoorType,
  GetDoorInput,
  ListDoorReplyInput,
} from "../__generated__/globalTypes";
import { TableError } from "../components/table-error";
import { AdminTop } from "../layout/adminTop";
import { useDelDoor } from "../hooks/useDelDoor";
import { delDoor } from "../__generated__/delDoor";
import { useGetDoor } from "../hooks/useGetDoor";
import { useListDoorReply } from "../hooks/useListDoorReply";
import moment from "moment";
import { ComponentCrDoorReply } from "../components/component-cr-door-reply";
import { useMe } from "../hooks/useMe";
import { ComponentUpDoorReply } from "../components/component-up-door-reply";
import { ComponentTabLink, ITabLink } from "../components/component-tab";

export const AdminDetailDoor = () => {
  const params = useParams();
  const [edit, setEdit] = useState<boolean>(false);
  const editHandler = () => {
    setEdit(!edit);
  };

  const { data: me } = useMe();

  const [getDoor, { data, loading, refetch }] = useGetDoor();
  const getDoorHandler = (doorId: number) => {
    const input: GetDoorInput = {
      doorId,
    };
    getDoor({ variables: { input } });
  };

  const tabList: ITabLink[] = [
    { text: "목록", to: "/admin/user" },
    {
      text: "1:1문의",
      to: "/admin/user/door",
      ...(data?.getDoor.ok &&
        data?.getDoor.door?.type !== DoorType.Refund && { selected: true }),
    },
    {
      text: "환불문의",
      to: "/admin/user/refund",
      ...(data?.getDoor.ok &&
        data?.getDoor.door?.type === DoorType.Refund && { selected: true }),
    },
  ];

  useEffect(() => {
    pageIndicator("user");
  }, []);

  const [
    listDoorReply,
    {
      data: listDoorReplyData,
      loading: listDoorReplyLoading,
      refetch: listDoorReplyRefetch,
    },
  ] = useListDoorReply();
  const listDoorReplyHandler = (doorId: number) => {
    const input: ListDoorReplyInput = {
      doorId,
    };
    listDoorReply({ variables: { input } });
  };

  const onDelete = async (data: delDoor) => {
    const {
      delDoor: { ok, err },
    } = data;
    if (ok) {
      alert("문의 정보를 삭제하였습니다");
      refetch();
    }
    if (!ok) {
      alert(err);
    }
  };
  const [delDoor, { loading: delDoorLoading }] = useDelDoor(onDelete);
  const delDoorHandler = (doorIdToDel: number) => {
    if (!delDoorLoading) {
      const input: DelDoorInput = {
        doorIdToDel,
      };
      delDoor({ variables: { input } });
    }
  };

  useEffect(() => {
    if (params.doorId) {
      getDoorHandler(+params.doorId);
      listDoorReplyHandler(+params.doorId);
    }
  }, []);

  return (
    <div className="bg-main bg-opacity-50 w-full h-full min-h-screen">
      <div className="w-[1440px] mx-auto h-full min-h-screen  bg-white">
        <AdminTop title={"top"} children={"관리자모드"} />
        <main id="main">
          <div className="grid grid-cols-[300px_1fr]">
            <AsideAdminComponent />
            <div className="mypages">
              <div className="lectureWrap">
                <ComponentTabLink tabs={tabList} />
                <div className=" bg-gray-100 h-fix-content relative box-border">
                  {!loading && data?.getDoor.ok && (
                    <div className={`w-full h-full px-10 py-12`}>
                      <div className="bg-white p-4 rounded-2xl shadow-lg relative">
                        <div className="border-b ">
                          <div className="qinfo">
                            <p className="p-1 text-left text-text border-b border-[#e2e2e2 text-sm">
                              <span>작성일:</span>{" "}
                              {moment(data?.getDoor.door?.createdAt).format(
                                "yyyy년 M월 D일 HH:mm"
                              )}
                            </p>
                            <p className="bg-[#e2e2e2] py-1 pl-3 border-b border-[#e2e2e2 font-bold">
                              {data?.getDoor.door?.title}
                            </p>
                            <p className="py-1 pl-3 border-b border-[#e2e2e2]">
                              <span className="mr-3">상태:</span>{" "}
                              <span className="font-bold">
                                {data?.getDoor.door?.status}
                              </span>
                            </p>
                            <p className="py-1 pl-3 border-b border-[#e2e2e2]">
                              <span className="mr-3">분류:</span>{" "}
                              <span className="font-bold">
                                {data?.getDoor.door?.type}
                              </span>
                            </p>
                            <p className="py-1 pl-3 border-b border-[#e2e2e2]">
                              <span className="mr-3">글쓴이:</span>
                              <span className="font-bold">
                                {data?.getDoor.door?.author?.name}
                              </span>
                            </p>
                          </div>
                          <div className="p-4">
                            <textarea
                              value={data?.getDoor.door?.note}
                              disabled={!edit}
                              id="desc"
                              className="text-sm w-full resize-none h-48 whitespace-pre-wrap"
                            ></textarea>

                            {data?.getDoor.door?.author?.id === me?.me.id && (
                              <p className={`text-right ${edit && "hidden"}`}>
                                <button
                                  type="button"
                                  onClick={editHandler}
                                  className={`text-sm border border-grays rounded-md py-1 px-3 hover:opacity-60 `}
                                >
                                  수정
                                </button>
                              </p>
                            )}
                          </div>
                          {params.doorId && (
                            <ComponentCrDoorReply
                              doorId={+params.doorId}
                              listRefetch={listDoorReplyRefetch}
                              getRefetch={refetch}
                            />
                          )}
                          {!listDoorReplyLoading &&
                            listDoorReplyData?.listDoorReply.doorReplies?.map(
                              (doorReply) => (
                                <ComponentUpDoorReply
                                  key={`door-reply-${doorReply.id}`}
                                  doorReply={doorReply}
                                  listRefetch={listDoorReplyRefetch}
                                />
                              )
                            )}
                        </div>
                        <div className="btnBox pt-4 text-center space-x-4">
                          <Link
                            to={`/admin/user/door`}
                            className={`inline-block rounded-md text-main w-[300px] py-1 hover:opacity-60 text-sm lg:text-base bg-white border border-main ${
                              edit && "hidden"
                            }`}
                          >
                            뒤로가기
                          </Link>
                          <button
                            type="button"
                            onClick={editHandler}
                            className={`rounded-md text-white w-[300px] py-1 hover:opacity-60 text-sm lg:text-base bg-main border border-main ${
                              !edit && "hidden"
                            }`}
                          >
                            수정완료
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                  {loading && (
                    <div className="w-full p-5 text-center box-border rounded">
                      <div className="bg-white w-full">
                        <TableError errorMessage={`정보를 불러오는 중입니다`} />
                      </div>
                    </div>
                  )}
                  <Footer></Footer>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
