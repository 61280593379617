import React from "react";
import { gql, useLazyQuery, useQuery } from "@apollo/client";
import {
  listBuyMineOffline,
  listBuyMineOfflineVariables,
} from "../__generated__/listBuyMineOffline";
import { LIST_RECEIPT_FRAGMENT } from "../fragment-receipt";

const LIST_BUY_MINE_OFFLINE_QUERY = gql`
  query listBuyMineOffline($input: ListBuyMineOfflineInput!) {
    listBuyMineOffline(input: $input) {
      ok
      err
      buys {
        ...ListReceiptParts
      }
    }
  }
  ${LIST_RECEIPT_FRAGMENT}
`;

export const useListBuyMineOffline = (
  onCompleted?: (data: listBuyMineOffline) => void
) => {
  return useLazyQuery<listBuyMineOffline, listBuyMineOfflineVariables>(
    LIST_BUY_MINE_OFFLINE_QUERY,
    {
      onCompleted,
      fetchPolicy: `network-only`,
    }
  );
};
