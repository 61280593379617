import { ApolloError, gql, useMutation } from "@apollo/client";
import React from "react";
import { UpDoorInput } from "../__generated__/globalTypes";
import { upDoor, upDoorVariables } from "../__generated__/upDoor";

export interface IUpDoorFormProps extends UpDoorInput {}

const UP_DOOR_MUTATION = gql`
  mutation upDoor($input: UpDoorInput!) {
    upDoor(input: $input) {
      ok
      err
    }
  }
`;

export const useUpDoor = (
  onCompleted?: (data: upDoor) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<upDoor, upDoorVariables>(UP_DOOR_MUTATION, {
    onCompleted,
    onError,
  });
};
