import { useState } from "react";
import { useForm } from "react-hook-form";
import { useMe } from "../../../../hooks/useMe";
import { useUpUser } from "../../../../hooks/useUpUser";
import { Modal } from "@mui/material";
import DaumPostcode from "react-daum-postcode";

import { upUser } from "../../../../__generated__/upUser";
import { sendChangePasswordEmail } from "../../../../__generated__/sendChangePasswordEmail";
import { useSendChangePasswordEmail } from "../../../../hooks/useSendChangePasswordEmail";
import {
  SendChangePasswordEmailInput,
  UpUserInput,
} from "../../../../__generated__/globalTypes";
import { Button } from "../../../../components/button";
interface IUpUserFormProps extends UpUserInput {}

export const Edit_Dataform_ad = () => {
  const [tabs, setTabs] = useState(0);
  const [addressAPI, setAddressAPI] = useState(false);
  const { data, refetch } = useMe();
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors, isValid },
  } = useForm<IUpUserFormProps>({
    mode: "onChange",
  });
  const onCompleted = async (data: upUser) => {
    const {
      upUser: { ok },
    } = data;
    if (ok) {
      alert("개인정보가 수정되었습니다.");
      await refetch();
    }
  };
  const [upUser, { data: upUserResult, loading }] = useUpUser(onCompleted);
  const onSubmit = () => {
    if (!loading) {
      const { password, phone, address, subAddress } = getValues();
      const upUserInput: UpUserInput = {
        ...(password && { password }),
        phone,
        address,
        subAddress,
      };
      upUser({
        variables: {
          input: upUserInput,
        },
      });
    }
  };
    // daum API
  const handlePostCode = (data: any) => {
    let fullAddress = data.address;
    let extraAddress = "";

    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddress += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddress +=
          extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
    }
    setValue(`address`, fullAddress);
    setAddressAPI(false);
  };

  const onInvalid = () => {};
  const onSendCompleted = (data: sendChangePasswordEmail) => {
    const {
      sendChangePasswordEmail: { ok, err },
    } = data;
    if (ok) {
      alert(`메일을 보냈습니다`);
    } else {
      alert(err);
    }
  };
  const [
    sendChangePasswordEmail,
    {
      data: sendChangePasswordEmailData,
      loading: sendChangePasswordEmailLoading,
    },
  ] = useSendChangePasswordEmail(onSendCompleted);

  const sendChangePasswordEmailHandler = () => {
    if (!sendChangePasswordEmailLoading && data?.me.email) {
      const sendChangePasswordEmailInput: SendChangePasswordEmailInput = {
        email: data.me.email,
      };
      sendChangePasswordEmail({
        variables: { input: sendChangePasswordEmailInput },
      });
    }
  };

  return (
    <div className="w-full h-full ">
      <div className="bg-white p-5 rounded-2xl ">
        <form onSubmit={handleSubmit(onSubmit, onInvalid)}>
        <div className=" max-w-xl space-y-6">
          <p className="font-bold pb-5 text-xl">개인정보 수정</p>
          <div className="space-y-2">
            <p className="font-bold text-main">ID</p>
            <input
              type="text"
              defaultValue={data?.me.email}
              disabled
              className="border border-grays rounded-xl py-1 px-2 w-full"
            />
          </div>
          <div className="space-y-2">
            <p className="font-bold text-main">이름</p>
            <input
              disabled
              defaultValue={data?.me.name}
              type="text"
              className="border border-grays rounded-xl py-1 px-2 w-full"
            />
          </div>

          <div className="space-y-2">
            <p className="font-bold text-main">주소</p>
            <div className="grid grid-cols-[2.5fr_1fr] gap-x-3">
              <input
                              {...register(`address`)}

                type="text"
                defaultValue={data?.me.address}
                className="border border-grays rounded-xl py-1 px-2 w-full"
                disabled
              />
              <button 
              type="button"
                              onClick={() => setAddressAPI(true)}

              className="hover:opacity-80 text-sm block w-full h-full border border-grays rounded-xl">
                주소찾기
              </button>
            </div>
            <input
                            {...register("subAddress", { required: true })}

              type="text"
              defaultValue={data?.me.subAddress}
              className="border border-grays rounded-xl py-1 px-2 w-full"
            />
            <Modal
                            open={addressAPI}
                            onClose={() => {
                              setAddressAPI(false);
                            }}
                          >
                            <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 p-3 bg-white rounded-md shadow-lg">
                              <DaumPostcode onComplete={handlePostCode} />
                            </div>
                          </Modal>
          </div>
          <div className="space-y-2">
            <p className="font-bold text-main">전화</p>
            <input
              type="text"
              {...register("phone", {
                required: "전화번호는 필수 사항입니다.",
              })}
              defaultValue={data?.me.phone}
              className="border border-grays rounded-xl py-1 px-2 w-full"
              placeholder="-없이 11자리로 작성해주세요"
              maxLength={11}
            />
          </div>

          <div className="w-full sm:w-auto left-0 bottom-16 fixed sm:static text-center space-x-5 sm:pt-8 py-4 bg-white sm:bg-transparent">
                          <button
                            type="button"
                            onClick={() => {
                              window.location.reload();
                            }}
                            className="w-2/5 sm:w-40 py-1 border border-grays rounded-md hover:opacity-60"
                          >
                            취소
                          </button>
                          <Button
                            canClick={isValid}
                            actionText={`수정하기`}
                            className={`bg-main w-2/5 sm:w-40 py-1 text-white rounded-md`}
                            loading={loading}
                            color={`bg-main`}
                          />
                        </div>
        </div>
          </form>
        
      </div>
    </div>
  );
};
