import React from "react";
import { gql, useLazyQuery } from "@apollo/client";
import {
  listPromotionProduct,
  listPromotionProductVariables,
} from "../__generated__/listPromotionProduct";

const LIST_PROMOTION_PROMOTION_QUERY = gql`
  query listPromotionProduct($input: ListPromotionProductInput!) {
    listPromotionProduct(input: $input) {
      ok
      err
      totalPages
      totalResults
      row
      promotionProducts {
        id
        index
        discountRate
        product {
          id
          productInfo {
            id
            title
            price
            discountRate
          }
          inst {
            user {
              name
            }
          }
        }
      }
    }
  }
`;

export const useListPromotionProduct = (
  onCompleted?: (data: listPromotionProduct) => void
) => {
  return useLazyQuery<listPromotionProduct, listPromotionProductVariables>(
    LIST_PROMOTION_PROMOTION_QUERY,
    {
      onCompleted,
      fetchPolicy: `network-only`,
    }
  );
};
