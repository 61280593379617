import React from "react";
import gql from "graphql-tag";
import { ApolloError, useLazyQuery } from "@apollo/client";
import {
  listProductThemeCurr,
  listProductThemeCurrVariables,
} from "../__generated__/listProductThemeCurr";

const LIST_PRODUCT_THEME_CURR_QUERY = gql`
  query listProductThemeCurr($input: ListProductThemeCurrInput!) {
    listProductThemeCurr(input: $input) {
      ok
      err
      productThemeCurrs {
        id
        title
        playTime
        index
        updatedAt
        video {
          filename
          uuid
          url
        }
      }
    }
  }
`;

export const useListProductThemeCurr = (
  onCompleted?: (data: listProductThemeCurr) => void,
  onError?: (error: ApolloError) => void
) => {
  return useLazyQuery<listProductThemeCurr, listProductThemeCurrVariables>(
    LIST_PRODUCT_THEME_CURR_QUERY,
    {
      onCompleted,
      onError,
      fetchPolicy: "network-only",
    }
  );
};
