import React from "react";
import { gql, useLazyQuery, useQuery } from "@apollo/client";
import {
  listVerifiedCurator,
  listVerifiedCuratorVariables,
} from "../__generated__/listVerifiedCurator";

const LIST_VERIFIED_CURATOR_QUERY = gql`
  query listVerifiedCurator($input: ListVerifiedCuratorInput!) {
    listVerifiedCurator(input: $input) {
      ok
      err
      totalResults
      totalPages
      row
      curators {
        id
        name
        email
        phone
        address
        verified
        charged {
          id
        }
      }
    }
  }
`;

export const useListVerifiedCurator = (
  onCompleted?: (data: listVerifiedCurator) => void
) => {
  return useLazyQuery<listVerifiedCurator, listVerifiedCuratorVariables>(
    LIST_VERIFIED_CURATOR_QUERY,
    {
      onCompleted,
      fetchPolicy: "network-only",
    }
  );
};
