import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useListProductThemeCurr } from "../hooks/uselistProductThemeCurr";
import { ListProductThemeCurrInput } from "../__generated__/globalTypes";

interface IUserModalVideoProps {
  title: string;
  productId?: number;
  closeHandler: () => void;
}

export const SubUserModalVideo: React.FC<IUserModalVideoProps> = ({
  title,
  productId,
  closeHandler,
}: IUserModalVideoProps) => {
  const [index, setIndex] = useState<number>(0);

  const [listProductThemeCurr, { data, loading }] = useListProductThemeCurr();

  useEffect(() => {
    setIndex(0);
    if (productId) {
      const listProductThemeCurrInput: ListProductThemeCurrInput = {
        productId,
      };
      listProductThemeCurr({ variables: { input: listProductThemeCurrInput } });
    }
  }, []);

  const prevVideoHandler = () => {
    setIndex((index) => index - 1);
    (document.getElementById('lectureTitle') as HTMLSelectElement).value = (index)+""
  };

  const nextVideoHandler = () => {
    setIndex((index) => index + 1);
    (document.getElementById('lectureTitle') as HTMLSelectElement).value = (index+2)+""
  };

  return (
    <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 p-4 max-w-4xl w-full bg-grays bg-opacity-80">
      {!loading &&
      data?.listProductThemeCurr.productThemeCurrs &&
      data?.listProductThemeCurr.productThemeCurrs.length > 0 ? (
        <>
          <div className="w-full ">
            <p className="text-sm sm:text-base align-middle inline-block bg-white rounded-md py-1 px-2 w-11/12 keep-all truncate">
              {title}
            </p>
            <div className="align-middle inline-flex flex-wrap content-center justify-center w-1/12 px-1">
              <button onClick={closeHandler}>
                <i className="fas fa-times text-2xl sm:text-3xl "></i>
              </button>
            </div>
          </div>
          <div className="pt-5">
            <div className="w-full aspect-video relative">
              <video
                src={`${data.listProductThemeCurr.productThemeCurrs[index].video?.url}`}
                title={`${data.listProductThemeCurr.productThemeCurrs[index]?.title}`}
                controls
                className="absolute left-0 top-0 w-full h-full "
              ></video>
            </div>
          </div>
          <div className="pt-5 flex gap-x-4">
            <button
              className={`w-1/6 py-1 bg-white border border-grays rounded-md text-sm sm:text-base keep-all ${
                index < 1 && `pointer-events-none bg-opacity-60`
              }`}
              onClick={prevVideoHandler}
            >
              이전 강의
            </button>
            <select 
            id="lectureTitle"
            onChange={(e)=>{
              setIndex(Number(e.target.value) - 1 )
            }}
            className={`relative flex-1 px-1 bg-white border border-grays rounded-md flex flex-wrap content-center justify-center text-sm sm:text-base truncate`}>
            {data?.listProductThemeCurr.productThemeCurrs.map((curr)=>(
              <option key={curr.index+ "강" +curr.title} value={Number(curr.index)}>
                {`${
                curr.index
              }강. ${
                curr.title
              }`}
                </option>
            ))}
              

            </select>
            <button
              className={`w-1/6 py-1 bg-white border border-grays rounded-md text-sm sm:text-base keep-all ${
                data?.listProductThemeCurr.productThemeCurrs &&
                index + 1 ===
                  data?.listProductThemeCurr.productThemeCurrs.length
                  ? `pointer-events-none bg-opacity-50 `
                  : `cursor-pointer`
              }`}
              onClick={nextVideoHandler}
            >
              {data?.listProductThemeCurr.productThemeCurrs &&
              index + 1 === data?.listProductThemeCurr.productThemeCurrs.length
                ? "다음 강의 없음"
                : "다음 강의"}
            </button>
          </div>
        </>
      ) : (
        <div
          className={`text-center text-white text-bold text-xl whitespace-pre-wrap`}
        >
          강의 정보가 없습니다
        </div>
      )}
    </div>
  );
};
