import { ApolloError, useMutation } from "@apollo/client";
import gql from "graphql-tag";
import React from "react";
import { upUser, upUserVariables } from "../__generated__/upUser";

const UP_USER_MUTATION = gql`
  mutation upUser($input: UpUserInput!) {
    upUser(input: $input) {
      ok
      err
    }
  }
`;

export const useUpUser = (
  onCompleted?: (data: upUser) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<upUser, upUserVariables>(UP_USER_MUTATION, {
    onCompleted,
    onError,
  });
};
