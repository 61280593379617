import { useEffect, useState } from "react";
import { Footer } from "../layout/footer";
import searchImg from "../assets/icon/search.png";
import usePagination from "@mui/material/usePagination";
import { styled } from "@mui/material/styles";
import { comma, pageIndicator } from "../apollo";
import { AsideAdminComponent } from "../layout/aside-admin";
import { AdminTop } from "../layout/adminTop";
import { useListReceipt } from "../hooks/useListReceipt";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import { ComponentTabLink, ITabLink } from "../components/component-tab";
import { ListReceiptInput } from "../__generated__/globalTypes";
import { ISearchFormProps } from "./admin-list-magazine";
import { useForm } from "react-hook-form";
import { TableError } from "../components/table-error";
import { PaginationComponent } from "../components/pagination";

export const AdminListPayments = () => {
  const history = useNavigate();
  const location = useLocation();
  const [page, setPage] = useState<number>(1);
  const [search, setSearch] = useState<string>("");
  const word = "결제";

  const { register, getValues, handleSubmit } = useForm<ISearchFormProps>({
    mode: "all",
  });

  const tabList: ITabLink[] = [
    { text: "결제목록", to: "/admin/payments", selected: true },
  ];

  const [listReceipt, { data, loading }] = useListReceipt();
  console.log(data);
  const listReceiptHandler = (page: number, search: string) => {
    const input: ListReceiptInput = {
      page,
      search,
    };
    listReceipt({ variables: { input } });
  };

  const pageHandler = (page: number) => {
    setPage(page);
    history({
      pathname: "/admin/payments",
      search: `?page=${page}${search ? `&search=${search}` : ""}`,
    });
    listReceiptHandler(page, search);
  };

  const onSearch = async () => {
    const { search } = getValues();
    setSearch(search);
    history({
      pathname: "/admin/payments",
      search: `${search ? `?search=${search}` : ""}`,
    });
    listReceiptHandler(1, search);
  };

  useEffect(() => {
    pageIndicator("payments");
    setPage(
      location.search.split("page=").length > 1
        ? +location.search.split("page=")[1].split("&")[0]
        : 1
    );
    setSearch(
      location.search.split("search=").length > 1
        ? location.search.split("search=")[1].split("&")[0]
        : ""
    );
    listReceiptHandler(page, search);
  }, []);
  return (
    <div className="bg-main bg-opacity-50 w-full h-full min-h-screen">
      <div className="w-[1440px] mx-auto h-full min-h-screen  bg-white">
        <AdminTop title={"top"} children={"관리자모드"} />
        <main id="main">
          <div className="grid grid-cols-[300px_1fr]">
            <AsideAdminComponent />
            <div className="mypages">
              <div className="lectureWrap">
                <ComponentTabLink tabs={tabList} />
                <div className=" bg-gray-100 h-fix-content relative">
                  <div className={`w-full h-full px-10 py-12`}>
                    <div className="text-right pb-8">
                      <form onSubmit={handleSubmit(onSearch)}>
                        <p className="inline-block max-w-md w-full text-right relative">
                          <input
                            {...register(`search`)}
                            type="text"
                            className="w-full py-1 px-4 bg-white rounded-full"
                            placeholder="구매자 이름, 강의명으로 검색"
                          />
                          <button className="absolute right-3 top-1">
                            <img src={searchImg} alt="찾기" className="w-5" />
                          </button>
                        </p>
                      </form>
                    </div>
                    {/* 여기 */}
                    <div className="bg-white p-5 rounded-2xl shadow-lg">
                      <p className="text-main font-bold pb-3">결제 리스트</p>
                      <table className="w-full">
                        <thead>
                          <tr className="bg-main  border-gray-300">
                            <th className="text-white ">강의번호</th>
                            <th className="text-white ">강의제목</th>
                            <th className="text-white ">구매자</th>
                            <th className="text-white ">전화번호</th>
                            <th className="text-white ">금액</th>
                            <th className="text-white ">상태</th>
                          </tr>
                        </thead>
                        <tbody>
                          {!loading &&
                            data?.listReceipt?.receipts?.map(
                              (receipt, index) => (
                                <tr
                                  key={receipt.createdAt + receipt.receiver}
                                  className="border-b-2 border-gray-300 h-4"
                                >
                                  <td className="text-sm text-center py-1 px-1 h-full overflow-hidden">
                                    {receipt.id}
                                  </td>
                                  <td className="text-sm text-center h-full relative">
                                    {receipt.pgOption?.name}
                                  </td>
                                  <td className="text-sm text-center  relative">
                                    {receipt.receiver}
                                  </td>
                                  <td className="text-sm text-center py-1 px-1 h-full overflow-hidden ">
                                    {receipt.phone}
                                  </td>
                                  <td className="text-sm text-center py-1 px-1 h-full overflow-hidden">
                                    {receipt.paid_amount}
                                  </td>
                                  <td className="text-sm text-center py-1 px-1 h-full overflow-hidden ">
                                    {receipt.status}
                                  </td>
                                </tr>
                              )
                            )}
                        </tbody>
                      </table>
                      {loading && (
                        <TableError errorMessage={`정보를 불러오는 중입니다`} />
                      )}
                      {!loading &&
                        data?.listReceipt.receipts &&
                        data.listReceipt.receipts.length < 1 && (
                          <TableError
                            errorMessage={`해당하는 ${word} 정보가 없습니다`}
                          />
                        )}
                      {!loading &&
                        data?.listReceipt.receipts &&
                        data.listReceipt.receipts.length > 0 && (
                          <PaginationComponent
                            page={page}
                            totalPages={Number(data.listReceipt.totalPages)}
                            totalResults={Number(data.listReceipt.totalResults)}
                            pageHandler={pageHandler}
                          />
                        )}
                    </div>
                  </div>
                  <Footer></Footer>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
