import { ApolloError, gql, useMutation } from "@apollo/client";
import React from "react";
import { UpBannerInput } from "../__generated__/globalTypes";
import { upBanner, upBannerVariables } from "../__generated__/upBanner";

export interface IUpBannerFormProps extends UpBannerInput {}

const UP_BANNER_MUTATION = gql`
  mutation upBanner($input: UpBannerInput!) {
    upBanner(input: $input) {
      ok
      err
    }
  }
`;

export const useUpBanner = (
  onCompleted?: (data: upBanner) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<upBanner, upBannerVariables>(UP_BANNER_MUTATION, {
    onCompleted,
    onError,
    fetchPolicy: "network-only",
  });
};
