import React from "react";
import gql from "graphql-tag";
import { ApolloError, useMutation } from "@apollo/client";
import { UpProductViewInput } from "../__generated__/globalTypes";
import {
  upProductView,
  upProductViewVariables,
} from "../__generated__/upProductView";

export interface IUpProductViewFormProps extends UpProductViewInput {}

const UP_PRODUCT_VIEW_MUTATION = gql`
  mutation upProductView($input: UpProductViewInput!) {
    upProductView(input: $input) {
      ok
      err
    }
  }
`;

export const useUpProductView = (
  onCompleted?: (data: upProductView) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<upProductView, upProductViewVariables>(
    UP_PRODUCT_VIEW_MUTATION,
    {
      onCompleted,
      onError,
    }
  );
};
