import { ApolloError, gql, useMutation } from "@apollo/client";
import React from "react";
import { getBillURL, getBillURLVariables } from "../__generated__/getBillURL";

const GET_BILL_URL_QUERY = gql`
  mutation getBillURL($input: GetBillURLInput!) {
    getBillURL(input: $input) {
      ok
      err
      url
    }
  }
`;

export const useGetBillURL = (
  onCompleted?: (data: getBillURL) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<getBillURL, getBillURLVariables>(GET_BILL_URL_QUERY, {
    onCompleted,
    onError,
    fetchPolicy: "network-only",
  });
};
