import { useState } from "react";
import { useForm } from "react-hook-form";
import { useMe } from "../../../../../hooks/useMe";
import { UpInstInput } from "../../../../../__generated__/globalTypes";
import { useUpInst } from "../../../../../hooks/useUpInst";
import { upInst } from "../../../../../__generated__/upInst";
import { uploadBusiness } from "../../../../../functions/uploads";
import { Button } from "../../../../../components/button";
interface IUpInstFormProps extends UpInstInput {}

export const BusinessInfo = () => {
  const [crr, setCrr] = useState(0);
  const [uploading, setUploading] = useState<boolean>(false);
  const { data, refetch } = useMe();

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors, isValid },
  } = useForm<IUpInstFormProps>({
    mode: "onChange",
  });
  const onCompleted = async (data: upInst) => {
    const {
      upInst: { ok },
    } = data;
    if (ok) {
      alert("사업자정보가 수정되었습니다.");
      await refetch();
    }
  };
  const [upInst, { data: upInstResult, loading: upInstLoading }] =
    useUpInst(onCompleted);
  const onSubmit = () => {
    if (!upInstLoading) {
      const {
        businessFileUuid,
        businessItem,
        businessName,
        businessNumber,
        businessType,
      } = getValues();
      const input: UpInstInput = {
        instIdToUp: String(data?.me.inst?.id),
        businessFileUuid,
        businessItem,
        businessName,
        businessNumber,
        businessType,
      };
      upInst({
        variables: {
          input: input,
        },
      });
    }
  };

  const onInvalid = () => {};
  const [fileName, setFileName] = useState<string | undefined | null>();
  const uploadBusinessFileHandler = async (event: any) => {
    if (uploading) {
      alert("파일을 업로드 중입니다");
      return;
    }
    if (!upInstLoading) {
      setUploading(true);
      const result = await uploadBusiness(event);

      if (result.data.ok) {
        setValue("businessFileUuid", result.data.file.uuid);
        setFileName(result.data.file.filename);
      } else {
        alert(
          `잘못된 파일이거나 사용할 수 없는 파일입니다. 문제가 반복해서 발생할 시 업체에 문의해주세요`
        );
      }
      setUploading(false);
    }
  };
  return (
    <div className="w-full h-full ">
      <div className="bg-white p-4 rounded-2xl ">
        <form onSubmit={handleSubmit(onSubmit, onInvalid)}>
          <div className=" max-w-xl space-y-10">
            <div className="space-y-2">
              <p className="font-bold text-main text-sm lg:text-base">상호</p>
              <input
                type="text"
                {...register(`businessName`)}
                defaultValue={
                  data?.me.inst?.businessName !== null
                    ? data?.me.inst?.businessName
                    : " "
                }
                className="border border-grays rounded-xl py-1 px-2 w-full text-sm lg:text-base"
              />
            </div>
            <div className="space-y-2">
              <p className="font-bold text-main text-sm lg:text-base">
                사업자번호
              </p>
              <input
                type="number"
                {...register(`businessNumber`)}
                defaultValue={
                  data?.me.inst?.businessNumber !== null
                    ? data?.me.inst?.businessNumber
                    : " "
                }
                className="border border-grays rounded-xl py-1 px-2 w-full text-sm lg:text-base"
                placeholder="- 없이 붙여서 써주세요"
              />
            </div>
            <div className="space-y-2">
              <div className="grid grid-cols-2 gap-x-5">
                <div className="space-y-2">
                  <p className="font-bold text-main text-sm lg:text-base">
                    업태
                  </p>
                  <input
                    type="text"
                    {...register(`businessType`)}
                    defaultValue={
                      data?.me.inst?.businessType !== null
                        ? data?.me.inst?.businessType
                        : ""
                    }
                    className="border border-grays rounded-xl py-1 px-2 w-full text-sm lg:text-base"
                  />
                </div>
                <div className="space-y-2">
                  <p className="font-bold text-main text-sm lg:text-base">
                    종목
                  </p>
                  <input
                    type="text"
                    {...register(`businessItem`)}
                    defaultValue={
                      data?.me.inst?.businessItem !== null
                        ? data?.me.inst?.businessItem
                        : ""
                    }
                    className="border border-grays rounded-xl py-1 px-2 w-full text-sm lg:text-base"
                  />
                </div>
              </div>
            </div>
            <div className="space-y-2">
              <p className="font-bold text-main text-sm lg:text-base">
                사업자등록증
              </p>
              <div className="grid grid-cols-[1fr_2.5fr] gap-x-3">
                <label
                  htmlFor="business_id"
                  className="hover:opacity-80 text-xs lg:text-sm flex flex-wrap content-center justify-center w-full h-full border border-grays rounded-xl cursor-pointer"
                >
                  파일선택
                </label>
                <input
                  type="file"
                  name="join_Bfile"
                  className="hidden"
                  id="business_id"
                  onChange={uploadBusinessFileHandler}
                />
                <input
                  type="text"
                  defaultValue={
                    data?.me.inst?.businessFile?.url !== null
                      ? data?.me.inst?.businessFile?.url
                      : ""
                  }
                  value={fileName !== undefined ? `${fileName}` : ``}
                  className="border border-grays rounded-xl py-1 px-2 w-full text-sm lg:text-base"
                  disabled
                />
              </div>
            </div>
            <div className="py-4 left-0 bottom-16 w-full fixed sm:static text-center space-x-5 sm:pt-8 bg-white sm:bg-transparent">
              <button
                onClick={() => {
                  window.location.reload();
                }}
                className="w-2/5 sm:w-40 py-1 border border-grays rounded-md hover:opacity-60 text-sm lg:text-base"
              >
                취소
              </button>
              <Button
                canClick={isValid}
                actionText={`수정하기`}
                className={`bg-main w-2/5 sm:w-40 py-1 text-white rounded-md hover:opacity-60 text-sm lg:text-base`}
                loading={upInstLoading}
                color={`bg-main`}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
