import React from "react";
import { HOME } from "../routes/home/home";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { DOMRoutes } from "../constants/props";
import { useMe } from "../hooks/useMe";
import { UserRole } from "../__generated__/globalTypes";
import { Lecture_st } from "../routes/inst/mypage/lecture/lecture";
import { Enroll_st } from "../routes/inst/mypage/enroll/enroll";
import { Consult_st } from "../routes/inst/mypage/consult/consult";
import { Sales_st } from "../routes/inst/mypage/sales/inst-sales";
import { Change_st } from "../routes/inst/mypage/change/change";
import { Inst_ad } from "../routes/admin/inst/inst";
import { Lecture } from "../routes/users/mypage/lecture/lecture";
import { Buy } from "../routes/users/mypage/buyList/buy";
import { Question } from "../routes/users/mypage/question/question";
import { Change } from "../routes/users/mypage/change/change";
import { AdminListConsult } from "../routes/curator/dashboard/dashboard";
import { Inst_Q } from "../routes/curator/inst/inst";
import { Change_Q } from "../routes/curator/change/change";
import { Curator_ad } from "../routes/admin/curator/curator";
import { Lecture_ad } from "../routes/admin/lecture/lecture";
import { Change_ad } from "../routes/admin/change/change";
import { AdminListPayments } from "../routes/admin-list-payment";
import { SubCommonListOfflineClass } from "../sub-routes/sub-common-list-offline-class";
import { CommonRoutes } from "./logged-out-router";
import { Payment } from "../routes/users/payment/parts/payment";
import { GeneralPaymentComplete } from "../routes/general-payment-complete";
import { AdminCrMagazine } from "../routes/admin-cr-magazine";
import { AdminListMagazine } from "../routes/admin-list-magazine";
import { AdminUpMagazine } from "../routes/admin-up-magazine";
import { AdminListPlace } from "../routes/admin-list-place";
import { AdminCrPlace } from "../routes/admin-cr-place";
import { AdminUpPlace } from "../routes/admin-up-place";
import { AdminListBanner } from "../routes/admin-list-banner";
import { AdminCrBanner } from "../routes/admin-cr-banner";
import { AdminUpBanner } from "../routes/admin-up-banner";
import { JoinDataformCurator } from "../routes/join/parts/dataform_cu";
import { CommonJoinAgreeCurator } from "../routes/common-join-agree-curator";
import { AdminListPromotion } from "../routes/admin-list-promotion";
import { AdminCrPromotion } from "../routes/admin-cr-promotion";
import { AdminUpPromotion } from "../routes/admin-up-promotion";
import { AdminListGeneral } from "../routes/admin-list-general";
import { AdminListDoor } from "../routes/admin-list-door";
import { AdminDetailDoor } from "../routes/admin-detail-door";
import { AdminDetailConsult } from "../routes/admin-detail-consult";
import { AdminListDoorRefund } from "../routes/admin-list-refund";
import { AdminListBill } from "../routes/admin-list-bill";

const UserRoutes: DOMRoutes[] = [
  {
    path: "/my",
    key: "/my",
    component: <HOME />,
  },
  {
    path: "/mypage",
    key: "/mypage",
    component: <Lecture />,
  },
  {
    path: "/mypage/buylist",
    key: "/mypage/buylist",
    component: <Buy />,
  },
  {
    path: "/mypage/question",
    key: "/mypage/question",
    component: <Question />,
  },
  {
    path: "/mypage/change",
    key: "/mypage/change",
    component: <Change />,
  },
  {
    path: "/payment/:productId",
    key: "/payment/:productId",
    component: <Payment />,
  },
  {
    path: "/payment/completed/:receiptId",
    key: "/payment/completed/:receiptId",
    component: <GeneralPaymentComplete />,
  },
];

const InstRoutes: DOMRoutes[] = [
  {
    path: "/instPage",
    key: "/instPage",
    component: <Lecture_st />,
  },
  {
    path: "/instPage/enroll",
    key: "/instPage/enroll",
    component: <Enroll_st />,
  },
  {
    path: "/instPage/consult",
    key: "/instPage/consult",
    component: <Consult_st />,
  },
  {
    path: "/instPage/change",
    key: "/instPage/change",
    component: <Change_st />,
  },
  {
    path: "/instPage/sales",
    key: "/instPage/sales",
    component: <Sales_st />,
  },
  {
    path: "/instPage/enroll/:key",
    key: "/instPage/enroll/:key",
    component: <Enroll_st />,
  },
];

const AdminRoutes: DOMRoutes[] = [
  {
    path: "/join/curator/:code",
    key: "/join/curator/:code",
    component: <JoinDataformCurator />,
  },
  {
    path: "/join/agree/curator/:code",
    key: "/join/agree/curator/:code",
    component: <CommonJoinAgreeCurator />,
  },
  {
    path: "/curator",
    key: "/curator",
    component: <AdminListConsult />,
  },
  {
    path: "/admin/consult/update/:consultId",
    key: "/admin/consult/update/:consultId",
    component: <AdminDetailConsult />,
  },
  {
    path: "/admin/consult",
    key: "/admin/consult",
    component: <AdminListConsult />,
  },
  {
    path: "/curator/insts",
    key: "/curator/insts",
    component: <Inst_Q />,
  },
  {
    path: "/curator/profiles",
    key: "/curator/profiles",
    component: <Change_Q />,
  },
  {
    path: "/admin/promotion/update/:promotionId",
    key: "/admin/promotion/update/:promotionId",
    component: <AdminUpPromotion />,
  },
  {
    path: "/admin/promotion/create",
    key: "/admin/promotion/create",
    component: <AdminCrPromotion />,
  },
  {
    path: "/admin/promotion",
    key: "/admin/promotion",
    component: <AdminListPromotion />,
  },
  {
    path: "/admin/banner/update/:bannerId",
    key: "/admin/banner/update/:bannerId",
    component: <AdminUpBanner />,
  },
  {
    path: "/admin/banner/create",
    key: "/admin/banner/create",
    component: <AdminCrBanner />,
  },
  {
    path: "/admin/banner",
    key: "/admin/banner",
    component: <AdminListBanner />,
  },
  {
    path: "/admin/promotion",
    key: "/admin/promotion",
    component: <Curator_ad />,
  },
  {
    path: "/admin/curators",
    key: "/admin/curators",
    component: <Curator_ad />,
  },
  {
    path: "/admin/insts",
    key: "/admin/insts",
    component: <Inst_ad />,
  },
  {
    path: "/admin/lectures",
    key: "/admin/lectures",
    component: <Lecture_ad />,
  },
  {
    path: "/admin/magazine/update/:productId",
    key: "/admin/magazine/update/:productId",
    component: <AdminUpMagazine />,
  },
  {
    path: "/admin/magazine/create",
    key: "/admin/magazine/create",
    component: <AdminCrMagazine />,
  },
  {
    path: "/admin/magazine",
    key: "/admin/magazine",
    component: <AdminListMagazine />,
  },
  {
    path: "/admin/place/update/:productId",
    key: "/admin/place/update/:productId",
    component: <AdminUpPlace />,
  },
  {
    path: "/admin/place/create",
    key: "/admin/place/create",
    component: <AdminCrPlace />,
  },
  {
    path: "/admin/place",
    key: "/admin/place",
    component: <AdminListPlace />,
  },
  {
    path: "/admin/payments",
    key: "/admin/payments",
    component: <AdminListPayments />,
  },
  {
    path: "/admin/profile",
    key: "/admin/profile",
    component: <Change_ad />,
  },
  {
    path: "/admin/user/door/:doorId",
    key: "/admin/user/door/:doorId",
    component: <AdminDetailDoor />,
  },
  {
    path: "/admin/user/refund",
    key: "/admin/user/refund",
    component: <AdminListDoorRefund />,
  },
  {
    path: "/admin/user/door",
    key: "/admin/user/door",
    component: <AdminListDoor />,
  },
  {
    path: "/admin/user",
    key: "/admin/user",
    component: <AdminListGeneral />,
  },
  {
    path: "/admin/bill",
    key: "/admin/bill",
    component: <AdminListBill />,
  },

  {
    path: "/classGoList",
    key: "/classGoList",
    component: <SubCommonListOfflineClass />,
  },
];

export const LoggedInRouter = () => {
  const { data, loading } = useMe();
  return (
    <Router>
      {!loading && (
        <Routes>
          {(data?.me.role === UserRole.General ||
            data?.me.role === UserRole.Instructor) &&
            CommonRoutes.map((route) => (
              <Route
                key={route.key}
                path={route.path}
                element={route.component}
              />
            ))}
          {UserRoutes.map((route) => (
            <Route
              key={route.key}
              path={route.path}
              element={route.component}
            />
          ))}
          {data?.me.role === UserRole.Instructor &&
            InstRoutes.map((route) => (
              <Route
                key={route.key}
                path={route.path}
                element={route.component}
              />
            ))}
          {(data?.me.role === UserRole.Admin ||
            data?.me.role === UserRole.Curator) &&
            AdminRoutes.map((route) => (
              <Route
                key={route.key}
                path={route.path}
                element={route.component}
              />
            ))}
          {data?.me.role === UserRole.General ||
            (data?.me.role === UserRole.Instructor && (
              <Route path={`/*`} element={<HOME />} />
            ))}
          {data?.me.role === UserRole.Admin && (
            <Route path={`/*`} element={<AdminListBanner />} />
          )}
          {data?.me.role === UserRole.Curator && (
            <Route path={`/*`} element={<AdminListConsult />} />
          )}
        </Routes>
      )}
    </Router>
  );
};
