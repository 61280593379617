import { ApolloError, gql, useMutation } from "@apollo/client";
import React from "react";
import { UpDoorInput, UpDoorReplyInput } from "../__generated__/globalTypes";
import {
  upDoorReply,
  upDoorReplyVariables,
} from "../__generated__/upDoorReply";

export interface IUpDoorReplyFormProps extends UpDoorReplyInput {}

const UP_DOOR_REPLY_MUTATION = gql`
  mutation upDoorReply($input: UpDoorReplyInput!) {
    upDoorReply(input: $input) {
      ok
      err
    }
  }
`;

export const useUpDoorReply = (
  onCompleted?: (data: upDoorReply) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<upDoorReply, upDoorReplyVariables>(
    UP_DOOR_REPLY_MUTATION,
    {
      onCompleted,
      onError,
    }
  );
};
