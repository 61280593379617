import React from "react";

export interface TabProps {
  index: number;
  tabText: string;
}

interface ITabProps {
  index: number;
  tabs: TabProps[];
  setTabs: (index: number) => void;
}

export const Tabs: React.FC<ITabProps> = ({ index, tabs, setTabs }) => {
  return (
    <div className="subtitle h-full flex flex-wrap content-end gap-x-3">
      {tabs.map((tab) => (
        <div
          key={`tab-${tab.index}`}
          className={tab.index === index ? "tab-selected" : "tab-unselected"}
        >
          <button
            type="button"
            className={
              tab.index === index ? "tab-text-selected" : "tab-text-unselected"
            }
            onClick={() => setTabs(tab.index)}
          >
            {`${tab.tabText}`}
          </button>
        </div>
      ))}
    </div>
  );
};
