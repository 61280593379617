export interface RequestPayAdditionalParams {
  digital?: boolean;
  vbank_due?: string;
  m_redirect_url?: string;
  app_scheme?: string;
  biz_num?: string;
}

export interface Display {
  card_quota?: number[];
}

export interface RequestPayParams extends RequestPayAdditionalParams {
  pg?: string;
  pay_method: string;
  escrow?: boolean;
  merchant_uid: string;
  name?: string;
  amount: number;
  custom_data?: any;
  buyer_name?: string;
  buyer_tel: string;
  buyer_email?: string;
  buyer_addr?: string;
  notice_url?: string | string[];
  digital?: boolean;
}

export interface RequestPayAdditionalResponse {
  apply_num?: string;
  vbank_num?: string;
  vbank_name?: string;
  bank_name?: string;
  vbank_holder?: string | null;
  vbank_date?: string;
}

export interface RequestPayResponse extends RequestPayAdditionalResponse {
  success: boolean;
  error_code: string;
  error_msg: string;
  imp_uid: string;
  merchant_uid: string;
  pay_method?: string;
  paid_amount?: number;
  card_name?: string;
  card_number?: string;
  status?: string;
  name?: string;
  pg_provider?: string;
  pg_tid?: string;
  pg_type?: string;
  buyer_name?: string;
  buyer_email?: string;
  buyer_tel?: string;
  buyer_addr?: string;
  buyer_postcode?: string;
  custom_data?: any;
  paid_at?: string;
  receipt_url?: string;
}

export interface ConstantPayParams {
  pg: string;
  escrow: boolean;
  // name: string;
  digital: boolean;
}

export const payParams: ConstantPayParams = {
  pg: "danal_tpay",
  escrow: false,
  // name: "lolo",
  digital: false,
};

export const IMP_ID = "imp53642799";

export type RequestPayResponseCallback = (response: RequestPayResponse) => void;

export interface Iamport {
  init: (accountID: string) => void;
  request_pay: (
    params: RequestPayParams,
    callback?: RequestPayResponseCallback
  ) => void;
}

declare global {
  interface Window {
    IMP?: Iamport;
  }
}
