import { useState, useEffect } from "react";
import { Footer } from "../../../../layout/footer";
import { Fade, Slide } from "@mui/material";
import { SalesList_st } from "./parts/inst-list-sales";
import { BillList_st } from "./parts/billList";
import { Billing_st } from "./parts/billing";
import { SubTop } from "../../../../layout/subTop";
import { Aside } from "../aside";
import { ToTheTop } from "../../../../components/to-the-top";
export const Sales_st = () => {
  const pages = 4;
  const [tabs, setTabs] = useState(0);
  // reload

  const alertUser = (e: any) => {
    e.preventDefault();
    e.returnValue = "";
  };
  return (
    <div className="bg-main bg-opacity-50 w-full h-full min-h-screen">
      <div className="max-w-screen-yl mx-auto w-full h-full min-h-screen  bg-white">
        <SubTop title={"nothing"} />
        <main id="main">
          <div className="lg:grid lg:grid-cols-[300px_1fr]">
            <Aside page={pages} />
            <ToTheTop posi={`bottom-32 right-4 lg:bottom-16`} white={true} />
            <div className="mypages ">
              <div className="lectureWrap pb-24 sm:pb-16">
                <div className="h-12 lg:h-20">
                  <div className="subtitle h-full flex flex-wrap content-end lg:px-10 px-4 sm:px-5 lg:gap-x-1">
                    <div
                      className={`inline-block  w-1/3 lg:w-44 ${
                        tabs === 0 && "border-main border-b-2"
                      }`}
                    >
                      <button
                        className={`text-xs sm:text-sm lg:text-base leading-normal font-bold hover:opacity-60 w-full py-1  ${
                          tabs === 0 ? "text-main" : "text-gray-400"
                        }`}
                        onClick={() =>
                          setTabs(() => {
                            return 0;
                          })
                        }
                      >
                        매출내역 조회
                      </button>
                    </div>
                    {/* <div
                      className={`inline-block  w-1/3 lg:w-44 ${
                        tabs === 1 && "border-main border-b-2"
                      }`}
                    >
                      <button
                        className={`text-xs sm:text-sm lg:text-base leading-normal font-bold hover:opacity-60 w-full py-1  ${
                          tabs === 1 ? "text-main" : "text-gray-400"
                        }`}
                        onClick={() =>
                          setTabs(() => {
                            return 1;
                          })
                        }
                      >
                        정산내역 조회
                      </button>
                    </div>
                    <div
                      className={`inline-block  w-1/3 lg:w-44 ${
                        tabs === 2 && "border-main border-b-2"
                      }`}
                    >
                      <button
                        className={`text-xs sm:text-sm lg:text-base leading-normal font-bold hover:opacity-60 w-full py-1  ${
                          tabs === 2 ? "text-main" : "text-gray-400"
                        }`}
                        onClick={() =>
                          setTabs(() => {
                            return 2;
                          })
                        }
                      >
                        정산 요청
                      </button>
                    </div> */}
                  </div>
                </div>
                <div className=" bg-gray-100 h-fix-content pb-16 lg:pb-0 relative overflow-hidden grid grid-rows-1">
                  <Fade in={tabs === 0}>
                    <div
                      className={`w-full h-full px-4 lg:px-10 py-8 lg:py-12  ${
                        tabs === 0 ? "static" : "absolute left-0 top-0"
                      })`}
                    >
                      <SalesList_st />
                    </div>
                  </Fade>
                  {/* <Fade in={tabs === 1}>
                    <div
                      className={`w-full h-full px-4 lg:px-10 py-8 lg:py-12 space-y-10 ${
                        tabs === 1 ? "static" : "absolute left-0 top-0"
                      })`}
                    >
                      <BillList_st />
                    </div>
                  </Fade>
                  <Fade in={tabs === 2}>
                    <div
                      className={`w-full h-full px-4 lg:px-10 py-8 lg:py-12 space-y-10 ${
                        tabs === 2 ? "static" : "absolute left-0 top-0"
                      })`}
                    >
                      <Billing_st />
                    </div>
                  </Fade> */}
                  <Footer></Footer>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
