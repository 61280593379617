import { useState } from "react";
import DaumPostcode from "react-daum-postcode";
import { Modal } from "@mui/material";
import { useMe } from "../../../../../hooks/useMe";
import { UpUserInput } from "../../../../../__generated__/globalTypes";
import { upUser } from "../../../../../__generated__/upUser";
import { useForm } from "react-hook-form";
import { useUpUser } from "../../../../../hooks/useUpUser";
import { Button } from "../../../../../components/button";

interface IUpUserFormProps extends UpUserInput {}

export const Edit_Dataform_st = () => {
  const [crr, setCrr] = useState(0);
  const [addressAPI, setAddressAPI] = useState(false);
  const { data, refetch } = useMe();

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors, isValid },
  } = useForm<IUpUserFormProps>({
    mode: "onChange",
  });
  const onCompleted = async (data: upUser) => {
    const {
      upUser: { ok },
    } = data;
    if (ok) {
      alert("개인정보가 수정되었습니다.");
      await refetch();
    }
  };
  const [upUser, { data: upUserResult, loading }] = useUpUser(onCompleted);
  const onSubmit = () => {
    if (!loading) {
      const { password, phone, address, subAddress } = getValues();
      const upUserInput: UpUserInput = {
        ...(password && { password }),
        phone,
        address,
        subAddress,
      };
      upUser({
        variables: {
          input: upUserInput,
        },
      });
    }
  };
  // daum API
  const handlePostCode = (data: any) => {
    let fullAddress = data.address;
    let extraAddress = "";

    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddress += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddress +=
          extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
    }
    setValue(`address`, fullAddress);
    setAddressAPI(false);
  };
  const onInvalid = () => {};
  return (
    <div className="w-full h-full ">
      <div className="bg-white p-4 rounded-2xl ">
        <form onSubmit={handleSubmit(onSubmit, onInvalid)}>
          <div className=" max-w-xl space-y-10">
            <div className="space-y-2">
              <p className="font-bold text-main text-sm lg:text-base">이메일</p>
              <input
                type="email"
                value={data?.me.email}
                disabled
                className=" rounded-xl py-1 px-2 w-full text-sm lg:text-base"
              />
            </div>
            <div className="space-y-2">
              <p className="font-bold text-main text-sm lg:text-base">이름</p>
              <input
                value={data?.me.name}
                disabled
                type="text"
                className="border border-grays rounded-xl py-1 px-2 w-full text-sm lg:text-base"
              />
            </div>
            <div className="space-y-2">
              <p className="font-bold text-main text-sm lg:text-base">
                전화번호
              </p>
              <input
                type="text"
                {...register("phone", {
                  required: "전화번호는 필수 사항입니다.",
                })}
                className="border border-grays rounded-xl py-1 px-2 w-full text-sm lg:text-base"
                placeholder="-없이 11자리로 작성해주세요"
                defaultValue={data?.me.phone}
                maxLength={11}
              />
            </div>
            <div className="space-y-2">
              <p className="font-bold text-main text-sm lg:text-base">주소</p>
              <div className="grid grid-cols-[2.5fr_1fr] gap-x-3">
                <input
                  {...register(`address`)}
                  type="text"
                  className=" rounded-xl py-1 px-2 w-full text-sm lg:text-base"
                  defaultValue={data?.me.address}
                  disabled
                />
                <button
                  onClick={() => setAddressAPI(true)}
                  type="button"
                  className="hover:opacity-80 block w-full h-full border border-grays rounded-xl text-xs lg:text-sm"
                >
                  주소찾기
                </button>
              </div>
              <input
                type="text"
                {...register("subAddress", { required: true })}
                className="border border-grays rounded-xl py-1 px-2 w-full text-sm lg:text-base"
                defaultValue={data?.me.subAddress}
              />
              <Modal
                open={addressAPI}
                onClose={() => {
                  setAddressAPI(false);
                }}
              >
                <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 p-3 bg-white rounded-md shadow-lg">
                  <DaumPostcode onComplete={handlePostCode} />
                </div>
              </Modal>
            </div>
            <div className="w-full sm:w-auto left-0 bottom-16 fixed sm:static text-center space-x-5 sm:pt-8 py-4 bg-white sm:bg-transparent">
              <button
                type="button"
                onClick={() => {
                  window.location.reload();
                }}
                className="w-2/5 sm:w-40 py-1 border border-grays rounded-md hover:opacity-60"
              >
                취소
              </button>
              <Button
                canClick={isValid}
                actionText={`수정하기`}
                className={`bg-main w-2/5 sm:w-40 py-1 text-white rounded-md`}
                loading={loading}
                color={`bg-main`}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
