import { ApolloError, gql, useLazyQuery } from "@apollo/client";
import React from "react";
import { DOOR_LIST_FRAGMENT } from "../fragment-door";
import { getDoor, getDoorVariables } from "../__generated__/getDoor";

const GET_DOOR_MUTATION = gql`
  query getDoor($input: GetDoorInput!) {
    getDoor(input: $input) {
      ok
      err
      door {
        ...DoorListParts
      }
    }
  }
  ${DOOR_LIST_FRAGMENT}
`;

export const useGetDoor = (
  onCompleted?: (data: getDoor) => void,
  onError?: (error: ApolloError) => void
) => {
  return useLazyQuery<getDoor, getDoorVariables>(GET_DOOR_MUTATION, {
    onCompleted,
    onError,
    fetchPolicy: "network-only",
  });
};
