import { useState } from "react";
import logo from "../../../assets/icon/localpick_logo.svg";
import { Link, BrowserRouter as Router, Route, Routes } from "react-router-dom";
import {
  userRule1,
  userRule2,
  userRule3,
  personalInfo,
} from "../../../layout/text";
import ruleCellCome from "../../../assets/images/useRule_come.png";
import ruleCellGo from "../../../assets/images/useRule_go.png";
export const JOIN_agree = () => {
  const [allchk, setAllchk] = useState<boolean>(false);
  const [usechk, setUsechk] = useState<boolean>(false);
  const [infochk, setInfochk] = useState<boolean>(false);
  const [prochk, setProchk] = useState<boolean>(false);
  const [confirm, setConfirm] = useState<boolean>(false);
  const allHandler = () => {
    if (allchk) {
      setUsechk(() => {
        return false;
      });
      setInfochk(() => {
        return false;
      });
      setProchk(() => {
        return false;
      });
    } else {
      setUsechk(() => {
        return true;
      });
      setInfochk(() => {
        return true;
      });
      setProchk(() => {
        return true;
      });
    }
    setAllchk(() => {
      return !allchk;
    });
  };
  const useHandler = () => {
    if (allchk) {
      setAllchk(!allchk);
    }
    setUsechk(() => {
      return !usechk;
    });
  };
  const infoHandler = () => {
    if (allchk) {
      setAllchk(!allchk);
    }
    setInfochk(() => {
      return !infochk;
    });
  };
  const proHandler = () => {
    setProchk(() => {
      return !prochk;
    });
  };
  return (
    <div className="bg-main bg-opacity-50 w-full h-full min-h-screen">
      <div className="max-w-screen-yl mx-auto w-full h-full min-h-screen bg-white flex flex-wrap justify-center pt-10 pb-28 sm:pb-0">
        <div className="sm:max-w-3xl w-full px-5">
          <div className="w-[200px] mx-auto">
            <a href="/">
              <img src={logo} alt="로로 로고" className="w-full" />
            </a>
          </div>
          <p className="py-10">
            <label
              htmlFor="all"
              onClick={allHandler}
              className={`${
                allchk ? "bg-main" : ""
              } inline-flex flex-wrap content-center justify-center align-middle w-5 h-5 rounded-full border border-gray-800 cursor-pointer `}
            >
              {allchk && <i className="fas fa-check invert text-[13px]"></i>}
            </label>
            <input
              type="checkbox"
              name="allChk"
              className="invisible"
              id="all"
            />
            <span onClick={allHandler} className="text-sm">
              로컬픽 이용약관, 개인정보 수집 및 이용, 프로모션 정보 수신(선택)에
              모두 동의 합니다.
            </span>
          </p>
          <div className="pb-5">
            <p>
              <label
                htmlFor="use"
                onClick={useHandler}
                className={`${
                  usechk ? "bg-main" : ""
                } inline-flex flex-wrap content-center justify-center align-middle w-5 h-5 rounded-full border border-gray-800 cursor-pointer `}
              >
                {usechk && <i className="fas fa-check invert text-[13px]"></i>}
              </label>
              <input
                type="checkbox"
                name="agreeForUse"
                className="invisible"
                id="use"
              />
              <span onClick={useHandler} className="text-sm">
                로컬픽 이용약관 동의(필수)
              </span>
            </p>
            <div className=" text-sm p-3 border-2 border-gray-400 overflow-y-scroll h-48 mt-2 whitespace-pre-wrap">
              {userRule1}
              <img
                src={ruleCellCome}
                alt="클래스 콘텐츠만 구매시, 결제 완료일부터 7일 이내 환불요청 가능 , 환불금액은 chapter1을 초과하여 수강한 경우 결제한 금액의 50%. 패키지 구매 시 (클래스 콘텐츠 + 키트) 배송 완료일부터 7일 이내 교환/환불 요청 가능 Chapter1을 초과하여 수강한 경우 결제한 금액에서 수강권 정가의 50%를 차감한 금액. 패키지 구매 후 클래스 콘텐츠만 환불 불가"
              />
              {userRule2}
              <img
                src={ruleCellGo}
                alt="예정 수강일로부터 3일 이내 교환/환불 요청 시, 금액은 결제한 금액 / 예정 수강일로부터 2일이내 교환/환불 시, 환불금액은 수강권 결제액의 30%를 차감한 금액 , 예정 수강일 당일 교환/환불 요청은 불가능"
              />
              {userRule3}
            </div>
          </div>
          <div className="pb-5">
            <p>
              <label
                htmlFor="info"
                onClick={infoHandler}
                className={`${
                  infochk ? "bg-main" : ""
                } inline-flex flex-wrap content-center justify-center align-middle w-5 h-5 rounded-full border border-gray-800 cursor-pointer `}
              >
                {infochk && <i className="fas fa-check invert text-[13px]"></i>}
              </label>
              <input
                type="checkbox"
                name="agreeForInfo"
                className="invisible"
                id="info"
              />
              <span onClick={infoHandler} className="text-sm">
                로컬픽 개인정보 수집 및 이용동의(필수)
              </span>
            </p>
            <div className=" text-sm p-3 border-2 border-gray-400 overflow-y-scroll h-48 mt-2 whitespace-pre-wrap">
              {personalInfo}
            </div>
          </div>
          {/* <div className="pb-10">
            <p>
              <label
                htmlFor="pro"
                onClick={proHandler}
                className={`${
                  prochk ? "bg-main" : ""
                } inline-flex flex-wrap content-center justify-center align-middle w-5 h-5 rounded-full border border-gray-800 cursor-pointer `}
              >
                {prochk && <i className="fas fa-check invert text-[13px]"></i>}
              </label>
              <input
                type="checkbox"
                name="agreeForPromotion"
                className="invisible"
                id="pro"
              />
              <span className="text-sm">기타 정보 수신 동의(선택)</span>
            </p>
            <div className=" text-sm p-3 border-2 border-gray-400 overflow-y-scroll h-32 mt-2">
              Where does it come from? Contrary to popular belief, Lorem Ipsum
              is not simply random text. It has roots in a piece of classical
              Latin literature from 45 BC, making it over 2000 years old.
              Richard McClintock, a Latin professor at Hampden-Sydney College in
              Virginia, looked up one of the more obscure Latin words,
              consectetur, from a Lorem Ipsum passage, and going through the
              cites of the word in classical literature, discovered the
              undoubtable source. Lorem Ipsum comes from sections 1.10.32 and
              1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good
              and Evil) by Cicero, written in 45 BC. This book is a treatise on
              the theory of ethics, very popular during the Renaissance. The
              first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes
              from a line in section 1.10.32. The standard chunk of Lorem Ipsum
              used since the 1500s is reproduced below for those interested.
              Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum"
              by Cicero are also reproduced in their exact original form,
              accompanied by English versions from the 1914 translation by H.
              Rackham. Where can I get some? There are many variations of
              passages of Lorem Ipsum available, but the majority have suffered
              alteration in some form, by injected humour, or randomised words
              which don't look even slightly believable. If you are
            </div>
          </div> */}
          {confirm && (
            <p className="text-sm text-red-600 text-center">
              로로클래스 이용약관 동의와 개인정보 수집 및 이용동의에 모두
              동의해주세요.{" "}
            </p>
          )}
          <div className=" bg-white sm:bg-transparent left-0 bottom-0 w-full fixed sm:static text-center space-x-5 py-4 sm:py-10 border sm:border-0">
            <button className="hover:opacity-60 w-2/5 sm:w-40 py-1 rounded-md border border-grays ">
              <Link to="/signin" className="block w-full h-full">
                취소
              </Link>
            </button>
            <button
              className="hover:opacity-60 w-2/5 sm:w-40 py-1 rounded-md  text-white bg-main"
              onClick={() => {
                if (usechk === false || infochk === false) {
                  setConfirm(() => {
                    return true;
                  });
                } else {
                  document.location.href = "/join/select";
                }
              }}
            >
              확인
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
