import moment from "moment";
import React from "react";
import { listDoorMine } from "../__generated__/listDoorMine";

interface IGeneralDoorListProps {
  data: listDoorMine | undefined;
  loading: boolean;
  refetch: () => void;
  setDoorHandler: (doorId: number) => void;
}

export const SubGeneralListDoor: React.FC<IGeneralDoorListProps> = ({
  data,
  loading,
  refetch,
  setDoorHandler,
}) => {
  return (
    <div className="bg-white p-5 rounded-2xl shadow-lg">
      <p className="text-main font-bold py-2">문의목록</p>
      <table className="w-full overflow-hidden">
        <thead>
          <tr className="bg-main  border-gray-300">
            <th className="text-white text-md py-1 sm:table-cell hidden">
              No.
            </th>
            <th className="text-white text-md py-1 sm:table-cell hidden">
              분류
            </th>
            <th className="text-white text-md py-1">제목</th>
            <th className="text-white text-md py-1 min-w-[50px]">상태</th>
            <th className="text-white text-md py-1 sm:table-cell hidden">
              문의 날짜
            </th>
          </tr>
        </thead>
        <tbody>
          {!loading &&
            data?.listDoorMine.doors &&
            data.listDoorMine.doors.map((door, index) => (
              <tr
                key={`door-${door.id}`}
                className="border-b-2 border-gray-300 hover:bg-gray-100 cursor-pointer"
              >
                <td className="text-md text-center py-1 sm:table-cell hidden">
                  {index + 1}
                </td>
                <td className="text-md text-center py-1 sm:table-cell hidden">
                  {door.type}
                </td>
                <td className="text-md text-center py-1">
                  <p
                    className="w-full ellipsis1 h-[20px] overflow-hidden"
                    onClick={() => setDoorHandler(door.id)}
                  >
                    {door.title}
                  </p>
                </td>
                <td className={`text-sm text-center py-1`}>{door.status}</td>
                <td className={`text-sm text-center py-1 sm:table-cell hidden`}>
                  {moment(door.createdAt).format("yy년 M월 D일 HH:mm")}
                </td>
              </tr>
            ))}
          {!loading && data?.listDoorMine.doors?.length === 0 && (
            <tr>
              <td colSpan={5}>
                <p className={`py-1 text-center`}>문의하신 내역이 없습니다.</p>
              </td>
            </tr>
          )}
          {!loading && !data && (
            <tr>
              <td colSpan={5}>
                <p className={`py-1 text-center`}>문의하신 내역이 없습니다.</p>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};
