import { ApolloError, gql, useMutation } from "@apollo/client";
import React from "react";
import {
  delConsultReply,
  delConsultReplyVariables,
} from "../__generated__/delConsultReply";

const DEL_CONSULT_REPLY_MUTATION = gql`
  mutation delConsultReply($input: DelConsultReplyInput!) {
    delConsultReply(input: $input) {
      ok
      err
    }
  }
`;

export const useDelConsultReply = (
  onCompleted?: (data: delConsultReply) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<delConsultReply, delConsultReplyVariables>(
    DEL_CONSULT_REPLY_MUTATION,
    {
      onCompleted,
      onError,
    }
  );
};
