import React, { useEffect, useState } from "react";
import { createBrowserHistory } from "history";
import { useLocation, useParams } from "react-router";
import { ListBannerOptionInput } from "../__generated__/globalTypes";
import { useListBannerOption } from "../hooks/useListBannerOption";
import { listBannerOption } from "../__generated__/listBannerOption";
import { UpBannerOptionForm7_3 } from "../components/up-banner-option-form-7-3";

export const SubAdminUpBannerMain: React.FC = () => {
  const history = createBrowserHistory();
  const params = useParams();

  const onListCompleted = (data: listBannerOption) => {};
  const [listBannerOption, { data, loading, refetch }] =
    useListBannerOption(onListCompleted);
  const listBannerOptionHandler = (bannerId: number) => {
    const listBannerOptionInput: ListBannerOptionInput = {
      bannerId,
    };
    listBannerOption({ variables: { input: listBannerOptionInput } });
  };

  useEffect(() => {
    if (params.bannerId) {
      listBannerOptionHandler(+params.bannerId);
    }
  }, []);

  return (
    <div className="bg-white p-5 rounded-2xl shadow-lg">
      <div className="p-5 space-y-5 lg:space-y-10">
        <div className="space-y-3">
          <div className="w-full grid grid-cols-1 gap-3">
            {loading && <div>정보를 불러오는 중입니다</div>}
            {!loading && (
              <>
                <UpBannerOptionForm7_3
                  productType={null}
                  bannerOptionId={
                    data?.listBannerOption.bannerOptions
                      ? Number(data?.listBannerOption.bannerOptions[0].id)
                      : 0
                  }
                  link={`${
                    data?.listBannerOption.bannerOptions &&
                    data?.listBannerOption.bannerOptions[0].link
                  }`}
                  bannerTitle={`메인 배너`}
                  posterUrl={
                    data?.listBannerOption.bannerOptions
                      ? data.listBannerOption.bannerOptions[0].view?.url
                      : ``
                  }
                  refetch={refetch}
                />
                <UpBannerOptionForm7_3
                  productType={null}
                  bannerOptionId={
                    data?.listBannerOption.bannerOptions
                      ? Number(data?.listBannerOption.bannerOptions[1].id)
                      : 0
                  }
                  link={`${
                    data?.listBannerOption.bannerOptions &&
                    data?.listBannerOption.bannerOptions[1].link
                  }`}
                  bannerTitle={`메인 배너 2`}
                  posterUrl={
                    data?.listBannerOption.bannerOptions
                      ? data.listBannerOption.bannerOptions[1].view?.url
                      : ``
                  }
                  refetch={refetch}
                />
                <UpBannerOptionForm7_3
                  productType={null}
                  bannerOptionId={
                    data?.listBannerOption.bannerOptions
                      ? Number(data?.listBannerOption.bannerOptions[2].id)
                      : 0
                  }
                  link={`${
                    data?.listBannerOption.bannerOptions &&
                    data?.listBannerOption.bannerOptions[2].link
                  }`}
                  bannerTitle={`메인 배너 3`}
                  posterUrl={
                    data?.listBannerOption.bannerOptions
                      ? data.listBannerOption.bannerOptions[2].view?.url
                      : ``
                  }
                  refetch={refetch}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
