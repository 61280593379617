import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
// import { classes, festival } from "./data";
import offline from "../../../assets/icon/off.svg";
import online from "../../../assets/icon/on.svg";
import search from "../../../assets/icon/search.svg";
import { Fade } from "@mui/material";
import map from "../../../assets/images/sample/map.png";
import { calculate, comma } from "../../../apollo";
import { getMap } from "../../../constants/get-map";
import { useSearchNaver } from "../../../hooks/useSearchNaver";
import { SearchNaverInput } from "../../../__generated__/globalTypes";
import { useForm } from "react-hook-form";
import { searchNaver } from "../../../__generated__/searchNaver";
import { SearchProductWAddressInput } from "../../../__generated__/globalTypes";
import { useSearchProductAddress } from "../../../hooks/useSearchProductWAddress";
import { useGetBannerOptionActive } from "../../../hooks/useGetBannerOptionActive";
import { ComponentProductWidget } from "../../../components/component-product";
interface ISearchNaverFormProps extends SearchNaverInput {}
interface ISearchProductFormProps extends SearchProductWAddressInput {}

export const Place = () => {
  const { register, getValues, setValue, setFocus, handleSubmit } =
    useForm<ISearchNaverFormProps>({
      mode: "onChange",
    });

  // remove  HTML Tags
  var tagBody = "(?:[^\"'>]|\"[^\"]*\"|'[^']*')*";
  var tagOrComment = new RegExp(
    "<(?:" +
      // Comment body.
      "!--(?:(?:-*[^->])*--+|-?)" +
      // Special "raw text" elements whose content should be elided.
      "|script\\b" +
      tagBody +
      ">[\\s\\S]*?</script\\s*" +
      "|style\\b" +
      tagBody +
      ">[\\s\\S]*?</style\\s*" +
      // Regular name
      "|/?[a-z]" +
      tagBody +
      ")>",
    "gi"
  );
  function removeTags(html: any) {
    var oldHtml;
    do {
      oldHtml = html;
      html = html.replace(tagOrComment, "");
    } while (html !== oldHtml);
    return html.replace(/</g, "&lt;");
  }

  const [searchPlace, setsearchPlace] = useState(false);
  const [titleName, setTitleName] = useState("");

  const keywordHandler = (str: string) => {
    setValue("search", str);
    setFocus("search");
    onSubmit();
  };
  // 지역명 뽑기
  const regionNameGet = (str: string) => {
    let title = str.split(" ")[0] + " " + str.split(" ")[1];
    return title;
  };
  const showClassHandler = (str: string) => {
    setTitleName(str);
    let near = document.getElementById("nearStore")?.offsetTop;
    window.scrollTo(0, Number(near));
  };

  const onCompleted = (rst: searchNaver) => {};

  const [
    searchNaver,
    { data: searchNaverResult, loading: searchNaverLoading },
  ] = useSearchNaver(onCompleted);

  const onSubmit = () => {
    const { search } = getValues();
    setTitleName("");
    setsearchPlace(true);
    if (!searchNaverLoading) {
      const searchNaverInput: SearchNaverInput = {
        search,
      };
      searchNaver({
        variables: {
          input: searchNaverInput,
        },
      });
    }
  };
  const [
    searchProductWAddress,
    { data: searchProductWAddressData, loading, called },
  ] = useSearchProductAddress();
  const listProductInPlaceHandler = (address: string) => {
    const input: SearchProductWAddressInput = {
      address,
    };
    searchProductWAddress({ variables: { input } });
  };

  const { data } = useGetBannerOptionActive();

  return (
    <div
      className={`max-w-screen-yl mx-auto px-5 py-8 lg:py-20`}
      id="localPlace"
    >
      <div className="flex flex-wrap justify-between">
        <p className="inline-block text-main gangwon text-2xl sm:text-3xl">
          <a
            href="/place"
            className="hover:underline inline-flex flex-wrap content-center"
          >
            <span className="align-middle">로컬픽플레이스</span>
          </a>
          <span className="hidden sm:inline align-middle text-sm ml-3">
            <i className="fas fa-chevron-right"></i>
          </span>
        </p>
        <button className="sm:hidden flex pb-2 md:pb-0 hover:underline text-text text-xs md:text-base flex-wrap content-end">
          <a href="/place">more</a>
        </button>
      </div>
      <div className="hidden sm:flex flex-wrap justify-between">
        <p className="text-text pt-3 text-sm sm:text-base">
          전국 어디든 힙한 곳을 검색해 보세요!
        </p>
        <button className="hidden sm:flex pb-2 md:pb-0 hover:underline text-text text-xs md:text-base flex-wrap content-end">
          <a href="/place">more</a>
        </button>
      </div>
      <div>
        <div className="block sm:grid grid-cols-2 gap-3 sm:gap-5 pt-5">
          <div className="search">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="border-main border-4 rounded-sm relative">
                <input
                  {...register("search")}
                  id="naverSearch"
                  type="text"
                  className="w-full md:text-base py-1 px-2 focus:outline-none"
                />
                <button
                  type="submit"
                  className="absolute right-0 top-0 px-2 h-full bg-main"
                >
                  <img src={search} alt="검색" className="w-6" />
                </button>
              </div>
            </form>
            <p className="pt-3 flex flex-wrap gap-1">
              <button
                onClick={() => keywordHandler("울산 카페")}
                className="rounded-xl sm:bg-[#C7CEC7] px-2 py-1 sm:text-white bg-white border border-[##F1F2EF] sm:text-base text-sm text-[#A09B99]  "
              >
                울산 카페
              </button>
              <button
                onClick={() => keywordHandler("불꽃놀이 축제")}
                className="rounded-xl sm:bg-[#C7CEC7] px-2 py-1 sm:text-white bg-white border border-[##F1F2EF] sm:text-base text-sm text-[#A09B99]  "
              >
                불꽃놀이 축제
              </button>
              <button
                onClick={() => keywordHandler("수채화 공방")}
                className="rounded-xl sm:bg-[#C7CEC7] px-2 py-1 sm:text-white bg-white border border-[##F1F2EF] sm:text-base text-sm text-[#A09B99]  "
              >
                수채화 공방
              </button>
              <button
                onClick={() => keywordHandler("벚꽃놀이")}
                className="rounded-xl sm:bg-[#C7CEC7] px-2 py-1 sm:text-white bg-white border border-[##F1F2EF] sm:text-base text-sm text-[#A09B99]  "
              >
                벚꽃놀이
              </button>
            </p>
            <div className="overflow-x-auto removeScroll">
              {data?.getBannerOptionActive.bannerOptions && (
                <div className="grid min-w-[1200px] sm:min-w-0 grid-cols-4 sm:grid-cols-3 gap-3 sm:gap-x-2 pt-10">
                  {data.getBannerOptionActive.bannerOptions[9].product
                    ?.productViews &&
                    data.getBannerOptionActive.bannerOptions[9].product
                      .productViews.length > 0 && (
                      <a
                        href={`/place/${data?.getBannerOptionActive.bannerOptions[9].product?.id}`}
                        className="placeBannerRatio relative overflow-hidden placeBanner"
                      >
                        <div className="absolute w-full h-full left-0 top-0 placeEffect">
                          <img
                            src={`${
                              data.getBannerOptionActive.bannerOptions[9].product?.productViews?.filter(
                                (productView) => productView.is_rep
                              )[0].view?.url
                            }`}
                            alt={`${data.getBannerOptionActive.bannerOptions[9].product?.productInfo?.title} 배너`}
                            className="absolute left-0 top-0 w-full h-full object-cover transform scale-100 transition-transform placeImg duration-300 "
                          />
                          <div className="absolute flex flex-wrap  flex-col content-center justify-center left-0 -top-full w-full h-full px-4 z-10 placeText transition-all duration-300 ">
                            <p className="relative text-white text-sm">
                              {`${data.getBannerOptionActive.bannerOptions[9].product?.productInfo?.title}`}
                            </p>
                            <p className="relative font-bold text-white text-sm pt-4 text-center">
                              바로가기
                            </p>
                          </div>
                        </div>
                      </a>
                    )}
                  {data.getBannerOptionActive.bannerOptions[10].product
                    ?.productViews &&
                    data.getBannerOptionActive.bannerOptions[10].product
                      .productViews.length > 0 && (
                      <a
                        href={`/place/${data?.getBannerOptionActive.bannerOptions[10].product?.id}`}
                        className="placeBannerRatio relative overflow-hidden placeBanner"
                      >
                        <div className="absolute w-full h-full left-0 top-0 placeEffect">
                          <img
                            src={`${
                              data.getBannerOptionActive.bannerOptions[10].product?.productViews?.filter(
                                (productView) => productView.is_rep
                              )[0].view?.url
                            }`}
                            alt={`${data.getBannerOptionActive.bannerOptions[10].product?.productInfo?.title} 배너`}
                            className="absolute left-0 top-0 w-full h-full object-cover transform scale-100 transition-transform placeImg duration-300 "
                          />
                          <div className="absolute flex flex-wrap  flex-col content-center justify-center left-0 -top-full w-full h-full px-4 z-10 placeText transition-all duration-300 ">
                            <p className="relative text-white text-sm">
                              {`${data.getBannerOptionActive.bannerOptions[10].product?.productInfo?.title}`}
                            </p>
                            <p className="relative font-bold text-white text-sm pt-4 text-center">
                              바로가기
                            </p>
                          </div>
                        </div>
                      </a>
                    )}
                  {data.getBannerOptionActive.bannerOptions[11].product
                    ?.productViews &&
                    data.getBannerOptionActive.bannerOptions[11].product
                      .productViews.length > 0 && (
                      <a
                        href={`/place/${data?.getBannerOptionActive.bannerOptions[11].product?.id}`}
                        className="placeBannerRatio relative overflow-hidden placeBanner"
                      >
                        <div className="absolute w-full h-full left-0 top-0 placeEffect">
                          <img
                            src={`${
                              data.getBannerOptionActive.bannerOptions[11].product?.productViews?.filter(
                                (productView) => productView.is_rep
                              )[0].view?.url
                            }`}
                            alt={`${data.getBannerOptionActive.bannerOptions[11].product?.productInfo?.title} 배너`}
                            className="absolute left-0 top-0 w-full h-full object-cover transform scale-100 transition-transform placeImg duration-300 "
                          />
                          <div className="absolute flex flex-wrap  flex-col content-center justify-center left-0 -top-full w-full h-full px-4 z-10 placeText transition-all duration-300 ">
                            <p className="relative text-white text-sm">
                              {`${data.getBannerOptionActive.bannerOptions[11].product?.productInfo?.title}`}
                            </p>
                            <p className="relative font-bold text-white text-sm pt-4 text-center">
                              바로가기
                            </p>
                          </div>
                        </div>
                      </a>
                    )}
                  {data.getBannerOptionActive.bannerOptions[12].product
                    ?.productViews &&
                    data.getBannerOptionActive.bannerOptions[12].product
                      .productViews.length > 0 && (
                      <a
                        href={`/place/${data?.getBannerOptionActive.bannerOptions[12].product?.id}`}
                        className="placeBannerRatio relative overflow-hidden sm:hidden placeBanner"
                      >
                        <div className="absolute w-full h-full left-0 top-0 placeEffect">
                          <img
                            src={`${
                              data.getBannerOptionActive.bannerOptions[12].product?.productViews?.filter(
                                (productView) => productView.is_rep
                              )[0].view?.url
                            }`}
                            alt={`${data.getBannerOptionActive.bannerOptions[12].product?.productInfo?.title} 배너`}
                            className="absolute left-0 top-0 w-full h-full object-cover transform scale-100 transition-transform placeImg duration-300 "
                          />
                          <div className="absolute flex flex-wrap  flex-col content-center justify-center left-0 -top-full w-full h-full px-4 z-10 placeText transition-all duration-300 ">
                            <p className="relative text-white text-sm">
                              {`${data.getBannerOptionActive.bannerOptions[12].product?.productInfo?.title}`}
                            </p>
                            <p className="relative font-bold text-white text-sm pt-4 text-center">
                              바로가기
                            </p>
                          </div>
                        </div>
                      </a>
                    )}
                </div>
              )}
            </div>
          </div>
          {data?.getBannerOptionActive.bannerOptions &&
            data.getBannerOptionActive.bannerOptions[12].product
              ?.productViews &&
            data.getBannerOptionActive.bannerOptions[12].product.productViews
              .length > 0 && (
              <a
                href={`/place/${data?.getBannerOptionActive.bannerOptions[12].product?.id}`}
                className="relative sm:block hidden overflow-hidden placeBanner"
              >
                <div className="absolute w-full h-full left-0 top-0 placeEffect">
                  <img
                    src={`${
                      data.getBannerOptionActive.bannerOptions[12].product?.productViews?.filter(
                        (productView) => productView.is_rep
                      )[0].view?.url
                    }`}
                    alt={`${data.getBannerOptionActive.bannerOptions[12].product?.productInfo?.title} 배너`}
                    className="absolute left-0 top-0 w-full h-full object-cover transform scale-100  transition-transform duration-300 placeImg "
                  />
                  <div className="absolute flex flex-wrap flex-col content-center justify-center left-0 -top-full w-full h-full px-4 z-10 placeText transition-all duration-300">
                    <p className="relative text-white text-sm">
                      {`${data.getBannerOptionActive.bannerOptions[12].product?.productInfo?.title}`}
                    </p>
                    <p className="relative font-bold text-white text-sm pt-4 text-center">
                      바로가기
                    </p>
                  </div>
                </div>
              </a>
            )}
        </div>
        <Fade in={searchPlace}>
          <div
            id="Naverplace"
            className={`result pt-5 ${searchPlace ? "block" : "hidden"}`}
          >
            <p className="py-3">
              네이버 검색 장소{" "}
              <span
                onClick={() => setsearchPlace(false)}
                className="text-xs ml-10 text-text cursor-pointer hover:underline"
              >
                닫기
              </span>
            </p>
            <div className="inline-grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 rounded-md ">
              {!searchNaverLoading &&
                searchNaverResult?.searchNaver.items &&
                searchNaverResult?.searchNaver?.items.map((item, index) => (
                  <div
                    key={`places-${index}`}
                    className=" card max-w-[360px] px-3 py-5 bg-white rounded grid grid-cols-[1fr_100px] border-b"
                  >
                    <div className="pr-2">
                      <p className="title">
                        {item.link ? (
                          <a
                            href={item.link}
                            target="_blank"
                            title={removeTags(item.title) + " 사이트로 가기"}
                            className="text-blue-500  hover:underline text-sm lg:text-base leading-tight"
                          >
                            {removeTags(item.title)}
                          </a>
                        ) : (
                          <span className="text-blue-500  hover:underline text-sm lg:text-base leading-tight">
                            {removeTags(item.title)}
                          </span>
                        )}
                      </p>
                      <p className="category text-xs lg:text-sm text-text">
                        {item.category}
                      </p>
                      <p className="address pt-2 h-13 leading-tight ellipsis2 overflow-hidden text-sm lg:text-base">
                        {item.address}
                      </p>
                      <p className="pt-2">
                        <button
                          onClick={() => {
                            showClassHandler(removeTags(item.title));
                            listProductInPlaceHandler(
                              regionNameGet(item.address)
                            );
                          }}
                          className="text-xs lg:text-sm border border-grays rounded px-2 py-1 shadow hover:opacity-60 focus:bg-gray-100"
                        >
                          근처 클래스 보기
                        </button>
                      </p>
                    </div>
                    <div>
                      <a
                        href={`https://map.naver.com/v5/search/${removeTags(
                          item.title
                        )}`}
                        target="_blank"
                      >
                        <img
                          src={getMap("300", "300", +item.mapx, +item.mapy)}
                          alt="지도"
                          title="지도검색"
                          className="w-full aspect-square hover:outline hover:outline-1 hover:outline-grays"
                        />
                      </a>
                    </div>
                  </div>
                ))}
            </div>
            {searchNaverResult?.searchNaver.items?.length === 0 && (
              <div className="w-full py-10 text-xl font-bold">
                검색된 장소가 없습니다.
              </div>
            )}
            <Fade in={Boolean(titleName)}>
              <div id="nearStore" className={`classes pt-5 `}>
                <p className="py-3">
                  근방 클래스 리스트{" "}
                  <span>{titleName && `- ${titleName}`}</span>
                </p>
                {/* <div className="grid grid-cols-2 md:grid-cols-4 gap-3 md:gap-5 pt-5">
                  {searchProductWAddressData?.searchProductWAddress.products &&
                    searchProductWAddressData.searchProductWAddress.products.map(
                      (product) => (
                        <div key={`withaddress-${product.id}`} className="card">
                          <ComponentProductWidget
                            product={product}
                            key={"-with-address"}
                          />
                        </div>
                      )
                    )}
                </div> */}
                {searchProductWAddressData?.searchProductWAddress.products &&
                  searchProductWAddressData?.searchProductWAddress.products
                    .length === 0 && (
                    <div className="py-10 text-bold text-2xl">
                      근방에서 운영하는 클래스가 없습니다.
                    </div>
                  )}
              </div>
            </Fade>
          </div>
        </Fade>
      </div>
    </div>
  );
};
