import React from "react";
import gql from "graphql-tag";
import { ApolloError, useMutation } from "@apollo/client";
import {
  crUserCurator,
  crUserCuratorVariables,
} from "../__generated__/crUserCurator";
import { CrUserCuratorInput } from "../__generated__/globalTypes";

export interface ICrUserCuratorFormProps extends CrUserCuratorInput {
  confirmPassword: string;
}

const CR_USER_CURATOR_MUTATION = gql`
  mutation crUserCurator($input: CrUserCuratorInput!) {
    crUserCurator(input: $input) {
      ok
      err
    }
  }
`;

export const useCrUserCurator = (
  onCompleted?: (data: crUserCurator) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<crUserCurator, crUserCuratorVariables>(
    CR_USER_CURATOR_MUTATION,
    {
      onCompleted,
      onError,
    }
  );
};
