import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper";
import "swiper/css/pagination";
import "swiper/css";
import { useGetBannerOptionActive } from "../../../hooks/useGetBannerOptionActive";
export const Banner = () => {
  const { data } = useGetBannerOptionActive();

  return (
    <div className="bannerBox w-full max-w-screen-yl aspect-[7/3] mx-auto lg:px-0">
      <div className="innerBanner border-t-2 relative">
        <Swiper
          modules={[Navigation, Autoplay, Pagination]}
          slidesPerView={1}
          pagination={{
            clickable: true,
            el: ".swiper-pagination",
            type: "bullets",
          }}
          autoplay={{ delay: 5000, disableOnInteraction: false }}
          className="relative"
        >
          <div className="swiper-pagination"></div>
          {data?.getBannerOptionActive.bannerOptions
            ?.filter(
              (bannerOption) =>
                bannerOption.index &&
                bannerOption.index < 4 &&
                bannerOption.view
            )
            .map((bannerOption) => (
              <SwiperSlide
                key={`main-swiper-${bannerOption.id}`}
                className="w-full bannerRatio relative overflow-hidden"
              >
                <div className="absolute left-0 top-0 w-full h-full">
                  <a href={`${bannerOption.link ? bannerOption.link : ``}`}>
                    <img
                      src={`${bannerOption.view?.url}`}
                      alt={`${bannerOption.link} 메인 배너`}
                      className="md:w-full h-full object-cover"
                    />
                  </a>
                </div>
              </SwiperSlide>
            ))}
        </Swiper>
      </div>
    </div>
  );
};
