import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import usePagination from "@mui/material/usePagination";
import { createBrowserHistory } from "history";
import searchButton from "../assets/icon/search.png";
import { useLocation } from "react-router";
import {
  DelProductInput,
  ListClassInactiveInput,
  ProductStatus,
  UpProductStatusInput,
} from "../__generated__/globalTypes";
import { TableError } from "../components/table-error";
import {
  listClassInactive,
  listClassInactive_listClassInactive_classes,
} from "../__generated__/listClassInactive";
import { TextButton } from "../components/button";
import { useUpProductStatus } from "../hooks/useUpProductStatus";
import { upProductStatus } from "../__generated__/upProductStatus";
import { useListClassInactive } from "../hooks/useListClassInactive";
import { useForm } from "react-hook-form";
import { delProduct } from "../__generated__/delProduct";
import { useDelProduct } from "../hooks/useDelProduct";

export interface ISearchFormProps {
  search: string;
}
export const SubAdminListInactiveClass: React.FC = () => {
  const history = createBrowserHistory();
  const location = useLocation();
  const [search, setSearch] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const { register, getValues, handleSubmit } = useForm<ISearchFormProps>({
    mode: "all",
  });
  const pageHandler = (page: number) => {
    const search =
      location.search.split("search=").length > 1
        ? location.search.split("search=")[1].split("&")[0]
        : null;
    history.push({
      pathname: "/admin/classes",
      search: `?page=${page}${search ? `&search=${search}` : ""}`,
    });
    listClassInactiveHandler(page, search ? search : undefined);
  };

  const onSearch = async () => {
    const { search } = getValues();
    setSearch(search);
    history.push({
      pathname: "/admin/promotion",
      search: `${search ? `?search=${search}` : ""}`,
    });
    listClassInactiveHandler(1, search);
  };

  const onCompleted = (data: listClassInactive) => {};

  const [listClassInactive, { data: listClassInactiveData, loading, refetch }] =
    useListClassInactive(onCompleted);

  const listClassInactiveHandler = (page: number, search?: string) => {
    const listClassInactiveInput: ListClassInactiveInput = {
      ...(page && { page: +page }),
      ...(search && { search }),
    };
    listClassInactive({ variables: { input: listClassInactiveInput } });
  };

  const onUpCompleted = async (data: upProductStatus) => {
    const {
      upProductStatus: { ok, err },
    } = data;
    if (ok) {
      alert(`클래스 상태가 수정되었습니다`);
      await refetch();
    } else {
      alert(err);
    }
  };
  const [upProductStatus, { loading: upProductStatusLoading }] =
    useUpProductStatus(onUpCompleted);
  const upProductStatusOnHandler = (
    productIdToUp: number,
    productStatus: ProductStatus
  ) => {
    const upProductStatusInput: UpProductStatusInput = {
      productIdToUp,
      productStatus,
    };
    upProductStatus({ variables: { input: upProductStatusInput } });
  };

  const onDelCompleted = async (data: delProduct) => {
    const {
      delProduct: { ok, err },
    } = data;
    if (ok) {
      alert(`강의를 삭제하였습니다`);
      await refetch();
    } else {
      alert(err);
    }
  };
  const [delProduct, { loading: delProductLoading }] =
    useDelProduct(onDelCompleted);
  const delProductHandler = (productIdToDel: number) => {
    if (delProductLoading) return;
    if (
      window.confirm(
        "삭제한 자료는 복구할 수 없습니다, 정말로 삭제하시겠습니까?"
      )
    ) {
      const input: DelProductInput = {
        productIdToDel,
      };
      delProduct({ variables: { input } });
    }
  };

  const { items } = usePagination({
    count: listClassInactiveData?.listClassInactive.totalPages
      ? listClassInactiveData.listClassInactive.totalPages
      : 1,
  });

  useEffect(() => {
    listClassInactiveHandler(page, search ? search : undefined);
  }, []);

  const List = styled("ul")({
    listStyle: "none",
    paddingTop: 20,
    textAlign: "center",
    display: "block",
  });

  return (
    <div className={`w-full h-full px-10 py-12`}>
      <div className="text-right pb-8">
        <form onSubmit={handleSubmit(onSearch)}>
          <p className="inline-block max-w-md w-full text-right relative">
            <input
              {...register(`search`)}
              type="text"
              className="w-full py-1 px-4 bg-white rounded-full"
              placeholder="강의명, 작가명으로 찾기"
            />
            <button className="absolute right-3 top-1">
              <img src={searchButton} alt="찾기" className="w-5" />
            </button>
          </p>
        </form>
      </div>
      <div className="bg-white p-5 rounded-2xl shadow-lg">
        <p className="text-main font-bold pb-3">비활성화 클래스</p>
        <table className="w-full">
          <thead>
            <tr className="bg-main  border-gray-300">
              <th className="text-white ">강의번호</th>
              <th className="text-white ">강의제목</th>
              <th className="text-white ">작가</th>
              <th className="text-white ">금액</th>
              <th className="text-white ">상태</th>
              <th className="text-white ">기능</th>
            </tr>
          </thead>
          <tbody>
            {!loading &&
              listClassInactiveData?.listClassInactive.classes &&
              listClassInactiveData.listClassInactive.classes.map(
                (_class: listClassInactive_listClassInactive_classes) => (
                  <tr
                    key={`classes-${_class?.id}`}
                    className="border-b-2 border-gray-300 h-4"
                  >
                    <td className="text-sm text-center py-1 px-1 h-full overflow-hidden">
                      {_class?.id && _class.id}
                    </td>
                    <td className="text-sm text-center h-full relative">
                      {_class?.productInfo?.title && _class.productInfo.title}
                    </td>
                    <td className="text-sm text-center  relative">
                      {_class?.inst.user?.name && _class.inst.user.name}
                    </td>
                    <td className="text-sm text-center py-1 px-1 h-full overflow-hidden">
                      {_class?.productInfo?.price &&
                        _class.productInfo.price.toLocaleString()}
                    </td>
                    <td className="text-sm text-center py-1 px-1 h-full overflow-hidden ">
                      {_class?.productStatus && _class.productStatus}
                    </td>
                    <td className="text-sm text-center py-1 px-1 h-full overflow-hidden">
                      <TextButton
                        canClick={!upProductStatusLoading}
                        actionText={`활성화`}
                        onClick={() =>
                          upProductStatusOnHandler(_class.id, ProductStatus.On)
                        }
                      />
                      {` / `}
                      <TextButton
                        canClick={false}
                        actionText={`비활성화`}
                        onClick={() =>
                          upProductStatusOnHandler(
                            _class.id,
                            ProductStatus.Pending
                          )
                        }
                      />
                      {` / `}
                      <TextButton
                        canClick={true}
                        actionText={`삭제`}
                        onClick={() => delProductHandler(_class.id)}
                      />
                    </td>
                  </tr>
                )
              )}
          </tbody>
        </table>
        {loading && <TableError errorMessage={`정보를 불러오는 중입니다`} />}
        {!loading &&
          listClassInactiveData?.listClassInactive.classes &&
          listClassInactiveData.listClassInactive.classes.length < 1 && (
            <TableError errorMessage={`해당하는 작가 정보가 없습니다`} />
          )}
        <List>
          {items.map(({ page, type, selected, ...item }, index) => {
            let children = null;

            if (type === "start-ellipsis" || type === "end-ellipsis") {
              children = "…";
            } else if (type === "page") {
              children = (
                <button
                  type="button"
                  style={{
                    fontWeight: selected ? "bold" : undefined,
                  }}
                  {...item}
                  onClick={() => pageHandler(page)}
                >
                  {page}
                </button>
              );
            } else if (type === "previous") {
              children = (
                <button type="button" {...item}>
                  <i className="fas fa-angle-left"></i>
                </button>
              );
            } else if (type === "next") {
              children = (
                <button type="button" {...item}>
                  <i className="fas fa-angle-right"></i>
                </button>
              );
            }
            return (
              <li
                key={index + type}
                className={`text-xs px-2  border-grays inline-block ${
                  (type === "page" &&
                    page !== 10 &&
                    items.length % 10 > 3 &&
                    "border-r") ||
                  (type === "end-ellipsis" && "border-r") ||
                  (type === "start-ellipsis" && "border-r")
                }`}
              >
                {children}
              </li>
            );
          })}
        </List>
      </div>
    </div>
  );
};
