import { useForm } from "react-hook-form";
import { Button } from "../../../../../components/button";
import {
  ICrConsultFormProps,
  useCrConsult,
} from "../../../../../hooks/useCrConsult";
import { useMe } from "../../../../../hooks/useMe";
import { crConsult } from "../../../../../__generated__/crConsult";
import { CrConsultInput } from "../../../../../__generated__/globalTypes";

export const Request = () => {
  const { data: dataMe } = useMe();
  const removeAll = () => {
    if (window.confirm("작성 중이던 내용을 모두 삭제하시겠습니까?")) {
      let title = document.getElementsByClassName(
        "q_title"
      )[0] as HTMLInputElement;
      let note = document.getElementsByClassName(
        "q_desc"
      )[0] as HTMLInputElement;

      title.value = "";
      note.value = "";
    }
  };
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors, isValid },
  } = useForm<ICrConsultFormProps>({
    mode: "onChange",
  });
  const onCompleted = async (data: crConsult) => {
    const {
      crConsult: { ok },
    } = data;
    if (ok) {
      alert("컨설팅 요청이 등록이되었습니다.");
      window.location.href = `/instPage/consult`;
    }
  };
  const [crConsult, { data: crConsultResult, loading }] =
    useCrConsult(onCompleted);

  const onSubmit = () => {
    const { title, note } = getValues();
    if (!Boolean(title)) {
      alert("강의 제목을 적어주세요.");
      return;
    }
    if (!Boolean(note)) {
      alert("내용을 적어주세요");
      return;
    }
    const crConsultInput: CrConsultInput = {
      title,
      note,
    };
    crConsult({
      variables: {
        input: crConsultInput,
      },
    });
  };
  const onInvalid = () => {};

  return (
    <div className="bg-white p-4 lg:p-10 rounded-2xl shadow-lg">
      <div className="max-w-xl">
        <form onSubmit={handleSubmit(onSubmit, onInvalid)}>
          <p className="text-main font-bold py-2">컨설팅 요청</p>
          <div className="space-y-2 pt-8">
            <p className="font-bold text-main text-sm lg:text=base">강의제목</p>
            <input
              {...register("title")}
              type="text"
              className="q_title border border-grays rounded-xl lg:text-base test-sm py-1 px-2 w-full"
            />
          </div>
          <div className="space-y-2 pt-5">
            <p className="font-bold text-main text-sm lg:text=base">내용</p>
            <textarea
              {...register("note")}
              rows={15}
              className="q_desc w-full border border-grays rounded-xl lg:text-base test-sm px-2 py-1 resize-none"
            ></textarea>
          </div>
          <div className="left-0 bottom-16 w-full fixed sm:static text-center py-4 sm:py-10 space-x-5 bg-white sm:bg-transparent">
            <button
              onClick={removeAll}
              className="rounded-md border border-grays w-2/5 sm:w-[150px] py-1 hover:opacity-60 text-sm lg:text-base"
            >
              모두 제거
            </button>

            <Button
              className={`rounded-md text-white w-2/5 sm:w-[150px] py-1 hover:opacity-60 text-sm lg:text-base bg-main border border-main`}
              canClick={isValid}
              actionText={`저장`}
              loading={loading}
            ></Button>
          </div>
        </form>
      </div>
    </div>
  );
};
