import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import online from "../assets/icon/on.svg";

import { listProductMain_listProductMain_onlineClasses } from "../__generated__/listProductMain";
import banner from "../assets/images/banners/banner2.jpg";
import { useGetBannerOptionActive } from "../hooks/useGetBannerOptionActive";
import { LocalCategory, ProductCategory } from "../__generated__/globalTypes";

import { ComponentProductWidget } from "../components/component-product";

interface IMainClassListProps {
  data?: listProductMain_listProductMain_onlineClasses[] | null;
  refetch: () => void;
}

export const SubCommonMainOnlineClass: React.FC<IMainClassListProps> = ({
  data,
  refetch,
}) => {
  const { data: getBannerOptionActiveData } = useGetBannerOptionActive();

  return (
    <>
      <div
        id="class_come"
        className={`max-w-screen-yl mx-auto px-4 sm:px-5 pb-8 lg:pb-20`}
      >
        <div className="tracking-tight title flex flex-wrap justify-between">
          <p className="inline-flex flex-wrap content-center text-[#99AC69] sm:text-main gangwon text-2xl sm:text-3xl">
            <Link
              to="/classComeList"
              className="hover:underline inline-flex flex-wrap content-center"
            >
              <span className="align-middle">집으로 오다</span>
            </Link>
            <span className="hidden sm:inline-flex align-middle text-sm ml-3 flex-wrap content-center">
              <i className="fas fa-chevron-right"></i>
            </span>
            <img
              src={online}
              alt="온라인"
              className="hidden sm:inline-block  w-10 sm:w-16 ml-3 sm:ml-5"
            />
          </p>
          <button className="sm:hidden flex pb-2 md:pb-0 hover:underline text-text text-xs md:text-base flex-wrap content-end">
            <Link to="/classComeList">more</Link>
          </button>
        </div>
        <div className="hidden sm:flex flex-wrap justify-between">
          <p className="text-text pt-3 text-sm sm:text-base">
            내가 원하는 온라인 클래스를 DIY Kit로 집으로 편하게 들으세요!
          </p>
          <button className="hidden sm:flex pb-2 md:pb-0 hover:underline text-text text-xs md:text-base flex-wrap content-end">
            <Link to="/classComeList">more</Link>
          </button>
        </div>
        {data && data.length > 0 ? (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 sm:gap-4 sm:gap-y-10 sm:pt-5 pb-8 lg:pb-20">
            {data.map((product, index) => {
              if (index < 4)
                return (
                  <div key={`online-class-${product.id}`}>
                    <ComponentProductWidget
                      product={product}
                      refetch={refetch}
                    />
                  </div>
                );
            })}
          </div>
        ) : (
          <div className="py-10 text-center text-bold text-lg">
            조건에 맞는 강의가 없습니다.
          </div>
        )}
      </div>
      <div className="w-full relative">
        {getBannerOptionActiveData?.getBannerOptionActive.bannerOptions && (
          <a
            href={`${getBannerOptionActiveData.getBannerOptionActive.bannerOptions[4].link}`}
          >
            <img
              src={`${
                getBannerOptionActiveData.getBannerOptionActive.bannerOptions[4]
                  .view?.url
                  ? getBannerOptionActiveData.getBannerOptionActive
                      .bannerOptions[4].view.url
                  : `#`
              }`}
              alt="배너이미지"
              className="w-full max-w-screen-yl mx-auto relative sm:block hidden"
            />
            <img
              src={`${
                getBannerOptionActiveData.getBannerOptionActive.bannerOptions[4]
                  .subView?.url
                  ? getBannerOptionActiveData.getBannerOptionActive
                      .bannerOptions[4].subView?.url
                  : `#`
              }`}
              alt="배너이미지"
              className="w-full max-w-screen-yl mx-auto relative sm:hidden block"
            />
          </a>
        )}
      </div>
    </>
  );
};
