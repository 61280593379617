import React from "react";
import { gql, useLazyQuery, useQuery } from "@apollo/client";
import {
  getProductIntro,
  getProductIntroVariables,
} from "../__generated__/getProductIntro";

const GET_PRODUCT_INTRO_QUERY = gql`
  query getProductIntro($input: GetProductIntroInput!) {
    getProductIntro(input: $input) {
      ok
      err
      productIntro {
        id
        intro
      }
    }
  }
`;

export const useGetProductIntro = (
  onCompleted?: (data: getProductIntro) => void
) => {
  return useLazyQuery<getProductIntro, getProductIntroVariables>(
    GET_PRODUCT_INTRO_QUERY,
    {
      onCompleted,
      fetchPolicy: "network-only",
    }
  );
};
