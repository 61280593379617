import React, { useEffect } from "react";
import { createBrowserHistory } from "history";
import { useParams } from "react-router";
import {
  ListBannerOptionInput,
  ProductType,
} from "../__generated__/globalTypes";
import { UpBannerOptionForm4_1 } from "../components/up-banner-option-form-4-1";
import { useListBannerOption } from "../hooks/useListBannerOption";
import { listBannerOption } from "../__generated__/listBannerOption";
import { UpBannerOptionForm4_3 } from "../components/up-banner-option-form-4-3";

export const SubAdminUpBannerPlace: React.FC = () => {
  const history = createBrowserHistory();
  const params = useParams();

  const [listBannerOption, { data, loading, refetch }] = useListBannerOption();
  const listBannerOptionHandler = (bannerId: number) => {
    const listBannerOptionInput: ListBannerOptionInput = {
      bannerId,
    };
    listBannerOption({ variables: { input: listBannerOptionInput } });
  };

  useEffect(() => {
    if (params.bannerId) {
      listBannerOptionHandler(+params.bannerId);
    }
  }, []);
  return (
    <>
      <div className="bg-white p-5 rounded-2xl shadow-lg">
        <div className="p-5 space-y-5 lg:space-y-10">
          <div className="space-y-3">
            <div className="w-full grid grid-cols-1 gap-3">
              {loading && <div>정보를 불러오는 중입니다</div>}
              {!loading &&
                data?.listBannerOption.bannerOptions &&
                data.listBannerOption.bannerOptions.length > 0 && (
                  <>
                    <UpBannerOptionForm4_1
                      productType={null}
                      bannerOptionId={
                        data?.listBannerOption.bannerOptions
                          ? Number(data?.listBannerOption.bannerOptions[8].id)
                          : 0
                      }
                      link={`${
                        data?.listBannerOption.bannerOptions &&
                        data?.listBannerOption.bannerOptions[8].link
                      }`}
                      bannerTitle={`플레이스 배너`}
                      posterUrl={
                        data?.listBannerOption.bannerOptions
                          ? data.listBannerOption.bannerOptions[8].view?.url
                          : ``
                      }
                      refetch={refetch}
                    />
                    <UpBannerOptionForm4_3
                      productType={ProductType.Place}
                      bannerOptionId={
                        data?.listBannerOption.bannerOptions
                          ? Number(data?.listBannerOption.bannerOptions[9].id)
                          : 0
                      }
                      link={`${
                        data?.listBannerOption.bannerOptions &&
                        data?.listBannerOption.bannerOptions[9].link
                      }`}
                      bannerTitle={`플레이스 1`}
                      posterUrl={
                        data.listBannerOption.bannerOptions[9].product
                          ?.productViews &&
                        data?.listBannerOption.bannerOptions[9].product
                          ?.productViews.length > 0
                          ? data.listBannerOption.bannerOptions[9].product?.productViews?.filter(
                              (productView) => productView.is_rep
                            )[0].view?.url
                          : ``
                      }
                      productTitle={
                        data.listBannerOption.bannerOptions[9].product
                          ?.productInfo?.title
                      }
                      productId={
                        data.listBannerOption.bannerOptions[9].product?.id
                      }
                      instName={
                        data.listBannerOption.bannerOptions[9].product?.inst
                          .user?.name
                      }
                      refetch={refetch}
                    />
                    <UpBannerOptionForm4_3
                      productType={ProductType.Place}
                      bannerOptionId={
                        data?.listBannerOption.bannerOptions
                          ? Number(data?.listBannerOption.bannerOptions[10].id)
                          : 0
                      }
                      link={`${
                        data?.listBannerOption.bannerOptions &&
                        data?.listBannerOption.bannerOptions[10].link
                      }`}
                      bannerTitle={`플레이스 2`}
                      posterUrl={
                        data.listBannerOption.bannerOptions[10].product
                          ?.productViews &&
                        data?.listBannerOption.bannerOptions[10].product
                          ?.productViews.length > 0
                          ? data.listBannerOption.bannerOptions[10].product?.productViews?.filter(
                              (productView) => productView.is_rep
                            )[0].view?.url
                          : ``
                      }
                      productTitle={
                        data.listBannerOption.bannerOptions[10].product
                          ?.productInfo?.title
                      }
                      productId={
                        data.listBannerOption.bannerOptions[10].product?.id
                      }
                      instName={
                        data.listBannerOption.bannerOptions[10].product?.inst
                          .user?.name
                      }
                      refetch={refetch}
                    />
                    <UpBannerOptionForm4_3
                      productType={ProductType.Place}
                      bannerOptionId={
                        data?.listBannerOption.bannerOptions
                          ? Number(data?.listBannerOption.bannerOptions[11].id)
                          : 0
                      }
                      link={`${
                        data?.listBannerOption.bannerOptions &&
                        data?.listBannerOption.bannerOptions[11].link
                      }`}
                      bannerTitle={`플레이스 3`}
                      posterUrl={
                        data.listBannerOption.bannerOptions[11].product
                          ?.productViews &&
                        data?.listBannerOption.bannerOptions[11].product
                          ?.productViews.length > 0
                          ? data.listBannerOption.bannerOptions[11].product?.productViews?.filter(
                              (productView) => productView.is_rep
                            )[0].view?.url
                          : ``
                      }
                      productTitle={
                        data.listBannerOption.bannerOptions[11].product
                          ?.productInfo?.title
                      }
                      productId={
                        data.listBannerOption.bannerOptions[11].product?.id
                      }
                      instName={
                        data.listBannerOption.bannerOptions[11].product?.inst
                          .user?.name
                      }
                      refetch={refetch}
                    />
                    <UpBannerOptionForm4_3
                      productType={ProductType.Place}
                      bannerOptionId={
                        data?.listBannerOption.bannerOptions
                          ? Number(data?.listBannerOption.bannerOptions[12].id)
                          : 0
                      }
                      link={`${
                        data?.listBannerOption.bannerOptions &&
                        data?.listBannerOption.bannerOptions[12].link
                      }`}
                      bannerTitle={`플레이스 4`}
                      posterUrl={
                        data.listBannerOption.bannerOptions[12].product
                          ?.productViews &&
                        data?.listBannerOption.bannerOptions[12].product
                          ?.productViews.length > 0
                          ? data.listBannerOption.bannerOptions[12].product?.productViews?.filter(
                              (productView) => productView.is_rep
                            )[0].view?.url
                          : ``
                      }
                      productTitle={
                        data.listBannerOption.bannerOptions[12].product
                          ?.productInfo?.title
                      }
                      productId={
                        data.listBannerOption.bannerOptions[12].product?.id
                      }
                      instName={
                        data.listBannerOption.bannerOptions[12].product?.inst
                          .user?.name
                      }
                      refetch={refetch}
                    />
                  </>
                )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
