import { ApolloError, gql, useLazyQuery, useMutation } from "@apollo/client";
import React from "react";
import {
  getPromotion,
  getPromotionVariables,
} from "../__generated__/getPromotion";

const GET_PROMOTION_MUTATION = gql`
  query getPromotion($input: GetPromotionInput!) {
    getPromotion(input: $input) {
      ok
      err
      promotion {
        id
        title
        badge
        badgeColor
        desc
        discountRate
        is_active
        startAt
        endAt
        author {
          name
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const useGetPromotion = (
  onCompleted?: (data: getPromotion) => void,
  onError?: (error: ApolloError) => void
) => {
  return useLazyQuery<getPromotion, getPromotionVariables>(
    GET_PROMOTION_MUTATION,
    {
      onCompleted,
      onError,
      fetchPolicy: "network-only",
    }
  );
};
