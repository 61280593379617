import profile from "../../assets/icon/profile.svg";
import list from "../../assets/icon/dashboard.svg";
import lecture from "../../assets/icon/lecture.svg";
import change from "../../assets/icon/change.svg";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useReactiveVar } from "@apollo/client";
import { pageIndicator } from "../../apollo";
import { useMe } from "../../hooks/useMe";
export const ASIDE_Q = () => {
  const { data } = useMe();
  const usePageIndicator = useReactiveVar(pageIndicator);
  return (
    <div className="pt-20">
      <div className="consoleBox pt-3 border-t-2 border-[#C9CACA]">
        <div className="profile text-center">
          <img src={profile} alt="프로필 사진" className="w-16 inline-block" />
          <p className="name pt-2">{data?.me.name}</p>
        </div>
        <div className="pt-5">
          <ul>
            <li className="border-b-2 border-[#C9CACA]">
              <Link
                to={`/curator/`}
                className={`inline-block py-2 w-full h-full text-left hover:bg-gray-100 px-16 relative ${
                  usePageIndicator === "dashboard" && "bg-gray-100"
                }`}
              >
                <img
                  src={list}
                  alt="list 아이콘"
                  className="inline-block mr-2 w-6"
                />
                컨설팅목록
              </Link>
            </li>
            <li className="border-b-2 border-[#C9CACA]">
              <Link
                to={`/curator/insts`}
                className={`inline-block py-2 w-full h-full text-left hover:bg-gray-100 px-16 ${
                  usePageIndicator === "insts" && "bg-gray-100"
                }`}
              >
                <img
                  src={lecture}
                  alt="강의 관리"
                  className="inline-block mr-2 w-6"
                />
                작가 관리
              </Link>
            </li>
            <li className="border-b-2 border-[#C9CACA]">
              <Link
                to={`/curator/profiles`}
                className={`inline-block py-2 w-full h-full text-left hover:bg-gray-100 px-16 ${
                  usePageIndicator === "profiles" && "bg-gray-100"
                }`}
              >
                <img
                  src={change}
                  alt="개인정보 아이콘"
                  className="inline-block mr-2 w-6"
                />
                개인정보수정
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
