import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button } from "../../../../../components/button";
import { FormError2 } from "../../../../../components/form-error";
import {
  LocalCategoryOption,
  LocalCategoryOptionType,
  ProductCategoryOption,
  ProductCategoryOptionType,
} from "../../../../../constants/enum";
import {
  ICrProductFormProps,
  useCrProduct,
} from "../../../../../hooks/useCrProduct";
import { useMe } from "../../../../../hooks/useMe";
import { crProduct } from "../../../../../__generated__/crProduct";
import {
  CrProductInput,
  ProductCategory,
  ProductType,
} from "../../../../../__generated__/globalTypes";

export const Info = () => {
  const [onoff, setOnOff] = useState(0);

  const { data: dataMe } = useMe();

  const {
    register,
    handleSubmit,
    getValues,
    setFocus,
    setValue,
    formState: { errors, isValid },
  } = useForm<ICrProductFormProps>({
    mode: "onChange",
    defaultValues: {
      productType: ProductType.Online,
      discountRate: 1,
    },
  });

  const onCompleted = async (data: crProduct) => {
    const {
      crProduct: { ok, err },
    } = data;
    if (ok) {
      window.location.href = `/instPage/enroll/${data.crProduct.productId}`;
    }
  };

  const [crProduct, { data: crProductResult, loading }] =
    useCrProduct(onCompleted);

  const onSubmit = () => {
    const {
      title,
      price,
      discountRate,
      scheduledAt,
      productType,
      summary,
      productCategory,
      place,
      max,
      playTime,
      materials,
      localCategory,
    } = getValues();
    const crProductInput: CrProductInput = {
      title,
      price: price && +price,
      discountRate: discountRate ? +discountRate : 1,
      productType,
      materials,
      localCategory,
      ...(Boolean(scheduledAt) && { scheduledAt }),
      ...(Boolean(summary) && { summary }),
      ...(Boolean(productCategory) && { productCategory }),
      ...(Boolean(place) && { place }),
      ...(Boolean(max) && { max: max ? +max : 99 }),
      ...(Boolean(playTime) && { playTime }),
    };
    crProduct({
      variables: {
        input: crProductInput,
      },
    });
  };

  const onInvalid = () => {};

  useEffect(() => {
    setFocus("title");
  }, []);

  return (
    <div className="bg-white">
      <form onSubmit={handleSubmit(onSubmit, onInvalid)}>
        <div className=" p-4 lg:p-10 space-y-5 lg:space-y-10 max-w-xl pb-28">
          <div className="space-y-3">
            <p className="font-bold text-main lg:text-base text-sm">강의명</p>
            <input
              {...register("title", { required: `타이틀을 입력해주세요` })}
              type="text"
              className="border border-grays rounded-xl py-1 px-2 w-full text-sm lg:text-base"
            />
            {errors.title?.message && (
              <FormError2 errorMessage={errors.title.message} />
            )}
          </div>

          <div className="space-y-3">
            <p className="font-bold text-main lg:text-base text-sm">가격</p>
            <input
              {...register("price", { required: `가격을 입력해주세요` })}
              type="number"
              className="border border-grays rounded-xl py-1 px-2 w-full text-sm lg:text-base"
            />
            {errors.price?.message && (
              <FormError2 errorMessage={errors.price.message} />
            )}
          </div>

          <div className="space-y-3">
            <p className="font-bold text-main lg:text-base text-sm">할인율</p>
            <input
              {...register("discountRate", {
                required: `가격을 입력해주세요`,
                max: { value: 100, message: `최댓값은 100입니다` },
                min: { value: 0, message: `최소값은 0입니다` },
              })}
              type="number"
              className="border border-grays rounded-xl py-1 px-2 w-full text-sm lg:text-base"
              defaultValue={1}
            />
            {errors.discountRate?.message && (
              <FormError2 errorMessage={errors.discountRate.message} />
            )}
          </div>

          <div className="space-y-3">
            <p className="font-bold text-main lg:text-base text-sm">
              수업 카테고리
            </p>
            <select
              {...register(`productCategory`)}
              className="border border-grays rounded-xl py-2 px-2 w-full text-sm lg:text-base"
            >
              {Object(ProductCategoryOption).map(
                (productCategory: ProductCategoryOptionType, index: number) => (
                  <option
                    key={`product-category-option-${index}`}
                    value={productCategory.value}
                  >
                    {productCategory.label}
                  </option>
                )
              )}
            </select>
            {errors.productCategory?.message && (
              <FormError2 errorMessage={errors.productCategory.message} />
            )}
          </div>

          <div className="space-y-3">
            <p className="font-bold text-main lg:text-base text-sm">준비물</p>
            <input
              type="text"
              {...register("materials", {
                required: `준비물은 필수 정보입니다.`,
              })}
              className="border border-grays rounded-xl py-1 px-2 w-full text-sm lg:text-base"
            />
            {errors.materials?.message && (
              <FormError2 errorMessage={errors.materials.message} />
            )}
          </div>

          <div className="space-y-3">
            <p className="font-bold text-main lg:text-base text-sm">지역</p>
            <select
              {...register("localCategory")}
              className="inline-block py-1 rounded-none border-b border-[#F1F2EF] focus:outline-none pl-1 leading-normal w-full text-xs lg:text-base"
            >
              {Object(LocalCategoryOption).map(
                (localCategory: LocalCategoryOptionType, index: number) => (
                  <option
                    key={`local-category-options-${index}`}
                    value={localCategory.value}
                  >
                    {localCategory.label}
                  </option>
                )
              )}
            </select>
          </div>

          <div className="space-y-3">
            <p className="font-bold text-main lg:text-base text-sm">수업방식</p>
            <div>
              <label className="inline-block align-middle w-1/2">
                <div
                  className={`inline-flex flex-wrap content-center justify-center align-middle w-5 h-5 border-2 border-grays rounded-full ${
                    onoff === 0 && "border-main"
                  }`}
                >
                  {onoff === 0 && (
                    <span className="block w-3 h-3 rounded-full bg-main"></span>
                  )}
                </div>
                <input
                  {...register("productType")}
                  type="radio"
                  className="hidden"
                  value={ProductType.Online}
                  onChange={(e: any) => {
                    setOnOff(0);
                    setValue(`productType`, ProductType.Online);
                  }}
                />
                <span className="ml-1 text-sm font-bold">온라인</span>
              </label>
              <label className="inline-block align-middle w-1/2">
                <div
                  className={`inline-flex flex-wrap content-center justify-center align-middle w-5 h-5 border-2 border-grays rounded-full ${
                    onoff === 1 && "border-main"
                  }`}
                >
                  {onoff === 1 && (
                    <span className="block w-3 h-3 rounded-full bg-main"></span>
                  )}
                </div>
                <input
                  {...register("productType")}
                  type="radio"
                  className="hidden"
                  value={ProductType.Offline}
                  onChange={(e: any) => {
                    setOnOff(1);
                    setValue(`productType`, ProductType.Offline);
                  }}
                />
                <span className="ml-1 text-sm font-bold">오프라인</span>
              </label>
            </div>
          </div>

          {onoff === 1 && (
            <div className="space-y-3">
              <p className="font-bold text-main lg:text-base text-sm">
                진행일자
              </p>
              <input
                type="date"
                {...register("scheduledAt")}
                max="9999-12-31"
                className="border border-grays rounded-xl py-1 px-2 w-full text-sm lg:text-base"
              />
            </div>
          )}

          {onoff === 1 && (
            <div className="space-y-3">
              <p className="font-bold text-main lg:text-base text-sm">
                진행장소
              </p>
              <input
                {...register("place", { required: `타이틀을 입력해주세요` })}
                type="text"
                className="border border-grays rounded-xl py-1 px-2 w-full text-sm lg:text-base"
              />
              {errors.place?.message && (
                <FormError2 errorMessage={errors.place.message} />
              )}
            </div>
          )}

          {onoff === 1 && (
            <div className="space-y-3">
              <p className="font-bold text-main lg:text-base text-sm">
                초대인원
              </p>
              <input
                {...register("max", {
                  required: `최대 참석 인원을 입력해주세요`,
                })}
                type="number"
                className="border border-grays rounded-xl py-1 px-2 w-full text-sm lg:text-base"
                defaultValue={0}
              />
              {errors.max?.message && (
                <FormError2 errorMessage={errors.max.message} />
              )}
            </div>
          )}

          {onoff === 1 && (
            <div className="space-y-3">
              <p className="font-bold text-main lg:text-base text-sm">
                진행시간
              </p>
              <input
                type="text"
                {...register("playTime", {
                  required: `진행시간은 필수 정보입니다.`,
                })}
                className="border border-grays rounded-xl py-1 px-2 w-full text-sm lg:text-base"
              />
              {errors.playTime?.message && (
                <FormError2 errorMessage={errors.playTime.message} />
              )}
            </div>
          )}

          <div className="space-y-3 relative">
            <p className="font-bold text-main lg:text-base text-sm">요약</p>
            <textarea
              {...register("summary", { required: `타이틀을 입력해주세요` })}
              className="border border-grays rounded-xl py-1 px-2 w-full text-sm lg:text-base min-h-[200px] "
            ></textarea>
            {errors.summary?.message && (
              <FormError2 errorMessage={errors.summary.message} />
            )}
          </div>

          <div className="w-full left-0 bottom-16 p-4 sm:px-0 fixed sm:static text-center sm:py-10 space-x-5 bg-white sm:bg-transparent">
            <button
              onClick={() => {
                window.location.reload();
              }}
              type="button"
              className="rounded-md border border-grays w-2/5 sm:w-[150px] py-1 hover:opacity-60 text-sm lg:text-base "
            >
              취소
            </button>
            <Button
              className={`rounded-md text-white w-2/5 sm:w-[150px] py-1 hover:opacity-60 text-sm lg:text-base bg-main border border-main`}
              canClick={isValid}
              actionText={`저장`}
              loading={loading}
            />
          </div>
        </div>
      </form>
    </div>
  );
};
