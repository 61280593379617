import React from "react";
import gql from "graphql-tag";
import { ApolloError, useMutation } from "@apollo/client";
import { RegistCorpInput } from "../__generated__/globalTypes";
import { registCorp, registCorpVariables } from "../__generated__/registCorp";

export interface IRegistCorpFormProps extends RegistCorpInput {
  confirmPassword: string;
}

const USE_REGIST_CORP_MUTATION = gql`
  mutation registCorp($input: RegistCorpInput!) {
    registCorp(input: $input) {
      ok
      err
    }
  }
`;

export const useRegistCorp = (
  onCompleted?: (data: registCorp) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<registCorp, registCorpVariables>(
    USE_REGIST_CORP_MUTATION,
    {
      onCompleted,
      onError,
    }
  );
};
