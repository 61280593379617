import { ApolloError, gql, useMutation } from "@apollo/client";
import React from "react";
import { crRefund, crRefundVariables } from "../__generated__/crRefund";
import { CrRefundInput } from "../__generated__/globalTypes";

export interface ICrRefundFormProps extends CrRefundInput {}

const CR_REFUND_MUTATION = gql`
  mutation crRefund($input: CrRefundInput!) {
    crRefund(input: $input) {
      ok
      err
    }
  }
`;

export const useCrRefund = (
  onCompleted?: (data: crRefund) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<crRefund, crRefundVariables>(CR_REFUND_MUTATION, {
    onCompleted,
    onError,
  });
};
