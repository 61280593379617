import React from "react";
import { gql, useLazyQuery } from "@apollo/client";
import {
  listConsultReply,
  listConsultReplyVariables,
} from "../__generated__/listConsultReply";

const LIST_CONSULT_REPLY_QUERY = gql`
  query listConsultReply($input: ListConsultReplyInput!) {
    listConsultReply(input: $input) {
      ok
      err
      consultReplies {
        id
        note
        author {
          id
          name
        }
        updatedAt
        createdAt
      }
    }
  }
`;

export const useListConsultReply = (
  onCompleted?: (data: listConsultReply) => void
) => {
  return useLazyQuery<listConsultReply, listConsultReplyVariables>(
    LIST_CONSULT_REPLY_QUERY,
    {
      onCompleted,
      fetchPolicy: "network-only",
    }
  );
};
