import { ApolloError, gql, useLazyQuery } from "@apollo/client";
import React from "react";
import { getBanner, getBannerVariables } from "../__generated__/getBanner";

const GET_BANNER_MUTATION = gql`
  query getBanner($input: GetBannerInput!) {
    getBanner(input: $input) {
      ok
      err
      banner {
        id
        title
        is_active
        author {
          name
        }
        updatedAt
        createdAt
      }
    }
  }
`;

export const useGetBanner = (
  onCompleted?: (data: getBanner) => void,
  onError?: (error: ApolloError) => void
) => {
  return useLazyQuery<getBanner, getBannerVariables>(GET_BANNER_MUTATION, {
    onCompleted,
    onError,
    fetchPolicy: "network-only",
  });
};
