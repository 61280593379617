import React from "react";
import { gql, useLazyQuery } from "@apollo/client";
import {
  listClassInactive,
  listClassInactiveVariables,
} from "../__generated__/listClassInactive";

const LIST_CLASS_INACTIVE_QUERY = gql`
  query listClassInactive($input: ListClassInactiveInput!) {
    listClassInactive(input: $input) {
      ok
      err
      totalPages
      totalResults
      row
      classes {
        id
        productInfo {
          scheduledAt
          title
          price
        }
        inst {
          id
          atelier {
            name
          }
          user {
            name
          }
        }
        createdAt
        productStatus
        productType
      }
    }
  }
`;

export const useListClassInactive = (
  onCompleted?: (data: listClassInactive) => void
) => {
  return useLazyQuery<listClassInactive, listClassInactiveVariables>(
    LIST_CLASS_INACTIVE_QUERY,
    {
      onCompleted,
      fetchPolicy: `network-only`,
    }
  );
};
