import top from "../assets/icon/top.svg";
import top_line from "../assets/icon/top_line.svg";
export const ToTheTop = (props: any) => {
  return (
    <button
      onClick={() => {
        window.scrollTo(0, 0);
      }}
      className={`fixed z-10 ${props.posi} w-12 h-12 rounded-full`}
    >
      {props.white ? (
        <img src={top_line} alt="위로 가기" className="w-full rounded-full" />
      ) : (
        <img src={top} alt="위로 가기" className="w-full rounded-full" />
      )}
    </button>
  );
};
