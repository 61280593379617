import { ApolloError, gql, useMutation } from "@apollo/client";
import React from "react";
import {
  canWriteProductReview,
  canWriteProductReviewVariables,
} from "../__generated__/canWriteProductReview";
import { UpConsultInput } from "../__generated__/globalTypes";

export interface IUpConsultFormProps extends UpConsultInput {}

const CAN_WRITE_PRODUCT_REVIEW = gql`
  mutation canWriteProductReview($input: CanWriteProductReviewInput!) {
    canWriteProductReview(input: $input) {
      ok
      err
    }
  }
`;

export const useCanWriteProductReview = (
  onCompleted?: (data: canWriteProductReview) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<canWriteProductReview, canWriteProductReviewVariables>(
    CAN_WRITE_PRODUCT_REVIEW,
    {
      onCompleted,
      onError,
    }
  );
};
