import React, { useEffect, useState } from "react";
import {
  CrPromotionProductInput,
  ListMagazineInput,
} from "../__generated__/globalTypes";
import searchImg from "../assets/icon/search.png";
import { ISearchFormProps } from "../routes/admin-list-magazine";
import { useForm } from "react-hook-form";
import { TextButton } from "./button";
import { TableError } from "./table-error";
import { PaginationComponent } from "./pagination";
import { useListClass } from "../hooks/useListClass";
import { crPromotionProduct } from "../__generated__/crPromotionProduct";
import { useCrPromotionProduct } from "../hooks/useCrPromotionProduct";

interface IModalListClassProps {
  promotionId: number;
  onClose: () => void;
}

export const ModalListClass: React.FC<IModalListClassProps> = ({
  promotionId,
  onClose,
}) => {
  const [page, setPage] = useState<number>(1);
  const [search, setSearch] = useState<string>("");

  const pageHandler = (page: number) => {
    setPage(page);
    listClassHandler(page, search);
  };

  const { register, getValues, handleSubmit } = useForm<ISearchFormProps>();

  const onSearch = async () => {
    const { search } = getValues();
    if (search) setSearch(search);
    listClassHandler(page, search);
  };

  const [listClass, { data, loading, refetch }] = useListClass();
  const listClassHandler = (page: number, search: string) => {
    const input: ListMagazineInput = {
      page,
      search,
    };
    listClass({ variables: { input } });
  };

  const onCrComplete = async (data: crPromotionProduct) => {
    const {
      crPromotionProduct: { ok, err },
    } = data;
    if (ok) {
      alert("저장되었습니다");
      onClose();
    } else {
      alert(err);
    }
  };
  const [crPromotionProduct, { loading: crPromotionProductLoading }] =
    useCrPromotionProduct(onCrComplete);
  const onSelect = (productId: number) => {
    const input: CrPromotionProductInput = {
      productId,
      promotionId,
    };
    crPromotionProduct({ variables: { input } });
  };

  useEffect(() => {
    listClassHandler(page, search);
  }, []);

  return (
    <div className="modal">
      <div className="text-right pb-8">
        <form onSubmit={handleSubmit(onSearch)}>
          <p className="inline-block max-w-md w-full text-right relative shadow-md">
            <input
              {...register(`search`)}
              type="text"
              className="w-full py-1 px-4 bg-white rounded-full"
              placeholder="작가, 클래스 검색"
            />
            <button className="absolute right-3 top-1">
              <img src={searchImg} alt="찾기" className="w-5" />
            </button>
          </p>
        </form>
      </div>
      {/* 여기 */}
      <div className="bg-white px-5 pb-5 rounded-md shadow-lg min-w-max">
        <p className="text-main font-bold pb-3">클래스 목록</p>
        <table className="w-full">
          <colgroup>
            <col className="w-1/12" />
            <col className="w-3/12" />
            <col className="w-5/12" />
            <col className="w-3/12" />
          </colgroup>
          <thead>
            <tr className="bg-main  border-gray-300">
              <th className="text-white ">번호</th>
              <th className="text-white ">클래스</th>
              <th className="text-white ">작가</th>
              <th className="text-white ">기능</th>
            </tr>
          </thead>
          <tbody>
            {!loading &&
              data?.listClass.products &&
              data.listClass.products.map((product) => (
                <tr
                  key={`magazine-${product?.id}`}
                  className="border-b-2 border-gray-300 h-4"
                >
                  <td className="text-sm text-center py-1 px-1 h-full overflow-hidden">
                    {product.id && `${product.id}`}
                  </td>
                  <td className="text-sm text-center h-full relative">
                    {product.productInfo?.title &&
                      `${product.productInfo.title}`}
                  </td>
                  <td className="text-sm text-center py-1 px-1 h-full overflow-hidden">
                    {product.inst.atelier?.name &&
                      `${product.inst.atelier.name}`}
                  </td>
                  <td className="text-sm text-center py-1 px-1 h-full w-1/6 overflow-hidden">
                    <TextButton
                      canClick={
                        !crPromotionProductLoading &&
                        !Boolean(
                          product.promotionProducts?.filter(
                            (promotionProduct) =>
                              promotionProduct.promotion?.id === promotionId
                          ).length
                        )
                      }
                      actionText={`선택`}
                      onClick={() => onSelect(product.id)}
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        {loading && <TableError errorMessage={`정보를 불러오는 중입니다`} />}
        {!loading &&
          data?.listClass.products &&
          data.listClass.products.length < 1 && (
            <TableError errorMessage={`해당하는 강의 정보가 없습니다`} />
          )}
        {!loading &&
          data?.listClass.products &&
          data.listClass.products.length > 0 && (
            <PaginationComponent
              page={page}
              totalPages={Number(data.listClass.totalPages)}
              totalResults={Number(data.listClass.totalResults)}
              pageHandler={pageHandler}
            />
          )}
      </div>
    </div>
  );
};
