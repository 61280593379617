import { useState, useEffect } from "react";
import { useParams } from "react-router";
import { useCrProductThemeCurr } from "../../../../../hooks/useCrProductThemeCurr";
import { useListProductThemeCurr } from "../../../../../hooks/uselistProductThemeCurr";
import { useUpProductThemeCurr } from "../../../../../hooks/useUpProductThemeCurr";
import { crProductThemeCurr } from "../../../../../__generated__/crProductThemeCurr";
import { delProductThemeCurr } from "../../../../../__generated__/delProductThemeCurr";
import {
  CrProductThemeCurrInput,
  ListProductThemeCurrInput,
} from "../../../../../__generated__/globalTypes";
import { listProductThemeCurr } from "../../../../../__generated__/listProductThemeCurr";
import { upProductThemeCurr } from "../../../../../__generated__/upProductThemeCurr";
import { ProductThemeCurrForm } from "./currParts/currUpForm";
export const Curriculum = () => {
  const params = useParams();

  const onListCompleted = async (data: listProductThemeCurr) => {};

  const onCrCompleted = async (data: crProductThemeCurr) => {
    const {
      crProductThemeCurr: { ok },
    } = data;
    if (ok) {
      await listRefetch();
    }
  };

  const onUpCompleted = async (data: upProductThemeCurr) => {
    const {
      upProductThemeCurr: { ok },
    } = data;
    if (ok) {
      await listRefetch();
      alert("강의가 수정되었습니다");
    }
  };

  const onDelCompleted = async (data: delProductThemeCurr) => {
    const {
      delProductThemeCurr: { ok },
    } = data;
    if (ok) {
      await listRefetch();
    }
  };

  const [
    listProductThemeCurr,
    {
      data: listProductThemeCurrData,
      loading: listLoading,
      refetch: listRefetch,
    },
  ] = useListProductThemeCurr(onListCompleted);
  const [crProductThemeCurr, { loading: crProductThemeCurrLoading }] =
    useCrProductThemeCurr(onCrCompleted);

  const crProductThemeCurrHandler = () => {
    if (!crProductThemeCurrLoading && params.key) {
      const crProductThemeCurrinput: CrProductThemeCurrInput = {
        productId: +params.key,
      };
      crProductThemeCurr({ variables: { input: crProductThemeCurrinput } });
    }
  };

  useEffect(() => {
    if (params.key) {
      const listProductThemeCurrInput: ListProductThemeCurrInput = {
        productId: +params.key,
      };
      listProductThemeCurr({ variables: { input: listProductThemeCurrInput } });
    }
  }, []);

  return (
    <div className="bg-white p-3 lg:p-10">
      <table className="w-full">
        <thead>
          <tr className="bg-main text-white">
            <th className="w-[5%]"></th>
            <th className="text-sm lg:text-base">No.</th>
            <th className="text-center text-sm lg:text-base">강의명</th>
            <th className="py-1 max-w-[200px]">
              <button
                className="font-bold text-main text-xs lg:text-base border border-grays p-1 rounded-md bg-[#F1ECE8]"
                onClick={crProductThemeCurrHandler}
              >
                회차추가+
              </button>
            </th>
          </tr>
        </thead>
        <tbody>
          {!listLoading &&
            listProductThemeCurrData?.listProductThemeCurr.productThemeCurrs &&
            listProductThemeCurrData.listProductThemeCurr.productThemeCurrs.map(
              (productThemeCurr) => (
                <ProductThemeCurrForm
                  key={`product-curr-${productThemeCurr.id}`}
                  index={productThemeCurr.index ? productThemeCurr.index : 0}
                  title={productThemeCurr.title ? productThemeCurr.title : ""}
                  currId={productThemeCurr.id && productThemeCurr.id}
                  filename={productThemeCurr.video?.filename}
                  videoUuid={productThemeCurr.video?.uuid}
                  onUpCompleted={onUpCompleted}
                  onDelCompleted={onDelCompleted}
                />
              )
            )}
        </tbody>
      </table>
      {/* <div className="text-center pt-10 space-x-5">
        <button className="rounded-md border border-grays w-2/5 sm:w-[150px] py-1  text-sm lg:text-base">
          취소
        </button>
        <button className="rounded-md border border-main w-2/5 sm:w-[150px] py-1  text-sm lg:text-base bg-main text-white  ">
          저장
        </button>
      </div> */}
    </div>
  );
};
