import { Footer } from "../layout/footer";
import { LayoutTop } from "../layout/top";
import search from "../assets/icon/search.svg";
import { createBrowserHistory } from "history";
import { useState, useEffect } from "react";
import place from "../assets/icon/local_place.svg";
import magazine from "../assets/icon/px4_magazine.svg";
import come from "../assets/icon/come_to_home.svg";
import good from "../assets/icon/heart.svg";
import offline from "../assets/icon/off.svg";
import online from "../assets/icon/on.svg";
import notFInd from "../assets/icon/notFInd.svg";
import {
  LocalCategoryOption,
  LocalCategoryOptionType,
  ProductCategoryOption,
  ProductCategoryOptionType,
} from "../constants/enum";
import go from "../assets/icon/go_to_region.svg";
import {
  ListProductInput,
  ListProductMainInput,
} from "../__generated__/globalTypes";
import { useListProductMain } from "../hooks/useListProductMain";
import { calculate } from "../apollo";
import { Link, useLocation } from "react-router-dom";
import { useListProduct } from "../hooks/useListProduct";
import { listProduct } from "../__generated__/listProduct";
import { useForm } from "react-hook-form";
import { ComponentProductWidget } from "../components/component-product";

export interface ISearchFormProps {
  search: string;
}

export const Search = () => {
  // front action
  const [find, setFind] = useState(false);
  const [words, setWords] = useState("");
  const keywordHandler = (str: string) => {
    let input = document.getElementById("searchClasses") as HTMLInputElement;
    input.focus();
    input.value = str;
    setWords(str);
  };
  // data
  const history = createBrowserHistory();

  const { register, getValues, handleSubmit } = useForm<ISearchFormProps>({
    mode: "all",
  });
  const onSearch = async () => {
    const search = (
      document.getElementById("searchClasses") as HTMLInputElement
    ).value;
    history.push({
      pathname: "/search",
      search: `${search ? `?search=${search}` : ""}`,
    });
    ListProductHandler(search);
  };
  const [listProduct, { data, loading, refetch }] = useListProduct();
  const ListProductHandler = (search: string | null) => {
    const listProductInput: ListProductInput = {
      ...(search && { search }),
    };
    listProduct({ variables: { input: listProductInput } });
  };
  useEffect(() => {
    let search = (document.getElementById("searchClasses") as HTMLInputElement)
      .value;
    ListProductHandler(search);
  }, []);
  return (
    <div className="contentWrap">
      <LayoutTop title={"nothing"} />
      <main id="main">
        <section className="pt-28 lg:pt-52 max-w-screen-yl mx-auto px-4 md:px-0 pb-20">
          <h2 className="text-[#6d6c6c] text-xl text-center font-bold keep-all">
            원하는 로컬픽 클래스를 검색해보세요!
          </h2>
          <div className="searchBox pt-4 max-w-2xl mx-auto">
            <div className="searchBar relative py-2 pl-4 pr-12 bg-main">
              <form onSubmit={handleSubmit(onSearch)}>
                <input
                  type="text"
                  id="searchClasses"
                  onChange={(e) => {
                    setWords(e.target.value);
                  }}
                  name="searchClasses"
                  className="bg-white py-1 px-3 text-text text-lg w-full focus:outline-none"
                  placeholder={`검색창에 "키워드"를 검색해보세요.`}
                />
                <button
                  onClick={() => {
                    setFind(true);
                    let input = document.getElementById(
                      "searchClasses"
                    ) as HTMLInputElement;
                    setWords(input.value);
                  }}
                  className="absolute w-10 h-10 right-1 top-1/2 transform -translate-y-1/2"
                >
                  <img
                    src={search}
                    alt="검색하기"
                    className="w-6 inline-block"
                  />
                </button>
              </form>
            </div>
            <p className="keywords pt-4 pl-2 text-[grays] text-sm md:text-base keep-all">
              ex)
              <span
                onClick={() => keywordHandler("드로잉")}
                className="hover:underline cursor-pointer"
              >
                드로잉
              </span>
              ,{" "}
              <span
                onClick={() => keywordHandler("취미")}
                className="hover:underline cursor-pointer"
              >
                취미
              </span>
              ,{" "}
              <span
                onClick={() => keywordHandler("향수")}
                className="hover:underline cursor-pointer"
              >
                향수
              </span>
              ,{" "}
              <span
                onClick={() => keywordHandler("만들기")}
                className="hover:underline cursor-pointer"
              >
                만들기
              </span>
              ,{" "}
              <span
                onClick={() => keywordHandler("색칠하기")}
                className="hover:underline cursor-pointer"
              >
                색칠하기
              </span>
              ,{" "}
              <span
                onClick={() => keywordHandler("취미")}
                className="hover:underline cursor-pointer"
              >
                취미
              </span>
              ,{" "}
              <span
                onClick={() => keywordHandler("전문가")}
                className="hover:underline cursor-pointer"
              >
                전문가
              </span>
              , 등등
            </p>
          </div>
          <div className="otherPages pt-20 flex flex-wrap justify-center gap-x-4 md:gap-x-20">
            <a
              href="/classGoList"
              className="hover:opacity-60 text-center w-[60px] sm:w-[70px]"
            >
              <img
                src={go}
                alt="집에 나와 걸어가는 인간 픽토그램"
                className="w-full aspect-square inline-block"
              />
              <p className="text-xs sm:text-sm keep-all">
                지역으로
                <br />
                가다
              </p>
            </a>
            <a
              href="/classComeList"
              className="hover:opacity-60 text-center w-[60px] sm:w-[70px]"
            >
              <img
                src={come}
                alt="집으로 걸어 들어가는 인간 픽토그램"
                className="w-full aspect-square inline-block"
              />
              <p className="text-xs sm:text-sm keep-all">
                집으로
                <br />
                오다
              </p>
            </a>
            <a
              href="/magazine"
              className="hover:opacity-60 text-center w-[60px] sm:w-[70px]"
            >
              <img
                src={magazine}
                alt="책 그림"
                className="w-full aspect-square inline-block"
              />
              <p className="text-xs sm:text-sm keep-all">
                PX4
                <br />
                매거진
              </p>
            </a>
            <a
              href="/place"
              className="hover:opacity-60 text-center w-[60px] sm:w-[70px]"
            >
              <img
                src={place}
                alt="지도 그림"
                className="w-full aspect-square inline-block"
              />
              <p className="text-xs sm:text-sm keep-all">
                로컬픽
                <br />
                플레이스
              </p>
            </a>
          </div>
          <div
            className={`resultBox px-0 md:px-4 yl:px-0 pt-20 ${
              find ? "block" : "hidden"
            }`}
          >
            <p className="font-bold text-black text-lg md:text-2xl">검색결과</p>
            {/* 결과가 있다면? */}

            <div className="resultIs">
              {/* 오프라인 */}
              {!loading &&
                Number(data?.listProduct.offlineClasses?.length) > 0 && (
                  <div
                    id="search_offlineClass"
                    className={`max-w-screen-yl mx-auto pt-20`}
                  >
                    <div className="tracking-tight title">
                      <p className="inline-block text-main gangwon text-2xl sm:text-3xl">
                        <span className="align-middle font-bold">
                          지역으로 가다
                        </span>
                        <span className="align-middle text-sm ml-3 a13">
                          <i className="fas fa-chevron-right"></i>
                        </span>
                        <img
                          src={offline}
                          alt="오프라인"
                          className="inline-block align-middle w-10 sm:w-16 ml-3 sm:ml-5"
                        />
                        <p className="pt-2 md:pt-0 block md:inline text-base md:ml-4 md:text-xl text-[#6c6c6c] pretendardR">
                          <span className="font-bold">오프라인클래스</span>가
                          검색되었습니다.
                        </p>
                      </p>
                    </div>

                    <div className="grid grid-cols-2 md:grid-cols-4 gap-3 md:gap-5 pt-5">
                      {data?.listProduct?.offlineClasses &&
                        data?.listProduct?.offlineClasses?.map((product) => (
                          <ComponentProductWidget
                            product={product}
                            refetch={refetch}
                          />
                        ))}
                    </div>
                  </div>
                )}
              {/* 온라인 */}
              {!loading && Number(data?.listProduct.onlineClasses?.length) > 0 && (
                <div
                  id="search_onlineClass"
                  className={`max-w-screen-yl mx-auto pt-20`}
                >
                  <div className="tracking-tight title">
                    <p className="inline-block text-main gangwon text-2xl sm:text-3xl">
                      <span className="align-middle font-bold">
                        집으로 오다
                      </span>
                      <span className="align-middle text-sm ml-3 a13">
                        <i className="fas fa-chevron-right"></i>
                      </span>
                      <img
                        src={online}
                        alt="온라인"
                        className="inline-block align-middle w-10 sm:w-16 ml-3 sm:ml-5"
                      />
                      <p className="block md:inline pt-2 md:pt-0 text-base md:ml-4 md:text-xl text-[#6c6c6c] pretendardR">
                        <span className="font-bold">온라인클래스</span>가
                        검색되었습니다.
                      </p>
                    </p>
                  </div>

                  <div className="grid grid-cols-2 md:grid-cols-4 gap-3 md:gap-5 pt-5">
                    {data?.listProduct?.onlineClasses &&
                      data?.listProduct?.onlineClasses?.map((product) => (
                        <ComponentProductWidget
                          product={product}
                          refetch={refetch}
                        />
                      ))}
                  </div>
                </div>
              )}
            </div>
            {/* 결과가 없을 때, */}
            {!loading &&
              Number(data?.listProduct?.offlineClasses?.length) === 0 &&
              Number(data?.listProduct?.onlineClasses?.length) === 0 && (
                <div className="noResult pt-10">
                  <div className="flex flex-wrap flex-col content-center justify-center relative w-full aspect-video bg-[#efefef]">
                    <img
                      src={notFInd}
                      alt="돋보기 X"
                      className="h-[50px] md:h-auto mx-auto"
                    />
                    <p className="text-base md:text-2xl font-bold pt-10 text-[#6d6c6c]">
                      검색결과 "{words}"을(를) 찾을 수 없습니다.
                    </p>
                  </div>
                </div>
              )}
          </div>
        </section>
      </main>
      <Footer></Footer>
    </div>
  );
};
