import { useState, useEffect } from "react";
import { Footer } from "../../../../layout/footer";
import { Fade, Slide } from "@mui/material";
import { Edit_Dataform_st } from "./parts/dataform";
import { Edit_Password_st } from "./parts/password";
import { Aside } from "../aside";
import { SubTop } from "../../../../layout/subTop";
import { useMe } from "../../../../hooks/useMe";
import { ToTheTop } from "../../../../components/to-the-top";
import { BusinessInfo } from "./parts/businessInfo";
import { TaxInfo } from "./parts/taxInfo";
import arrow from "../../../../assets/icon/arrow-right.svg";

export const Change_st = () => {
  const { data } = useMe();

  const pages = 3;

  const [tabs, setTabs] = useState(0);
  // reload

  const alertUser = (e: any) => {
    e.preventDefault();
    e.returnValue = "";
  };
  const scorllHandler = () => {
    var element = document.getElementById("tabs");
    var left = element?.scrollLeft;
    element?.scrollTo({ left: Number(left) + 200, behavior: "smooth" });
  };
  return (
    <div className="bg-main bg-opacity-50 w-full h-full min-h-screen">
      <div className="max-w-screen-yl mx-auto w-full h-full min-h-screen  bg-white">
        <SubTop title={"nothing"} children={data?.me.name} />
        <main id="main">
          <div className="lg:grid lg:grid-cols-[300px_1fr]">
            <Aside page={pages} />
            <ToTheTop posi={`bottom-32 right-4 lg:bottom-16`} white={true} />
            <div className="mypages ">
              <div className="lectureWrap relative">
                <button
                  onClick={scorllHandler}
                  className="sm:hidden block text-center w-10 h-10 absolute right-0 top-0  bg-white z-10"
                >
                  <img src={arrow} alt="arrow" className="inline-block" />
                </button>
                <div
                  id="tabs"
                  className="h-10 sm:h-20 overflow-auto relative removeScroll"
                >
                  <div className="w-[200%] sm:w-full subtitle h-full flex flex-wrap content-end lg:px-10 pl-3 pr-12 sm:px-5 lg:gap-x-1 ">
                    <div
                      className={`inline-block  w-1/6 sm:w-1/3 lg:w-44 ${
                        tabs === 0 && "border-main border-b-2"
                      }`}
                    >
                      <button
                        className={`text-xs sm:text-sm lg:text-base leading-normal font-bold hover:opacity-60 w-full py-1 ${
                          tabs === 0 ? "text-main" : "text-gray-400"
                        }`}
                        onClick={() =>
                          setTabs(() => {
                            return 0;
                          })
                        }
                      >
                        개인정보 수정
                      </button>
                    </div>
                    <div
                      className={`inline-block  w-1/6 sm:w-1/3 lg:w-44 ${
                        tabs === 1 && "border-main border-b-2"
                      }`}
                    >
                      <button
                        className={`text-xs sm:text-sm lg:text-base leading-normal font-bold hover:opacity-60 w-full py-1 ${
                          tabs === 1 ? "text-main" : "text-gray-400"
                        }`}
                        onClick={() =>
                          setTabs(() => {
                            return 1;
                          })
                        }
                      >
                        사업자정보 수정
                      </button>
                    </div>
                    <div
                      className={`inline-block  w-1/6 sm:w-1/3 lg:w-44 ${
                        tabs === 2 && "border-main border-b-2"
                      }`}
                    >
                      <button
                        className={`text-xs sm:text-sm lg:text-base leading-normal font-bold hover:opacity-60 w-full py-1 ${
                          tabs === 2 ? "text-main" : "text-gray-400"
                        }`}
                        onClick={() =>
                          setTabs(() => {
                            return 2;
                          })
                        }
                      >
                        세금계산서 정보
                      </button>
                    </div>
                    <div
                      className={`inline-block  w-1/6 sm:w-1/3 lg:w-44 ${
                        tabs === 3 && "border-main border-b-2"
                      }`}
                    >
                      <button
                        className={`text-xs sm:text-sm lg:text-base leading-normal font-bold hover:opacity-60 w-full py-1 ${
                          tabs === 3 ? "text-main" : "text-gray-400"
                        }`}
                        onClick={() =>
                          setTabs(() => {
                            return 3;
                          })
                        }
                      >
                        비밀번호 변경
                      </button>
                    </div>
                  </div>
                </div>
                <div className=" bg-gray-100 h-fix-content relative overflow-hidden grid grid-rows-1 pb-28 sm:pb-16">
                  <Fade in={tabs === 0}>
                    <div
                      className={`w-full h-full px-4 py-6 sm:px-10 sm:py-12  ${
                        tabs === 0 ? "static" : "absolute left-0 top-0"
                      })`}
                    >
                      <Edit_Dataform_st />
                    </div>
                  </Fade>
                  <Fade in={tabs === 1}>
                    <div
                      className={`w-full h-full px-4 py-6 sm:px-10 sm:py-12  ${
                        tabs === 1 ? "static" : "absolute left-0 top-0"
                      })`}
                    >
                      <BusinessInfo />
                    </div>
                  </Fade>
                  <Fade in={tabs === 2}>
                    <div
                      className={`w-full h-full px-4 py-6 sm:px-10 sm:py-12  ${
                        tabs === 2 ? "static" : "absolute left-0 top-0"
                      })`}
                    >
                      <TaxInfo />
                    </div>
                  </Fade>
                  <Fade in={tabs === 3}>
                    <div
                      className={`w-full h-full px-4 py-6 sm:px-10 sm:py-12 space-y-10 ${
                        tabs === 3 ? "static" : "absolute left-0 top-0"
                      })`}
                    >
                      <Edit_Password_st />
                    </div>
                  </Fade>
                  <Footer></Footer>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
