import { LayoutTop } from "../layout/top";
import { Footer } from "../layout/footer";
import desk from "../assets/images/whatIsLocalPick_desktop.png";
import mobile from "../assets/images/whatIsLocalPick_mobile.png";
import banner from "../assets/images/what-banner.jpg";
import first from "../assets/images/what-firstImg.jpg";
import second from "../assets/images/what-secondImg.jpg";
import illur from "../assets/images/what-illurst.png";
import baloon from "../assets/images/what-textBaloon.png";
import coffee from "../assets/images/what-coffee.png";
import { whatLocalMission1, whatLocalMission2 } from "../layout/text";
export const SubWhatIsLocalPick = () => {
  return (
    <div className="contentWrap">
      <LayoutTop title={"nothing"} />
      <main id="main">
        <div>
          <img
            src={desk}
            alt="로컬픽이란"
            className="max-w-[1920px] mx-auto w-full hidden sm:block"
          />
          <img
            src={mobile}
            alt="로컬픽이란"
            className="w-full block sm:hidden"
          />
        </div>
      </main>
      <Footer></Footer>
    </div>
  );
};
