import { ApolloError, gql, useMutation } from "@apollo/client";
import React from "react";
import { UpBannerOptionInput } from "../__generated__/globalTypes";
import {
  upBannerOption,
  upBannerOptionVariables,
} from "../__generated__/upBannerOption";

export interface IUpBannerOptionFormProps extends UpBannerOptionInput {}

const UP_BANNER_OPTION_MUTATION = gql`
  mutation upBannerOption($input: UpBannerOptionInput!) {
    upBannerOption(input: $input) {
      ok
      err
    }
  }
`;

export const useUpBannerOption = (
  onCompleted?: (data: upBannerOption) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<upBannerOption, upBannerOptionVariables>(
    UP_BANNER_OPTION_MUTATION,
    {
      onCompleted,
      onError,
    }
  );
};
