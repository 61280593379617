import React, { useEffect, useState } from "react";
import { createBrowserHistory } from "history";
import { useForm } from "react-hook-form";
import { FormError2 } from "../components/form-error";
import { Button } from "../components/button";
import { useParams } from "react-router";
import { useGetBanner } from "../hooks/useGetBanner";
import { IUpBannerFormProps, useUpBanner } from "../hooks/useUpBanner";
import {
  GetBannerInput,
  GetPromotionInput,
  UpBannerInput,
  UpPromotionInput,
} from "../__generated__/globalTypes";
import { getBanner } from "../__generated__/getBanner";
import { upBanner } from "../__generated__/upBanner";
import { IUpPromotionFormProps, useUpPromotion } from "../hooks/useUpPromotion";
import { getPromotion } from "../__generated__/getPromotion";
import moment from "moment";
import { useGetPromotion } from "../hooks/useGetPromotion";
import { upPromotion } from "../__generated__/upPromotion";

export const SubAdminUpPromotion: React.FC = () => {
  const history = createBrowserHistory();
  const params = useParams();
  const [text, setText] = useState("");

  const {
    getValues,
    register,
    handleSubmit,
    setValue,
    setFocus,
    formState: { errors, isValid },
  } = useForm<IUpPromotionFormProps>({ mode: "all" });

  const onGetComplete = (data: getPromotion) => {
    const {
      getPromotion: { ok, err },
    } = data;
    if (!ok) {
      alert("잘못된 접근입니다");
      window.location.href = "/admin/banner";
      return;
    }
    setValue("title", `${data.getPromotion.promotion?.title}`);
    setValue("badge", `${data.getPromotion.promotion?.badge}`);
    setValue("badgeColor", `${data.getPromotion.promotion?.badgeColor}`);
    setValue("desc", `${data.getPromotion.promotion?.desc}`);
    setValue("discountRate", data.getPromotion.promotion?.discountRate);
    setValue(
      "startAt",
      moment(data.getPromotion.promotion?.startAt).format("YYYY-MM-DD")
    );
    setValue(
      "endAt",
      moment(data.getPromotion.promotion?.endAt).format("YYYY-MM-DD")
    );
    setText(String(getValues("badge")));
    (document.getElementById("badge") as HTMLElement).style.background = String(
      getValues("badgeColor")
    );
    setFocus("title");
  };
  const [getPromotion, { loading: getPromotionLoading }] =
    useGetPromotion(onGetComplete);
  const getPromotionHandler = (promotionId: number) => {
    if (getPromotionLoading) return;
    const input: GetPromotionInput = {
      promotionId,
    };
    getPromotion({ variables: { input } });
  };

  const onComplete = (data: upPromotion) => {
    const {
      upPromotion: { ok, err },
    } = data;
    if (ok) {
      alert("저장되었습니다");
      window.location.href = "/admin/promotion";
    } else {
      alert(err);
    }
  };
  const [upPromotion, { loading }] = useUpPromotion(onComplete);
  const onSubmit = () => {
    if (!params.promotionId) {
      alert("배너 정보가 유효하지 않습니다");
      return;
    }
    if (!loading) {
      const { title, badge, desc, discountRate, startAt, endAt, badgeColor } =
        getValues();
      const input: UpPromotionInput = {
        promotionIdToUp: +params.promotionId,
        title,
        badge,
        badgeColor,
        desc,
        discountRate: discountRate ? +discountRate : 1,
        startAt,
        endAt,
      };
      upPromotion({ variables: { input } });
    }
  };

  useEffect(() => {
    if (!params.promotionId) return;
    getPromotionHandler(+params.promotionId);
  }, []);

  return !getPromotionLoading ? (
    <div className="bg-white p-5 rounded-2xl shadow-lg">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className=" p-5 lg:p-10 space-y-5 lg:space-y-10 max-w-xl">
          <div className="space-y-3">
            <p className="font-bold text-main lg:text-base text-sm">제목</p>
            <input
              {...register("title", {
                required: `제목을 입력해주세요`,
              })}
              type="text"
              className="border border-grays rounded-xl py-1 px-2 w-full text-sm lg:text-base"
            />
            {errors.title?.message && (
              <FormError2 errorMessage={errors.title.message} />
            )}
          </div>

          <div className="space-y-3">
            <p className="font-bold text-main lg:text-base text-sm">뱃지</p>
            <input
              {...register("badge", {
                required: `뱃지를 입력해주세요`,
              })}
              type="text"
              className="border border-grays rounded-xl py-1 px-2 w-full text-sm lg:text-base"
            />
            {errors.badge?.message && (
              <FormError2 errorMessage={errors.badge.message} />
            )}
          </div>

          <div className="space-y-3">
            <p className="font-bold text-main lg:text-base text-sm">뱃지색</p>
            <input
              {...register("badgeColor", {
                required: `뱃지색을 정해주세요`,
              })}
              type="color"
              onChange={(e) => {
                (
                  document.getElementById("badge") as HTMLElement
                ).style.background = e.currentTarget.value;
              }}
              className="border border-grays rounded-xl py-1 px-2 w-[60px] text-sm lg:text-base cursor-pointer"
            />
            <div className="inline-block ml-2">
              <span
                className={`inline-block p-1 px-3 rounded-lg opacity-80 text-white`}
                id="badge"
              >
                {text}
              </span>
            </div>
            {errors.badgeColor?.message && (
              <FormError2 errorMessage={errors.badgeColor?.message} />
            )}
          </div>

          <div className="space-y-3">
            <p className="font-bold text-main lg:text-base text-sm">할인율</p>
            <input
              {...register("discountRate", {
                required: "할인율은 필수 정보입니다",
              })}
              type="number"
              defaultValue={0}
              className="border border-grays rounded-xl py-1 px-2 w-full text-sm lg:text-base"
            />
          </div>
          {errors.discountRate?.message && (
            <FormError2 errorMessage={errors.discountRate.message} />
          )}

          <div className="space-y-3">
            <p className="font-bold text-main lg:text-base text-sm">시작일</p>
            <input
              {...register("startAt", {
                required: "시작일은 필수정보입니다",
              })}
              type="date"
              max={`2999-12-31`}
              className="border border-grays rounded-xl py-1 px-2 w-full text-sm lg:text-base"
            />
          </div>
          {errors.startAt?.message && (
            <FormError2 errorMessage={errors.startAt.message} />
          )}

          <div className="space-y-3">
            <p className="font-bold text-main lg:text-base text-sm">종료일</p>
            <input
              {...register("endAt", {
                required: "종료일은 필수정보입니다",
              })}
              type="date"
              max={`2999-12-31`}
              className="border border-grays rounded-xl py-1 px-2 w-full text-sm lg:text-base"
            />
          </div>
          {errors.endAt?.message && (
            <FormError2 errorMessage={errors.endAt.message} />
          )}

          <div className="space-y-3 relative">
            <p className="font-bold text-main lg:text-base text-sm">설명</p>
            <textarea
              {...register("desc", {
                required: `설명을 입력해주세요`,
              })}
              className="border border-grays rounded-xl py-1 px-2 w-full text-sm lg:text-base min-h-[300px]"
            ></textarea>
            {errors.desc?.message && (
              <FormError2 errorMessage={errors.desc.message} />
            )}
          </div>

          <div className="text-center py-10 space-x-5">
            <button className="rounded-md border border-grays w-2/5 sm:w-[150px] py-1 hover:opacity-60 text-sm lg:text-base ">
              취소
            </button>
            <Button
              className={`rounded-md text-white w-2/5 sm:w-[150px] py-1 hover:opacity-60 text-sm lg:text-base bg-main border border-main`}
              canClick={isValid}
              actionText={`저장`}
              loading={loading}
              color={"text-white"}
            />
          </div>
        </div>
      </form>
    </div>
  ) : (
    <div className="bg-white p-5 rounded-2xl shadow-lg text-center">{`데이터를 불러오는 중입니다`}</div>
  );
};
