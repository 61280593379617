import { SubTop } from "../../../../layout/subTop";
import { useState, useEffect } from "react";
import { Footer } from "../../../../layout/footer";
import { Fade, Slide } from "@mui/material";
import { Curriculum } from "./parts/curri";
import { Thumb } from "./parts/thumb";
import { Info } from "./parts/info";
import { Blog } from "./parts/blog";
import { Kit } from "./parts/kit";
import { Aside } from "../aside";
import { useParams } from "react-router";
import { EditInfo } from "./parts/editInfo";
import { useMe } from "../../../../hooks/useMe";
import { Intro } from "./parts/intro";
import arrow from "../../../../assets/icon/arrow-right.svg";
import { ToTheTop } from "../../../../components/to-the-top";

export const Enroll_st = () => {
  const { data } = useMe();
  const params: any = useParams();
  const [pages, setPages] = useState(1);
  const [tabs, setTabs] = useState(0);
  // reload

  const alertUser = (e: any) => {
    e.preventDefault();
    e.returnValue = "";
  };
  const scorllHandler = () => {
    var element = document.getElementById("tabs");
    var left = element?.scrollLeft;
    element?.scrollTo({ left: Number(left) + 200, behavior: "smooth" });
  };
  return (
    <div className="bg-main bg-opacity-50 w-full h-full min-h-screen">
      <div className="max-w-screen-yl mx-auto w-full h-full min-h-screen  bg-white">
        <SubTop title={"nothing"} children={data?.me.name} />
        <main id="main">
          <div className="lg:grid lg:grid-cols-[300px_1fr]">
            <Aside page={pages} />
            <ToTheTop posi={`bottom-32 right-4 lg:bottom-16`} white={true} />
            <div className="mypages ">
              <div className="lectureWrap relative">
                <button
                  onClick={scorllHandler}
                  className="sm:hidden block text-center w-10 h-10 absolute right-0 top-0  bg-white z-10"
                >
                  <img src={arrow} alt="arrow" className="inline-block" />
                </button>
                <div
                  id="tabs"
                  className="h-10 sm:h-20 overflow-auto relative removeScroll"
                >
                  <div className="w-[200%] sm:w-full subtitle h-full flex flex-wrap content-end lg:px-10 pl-3 pr-12 sm:px-5 lg:gap-x-1 ">
                    <div
                      className={`inline-block  w-1/6 sm:w-1/3 lg:w-40 ${
                        tabs === 0 && "border-main border-b-2"
                      }`}
                    >
                      <button
                        className={`text-xs sm:text-sm lg:text-base leading-normal font-bold hover:opacity-60 w-full py-1  ${
                          tabs === 0 ? "text-main" : "text-gray-400"
                        }`}
                        onClick={() =>
                          setTabs(() => {
                            return 0;
                          })
                        }
                      >
                        강의 필수 정보
                      </button>
                    </div>
                    <div
                      className={`inline-block  w-1/6 sm:w-1/3 lg:w-40 ${
                        tabs === 1 && "border-main border-b-2"
                      }`}
                    >
                      <button
                        disabled={!params.key}
                        className={`text-xs sm:text-sm lg:text-base leading-normal font-bold ${
                          params.key ? "hover:opacity-60" : "opacity-60"
                        }  w-full py-1  ${
                          tabs === 1 ? "text-main" : "text-gray-400"
                        }`}
                        onClick={() =>
                          setTabs(() => {
                            return 1;
                          })
                        }
                      >
                        썸네일 관리
                      </button>
                    </div>
                    <div
                      className={`inline-block  w-1/6 sm:w-1/3 lg:w-40 ${
                        tabs === 2 && "border-main border-b-2"
                      }`}
                    >
                      <button
                        disabled={!params.key}
                        className={`text-xs sm:text-sm lg:text-base leading-normal font-bold ${
                          params.key ? "hover:opacity-60" : "opacity-60"
                        } hover:opacity-60 w-full py-1  ${
                          tabs === 2 ? "text-main" : "text-gray-400"
                        }`}
                        onClick={() =>
                          setTabs(() => {
                            return 2;
                          })
                        }
                      >
                        강의 구성 관리
                      </button>
                    </div>
                    <div
                      className={`inline-block  w-1/6 sm:w-1/3 lg:w-40 ${
                        tabs === 3 && "border-main border-b-2"
                      }`}
                    >
                      <button
                        disabled={!params.key}
                        className={`text-xs sm:text-sm lg:text-base leading-normal font-bold ${
                          params.key ? "hover:opacity-60" : "opacity-60"
                        } hover:opacity-60 w-full py-1  ${
                          tabs === 3 ? "text-main" : "text-gray-400"
                        }`}
                        onClick={() =>
                          setTabs(() => {
                            return 3;
                          })
                        }
                      >
                        클래스 소개
                      </button>
                    </div>
                    <div
                      className={`inline-block  w-1/6 sm:w-1/3 lg:w-40 ${
                        tabs === 4 && "border-main border-b-2"
                      }`}
                      onClick={() => {
                        if (!params.key) {
                          alert("필수 정보가 저장되어야 사용 가능합니다.");
                        }
                      }}
                    >
                      <button
                        disabled={!params.key}
                        className={`text-xs sm:text-sm lg:text-base leading-normal font-bold ${
                          params.key ? "hover:opacity-60" : "opacity-60"
                        } hover:opacity-60 w-full py-1  ${
                          tabs === 4 ? "text-main" : "text-gray-400"
                        }`}
                        onClick={() =>
                          setTabs(() => {
                            return 4;
                          })
                        }
                      >
                        커리큘럼
                      </button>
                    </div>
                    <div
                      className={`inline-block  w-1/6 sm:w-1/3 lg:w-40 ${
                        tabs === 5 && "border-main border-b-2"
                      }`}
                    >
                      <button
                        disabled={!params.key}
                        className={`text-xs sm:text-sm lg:text-base leading-normal font-bold ${
                          params.key ? "hover:opacity-60" : "opacity-60"
                        } hover:opacity-60 w-full py-1  ${
                          tabs === 5 ? "text-main" : "text-gray-400"
                        }`}
                        onClick={() =>
                          setTabs(() => {
                            return 5;
                          })
                        }
                      >
                        키트 관리
                      </button>
                    </div>
                  </div>
                </div>
                <div className=" bg-gray-100 h-fix-content pb-28 sm:pb-16 lg:pb-0 relative overflow-hidden grid grid-rows-1">
                  {tabs === 0 && (
                    <Fade in={tabs === 0}>
                      <div
                        className={`w-full h-full px-3 lg:px-10 py-8 lg:py-12  ${
                          tabs === 0 ? "static" : "absolute left-0 top-0"
                        })`}
                      >
                        {!Boolean(params.key) ? <Info /> : <EditInfo />}
                      </div>
                    </Fade>
                  )}
                  {tabs === 1 && (
                    <Fade in={tabs === 1}>
                      <div
                        className={`w-full h-full px-3 lg:px-10 py-8 lg:py-12 ${
                          tabs === 1 ? "static" : "absolute left-0 top-0"
                        })`}
                      >
                        <Thumb />
                      </div>
                    </Fade>
                  )}
                  {tabs === 2 && (
                    <Fade in={tabs === 2}>
                      <div
                        className={`w-full h-full px-3 lg:px-10 py-8 lg:py-12 ${
                          tabs === 2 ? "static" : "absolute left-0 top-0"
                        })`}
                      >
                        <Curriculum />
                      </div>
                    </Fade>
                  )}
                  {tabs === 3 && (
                    <Fade in={tabs === 3}>
                      <div
                        className={`w-full h-full px-3 lg:px-10 py-8 lg:py-12 ${
                          tabs === 3 ? "static" : "absolute left-0 top-0"
                        })`}
                      >
                        <Intro />
                      </div>
                    </Fade>
                  )}
                  {tabs === 4 && (
                    <Fade in={tabs === 4}>
                      <div
                        className={`w-full h-full px-3 lg:px-10 py-8 lg:py-12 ${
                          tabs === 4 ? "static" : "absolute left-0 top-0"
                        })`}
                      >
                        <Blog />
                      </div>
                    </Fade>
                  )}
                  {tabs === 5 && (
                    <Fade in={tabs === 5}>
                      <div
                        className={`w-full h-full px-3 lg:px-10 py-8 lg:py-12 ${
                          tabs === 5 ? "static" : "absolute left-0 top-0"
                        })`}
                      >
                        <Kit />
                      </div>
                    </Fade>
                  )}
                  <Footer></Footer>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
