import logo from "../../../assets/icon/localpick_logo_footer.svg";
import { Link } from "react-router-dom";
export const JOIN_complete = () => {
  return (
    <div className="bg-main bg-opacity-50 w-full h-full min-h-screen">
      <div className="max-w-screen-yl mx-auto w-full h-full min-h-screen bg-white flex flex-wrap justify-center content-center">
        <div className="bg-main bg-opacity-80 w-full py-10 sm:py-20 max-w-xl">
          <img src={logo} alt="로로 로컬 투 로컬" className="w-1/2 mx-auto" />
          <div className="line h-[2px] w-5/6 sm:w-96 mx-auto bg-white my-5 sm:my-10"></div>
          <p className="text-white font-bold text-lg sm:text-xl text-center pb-5">
            회원가입이 완료되었습니다!
          </p>
          <div>
            <button className="block text-main bg-white w-1/3 font-bold text-xs sm:text-base mx-auto">
              <Link to="/signin" className="block w-full py-2">
                로그인 페이지로 이동
              </Link>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
