import { ApolloError, gql, useLazyQuery } from "@apollo/client";
import { CLASS_LIST_WIDGET_FRAGMENT } from "../fragments";
import {
  searchProductWAddress,
  searchProductWAddressVariables,
} from "../__generated__/searchProductWAddress";
const SEARCH_PRODUCT_QUERY = gql`
  query searchProductWAddress($input: SearchProductWAddressInput!) {
    searchProductWAddress(input: $input) {
      ok
      err
      products {
        ...ClassListWidgetParts
      }
    }
  }
  ${CLASS_LIST_WIDGET_FRAGMENT}
`;
export const useSearchProductAddress = (
  onCompleted?: (data: searchProductWAddress) => void,
  onError?: (error: ApolloError) => void
) => {
  return useLazyQuery<searchProductWAddress, searchProductWAddressVariables>(
    SEARCH_PRODUCT_QUERY,
    {
      onCompleted,
      onError,
      fetchPolicy: "network-only",
    }
  );
};
