import { ApolloError, gql, useMutation } from "@apollo/client";
import React from "react";
import {
  crDoorReply,
  crDoorReplyVariables,
} from "../__generated__/crDoorReply";
import { CrDoorReplyInput } from "../__generated__/globalTypes";

export interface ICrDoorReplyFormProps extends CrDoorReplyInput {}

const CR_DOOR_REPLY_MUTATION = gql`
  mutation crDoorReply($input: CrDoorReplyInput!) {
    crDoorReply(input: $input) {
      ok
      err
    }
  }
`;

export const useCrDoorReply = (
  onCompleted?: (data: crDoorReply) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<crDoorReply, crDoorReplyVariables>(
    CR_DOOR_REPLY_MUTATION,
    {
      onCompleted,
      onError,
    }
  );
};
