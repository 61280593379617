import { useState, useEffect } from "react";
import { Footer } from "../layout/footer";
import { LayoutTop } from "../layout/top";
import magazine1 from "../assets/images/magazine_first.png";
import magazine2 from "../assets/images/magazine_second.png";
import { magazine_test } from "../routes/home/parts/data";
import Place_right from "../assets/icon/place-right.svg";
import Place_left from "../assets/icon/place-left.svg";
import Pick_left from "../assets/icon/pick-left.svg";
import Pick_right from "../assets/icon/pick-right.svg";
import People_left from "../assets/icon/people-left.svg";
import People_right from "../assets/icon/people-right.svg";
import Project_right from "../assets/icon/project-right.svg";
import Project_left from "../assets/icon/project-left.svg";
import banner from "../assets/images/magazine_inner_banner.png";
import { Link } from "react-router-dom";
import { useListMagazineActive } from "../hooks/useListMagazineActive";
import { ListMagazineInput, ProductStatus } from "../__generated__/globalTypes";
import { listMagazineActive } from "../__generated__/listMagazineActive";
import moment from "moment";
import { useGetBannerOptionActive } from "../hooks/useGetBannerOptionActive";
export const SubCommonListMagazine = () => {
  const [page, setPage] = useState<number>(1);
  const [search, setSearch] = useState<string>("");
  const [
    listMagazineActive,
    {
      data: listMagazineActiveData,
      loading: listMagazineActiveLoading,
      refetch,
    },
  ] = useListMagazineActive();
  const listMagazineActiveHandler = (page: number, search: string) => {
    const input: ListMagazineInput = {
      page,
      search,
    };
    listMagazineActive({ variables: { input } });
  };
  const MgazineBanner =
    useGetBannerOptionActive().data?.getBannerOptionActive.bannerOptions?.filter(
      (banner) => banner.bannerOptionType === "MagazineC"
    )[0];
  const categoryTagHandler = (category: any) => {
    var imageURL;
    switch (category) {
      case "Place":
        imageURL = {
          left: Place_left,
          right: Place_right,
        };
        break;
      case "Pick":
        imageURL = {
          left: Pick_left,
          right: Pick_right,
        };
        break;
      case "People":
        imageURL = {
          left: People_left,
          right: People_right,
        };
        break;
      case "Project":
        imageURL = {
          left: Project_left,
          right: Project_right,
        };
        break;
      default:
        imageURL = {
          left: Place_left,
          right: Place_right,
        };
    }
    return imageURL;
  };
  useEffect(() => {
    listMagazineActiveHandler(page, search);
  }, []);
  return (
    <>
      <LayoutTop title={"PX4매거진"} />
      <main id="main">
        <div className="max-w-screen-yl mx-auto lg:py-10 px-4">
          <div className="hidden lg:block">
            <p className="text-3xl py-5 text-center font-bold">
              Px4 <span className="uppercase">magazine</span>
            </p>
            <p className="text-center">
              트렌디한 지역 분위기를 담은 공간과 문화체험을 로컬픽의 관점으로
              소개합니다.
            </p>
          </div>
          <div className="newContents lg:pt-20">
            {!listMagazineActiveLoading &&
              listMagazineActiveData?.listMagazineActive.products
                ?.filter((product, index) => index === 0)
                .map((product) => (
                  <div
                    id="firstMagazine"
                    className="pt-20 flex md:flex-row flex-col"
                    key={`${
                      product.productInfo &&
                      product?.productInfo?.title + product.id
                    }`}
                  >
                    <div className={`w-full md:w-1/2 overflow-hidden`}>
                      {product?.productViews &&
                        product.productViews
                          .filter((productView) => productView.is_rep)
                          .map((productView) => (
                            <img
                              key={`magazine-view-${productView.id}`}
                              src={
                                productView?.view?.url && productView.view.url
                              }
                              alt={`${product?.productInfo?.title} 썸네일`}
                              className="w-full h-full object-cover transform hover:scale-125 scale-100 transition-transform duration-300"
                            />
                          ))}
                      {/* <img
                        src={magazine1}
                        alt="매거진사진"
                        className="w-full h-full object-cover transform hover:scale-125 scale-100 transition-transform duration-300"
                      /> */}
                    </div>
                    <div className="w-full md:w-1/2 p-4 sm:p-6 bg-[#f4ece9] flex flex-wrap content-center relative">
                      {/* 카테고리별 태그 */}
                      <img
                        src={
                          categoryTagHandler(product?.productInfo?.category)
                            .right
                        }
                        alt="first_category"
                        className="sm:w-[80px] absolute right-0 top-0 "
                      />

                      <Link
                        to={`/magazine/${product.id}`}
                        className="p-6 flex flex-wrap content-center hover:underline"
                      >
                        <p className="text-xl md:text-3xl gangwon text-center md:text-left">
                          {product.productInfo?.title}
                        </p>
                        <p className="pt-5 whitespace-pre-wrap text-[#6D6C6C] overflow-hidden ellipsis8">
                          {product.productInfo?.summary}
                        </p>
                      </Link>
                      <p className="absolute right-3 bottom-3">
                        {product.inst.user?.name} |{" "}
                        {moment(product.createdAt).format("YYYY.MM.DD")}
                      </p>
                    </div>
                  </div>
                ))}

            {!listMagazineActiveLoading &&
              listMagazineActiveData?.listMagazineActive.products
                ?.filter((product, index) => index === 1)
                .map((product) => (
                  <div
                    id="secondMagazine"
                    className="pt-20 flex md:flex-row flex-col-reverse"
                    key={`${
                      product.productInfo &&
                      product?.productInfo?.title + product.id
                    }`}
                  >
                    <div className="w-full md:w-1/2 p-4 sm:p-6 bg-[#f4ece9] flex flex-wrap content-center relative">
                      <img
                        src={
                          categoryTagHandler(product?.productInfo?.category)
                            .left
                        }
                        alt="second_Category"
                        className="sm:w-[80px] absolute left-0 top-0 hidden md:block"
                      />
                      <img
                        src={
                          categoryTagHandler(product?.productInfo?.category)
                            .right
                        }
                        alt="second_Category"
                        className="sm:w-[80px] absolute right-0 top-0 block md:hidden"
                      />
                      <Link
                        to={`/magazine/${product.id}`}
                        className=" p-6 flex flex-wrap content-center hover:underline"
                      >
                        <p className="text-xl md:text-3xl gangwon text-center md:text-left">
                          {product.productInfo?.title}
                        </p>
                        <p className="pt-5 whitespace-pre-wrap text-[#6D6C6C] overflow-hidden ellipsis8">
                          {product.productInfo?.summary}
                        </p>
                      </Link>
                      <p className="absolute right-3 bottom-3">
                        {product.inst.user?.name} |{" "}
                        {moment(product.createdAt).format("YYYY.MM.DD")}
                      </p>
                    </div>
                    <div className={`w-full md:w-1/2 overflow-hidden`}>
                      {product?.productViews &&
                        product.productViews
                          .filter((productView) => productView.is_rep)
                          .map((productView) => (
                            <img
                              key={`magazine-view-${productView.id}`}
                              src={
                                productView?.view?.url && productView.view.url
                              }
                              alt={`${product?.productInfo?.title} 썸네일`}
                              className="w-full h-full object-cover transform hover:scale-125 scale-100 transition-transform duration-300"
                            />
                          ))}
                    </div>
                  </div>
                ))}
          </div>
          <div className="banners pt-20">
            {MgazineBanner?.link ? (
              <Link to={`${MgazineBanner?.link}`}>
                <img
                  src={MgazineBanner?.view?.url}
                  alt="배너"
                  className="w-full aspect-[2/1]"
                />
              </Link>
            ) : (
              <img
                src={MgazineBanner?.view?.url}
                alt="배너"
                className="w-full aspect-[2/1]"
              />
            )}
          </div>
          {/* 리스트  */}
          <div className="articles py-4 lg:py-20 grid grid-cols-2 lg:grid-cols-3 gap-x-10 gap-y-16">
            {!listMagazineActiveLoading &&
              listMagazineActiveData?.listMagazineActive.products?.length !==
                0 &&
              listMagazineActiveData?.listMagazineActive.products?.map(
                (product) => (
                  <div
                    key={`${
                      product.productInfo &&
                      product?.productInfo?.title + product.id
                    }`}
                    className="card relative"
                  >
                    <a
                      href={`/magazine/${product.id}`}
                      className="aspect-magazine relative overflow-hidden block"
                    >
                      {product?.productViews &&
                        product.productViews
                          .filter((productView) => productView.is_rep)
                          .map((productView) => (
                            <img
                              key={`class-view-${productView.id}`}
                              src={
                                productView?.view?.url && productView.view.url
                              }
                              alt={`${product?.productInfo?.title} 썸네일`}
                              className="absolute left-0 top-0 w-full h-full object-cover transform scale-100 hover:scale-125 transition-transform duration-300"
                            />
                          ))}
                    </a>
                    <div className="px-5 pt-3 pb-2 bg-[#f4ece9]">
                      <p className="tracking-tight text-base md:text-xl py-1 font-bold text-main">
                        {product?.productInfo?.title}
                      </p>
                      <p className="leading-tight h-[46px] text-xs md:text-base py-1 overflow-hidden ellipsis2">
                        {product.productInfo?.place}
                      </p>
                    </div>
                  </div>
                )
              )}
          </div>
        </div>
        <div className="py-8 lg:py-20 w-full bg-[#f4ece9] lg:mb-20">
          <div className="max-w-screen-yl mx-auto flex flex-wrap gap-y-8 lg:gap-0 content-center justify-center">
            <div className="inline-block px-20 text-center relative">
              <p className="text-5xl text-bold sansation">Px4</p>
              <p className="text-bold uppercase sansation text-2xl">magazine</p>
              <div className="hidden lg:block line bg-black h-1/2 w-[1px] absolute right-0 top-1/2 transform -translate-y-1/2"></div>
            </div>
            <div className="inline-block px-20 text-center">
              <p>"Px4 매거진"은 Pick / Place / Project 컨셉 아래에</p>
              <p>
                직접 공방을 선택하고 방문하여 공간을 촬영하고 작가님과의
                인터뷰를 통해
              </p>
              <p>
                공방의 스토리 및 클래스 이야기를 고객들에게 매거진 형식으로
                전달해 그리는 공간입니다.
              </p>
            </div>
          </div>
        </div>
      </main>
      <Footer></Footer>
    </>
  );
};
