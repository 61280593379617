import React from "react";
import { gql, useLazyQuery, useQuery } from "@apollo/client";
import { listProductMain } from "../__generated__/listProductMain";
import { CLASS_LIST_WIDGET_FRAGMENT } from "../fragments";
import { listProductMainVariables } from "../__generated__/listProductMain";

const LIST_PRODUCT_MAIN_QUERY = gql`
  query listProductMain($input: ListProductMainInput!) {
    listProductMain(input: $input) {
      ok
      err
      onlineClasses {
        ...ClassListWidgetParts
      }
      offlineClasses {
        ...ClassListWidgetParts
      }
    }
  }
  ${CLASS_LIST_WIDGET_FRAGMENT}
`;

export const useListProductMain = (
  onCompleted?: (data: listProductMain) => void
) => {
  return useLazyQuery<listProductMain, listProductMainVariables>(
    LIST_PRODUCT_MAIN_QUERY,
    {
      onCompleted,
      fetchPolicy: "network-only",
    }
  );
};
