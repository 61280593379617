import { ApolloError, gql, useMutation } from "@apollo/client";
import React from "react";
import { UpPromotionProductInput } from "../__generated__/globalTypes";
import {
  upPromotionProduct,
  upPromotionProductVariables,
} from "../__generated__/upPromotionProduct";

export interface IUpPromotionProductFormProps extends UpPromotionProductInput {}

const UP_PROMOTION_PRODUCT_MUTATION = gql`
  mutation upPromotionProduct($input: UpPromotionProductInput!) {
    upPromotionProduct(input: $input) {
      ok
      err
    }
  }
`;

export const useUpPromotionProduct = (
  onCompleted?: (data: upPromotionProduct) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<upPromotionProduct, upPromotionProductVariables>(
    UP_PROMOTION_PRODUCT_MUTATION,
    {
      onCompleted,
      onError,
    }
  );
};
