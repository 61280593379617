import { useEffect, useState } from "react";
import { Map } from "./map";
import arrow from "../../../assets/icon/arrow-right.svg";
import {
  LocalCategoryOption,
  ProductCategoryOption,
} from "../../../constants/enum";
import { useGetBannerOptionActive } from "../../../hooks/useGetBannerOptionActive";

export interface ICategoryProps {
  local: string;
  product: string;
  productCategoryHandler: (product: string) => void;
  localCategoryHandler: (local: string) => void;
}

export const Category: React.FC<ICategoryProps> = ({
  local,
  product,
  productCategoryHandler,
  localCategoryHandler,
}) => {
  const { data } = useGetBannerOptionActive();
  const scorllHandler = () => {
    var element = document.getElementById("big4");
    var left = element?.scrollLeft;
    element?.scrollTo({ left: Number(left) + 200, behavior: "smooth" });
  };
  // select

  return (
    <div className="max-w-screen-yl px-4 yl:px-0 mx-auto h-auto pb-8 lg:pb-20">
      {/* 윗줄 */}
      <div className="relative block lg:flex justify-between pt-0 lg:pt-8 ">
        <button
          onClick={scorllHandler}
          className="sm:hidden block text-center w-8 h-8 button absolute top-3 right-0 bg-white z-10"
        >
          <img src={arrow} alt="arrow" className="inline-block" />
        </button>
        {/* big4 */}
        <nav
          id="big4"
          className="py-4 lg:py-0 overflow-auto removeScroll smoothScroll"
        >
          <ul id="ps_big4" className="w-[640px] sm:w-auto">
            <li className="inline-block">
              <a
                href="#class_go"
                className="text-main text-xl px-4 sm:px-6 gangwon relative"
              >
                <span className="hidden sm:block w-[1px] h-[60%] absolute left-0 top-1/2 transform -translate-y-1/2 bg-[#C0C1C1]"></span>
                지역으로가다
              </a>
            </li>
            <li className="inline-block">
              <a
                href="#class_come"
                className="text-main text-xl px-4 sm:px-6 gangwon relative"
              >
                <span className="hidden sm:block w-[1px] h-[60%] absolute left-0 top-1/2 transform -translate-y-1/2 bg-[#C0C1C1]"></span>
                집으로오다
              </a>
            </li>
            <li className="inline-block">
              <a
                href="#magazine"
                className="text-main text-xl px-4 sm:px-6 gangwon relative"
              >
                <span className="hidden sm:block w-[1px] h-[60%] absolute left-0 top-1/2 transform -translate-y-1/2 bg-[#C0C1C1]"></span>
                PX4매거진
              </a>
            </li>
            <li className="inline-block">
              <a
                href="#localPlace"
                className="text-main text-xl px-4 sm:px-6 gangwon relative"
              >
                <span className="hidden sm:block w-[1px] h-[60%] absolute left-0 top-1/2 transform -translate-y-1/2 bg-[#C0C1C1]"></span>
                <span className="hidden sm:block w-[1px] h-[60%] absolute right-0 top-1/2 transform -translate-y-1/2 bg-[#C0C1C1]"></span>
                로컬플레이스
              </a>
            </li>
          </ul>
        </nav>
        {/* 카테고리 */}
        <div className=" text-center lg:text-left py-3 lg:py-0 bg-[#F1ECE8] lg:bg-transparent">
          <div className="inline-block align-middle relative">
            <select
              value={local}
              onChange={(e) => localCategoryHandler(e.target.value)}
              className={`w-full bg-transparent py-1 pr-4 text-center cursor-pointer text-[#8A6A59] lg:text-gray-600 select_arrow`}
            >
              {LocalCategoryOption.map((localCategory) => (
                <option
                  key={`local-category-${localCategory.value}`}
                  value={localCategory.string}
                >
                  {localCategory.label}
                </option>
              ))}
            </select>
          </div>
          {/* 클래스 카테고리 */}
          <div className="inline-block align-middle relative pl-8">
            <select
              value={product}
              className={`w-full bg-transparent py-1 pr-4 text-center cursor-pointer text-[#8A6A59] lg:text-gray-600 select_arrow`}
              onChange={(e) => productCategoryHandler(e.target.value)}
            >
              {ProductCategoryOption.map((productCategory) => (
                <option
                  key={`product-category-${productCategory.value}`}
                  value={productCategory.string}
                >
                  {productCategory.label}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      {/* 아랫줄 */}
      <div className="flex flex-wrap gap-4 lg:pt-10">
        {/* 이미지 */}
        <figure className="flex-1">
          {data?.getBannerOptionActive.bannerOptions && (
            <a href={`${data.getBannerOptionActive.bannerOptions[3].link}`}>
              <img
                src={`${
                  data.getBannerOptionActive.bannerOptions[3].view?.url
                    ? data.getBannerOptionActive.bannerOptions[3].view.url
                    : `#`
                }`}
                alt="배너이미지"
                className="h-full w-full object-cover aspect-[3/1]"
              />
            </a>
          )}
        </figure>
        {/* 맵 */}
        <div className="hidden lg:block max-w-[330px] w-full bg-[#ebece7] rounded-2xl p-4">
          <p className="text-sm text-text font-bold text-left">
            나의 로컬픽은?
          </p>
          <Map local={local} localCategoryHandler={localCategoryHandler}></Map>
        </div>
      </div>
    </div>
  );
};
