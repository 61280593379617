import React, { useEffect, useState } from "react";
import { LayoutTop } from "../../layout/top";
import { Banner } from "./parts/slideBanner";
import { Category } from "./parts/category";
import { Magazine } from "./parts/magazine";
import { Footer } from "../../layout/footer";
import { Place } from "./parts/place";
import { Instagram } from "./parts/instagram";
import { useListProductMain } from "../../hooks/useListProductMain";
import { createBrowserHistory } from "history";
import { useLocation } from "react-router";
import { ListProductMainInput } from "../../__generated__/globalTypes";
import { SubCommonMainOfflineClass } from "../../sub-routes/sub-common-main-offline-class";
import { SubCommonMainOnlineClass } from "../../sub-routes/sub-common-main-online-class";
import {
  LocalCategoryOption,
  LocalCategoryOptionType,
  ProductCategoryOption,
  ProductCategoryOptionType,
} from "../../constants/enum";
import { ToTheTop } from "../../components/to-the-top";
import { useGetBannerOptionActive } from "../../hooks/useGetBannerOptionActive";

export interface IListProductMainProps {
  local?: string;
  product?: string;
}

export const HOME = () => {
  const { data: getBannerOptionActiveData, loading } =
    useGetBannerOptionActive();

  const history = createBrowserHistory();
  const location = useLocation();
  const [local, setLocal] = useState<string>("");
  const [product, setProduct] = useState<string>("");

  const [listProductMain, { data, refetch }] = useListProductMain();

  const listProductMainHandler = ({
    local: _local,
    product: _product,
  }: IListProductMainProps) => {
    const localCategory = _local
      ? Object(LocalCategoryOption).filter(
          (localCategoryOption: LocalCategoryOptionType) =>
            localCategoryOption.string === _local
        )[0]
      : local;
    const productCategory = _product
      ? Object(ProductCategoryOption).filter(
          (productCategoryOption: ProductCategoryOptionType) =>
            productCategoryOption.string === _product
        )[0]
      : product;

    const listProductMainInput: ListProductMainInput = {
      ...(localCategory && { localCategory: localCategory.value }),
      ...(productCategory && { productCategory: productCategory.value }),
    };
    listProductMain({ variables: { input: listProductMainInput } });
  };

  const productCategoryHandler = (product: string) => {
    setProduct(product);
    history.push({
      pathname: "/",
      search: `${(local || product) && `?`}${local && `local=${local}`}${
        local && product && `&`
      }${product && `product=${product}`}`,
    });
    listProductMainHandler({ product });
  };

  const localCategoryHandler = (local: string) => {
    setLocal(local);
    history.push({
      pathname: "/",
      search: `${(local || product) && `?`}${local && `local=${local}`}${
        local && product && `&`
      }${product && `product=${product}`}`,
    });
    listProductMainHandler({ local });
  };

  useEffect(() => {
    const local =
      location.search.split("local=").length > 1
        ? location.search.split("local=")[1].split("&")[0]
        : undefined;
    if (local) setLocal(local);
    const product =
      location.search.split("product=").length > 1
        ? location.search.split("product=")[1].split("&")[0]
        : undefined;
    if (product) setProduct(product);
    listProductMainHandler({ local, product });
  }, []);

  return (
    <div className="contentWrap">
      <LayoutTop title={"nothing"} />
      {!loading && getBannerOptionActiveData && (
        <main id="main">
          <ToTheTop posi={`bottom-5 sm:bottom-10 right-5 sm:right-10`} />
          <Banner />
          <Category
            local={local}
            product={product}
            productCategoryHandler={productCategoryHandler}
            localCategoryHandler={localCategoryHandler}
          />
          <SubCommonMainOfflineClass
            data={data?.listProductMain.offlineClasses}
            refetch={refetch}
          />
          <SubCommonMainOnlineClass
            data={data?.listProductMain.onlineClasses}
            refetch={refetch}
          />
          <Magazine />
          <Place />
          <Instagram />
        </main>
      )}
      <Footer />
    </div>
  );
};
