import React from "react";
import { useState } from "react";
import { useListProductMineInactive } from "../../../../hooks/useListProductMineInactive";
import moment from "moment";
import { upProductStatus } from "../../../../__generated__/upProductStatus";
import { useUpProductStatus } from "../../../../hooks/useUpProductStatus";
import {
  DelProductInput,
  ProductStatus,
  UpProductStatusInput,
} from "../../../../__generated__/globalTypes";
import { delProduct } from "../../../../__generated__/delProduct";
import {
  IDelProductInput,
  useDelProduct,
} from "../../../../hooks/useDelProduct";
import { VariablesAreInputTypesRule } from "graphql";

export const GetReady = () => {
  const pathname = document.location.pathname;
  const { data, loading, refetch } = useListProductMineInactive();

  const onUpCompleted = async (data: upProductStatus) => {
    const {
      upProductStatus: { ok, err },
    } = data;
    if (ok) {
      alert(`클래스 상태를 변경하였습니다`);
      await refetch();
    } else {
      alert(err);
    }
  };
  const [upProductStatus, { loading: upProductStatusLoading }] =
    useUpProductStatus(onUpCompleted);

  const upProductStatusHandler = (
    productIdToUp: number,
    productStatus: ProductStatus
  ) => {
    if (!upProductStatusLoading) {
      const upProductStatusInput: UpProductStatusInput = {
        productIdToUp,
        productStatus,
      };
      upProductStatus({ variables: { input: upProductStatusInput } });
    }
  };

  const onDelete = (data: delProduct) => {
    const {
      delProduct: { ok },
    } = data;
    if (ok) {
      alert("삭제하였습니다");
      refetch();
    }
  };
  const [delProduct, { loading: delProductLoading }] = useDelProduct(onDelete);
  const delProductHandler = ({ productIdToDel }: IDelProductInput) => {
    if (delProductLoading) return;
    if (window.confirm("정말로 삭제하시겠습니까")) {
      const input: DelProductInput = {
        productIdToDel,
      };
      delProduct({ variables: { input } });
    }
  };

  return (
    <div>
      <div className="bg-white p-4 rounded-2xl shadow-lg">
        <p className="text-main font-bold py-2">준비중인 강의</p>
        <table className="w-full">
          <thead>
            <tr className="bg-main  border-gray-300">
              <th className="text-white lg:table-cell hidden">생성일</th>
              <th className="text-white min-w-[180px]">강의명</th>
              <th className="text-white lg:table-cell hidden">현재진행</th>
              <th className="text-white lg:table-cell hidden">타입</th>
              <th className="text-white">기능</th>
            </tr>
          </thead>
          <tbody>
            {!loading &&
              data?.listProductMineInactive.products &&
              data?.listProductMineInactive.products.map((product) => (
                <tr
                  key={`inactive-${product.id}`}
                  className="border-b-2 border-gray-300  h-4"
                >
                  <td className="lg:table-cell hidden text-sm text-center py-1 px-1 h-full overflow-hidden ">
                    {moment(product.createdAt).format("YYYY년 M월 D일")}
                  </td>
                  <td className="text-sm text-center h-full relative">
                    <p className="h-full truncate py-1 px-1 w-full absolute left-0 top-0">
                      {product?.productInfo && product.productInfo.title}
                    </p>
                  </td>
                  <td className="lg:table-cell hidden text-sm text-center relative">
                    <p className="h-full truncate py-1 px-1 w-full absolute left-0 top-0">
                      {product.productStatus}
                    </p>
                  </td>
                  <td className="lg:table-cell hidden text-sm text-center py-1 px-1 h-full overflow-hidden ">
                    {product?.productType && product.productType}
                  </td>
                  <td className="text-sm text-center py-1 px-1 h-full overflow-hidden ">
                    <a
                      href={pathname + `/enroll/${product.id}`}
                      className=" hover:underline inline-block p-1 border border-grays rounded-md"
                    >
                      편집
                    </a>
                    {product.productStatus === ProductStatus.Ready ? (
                      <button
                        className="hover:underline whitespace-pre-wrap border bg-line text-white border-grays p-1 rounded-md ml-1"
                        onClick={() =>
                          upProductStatusHandler(
                            product.id,
                            ProductStatus.Request
                          )
                        }
                      >
                        {"오픈 신청"}
                      </button>
                    ) : (
                      <button
                        className="hover:underline whitespace-pre-wrap border border-grays p-1 rounded-md ml-1"
                        onClick={() =>
                          upProductStatusHandler(
                            product.id,
                            ProductStatus.Ready
                          )
                        }
                      >
                        {"신청 취소"}
                      </button>
                    )}
                    <button
                      className="hover:underline whitespace-pre-wrap border border-grays p-1 rounded-md ml-1"
                      onClick={() =>
                        delProductHandler({ productIdToDel: product.id })
                      }
                    >
                      {"삭제"}
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        {!loading && data?.listProductMineInactive.products?.length === 0 && (
          <p className="text-center text-black text-lg py-5">
            {" "}
            준비중인 강의가 없습니다.{" "}
          </p>
        )}
      </div>
    </div>
  );
};
