import React from "react";
import { gql, useLazyQuery, useQuery } from "@apollo/client";
import { getProduct, getProductVariables } from "../__generated__/getProduct";
import { DETAIL_CLASS_FRAGMENT } from "../fragments";

const GET_PRODUCT_QUERY = gql`
  query getProduct($input: GetProductInput!) {
    getProduct(input: $input) {
      ok
      err
      product {
        id
        like_count
        uuid
        createdAt
        productInfo {
          scheduledAt
          title
          price
          discountRate
          summary
          productCategory
          place
          max
          fee
          playTime
          materials
          category
          localCategory
        }
        productStatus
        productType
        productViews {
          id
          is_rep
          view {
            url
          }
        }
        productPage {
          page
        }
        productIntro {
          intro
        }
        productKit {
          desc
          view {
            filename
            url
          }
        }
        inst {
          id
          user {
            name
          }
          atelier {
            name
            intro
          }
        }
        promotionProducts {
          discountRate
          promotion {
            id
            badge
            badgeColor
          }
        }
      }
    }
  }
`;

export const useGetProduct = (onCompleted?: (data: getProduct) => void) => {
  return useLazyQuery<getProduct, getProductVariables>(GET_PRODUCT_QUERY, {
    onCompleted,
    fetchPolicy: "network-only",
  });
};
