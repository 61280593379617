import { ApolloError, gql, useMutation } from "@apollo/client";
import React from "react";
import {
  getBaroBillURLCERT,
  getBaroBillURLCERTVariables,
} from "../__generated__/getBaroBillURLCERT";

const GET_BAROBILL_URL_CERT = gql`
  mutation getBaroBillURLCERT($input: GetBaroBillURLCERTInput!) {
    getBaroBillURLCERT(input: $input) {
      ok
      err
      url
    }
  }
`;

export const useGetBaroBillURLCERT = (
  onCompleted?: (data: getBaroBillURLCERT) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<getBaroBillURLCERT, getBaroBillURLCERTVariables>(
    GET_BAROBILL_URL_CERT,
    {
      onCompleted,
      onError,
    }
  );
};
