import { ApolloError, gql, useMutation } from "@apollo/client";
import React from "react";
import {
  sendSignupEmail,
  sendSignupEmailVariables,
} from "../__generated__/sendSignupEmail";

const SEND_SIGNUP_EMAIL_MUTATION = gql`
  mutation sendSignupEmail($input: SendSignupEmailInput!) {
    sendSignupEmail(input: $input) {
      ok
      err
    }
  }
`;

export const useSendSignupEmail = (
  onCompleted?: (data: sendSignupEmail) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<sendSignupEmail, sendSignupEmailVariables>(
    SEND_SIGNUP_EMAIL_MUTATION,
    {
      onCompleted,
      onError,
    }
  );
};
