import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { calculate } from "../apollo";
import good from "../assets/icon/heart.svg";
import good_white from "../assets/icon/heart_white.svg";
import { promotionProductsDiscountRate } from "../constants";
import { useUpProductLike } from "../hooks/useUpProductLike";
import { UpProductLikeInput } from "../__generated__/globalTypes";
import { ProductType } from "../__generated__/globalTypes";

import {
  listProductMain_listProductMain_offlineClasses,
  listProductMain_listProductMain_onlineClasses,
} from "../__generated__/listProductMain";
import { listProductSub_listProductSub_products } from "../__generated__/listProductSub";
import { searchProductWAddress_searchProductWAddress_products } from "../__generated__/searchProductWAddress";
import { upProductLike } from "../__generated__/upProductLike";

interface IComponentProductWidgetProps {
  product:
    | listProductMain_listProductMain_offlineClasses
    | listProductMain_listProductMain_onlineClasses
    | searchProductWAddress_searchProductWAddress_products
    | listProductSub_listProductSub_products;
  key?: string;
  refetch?: () => void;
}

export const ComponentProductWidget: React.FC<IComponentProductWidgetProps> = ({
  product,
  key,
  refetch,
}) => {
  const onUpCompleted = async (data: upProductLike) => {
    const {
      upProductLike: { ok, err },
    } = data;
    if (ok) {
      if (refetch) {
        await refetch();
      }
    } else {
      console.error(err);
    }
  };
  const [upProductLike, { loading: upProductLikeLoading }] =
    useUpProductLike(onUpCompleted);
  const upProductLikeHandler = (productIdToUp: number) => {
    if (!upProductLikeLoading) {
      const upProductLikeInput: UpProductLikeInput = {
        productIdToUp,
      };
      upProductLike({ variables: { input: upProductLikeInput } });
    }
  };
  return (
    <div className="card relative grid grid-cols-2 sm:block py-4 sm:py-0 border-b border-[#F1F2EF] sm:border-0">
      <a
        href={`/class/${product.id}`}
        className="aspect-class overflow-hidden block w-full h-full sm:h-auto relative"
      >
        {product?.productViews &&
          product.productViews
            .filter((productView) => productView.is_rep)
            .map((productView) => (
              <img
                key={`class-view-${product.id}-${productView.id}`}
                src={productView?.view?.url && productView.view.url}
                alt={`${product?.productInfo?.title} 포스터`}
                className="absolute left-0 top-0 w-full h-full object-cover transform hover:scale-125 scale-100 transition-transform duration-300 rounded-md sm:rounded-none"
              />
            ))}
        {product.promotionProducts && product.promotionProducts.length > 0 && (
          <div className="absolute top-0 right-0 p-2">
            {product.promotionProducts.map((promotionProduct) => (
              <p
                key={`promotion-badge-${product.id}-${promotionProduct.promotion?.id}`}
                style={{
                  backgroundColor: `${promotionProduct.promotion?.badgeColor}`,
                }}
                className={`inline-block p-1 px-3 rounded-lg text-white text-sm lg:text-base`}
              >{`${promotionProduct.promotion?.badge}`}</p>
            ))}
          </div>
        )}
      </a>
      <div className="grid grid-rows-1 sm:block">
        <div className="text px-2 sm:p-2 sm:bg-[#F1F2EF]">
          <p className="tracking-tight text-sm sm:text-xs py-1 font-bold">
            {/* {product?.inst?.atelier?.name} */}
            {product?.inst.user?.name}
          </p>
          <p className="text-sm md:text-base md:leading-tight h-10 md:h-[48px] py-1 hover:underline cursor-pointer overflow-hidden ellipsis2">
            <Link to={`/class/${product.id}`}>
              {product?.productInfo?.title}
            </Link>
          </p>
        </div>
        <div
          className={`price px-3 py-1 ${
            product.productType === ProductType.Online
              ? `sm:bg-[#99AC69] text-[#99AC69]`
              : ``
          }${
            product.productType === ProductType.Offline
              ? `sm:bg-[#8CBDCB] text-[#8CBDCB]`
              : ``
          } sm:text-white flex flex-wrap h-14 xl:h-8`}
        >
          <div className="flex-1 flex flex-wrap flex-col xl:flex-row content-start xl:justify-between">
            <button onClick={() => upProductLikeHandler(product.id)}>
              <img
                src={good_white}
                alt="좋아요 아이콘"
                className="sm:inline-block w-4 hidden"
              />
              <img
                src={good}
                alt="좋아요 아이콘"
                className="sm:hidden inline-block w-4"
              />
              <span className="ml-1 text-[#E79191] sm:text-white text-xs sm:text-sm">
                {product?.like_count}
              </span>
            </button>
            {product.productInfo &&
              product.productInfo.discountRate +
                promotionProductsDiscountRate(product.promotionProducts) !==
                0 && (
                <p className="tracking-tight text-sm md:text-base font-bold">
                  {product?.productInfo?.discountRate +
                    promotionProductsDiscountRate(product.promotionProducts)}
                  %
                </p>
              )}
          </div>
          {product.productInfo &&
          product?.productInfo?.discountRate +
            promotionProductsDiscountRate(product.promotionProducts) ===
            0 ? (
            <span className="flex flex-wrap content-end xl:inline text-sm md:text-base font-bold">
              {product?.productInfo?.price?.toLocaleString()}원
            </span>
          ) : (
            <div className="flex flex-wrap content-end sm:block">
              <p className="tracking-tight flex flex-wrap flex-col justify-end xl:inline-block ml-3">
                <span className="block xl:inline text-xs line-through text-right xl:text-left">
                  {product?.productInfo?.price?.toLocaleString()}원
                </span>
                <span className="block xl:inline text-base font-bold xl:ml-2">
                  {product?.productInfo &&
                    calculate(
                      Number(product?.productInfo?.price),
                      product?.productInfo?.discountRate +
                        promotionProductsDiscountRate(product.promotionProducts)
                    ).toLocaleString()}
                  원
                </span>
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
