import React from "react";
import { listProduct_listProduct_offlineClasses_promotionProducts } from "./__generated__/listProduct";
import {
  listProductMain_listProductMain,
  listProductMain_listProductMain_onlineClasses_promotionProducts,
} from "./__generated__/listProductMain";
import { listProductSub_listProductSub_products_promotionProducts } from "./__generated__/listProductSub";
import { searchProductWAddress_searchProductWAddress_products_promotionProducts } from "./__generated__/searchProductWAddress";

export const pageListLength = 4;

export const promotionProductsDiscountRate = (
  promotionProducts:
    | listProductMain_listProductMain_onlineClasses_promotionProducts[]
    | listProduct_listProduct_offlineClasses_promotionProducts[]
    | searchProductWAddress_searchProductWAddress_products_promotionProducts[]
    | listProductSub_listProductSub_products_promotionProducts[]
    | null
): number => {
  var discountRate = 0;
  if (promotionProducts)
    promotionProducts?.forEach((promotionProduct) => {
      if (promotionProduct.discountRate > 0)
        discountRate += promotionProduct.discountRate;
    });
  return discountRate;
};
