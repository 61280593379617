import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { DOMRoutes } from "../constants/props";
import { HOME } from "../routes/home/home";
import { JOIN_agree } from "../routes/join/parts/agree";
import { JOIN_complete } from "../routes/join/parts/complete";
import { JOIN_dataform } from "../routes/join/parts/dataform";
import { JOIN_dataform_st } from "../routes/join/parts/dataform_st";
import { LOGIN } from "../routes/login/customer_login";
import { Find_pwd } from "../routes/login/find_pwd";
import { Find_id } from "../routes/login/find_id";
import { ADMIN_Login } from "../routes/login/admin_login";
import { SubCommonListMagazine } from "../sub-routes/sub-common-list-magazine";
import { SubWhatIsLocalPick } from "../sub-routes/sub-common-whatis-localpick";
import { SubCommonListPlace } from "../sub-routes/sub-common-list-place";
import { SubCommonDetailPlace } from "../sub-routes/sub-common-detail-place";
import { SubCommonDetailMagazine } from "../sub-routes/sub-common-detail-magazine";
import { SubCommonListOfflineClass } from "../sub-routes/sub-common-list-offline-class";
import { SubCommonListOnlineClass } from "../sub-routes/sub-common-list-online-class";
import { SubInstCollectJoin } from "../sub-routes/sub-Inst-collect-join";
import { Detail } from "../routes/detail/detail";
import { SubJoinSelectRole } from "../sub-routes/sub-join-select-role";
import { SubChangePassword } from "../sub-routes/sub-change-password";
import { CuratorCollectJoin } from "../sub-routes/sub-curator-collect-join";
import { JoinDataformCurator } from "../routes/join/parts/dataform_cu";
import { Search } from "../sub-routes/sub-common-list-search";
import { CommonJoinAgreeCurator } from "../routes/common-join-agree-curator";
import { Verify } from "crypto";
import { CommonVerifyGeneral } from "../routes/common-verify-general";

const LoggedOutRoutes: DOMRoutes[] = [
  {
    path: "/signin",
    key: "/signin",
    component: <LOGIN />,
  },

  {
    path: "/join/agree",
    key: "/join/agree",
    component: <JOIN_agree />,
  },
  {
    path: "/join/dataform",
    key: "/join/dataform",
    component: <JOIN_dataform />,
  },
  {
    path: "/join/instForm",
    key: "/join/instForm",
    component: <JOIN_dataform_st />,
  },
  {
    path: "/join/select",
    key: "/join/select",
    component: <SubJoinSelectRole />,
  },
  {
    path: "/join/complete",
    key: "/join/complete",
    component: <JOIN_complete />,
  },
  {
    path: "/findId",
    key: "/findId",
    component: <Find_id />,
  },
  {
    path: "/findPwd",
    key: "/findPwd",
    component: <Find_pwd />,
  },
  {
    path: "/admin/login",
    key: "/admin/login",
    component: <ADMIN_Login />,
  },
];

export const CommonRoutes: DOMRoutes[] = [
  { path: "/", key: "/", component: <HOME /> },
  {
    path: "/class/:key",
    key: "/class/:key",
    component: <Detail />,
  },
  {
    path: `/change-password/:code`,
    key: `/change-password/:code`,
    component: <SubChangePassword />,
  },
  {
    path: "/join/curator/:code",
    key: "/join/curator/:code",
    component: <JoinDataformCurator />,
  },
  {
    path: "/join/agree/curator/:code",
    key: "/join/agree/curator/:code",
    component: <CommonJoinAgreeCurator />,
  },
  {
    path: `/verify/:code`,
    key: `/verify/:code`,
    component: <CommonVerifyGeneral />,
  },
  {
    path: `/search`,
    key: `/search`,
    component: <Search />,
  },

  {
    path: "/classGoList",
    key: "/classGoList",
    component: <SubCommonListOfflineClass />,
  },
  {
    path: "/classComeList",
    key: "/classComeList",
    component: <SubCommonListOnlineClass />,
  },
  {
    path: "/magazine",
    key: "/magazine",
    component: <SubCommonListMagazine />,
  },
  {
    path: `/magazine/:key`,
    key: `/magazine/:key`,
    component: <SubCommonDetailMagazine />,
  },

  {
    path: `/whatIsLocalPick`,
    key: `/whatIsLocalPick`,
    component: <SubWhatIsLocalPick />,
  },
  {
    path: `/place`,
    key: `/place`,
    component: <SubCommonListPlace />,
  },
  {
    path: `/place/:key`,
    key: `/place/:key`,
    component: <SubCommonDetailPlace />,
  },
  {
    path: `/collectInst`,
    key: `/collectInst`,
    component: <SubInstCollectJoin />,
  },
  {
    path: `/collectCurator`,
    key: `/collectCurator`,
    component: <CuratorCollectJoin />,
  },
];

const AdminRoutes: DOMRoutes[] = [
  {
    path: "admin",
    key: "admin",
    component: <ADMIN_Login />,
  },
];

export const LoggedOutRouter = () => {
  return (
    <Router>
      <Routes>
        {LoggedOutRoutes.map((route) => (
          <Route key={route.key} path={route.path} element={route.component} />
        ))}
        {CommonRoutes.map((route) => (
          <Route key={route.key} path={route.path} element={route.component} />
        ))}
        {AdminRoutes.map((route) => (
          <Route key={route.key} path={route.path} element={route.component} />
        ))}
        <Route path={`/*`} element={<HOME />} />
      </Routes>
    </Router>
  );
};
