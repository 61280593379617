import { useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import ReactQuill, {Quill} from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useParams } from "react-router";
import {
  UpProductIntroInput,
  GetProductIntroInput,
} from "../../../../../__generated__/globalTypes";
import { upload } from "../../../../../functions/uploads";
import { useGetProductIntro } from "../../../../../hooks/useGetProductIntro";
import {
  useUpProductIntro,
  IUpProducIntroFormProps,
} from "../../../../../hooks/useUpProductIntro";
import {
  getProductIntro,
  getProductIntro_getProductIntro_productIntro,
} from "../../../../../__generated__/getProductIntro";
import { upProductIntro } from "../../../../../__generated__/upProductIntro";

export const Intro = () => {
  const params: any = useParams();
  const [called, setCalled] = useState<boolean>(false);
  const [originalProductIntro, setOriginalProductIntro] =
    useState<getProductIntro_getProductIntro_productIntro>();
  const onGetCompleted = (data: getProductIntro) => {
    if (called) {
      setValue("intro", data.getProductIntro.productIntro?.intro);
      if (data.getProductIntro.productIntro)
        setOriginalProductIntro(data.getProductIntro.productIntro);
      setCalled(false);
    }
  };

  const onUpCompleted = async (data: upProductIntro) => {
    const {
      upProductIntro: { ok },
    } = data;
    if (ok) {
      await refetch();
      alert("상세정보가 저장되었습니다");
    }
  };

  const [
    getProductIntro,
    { data: getProductIntroData, loading: getProductIntroLoading, refetch },
  ] = useGetProductIntro(onGetCompleted);

  const { getValues, setValue, watch } = useForm<IUpProducIntroFormProps>({
    mode: "all",
  });

  const [upProductIntro, { data: upProductIntroData, loading }] =
    useUpProductIntro(onUpCompleted);

  const onSubmit = () => {
    let { intro } = getValues();
    if (originalProductIntro === null) {
      alert("기존 정보를 불러올 수 없습니다");
      return;
    }
    const productIntroIdToUp: number = originalProductIntro?.id
      ? originalProductIntro.id
      : 0;
    if (productIntroIdToUp === 0) {
      alert("강의정보를 수정할 수 없습니다.");
      return;
    }
    const upProductIntroInput: UpProductIntroInput = {
      ...(intro && originalProductIntro?.intro !== intro && { intro }),
      productIntroIdToUp,
    };
    upProductIntro({
      variables: {
        input: upProductIntroInput,
      },
    });
    setCalled(true);
  };
  const Font = ReactQuill
  const editorContent = watch("intro");

  const editorStateHandler = (editorState: any) => {
    setValue("intro", editorState);
  };

  const QuillRef = useRef<ReactQuill>();

  const imageHandler = () => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async (event: any) => {
      const file = input.files;
      if (!Boolean(file)) return;

      const result = await upload(event);
      if (result.data.ok) {
        const url = result.data.file.url;

        const range = QuillRef?.current?.getEditor().getSelection()?.index;
        if (range !== null && range !== undefined) {
          let quill = QuillRef.current?.getEditor();
          quill?.setSelection(range, 1);
          quill?.clipboard.dangerouslyPasteHTML(
            range,
            `<img src=${url} alt="이미지" />`
          );
        }
      } else {
        alert(
          `잘못된 파일이거나 사용할 수 없는 파일입니다. 문제가 반복해서 발생할 시 업체에 문의해주세요`
        );
      }
    };
  };

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          ["bold", "italic", "underline", "strike"], // toggled buttons
          ["blockquote"],
          [{ size: ["small", false, "large", "huge"] }],
          ["code-block"],
          [{ list: "ordered" }, { list: "bullet" }],
          [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          [{ color: [] }, { background: [] }], // dropdown with defaults from theme
          [{ font: [] }],
          [{ align: [] }],
          ["link", "image", "video"],
          ["clean"], // remove formatting button
        ],
        handlers: {
          image: imageHandler,
        },
      },
    }),
    []
  );
  
  useEffect(() => {
    if (params.key) {
      const getProductIntroInput: GetProductIntroInput = {
        productId: +params.key,
      };
      getProductIntro({ variables: { input: getProductIntroInput } });
      refetch();
      setCalled(true);
    }
  }, []);

  return (
    <div className="bg-white p-5 lg:p-10 ">
      <div className="max-w-[900px] pb-36 sm:pb-0">
        {!getProductIntroLoading && (
          <>
            <ReactQuill
              placeholder="상세페이지를 작성해주세요"
              className="w-full h-[450px] "
              ref={(element) => {
                if (element !== null) {
                  QuillRef.current = element;
                }
              }}
              modules={modules}
              value={editorContent ? editorContent : ""}
              onChange={editorStateHandler}
              defaultValue={
                getProductIntroData?.getProductIntro.productIntro?.intro
                  ? getProductIntroData.getProductIntro.productIntro.intro
                  : ""
              }
            ></ReactQuill>
            <div className="sm:bg-transparent bg-white w-full left-0 bottom-16 fixed sm:static text-center py-4 sm:pt-28 space-x-5">
              <button className="rounded-md border border-grays w-1/3 sm:w-[150px] py-1 hover:opacity-60">
                취소
              </button>
              <button
                onClick={onSubmit}
                className="rounded-md text-white w-1/3 sm:w-[150px] py-1 hover:opacity-60 bg-main border border-main"
              >
                저장
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
