import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import logo from "../../assets/icon/localpick_logo.svg";
import { adminSignIn, signIn } from "../../constants/token";
import { useAdminSignIn } from "../../hooks/useAdminSignin";
import { adminLogin } from "../../__generated__/adminLogin";
import { LoginInput } from "../../__generated__/globalTypes";
import { login } from "../../__generated__/login";
import { ILoginForm } from "./customer_login";
export const ADMIN_Login = () => {
  const {
    register,
    getValues,
    handleSubmit,
    setFocus,
    setValue,
    formState: { errors, isValid },
  } = useForm<ILoginForm>({ mode: "onChange" });

  const onCompleted = (data: adminLogin) => {
    const {
      adminLogin: { ok, token },
    } = data;
    if (ok && token) {
      adminSignIn(token);
    } else {
      alert("아이디와 비밀번호를 확인해주세요");
      setValue(`password`, ``);
    }
  };

  const [login, { data: loginResult, loading }] = useAdminSignIn(onCompleted);

  const onSubmit = () => {
    if (!loading) {
      const { email, password } = getValues();
      const loginInput: LoginInput = { email, password };
      login({
        variables: {
          loginInput,
        },
      });
    }
  };

  useEffect(() => {
    setFocus(`email`);
  }, []);

  return (
    <div className="bg-main bg-opacity-50 w-full h-full min-h-screen">
      <div className="max-w-screen-yl mx-auto w-full h-full min-h-screen bg-white flex flex-wrap justify-center content-center">
        <div className="logBox w-full max-w-xl">
          <img src={logo} alt="로로 로고" className="w-[200px] mx-auto" />
          <p className="text-center text-main">관리자</p>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="pt-10 mx-auto w-2/3"
          >
            <label className="flex flex-wrap py-5">
              <span className="inline-block w-[40px] text-main uppercase font-bold">
                id
              </span>
              <input
                {...register("email", { required: true })}
                type="text"
                className="flex-1 inline-block border-b border-main focus:outline-none pl-1 leading-normal w-10/12"
              />
            </label>
            <label className="flex flex-wrap py-5">
              <span className="inline-block w-[40px] text-main uppercase font-bold">
                pw
              </span>
              <input
                {...register("password", { required: true })}
                type="password"
                className=" flex-1 inline-block border-b border-main focus:outline-none tracking-tighter pl-1 leading-normal w-10/12"
              />
            </label>
            {loginResult?.adminLogin.err && (
              <p className="text-center text-sm text-reds">
                {loginResult.adminLogin.err}
              </p>
            )}
            <button
              type={`submit`}
              className="mt-10 bg-main text-white text-bold flex flex-wrap content-center justify-center w-full sm:w-[300px] py-2 mx-auto hover:opacity-80 "
            >
              관리자 로그인
            </button>
          </form>

          <p className="pt-3 text-center text-xs">
            <Link to="/findId" className="hover:underline">
              ID찾기
            </Link>{" "}
            /
            <Link to="/findPwd" className="hover:underline">
              비밀번호 찾기
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};
