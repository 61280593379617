import { ApolloError, gql, useMutation } from "@apollo/client";
import React from "react";
import { delBanner, delBannerVariables } from "../__generated__/delBanner";

const DEL_BANNER_MUTATION = gql`
  mutation delBanner($input: DelBannerInput!) {
    delBanner(input: $input) {
      ok
      err
    }
  }
`;

export const useDelBanner = (
  onCompleted?: (data: delBanner) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<delBanner, delBannerVariables>(DEL_BANNER_MUTATION, {
    onCompleted,
    onError,
  });
};
