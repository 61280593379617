import React, { useEffect, useState } from "react";
import {
  CanWriteProductReviewInput,
  CrProductReviewInput,
  ProductType,
} from "../__generated__/globalTypes";
import { useForm } from "react-hook-form";
import { crProductReview } from "../__generated__/crProductReview";
import { TextareaAutosize } from "@mui/material";
import { ReplyButton } from "./button";
import {
  ICrProductReviewFormProps,
  useCrProductReview,
} from "../hooks/useCrProductReview";
import { useMe } from "../hooks/useMe";
import { useCanWriteProductReview } from "../hooks/useCanWriteProductReview";
import { payParams } from "../interfaces/imp";
import { useParams } from "react-router";

interface IComponentCrProductReviewProps {
  productId: number;
  productType: ProductType;
  listRefetch: () => void;
}

export const ComponentCrProductReview: React.FC<
  IComponentCrProductReviewProps
> = ({ productId, productType, listRefetch }) => {
  const params = useParams();

  const {
    register,
    getValues,
    setValue,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<ICrProductReviewFormProps>({ mode: "all" });

  const { data: me } = useMe();

  const [
    canWriteProductReview,
    { data: canWriteProductReviewData, loading: canWriteProductReviewLoading },
  ] = useCanWriteProductReview();
  const canWriteProductReviewHandler = (productId: number) => {
    const input: CanWriteProductReviewInput = {
      productId,
    };
    canWriteProductReview({ variables: { input } });
  };

  const onCrComplete = async (data: crProductReview) => {
    const {
      crProductReview: { ok, err },
    } = data;
    if (ok) {
      setValue("text", "");
      listRefetch();
    }
    if (!ok) {
      alert(err);
    }
  };
  const [crProductReview, { loading: crProductReviewLoading }] =
    useCrProductReview(onCrComplete);
  const onSubmit = () => {
    if (!crProductReviewLoading) {
      const { text } = getValues();
      const input: CrProductReviewInput = {
        productId,
        is_private: false,
        text,
      };
      crProductReview({ variables: { input } });
    }
  };

  useEffect(() => {
    if (params.key && me) {
      canWriteProductReviewHandler(+params.key);
    }
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <p className="font-bold text-sm px-2 pb-3 ">후기를 남겨주세요</p>
      <div className="border border-grays rounded">
        <TextareaAutosize
          {...register(`text`, {
            required: "필수 정보입니다",
          })}
          minRows="5"
          disabled={
            !me || !canWriteProductReviewData?.canWriteProductReview.ok
              ? true
              : false
          }
          placeholder={
            !me
              ? `로그인을 해주세요`
              : !canWriteProductReviewData?.canWriteProductReview.ok
              ? `구매를 하셔야 후기를 남길 수 있습니다`
              : `후기를 남겨주세요`
          }
          onKeyPress={(e) => {
            if (e.code === "Enter" && e.ctrlKey === true) {
              onSubmit();
            }
          }}
          className="w-full inline-block resize-none focus:outline-none p-2 text-sm lg:text-base rounded"
        />
      </div>
      <div className="pt-5">
        <p className="text-right px-1">
          <button
            className={`hover:opacity-60 inline-block w-1/3 max-w-[12rem] py-2 text-sm ${
              productType === ProductType.Offline
                ? "bg-[#8CBDCB]"
                : "bg-[#99AC69]"
            } text-white rounded-md ${
              !me || !canWriteProductReviewData?.canWriteProductReview.ok
                ? `pointer-events-none`
                : ``
            }`}
          >
            작성 완료
          </button>
        </p>
      </div>
    </form>
  );
};
