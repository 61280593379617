export const errTexts = (err: any) => {
  var texts;
  switch (err) {
    case "-32000":
      texts = "이미 가입된 연계사업자 입니다.";
      break;
    case "-32001":
      texts = "사업자번호가 유효하지 않습니다.";
      break;
    case "-32002":
      texts = "회사명이 유효하지 않습니다.";
      break;
    case "-32003":
      texts = "대표자명이 유효하지 않습니다.";
      break;
    case "-32004":
      texts = "업태가 유효하지 않습니다.";
      break;
    case "-32005":
      texts = "업종이 유효하지 않습니다.";
      break;
    case "-32006":
      texts = "주소가 유효하지 않습니다.";
      break;
    case "-32008":
      texts = "담당자명이 유효하지 않습니다.";
      break;
    case "-32010":
      texts = "아이디가 유효하지 않습니다.";
      break;
    case "-32015":
      texts = "해당 아이디는 이미 사용중입니다.";
      break;
    case "-32011":
      texts = "패스워드가 유효하지 않습니다.";
      break;
    case "-32012":
      texts = "연락처가 유효하지 않습니다.";
      break;
    case "-32013":
      texts = "이메일이 유효하지 않습니다.";
      break;
    case "-32016":
      texts = "해당 아이디를 찾을 수 없습니다.";
      break;
    case "-32017":
      texts = "탈퇴한 아이디입니다.";
      break;
  }
  return texts;
};

export const valueState = (state: any) => {
  var texts;
  switch (state) {
    case "Valid":
      texts = "인증서 사용 중";
      break;
    case "Expired":
      texts = "인증서 만료";
      break;
    case "InValid":
      texts = "등록 대기 중";
      break;
  }
  return texts;
};
