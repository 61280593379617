import React from "react";
import { gql, useLazyQuery, useQuery } from "@apollo/client";
import {
  listReceiptProduct,
  listReceiptProductVariables,
} from "../__generated__/listReceiptProduct";

const LIST_RECEIPT_PRODUCT_QUERY = gql`
  query listReceiptProduct($input: ListReceiptProductInput!) {
    listReceiptProduct(input: $input) {
      ok
      err
      receipts {
        id
        scheduledAt
        orderStatus
        is_expired
        paid_amount
        createdAt
        receiver
        phone
        address
        message
        status
        user {
          name
          address
          phone
        }
        pgOption {
          name
          receipt_url
          merchant_uid
          pay_method
        }
        product {
          productInfo {
            scheduledAt
            title
          }
          inst {
            id
            atelier {
              name
            }
            user {
              name
            }
          }
          createdAt
          productStatus
          productType
          productKit {
            id
            view {
              url
            }
            desc
          }
          productViews {
            index
            is_rep
            id
            view {
              url
            }
          }
        }
      }
      totalPages
      totalResults
      row
    }
  }
`;

export const useListReceiptProduct = (
  onCompleted?: (data: listReceiptProduct) => void
) => {
  return useLazyQuery<listReceiptProduct, listReceiptProductVariables>(
    LIST_RECEIPT_PRODUCT_QUERY,
    {
      onCompleted,
      fetchPolicy: `network-only`,
    }
  );
};
