export const userRule1 = `
로컬픽 이용약관

제1장 총칙

제1조(목적)
본 약관은 ㈜어블러 회사가 운영하는 인터넷 사이트 (www.hilocalpick.com 이하“로컬픽”)를 통하여 제공하는 전자상거래 및 인터넷 관련 서비스를 이용함에 있어 로컬픽의 이용자의 권리․의무 및 책임사항을 규정함을 목적으로 합니다.
※PC통신, 무선 등을 이용하는 전자상거래에 대해서도 그 성질에 반하지 않는 한 이 약관을 준용합니다.

제2조(정의)
1.“로컬픽”이란 ㈜어블러 회사가 재화 또는 상품 등을 이용자에게 제공하기 위하여 컴퓨터 등 정보통신설비를 이용하여 재화 등을 거래할 수 있도록 설정한 가상의 공간을 말하며, 아울러 로컬픽을 운영하는 사업자의 의미로도 사용합니다.
2.“이용자”란 “로컬픽”에 접속하여 이 약관에 따라 “로컬픽”가 제공하는 서비스를 받는 회원 및 비회원을 말합니다.
3.“회원”이라 함은 “로컬픽”에 회원등록을 한 자로서, 계속적으로 “로컬픽”이 제공하는 서비스를 이용할 수 있는 자를 말합니다.
4.“비회원”이라 함은 회원에 가입하지 않고 “로컬픽”이 제공하는 서비스를 이용하는 자를 말합니다.

제3조 (약관 등의 명시와 설명 및 개정)
1.“로컬픽”은 이 약관의 내용과 상호 및 대표자 성명, 영업소 소재지 주소(소비자의 불만을 처리할 수 있는 곳의 주소를 포함), 전화번호, 이메일주소, 사업자등록번호, 통신판매업신고번호, 개인정보관리책임자등을 이용자가 쉽게 알 수 있도록 “로컬픽” 초기 서비스화면(전면)에 게시합니다. 다만, 약관의 내용은 이용자가 연결화면을 통하여 볼 수 있도록 할 수 있습니다.
2.“로컬픽”은 이용자가 약관에 동의하기에 앞서 약관에 정하여져 있는 내용 중 청약철회․배송책임․환불조건 등과 같은 중요한 내용을 이용자가 이해할 수 있도록 별도의 연결화면 또는 팝업화면 등을 제공하여 이용자의 확인을 구하여야 합니다.
3.“로컬픽”이 「전자상거래 등에서의 소비자보호에 관한 법률」, 「약관의 규제에 관한 법률」, 「전자문서 및 전자거래기본법」, 「전자금융거래법」, 「전자서명법」, 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」, 「방문판매 등에 관한 법률」, 「소비자기본법」 등 관련 법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.
4.“로컬픽”이 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 로컬픽의 초기화면에 그 적용일자 7일 이전부터 적용일자 전일까지 공지합니다. 다만, 이용자에게 불리하게 약관내용을 변경하는 경우에는 최소한 30일 이상의 사전 유예기간을 두고 공지합니다. 이 경우 "로컬픽“는 개정 전 내용과 개정 후 내용을 명확하게 비교하여 이용자가 알기 쉽도록 표시합니다.
5.“로컬픽”이 약관을 개정할 경우에는 그 개정약관은 그 적용일자 이후에 체결되는 계약에만 적용되고 그 이전에 이미 체결된 계약에 대해서는 개정 전의 약관조항이 그대로 적용됩니다. 다만 이미 계약을 체결한 이용자가 개정약관 조항의 적용을 받기를 원하는 뜻을 제3항에 의한 개정약관의 공지기간 내에 “로컬픽”에 송신하여 “로컬픽”의 동의를 받은 경우에는 개정약관 조항이 적용됩니다.
6.이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 전자상거래 등에서의 소비자보호에 관한 법률, 약관의 규제 등에 관한 법률, 공정거래위원회가 정하는 전자상거래 등에서의 소비자 보호지침 및 관계법령 또는 상관례에 따릅니다.

제2장 서비스의 이용

제4조(서비스의 제공 및 변경)
1.“로컬픽”은 다음과 같은 업무를 수행합니다.
①재화 또는 용역에 대한 정보 제공 및 구매계약의 체결
②구매계약이 체결된 재화 또는 용역의 배송
③기타 “로컬픽”이 정하는 업무
2.“로컬픽”은 재화 또는 용역의 품절 또는 기술적 사양의 변경 등의 경우에는 장차 체결되는 계약에 의해 제공할 재화 또는 용역의 내용을 변경할 수 있습니다. 이 경우에는 변경된 재화 또는 용역의 내용 및 제공일자를 명시하여 현재의 재화 또는 용역의 내용을 게시한 곳에 즉시 공지합니다.
3.“로컬픽”이 제공하기로 이용자와 계약을 체결한 서비스의 내용을 재화등의 품절 또는 기술적 사양의 변경 등의 사유로 변경할 경우에는 그 사유를 이용자에게 통지 가능한 주소로 즉시 통지합니다.
4.전항의 경우 “로컬픽”은 이로 인하여 이용자가 입은 손해를 배상합니다. 다만, “로컬픽”이 고의 또는 과실이 없음을 입증하는 경우에는 그러하지 아니합니다.

제5조(서비스의 중단)
1.“로컬픽”은 컴퓨터 등 정보통신설비의 보수점검․교체 및 고장, 통신의 두절 등의 사유가 발생한 경우에는 서비스의 제공을 일시적으로 중단할 수 있습니다.
2.“로컬픽”은 제1항의 사유로 서비스의 제공이 일시적으로 중단됨으로 인하여 이용자 또는 제3자가 입은 손해에 대하여 배상합니다. 단, “로컬픽”이 고의 또는 과실이 없음을 입증하는 경우에는 그러하지 아니합니다.
3."로컬픽"은 제1항의 사유로 서비스의 제공이 일시적으로 중단됨으로 인하여 이용자 또는 제3자가 입은 손해에 대하여 "회사"의 고의성의 없는 경우에는 배상하지 아니합니다.
4. 사업종목의 전환, 사업의 포기, 업체 간의 통합 등의 이유로 서비스를 제공할 수 없게 되는 경우에는 “회사”는 제10조에 정한 방법으로 이용자에게 통지하고 당초 “회사”에서 제시한 조건에 따라 소비자에게 보상합니다.

제3장 회원가입 계약

제6조(회원가입)
1.이용자는 무료로 회원가입할 수 있으며 “로컬픽”이 정한 가입 양식에 따라 회원정보를 기입한 후 이 약관에 동의한다는 의사표시를 함으로서 회원가입을 신청합니다.
2.“로컬픽”은 제1항과 같이 회원으로 가입할 것을 신청한 이용자 중 다음 각 호에 해당하지 않는 한 회원으로 등록합니다.
①가입신청자가 이 약관 제7조제3항에 의하여 이전에 회원자격을 상실한 적이 있는 경우, 다만 제7조제3항에 의한 회원자격 상실 후 3년이 경과한 자로서 “로컬픽”의 회원재가입 승낙을 얻은 경우에는 예외로 한다.
②등록 내용에 허위, 기재누락, 오기가 있는 경우
③기타 회원으로 등록하는 것이 “로컬픽”의 기술상 현저히 지장이 있다고 판단되는 경우
3.회원가입계약의 성립 시기는 “로컬픽”의 승낙이 회원에게 도달한 시점으로 합니다.
4."회원"은 "회사"에 등록한 회원정보에 변경이 있는 경우, 즉시 "로컬픽"에서 정하는 방법에 따라 해당 변경사항을 "회사"에게 통지하거나 수정하여야 합니다.

제7조(회원 탈퇴 및 자격 상실 등)
1.회원은 “로컬픽”에 언제든지 탈퇴를 요청할 수 있으며 “로컬픽”은 즉시 회원탈퇴를 처리합니다. 단, 해지의사를 통지하기 전에 현재 진행중인 모든 상품의 거래를 완료하거나 철회 또는 취소하여야 하며, 거래의 철회 또는 취소로 인한 불이익은 회원 본인이 부담하여야 합니다. 회원 탈퇴로 인해 발생한 불이익에 대한 책임은 회원 본인이 져야 하며, 이용계약이 종료되면 회사는 회원에게 부가적으로 제공한 각종 혜택을 회수할 수 있습니다.
2.회원이 다음 각 호의 사유에 해당하는 경우, “로컬픽”은 회원자격을 제한 및 정지시킬 수 있습니다.
①가입 신청 시에 허위 내용을 등록한 경우
②“로컬픽”을 이용하여 구입한 재화 등의 대금, 기타 “로컬픽”이용에 관련하여 회원이 부담하는 채무를 기일에 지급하지 않는 경우
③타인의 ID와 비밀번호 또는 그 개인정보를 도용한 경우
④다른 사람의 “로컬픽” 이용을 방해하거나 그 정보를 도용하는 등 전자상거래 질서를 위협하는 경우
⑤“로컬픽”을 이용하여 법령 또는 이 약관이 금지하거나 공서양속에 반하는 행위를 하는 경우
⑥기타 서비스 운영을 고의로 방해하는 행위를 하는 경우
3.“로컬픽”이 회원 자격을 제한․정지 시킨 후, 동일한 행위가 2회 이상 반복되거나 30일 이내에 그 사유가 시정되지 아니하는 경우 “로컬픽”은 회원자격을 상실시킬 수 있습니다.
4.“로컬픽”이 회원자격을 상실시키는 경우에는 회원등록을 말소합니다. 이 경우 회원에게 이를 통지하고, 회원등록 말소 전에 최소한 30일 이상의 기간을 정하여 소명할 기회를 부여합니다.

제8조(회원정보의 변경)
1."회원"은 개인정보관리화면을 통하여 언제든지 자신의 개인정보를 열람하고 수정할 수 있습니다.
2."회원"은 회원가입신청 시 기재한 사항이 변경되었을 경우 온라인으로 수정을 하거나 전자우편 기타 방법으로 "로컬픽"에 대하여 그 변경사항을 알려야 합니다.
3. "로컬픽"은 제2항의 변경사항을 "로컬픽"에 알리지 않아 발생한 불이익에 대하여 "로컬픽"은 책임지지 않습니다.

제9조(회원에 대한 통지)
1.회원에 대한 통지를 하는 경우 "로컬픽"은 회원이 등록한 e-mail 주소 또는 SMS 등으로 할 수 있습니다.
2."로컬픽"은 불특정 다수 회원에 대한 통지의 경우 서비스 게시판 등에 게시함으로써 개별 통지에 갈음할 수 있습니다.

제10조(회원의 정보 보안)
1. 회사 서비스 가입절차를 완료하는 순간부터 회원은 입력한 정보의 비밀을 유지할 책임이 있으며 ID와 비밀번호를 사용하여 발생하는 모든 결과에 대한 책임도 회원에게 있습니다.
2. ID와 비밀번호에 관한 모든 관리의 책임은 회원에게 있으며, ID 나 비밀번호가 부정하게 사용되었다는 사실을 발견한 경우에는 즉시 회사에 신고하여야 합니다. 신고하지 않음으로 인해 발생하는 모든 책임은 회원에게 있습니다.
3. 이용자는 회사 서비스의 사용 종료 시마다 정확히 로그아웃(Log-out)해야 하며, 로그아웃 하지 아니하여 제3자가 회원에 관한 정보를 도용하는 등의 결과로 인해 발생하는 손해 및 손실에 대하여 회사는 책임을 부담하지 아니합니다.

제4장 구매계약 및 대금 결제

제11조(구매신청 및 개인정보 제공 동의 등)
1.“로컬픽”이용자는 “로컬픽”상에서 다음 또는 이와 유사한 방법에 의하여 구매를 신청하며, “로컬픽”은 이용자가 구매신청을 함에 있어서 다음의 각 내용을 알기 쉽게 제공하여야 합니다.
①재화 등의 검색 및 선택
②받는 사람의 성명, 주소, 전화번호, 이메일 주소(또는 이동전화번호) 등의 입력
③약관내용, 청약철회권이 제한되는 서비스, 배송료․설치비 등의 비용부담과 관련한 내용에 대한 확인
④이 약관에 동의하고 위 3.호의 사항을 확인하거나 거부하는 표시 (예, 마우스 클릭)
⑤재화등의 구매신청 및 이에 관한 확인 또는 “로컬픽”의 확인에 대한 동의
⑥결제방법의 선택
2.“로컬픽”이 제3자에게 구매자 개인정보를 제공할 필요가 있는 경우 1) 개인정보를 제공받는 자, 2)개인정보를 제공받는 자의 개인정보 이용목적, 3) 제공하는 개인정보의 항목, 4) 개인정보를 제공받는 자의 개인정보 보유 및 이용기간을 구매자에게 알리고 동의를 받아야 합니다. (동의를 받은 사항이 변경되는 경우에도 같습니다.)
3.“로컬픽”이 제3자에게 구매자의 개인정보를 취급할 수 있도록 업무를 위탁하는 경우에는 1) 개인정보 취급위탁을 받는 자, 2) 개인정보 취급위탁을 하는 업무의 내용을 구매자에게 알리고 동의를 받아야 합니다. (동의를 받은 사항이 변경되는 경우에도 같습니다.) 다만, 서비스제공에 관한 계약이행을 위해 필요하고 구매자의 편의증진과 관련된 경우에는 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」에서 정하고 있는 방법으로 개인정보 취급방침을 통해 알림으로써 고지절차와 동의절차를 거치지 않아도 됩니다.

제12조(구매계약의 성립)
1.“로컬픽”은 제11조와 같은 구매신청에 대하여 다음 각 호에 해당하면 승낙하지 않을 수 있습니다. 다만, 미성년자와 계약을 체결하는 경우에는 법정대리인의 동의를 얻지 못하면 미성년자 본인 또는 법정대리인이 계약을 취소할 수 있다는 내용을 고지하여야 합니다.
①신청 내용에 허위, 기재누락, 오기가 있는 경우
②미성년자가 담배, 주류 등 청소년보호법에서 금지하는 재화 및 용역을 구매하는 경우
③기타 구매신청에 승낙하는 것이 “로컬픽” 기술상 현저히 지장이 있다고 판단하는 경우
④기타 제반 법령 및 정부의 가이드라인에 위반되는 경우
⑤만 14세 미만의 이용자가 유료서비스를 이용하고자 하는 경우에 부모 등 법정 대리인의 동의를 얻거나, 계약체결 후 추인을 얻지 않으면 미성년자 본인 또는 법정대리인이 그 계약을 취소할 수 있다는 내용을 계약체결 전에 고지하는 조치를 취합니다.
2.“로컬픽”의 승낙이 제11조제1항의 수신확인통지형태로 이용자에게 도달한 시점에 계약이 성립한 것으로 봅니다.
3.“로컬픽”의 승낙의 의사표시에는 이용자의 구매 신청에 대한 확인 및 판매가능 여부, 구매신청의 정정 취소 등에 관한 정보 등을 포함하여야 합니다.

제13조(지급방법)
1.“로컬픽”에서 구매한 재화 또는 용역에 대한 대금지급방법은 다음 각 호의 방법중 가용한 방법으로 할 수 있습니다.
2.“로컬픽”은 이용자의 지급방법에 대하여 재화 등의 대금에 PG사와 카드사에서 정하는 수수료(2.0%에서 4.0% 사이)와 “로컬픽”에서 정하는 수수료(클래스 등록 후의 경과된 기간에 따라 무료에서 7.5% 사이)와 원천세(3.3%에 해당 하는 금액을 공제하고 지급 할 수 있습니다. 단, 전자세금계산서 발급을 원하는 경우 별도의 요청 필요.
①폰뱅킹, 인터넷뱅킹, 메일 뱅킹 등의 각종 계좌이체
②선불카드, 직불카드, 신용카드 등의 각종 카드 결제
③온라인무통장입금
④전자화폐에 의한 결제
⑤수령 시 대금지급
⑥마일리지 등 “로컬픽”이 지급한 포인트에 의한 결제
⑦“로컬픽”과 계약을 맺었거나 “로컬픽”이 인정한 상품권에 의한 결제
⑧기타 전자적 지급 방법에 의한 대금 지급 등
3.구매대금의 결제와 관련하여 이용자가 입력한 정보 및 그와 관련된 책임은 이용자에게 있으며, 재화 또는 용역의 청약 후 합리적인 일정 기간 내에 결제가 이루어 지지 않는 경우 "회사"는 이에 해당주문을 취소할 수 있습니다.
4."로컬픽"은 구매자의 결제수단에 대하여 정당한 사용권한 보유여부를 확인할 수 있으며 필요한 경우 해당 거래진행의 정지 및 소명자료의 제출을 요청할 수 있습니다.

제14조(수신확인통지․구매신청 변경 및 취소)
1.“로컬픽”은 이용자의 구매신청이 있는 경우 이용자에게 수신확인통지를 합니다.
2.수신확인통지를 받은 이용자는 의사표시의 불일치 등이 있는 경우에는 수신확인통지를 받은 후 즉시 구매신청 변경 및 취소를 요청할 수 있고 “로컬픽”은 배송 전에 이용자의 요청이 있는 경우에는 지체 없이 그 요청에 따라 처리하여야 합니다. 다만 이미 대금을 지불한 경우에는 제17조의 청약철회 등에 관한 규정에 따릅니다.

제15조(“로컬픽”과 “Host/Guest” 정의)
“로컬픽”과 "Host"의 경우, 전자를 “로컬픽”(웹 사이트, 서비스 제공자)이라고 하고 후자의 경우 "로컬픽"상에서 상품(클래스 또는 체험 등)을 등록하여 운영(수익 및 홍보 활동 등)하는 "Host(작가/개인 등)"이라 한다. “Host”가 판매하는 재화의 수요자(구매자)를 “Guest”라 칭한다.

제16조(서비스 제공)
“로컬픽”에서 상품이라 지칭하는 재화는 온,오프라인 클래스 수강권 또는 DIY KIT, 또는 티켓, 체험권, 참여권 등이 이에 해당합니다.
1.“로컬픽”으로부터 구매한 상품은 정해진 날짜가 있는 경우 해당 날짜에 사용 할 수 있으며, 별도의 정해진 날짜가 없는 상품의 경우 Host와의 날짜 조율이 필요하며, 구매 전 해당 사항을 안내드립니다. 별도로 Host와의 조율이 된 경우 조율된 날짜로 진행 할 수 있습니다.
2.“로컬픽”은 이용자가 상품의 제공 절차 및 진행 사항을 확인할 수 있도록 적절한 안내 조치를 합니다.
3.“로컬픽”은 “Host”가 “Guest”와 행하는 거래(상품 구매)의 취소 및 환불과 분쟁 및 보증에 관해서는 17조(취소 및 환불)과 24조(분쟁해결) 그리고 18조(회사의 면책)을 따릅니다.

제17조(취소/교환/반품 정책)
1.“로컬픽”은 판매하는 상품별로 다음과 같이 상이한 환불 규정을 적용하며, “이용자”는 고객센터를 통해 취소/교환/반품 요청을 할 수 있습니다.
①집으로 오다 상품
`;
export const userRule2 = `
※강의 재생 시간과 관계없이 영상을 재생한 경우 수강한 것으로 간주합니다.
※“이용자” 사유로 키트를 환불하는 경우, 환불 금액에서 왕복 배송비가 차감됩니다.
※“이용자”사유로 키트를 교환하는 경우, 왕복 배송비를 지급해야 합니다.
※키트의 포장 또는 상품성이 훼손된 경우, 키트 결제금액 교환/환불이 불가할 수 있습니다.
②지역으로 가다
`;
export const userRule3 = `
※“이용자” 사유로 수업 당일 취소시 교환/환불이 불가할 수 있습니다.
2.이용자는 재화 등을 배송 받은 경우 다음 각호에 해당하는 경우에는 반품 및 교환을 할 수 없습니다. 
①이용자의 사용 또는 일부 소비에 의하여 재화 등의 가치가 현저히 감소한 경우
②시간의 경과에 의하여 재판매가 곤란할 정도로 재화 등의 가치가 현저히 감소한 경우
③같은 성능을 지닌 재화 등으로 복제가 가능한 경우 그 원본인 재화 등의 포장을 훼손한 경우
④그 밖에 거래의 안전을 위하여 대통령령이 정하는 경우
3.제2항 제2호 내지 제4호의 경우에는 "회사"가 사전에 청약 철회 등이 제한되는 사실을 소비자가 쉽게 알 수 있는 곳에 명기하거나 시용 상품을 제공하는 등의 조치를 하지 않았다면 이용자의 청약 철회 등이 제한되지 않습니다. 
4."이용자"는 제1항의 규정에도 불구하고 상품의 내용이 표시, 광고 내용과 다르거나 계약내용과 다르게 이행된 때에는 당해 상품을 공급받은 날로부터 3개월 이내, 그 사실을 인지한 날 또는 인지할 수 있었던 날로부터 30일 이내에 구매계약철회 등을 할 수 있습니다.
5.구매한 상품에 대한 취소 및 환불은 아래의 내용을 따릅니다. 취소 처리가 가능한 결제 방식에 대해서는 취소 처리드리며, 취소 처리가 불가한 방식에 대해서는 계좌 입금 방식으로 환불 처리를 드리게 됩니다. 사용하신 쿠폰 및 포인트가 있는 경우는 해당 금액을 제외하고 환불드리며, 쿠폰과 포인트는 사용 하신 쿠폰 또는 포인트의 형태로 재생성해 드립니다.
[취소 및 환불 약관]
- 정해진 사용 날짜가 있는 경우, 이틀 전까지 전액 환불 드리며, 수업 전날과 수업 당일은 환불이 불가합니다.
- 정해진 사용 날짜가 없는 상품의 경우, 구매 후 1개월 이내로는 환불 드리며, 1개월 이후로는 환불 드리지 않습니다.
- 이미 사용한 상품에 대해서는 환불이 불가합니다. 다만, Host의 동의가 있는 경우 환불이 가능합니다.
- Host의 사유로 인해 상품이 사용 되지 못한 경우에는 기간에 관계없이 환불이 가능합니다.

제18조(“로컬픽”의 면책)
1.“로컬픽”은 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 관한 책임이 면제됩니다.
2.“로컬픽”은 회원의 귀책사유로 인한 서비스 이용의 장애에 대하여 책임을 지지 않습니다.
3.“로컬픽”은 "Host"가 작성해 게시한 내용과 사진 및 기타 정보에 대한 신뢰도와 정확도에 대해 검증 할 책임이 있으며, 거래된 서비스에서 중대한 허위 사실이 규명될 경우 결제 금액 범위 내에서 보증에 대한 책임을 갖습니다. 다만, 게시되지 않은 내용과 사진 및 정보에 대해서는 책임을 갖지 않습니다.
4.“Host"와 ”Guest"가 “로컬픽”을 통해서가 아닌, 상호간 또는 제3자와 진행한 개별적인 거래에 대해서는 어떠한 책임도 지지 않습니다.
5."Host"와 "Guest"간의 서비스 제공, 결제 그리고 취소 및 환불 규정들은 “로컬픽” 이용 약관의 각 조항들을 따르며, 해당 조항들과 다르게 진행한다는 상호 협의가 있는 경우 이로 인해 발생하는 분쟁과 관련한 책임은 당사자들이 지며, “로컬픽”의 귀책사유가 없는 경우 책임을 갖지 않습니다.
6."Host"또는 “Guest"가 배송업체 또는 금융사(PG사, 카드사 등)와 분쟁이 발생한 경우 ”로컬픽“는 해당 배송업체 또는 금융사의 분쟁 해결 약관을 따라 해결할 책임을 갖습니다. 배송업체 및 금융사 그리고 "Host" 및 ”Guest"의 잘못이 명백한 경우에는 책임을 갖지 않습니다.

제5장 계약당사자의 의무

제19조(개인정보보호)
1.“로컬픽”은 이용자의 정보수집시 서비스의 제공에 필요한 최소한의 정보를 수집합니다. 다음 사항을 필수사항으로 하며 그 외 사항은 선택사항으로 합니다. 
① 성명
② 생년월일
③ E-mail(전자우편) 주소
④ 이동전화번호
⑤ 비밀번호 (회원의 경우)
⑥ 기타 "회사"가 필요하다고 인정하는 사항
2.“로컬픽”은 회원 가입 시 구매계약이행에 필요한 정보를 미리 수집하지 않습니다. 다만, 관련 법령상 의무이행을 위하여 구매계약 이전에 본인확인이 필요한 경우로서 최소한의 특정 개인정보를 수집하는 경우에는 그러하지 아니합니다.
3.“로컬픽”은 이용자의 개인정보를 수집·이용하는 때에는 당해 이용자에게 그 목적을 고지하고 동의를 받습니다.
4.“로컬픽”은 수집된 개인정보를 목적 외의 용도로 이용할 수 없으며, 새로운 이용목적이 발생한 경우 또는 제3자에게 제공하는 경우에는 이용·제공단계에서 당해 이용자에게 그 목적을 고지하고 동의를 받습니다. 다만, 관련 법령에 달리 정함이 있는 경우에는 예외로 합니다.
① 배송 업무상 배송 업체에게 배송에 필요한 최소한의 이용자의 정보(성명, 주소, 전화번호)를 알려주는 경우
② 정보통신서비스의 제공에 관한 계약의 이행을 위하여 필요한 개인정보로서 경제적, 기술적인 사유로 통상의 동의를 받는 것이 현저히 곤란한 경우
③ 재화 등의 거래에 따른 대금 정산을 위하여 필요한 경우
④ 도용 방지를 위하여 본인 확인에 필요한 경우
⑤ 법률의 규정 또는 법률에 의하여 필요한 불가피한 사유가 있는 경우
⑥ 신속한 이용문의 상담 및 이용자의 불만처리 업무를 대행하는 "회사"에 상담업무에 필요한 이용자의 정보를 제공하는 경우 
⑦ 회원가입 시 동의하신 제휴사별 제휴 업무 진행을 위한 필요로 하는 본인확인을 위한 최소한의 정보(성명, 성별, 생년월일, 주문상품평)를 제휴사에게 제공하는 경우
5.“로컬픽”이 제2항과 제3항에 의해 이용자의 동의를 받아야 하는 경우에는 개인정보관리 책임자의 신원(소속, 성명 및 전화번호, 기타 연락처), 정보의 수집목적 및 이용목적, 제3자에 대한 정보제공 관련사항(제공받은 자, 제공목적 및 제공할 정보의 내용) 등 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」 제22조제2항이 규정한 사항을 미리 명시하거나 고지해야 하며 이용자는 언제든지 이 동의를 철회할 수 있습니다.
6.이용자는 언제든지 “로컬픽”이 가지고 있는 자신의 개인정보에 대해 열람 및 오류정정을 요구할 수 있으며 “로컬픽”은 이에 대해 지체 없이 필요한 조치를 취할 의무를 집니다. 이용자가 오류의 정정을 요구한 경우에는 “로컬픽”은 그 오류를 정정할 때까지 당해 개인정보를 이용하지 않습니다.

7.“로컬픽”은 개인정보 보호를 위하여 이용자의 개인정보를 취급하는 자를 최소한으로 제한하여야 하며 신용카드, 은행계좌 등을 포함한 이용자의 개인정보의 분실, 도난, 유출, 동의 없는 제3자 제공, 변조 등으로 인한 이용자의 손해에 대하여 모든 책임을 집니다.
8.“로컬픽” 또는 그로부터 개인정보를 제공받은 제3자는 개인정보의 수집목적 또는 제공받은 목적을 달성한 때에는 당해 개인정보를 지체 없이 파기합니다.
9.“로컬픽”은 개인정보의 수집·이용·제공에 관한 동의 란을 미리 선택한 것으로 설정해두지 않습니다. 또한 개인정보의 수집·이용·제공에 관한 이용자의 동의거절시 제한되는 서비스를 구체적으로 명시하고, 필수수집항목이 아닌 개인정보의 수집·이용·제공에 관한 이용자의 동의 거절을 이유로 회원가입 등 서비스 제공을 제한하거나 거절하지 않습니다.

제20조(“로컬픽“의 의무)
1.“로컬픽”은 법령과 이 약관이 금지하거나 공서양속에 반하는 행위를 하지 않으며 이 약관이 정하는 바에 따라 지속적이고, 안정적으로 재화․용역을 제공하는데 최선을 다하여야 합니다.
2.“로컬픽”은 이용자가 안전하게 인터넷 서비스를 이용할 수 있도록 이용자의 개인정보(신용정보 포함)보호를 위한 보안 시스템을 갖추어야 합니다.
3.“로컬픽”이 상품이나 용역에 대하여 「표시․광고의 공정화에 관한 법률」 제3조 소정의 부당한 표시․광고행위를 함으로써 이용자가 손해를 입은 때에는 이를 배상할 책임을 집니다.
4.“로컬픽”은 수신거절의 의사를 명백히 표시한 이용자에 대해서는 영리목적의 광고성 이메일을 발송하지 않습니다.

제21조(회원의 ID 및 비밀번호에 대한 의무)
1.제17조의 경우를 제외한 ID와 비밀번호에 관한 관리책임은 회원에게 있습니다.
2.회원은 자신의 ID 및 비밀번호를 제3자에게 이용하게 해서는 안 됩니다.
3.회원이 자신의 ID 및 비밀번호를 도난당하거나 제3자가 사용하고 있음을 인지한 경우에는 바로 “로컬픽”에 통보하고 “로컬픽”의 안내가 있는 경우에는 그에 따라야 합니다.
4."로컬픽"은 회원이 상기 제1항, 제2항, 제3항을 위반하여 회원에게 발생한 손해에 대하여 아무런 책임을 부담하지 않습니다.

제22조(이용자의 의무)
이용자는 다음 행위를 하여서는 안 됩니다.
1.신청 또는 변경이 허위 내용의 등록
2.타인의 정보 도용
3.“로컬픽”에 게시된 정보의 변경
4.“로컬픽”이 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의 송신 또는 게시
5.“로컬픽” 기타 제3자의 저작권 등 지적재산권에 대한 침해
6.“로컬픽” 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위
7.외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는 정보를 로컬픽에 공개 또는 게시하는 행위

제23조(저작권의 귀속 및 이용제한)
1.“서비스 이용자(Host 유저와 Guest 유저“가 작성한 저작물에 대한 저작권 기타 지적재산권은 ”작성자“에 귀속합니다. 다만, "로컬픽"은 "로컬픽" 상에 작성된 저작물은 자유롭게 활용 가능함을 원칙으로 합니다.
2.이용자는 “로컬픽”을 이용함으로써 얻은 정보 중 “로컬픽”또는 타인이 작성한 지적재산권이 귀속된 정보를 “로컬픽”의 사전 승낙 없이 복제, 송신, 출판, 배포, 방송 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게 하여서는 안됩니다.
3.“로컬픽”은 영리목적으로 이용자에게 귀속된 저작물을 사용하는 경우 당해 이용자에게 통보하여야 합니다.

제6장 기타

제24조(분쟁해결)
1.“로컬픽”은 이용자가 제기하는 정당한 의견이나 불만을 반영하고 그 피해를 보상처리하기 위하여 피해보상처리기구를 설치․운영합니다.
2.“로컬픽”은 이용자로부터 제출되는 불만사항 및 의견은 우선적으로 그 사항을 처리합니다. 다만, 신속한 처리가 곤란한 경우에는 이용자에게 그 사유와 처리일정을 즉시 통보해 드립니다.
3.“로컬픽”과 이용자 간에 발생한 전자상거래 분쟁과 관련하여 이용자의 피해구제신청이 있는 경우에는 공정거래위원회 또는 시·도지사가 의뢰하는 분쟁조정기관의 조정에 따를 수 있습니다.

제23조(재판권 및 준거법)

1.“로컬픽”과 이용자 간에 발생한 전자상거래 분쟁에 관한 소송은 제소 당시의 이용자의 주소에 의하고, 주소가 없는 경우에는 거소를 관할하는 지방법원의 전속관할로 합니다. 다만, 제소 당시 이용자의 주소 또는 거소가 분명하지 않거나 외국 거주자의 경우에는 민사소송법상의 관할법원에 제기합니다.
2.“로컬픽”과 이용자 간에 제기된 전자상거래 소송에는 한국법을 적용합니다.


위의 이용 약관은 2022.02.03 부터 적용 됩니다.
    `;

export const personalInfo = `
로컬픽 개인정보처리방침

㈜어블러(이하“로컬픽”)는 고객님의 개인정보를 중요시하며, "정보통신망 이용촉진 및 정보보호"에 관한 법률을 준수하고 있습니다. 로컬픽은 개인정보취급방침을 통하여 고객님께서 제공하시는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며, 개인정보보호를 위해 어떠한 조치가 취해지고 있는지 알려드립니다.

1. 수집하는 개인정보 항목 및 수집방법

가. 수집하는 개인정보의 항목
① 로컬픽은 회원가입, 상담, 서비스 신청 등을 위해 아래와 같은 개인정보를 수집하고 있습니다.
- 이메일로 계정 등록한 경우: 이메일, 비밀번호, 이름, 성별, 닉네임, 생년월일, 휴대전화번호
- 재화 또는 서비스 제공 시: 이름, 이메일, 휴대전화번호, 주소, 자택 전화번호, 결제 정보(신용카드번호 등) 
② 서비스 이용 과정이나 사업 처리 과정에서 서비스이용기록, 접속로그, 쿠키, 접속 IP, 결제 기록, 불량이용 기록이 생성되어 수집될 수 있습니다.

나. 수집방법
로컬픽은 다음과 같은 방법으로 개인정보를 수집합니다.
- 서비스 가입이나 사용 중 이용자의 자발적 제공을 통한 수집
- 회원의 선택에 따라 카카오, 네이버, 페이스북, 구글 등의 아이디를 이용하여 로그인하는 회원의 경우 해당 협력회사로부터 자동으로 수집
- 그 외 협력회사로부터 제공
- 생성정보 수집 툴을 통한 수집

2. 개인정보의 수집 및 이용목적

로컬픽은 수집한 개인정보를 다음의 목적을 위해 활용합니다.
① 서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 요금정산
콘텐츠 제공, 구매 및 요금 결제, 물품배송 또는 청구지 등 발송, 금융거래 본인 인증 및 금융 서비스
② 회원 관리
회원제 서비스 이용에 따른 본인확인, 개인 식별, 불량회원의 부정 이용 방지와 비인가 사용 방지, 가입 의사 확인, 연령확인, 만14세 미만 아동 개인정보 수집 시 법정 대리인 동의여부 확인, 불만처리 등 민원처리, 고지사항 전달
③ 마케팅 및 광고에 활용
이벤트 등 광고성 정보 전달, 접속 빈도 파악 또는 회원의 서비스 이용에 대한 통계

3. 개인정보의 보유 및 이용기간

원칙적으로, 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체 없이 파기합니다. 단, 다음의 정보에 대해서는 아래의 이유로 명시한 기간 동안 보존합니다.

가. 회사 내부방침에 의한 정보보유 사유
회원이 탈퇴한 경우에도 불량회원의 부정한 이용의 재발을 방지, 분쟁해결 및 수사기관의 요청에 따른 협조를 위하여, 이용계약 해지일로부터 5년간 회원의 정보를 보유할 수 있습니다. 

나. 관련 법령에 의한 정보 보유 사유 
전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의 규정에 의하여 보존할 필요가 있는 경우 로컬픽은 아래와 같이 관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다.
① 계약 또는 청약철회 등에 관한 기록
-보존이유 : 전자상거래 등에서의 소비자보호에 관한 법률
-보존기간 : 5년
② 대금 결제 및 재화 등의 공급에 관한 기록
-보존이유: 전자상거래 등에서의 소비자보호에 관한 법률
-보존기간 : 5년 
③ 소비자 불만 또는 분쟁처리에 관한 기록
-보존이유 : 전자상거래 등에서의 소비자보호에 관한 법률
-보존기간 : 3년 
④ 로그 기록 
-보존이유: 통신비밀보호법
-보존기간 : 3개월

4. 개인정보의 파기절차 및 방법

로컬픽은 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체 없이 파기합니다. 파기절차 및 방법은 다음과 같습니다.
① 파기절차
회원님이 회원가입 등을 위해 입력하신 정보는 목적이 달성된 후 별도의 DB로 옮겨져(종이의 경우 별도의 서류함) 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라(보유 및 이용기간 참조) 일정 기간 저장된 후 파기 되어 집니다.
별도 DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 보유되어지는 이외의 다른 목적으로 이용되지 않습니다.
② 파기방법
전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.
* 로컬픽은 1년 동안 회사의 서비스를 이용하지 않은 이용자의 개인정보는 ‘정보통신망 이용촉진 및 정보보호등에 관한 법률 제29조’ 에 근거하여 이용자에게 사전통지하고 개인정보를 파기하거나 별도로 분리하여 저장 관리합니다.

5. 개인정보 제공

로컬픽은 이용자의 개인정보를 원칙적으로 외부에 제공하지 않습니다. 다만, 아래의 경우에는 예외로 합니다.
① 이용자들이 사전에 동의한 경우
② 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우

6. 수집한 개인정보의 위탁

로컬픽은 서비스 이행을 위해 아래와 같이 외부 전문업체에 위탁하여 운영하고 있습니다.
① 위탁 대상자 : 택배사 CJ택배, 우체국택배, 현대택배, 대한통운, 한진택배 외 기타택배사 
② 위탁업무 내용 : 물품 배송 등의 물류 업무
③ 위탁 대상자 : PG사 ㈜나이스정보통신, ㈜다날
④ 위탁업무 내용 : 결제처리(휴대폰, 무통장 입금, 계좌이체, 신용카드, 지류상품권 및 기타 결제수단, 환불계좌 인증) 및 결제도용 방지

7. 이용자 및 법정대리인의 권리와 그 행사방법

로컬픽은 이용자(만 14세 미만자인 경우에는 법정대리인)의 권리를 다음과 같이 보호하고 있습니다.
① 이용자는 언제든지 등록되어 있는 자신의 개인정보 또는 법정대리인의 경우 만 14세 미만자의 개인정보를 조회하거나 수정할 수 있으며 가입해지를 요청할 수도 있습니다.
② 이용자들의 개인정보 조회,수정을 위해서는 "개인정보변경"(또는 "회원정보수정" 등)을 가입해지(동의철회)를 위해서는 "회원탈퇴"를 클릭하여 본인 확인 절차를 거치신 후 직접 열람, 정정 또는 탈퇴가 가능합니다.
③ 혹은 개인정보관리책임자에게 서면, 전화 또는 이메일로 연락하시면 지체 없이 조치하겠습니다.
④ 귀하가 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기 전까지 당해 개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체 없이 통지하여 정정이 이루어지도록 하겠습니다.
⑤ 로컬픽은 이용자의 요청에 의해 해지 또는 삭제된 개인정보는 "회사가 수집하는 개인정보의 보유 및 이용기간"에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.

8. 개인정보 자동수집 장치의 설치, 운영 및 그 거부에 관한 사항

로컬픽은 귀하의 정보를 수시로 저장하고 찾아내는 "쿠키(cookie)" 등을 운용합니다. 쿠키란 웹사이트를 운영하는데 이용되는 서버가 귀하의 브라우저에 보내는 아주 작은 텍스트 파일로서 귀하의 컴퓨터 하드디스크에 저장됩니다.
로컬픽은 다음과 같은 목적을 위해 쿠키를 사용합니다.
① 쿠키 등 사용 목적
- 회원과 비회원의 접속 빈도나 방문 시간 등을 분석, 이용자의 취향과 관심분야를 파악 및 자취 추적, 각종 이벤트 참여 정도 및 방문 회수 파악 등을 통한 타겟 마케팅 및 개인 맞춤 서비스 제공
- 귀하는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, 귀하는 웹브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다.
② 쿠키 설정 거부 방법
- 쿠키 설정을 거부하는 방법으로는 회원님이 사용하시는 웹 브라우저의 옵션을 선택함으로써 모든 쿠키를 허용하거나 쿠키를 저장할 때마다 확인을 거치거나, 모든 쿠키의 저장을 거부할 수 있습니다.
- 설정방법 예(인터넷 익스플로러의 경우) : 웹 브라우저 상단의 도구 > 인터넷 옵션 > 개인정보
- 단, 귀하께서 쿠키 설치를 거부하였을 경우 서비스 제공에 어려움이 있을 수 있습니다.
③ 구글 애널리틱스를 이용한 웹로그 분석
로컬픽은 구글(Google)에서 제공하는 웹 분석 도구인 구글 애널리틱스를 이용하고 있으며, 이 경우 이용자 개인을 식별할 수 없도록 비식별화 처리된 정보를 이용합니다. 이용자는 구글 애널리틱스 Opt-out Browser Add-on 을 이용하거나, 쿠키 설정 거부를 통해 구글 애널리틱스 이용을 거부할 수 있습니다.

9. 개인정보에 관한 민원서비스

로컬픽은 고객의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여 아래와 같이 관련 부서 및 개인정보관리책임자를 지정하고 있습니다.

① 개인정보 관리 및 보호책임자 
- 성명 : 허은애
- 이메일 : eunae512@naver.com

② 귀하께서는 회사의 서비스를 이용하시며 발생하는 모든 개인정보보호 관련 민원을 개인정보관리책임자 혹은 담당부서로 신고하실 수 있습니다.
③ 로컬픽은 이용자들의 신고사항에 대해 신속하게 충분한 답변을 드릴 것입니다.

10. 기타

회사의 서비스 내에 링크되어 있는 웹사이트 등 타 서비스들이 개인정보를 수집하는 행위에 대해서는 본 로컬픽 개인정보처리방침이 적용되지 않음을 알려드립니다.

11. 고지의 의무

현 개인정보처리방침 내용 추가, 삭제 및 수정이 있을 시에는 시행일 최소 7일전부터 서비스 웹사이트(https://www.hilocalpick.com)의 '공지사항'을 통해 공고할 것입니다.

부칙
본 개인정보 이용정책은 2022년 02월 03일부터 적용됩니다.
`;

export const delrefund = `
<배송정책>

본 상품은 로컬픽이 아닌 어썸크래프트에서 개별 발송하는 상품으로, 배송 책임은 어썸크래프트에 있습니다

1) 배송가능지역: 전국
2) 택배사 : CJ 대한통운
3) 기본배송비:무료(도서산간 지역의 경우 추가 배송비가 부과됩니다.)
4) 배송기간
가. 출발일로부터 7~10일 소요됩니다.
나. 오후 12시 주문 건까지 당일 출고됩니다. (주말 및 공휴일 제외)
다. 택배사 일정에 따라 공휴일 전날에는 택배 발송이 어려울 수 있습니다.

<교환정책>

교환 배송 관련 안내 
* 준비물 키트에 한해서만 교환 가능합니다.
* 배송 완료일로부터 7일이내 교환 신청 가능합니다.
* 단순 변심 등 고객 사유로 인한 교환의 경우 왕복배송비를 지불해야하며, 제품 및 포장이 재판매 가능한 상태여야 합니다. (상품 및 구성품,  포장 훼손,  분실 시 교환 불가)

1) 배송비: 6,000원(왕복)
2) 지정택배사: CJ 대한통운
3) 교환 보내실 주소: 울산 중구 종가로 406-21 울산비즈파크 838호

* 반품 신청시, 담당자가 연락 후 반품 택배 접수를 도와드립니다. 연락없이 먼저 발송하지 말아주세요

<환불정책>

환불관련 안내
* 배송 완료일로부터 7일 이내 환불 요청 가능합니다.
* 제품 또는 포장이 훼손되어 재판매가 불가한 경우, 환불을 거절할 수 있습니다.
* 단순 변심 등 고객 사유로 인한 반품의 경우, 왕복 배송비가 차감됩니다.

1) 배송비: 6,000(왕복)
2) 지정택배사: CJ 대한통운
3) 반품 보내실 주소: 울산 중구 종가로 406-21 울산비즈파크 838호 

* 반품 신청시, 담당자가 연락 후 반품 택배 접수를 도와드립니다. 연락없이 먼저 발송하지 말아주세요


반품/교환 불가능 사유

1. 반품요청기간이 지난 경우
2. 구매자의 책임 있는 사유로 상품 등이 멸실 또는 훼손된 경우 (단, 상품의 내용을 확인하기 위하여 포장 등을 훼손한 경우는 제외)
3. 구매자의 책임있는 사유로 포장이 훼손되어 상품 가치가 현저히 상실된 경우
4. 구매자의 사용 또는 일부 소비에 의하여 상품의 가치가 현저히 감소한 경우
5. 시간의 경과에 의하여 재판매가 곤란할 정도로 상품 등의 가치가 현저히 감소한 경우
6. 고객의 요청사항에 맞춰 제작에 들어가는 맞춤제작상품의 경우
7. 복제가 가능한 상품 등의 포장을 훼손한 경우
`;

export const whatLocalMission1 =
  "로컬픽(Local Pick)의 미션은 트랜디한 지역 분위기를 담은 특별한 공간과 문화체험을 다양한 방식으로 고객에게 온,오프라인으로 이어주는 것입니다. 지역 공방, 복합문화공간, 클래스, Diy Kit를 큐레이팅하여 고객에게 전달함으로써 고객은 온라인을 통해 한눈에 문화체험, 공간 정보를 볼 수 있고 더나아가 오프라인 경험으로 이어지도록 이끌어 가려합니다. 궁극적으로 지역의 문화체험과 공간을 알림으로써 다같이 상생 할 수 있고 새로운 문화컨텐츠가 더 많이 나올 수 있도록 의미 있는 플랫폼이 되고자 합니다.";
export const whatLocalMission2 =
  "취향에 맞게 보고, 즐기고 싶은 사람들에게 유용하게 쓰일 만한 큐레이션 서비스 제공합니다. 로컬픽에서 추천하는 문화체험, 공간을 통해 다양한 지역의 분위기를 느껴보세요.";
