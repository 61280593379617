import React, { useEffect, useState } from "react";
import { ListMagazineInput } from "../__generated__/globalTypes";
import searchImg from "../assets/icon/search.png";
import { ISearchFormProps } from "../routes/admin-list-magazine";
import { useForm } from "react-hook-form";
import { TextButton } from "./button";
import { TableError } from "./table-error";
import { styled } from "@mui/material";
import usePagination from "@mui/material/usePagination";
import { useListPlaceActive } from "../hooks/useListPlaceActive";

interface IModalListPlace {
  onSelect: (productId: number) => void;
}

export const ModalListPlace: React.FC<IModalListPlace> = ({ onSelect }) => {
  const [page, setPage] = useState<number>(1);
  const [search, setSearch] = useState<string>("");

  const pageHandler = (page: number) => {
    setPage(page);
    listPlaceActiveHandler(page, search);
  };

  const { register, getValues, handleSubmit } = useForm<ISearchFormProps>();

  const onSearch = async () => {
    const { search } = getValues();
    if (search) setSearch(search);
    listPlaceActiveHandler(page, search);
  };

  const [listPlaceActive, { data, loading, refetch }] = useListPlaceActive();
  const listPlaceActiveHandler = (page: number, search: string) => {
    const input: ListMagazineInput = {
      page,
      search,
    };
    listPlaceActive({ variables: { input } });
  };

  const { items } = usePagination({
    count: data?.listPlaceActive.totalPages
      ? data.listPlaceActive.totalPages
      : 1,
  });

  const List = styled("ul")({
    listStyle: "none",
    paddingTop: 20,
    textAlign: "center",
    display: "block",
  });

  useEffect(() => {
    listPlaceActiveHandler(page, search);
  }, []);

  return (
    <div className="modal">
      <div className="text-right pb-8">
        <form onSubmit={handleSubmit(onSearch)}>
          <p className="inline-block max-w-md w-full text-right relative shadow-md">
            <input
              {...register(`search`)}
              type="text"
              className="w-full py-1 px-4 bg-white rounded-full"
              placeholder="플레이스 이름으로 검색"
            />
            <button className="absolute right-3 top-1">
              <img src={searchImg} alt="찾기" className="w-5" />
            </button>
          </p>
        </form>
      </div>
      {/* 여기 */}
      <div className="bg-white px-5 pb-5 rounded-md shadow-lg min-w-max">
        <p className="text-main font-bold pb-3">플레이스 목록</p>
        <table className="w-full">
          <colgroup>
            <col className="w-2/12" />
            <col className="w-5/12" />
            <col className="w-1/12" />
            <col className="w-2/12" />
            <col className="w-2/12" />
          </colgroup>
          <thead>
            <tr className="bg-main  border-gray-300">
              <th className="text-white ">번호</th>
              <th className="text-white ">제목</th>
              <th className="text-white ">작성자</th>
              <th className="text-white ">주소</th>
              <th className="text-white ">기능</th>
            </tr>
          </thead>
          <tbody>
            {!loading &&
              data?.listPlaceActive.products &&
              data.listPlaceActive.products.map((place) => (
                <tr
                  key={`magazine-${place?.id}`}
                  className="border-b-2 border-gray-300 h-4"
                >
                  <td className="text-sm text-center py-1 px-1 h-full overflow-hidden">
                    {place?.uuid && place.uuid}
                  </td>
                  <td className="text-sm text-center h-full relative">
                    {place?.productInfo?.title && `${place.productInfo.title}`}
                  </td>
                  <td className="text-sm text-center  relative">
                    {place?.inst.user?.name && `${place.inst.user.name}`}
                  </td>
                  <td className="text-sm text-center py-1 px-1 h-full overflow-hidden">
                    {place?.productInfo?.place && `${place.productInfo.place}`}
                  </td>
                  <td className="text-sm text-center py-1 px-1 h-full w-1/6 overflow-hidden">
                    <TextButton
                      canClick={!loading}
                      actionText={`선택`}
                      onClick={() => onSelect(place.id)}
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        {loading && <TableError errorMessage={`정보를 불러오는 중입니다`} />}
        {!loading &&
          data?.listPlaceActive.products &&
          data.listPlaceActive.products.length < 1 && (
            <TableError errorMessage={`해당하는 강의 정보가 없습니다`} />
          )}
        {!loading &&
          data?.listPlaceActive.products &&
          data.listPlaceActive.products.length > 0 && (
            <List>
              {items.map(({ page, type, selected, ...item }, index) => {
                let children = null;

                if (type === "start-ellipsis" || type === "end-ellipsis") {
                  children = "…";
                } else if (type === "page") {
                  children = (
                    <button
                      type="button"
                      style={{
                        fontWeight: selected ? "bold" : undefined,
                      }}
                      {...item}
                      onClick={() => pageHandler(page)}
                    >
                      {page}
                    </button>
                  );
                } else if (type === "previous") {
                  children = (
                    <button type="button" {...item}>
                      <i className="fas fa-angle-left"></i>
                    </button>
                  );
                } else if (type === "next") {
                  children = (
                    <button type="button" {...item}>
                      <i className="fas fa-angle-right"></i>
                    </button>
                  );
                }
                return (
                  <li
                    key={index + type}
                    className={`text-xs px-2  border-grays inline-block ${
                      (type === "page" &&
                        page !== data.listPlaceActive.products?.length &&
                        items.length %
                          Number(data.listPlaceActive.products?.length) >
                          3 &&
                        "border-r") ||
                      (type === "end-ellipsis" && "border-r") ||
                      (type === "start-ellipsis" && "border-r")
                    }`}
                  >
                    {children}
                  </li>
                );
              })}
            </List>
          )}
      </div>
    </div>
  );
};
