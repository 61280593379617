import React from "react";
import { gql, useLazyQuery, useQuery } from "@apollo/client";
import {
  listVerifiedInst,
  listVerifiedInstVariables,
} from "../__generated__/listVerifiedInst";
import { ListVerifiedInstInput } from "../__generated__/globalTypes";
import {
  listUnverifiedInst,
  listUnverifiedInstVariables,
} from "../__generated__/listUnverifiedInst";

const LIST_UNVERIFIED_INST_QUERY = gql`
  query listUnverifiedInst($input: ListUnverifiedInstInput!) {
    listUnverifiedInst(input: $input) {
      ok
      err
      totalResults
      totalPages
      row
      insts {
        id
        level
        verified
        businessNumber
        businessFile {
          uuid
          url
          mimetype
        }
        atelier {
          id
          name
        }
        user {
          id
          name
          phone
        }
      }
    }
  }
`;

export const useListUnverifiedInst = (
  onCompleted?: (data: listUnverifiedInst) => void
) => {
  return useLazyQuery<listUnverifiedInst, listUnverifiedInstVariables>(
    LIST_UNVERIFIED_INST_QUERY,
    {
      onCompleted,
      fetchPolicy: "network-only",
    }
  );
};
