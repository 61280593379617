import React from "react";
import gql from "graphql-tag";
import { ApolloError, useMutation } from "@apollo/client";
import {
  changeReceiptOrder,
  changeReceiptOrderVariables,
} from "../__generated__/changeReceiptOrder";

const CHANGE_RECEIPT_ORDER_MUTATION = gql`
  mutation changeReceiptOrder($input: ChangeReceiptOrderInput!) {
    changeReceiptOrder(input: $input) {
      ok
      err
    }
  }
`;

export const useChangeReceiptOrder = (
  onCompleted?: (data: changeReceiptOrder) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<changeReceiptOrder, changeReceiptOrderVariables>(
    CHANGE_RECEIPT_ORDER_MUTATION,
    {
      onCompleted,
      onError,
    }
  );
};
