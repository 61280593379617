import React from "react";
import { gql, useLazyQuery, useQuery } from "@apollo/client";
import {
  listVerifiedInst,
  listVerifiedInstVariables,
} from "../__generated__/listVerifiedInst";
import { ListVerifiedInstInput } from "../__generated__/globalTypes";

const LIST_VERIFIED_INST_QUERY = gql`
  query listVerifiedInst($input: ListVerifiedInstInput!) {
    listVerifiedInst(input: $input) {
      ok
      err
      totalResults
      totalPages
      row
      insts {
        id
        level
        verified
        businessNumber
        businessFile {
          uuid
          url
          mimetype
        }
        curator {
          name
          id
        }
        atelier {
          id
          name
        }
        user {
          id
          name
          phone
        }
      }
    }
  }
`;

export const useListVerifiedInst = (
  onCompleted?: (data: listVerifiedInst) => void
) => {
  return useLazyQuery<listVerifiedInst, listVerifiedInstVariables>(
    LIST_VERIFIED_INST_QUERY,
    {
      onCompleted,
      fetchPolicy: "network-only",
    }
  );
};
