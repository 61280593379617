import React from "react";
import gql from "graphql-tag";
import { ApolloError, useMutation } from "@apollo/client";
import {
  delProductView,
  delProductViewVariables,
} from "../__generated__/delProductView";

const DEL_PRODUCT_VIEW_MUTATION = gql`
  mutation delProductView($input: DelProductViewInput!) {
    delProductView(input: $input) {
      ok
      err
    }
  }
`;

export const useDelProductView = (
  onCompleted?: (data: delProductView) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<delProductView, delProductViewVariables>(
    DEL_PRODUCT_VIEW_MUTATION,
    {
      onCompleted,
      onError,
    }
  );
};
