import { useEffect } from "react";
import Instafeed from "../../../constants/instafeed";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
export const Instagram = () => {
  useEffect(() => {
    var feed = new Instafeed({
      target: "ins",
      template:
        '<div><a target="_blank" href="{{link}}"><img title="{{caption}}" src="{{image}}" className="aspect-insta"/></a></div>',
      limit: 5,
      accessToken:
        "IGQVJWYkZAZAbV9VS0lubGl3WEoxOWRNYmNzb2hSUVV2TnNWdDNoNzdmMFpDT2V3RWRaRHlMNDdZAV0k2OEZAqSFVqLTVTY0lQTDhhd2lWV1NLZAjNnX2pqNUtUVURMYzIzRDRQUFZAGSlNFWHNDTU1ucXhpagZDZD",
    });
    feed.run();
  }, []);
  return (
    <div className="bg-[#ebeee7] py-5">
      <div>
        <p className="text-[#A09B99] py-8 sansation text-3xl text-center font-bold">
          @class_awesome
        </p>
        <div className="max-w-screen-yl mx-auto overflow-x-auto scrollInvisible">
          <div
            id="ins"
            className="min-w-[630px] grid grid-cols-5 gap-x-3 md:gap-x-5 p-5 "
          ></div>
        </div>
      </div>
    </div>
  );
};
