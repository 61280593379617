import { useEffect, useState } from "react";
import logo from "../../../assets/icon/localpick_logo.svg";
import DaumPostcode from "react-daum-postcode";
import { Link } from "react-router-dom";
import { Modal } from "@mui/material";
import { cate_region } from "../../home/parts/data";
import { useForm } from "react-hook-form";
import {
  ICrUserInstFormProps,
  useCrUserInst,
} from "../../../hooks/useCrUserInst";
import { crUserInst } from "../../../__generated__/crUserInst";
import { checkEmail } from "../../../__generated__/checkEmail";
import {
  CheckEmailInput,
  CrUserInstInput,
} from "../../../__generated__/globalTypes";
import { useCheckEmail } from "../../../hooks/useCheckEmail";
import { FormError3 } from "../../../components/form-error";
import { upload, uploadBusiness } from "../../../functions/uploads";
import {
  LocalCategoryOption,
  LocalCategoryOptionType,
} from "../../../constants/enum";
import { Button } from "../../../components/button";

export const JOIN_dataform_st = () => {
  const [uploading, setUploading] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    watch,
    setFocus,
    formState: { errors, isValid },
  } = useForm<ICrUserInstFormProps>({
    mode: "onChange",
  });

  const onCompolete = async (data: crUserInst) => {
    const {
      crUserInst: { ok, err },
    } = data;
    if (ok) {
      window.location.href = "/join/complete";
    }
  };

  const [crUserInst, { data: crUserInstData, loading }] =
    useCrUserInst(onCompolete);

  const onSubmit = () => {
    if (!loading) {
      const {
        email,
        password,
        name,
        phone,
        address,
        subAddress,
        businessFileUuid,
        businessItem,
        businessName,
        businessNumber,
        businessType,
        localCategory,
        posterUuid,
      } = getValues();
      if (!Boolean(address)) {
        alert("주소를 입력해주세요");
        return;
      }
      const input: CrUserInstInput = {
        email,
        password,
        name,
        phone,
        address,
        subAddress,
        businessFileUuid,
        businessItem,
        businessName,
        businessNumber,
        businessType,
        localCategory,
        posterUuid,
      };
      crUserInst({
        variables: {
          input,
        },
      });
    }
  };

  const onCheckComplete = async (data: checkEmail) => {
    const {
      checkEmail: { ok, err },
    } = data;
    if (ok) {
      alert("사용할 수 있는 이메일입니다.");
    }
    if (!ok) {
      alert("사용할 수 없는 이메일입니다.");
    }
  };

  const [checkEmail, { data: checkEmailResult, loading: checkingLoading }] =
    useCheckEmail(onCheckComplete);

  const checkEmailHandler = () => {
    if (!checkingLoading) {
      const { email } = getValues();
      const input: CheckEmailInput = {
        email,
      };
      checkEmail({
        variables: {
          input,
        },
      });
    }
  };
  // data
  const [personalAddr, setPersonalAddr] = useState<string>("");
  // script

  const [addressAPI, setAddressAPI] = useState<boolean>(false);
  // fun id check

  // func filename
  const [fileName, setFileName] = useState<string | undefined | null>();

  const uploadBusinessFileHandler = async (event: any) => {
    if (uploading) {
      alert("파일을 업로드 중입니다");
      return;
    }
    if (!loading) {
      setUploading(true);
      const result = await uploadBusiness(event);

      if (result.data.ok) {
        setValue("businessFileUuid", result.data.file.uuid);
        setFileName(result.data.file.filename);
      } else {
        alert(
          `잘못된 파일이거나 사용할 수 없는 파일입니다. 문제가 반복해서 발생할 시 업체에 문의해주세요`
        );
      }
      setUploading(false);
    }
  };

  // daum API
  const handlePostCode = (data: any) => {
    let fullAddress = data.address;
    let extraAddress = "";

    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddress += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddress +=
          extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
    }
    setPersonalAddr(fullAddress);
    setValue("address", fullAddress);
    setAddressAPI(false);
  };
  // func submit Check

  useEffect(() => {
    setFocus("email");
  }, []);

  return (
    <div className="bg-main bg-opacity-50 w-full h-full min-h-screen">
      <div className="max-w-screen-yl mx-auto w-full h-full min-h-screen bg-white flex flex-wrap justify-center ">
        <div className="sm:max-w-2xl w-full yl:px-0 px-4 pb-20 sm:pb-10">
          <div className="w-[200px] mx-auto py-10">
            <a href="/">
              <img src={logo} alt="로로 로고" className="w-full" />
            </a>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <p className="pl-5 py-6 font-bold text-center">계정 정보</p>
            <div className="w-11/12 sm:w-8/12 mx-auto block py-5 sm:py-7 relative text-center">
              <span className="sm:absolute right-full top-1/2 sm:-translate-y-1/2 block w-max pr-3 py-1 sm:py-0 text-main font-bold text-sm sm:text-base">
                이메일
              </span>
              <input
                {...register("email", {
                  required: "이메일은 필수 입력 정보입니다.",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "이메일 형식에 맞게 입력해주세요",
                  },
                })}
                type="email"
                className="rounded-none border-b border-[##F1F2EF] focus:outline-none py-1 pl-1 leading-normal w-10/12 sm:w-full text-xs lg:text-base"
              />
              {errors.email?.message && (
                <FormError3 errorMessage={errors.email.message} />
              )}
              {checkEmailResult?.checkEmail.err && (
                <FormError3 errorMessage={checkEmailResult.checkEmail.err} />
              )}
              {checkEmailResult?.checkEmail.ok && (
                <FormError3 errorMessage={`사용할 수 있는 아이디입니다.`} />
              )}

              <button
                type="button"
                className="inline-block sm:absolute left-full w-2/12 sm:w-max top-1/2 sm:-translate-y-1/2 sm:py-2 sm:pl-4 text-[10px] lg:text-xs text-main font-bold hover:opacity-60"
                onClick={checkEmailHandler}
              >
                중복확인
              </button>
            </div>
            <div className="w-11/12 sm:w-8/12 mx-auto block py-5 sm:py-7 relative text-center">
              <span className="sm:absolute right-full top-1/2 sm:-translate-y-1/2 block w-max pr-3 py-1 sm:py-0 text-main font-bold text-sm sm:text-base">
                비밀번호
              </span>
              <input
                {...register("password", {
                  required: `패스워드는 필수 입력 정보입니다.`,
                  minLength: 8,
                  maxLength: 20,
                })}
                type="password"
                className="inline-block py-1 rounded-none border-b border-[##F1F2EF] focus:outline-none pl-1 leading-normal w-full text-xs lg:text-base"
              />
              {errors.password?.message && (
                <FormError3 errorMessage={errors.password.message} />
              )}
              {errors.password?.type === "maxLength" && (
                <FormError3
                  errorMessage={"비밀번호 등록가능 최대 글자수는 20자입니다."}
                />
              )}
              {errors.password?.type === "minLength" && (
                <FormError3
                  errorMessage={"비밀번호 등록가능 최소 글자수는 8자입니다."}
                />
              )}
            </div>
            <div className="w-11/12 sm:w-8/12 mx-auto block py-5 sm:py-7 relative text-center">
              <span className="sm:absolute right-full top-1/2 sm:-translate-y-1/2 block w-max pr-3 py-1 sm:py-0 text-main font-bold text-sm sm:text-base">
                비밀번호 확인
              </span>
              <input
                {...register("confirmPassword", {
                  validate: (value) =>
                    value === watch("password") ||
                    "비밀번호가 일치하지 않습니다",
                })}
                type="password"
                className="inline-block py-1 rounded-none border-b border-[#F1F2EF] focus:outline-none pl-1 leading-normal w-full text-xs lg:text-base"
              />
              {errors.confirmPassword?.message && (
                <FormError3 errorMessage={errors.confirmPassword.message} />
              )}
            </div>
            <div className="w-11/12 sm:w-8/12 mx-auto block py-5 sm:py-7 relative text-center">
              <span className="sm:absolute right-full top-1/2 sm:-translate-y-1/2 block w-max pr-3 py-1 sm:py-0 text-main font-bold text-sm sm:text-base">
                이름(공방)
              </span>
              <input
                {...register(`name`, {
                  required: "이름은 필수 입력 정보입니다.",
                })}
                type="text"
                className="inline-block py-1 rounded-none border-b border-[#F1F2EF] focus:outline-none pl-1 leading-normal w-full text-xs lg:text-base"
              />
              {errors.name?.message && (
                <FormError3 errorMessage={errors.name.message} />
              )}
            </div>
            <div className="w-11/12 sm:w-8/12 mx-auto block py-5 sm:py-7 relative text-center">
              <span className="sm:absolute right-full top-1/2 -translate-y-1/2 block w-max pr-3 text-main font-bold text-sm sm:text-base">
                연락처
              </span>
              <input
                {...register("phone", {
                  pattern: {
                    value: /^[0-9]+$/,
                    message: "숫자만 입력해주세요",
                  },
                  required: "휴대전화번호는 필수 입력 정보입니다.",
                })}
                type="text"
                className="holder_main py-1 inline-block rounded-none border-b border-[##F1F2EF] focus:outline-none pl-1 leading-normal w-full text-xs lg:text-base"
                placeholder="- 없이 붙여서 써주세요"
                onKeyUp={(e) =>
                  (e.currentTarget.value = e.currentTarget.value.replace(
                    /[^0-9]/g,
                    ""
                  ))
                }
                maxLength={11}
              />
              {errors.phone?.message && (
                <FormError3 errorMessage={errors.phone?.message} />
              )}
            </div>
            <div className="w-11/12 sm:w-8/12 mx-auto block py-5 sm:py-7 relative text-center">
              <div className="relative">
                <span className="sm:absolute right-full top-1/2 -translate-y-1/2 block w-max pr-3 text-main font-bold text-sm sm:text-base">
                  주소
                </span>
                <div className="w-full inline-block text-left">
                  <input
                    value={personalAddr}
                    type="text"
                    className="inline-block py-1 rounded-none border-b border-[##F1F2EF] focus:outline-none pl-1 leading-normal w-8/12 text-xs lg:text-base"
                    disabled
                  />
                  <div className="w-4/12 flex-wrap inline-flex align-middle">
                    <div className="w-1/3"></div>
                    <button
                      id="find_address"
                      className="w-2/3 py-1 border-[##F1F2EF] border text-sm hover:opacity-60"
                      type="button"
                      onClick={() => setAddressAPI(true)}
                    >
                      주소찾기
                    </button>
                  </div>
                </div>
              </div>
              <div className="pt-6 relative">
                <input
                  {...register("subAddress", { required: true })}
                  type="text"
                  className="holder_main inline-block py-1 rounded-none border-b border-[##F1F2EF] focus:outline-none pl-1 leading-normal w-full text-xs lg:text-base"
                  placeholder="상세주소입력"
                />
              </div>
            </div>
            <Modal
              open={addressAPI}
              onClose={() => {
                setAddressAPI(false);
              }}
            >
              <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2  bg-white rounded-md shadow-lg">
                <DaumPostcode onComplete={handlePostCode} className="p-3" />
              </div>
            </Modal>
            <p className="pl-5 py-6 font-bold text-center">사업자 정보</p>

            <div className="w-11/12 sm:w-8/12 mx-auto block py-5 sm:py-7 relative text-center">
              <span className="sm:absolute right-full top-1/2 sm:-translate-y-1/2 block w-max pr-3 py-1 sm:py-0 text-main font-bold text-sm sm:text-base">
                사업자명
              </span>
              <input
                {...register("businessName", {
                  required: "사업자명은 필수 입력 정보입니다.",
                })}
                type="text"
                className="inline-block py-1 rounded-none border-b border-[##F1F2EF] focus:outline-none pl-1 leading-normal w-full text-xs lg:text-base"
              />
              {errors.businessName?.message && (
                <FormError3 errorMessage={errors.businessName.message} />
              )}
            </div>
            <div className="w-11/12 sm:w-8/12 mx-auto block py-5 sm:py-7 relative text-center">
              <span className="sm:absolute right-full top-1/2 sm:-translate-y-1/2 block w-max pr-3 py-1 sm:py-0 text-main font-bold text-sm sm:text-base">
                업태
              </span>
              <input
                {...register("businessType", {
                  required: "업태는 필수 입력 정보입니다.",
                })}
                type="text"
                className="inline-block py-1 rounded-none border-b border-[##F1F2EF] focus:outline-none pl-1 leading-normal w-full text-xs lg:text-base"
              />
              {errors.businessType?.message && (
                <FormError3 errorMessage={errors.businessType.message} />
              )}
            </div>
            <div className="w-11/12 sm:w-8/12 mx-auto block py-5 sm:py-7 relative text-center">
              <span className="sm:absolute right-full top-1/2 sm:-translate-y-1/2 block w-max pr-3 py-1 sm:py-0 text-main font-bold text-sm sm:text-base">
                종목
              </span>
              <input
                {...register("businessItem", {
                  required: "종목은 필수 입력 정보입니다.",
                })}
                type="text"
                className="inline-block py-1 rounded-none border-b border-[##F1F2EF] focus:outline-none pl-1 leading-normal w-full text-xs lg:text-base"
              />
              {errors.businessItem?.message && (
                <FormError3 errorMessage={errors.businessItem.message} />
              )}
            </div>
            <div className="w-11/12 sm:w-8/12 mx-auto block py-5 sm:py-7 relative text-center">
              <span className="sm:absolute right-full top-1/2 -translate-y-1/2 block w-max pr-3 text-main font-bold text-sm sm:text-base">
                사업자등록번호
              </span>
              <input
                {...register("businessNumber", {
                  required: "사업자등록번호는 필수 입력 정보입니다.",
                })}
                type="text"
                className="holder_main py-1 inline-block rounded-none border-b border-[##F1F2EF] focus:outline-none pl-1 leading-normal w-full text-xs lg:text-base"
                placeholder="- 없이 붙여서 써주세요"
              />
              {errors.businessNumber?.message && (
                <FormError3 errorMessage={errors.businessNumber.message} />
              )}
            </div>
            <div className="w-11/12 sm:w-8/12 mx-auto block py-5 sm:py-7 relative text-center">
              <span className="sm:absolute right-full top-1/2 -translate-y-1/2 block w-max pr-3 text-main font-bold text-sm sm:text-base">
                사업자등록증첨부
              </span>
              <div className="w-full inline-block text-left">
                <div className="w-4/12 flex-wrap inline-flex align-middle">
                  <label
                    htmlFor="business_id"
                    className="w-2/3 py-1 border-[##F1F2EF] border text-sm hover:opacity-60 text-center"
                  >
                    파일선택
                  </label>
                  <input
                    type="file"
                    name="join_Bfile"
                    className="hidden"
                    id="business_id"
                    onChange={uploadBusinessFileHandler}
                  />
                  <div className="w-1/3"></div>
                </div>
                <input
                  value={fileName !== undefined ? `${fileName}` : ``}
                  type="text"
                  className="inline-block py-1 rounded-none border-b border-[##F1F2EF] focus:outline-none pl-1 leading-normal w-8/12 text-xs lg:text-base"
                  disabled
                />
              </div>
            </div>
            {/* <p className="pl-5 py-6 font-bold text-center">공방 정보</p> */}

            {/* <div className="w-11/12 sm:w-8/12 mx-auto block py-5 sm:py-7 relative text-center">
              <span className="sm:absolute right-full top-1/2 -translate-y-1/2 block w-max pr-3 text-main font-bold text-sm sm:text-base">
                공방이름
              </span>
              <input
                {...register("atelierName", {
                  required: "공방 이름은 필수 입력 정보입니다.",
                })}
                type="text"
                className="holder_main py-1 inline-block rounded-none border-b border-[##F1F2EF] focus:outline-none pl-1 leading-normal w-full text-xs lg:text-base"
              />
              {errors.atelierName?.message && (
                <FormError3 errorMessage={errors.atelierName.message} />
              )}
            </div> */}
            <div className="w-11/12 sm:w-8/12 mx-auto block py-5 sm:py-7 relative text-center">
              <span className="sm:absolute right-full top-1/2 -translate-y-1/2 block w-max pr-3 text-main font-bold text-sm sm:text-base">
                지역
              </span>
              <select
                {...register("localCategory")}
                className="inline-block py-1 rounded-none border-b border-[#F1F2EF] focus:outline-none pl-1 leading-normal w-full text-xs lg:text-base"
              >
                {Object(LocalCategoryOption).map(
                  (localCategory: LocalCategoryOptionType, index: number) => (
                    <option
                      key={`local-category-option-${index}`}
                      value={localCategory.value}
                    >
                      {localCategory.label}
                    </option>
                  )
                )}
              </select>
            </div>

            <div className="left-0 bottom-0 w-full fixed sm:static py-4 sm:pt-7 text-center border sm:border-0 bg-white sm:bg-transparent">
              <button
                type="button"
                className="inline-block hover:opacity-60 w-32 py-2 border border-grays text-sm font-bold rounded-md mr-4"
              >
                <Link to="/join/agree" className="block w-full h-full">
                  취소
                </Link>
              </button>
              <Button
                className={`text-white bg-main`}
                canClick={isValid}
                actionText={`가입하기`}
                loading={loading}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
