import { ApolloError, gql, useMutation } from "@apollo/client";
import React from "react";
import { crProduct, crProductVariables } from "../__generated__/crProduct";
import { CrProductInput } from "../__generated__/globalTypes";

export interface ICrProductFormProps extends CrProductInput {}

const CR_PRODUCT_MUTATION = gql`
  mutation crProduct($input: CrProductInput!) {
    crProduct(input: $input) {
      ok
      err
      productId
    }
  }
`;

export const useCrProduct = (
  onCompleted?: (data: crProduct) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<crProduct, crProductVariables>(CR_PRODUCT_MUTATION, {
    onCompleted,
    onError,
  });
};
