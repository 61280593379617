import React from "react";
import { useForm } from "react-hook-form";
import { Button } from "../components/button";
import { DoorTypeOption, DoorTypeOptionType } from "../constants/enum";
import { useCrDoor } from "../hooks/useCrDoor";
import { crDoor } from "../__generated__/crDoor";
import { CrDoorInput } from "../__generated__/globalTypes";

interface ICrDoorFormProps extends CrDoorInput {}

interface CUserMypageCrQuestionProps {
  refetch: () => void;
  refundRefetch: () => void;
}

export const SubUserMypageCrQuestion: React.FC<CUserMypageCrQuestionProps> = ({
  refetch,
  refundRefetch,
}) => {
  const {
    register,
    getValues,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<ICrDoorFormProps>({ mode: `all` });

  const onCrComplete = async (data: crDoor) => {
    const {
      crDoor: { ok, err },
    } = data;
    if (ok) {
      alert(`문의가 완료되었습니다 빠른 시간 내 답변드리겠습니다`);
      await refetch();
      await refundRefetch();
      window.location.reload();
    } else {
      alert(err);
    }
  };
  const [crDoor, { data: crDoorData, loading: crDoorLoading }] =
    useCrDoor(onCrComplete);
  const onSubmit = () => {
    if (!crDoorLoading) {
      const { title, note, type } = getValues();
      const crDoorInput: CrDoorInput = {
        note,
        title,
        type,
      };
      crDoor({ variables: { input: crDoorInput } });
    }
  };

  return (
    <div className="bg-white p-5 rounded-2xl shadow-lg max-w-xl">
      <form onSubmit={handleSubmit(onSubmit)}>
        <p className="text-main text-center font-bold py-2">문의하기</p>
        <div className="space-y-2 pt-5">
          <p className="font-bold text-main">제목</p>
          <input
            {...register(`title`, {
              required: `문의 제목은 필수 입력 정보입니다`,
            })}
            type="text"
            className={`border border-grays rounded-xl py-1 px-2 w-full lg:text-base text-sm ${
              errors.title?.message && `border-red-400`
            }`}
          />
        </div>
        <div className="space-y-2 pt-5">
          <p className="font-bold text-main">분류</p>
          <select
            {...register(`type`, { required: `분류를 선택해주세요` })}
            className={`border border-grays rounded-xl py-1 px-2 w-full lg:text-base text-sm ${
              errors.title?.message && `border-red-400`
            }`}
          >
            {Object(DoorTypeOption).map(
              (doorType: DoorTypeOptionType, index: number) => (
                <option
                  key={`door-type-option-${index}`}
                  value={doorType.value}
                >
                  {doorType.label}
                </option>
              )
            )}
          </select>
        </div>
        <div className="space-y-2 pt-5">
          <p className="font-bold text-main">내용</p>
          <textarea
            {...register(`note`, {
              required: `문의 내용은 필수 입력 정보입니다`,
            })}
            rows={15}
            className={`w-full border border-grays rounded-xl px-2 py-1 resize-none lg:text-base text-sm ${
              errors.note?.message && `border-red-400`
            }`}
          ></textarea>
        </div>
        <div className="w-full left-0 bottom-16 fixed sm:static text-center sm:text-right py-4 sm:pt-3 bg-white sm:bg-transparent">
          <Button
            canClick={isValid}
            actionText={`작성`}
            className={`bg-main w-48 py-1 text-white rounded-md hover:opacity-60 lg:text-base text-sm`}
            loading={crDoorLoading}
          />
        </div>
      </form>
    </div>
  );
};
