import React from "react";
import gql from "graphql-tag";
import { ApolloError, useMutation } from "@apollo/client";
import {
  crProductThemeCurr,
  crProductThemeCurrVariables,
} from "../__generated__/crProductThemeCurr";

const CR_PRODUCT_THEME_CURR_MUTATION = gql`
  mutation crProductThemeCurr($input: CrProductThemeCurrInput!) {
    crProductThemeCurr(input: $input) {
      ok
      err
    }
  }
`;

export const useCrProductThemeCurr = (
  onCompleted?: (data: crProductThemeCurr) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<crProductThemeCurr, crProductThemeCurrVariables>(
    CR_PRODUCT_THEME_CURR_MUTATION,
    {
      onCompleted,
      onError,
    }
  );
};
