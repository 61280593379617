import React from "react";
import { gql, useLazyQuery } from "@apollo/client";
import { listBill, listBillVariables } from "../__generated__/listBill";

const LIST_BILL_QUERY = gql`
  query listBill($input: ListBillInput!) {
    listBill(input: $input) {
      ok
      err
      totalPages
      totalResults
      row
      bills {
        id
        status
        note
        invoicerCorpName
        invoicerEmail
        amountTotal
        taxTotal
        totalAmount
        createdAt
      }
    }
  }
`;

export const useListBill = (onCompleted?: (data: listBill) => void) => {
  return useLazyQuery<listBill, listBillVariables>(LIST_BILL_QUERY, {
    onCompleted,
    fetchPolicy: `network-only`,
  });
};
