/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum BannerOptionType {
  MagazineA = "MagazineA",
  MagazineB = "MagazineB",
  MagazineC = "MagazineC",
  MainA = "MainA",
  MainB = "MainB",
  MainC = "MainC",
  Offline = "Offline",
  Online = "Online",
  Place = "Place",
  PlaceA = "PlaceA",
  PlaceB = "PlaceB",
  PlaceC = "PlaceC",
  PlaceD = "PlaceD",
  SubA = "SubA",
  SubB = "SubB",
  SubC = "SubC",
}

export enum BillOptionStatus {
  Expired = "Expired",
  InValid = "InValid",
  Pending = "Pending",
  Valid = "Valid",
}

export enum BillStatus {
  Canceled = "Canceled",
  Deny = "Deny",
  Failed = "Failed",
  Pending = "Pending",
  Ready = "Ready",
  Sending = "Sending",
  Valid = "Valid",
}

export enum ConsultStatus {
  Canceled = "Canceled",
  Completed = "Completed",
  Pending = "Pending",
  Replied = "Replied",
  Requested = "Requested",
}

export enum ConsultType {
  Cost = "Cost",
  Design = "Design",
  Service = "Service",
}

export enum DoorStatus {
  Canceled = "Canceled",
  Completed = "Completed",
  Failed = "Failed",
  New = "New",
  Pending = "Pending",
  Refunded = "Refunded",
  Replied = "Replied",
  Requested = "Requested",
}

export enum DoorType {
  Question = "Question",
  Refund = "Refund",
  System = "System",
}

export enum InstLevel {
  Inst = "Inst",
  Normal = "Normal",
  Owner = "Owner",
}

export enum LocalCategory {
  Choongcheong = "Choongcheong",
  Gangwon = "Gangwon",
  Gyeonggi = "Gyeonggi",
  Gyeongsang = "Gyeongsang",
  Jeju = "Jeju",
  Jeonra = "Jeonra",
}

export enum OrderStatus {
  Delivered = "Delivered",
  HandOut = "HandOut",
  None = "None",
  Pending = "Pending",
}

export enum PLocalCategory {
  Choongcheong = "Choongcheong",
  Gangwon = "Gangwon",
  Gyeonggi = "Gyeonggi",
  Gyeongsang = "Gyeongsang",
  Jeju = "Jeju",
  Jeonra = "Jeonra",
}

export enum ProductCategory {
  Caligraphy = "Caligraphy",
  Drawing = "Drawing",
  FlowerGardening = "FlowerGardening",
  KnittingWeaving = "KnittingWeaving",
  LeatherGlassCeramic = "LeatherGlassCeramic",
  MacrameWoolFelt = "MacrameWoolFelt",
  Pet = "Pet",
  RattanWood = "RattanWood",
  SoapCandleIncense = "SoapCandleIncense",
}

export enum ProductStatus {
  Done = "Done",
  On = "On",
  Pause = "Pause",
  Pending = "Pending",
  Ready = "Ready",
  Reject = "Reject",
  Request = "Request",
}

export enum ProductType {
  Kit = "Kit",
  Magazine = "Magazine",
  Offline = "Offline",
  Online = "Online",
  Place = "Place",
}

export enum PromotionColor {
  Blue = "Blue",
  Green = "Green",
  Orange = "Orange",
  Purple = "Purple",
  Red = "Red",
  Slate = "Slate",
  Yellow = "Yellow",
}

export enum UserRole {
  Admin = "Admin",
  Curator = "Curator",
  General = "General",
  Instructor = "Instructor",
}

export interface ActivateBannerInput {
  bannerIdToActivate: number;
}

export interface ActivatePromotionInput {
  promotionIdToActivate: number;
}

export interface ApproveCuratorInput {
  curatorIdToApprove: number;
}

export interface ApproveInstInput {
  instIdToApprove: number;
  curatorId: number;
}

export interface ApproveRefundInput {
  refundIdToApprove: number;
}

export interface CanBuyInput {
  productId: number;
}

export interface CanWriteProductReviewInput {
  productId: number;
}

export interface CancelRefundInput {
  refundIdToCancel: number;
}

export interface ChangePasswordInput {
  password: string;
  code: string;
}

export interface ChangeReceiptOrderInput {
  page?: number | null;
  search?: string | null;
  category?: ProductCategory | null;
  start?: any | null;
  end?: any | null;
  receiptIdToChange: number;
}

export interface ChangeReceiptOrderPendingInput {
  page?: number | null;
  search?: string | null;
  category?: ProductCategory | null;
  start?: any | null;
  end?: any | null;
  receiptIdToChange: number;
}

export interface CheckCERTIsValidInput {
  instIdToCheck: number;
}

export interface CheckEmailInput {
  email: string;
}

export interface CrBannerInput {
  title: string;
}

export interface CrBillInput {
  instId: number;
}

export interface CrConsultInput {
  title: string;
  note: string;
  type?: ConsultType | null;
}

export interface CrConsultReplyInput {
  note: string;
  consultId: number;
}

export interface CrDoorInput {
  title: string;
  note: string;
  type?: DoorType | null;
}

export interface CrDoorReplyInput {
  note: string;
  doorId: number;
}

export interface CrInstInput {
  email: string;
  password: string;
  name: string;
  phone: string;
  address: string;
  subAddress: string;
  businessName: string;
  businessType: string;
  businessItem: string;
  businessNumber: string;
  businessFileUuid: string;
  atelierAddress: string;
  atelierSubAddress: string;
  atelierLocalCategory: string;
  arelierThbUuid: string;
}

export interface CrMagazineInput {
  title: string;
  summary?: string | null;
  category?: string | null;
  place?: string | null;
}

export interface CrPlaceInput {
  title: string;
  summary?: string | null;
  localCategory?: PLocalCategory | null;
  place?: string | null;
  category?: string | null;
  fee?: string | null;
  playTime?: string | null;
}

export interface CrProductInput {
  title: string;
  summary?: string | null;
  price?: number | null;
  productCategory?: ProductCategory | null;
  localCategory?: PLocalCategory | null;
  discountRate?: number | null;
  place?: string | null;
  max?: number | null;
  playTime?: string | null;
  materials?: string | null;
  scheduledAt?: any | null;
  productType: ProductType;
}

export interface CrProductReviewInput {
  is_private: boolean;
  text: string;
  productId: number;
}

export interface CrProductThemeCurrInput {
  productId: number;
}

export interface CrProductViewInput {
  productId: number;
}

export interface CrPromotionInput {
  title: string;
  badge: string;
  badgeColor?: string | null;
  desc: string;
  discountRate?: number | null;
  startAt?: any | null;
  endAt?: any | null;
}

export interface CrPromotionProductInput {
  promotionId: number;
  productId: number;
}

export interface CrReceiptInput {
  status: string;
  paid_amount: number;
  paid_at?: string | null;
  receiver?: string | null;
  phone?: string | null;
  address?: string | null;
  message?: string | null;
  scheduledAt?: any | null;
  apply_num: string;
  receipt_url?: string | null;
  imp_uid: string;
  merchant_uid: string;
  name: string;
  pay_method: string;
  pg_tid: string;
  pg_type: string;
  vbank_num?: string | null;
  vbank_date?: string | null;
  vbank_name?: string | null;
  vbank_holder?: string | null;
  productId: number;
}

export interface CrRefundInput {
  note: string;
  refund_holder?: string | null;
  refund_bank?: string | null;
  refund_account?: string | null;
  receiptId: number;
}

export interface CrUserCuratorInput {
  email: string;
  password: string;
  name: string;
  phone: string;
  address: string;
  subAddress: string;
  code: string;
}

export interface CrUserInput {
  email: string;
  password: string;
  name: string;
  phone: string;
  address: string;
  subAddress: string;
}

export interface CrUserInstInput {
  email: string;
  password: string;
  name: string;
  phone: string;
  address: string;
  subAddress: string;
  businessName: string;
  businessType: string;
  businessItem: string;
  businessNumber: string;
  businessFileUuid: string;
  localCategory: LocalCategory;
  posterUuid?: string | null;
}

export interface DelBannerInput {
  bannerIdToDel: number;
}

export interface DelConsultInput {
  consultIdToDel: number;
}

export interface DelConsultReplyInput {
  consultReplyIdToDel: number;
}

export interface DelDoorInput {
  doorIdToDel: number;
}

export interface DelDoorReplyInput {
  replyIdToDel: number;
}

export interface DelProductInput {
  productIdToDel: number;
}

export interface DelProductReviewInput {
  productReviewIdToDel: number;
}

export interface DelProductThemeCurrInput {
  currIdToDel: number;
}

export interface DelProductViewInput {
  productViewIdToDel?: number | null;
}

export interface DelPromotionInput {
  promotionIdToDel: number;
}

export interface DelPromotionProductInput {
  promotionProductIdToDel: number;
}

export interface DelUserInput {
  userIdToDel: number;
}

export interface GetBannerInput {
  bannerId: number;
}

export interface GetBaroBillURLCERTInput {
  instIdToGet: number;
}

export interface GetBillOptionInput {
  InstIdToGet: number;
}

export interface GetBillURLInput {
  billIdToGetURL: number;
}

export interface GetConsultInput {
  consultId: number;
}

export interface GetDoorInput {
  doorId: number;
}

export interface GetProductInput {
  productId: number;
}

export interface GetProductIntroInput {
  productId: number;
}

export interface GetProductKitInput {
  productId: number;
}

export interface GetProductPageInput {
  productId: number;
}

export interface GetPromotionInput {
  promotionId: number;
}

export interface GetReceiptInput {
  page?: number | null;
  search?: string | null;
  category?: ProductCategory | null;
  start?: any | null;
  end?: any | null;
  receiptId: number;
}

export interface GetUserEmailInput {
  name: string;
  phone: string;
}

export interface InactivatePromotionInput {
  promotionIdToInactivate: number;
}

export interface ListBannerInput {
  page?: number | null;
  search?: string | null;
  category?: ProductCategory | null;
  start?: any | null;
  end?: any | null;
}

export interface ListBannerOptionInput {
  bannerId: number;
}

export interface ListBillInput {
  page?: number | null;
  search?: string | null;
  category?: ProductCategory | null;
  start?: any | null;
  end?: any | null;
}

export interface ListBuyMineOfflineInput {
  page?: number | null;
  search?: string | null;
  category?: ProductCategory | null;
  start?: any | null;
  end?: any | null;
}

export interface ListBuyMineOnlineInput {
  page?: number | null;
  search?: string | null;
  category?: ProductCategory | null;
  start?: any | null;
  end?: any | null;
}

export interface ListClassActiveInput {
  page?: number | null;
  search?: string | null;
  category?: ProductCategory | null;
  start?: any | null;
  end?: any | null;
}

export interface ListClassInactiveInput {
  page?: number | null;
  search?: string | null;
  category?: ProductCategory | null;
  start?: any | null;
  end?: any | null;
}

export interface ListClassInput {
  page?: number | null;
  search?: string | null;
  category?: ProductCategory | null;
  start?: any | null;
  end?: any | null;
}

export interface ListConsultInput {
  page?: number | null;
  search?: string | null;
  category?: ProductCategory | null;
  start?: any | null;
  end?: any | null;
}

export interface ListConsultMineInput {
  page?: number | null;
  search?: string | null;
  category?: ProductCategory | null;
  start?: any | null;
  end?: any | null;
}

export interface ListConsultReplyInput {
  page?: number | null;
  search?: string | null;
  category?: ProductCategory | null;
  start?: any | null;
  end?: any | null;
  consultId: number;
}

export interface ListDoorInput {
  page?: number | null;
  search?: string | null;
  category?: ProductCategory | null;
  start?: any | null;
  end?: any | null;
  status?: DoorStatus | null;
}

export interface ListDoorNewInput {
  page?: number | null;
  search?: string | null;
  category?: ProductCategory | null;
  start?: any | null;
  end?: any | null;
}

export interface ListDoorRefundInput {
  page?: number | null;
  search?: string | null;
  category?: ProductCategory | null;
  start?: any | null;
  end?: any | null;
}

export interface ListDoorReplyInput {
  page?: number | null;
  search?: string | null;
  category?: ProductCategory | null;
  start?: any | null;
  end?: any | null;
  doorId: number;
}

export interface ListMagazineActiveInput {
  page?: number | null;
  search?: string | null;
  category?: ProductCategory | null;
  start?: any | null;
  end?: any | null;
}

export interface ListMagazineInput {
  page?: number | null;
  search?: string | null;
  category?: ProductCategory | null;
  start?: any | null;
  end?: any | null;
}

export interface ListOfflineProductInput {
  page?: number | null;
  search?: string | null;
  category?: ProductCategory | null;
  start?: any | null;
  end?: any | null;
}

export interface ListOnlineProductInput {
  page?: number | null;
  search?: string | null;
  category?: ProductCategory | null;
  start?: any | null;
  end?: any | null;
}

export interface ListPlaceActiveInput {
  page?: number | null;
  search?: string | null;
  category?: ProductCategory | null;
  start?: any | null;
  end?: any | null;
  localCategory?: PLocalCategory | null;
  place?: string | null;
}

export interface ListPlaceInput {
  page?: number | null;
  search?: string | null;
  category?: ProductCategory | null;
  start?: any | null;
  end?: any | null;
  place?: string | null;
}

export interface ListProductInput {
  page?: number | null;
  search?: string | null;
  category?: ProductCategory | null;
  start?: any | null;
  end?: any | null;
}

export interface ListProductMainInput {
  localCategory?: LocalCategory | null;
  productCategory?: ProductCategory | null;
}

export interface ListProductReviewInput {
  page?: number | null;
  search?: string | null;
  category?: ProductCategory | null;
  start?: any | null;
  end?: any | null;
  productId?: number | null;
}

export interface ListProductSubInput {
  page?: number | null;
  search?: string | null;
  category?: ProductCategory | null;
  start?: any | null;
  end?: any | null;
  localCategory?: LocalCategory | null;
  productCategory?: ProductCategory | null;
  order?: string | null;
  type: ProductType;
}

export interface ListProductThemeCurrInput {
  productId: number;
}

export interface ListProductViewInput {
  productId?: number | null;
}

export interface ListPromotionInput {
  page?: number | null;
  search?: string | null;
  category?: ProductCategory | null;
  start?: any | null;
  end?: any | null;
}

export interface ListPromotionProductInput {
  page?: number | null;
  search?: string | null;
  category?: ProductCategory | null;
  start?: any | null;
  end?: any | null;
  promotionId: number;
}

export interface ListReceiptInput {
  page?: number | null;
  search?: string | null;
  category?: ProductCategory | null;
  start?: any | null;
  end?: any | null;
}

export interface ListReceiptMineInput {
  page?: number | null;
  search?: string | null;
  category?: ProductCategory | null;
  start?: any | null;
  end?: any | null;
}

export interface ListReceiptMineOfflineInput {
  page?: number | null;
  search?: string | null;
  category?: ProductCategory | null;
  start?: any | null;
  end?: any | null;
}

export interface ListReceiptMineOnlineInput {
  page?: number | null;
  search?: string | null;
  category?: ProductCategory | null;
  start?: any | null;
  end?: any | null;
}

export interface ListReceiptProductInput {
  page?: number | null;
  search?: string | null;
  category?: ProductCategory | null;
  start?: any | null;
  end?: any | null;
  productId: number;
}

export interface ListUnverifiedCuratorInput {
  page?: number | null;
  search?: string | null;
  category?: ProductCategory | null;
  start?: any | null;
  end?: any | null;
}

export interface ListUnverifiedInstInput {
  page?: number | null;
  search?: string | null;
  category?: ProductCategory | null;
  start?: any | null;
  end?: any | null;
}

export interface ListUserGeneralInput {
  page?: number | null;
  search?: string | null;
  category?: ProductCategory | null;
  start?: any | null;
  end?: any | null;
}

export interface ListUserInput {
  page?: number | null;
  search?: string | null;
  category?: ProductCategory | null;
  start?: any | null;
  end?: any | null;
}

export interface ListVerifiedCuratorInput {
  page?: number | null;
  search?: string | null;
  category?: ProductCategory | null;
  start?: any | null;
  end?: any | null;
}

export interface ListVerifiedInstInput {
  page?: number | null;
  search?: string | null;
  category?: ProductCategory | null;
  start?: any | null;
  end?: any | null;
}

export interface LoginInput {
  email: string;
  password: string;
}

export interface RegistCorpInput {
  CERTKEY?: string | null;
  CorpNum: string;
  CorpName: string;
  CEOName: string;
  BizType: string;
  BizClass: string;
  Addr1: string;
  Addr2: string;
  MemberName: string;
  ID: string;
  PWD: string;
  TEL: string;
  Email: string;
  instIdToRegist: number;
}

export interface ResignCuratorInput {
  curatorIdToResign: number;
}

export interface ResignInstInput {
  instIdToResign: number;
}

export interface ResignRefundInput {
  refundIdToResign: number;
}

export interface SearchNaverInput {
  search: string;
}

export interface SearchProductWAddressInput {
  address: string;
}

export interface SendChangePasswordEmailInput {
  email: string;
}

export interface SendSignupEmailInput {
  email: string;
}

export interface UpBannerInput {
  title?: string | null;
  bannerIdToUp: number;
}

export interface UpBannerOptionInput {
  bannerOptionIdToUp: number;
  link?: string | null;
  viewUuid?: string | null;
  subViewUuid?: string | null;
  productId?: number | null;
}

export interface UpConsultInput {
  title?: string | null;
  note?: string | null;
  status?: ConsultStatus | null;
  type?: ConsultType | null;
  consultIdToUp: number;
}

export interface UpConsultReplyInput {
  note?: string | null;
  consultReplyIdToUp: number;
}

export interface UpDoorInput {
  title?: string | null;
  note?: string | null;
  type?: DoorType | null;
  doorIdToUp: number;
}

export interface UpDoorReplyInput {
  note?: string | null;
  doorReplyIdToUp: number;
}

export interface UpInstInput {
  businessName?: string | null;
  businessType?: string | null;
  businessItem?: string | null;
  businessNumber?: string | null;
  instIdToUp: string;
  businessFileUuid?: string | null;
}

export interface UpMagazineInput {
  title?: string | null;
  summary?: string | null;
  place?: string | null;
  category?: string | null;
  productIdToUp: number;
}

export interface UpPlaceInput {
  title?: string | null;
  summary?: string | null;
  localCategory?: PLocalCategory | null;
  playTime?: string | null;
  place?: string | null;
  fee?: string | null;
  category?: string | null;
  productIdToUp: number;
}

export interface UpProductInput {
  title?: string | null;
  summary?: string | null;
  price?: number | null;
  discountRate?: number | null;
  materials?: string | null;
  productCategory?: ProductCategory | null;
  localCategory?: PLocalCategory | null;
  playTime?: string | null;
  place?: string | null;
  scheduledAt?: any | null;
  max?: number | null;
  productIdToUp: number;
  productType?: ProductType | null;
}

export interface UpProductIntroInput {
  intro?: string | null;
  productIntroIdToUp: number;
}

export interface UpProductKitInput {
  desc?: string | null;
  productKitIdToUp: number;
  viewUuid?: string | null;
}

export interface UpProductLikeInput {
  like_count?: number | null;
  productIdToUp: number;
}

export interface UpProductPageInput {
  page?: string | null;
  productPageIdToUp: number;
}

export interface UpProductReviewInput {
  is_private: boolean;
  text: string;
  productReviewIdToUp: number;
}

export interface UpProductStatusInput {
  productStatus?: ProductStatus | null;
  productIdToUp: number;
}

export interface UpProductThemeCurrInput {
  title?: string | null;
  playTime?: number | null;
  is_free?: boolean | null;
  currIdToUp: number;
  videoUuid?: string | null;
}

export interface UpProductViewInput {
  productViewIdToUp?: number | null;
  viewUuid?: string | null;
}

export interface UpPromotionInput {
  title?: string | null;
  badge?: string | null;
  badgeColor?: string | null;
  desc?: string | null;
  color?: PromotionColor | null;
  discountRate?: number | null;
  startAt?: any | null;
  endAt?: any | null;
  promotionIdToUp: number;
}

export interface UpPromotionProductInput {
  discountRate?: number | null;
  promotionProductIdToUp: number;
}

export interface UpUserInput {
  password?: string | null;
  phone?: string | null;
  address?: string | null;
  subAddress?: string | null;
}

export interface VerifyEmailInput {
  code: string;
}

export interface VerifySignupEmailInput {
  code: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
