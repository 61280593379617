import React from "react";
import gql from "graphql-tag";
import { ApolloError, useMutation } from "@apollo/client";
import {
  upProductThemeCurr,
  upProductThemeCurrVariables,
} from "../__generated__/upProductThemeCurr";
import {
  delProductThemeCurr,
  delProductThemeCurrVariables,
} from "../__generated__/delProductThemeCurr";

const DEL_PRODUCT_THEME_CURR_MUTATION = gql`
  mutation delProductThemeCurr($input: DelProductThemeCurrInput!) {
    delProductThemeCurr(input: $input) {
      ok
      err
    }
  }
`;

export const useDelProductThemeCurr = (
  onCompleted?: (data: delProductThemeCurr) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<delProductThemeCurr, delProductThemeCurrVariables>(
    DEL_PRODUCT_THEME_CURR_MUTATION,
    {
      onCompleted,
      onError,
    }
  );
};
