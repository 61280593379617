import { useEffect, useState } from "react";
import usePagination from "@mui/material/usePagination";
import { styled } from "@mui/material/styles";
import { pageIndicator } from "../apollo";
import searchImg from "../assets/icon/search.png";
import { AsideAdminComponent } from "../layout/aside-admin";
import { Footer } from "../layout/footer";
import { Link } from "react-router-dom";
import { createBrowserHistory } from "history";
import { useLocation } from "react-router";
import {
  ActivatePromotionInput,
  DelPromotionInput,
  InactivatePromotionInput,
  ListPromotionInput,
} from "../__generated__/globalTypes";
import { TableError } from "../components/table-error";
import { TextButton } from "../components/button";
import { useForm } from "react-hook-form";
import { AdminTop } from "../layout/adminTop";
import { useListPromotion } from "../hooks/useListPromotion";
import moment from "moment";
import { useActivatePromotion } from "../hooks/useActivatePromotion";
import { activatePromotion } from "../__generated__/activatePromotion";
import { inactivatePromotion } from "../__generated__/inactivatePromotion";
import { useInactivatePromotion } from "../hooks/useInactivatePromotion";
import { delPromotion } from "../__generated__/delPromotion";
import { useDelPromotion } from "../hooks/useDelPromotion";
import { PaginationComponent } from "../components/pagination";

export interface ISearchFormProps {
  search: string;
}

export const AdminListPromotion = () => {
  const history = createBrowserHistory();
  const location = useLocation();
  const [page, setPage] = useState<number>(1);
  const [search, setSearch] = useState<string>("");

  const word = "프로모션";

  const pageHandler = (page: number) => {
    setPage(page);
    history.push({
      pathname: "/admin/promotion",
      search: `?page=${page}${search ? `&search=${search}` : ""}`,
    });
    listPromotionHandler(page, search ? search : undefined);
  };

  const { register, getValues, handleSubmit } = useForm<ISearchFormProps>({
    mode: "all",
  });

  const onSearch = async () => {
    const { search } = getValues();
    setSearch(search);
    history.push({
      pathname: "/admin/promotion",
      search: `${search ? `?search=${search}` : ""}`,
    });
    listPromotionHandler(1, search);
  };

  useEffect(() => {
    pageIndicator("promotion");
  }, []);

  const onActivate = (data: activatePromotion) => {
    const {
      activatePromotion: { ok, err },
    } = data;
    if (!ok) {
      alert(err);
      return;
    }
    alert("활성화되었습니다");
    refetch();
  };
  const [activatePromotion, { loading: ActivatePromotionLoading }] =
    useActivatePromotion(onActivate);
  const activatePromotionHandler = (promotionIdToActivate: number) => {
    if (ActivatePromotionLoading) return;
    const input: ActivatePromotionInput = {
      promotionIdToActivate,
    };
    activatePromotion({ variables: { input } });
  };

  const onInactivate = (data: inactivatePromotion) => {
    const {
      inactivatePromotion: { ok, err },
    } = data;
    if (!ok) {
      alert(err);
      return;
    }
    alert("비활성화되었습니다");
    refetch();
  };
  const [inactivatePromotion, { loading: InactivatePromotionLoading }] =
    useInactivatePromotion(onInactivate);
  const inactivatePromotionHandler = (promotionIdToInactivate: number) => {
    if (InactivatePromotionLoading) return;
    const input: InactivatePromotionInput = {
      promotionIdToInactivate,
    };
    inactivatePromotion({ variables: { input } });
  };

  const onDelete = (data: delPromotion) => {
    const {
      delPromotion: { ok, err },
    } = data;
    if (!ok) {
      alert(err);
      return;
    }
    alert("삭제되었습니다");
    refetch();
  };
  const [delPromotion, { loading: delPromotionLoading }] =
    useDelPromotion(onDelete);
  const delPromotionHandler = (promotionIdToDel: number) => {
    if (delPromotionLoading) return;
    const input: DelPromotionInput = {
      promotionIdToDel,
    };
    delPromotion({ variables: { input } });
  };

  const [listPromotion, { data, loading, refetch }] = useListPromotion();
  const listPromotionHandler = (page: number, search?: string) => {
    const input: ListPromotionInput = {
      ...(page && { page }),
      ...(search && { search }),
    };
    listPromotion({ variables: { input } });
  };

  useEffect(() => {
    setPage(
      location.search.split("page=").length > 1
        ? +location.search.split("page=")[1].split("&")[0]
        : 1
    );
    setSearch(
      location.search.split("search=").length > 1
        ? location.search.split("search=")[1].split("&")[0]
        : ""
    );
    listPromotionHandler(page, search);
  }, []);

  return (
    <div className="bg-main bg-opacity-50 w-full h-full min-h-screen">
      <div className="w-[1440px] mx-auto h-full min-h-screen  bg-white">
        <AdminTop title={"top"} children={"관리자모드"} />
        <main id="main">
          <div className="grid grid-cols-[300px_1fr]">
            <AsideAdminComponent />
            <div className="mypages ">
              <div className="lectureWrap">
                <div className="h-20">
                  <div className="h-full flex-wrap flex justify-between px-10 content-center">
                    <div className="subtitle h-full flex flex-wrap content-end gap-x-3">
                      <div className={`tab-selected`}>
                        <Link
                          className={`tab-text-selected`}
                          to={`/admin/promotion`}
                        >
                          목록
                        </Link>
                      </div>
                      <div className={`tab-unselected`}>
                        <Link
                          className={`tab-text-unselected`}
                          to={`/admin/promotion/create`}
                        >
                          프로모션 생성
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className=" bg-gray-100 h-fix-content relative grid grid-rows-1">
                  <div className={`w-full h-full px-10 py-12`}>
                    <div className="text-right pb-8">
                      <form onSubmit={handleSubmit(onSearch)}>
                        <p className="inline-block max-w-md w-full text-right relative">
                          <input
                            {...register(`search`)}
                            type="text"
                            className="w-full py-1 px-4 bg-white rounded-full"
                            placeholder="프로모션 이름으로 검색"
                          />
                          <button className="absolute right-3 top-1">
                            <img src={searchImg} alt="찾기" className="w-5" />
                          </button>
                        </p>
                      </form>
                    </div>
                    {/* 여기 */}
                    <div className="bg-white p-5 rounded-2xl shadow-lg">
                      <p className="text-main font-bold pb-3">프로모션 목록</p>
                      <table className="w-full">
                        <colgroup>
                          <col className="w-1/10" />
                          <col className="w-1/10" />
                          <col className="w-1/10" />
                          <col className="w-3/10" />
                          <col className="w-1/10" />
                          <col className="w-1/10" />
                          <col className="w-2/10" />
                        </colgroup>
                        <thead>
                          <tr className="bg-main  border-gray-300">
                            <th className="text-white ">번호</th>
                            <th className="text-white ">시작일</th>
                            <th className="text-white ">종료일</th>
                            <th className="text-white ">제목</th>
                            <th className="text-white ">뱃지</th>
                            <th className="text-white ">상태</th>
                            <th className="text-white ">기능</th>
                          </tr>
                        </thead>
                        <tbody>
                          {!loading &&
                            data?.listPromotion.promotions &&
                            data.listPromotion.promotions.map((promotion) => (
                              <tr
                                key={`promotion-${promotion?.id}`}
                                className="border-b-2 border-gray-300 h-4"
                              >
                                <td className="text-sm text-center py-1 px-1 h-full overflow-hidden">
                                  {promotion?.id && promotion.id}
                                </td>
                                <td className="text-sm text-center py-1 px-1 h-full overflow-hidden">
                                  {promotion?.startAt &&
                                    moment(promotion.startAt).format(
                                      "YYYY-MM-DD"
                                    )}
                                </td>
                                <td className="text-sm text-center py-1 px-1 h-full overflow-hidden">
                                  {promotion?.startAt &&
                                    moment(promotion.endAt).format(
                                      "YYYY-MM-DD"
                                    )}
                                </td>
                                <td className="text-sm text-center h-full relative truncate">
                                  <Link
                                    to={`/admin/promotion/update/${promotion.id}`}
                                    className={`cursor-pointer hover:underline`}
                                  >
                                    {promotion?.title && promotion.title}
                                  </Link>
                                </td>
                                <td className="text-sm text-center  relative">
                                  {promotion?.badge && promotion.badge}
                                </td>
                                <td className="text-sm text-center py-1 px-1 h-full overflow-hidden ">
                                  {promotion?.is_active ? `진행 중` : `중단`}
                                </td>
                                <td className="text-sm text-center py-1 px-1 h-full w-1/6 overflow-hidden">
                                  <TextButton
                                    canClick={!loading && !promotion.is_active}
                                    actionText={`진행`}
                                    onClick={() =>
                                      activatePromotionHandler(promotion.id)
                                    }
                                  />
                                  {` / `}
                                  <TextButton
                                    canClick={!loading && promotion.is_active}
                                    actionText={`중단`}
                                    onClick={() =>
                                      inactivatePromotionHandler(promotion.id)
                                    }
                                  />
                                  {` / `}
                                  <TextButton
                                    canClick={!loading && !promotion.is_active}
                                    actionText={`삭제`}
                                    onClick={() =>
                                      delPromotionHandler(promotion.id)
                                    }
                                  />
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                      {loading && (
                        <TableError errorMessage={`정보를 불러오는 중입니다`} />
                      )}
                      {!loading &&
                        data?.listPromotion.promotions &&
                        data.listPromotion.promotions.length < 1 && (
                          <TableError
                            errorMessage={`해당하는 ${word} 정보가 없습니다`}
                          />
                        )}
                      {!loading &&
                        data?.listPromotion.promotions &&
                        data.listPromotion.promotions.length > 0 && (
                          <PaginationComponent
                            page={page}
                            totalPages={Number(data.listPromotion.totalPages)}
                            totalResults={Number(
                              data.listPromotion.totalResults
                            )}
                            pageHandler={pageHandler}
                          />
                        )}
                    </div>
                  </div>
                  <Footer></Footer>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
