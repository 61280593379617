import Modal from "@mui/material/Modal";
import moment from "moment";
import React, { useState } from "react";
import { TextButton } from "../components/button";
import { useCancelRefund } from "../hooks/useCancelRefund";
import { cancelRefund } from "../__generated__/cancelRefund";
import { CancelRefundInput, DoorStatus } from "../__generated__/globalTypes";
import { listDoorMine } from "../__generated__/listDoorMine";
import { listDoorRefund } from "../__generated__/listDoorRefund";

interface IGeneralDoorRefundListProps {
  data: listDoorRefund | undefined;
  loading: boolean;
  refetch: () => void;
  setDoorHandler: (doorId: number) => void;
}

export const SubGeneralListDoorRefund: React.FC<
  IGeneralDoorRefundListProps
> = ({ data, loading, refetch, setDoorHandler }) => {
  const [cancelopen, setCancelOpen] = useState(false);
  const [doorIdToUp, setDoorIdToUp] = useState<number>(0);
  const closeHandler = () => {
    setCancelOpen(false);
  };
  const refundStatus = (refundStatus: any) => {
    var Status = "";
    switch (refundStatus) {
      case DoorStatus.New:
        Status = "환불요청";
        return Status;
      case DoorStatus.Pending:
        Status = "환불반려";
        return Status;
      case DoorStatus.Failed:
        Status = "환불실패";
        return Status;
      case DoorStatus.Refunded:
        Status = "환불완료";
        return Status;
      case DoorStatus.Canceled:
        Status = "환불취소";
        return Status;
    }
  };
  const onCancel = async (data: cancelRefund) => {
    const {
      cancelRefund: { ok, err },
    } = data;
    if (ok) {
      alert("취소하였습니다");
      refetch();
    }
    if (!ok) {
      alert(err);
    }
  };
  const [cancelRefund, { loading: cancelRefundLoading }] =
    useCancelRefund(onCancel);
  const cancelRefundHandler = (refundIdToCancel: number) => {
    if (window.confirm("취소하시겠습니까?")) {
      if (!cancelRefundLoading) {
        const input: CancelRefundInput = {
          refundIdToCancel,
        };
        cancelRefund({ variables: { input } });
      }
    }
  };
  return (
    <div className="bg-white p-5 rounded-2xl shadow-lg">
      <p className="text-main font-bold py-2">환불 문의</p>
      <table className="w-full overflow-hidden">
        <colgroup>
          <col className={`w-3/12`} />
          <col className={`w-3/12`} />
          <col className={`w-2/12`} />
          <col className={`w-1/12`} />
          <col className={`w-1/12`} />
          <col className={`w-2/12`} />
        </colgroup>
        <thead>
          <tr className="bg-main  border-gray-300">
            <th className="text-white text-md py-1 sm:table-cell hidden">
              요청 날짜
            </th>
            <th className="text-white text-md py-1 sm:table-cell hidden">
              클래스
            </th>
            <th className="text-white text-md py-1">작가</th>
            <th className="text-white text-md py-1">결제금액</th>
            <th className="text-white text-md py-1 min-w-[50px]">환불상태</th>
            <th className="text-white text-md py-1 sm:table-cell">기능</th>
          </tr>
        </thead>
        <tbody>
          {!loading &&
            data?.listDoorRefund.doors &&
            data.listDoorRefund.doors.map((door, index) => (
              <tr
                key={`door-refund-${door.id}`}
                className="border-b-2 border-gray-300 "
              >
                <td className="text-md text-center py-1 sm:table-cell hidden">
                  {moment(door.createdAt).format("yy년 M월 D일 HH:mm")}
                </td>
                <td className="text-md text-center py-1">
                  <p className="w-full ellipsis1 h-[20px] overflow-hidden">
                    {door.receipt?.pgOption?.name}
                  </p>
                </td>
                <td className="text-md text-center py-1 sm:table-cell hidden">
                  {door.author.name}
                </td>
                <td className={`text-sm text-center py-1`}>
                  {door.receipt?.paid_amount.toLocaleString()}
                </td>
                <td className={`text-sm text-center py-1`}>
                  {refundStatus(door.status)}
                </td>
                <td className={`text-sm text-center py-1 sm:table-cell hidden`}>
                  <TextButton
                    canClick={
                      !cancelRefundLoading &&
                      door.status !== DoorStatus.Refunded &&
                      door.status !== DoorStatus.Canceled
                    }
                    actionText={`취소`}
                    onClick={() => {
                      cancelRefundHandler(door.id);
                    }}
                    className={`text-reds`}
                  />
                </td>
              </tr>
            ))}
          {!loading && data?.listDoorRefund.doors?.length === 0 && (
            <tr>
              <td colSpan={5}>
                <p className={`py-1 text-center`}>문의하신 내역이 없습니다.</p>
              </td>
            </tr>
          )}
          {!loading && !data && (
            <tr>
              <td colSpan={5}>
                <p className={`py-1 text-center`}>문의하신 내역이 없습니다.</p>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};
