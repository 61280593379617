import React from "react";
import { gql, useLazyQuery, useQuery } from "@apollo/client";
import {
  getBillOption,
  getBillOptionVariables,
} from "../__generated__/getBillOption";

const GET_BILL_OPTION_QUERY = gql`
  query getBillOption($input: GetBillOptionInput!) {
    getBillOption(input: $input) {
      ok
      err
      billOption {
        CEOName
        MemberName
        ID
      }
    }
  }
`;

export const useGetBillOption = (
  onCompleted?: (data: getBillOption) => void
) => {
  return useLazyQuery<getBillOption, getBillOptionVariables>(
    GET_BILL_OPTION_QUERY,
    {
      onCompleted,
      fetchPolicy: `network-only`,
    }
  );
};
