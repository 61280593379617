import React from "react";
import { gql, useLazyQuery } from "@apollo/client";
import {
  listPlaceActive,
  listPlaceActiveVariables,
} from "../__generated__/listPlaceActive";

const LIST_PLACE_ACTIVE_QUERY = gql`
  query listPlaceActive($input: ListPlaceActiveInput!) {
    listPlaceActive(input: $input) {
      ok
      err
      products {
        uuid
        id
        productInfo {
          title
          place
          summary
          playTime
          price
        }
        createdAt
        productStatus
        productType
        inst {
          id
          user {
            name
          }
        }
        productViews {
          index
          id
          is_rep
          view {
            url
          }
        }
      }
      totalPages
      totalResults
      row
    }
  }
`;

export const useListPlaceActive = (
  onCompleted?: (data: listPlaceActive) => void
) => {
  return useLazyQuery<listPlaceActive, listPlaceActiveVariables>(
    LIST_PLACE_ACTIVE_QUERY,
    {
      onCompleted,
      fetchPolicy: "network-only",
    }
  );
};
