import { ApolloError, gql, useMutation } from "@apollo/client";
import React from "react";
import {
  upProductStatus,
  upProductStatusVariables,
} from "../__generated__/upProductStatus";

const UP_PRODUCT_STATUS_MUTATION = gql`
  mutation upProductStatus($input: UpProductStatusInput!) {
    upProductStatus(input: $input) {
      ok
      err
    }
  }
`;

export const useUpProductStatus = (
  onCompleted?: (data: upProductStatus) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<upProductStatus, upProductStatusVariables>(
    UP_PRODUCT_STATUS_MUTATION,
    {
      onCompleted,
      onError,
    }
  );
};
