import React from "react";
import { gql, useLazyQuery } from "@apollo/client";
import {
  listBannerOption,
  listBannerOptionVariables,
} from "../__generated__/listBannerOption";

const LIST_BANNER_OPTION_QUERY = gql`
  query listBannerOption($input: ListBannerOptionInput!) {
    listBannerOption(input: $input) {
      ok
      err
      totalPages
      totalResults
      row
      bannerOptions {
        id
        index
        link
        bannerOptionType
        view {
          uuid
          url
        }
        subView {
          uuid
          url
        }
        product {
          id
          uuid
          productType
          productInfo {
            title
          }
          productViews {
            id
            is_rep
            view {
              url
              uuid
            }
          }
          inst {
            id
            user {
              name
            }
          }
        }
      }
    }
  }
`;

export const useListBannerOption = (
  onCompleted?: (data: listBannerOption) => void
) => {
  return useLazyQuery<listBannerOption, listBannerOptionVariables>(
    LIST_BANNER_OPTION_QUERY,
    {
      onCompleted,
      fetchPolicy: `network-only`,
    }
  );
};
