import {
  ApolloError,
  gql,
  useLazyQuery,
  useMutation,
  useQuery,
} from "@apollo/client";
import React from "react";
import {
  searchNaver,
  searchNaverVariables,
} from "../__generated__/searchNaver";

const SEARCH_NAVER_MUTATION = gql`
  query searchNaver($input: SearchNaverInput!) {
    searchNaver(input: $input) {
      ok
      err
      display
      start
      items {
        title
        link
        category
        description
        address
        roadAddress
        mapx
        mapy
      }
    }
  }
`;

export const useSearchNaver = (
  onCompleted?: (data: searchNaver) => void,
  onError?: (error: ApolloError) => void
) => {
  return useLazyQuery<searchNaver, searchNaverVariables>(
    SEARCH_NAVER_MUTATION,
    {
      onCompleted,
      onError,
      fetchPolicy: "network-only",
    }
  );
};
