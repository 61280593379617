import { List } from "./parts/list";
import { useEffect, useState } from "react";
import { Footer } from "../../../../layout/footer";
import { Request } from "./parts/request";
import { Aside } from "../aside";
import { SubTop } from "../../../../layout/subTop";
import { useMe } from "../../../../hooks/useMe";
import { ToTheTop } from "../../../../components/to-the-top";
import { pageIndicator } from "../../../../apollo";
import moment from "moment";
import { SubInstDetailConsult } from "../../../../sub-routes/sub-inst-detail-consults";
import { useListConsultMine } from "../../../../hooks/useListConsultMine";
import { ListConsultMineInput } from "../../../../__generated__/globalTypes";
import { ComponentTab, ITab } from "../../../../components/component-tab";
import { InstDetailConsult } from "../../../../sub-routes/sub-inst-detail-consult";
export const Consult_st = () => {
  const [page, setPage] = useState<number>(0);
  const [search, setSearch] = useState<string>("");
  const [tab, setTab] = useState(0);
  const [consultId, setConsultId] = useState<number>(0);
  const setTabHandler = (tab: number) => {
    setTab(tab);
  };
  const setConsultHandler = (consultId: number) => {
    setTabHandler(2);
    setConsultId(consultId);
  };
  const tabList: ITab[] = [{ text: "컨설팅 내역" }, { text: "컨설팅 요청" }];

  const { data: me } = useMe();

  const [listConsultMine, { data, loading, refetch }] = useListConsultMine();
  const listConsultMineHandler = (page?: number, search?: string) => {
    const input: ListConsultMineInput = {
      ...(page && { page }),
      ...(search && { search: decodeURI(search) }),
    };
    listConsultMine({ variables: { input } });
  };

  useEffect(() => {
    listConsultMineHandler(page, search);
  }, []);
  return (
    <div className="bg-main bg-opacity-50 w-full h-full min-h-screen">
      <div className="max-w-screen-yl mx-auto w-full h-full min-h-screen  bg-white">
        <SubTop title={"nothing"} children={me?.me.name} />
        <main id="main">
          <div className="lg:grid lg:grid-cols-[300px_1fr]">
            <Aside page={2} />
            <ToTheTop posi={`bottom-32 right-4 lg:bottom-16`} white={true} />

            <div className="mypages ">
              <div className="lectureWrap">
                <div className="h-12 lg:h-20">
                  <ComponentTab
                    tabs={tabList}
                    tab={tab}
                    setTabHandler={setTabHandler}
                  />
                </div>
                <div className=" bg-gray-100 h-fix-content relative overflow-hidden pb-28 sm:pb-16 lg:pb-0 grid grid-rows-1 ">
                  <div className={`w-full h-full p-4 lg:px-10 lg:py-12`}>
                    {tab === 0 && (
                      <div className="bg-white p-4 rounded-2xl shadow-lg">
                        <p className="text-main font-bold py-2">컨설팅 내역</p>
                        <table className="w-full">
                          <thead>
                            <tr className="bg-main  border-gray-300">
                              <th className="text-white lg:table-cell hidden">
                                No.
                              </th>
                              <th className="text-white ">분류</th>
                              <th className="text-white ">제목</th>
                              <th className="text-white ">상태</th>
                              <th className="text-white lg:table-cell hidden">
                                컨설턴트
                              </th>
                              <th className="text-white lg:table-cell hidden">
                                작성일
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {!loading &&
                              data?.listConsultMine.consults &&
                              data.listConsultMine.consults.map(
                                (consult, index) => (
                                  <tr
                                    key={`consult-${consult.id}`}
                                    className="border-b-2 border-gray-300 hover:bg-gray-100 cursor-pointer"
                                    onClick={() =>
                                      setConsultHandler(consult.id)
                                    }
                                  >
                                    <td className="text-md text-center py-1 lg:table-cell hidden">
                                      {consult.id}
                                    </td>
                                    <td className="text-md text-center py-1 ">
                                      {consult.type}
                                    </td>
                                    <td className="text-md text-center py-1">
                                      <p className="w-full ellipsis1 h-[20px] overflow-hidden">
                                        {consult.title}
                                      </p>
                                    </td>
                                    <td className={`text-sm text-center py-1`}>
                                      {consult.status === "Requested" && "요청"}
                                      {consult.status === "Pending" &&
                                        "확인 중"}
                                      {consult.status === "Replied" &&
                                        "답변완료"}
                                      {consult.status === "Completed" &&
                                        "문의완료"}
                                    </td>
                                    <td
                                      className={`text-sm text-center py-1 lg:table-cell hidden`}
                                    >
                                      {consult.curator
                                        ? `${consult.curator.name}`
                                        : `-`}
                                    </td>
                                    <td
                                      className={`text-sm text-center py-1 lg:table-cell hidden`}
                                    >
                                      {moment(consult.createdAt).format(
                                        "yy년 M월 D일"
                                      )}
                                    </td>
                                  </tr>
                                )
                              )}
                            {!loading &&
                              data?.listConsultMine.consults?.length === 0 && (
                                <tr>
                                  <td colSpan={6}>
                                    <p className={`py-1 text-center`}>
                                      문의하신 내역이 없습니다.
                                    </p>
                                  </td>
                                </tr>
                              )}
                            {!loading && !data && (
                              <tr>
                                <td colSpan={6}>
                                  <p className={`py-1 text-center`}>
                                    문의하신 내역이 없습니다.
                                  </p>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    )}
                    {tab === 1 && <Request />}
                    {tab === 2 && (
                      <InstDetailConsult
                        consultId={consultId}
                        setTabHandler={setTabHandler}
                      />
                    )}
                  </div>
                  <Footer></Footer>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
