import axios, { AxiosRequestConfig } from "axios";
import React from "react";
import {
  authTokenVar,
  UPLOAD_BUSINESS_URI,
  UPLOAD_URI,
  UPLOAD_VIDEO_URI,
} from "../apollo";

const headers: AxiosRequestConfig["headers"] = {
  "lo-jwt": authTokenVar() || "",
};

export const upload = async (event: any) => {
  let data = new FormData();
  let file = event.target.files[0];
  data.append("file", file);
  const config = {
    headers,
  };
  return await axios.post(UPLOAD_URI, data, config);
};

export const uploadBusiness = async (event: any) => {
  let data = new FormData();
  let file = event.target.files[0];
  data.append("file", file);
  return await axios.post(UPLOAD_BUSINESS_URI, data);
};

export const uploadVideo = async (event: any) => {
  let data = new FormData();
  let file = event.target.files[0];
  data.append("file", file);
  const config = {
    headers,
  };
  return await axios.post(UPLOAD_VIDEO_URI, data, config);
};
