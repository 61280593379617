import logo from "../../assets/icon/localpick_logo.svg";
import { useForm } from "react-hook-form";
import { ApolloError, gql, useMutation } from "@apollo/client";
import { LoginButton } from "../../components/button";
import { signIn } from "../../constants/token";
import { Link } from "react-router-dom";
import { LoginInput } from "../../__generated__/globalTypes";
import { login, loginVariables } from "../../__generated__/login";
import { useSignIn } from "../../hooks/useSignin";
export interface ILoginForm extends LoginInput {}

export const LOGIN = () => {
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors, isValid },
  } = useForm<ILoginForm>({ mode: "onChange" });

  const onCompleted = (data: login) => {
    const {
      login: { ok, token },
    } = data;
    if (ok && token) {
      signIn(token);
    } else {
      alert("아이디와 비밀번호를 확인해주세요");
      setValue("password", "");
      return;
    }
  };

  const onError = (error: ApolloError) => {};

  const [loginMutation, { data: loginMutationResult, loading }] =
    useSignIn(onCompleted);

  const onSubmit = () => {
    if (!loading) {
      const { email, password } = getValues();
      const loginInput: LoginInput = { email, password };
      loginMutation({
        variables: {
          loginInput,
        },
      });
    }
  };

  const onInvalid = () => {};

  return (
    <div className="bg-main bg-opacity-50 w-full h-full min-h-screen">
      <div className="max-w-screen-yl mx-auto w-full h-full min-h-screen bg-white flex flex-wrap justify-center content-center">
        <div className="logBox w-full max-w-xl">
          <Link to="/" className="block w-[200px] mx-auto">
            <img src={logo} alt="로로 로고" className="w-full" />
          </Link>
          <form
            onSubmit={handleSubmit(onSubmit, onInvalid)}
            className="pt-10 mx-auto max-w-md"
          >
            <label className="block pt-5 px-5">
              <span className="inline-block w-1/12 text-main uppercase font-bold">
                id
              </span>
              <input
                {...register("email", {
                  required: "이메일은 필수 입력 정보입니다.",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "invalid email address",
                  },
                })}
                type="text"
                className="inline-block rounded-none border-b border-main focus:outline-none pl-1 leading-normal w-10/12"
                autoComplete="username"
              />
            </label>
            <label className="block pt-10 pb-12 px-5">
              <span className="inline-block w-1/12 text-main uppercase font-bold">
                pw
              </span>
              <input
                {...register("password", {
                  required: `패스워드는 필수 입력 정보입니다.`,
                })}
                type="password"
                autoComplete="current-password"
                className="inline-block rounded-none border-b border-main focus:outline-none tracking-tighter pl-1 leading-normal w-10/12"
              />
            </label>
            <LoginButton
              canClick={isValid}
              actionText={`로그인`}
              loading={loading}
              color={`bg-main text-white`}
            />
            {/* <button
              className="bg-main text-white text-bold flex flex-wrap content-center justify-center w-full sm:w-3/4 py-2 mx-auto hover:opacity-80 "
              type="button"
              onClick={noBlank}
            >
              로그인
            </button> */}
          </form>
          <p className="text-text text-xs text-center pt-5">
            아이디가 아직 없으신가요?{" "}
            <Link
              to="/join/agree"
              className="text-main font-bold hover:opacity-60"
            >
              회원가입
            </Link>
            하러가기
          </p>
          <p className="pt-3 text-center text-xs">
            <Link to="/findId" className="hover:underline">
              ID찾기
            </Link>{" "}
            /
            <Link to="/findPwd" className="hover:underline">
              비밀번호 찾기
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};
