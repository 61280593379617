import React from "react";
import gql from "graphql-tag";
import { ApolloError, useMutation } from "@apollo/client";
import {
  sendChangePasswordEmail,
  sendChangePasswordEmailVariables,
} from "../__generated__/sendChangePasswordEmail";
import { SendChangePasswordEmailInput } from "../__generated__/globalTypes";

export interface ISendChangePasswordEmailForm
  extends SendChangePasswordEmailInput {}

const SEND_CHANGE_PASSWORD_EMAIL_MUTATION = gql`
  mutation sendChangePasswordEmail($input: SendChangePasswordEmailInput!) {
    sendChangePasswordEmail(input: $input) {
      ok
      err
    }
  }
`;

export const useSendChangePasswordEmail = (
  onCompleted?: (data: sendChangePasswordEmail) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<sendChangePasswordEmail, sendChangePasswordEmailVariables>(
    SEND_CHANGE_PASSWORD_EMAIL_MUTATION,
    {
      onCompleted,
      onError,
    }
  );
};
