import { useEffect, useState } from "react";
import usePagination from "@mui/material/usePagination";
import { styled } from "@mui/material/styles";
import { pageIndicator } from "../apollo";
import { SubTop } from "../layout/subTop";
import searchImg from "../assets/icon/search.png";
import { AsideAdminComponent } from "../layout/aside-admin";
import { Footer } from "../layout/footer";
import { Link } from "react-router-dom";
import { createBrowserHistory } from "history";
import { useLocation } from "react-router";
import {
  ActivateBannerInput,
  DelBannerInput,
  ListBannerInput,
} from "../__generated__/globalTypes";
import { TableError } from "../components/table-error";
import { TextButton } from "../components/button";
import { useForm } from "react-hook-form";
import { useActivateBanner } from "../hooks/useActivateBanner";
import { activateBanner } from "../__generated__/activateBanner";
import { useListBanner } from "../hooks/useListBanner";
import moment from "moment";
import { delBanner } from "../__generated__/delBanner";
import { useDelBanner } from "../hooks/useDelBanner";
import { ISearchFormProps } from "./admin-list-magazine";
import { AdminTop } from "../layout/adminTop";

export const AdminListBanner = () => {
  const history = createBrowserHistory();
  const location = useLocation();

  const pageHandler = (page: number) => {
    const search =
      location.search.split("search=").length > 1
        ? location.search.split("search=")[1].split("&")[0]
        : null;
    history.push({
      pathname: "/admin/banner",
      search: `?page=${page}${search ? `&search=${search}` : ""}`,
    });
    listBannerHandler(page, search);
  };

  const { register, getValues, handleSubmit } = useForm<ISearchFormProps>({
    mode: "all",
  });

  const onSearch = async () => {
    const { search } = getValues();
    history.push({
      pathname: "/admin/banner",
      search: `${search ? `?search=${search}` : ""}`,
    });
    listBannerHandler(1, search);
  };

  useEffect(() => {
    pageIndicator("banner");
  }, []);

  const onUpComplete = async (data: activateBanner) => {
    const {
      activateBanner: { ok, err },
    } = data;
    if (ok) {
      alert(`배너 상태가 수정되었습니다`);
      await refetch();
    } else {
      alert(err);
    }
  };
  const [activateBanner, { loading: activateBannerLoading }] =
    useActivateBanner(onUpComplete);
  const activateBannerHandler = (bannerIdToActivate: number) => {
    if (!activateBannerLoading) {
      const input: ActivateBannerInput = {
        bannerIdToActivate,
      };
      activateBanner({ variables: { input } });
    }
  };

  const [listBanner, { data, loading, refetch }] = useListBanner();
  const listBannerHandler = (page: number | null, search: string | null) => {
    const input: ListBannerInput = {
      ...(page && { page: +page }),
      ...(search && { search: decodeURI(search) }),
    };
    listBanner({ variables: { input } });
  };

  const onDelComplete = async (data: delBanner) => {
    const {
      delBanner: { ok, err },
    } = data;
    if (!ok) {
      alert(err);
      return;
    }
    alert("삭제되었습니다");
    await refetch();
  };
  const [delBanner, { loading: delBannerLoading }] =
    useDelBanner(onDelComplete);
  const delBannerHandler = (bannerIdToDel: number) => {
    const input: DelBannerInput = {
      bannerIdToDel,
    };
    delBanner({ variables: { input } });
  };

  const { items } = usePagination({
    count: data?.listBanner.totalPages ? data.listBanner.totalPages : 1,
  });

  const List = styled("ul")({
    listStyle: "none",
    paddingTop: 20,
    textAlign: "center",
    display: "block",
  });

  useEffect(() => {
    const page =
      location.search.split("page=").length > 1
        ? +location.search.split("page=")[1].split("&")[0]
        : 1;
    const search =
      location.search.split("search=").length > 1
        ? location.search.split("search=")[1].split("&")[0]
        : null;
    listBannerHandler(page, search);
  }, []);

  return (
    <div className="bg-main bg-opacity-50 w-full h-full min-h-screen">
      <div className="w-[1440px] mx-auto h-full min-h-screen  bg-white">
        <AdminTop title={"top"} />
        <main id="main">
          <div className="grid grid-cols-[300px_1fr]">
            <AsideAdminComponent />
            <div className="mypages ">
              <div className="lectureWrap">
                <div className="h-20">
                  <div className="h-full flex-wrap flex justify-between px-10 content-center">
                    <div className="subtitle h-full flex flex-wrap content-end gap-x-3">
                      <div
                        className={`inline-block w-44 text-center border-main border-b-2`}
                      >
                        <Link
                          className={`inline-block leading-normal font-bold hover:opacity-60 w-full py-1 text-main`}
                          to={`/admin/banner`}
                        >
                          목록
                        </Link>
                      </div>
                      <div className={`inline-block w-44 text-center`}>
                        <Link
                          className={`inline-block leading-normal font-bold hover:opacity-60 w-full py-1 text-gray-400`}
                          to={`/admin/banner/create`}
                        >
                          배너 생성
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className=" bg-gray-100 h-fix-content relative">
                  <div className={`w-full h-full px-10 py-12`}>
                    <div className="text-right pb-8">
                      <form onSubmit={handleSubmit(onSearch)}>
                        <p className="inline-block max-w-md w-full text-right relative">
                          <input
                            {...register(`search`)}
                            type="text"
                            className="w-full py-1 px-4 bg-white rounded-full"
                            placeholder="매거진 이름으로 검색"
                          />
                          <button className="absolute right-3 top-1">
                            <img src={searchImg} alt="찾기" className="w-5" />
                          </button>
                        </p>
                      </form>
                    </div>
                    {/* 여기 */}
                    <div className="bg-white p-5 rounded-2xl shadow-lg">
                      <p className="text-main font-bold pb-3">배너 목록</p>
                      <table className="w-full">
                        <colgroup>
                          <col className="w-2/12" />
                          <col className="w-4/12" />
                          <col className="w-1/12" />
                          <col className="w-2/12" />
                          <col className="w-1/12" />
                          <col className="w-2/12" />
                        </colgroup>
                        <thead>
                          <tr className="bg-main  border-gray-300">
                            <th className="text-white ">번호</th>
                            <th className="text-white ">제목</th>
                            <th className="text-white ">상태</th>
                            <th className="text-white ">수정일</th>
                            <th className="text-white ">생성일</th>
                            <th className="text-white ">기능</th>
                          </tr>
                        </thead>
                        <tbody>
                          {!loading &&
                            data?.listBanner.banners &&
                            data.listBanner.banners.map((banner) => (
                              <tr
                                key={`banner-${banner?.id}`}
                                className="border-b-2 border-gray-300 h-4"
                              >
                                <td className="text-sm text-center py-1 px-1 h-full overflow-hidden">
                                  {banner.id && `${banner.id}`}
                                </td>
                                <td className="text-sm text-center h-full relative">
                                  <Link
                                    to={`/admin/banner/update/${banner.id}`}
                                    className={`cursor-pointer`}
                                  >
                                    {banner?.title && `${banner.title}`}
                                  </Link>
                                </td>
                                <td className="text-sm text-center relative">
                                  {banner.is_active ? `활성화` : `비활성화`}
                                </td>
                                <td className="text-sm text-center py-1 px-1 h-full overflow-hidden">
                                  {banner.updatedAt &&
                                    `${moment(banner.updatedAt).format(
                                      "YYYY-M-D"
                                    )}`}
                                </td>
                                <td className="text-sm text-center py-1 px-1 h-full overflow-hidden ">
                                  {banner.createdAt &&
                                    `${moment(banner.createdAt).format(
                                      "YYYY-M-D"
                                    )}`}
                                </td>
                                <td className="text-sm text-center py-1 px-1 h-full w-1/6 overflow-hidden">
                                  <TextButton
                                    canClick={
                                      !loading &&
                                      !delBannerLoading &&
                                      !banner.is_active
                                    }
                                    actionText={`활성화`}
                                    onClick={() =>
                                      activateBannerHandler(banner.id)
                                    }
                                  />
                                  {` / `}
                                  <TextButton
                                    canClick={!loading && !delBannerLoading}
                                    actionText={`삭제`}
                                    onClick={() => delBannerHandler(banner.id)}
                                  />
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                      {loading && (
                        <TableError errorMessage={`정보를 불러오는 중입니다`} />
                      )}
                      {!loading &&
                        data?.listBanner.banners &&
                        data.listBanner.banners.length < 1 && (
                          <TableError
                            errorMessage={`해당하는 배너 정보가 없습니다`}
                          />
                        )}
                      {!loading &&
                        data?.listBanner.banners &&
                        data.listBanner.banners.length > 0 && (
                          <List>
                            {items.map(
                              ({ page, type, selected, ...item }, index) => {
                                let children = null;

                                if (
                                  type === "start-ellipsis" ||
                                  type === "end-ellipsis"
                                ) {
                                  children = "…";
                                } else if (type === "page") {
                                  children = (
                                    <button
                                      type="button"
                                      style={{
                                        fontWeight: selected
                                          ? "bold"
                                          : undefined,
                                      }}
                                      {...item}
                                      onClick={() => pageHandler(page)}
                                    >
                                      {page}
                                    </button>
                                  );
                                } else if (type === "previous") {
                                  children = (
                                    <button type="button" {...item}>
                                      <i className="fas fa-angle-left"></i>
                                    </button>
                                  );
                                } else if (type === "next") {
                                  children = (
                                    <button type="button" {...item}>
                                      <i className="fas fa-angle-right"></i>
                                    </button>
                                  );
                                }
                                return (
                                  <li
                                    key={index + type}
                                    className={`text-xs px-2  border-grays inline-block ${
                                      (type === "page" &&
                                        page !==
                                          data.listBanner.banners?.length &&
                                        items.length %
                                          Number(
                                            data.listBanner.banners?.length
                                          ) >
                                          3 &&
                                        "border-r") ||
                                      (type === "end-ellipsis" && "border-r") ||
                                      (type === "start-ellipsis" && "border-r")
                                    }`}
                                  >
                                    {children}
                                  </li>
                                );
                              }
                            )}
                          </List>
                        )}
                    </div>
                  </div>
                  <Footer></Footer>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
