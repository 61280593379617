import React from "react";

interface IFormErrorProps {
  errorMessage: string;
}

export const FormError: React.FC<IFormErrorProps> = ({
  errorMessage,
}: IFormErrorProps) => (
  <p className="absolute bottom-1 left-[25%] text-reds text-xs">
    {errorMessage}
  </p>
);

export const FormError2: React.FC<IFormErrorProps> = ({
  errorMessage,
}: IFormErrorProps) => <p className="py-1 text-reds text-xs">{errorMessage}</p>;

export const FormError3: React.FC<IFormErrorProps> = ({
  errorMessage,
}: IFormErrorProps) => (
  <p className="absolute bottom-1 left-0 text-reds text-xs">{errorMessage}</p>
);
