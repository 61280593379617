import React from "react";
import { gql, useLazyQuery, useQuery } from "@apollo/client";
import {
  listReceipt,
  listReceiptVariables,
} from "../__generated__/listReceipt";

const LIST_RECEIPT_QUERY = gql`
  query listReceipt($input: ListReceiptInput!) {
    listReceipt(input: $input) {
      ok
      err
      totalResults
      totalPages
      row
      receipts {
        id
        scheduledAt
        is_expired
        paid_amount
        createdAt
        receiver
        phone
        address
        message
        status
        pgOption {
          name
          receipt_url
          merchant_uid
          pay_method
        }
        product {
          productInfo {
            scheduledAt
            title
          }
          inst {
            id
            atelier {
              name
            }
            user {
              name
            }
          }
          createdAt
        }
        user {
          name
          email
        }
      }
    }
  }
`;

export const useListReceipt = (onCompleted?: (data: listReceipt) => void) => {
  return useLazyQuery<listReceipt, listReceiptVariables>(LIST_RECEIPT_QUERY, {
    onCompleted,
    fetchPolicy: `network-only`,
  });
};
