import { ApolloError, gql, useMutation } from "@apollo/client";
import React from "react";
import {
  verifyEmail,
  verifyEmailVariables,
} from "../__generated__/verifyEmail";

const VERIFY_EMAIL_MUTATION = gql`
  mutation verifyEmail($input: VerifyEmailInput!) {
    verifyEmail(input: $input) {
      ok
      err
    }
  }
`;

export const useVerifyEmail = (
  onCompleted?: (data: verifyEmail) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<verifyEmail, verifyEmailVariables>(VERIFY_EMAIL_MUTATION, {
    onCompleted,
    onError,
  });
};
