import { useEffect, useState } from "react";
import { pageIndicator } from "../apollo";
import { AsideAdminComponent } from "../layout/aside-admin";
import { Footer } from "../layout/footer";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import {
  GetConsultInput,
  ListConsultReplyInput,
} from "../__generated__/globalTypes";
import { TableError } from "../components/table-error";
import { AdminTop } from "../layout/adminTop";
import { useGetConsult } from "../hooks/useGetConsult";
import { useListConsultReply } from "../hooks/useListConsultReply";
import moment from "moment";
import { useMe } from "../hooks/useMe";
import { ComponentCrConsultReply } from "../components/component-cr-consult-reply";
import { ComponentUpConsultReply } from "../components/component-up-consult-reply";

export const AdminDetailConsult = () => {
  const history = useNavigate();
  const params = useParams();
  const [edit, setEdit] = useState<boolean>(false);
  const editHandler = () => {
    setEdit(!edit);
  };

  const { data: me } = useMe();

  useEffect(() => {
    pageIndicator("user");
  }, []);

  const [getConsult, { data, loading, refetch }] = useGetConsult();
  const getConsultHandler = (consultId: number) => {
    const input: GetConsultInput = {
      consultId,
    };
    getConsult({ variables: { input } });
  };

  const [
    listConsultReply,
    {
      data: listConsultReplyData,
      loading: listConsultReplyLoading,
      refetch: listConsultReplyRefetch,
    },
  ] = useListConsultReply();
  const listConsultReplyHandler = (consultId: number) => {
    const input: ListConsultReplyInput = {
      consultId,
    };
    listConsultReply({ variables: { input } });
  };

  useEffect(() => {
    if (params.consultId) {
      getConsultHandler(+params.consultId);
      listConsultReplyHandler(+params.consultId);
    }
  }, []);

  return (
    <div className="bg-main bg-opacity-50 w-full h-full min-h-screen">
      <div className="w-[1440px] mx-auto h-full min-h-screen  bg-white">
        <AdminTop title={"top"} children={"관리자모드"} />
        <main id="main">
          <div className="grid grid-cols-[300px_1fr]">
            <AsideAdminComponent />
            <div className="mypages">
              <div className="lectureWrap">
                <div className="h-20"></div>
                <div className=" bg-gray-100 h-fix-content relative box-border">
                  {!loading && data?.getConsult.ok && (
                    <div className={`w-full h-full px-10 py-12`}>
                      <div className="bg-white p-4 rounded-2xl shadow-lg relative">
                        <div className="border-b ">
                          <div className="qinfo">
                            <p className="p-1 text-left text-text border-b border-[#e2e2e2 text-sm">
                              <span>작성일:</span>{" "}
                              {moment(
                                data?.getConsult.consult?.createdAt
                              ).format("yyyy년 M월 D일 HH:mm")}
                            </p>
                            <p className="bg-[#e2e2e2] py-1 pl-3 border-b border-[#e2e2e2 font-bold">
                              {data?.getConsult.consult?.title}
                            </p>
                            <p className="py-1 pl-3 border-b border-[#e2e2e2]">
                              <span className="mr-3">상태:</span>{" "}
                              <span className="font-bold">
                                {data?.getConsult.consult?.status}
                              </span>
                            </p>
                            <p className="py-1 pl-3 border-b border-[#e2e2e2]">
                              <span className="mr-3">분류:</span>{" "}
                              <span className="font-bold">
                                {data?.getConsult.consult?.type}
                              </span>
                            </p>
                            <p className="py-1 pl-3 border-b border-[#e2e2e2]">
                              <span className="mr-3">글쓴이:</span>
                              <span className="font-bold">
                                {data?.getConsult.consult?.author?.name}
                              </span>
                            </p>
                          </div>
                          <div className="p-4">
                            <textarea
                              value={String(data?.getConsult.consult?.note)}
                              disabled={!edit}
                              id="desc"
                              className="text-sm w-full resize-none h-48 whitespace-pre-wrap"
                            ></textarea>

                            {data?.getConsult.consult?.author?.id ===
                              me?.me.id && (
                              <p className={`text-right ${edit && "hidden"}`}>
                                <button
                                  type="button"
                                  onClick={editHandler}
                                  className={`text-sm border border-grays rounded-md py-1 px-3 hover:opacity-60 `}
                                >
                                  수정
                                </button>
                              </p>
                            )}
                          </div>
                          {params.consultId && (
                            <ComponentCrConsultReply
                              consultId={+params.consultId}
                              listRefetch={listConsultReplyRefetch}
                              getRefetch={refetch}
                            />
                          )}
                          {!listConsultReplyLoading &&
                            listConsultReplyData?.listConsultReply.consultReplies?.map(
                              (consultReply) => (
                                <ComponentUpConsultReply
                                  consultReply={consultReply}
                                  listRefetch={listConsultReplyRefetch}
                                />
                              )
                            )}
                        </div>
                        <div className="btnBox pt-4 text-center space-x-4">
                          <Link
                            to={`/admin/consult`}
                            className={`inline-block rounded-md text-main w-[120px] py-1 hover:opacity-60 text-sm lg:text-base bg-white border border-main ${
                              edit && "hidden"
                            }`}
                          >
                            뒤로가기
                          </Link>
                          <button
                            type="button"
                            onClick={editHandler}
                            className={`rounded-md text-white w-[120px] py-1 hover:opacity-60 text-sm lg:text-base bg-main border border-main ${
                              !edit && "hidden"
                            }`}
                          >
                            수정완료
                          </button>
                          {/*
                            <Button
                              className={`rounded-md text-white w-[300px] py-1 hover:opacity-60 text-sm lg:text-base bg-main border border-main`}
                              canClick={isValid}
                              actionText={`저장`}
                              loading={loading}
                              color={`text-main`}
                            />
                            */}
                        </div>
                      </div>
                    </div>
                  )}
                  {loading && (
                    <div className="w-full p-5 text-center box-border rounded">
                      <div className="bg-white w-full">
                        <TableError errorMessage={`정보를 불러오는 중입니다`} />
                      </div>
                    </div>
                  )}
                  <Footer></Footer>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
