import React, { useState } from "react";
import { Footer } from "../../../../layout/footer";
import { useParams } from "react-router";
import DaumPostcode from "react-daum-postcode";
import { Modal } from "@mui/material";
import { ApolloError } from "@apollo/client";
import { useUpUser } from "../../../../hooks/useUpUser";
import { useMe } from "../../../../hooks/useMe";
import { useForm } from "react-hook-form";
import {
  SendChangePasswordEmailInput,
  UpUserInput,
} from "../../../../__generated__/globalTypes";
import { upUser } from "../../../../__generated__/upUser";
import { Button } from "../../../../components/button";
import { SubTop } from "../../../../layout/subTop";
import { ASIDE } from "../aside";
import { sendChangePasswordEmail } from "../../../../__generated__/sendChangePasswordEmail";
import { useSendChangePasswordEmail } from "../../../../hooks/useSendChangePasswordEmail";
interface IUpUserFormProps extends UpUserInput {}

export const changePasswordText = `
아래 버튼을 클릭하면 비밀번호를 변경할 수 있는 링크가 가입된 이메일로 전송됩니다
해당 링크를 통해 비밀번호를 변경해주시기 바랍니다
`;

export const Change = () => {
  const params: any = useParams();
  const [tabs, setTabs] = useState(0);
  const [addressAPI, setAddressAPI] = useState(false);
  const { data, refetch } = useMe();

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors, isValid },
  } = useForm<IUpUserFormProps>({
    mode: "onChange",
  });
  const onCompleted = async (data: upUser) => {
    const {
      upUser: { ok },
    } = data;
    if (ok) {
      alert("개인정보가 수정되었습니다.");
      await refetch();
    }
  };
  const [upUser, { data: upUserResult, loading }] = useUpUser(onCompleted);
  const onSubmit = () => {
    if (!loading) {
      const { password, phone, address, subAddress } = getValues();
      const upUserInput: UpUserInput = {
        ...(password && { password }),
        phone,
        address,
        subAddress,
      };
      upUser({
        variables: {
          input: upUserInput,
        },
      });
    }
  };
  // daum API
  const handlePostCode = (data: any) => {
    let fullAddress = data.address;
    let extraAddress = "";

    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddress += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddress +=
          extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
    }
    setValue(`address`, fullAddress);
    setAddressAPI(false);
  };

  const onInvalid = () => {};
  const onSendCompleted = (data: sendChangePasswordEmail) => {
    const {
      sendChangePasswordEmail: { ok, err },
    } = data;
    if (ok) {
      alert(`메일을 보냈습니다`);
    } else {
      alert(err);
    }
  };
  const [
    sendChangePasswordEmail,
    {
      data: sendChangePasswordEmailData,
      loading: sendChangePasswordEmailLoading,
    },
  ] = useSendChangePasswordEmail(onSendCompleted);

  const sendChangePasswordEmailHandler = () => {
    if (!sendChangePasswordEmailLoading && data?.me.email) {
      const sendChangePasswordEmailInput: SendChangePasswordEmailInput = {
        email: data.me.email,
      };
      sendChangePasswordEmail({
        variables: { input: sendChangePasswordEmailInput },
      });
    }
  };
  return (
    <div className="bg-main bg-opacity-50 w-full h-full min-h-screen">
      <div className="max-w-screen-yl mx-auto w-full h-full min-h-screen  bg-white">
        <SubTop title={"nothing"} children={`${data?.me.name} 님`} />
        <main id="main">
          <div className="lg:grid lg:grid-cols-[300px_1fr]">
            <ASIDE page={4} />
            <div className="lectureWrap">
              <div className="h-12 lg:h-20">
                <div className="subtitle h-full flex flex-wrap content-end lg:px-10 px-3 sm:px-5 lg:gap-x-1">
                  <div
                    className={`inline-block  w-1/3 lg:w-44 ${
                      tabs === 0 && "border-main border-b-2"
                    }`}
                  >
                    <button
                      className={`text-xs sm:text-sm lg:text-base leading-normal font-bold hover:opacity-60 w-full py-1  ${
                        tabs === 0 ? "text-main" : "text-gray-400"
                      }`}
                      onClick={() =>
                        setTabs(() => {
                          return 0;
                        })
                      }
                    >
                      개인정보수정
                    </button>
                  </div>
                  <div
                    className={`inline-block  w-1/3 lg:w-44 ${
                      tabs === 1 && "border-main border-b-2"
                    }`}
                  >
                    <button
                      className={`text-xs sm:text-sm lg:text-base leading-normal font-bold hover:opacity-60 w-full py-1  ${
                        tabs === 1 ? "text-main" : "text-gray-400"
                      }`}
                      onClick={() =>
                        setTabs(() => {
                          return 1;
                        })
                      }
                    >
                      비밀번호 변경
                    </button>
                  </div>
                </div>
              </div>
              <div className="bg-gray-100 h-fix-content relative grid grid-rows-1 pb-28 sm:pb-0">
                <div className="w-full h-full px-4 sm:px-10 py-6 sm:py-12 space-y-10">
                  {tabs === 0 && (
                    <form onSubmit={handleSubmit(onSubmit, onInvalid)}>
                      <div className="bg-white p-5 rounded-2xl shadow-lg max-w-xl space-y-5">
                        <div className="space-y-2">
                          <p className="font-bold text-main lg:text-base text-sm ">
                            아이디
                          </p>
                          <input
                            type="text"
                            className="rounded-xl py-1 px-2 w-full lg:text-base text-sm"
                            disabled
                            value={data?.me.email}
                          />
                        </div>
                        <div className="space-y-2">
                          <p className="font-bold text-main lg:text-base text-sm ">
                            이름
                          </p>
                          <input
                            type="text"
                            className="rounded-xl py-1 px-2 w-full lg:text-base text-sm"
                            value={data?.me.name}
                            disabled
                          />
                        </div>
                        <div className="space-y-2">
                          <p className="font-bold text-main lg:text-base text-sm ">
                            휴대전화
                          </p>
                          <input
                            {...register("phone", {
                              required: "전화번호는 필수 사항입니다.",
                            })}
                            type="text"
                            className="border border-grays rounded-xl py-1 px-2 w-full lg:text-base text-sm"
                            placeholder="-없이 11자리로 작성해주세요"
                            maxLength={11}
                            defaultValue={data?.me.phone}
                          />
                        </div>
                        <div className="space-y-2">
                          <p className="font-bold text-main lg:text-base text-sm ">
                            주소
                          </p>
                          <div className="grid grid-cols-[2.5fr_1fr] gap-x-3">
                            <input
                              {...register(`address`)}
                              type="text"
                              className="border border-grays rounded-xl py-1 px-2 w-full lg:text-base text-sm"
                              defaultValue={data?.me.address}
                              disabled
                            />
                            <button
                              type="button"
                              onClick={() => setAddressAPI(true)}
                              className="hover:opacity-80 block w-full h-full border border-grays rounded-xl text-xs lg:text-sm"
                            >
                              주소찾기
                            </button>
                          </div>
                          <input
                            type="text"
                            {...register("subAddress", { required: true })}
                            className="border border-grays rounded-xl py-1 px-2 w-full lg:text-base text-sm"
                            defaultValue={data?.me.subAddress}
                          />
                          <Modal
                            open={addressAPI}
                            onClose={() => {
                              setAddressAPI(false);
                            }}
                          >
                            <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 p-3 bg-white rounded-md shadow-lg">
                              <DaumPostcode onComplete={handlePostCode} />
                            </div>
                          </Modal>
                        </div>
                        <div className="w-full sm:w-auto left-0 bottom-16 fixed sm:static text-center space-x-5 sm:pt-8 py-4 bg-white sm:bg-transparent">
                          <button
                            type="button"
                            onClick={() => {
                              window.location.reload();
                            }}
                            className="w-2/5 sm:w-40 py-1 border border-grays rounded-md hover:opacity-60"
                          >
                            취소
                          </button>
                          <Button
                            canClick={isValid}
                            actionText={`수정하기`}
                            className={`bg-main w-2/5 sm:w-40 py-1 text-white rounded-md`}
                            loading={loading}
                            color={`bg-main`}
                          />
                        </div>
                      </div>
                    </form>
                  )}
                  {tabs === 1 && (
                    <div className="bg-white p-5 rounded-2xl shadow-lg max-w-xl">
                      <div className="space-y-2">
                        <p className="font-bold text-main lg:text-base text-sm ">
                          비밀번호 변경하기
                        </p>
                      </div>
                      <div>
                        <div className={`whitespace-pre-wrap`}>
                          {changePasswordText}
                        </div>
                      </div>
                      <div className="w-full left-0 bottom-16 fixed sm:static text-center space-x-5 sm:pt-8 py-4 px-4 sm:px-0 bg-white sm:bg-transparent">
                        <Button
                          canClick={true}
                          actionText={`이메일 보내기`}
                          className={`bg-main w-full sm:w-40 py-1 text-white rounded-md`}
                          loading={sendChangePasswordEmailLoading}
                          onClick={sendChangePasswordEmailHandler}
                        />
                      </div>
                    </div>
                  )}
                </div>
                <Footer></Footer>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
