import React, { useEffect } from "react";
import { createBrowserHistory } from "history";
import { useParams } from "react-router";
import {
  ListBannerOptionInput,
  ProductType,
} from "../__generated__/globalTypes";
import { UpBannerOptionForm4_1 } from "../components/up-banner-option-form-4-1";
import { useListBannerOption } from "../hooks/useListBannerOption";
import { listBannerOption } from "../__generated__/listBannerOption";
import { UpBannerOptionForm2_1 } from "../components/up-banner-option-form-2-1";

export const SubAdminUpBannerMagazine: React.FC = () => {
  const history = createBrowserHistory();
  const params = useParams();

  const onListCompleted = (data: listBannerOption) => {};
  const [listBannerOption, { data, loading, refetch }] =
    useListBannerOption(onListCompleted);
  const listBannerOptionHandler = (bannerId: number) => {
    const listBannerOptionInput: ListBannerOptionInput = {
      bannerId,
    };
    listBannerOption({ variables: { input: listBannerOptionInput } });
  };

  useEffect(() => {
    if (params.bannerId) {
      listBannerOptionHandler(+params.bannerId);
    }
  }, []);

  return (
    <>
      <div className="bg-white p-5 rounded-2xl shadow-lg">
        <div className="p-5 space-y-5 lg:space-y-10">
          <div className="space-y-3">
            <div className="w-full grid grid-cols-1 gap-3">
              {loading && <div>정보를 불러오는 중입니다</div>}
              {!loading &&
                data?.listBannerOption.bannerOptions &&
                data.listBannerOption.bannerOptions.length > 0 && (
                  <>
                    <UpBannerOptionForm2_1
                      productType={ProductType.Magazine}
                      bannerOptionId={
                        data?.listBannerOption.bannerOptions
                          ? Number(data?.listBannerOption.bannerOptions[13].id)
                          : 0
                      }
                      link={`${data?.listBannerOption.bannerOptions[13].link}`}
                      bannerTitle={`매거진 1`}
                      resolution={`(700x350)`}
                      posterUrl={
                        data.listBannerOption.bannerOptions[10].product
                          ?.productViews &&
                        data?.listBannerOption.bannerOptions[10].product
                          ?.productViews.length > 0
                          ? data.listBannerOption.bannerOptions[10].product?.productViews?.filter(
                              (productView) => productView.is_rep
                            )[0].view?.url
                          : ``
                      }
                      productTitle={
                        data.listBannerOption.bannerOptions[13].product
                          ?.productInfo?.title
                      }
                      productId={
                        data.listBannerOption.bannerOptions[13].product?.id
                      }
                      instName={
                        data.listBannerOption.bannerOptions[13].product?.inst
                          .user?.name
                      }
                      refetch={refetch}
                    />
                    <UpBannerOptionForm2_1
                      productType={ProductType.Magazine}
                      bannerOptionId={
                        data?.listBannerOption.bannerOptions
                          ? Number(data?.listBannerOption.bannerOptions[14].id)
                          : 0
                      }
                      link={`${data?.listBannerOption.bannerOptions[14].link}`}
                      bannerTitle={`매거진 2`}
                      resolution={`(700x350)`}
                      posterUrl={
                        data.listBannerOption.bannerOptions[10].product
                          ?.productViews &&
                        data?.listBannerOption.bannerOptions[10].product
                          ?.productViews.length > 0
                          ? data.listBannerOption.bannerOptions[10].product?.productViews?.filter(
                              (productView) => productView.is_rep
                            )[0].view?.url
                          : ``
                      }
                      productTitle={
                        data.listBannerOption.bannerOptions[14].product
                          ?.productInfo?.title
                      }
                      productId={
                        data.listBannerOption.bannerOptions[14].product?.id
                      }
                      instName={
                        data.listBannerOption.bannerOptions[14].product?.inst
                          .user?.name
                      }
                      refetch={refetch}
                    />
                    <UpBannerOptionForm2_1
                      productType={null}
                      bannerOptionId={
                        data?.listBannerOption.bannerOptions
                          ? Number(data?.listBannerOption.bannerOptions[15].id)
                          : 0
                      }
                      link={`${data?.listBannerOption.bannerOptions[15].link}`}
                      bannerTitle={`매거진 3`}
                      resolution={`(1400x700)`}
                      posterUrl={
                        data?.listBannerOption.bannerOptions
                          ? data.listBannerOption.bannerOptions[15].view?.url
                          : ``
                      }
                      refetch={refetch}
                    />
                  </>
                )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
