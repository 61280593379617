import kit from "../../../../../assets/images/sample/Sample1/diy1.png";
import offline from "../../../../../assets/icon/off.svg";
import Menu from "@mui/material/Menu";
import delivery from "../../../../../assets/icon/delivery.svg";
import React, { useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import Modal from "@mui/material/Modal";
import moment from "moment";
import { SubUserModalRequestRefund } from "../../../../../sub-routes/sub-user-modal-request-refund";
import { useListReceiptMineOffline } from "../../../../../hooks/useListReceiptMineOffline";
import { OrderStatus } from "../../../../../__generated__/globalTypes";

export const OffLec = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [kitopen, setKitOpen] = useState(false);
  const [deliopen, setDeliOpen] = useState(false);
  const [cancelopen, setCancelOpen] = useState(false);
  const [orderStatus, setOrderStatus] = useState<OrderStatus>();
  const [kitUrl, setKitUrl] = useState<string | null | undefined>(``);
  const [kitDesc, setKitDesc] = useState<string | null | undefined>(``);
  const [receiptId, setReceiptId] = useState<number>(0);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [listReceiptMineOffline, { data, loading }] =
    useListReceiptMineOffline();

  useEffect(() => {
    listReceiptMineOffline({ variables: { input: { page: 1 } } });
  }, []);

  const open = Boolean(anchorEl);
  const MenuBody = {
    boxShadow: "0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)",
  };
  const MenuStyle = {
    fontSize: "0.875rem",
  };
  const MenuStyle2 = {
    fontSize: "0.875rem",
    color: "red",
  };

  const closeHandler = () => {
    setCancelOpen(false);
  };

  return (
    <div className="offline bg-white p-5 lg:p-10 rounded-lg">
      <p className="pl-2 text-xl font-bold text-main leading-normal pb-3">
        <span className="align-middle">신청 오프라인 강의</span>
        <img
          src={offline}
          alt="오프라인"
          className="hidden sm:inline-block w-10 ml-3"
        />
      </p>
      {!loading &&
        data?.listReceiptMineOffline.receipts &&
        data?.listReceiptMineOffline.receipts.length > 0 && (
          <div className="flex flex-wrap gap-5">
            {data?.listReceiptMineOffline.receipts &&
              data.listReceiptMineOffline.receipts.map((receipt) => (
                <div
                  key={`Offline-class-${receipt.id}`}
                  className="cards w-full min-w-[200px] max-w-[300px]  lg:w-[300px] lg:max-w-none p-4 inline-block bg-gray-200 rounded-md"
                >
                  <div className="MypageRatio relative">
                    {receipt.product?.productViews
                      ?.filter((productView) => productView.is_rep)
                      .map((productView) => (
                        <img
                          key={productView.id}
                          src={
                            productView.view?.url ? productView.view.url : `#`
                          }
                          alt={receipt.pgOption?.name + "의 썸네일 그림"}
                          className="absolute left-0 top-0 w-full h-full"
                        />
                      ))}
                    <div
                      aria-controls={`Menu${receipt.id}`}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      onClick={handleClick}
                      className="inline-flex flex-wrap content-center justify-center absolute right-0 top-0 w-8 h-8 rounded-full bg-opacity-30 cursor-pointer hover:bg-opacity-60"
                    >
                      <i className="fas fa-ellipsis-v text-xl"></i>
                    </div>
                    <Menu
                      id={`Menu${receipt.id}`}
                      anchorEl={anchorEl}
                      open={
                        anchorEl?.getAttribute("aria-controls") ===
                        `Menu${receipt.id}`
                      }
                      onClose={handleClose}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                      style={MenuBody}
                    >
                      {receipt.product?.productKit?.desc && (
                        <MenuItem
                          onClick={() => {
                            handleClose();
                            setKitOpen(true);
                            setKitUrl(
                              receipt.product?.productKit?.view?.url
                                ? receipt.product?.productKit.view.url
                                : ``
                            );
                            setKitDesc(receipt.product?.productKit?.desc);
                          }}
                          style={MenuStyle}
                        >
                          키트정보
                        </MenuItem>
                      )}

                      <MenuItem
                        onClick={() => {
                          handleClose();
                          setDeliOpen(true);
                          setOrderStatus(receipt.orderStatus);
                        }}
                        style={MenuStyle}
                      >
                        배송정보
                      </MenuItem>
                      {receipt.createdAt &&
                        moment().diff(moment(receipt.createdAt)) > 7 && (
                          <MenuItem
                            onClick={() => {
                              handleClose();
                              if (receipt.pgOption?.pay_method === `vbank`) {
                                alert(
                                  "가상계좌로 결제한 주문은 환불이 불가합니다."
                                );
                              } else {
                                setCancelOpen(true);
                                setReceiptId(receipt.id);
                              }
                            }}
                            style={MenuStyle2}
                          >
                            강의취소하기
                          </MenuItem>
                        )}
                    </Menu>
                  </div>
                  <p className="font-bold text-xs flex flex-wrap justify-between pt-2">
                    {receipt.product?.inst.atelier?.name && (
                      <span>{receipt.product.inst.atelier?.name}</span>
                    )}
                  </p>
                  <p className="py-2 text-sm leading-tight">
                    {receipt.pgOption?.name}
                  </p>
                </div>
              ))}
            <Modal
              open={deliopen}
              onClose={() => {
                setDeliOpen(false);
              }}
            >
              <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 p-10 bg-white">
                <img src={delivery} alt="배송그림" className="w-[200px]" />
                <p className="text-base text-center py-1">현재 키트는</p>
                <p className="font-bold text-2xl text-center py-1">
                  {orderStatus === OrderStatus.Pending && `배송 전`}
                  {orderStatus === OrderStatus.HandOut && `배송 중`}
                  {orderStatus === OrderStatus.Delivered && `배송 완료`}
                </p>
                <p className="text-base text-center py-1">상태입니다.</p>
              </div>
            </Modal>
            <Modal
              open={kitopen}
              onClose={() => {
                setKitOpen(false);
              }}
            >
              <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 p-10 bg-white">
                {kitUrl && (
                  <img src={kitUrl} alt="키트그림" className="w-[400px]" />
                )}
                <div className={`whitespace-pre-wrap`}>{kitDesc}</div>
              </div>
            </Modal>

            <Modal
              open={cancelopen}
              onClose={() => {
                setCancelOpen(false);
              }}
            >
              <div>
                <SubUserModalRequestRefund
                  receiptId={receiptId}
                  closeHandler={closeHandler}
                />
              </div>
            </Modal>
          </div>
        )}
      {!loading &&
        data?.listReceiptMineOffline.receipts?.length !== undefined &&
        data.listReceiptMineOffline.receipts.length === 0 && (
          <div className={`text-center`}>신청한 강의가 없습니다</div>
        )}
      {loading && <div className={`text-center`}>강의를 불러오는 중입니다</div>}
    </div>
  );
};
