import { ApolloError, gql, useMutation } from "@apollo/client";
import React from "react";
import {
  delPromotionProduct,
  delPromotionProductVariables,
} from "../__generated__/delPromotionProduct";

const DEL_PROMOTION_PRODUCT_MUTATION = gql`
  mutation delPromotionProduct($input: DelPromotionProductInput!) {
    delPromotionProduct(input: $input) {
      ok
      err
    }
  }
`;

export const useDelPromotionProduct = (
  onCompleted?: (data: delPromotionProduct) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<delPromotionProduct, delPromotionProductVariables>(
    DEL_PROMOTION_PRODUCT_MUTATION,
    {
      onCompleted,
      onError,
    }
  );
};
