import { useState } from "react";
import { Button } from "../../../../components/button";
import { useMe } from "../../../../hooks/useMe";
import { useSendChangePasswordEmail } from "../../../../hooks/useSendChangePasswordEmail";
import {
  SendChangePasswordEmailInput,
  UpUserInput,
} from "../../../../__generated__/globalTypes";
import { sendChangePasswordEmail } from "../../../../__generated__/sendChangePasswordEmail";
export const changePasswordText = `
아래 버튼을 클릭하면 비밀번호를 변경할 수 있는 링크가 가입된 이메일로 전송됩니다
해당 링크를 통해 비밀번호를 변경해주시기 바랍니다
`;
export const Edit_Password_ad = () => {
  const {data} = useMe()
  const sendChangePasswordEmailHandler = () => {
    if (!sendChangePasswordEmailLoading && data?.me.email) {
      const sendChangePasswordEmailInput: SendChangePasswordEmailInput = {
        email: data.me.email,
      };
      sendChangePasswordEmail({
        variables: { input: sendChangePasswordEmailInput },
      });
    }
  };
  const onSendCompleted = (data: sendChangePasswordEmail) => {
    const {
      sendChangePasswordEmail: { ok, err },
    } = data;
    if (ok) {
      alert(`메일을 보냈습니다`);
    } else {
      alert(err);
    }
  };
  const [
    sendChangePasswordEmail,
    {
      data: sendChangePasswordEmailData,
      loading: sendChangePasswordEmailLoading,
    },
  ] = useSendChangePasswordEmail(onSendCompleted);
 
  return (
    <div className="bg-white p-5 rounded-2xl shadow-lg max-w-xl">
                      <div className="space-y-2">
                        <p className="font-bold text-main lg:text-base text-sm ">
                          비밀번호 변경하기
                        </p>
                      </div>
                      <div>
                        <div className={`whitespace-pre-wrap`}>
                          {changePasswordText}
                        </div>
                      </div>
                      <div className="w-full left-0 bottom-16 fixed sm:static text-center space-x-5 sm:pt-8 py-4 px-4 sm:px-0 bg-white sm:bg-transparent">
                        <Button
                          canClick={true}
                          actionText={`이메일 보내기`}
                          className={`bg-main w-full sm:w-40 py-1 text-white rounded-md`}
                          loading={sendChangePasswordEmailLoading}
                          onClick={sendChangePasswordEmailHandler}
                        />
                      </div>
                    </div>
  );
};
