import { ApolloError, gql, useMutation } from "@apollo/client";
import React from "react";
import { UpMagazineInput } from "../__generated__/globalTypes";
import { upMagazine, upMagazineVariables } from "../__generated__/upMagazine";

export interface IUpMagazineFormProps extends UpMagazineInput {}

const UP_MAGAZINE_MUTATION = gql`
  mutation upMagazine($input: UpMagazineInput!) {
    upMagazine(input: $input) {
      ok
      err
    }
  }
`;

export const useUpMagazine = (
  onCompleted?: (data: upMagazine) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<upMagazine, upMagazineVariables>(UP_MAGAZINE_MUTATION, {
    onCompleted,
    onError,
  });
};
