import { ApolloError, gql, useMutation } from "@apollo/client";
import React from "react";
import { UpConsultInput } from "../__generated__/globalTypes";
import { upConsult, upConsultVariables } from "../__generated__/upConsult";

export interface IUpConsultFormProps extends UpConsultInput {}

const UP_CONSULT_MUTATION = gql`
  mutation upConsult($input: UpConsultInput!) {
    upConsult(input: $input) {
      ok
      err
    }
  }
`;

export const useUpConsult = (
  onCompleted?: (data: upConsult) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<upConsult, upConsultVariables>(UP_CONSULT_MUTATION, {
    onCompleted,
    onError,
  });
};
