import React from "react";
import gql from "graphql-tag";
import { crUser, crUserVariables } from "../__generated__/crUser";
import { ApolloError, useMutation } from "@apollo/client";
import { CrUserInput } from "../__generated__/globalTypes";

export interface ICrUserFormProps extends CrUserInput {
  confirmPassword: string;
}

const CR_USER_MUTATION = gql`
  mutation crUser($input: CrUserInput!) {
    crUser(input: $input) {
      ok
      err
    }
  }
`;

export const useCrUser = (
  onCompleted?: (data: crUser) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<crUser, crUserVariables>(CR_USER_MUTATION, {
    onCompleted,
    onError,
  });
};
