import React from "react";
import { gql, useMutation } from "@apollo/client";
import {
  upProductReview,
  upProductReviewVariables,
} from "../__generated__/upProductReview";
import { UpProductReviewInput } from "../__generated__/globalTypes";

export interface IUpProductReviewFormProps extends UpProductReviewInput {}

const UP_PRODUCT_REVIEW_MUTATION = gql`
  mutation upProductReview($input: UpProductReviewInput!) {
    upProductReview(input: $input) {
      ok
      err
    }
  }
`;

export const useUpProductReview = (
  onCompleted?: (data: upProductReview) => void
) => {
  return useMutation<upProductReview, upProductReviewVariables>(
    UP_PRODUCT_REVIEW_MUTATION,
    {
      onCompleted,
      fetchPolicy: `network-only`,
    }
  );
};
