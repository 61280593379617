import React from "react";
import { gql, useLazyQuery } from "@apollo/client";
import {
  listClassActive,
  listClassActiveVariables,
} from "../__generated__/listClassActive";

const LIST_CLASS_ACTIVE_QUERY = gql`
  query listClassActive($input: ListClassActiveInput!) {
    listClassActive(input: $input) {
      ok
      err
      totalPages
      totalResults
      row
      classes {
        id
        productInfo {
          scheduledAt
          title
          price
        }
        inst {
          id
          atelier {
            name
          }
          user {
            name
          }
        }
        createdAt
        productStatus
        productType
      }
    }
  }
`;

export const useListClassActive = (
  onCompleted?: (data: listClassActive) => void
) => {
  return useLazyQuery<listClassActive, listClassActiveVariables>(
    LIST_CLASS_ACTIVE_QUERY,
    {
      onCompleted,
      fetchPolicy: `network-only`,
    }
  );
};
