import { Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { registCorp } from "../../../../../__generated__/registCorp";
import {
  BillOptionStatus,
  CheckCERTIsValidInput,
  GetBaroBillURLCERTInput,
  GetBillOptionInput,
  RegistCorpInput,
} from "../../../../../__generated__/globalTypes";
import { ICrBillFormProps, useCrBill } from "../../../../../hooks/useCrBill";
import {
  IRegistCorpFormProps,
  useRegistCorp,
} from "../../../../../hooks/useRegistCorp";
import { useMe } from "../../../../../hooks/useMe";
import { Button } from "../../../../../components/button";
import { errTexts, valueState } from "../../../../../constants/functions";
import { useGetBillOption } from "../../../../../hooks/useGetBillOption";
import { useGetBaroBillURLCERT } from "../../../../../hooks/useGetBaroBillURLCERT";
import { getBaroBillURLCERT } from "../../../../../__generated__/getBaroBillURLCERT";
import { useCheckCERTIsValid } from "../../../../../hooks/useCheckCERTIsValid";
import { checkCERTIsValid } from "../../../../../__generated__/checkCERTIsValid";

export const TaxInfo = () => {
  const { data: me } = useMe();
  const [errText, setErrText] = useState<any>();
  const errTextHandler = (err: any) => {
    setErrText(errTexts(err));
  };
  const {
    register,
    getValues,
    setValue,
    handleSubmit,
    watch,
    formState: { errors, isValid },
  } = useForm<IRegistCorpFormProps>({
    mode: `all`,
  });
  const onComplete = (data: registCorp) => {
    const {
      registCorp: { ok, err },
    } = data;
    if (ok) {
      alert("등록 요청이 완료되었습니다");
    } else {
      errTextHandler(err);
    }
  };
  const [registCorp, { loading }] = useRegistCorp(onComplete);
  const [getBillOption, { data: getBillOptionData }] = useGetBillOption();

  const onCheckCERTIsValid = (data: checkCERTIsValid) => {};
  const [checkCERTIsValid, { loading: checkCERTIsValidLoading }] =
    useCheckCERTIsValid(onCheckCERTIsValid);
  const checkCERTIsValidHandler = () => {
    if (!checkCERTIsValidLoading && me?.me.inst?.id) {
      const input: CheckCERTIsValidInput = {
        instIdToCheck: me.me.inst.id,
      };
      checkCERTIsValid({ variables: { input } });
    }
  };

  const onGetUrlComplete = (data: getBaroBillURLCERT) => {
    const {
      getBaroBillURLCERT: { ok, url },
    } = data;
    if (ok && url) {
      window.open(url);
      return;
    }
    alert("잠시후에 다시 시도해주세요");
  };
  const [getBaroBillURLCERT, { loading: getBaroBillURLCERTLoading }] =
    useGetBaroBillURLCERT(onGetUrlComplete);
  const getBaroBillURLCERTHandler = () => {
    if (!getBaroBillURLCERTLoading) {
      const input: GetBaroBillURLCERTInput = {
        instIdToGet: Number(me?.me.inst?.id),
      };
      getBaroBillURLCERT({
        variables: { input },
      });
    }
  };

  const onSubmit = () => {
    if (!loading) {
      const {
        CEOName,
        MemberName,
        ID,
        PWD,
        CorpName,
        CorpNum,
        BizType,
        BizClass,
        Addr1,
        Addr2,
        TEL,
        Email,
        instIdToRegist,
      } = getValues();
      const RegistCorpInput: RegistCorpInput = {
        CEOName,
        MemberName,
        ID,
        PWD,
        CorpName,
        CorpNum,
        BizClass,
        BizType,
        Addr1,
        Addr2,
        TEL,
        Email,
        instIdToRegist,
      };
      registCorp({ variables: { input: RegistCorpInput } });
    }
  };
  useEffect(() => {
    getBillOption({
      variables: { input: { InstIdToGet: Number(me?.me?.inst?.id) } },
    });

    setValue("CorpNum", String(me?.me.inst?.businessNumber));
    setValue("CorpName", String(me?.me.inst?.businessName));
    setValue("BizClass", String(me?.me.inst?.businessItem));
    setValue("BizType", String(me?.me.inst?.businessType));
    setValue("Email", String(me?.me.email));
    setValue("TEL", String(me?.me.phone));
    setValue("Addr1", String(me?.me.address));
    setValue("Addr2", String(me?.me.subAddress));
    setValue("Addr2", String(me?.me.subAddress));
    setValue("instIdToRegist", Number(me?.me.inst?.id));
  }, []);

  return (
    <div className="w-full h-full ">
      <div className="bg-white p-4 rounded-2xl ">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="max-w-xl space-y-10">
            <div className="space-y-2">
              <p className="font-bold text-main text-sm lg:text-base">대표자</p>
              <input
                {...register(`CEOName`, {
                  required: "대표자 성함은 필수 입니다.",
                })}
                disabled={Boolean(getBillOptionData)}
                defaultValue={
                  getBillOptionData
                    ? getBillOptionData?.getBillOption.billOption?.CEOName
                    : ""
                }
                type="text"
                className="border border-grays rounded-xl py-1 px-2 w-full text-sm lg:text-base"
              />
              {errors.CEOName?.message && (
                <p className="pt-2 text-xs text-reds pl-2">
                  {errors.CEOName.message}
                </p>
              )}
            </div>
            <div className="space-y-2">
              <p className="font-bold text-main text-sm lg:text-base">담당자</p>
              <input
                {...register(`MemberName`, {
                  required: "담당자 이름은 필수 입니다.",
                })}
                disabled={Boolean(getBillOptionData)}
                type="text"
                defaultValue={
                  getBillOptionData
                    ? getBillOptionData.getBillOption.billOption?.MemberName
                    : ""
                }
                className="border border-grays rounded-xl py-1 px-2 w-full text-sm lg:text-base"
              />
              {errors.MemberName?.message && (
                <p className="pt-2 text-xs text-reds pl-2">
                  {errors.MemberName.message}
                </p>
              )}
            </div>
            <div className="space-y-2">
              <p className="font-bold text-main text-sm lg:text-base">
                바로빌 아이디
              </p>
              <input
                type="text"
                {...register(`ID`, {
                  required: "아이디는 필수 입니다.",
                })}
                disabled={Boolean(getBillOptionData)}
                defaultValue={
                  getBillOptionData
                    ? getBillOptionData.getBillOption.billOption?.ID
                    : ""
                }
                className="border border-grays rounded-xl py-1 px-2 w-full text-sm lg:text-base"
              />
              {errors.ID?.message && (
                <p className="pt-2 text-xs text-reds pl-2">
                  {errors.ID.message}
                </p>
              )}
            </div>
            {!getBillOptionData && (
              <>
                <div className="space-y-2 relative">
                  <p className="font-bold text-main text-sm lg:text-base">
                    바로빌 패스워드
                  </p>
                  <input
                    {...register(`PWD`, {
                      pattern: {
                        value:
                          /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{6,20}$/,
                        message: "영문/숫자/특수문자 혼합 6~20자 입니다.",
                      },
                    })}
                    type="password"
                    placeholder="영문/숫자/특수문자 혼합. 6~20자"
                    className="border border-grays rounded-xl py-1 px-2 w-full text-sm lg:text-base"
                  />
                  {errors.PWD?.message && (
                    <p className="pt-2 text-xs text-reds pl-2">
                      {errors.PWD.message}
                    </p>
                  )}
                </div>
                <div className="space-y-2 relative">
                  <p className="font-bold text-main text-sm lg:text-base">
                    패스워드 확인
                  </p>
                  <input
                    {...register("confirmPassword", {
                      validate: (value) =>
                        value === watch("PWD") ||
                        "비밀번호가 일치하지 않습니다",
                    })}
                    type="password"
                    placeholder="영문/숫자/특수문자 혼합. 6~20자"
                    className="border border-grays rounded-xl py-1 px-2 w-full text-sm lg:text-base"
                  />
                  {errors.confirmPassword?.message && (
                    <p className="pt-2 text-xs text-reds pl-2">
                      {errors.confirmPassword.message}
                    </p>
                  )}
                </div>
              </>
            )}
            {getBillOptionData && <p></p>}
            <div className="py-4 left-0 bottom-16 w-full fixed sm:static text-center space-x-5 sm:pt-8 bg-white sm:bg-transparent">
              <button
                onClick={() => {
                  window.location.reload();
                }}
                className="w-2/5 sm:w-40 py-1 border border-grays rounded-md hover:opacity-60 text-sm lg:text-base"
              >
                취소
              </button>
              <Button
                canClick={isValid}
                actionText={`등록하기`}
                className={`bg-main w-2/5 sm:w-40 py-1 text-white rounded-md hover:opacity-60 text-sm lg:text-base `}
                loading={loading}
                color={`bg-main`}
              />
              {errText && <p className="py-2 text-reds text-sm">{errText}</p>}
            </div>
          </div>
        </form>
        <div className="px-2 py-10 space-y-5">
          <div>
            <p className="font-bold text-main text-sm lg:text-base">
              공인인증서 상태
              {me?.me.inst?.billOption?.status && (
                <span
                  className={`
                ml-10 ${
                  me?.me.inst?.billOption?.status === BillOptionStatus.Valid &&
                  "text-green-400"
                }
                ${
                  me?.me.inst?.billOption?.status ===
                    BillOptionStatus.InValid && "text-black"
                }
                ${
                  me?.me.inst?.billOption?.status ===
                    BillOptionStatus.Expired && "text-reds"
                }
                `}
                >
                  {valueState(me.me.inst.billOption.status)}
                </span>
              )}
              {!me?.me.inst?.billOption?.status && (
                <span
                  className={`
              ml-10 text-black
              
              `}
                >
                  {valueState(BillOptionStatus.InValid)}
                </span>
              )}
            </p>
          </div>
          <p>
            <button
              className="bg-main w-28 py-1 text-white rounded-md hover:opacity-60 text-sm lg:text-base "
              onClick={getBaroBillURLCERTHandler}
            >
              등록하러 가기
            </button>
            <button
              className="ml-3 text-sm hover:underline text-gray-500"
              onClick={checkCERTIsValidHandler}
            >
              - 새로고침 -
            </button>
          </p>
          <p className="text-sm text-gray-500">
            ※인증서를 등록 후 새로고침을 눌려주시면 바로 인증이 가능합니다.
          </p>
        </div>
      </div>
    </div>
  );
};
