import { ApolloError, gql, useMutation } from "@apollo/client";
import { login, loginVariables } from "../__generated__/login";

const LOGIN_MUTATION = gql`
  mutation login($loginInput: LoginInput!) {
    login(input: $loginInput) {
      ok
      token
      err
    }
  }
`;

export const useSignIn = (
  onCompleted?: (data: login) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<login, loginVariables>(LOGIN_MUTATION, {
    onCompleted,
    onError,
  });
};
