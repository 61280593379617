import React from "react";
import gql from "graphql-tag";
import { ApolloError, useMutation } from "@apollo/client";
import {
  approveCurator,
  approveCuratorVariables,
} from "../__generated__/approveCurator";
import {
  resignCurator,
  resignCuratorVariables,
} from "../__generated__/resignCurator";

const RESIGN_CURATOR_MUTATION = gql`
  mutation resignCurator($input: ResignCuratorInput!) {
    resignCurator(input: $input) {
      ok
      err
    }
  }
`;

export const useResignCurator = (
  onCompleted?: (data: resignCurator) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<resignCurator, resignCuratorVariables>(
    RESIGN_CURATOR_MUTATION,
    {
      onCompleted,
      onError,
    }
  );
};
