import React from "react";
import { gql, useLazyQuery } from "@apollo/client";
import {
  listUserGeneral,
  listUserGeneralVariables,
} from "../__generated__/listUserGeneral";

const LIST_USER_GENERAL_QUERY = gql`
  query listUserGeneral($input: ListUserGeneralInput!) {
    listUserGeneral(input: $input) {
      ok
      err
      totalResults
      totalPages
      row
      users {
        id
        uuid
        name
        email
        role
        phone
        verified
        address
        subAddress
      }
    }
  }
`;

export const useListUserGeneral = (
  onCompleted?: (data: listUserGeneral) => void
) => {
  return useLazyQuery<listUserGeneral, listUserGeneralVariables>(
    LIST_USER_GENERAL_QUERY,
    {
      onCompleted,
      fetchPolicy: "network-only",
    }
  );
};
