import { ApolloError, gql, useLazyQuery } from "@apollo/client";
import React from "react";
import { getConsult, getConsultVariables } from "../__generated__/getConsult";

const GET_CONSULT_MUTATION = gql`
  query getConsult($input: GetConsultInput!) {
    getConsult(input: $input) {
      ok
      err
      consult {
        uuid
        title
        note
        status
        type
        createdAt
        updatedAt
        product {
          productInfo {
            title
          }
        }
        author {
          id
          name
        }
        curator {
          id
          name
        }
        replies {
          note
          author {
            id
            name
          }
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const useGetConsult = (
  onCompleted?: (data: getConsult) => void,
  onError?: (error: ApolloError) => void
) => {
  return useLazyQuery<getConsult, getConsultVariables>(GET_CONSULT_MUTATION, {
    onCompleted,
    onError,
    fetchPolicy: "network-only",
  });
};
