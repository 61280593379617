import React from "react";
import gql from "graphql-tag";
import { ApolloError, useMutation } from "@apollo/client";
import { crUserInst, crUserInstVariables } from "../__generated__/crUserInst";
import { CrUserInstInput } from "../__generated__/globalTypes";

export interface ICrUserInstFormProps extends CrUserInstInput {
  confirmPassword: string;
}

const CR_USER_INST_MUTATION = gql`
  mutation crUserInst($input: CrUserInstInput!) {
    crUserInst(input: $input) {
      ok
      err
    }
  }
`;

export const useCrUserInst = (
  onCompleted?: (data: crUserInst) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<crUserInst, crUserInstVariables>(CR_USER_INST_MUTATION, {
    onCompleted,
    onError,
  });
};
