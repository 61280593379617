import React from "react";
import { gql, useLazyQuery } from "@apollo/client";
import { CLASS_LIST_WIDGET_FRAGMENT } from "../fragments";
import {
  listProductSub,
  listProductSubVariables,
} from "../__generated__/listProductSub";

const LIST_PRODUCT_SUB_QUERY = gql`
  query listProductSub($input: ListProductSubInput!) {
    listProductSub(input: $input) {
      ok
      err
      totalResults
      totalPages
      products {
        ...ClassListWidgetParts
      }
    }
  }
  ${CLASS_LIST_WIDGET_FRAGMENT}
`;

export const useListProductSub = (
  onCompleted?: (data: listProductSub) => void
) => {
  return useLazyQuery<listProductSub, listProductSubVariables>(
    LIST_PRODUCT_SUB_QUERY,
    {
      onCompleted,
      fetchPolicy: "network-only",
    }
  );
};
