import { createBrowserHistory } from "history";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import searchImg from "../assets/icon/search.png";
import { pageIndicator } from "../apollo";
import { TextButton } from "../components/button";
import { ITabLink, ComponentTabLink } from "../components/component-tab";
import { PaginationComponent } from "../components/pagination";
import { TableError } from "../components/table-error";
import { useDelDoor } from "../hooks/useDelDoor";
import { useListDoorRefund } from "../hooks/useListDoorRefund";
import { AdminTop } from "../layout/adminTop";
import { AsideAdminComponent } from "../layout/aside-admin";
import { Footer } from "../layout/footer";
import { delDoor } from "../__generated__/delDoor";
import {
  ListDoorRefundInput,
  DelDoorInput,
  ApproveRefundInput,
  ResignRefundInput,
  DoorStatus,
} from "../__generated__/globalTypes";
import { ISearchFormProps } from "./admin-list-magazine";
import { useApproveRefund } from "../hooks/useApproveRefund";
import { approveRefund } from "../__generated__/approveRefund";
import { useResignRefund } from "../hooks/useResignRefund";
import { resignRefund } from "../__generated__/resignRefund";

export const AdminListDoorRefund = () => {
  const history = createBrowserHistory();
  const location = useLocation();
  const [page, setPage] = useState<number>(1);
  const [search, setSearch] = useState<string>("");

  const tabList: ITabLink[] = [
    { text: "목록", to: "/admin/user" },
    { text: "1:1문의", to: "/admin/user/door" },
    { text: "환불문의", to: "/admin/user/refund", selected: true },
  ];

  const pageHandler = (page: number) => {
    setPage(page);
    history.push({
      pathname: "/admin/user/refund",
      search: `?page=${page}${search ? `&search=${search}` : ""}`,
    });
    listDoorRefundHandler(page, search);
  };

  const { register, getValues, handleSubmit } = useForm<ISearchFormProps>({
    mode: "all",
  });

  const onSearch = async () => {
    const { search } = getValues();
    setSearch(search);
    setPage(1);
    history.push({
      pathname: "/admin/user/door",
      search: `${search ? `?search=${search}` : ""}`,
    });
    listDoorRefundHandler(page, search);
  };

  useEffect(() => {
    pageIndicator("user");
  }, []);

  const [listDoorRefund, { data, loading, refetch }] = useListDoorRefund();
  const listDoorRefundHandler = (
    page: number | null,
    search: string | null
  ) => {
    const input: ListDoorRefundInput = {
      ...(page && { page: +page }),
      ...(search && { search: decodeURI(search) }),
    };
    listDoorRefund({ variables: { input } });
  };

  const onDelete = async (data: delDoor) => {
    const {
      delDoor: { ok, err },
    } = data;
    if (ok) {
      alert("문의 정보를 삭제하였습니다");
      refetch();
    }
    if (!ok) {
      alert(err);
    }
  };
  const [delDoor, { loading: delDoorLoading }] = useDelDoor(onDelete);
  const delDoorHandler = (doorIdToDel: number) => {
    if (window.confirm("정말로 삭제하시겠습니까?")) {
      if (!delDoorLoading) {
        const input: DelDoorInput = {
          doorIdToDel,
        };
        delDoor({ variables: { input } });
      }
    }
  };

  const onApprove = async (data: approveRefund) => {
    const {
      approveRefund: { ok, err },
    } = data;
    if (ok) {
      alert("환불에 승인하셨습니다");
      refetch();
    }
    if (!ok) {
      alert(err);
    }
  };
  const [approveRefund, { loading: approveRefundLoading }] =
    useApproveRefund(onApprove);
  const approveRefundHandler = (refundIdToApprove: number) => {
    if (window.confirm("환불에 승인하시겠습니까?")) {
      if (!approveRefundLoading) {
        const input: ApproveRefundInput = {
          refundIdToApprove,
        };
        approveRefund({ variables: { input } });
      }
    }
  };

  const onResign = async (data: resignRefund) => {
    const {
      resignRefund: { ok, err },
    } = data;
    if (ok) {
      alert("반려하였습니다");
      refetch();
    }
    if (!ok) {
      alert(err);
    }
  };
  const [resignRefund, { loading: resignRefundLoading }] =
    useResignRefund(onResign);
  const resignRefundHandler = (refundIdToResign: number) => {
    if (window.confirm("반려하시겠습니까?")) {
      if (!resignRefundLoading) {
        const input: ResignRefundInput = {
          refundIdToResign,
        };
        resignRefund({ variables: { input } });
      }
    }
  };

  useEffect(() => {
    setPage(
      location.search.split("page=").length > 1
        ? +location.search.split("page=")[1].split("&")[0]
        : 1
    );
    setSearch(
      location.search.split("search=").length > 1
        ? location.search.split("search=")[1].split("&")[0]
        : ""
    );
    listDoorRefundHandler(page, search);
  }, []);

  return (
    <div className="bg-main bg-opacity-50 w-full h-full min-h-screen">
      <div className="w-[1440px] mx-auto h-full min-h-screen  bg-white">
        <AdminTop title={"top"} children={"관리자모드"} />
        <main id="main">
          <div className="grid grid-cols-[300px_1fr]">
            <AsideAdminComponent />
            <div className="mypages">
              <div className="lectureWrap">
                <ComponentTabLink tabs={tabList} />
                <div className=" bg-gray-100 h-fix-content relative">
                  <div className={`w-full h-full px-10 py-12`}>
                    <div className="text-right pb-8">
                      <form onSubmit={handleSubmit(onSearch)}>
                        <p className="inline-block max-w-md w-full text-right relative">
                          <input
                            {...register(`search`)}
                            type="text"
                            className="w-full py-1 px-4 bg-white rounded-full"
                            placeholder="클래스 명으로 검색"
                          />
                          <button className="absolute right-3 top-1">
                            <img src={searchImg} alt="찾기" className="w-5" />
                          </button>
                        </p>
                      </form>
                    </div>
                    {/* 여기 */}
                    <div className="bg-white p-5 rounded-2xl shadow-lg">
                      <p className="text-main font-bold pb-3">문의 목록</p>
                      <table className="w-full">
                        <colgroup>
                          <col className="w-2/12" />
                          <col className="w-4/12" />
                          <col className="w-1/12" />
                          <col className="w-1/12" />
                          <col className="w-2/12" />
                          <col className="w-2/12" />
                        </colgroup>
                        <thead>
                          <tr className="bg-main  border-gray-300">
                            <th className="text-white ">번호</th>
                            <th className="text-white ">클래스</th>
                            <th className="text-white ">문의자</th>
                            <th className="text-white ">상태</th>
                            <th className="text-white ">연락처</th>
                            <th className="text-white ">기능</th>
                          </tr>
                        </thead>
                        <tbody>
                          {!loading &&
                            data?.listDoorRefund.doors &&
                            data.listDoorRefund.doors.map((door) => (
                              <tr
                                key={`place-${door?.id}`}
                                className="border-b-2 border-gray-300 h-4"
                              >
                                <td className="text-sm text-center py-1 px-1 h-full overflow-hidden">
                                  {door?.uuid && door.uuid}
                                </td>
                                <td className="text-sm text-center h-full relative">
                                  <Link
                                    to={`/admin/user/door/${door.id}`}
                                    className={`cursor-pointer hover:underline `}
                                  >
                                    {door.title}
                                  </Link>
                                </td>
                                <td className="text-sm text-center  relative">
                                  {door.author.name}
                                </td>
                                <td
                                  title={`${
                                    door.status === DoorStatus.Failed &&
                                    door.error
                                  }`}
                                  className={`text-sm text-center relative ${
                                    door.status === DoorStatus.Failed &&
                                    `text-red-400 cursor-help`
                                  }`}
                                >
                                  {door.status}
                                </td>
                                <td className="text-sm text-center py-1 px-1 h-full overflow-hidden">
                                  {door.author.phone}
                                </td>
                                <td className="text-sm text-center py-1 px-1 h-full w-1/6 overflow-hidden">
                                  <TextButton
                                    canClick={
                                      !approveRefundLoading &&
                                      (door.status === DoorStatus.New ||
                                        door.status === DoorStatus.Pending)
                                    }
                                    actionText={`승인`}
                                    onClick={() =>
                                      approveRefundHandler(door.id)
                                    }
                                  />
                                  {" / "}
                                  <TextButton
                                    canClick={
                                      !resignRefundLoading &&
                                      (door.status === DoorStatus.Failed ||
                                        door.status === DoorStatus.New)
                                    }
                                    actionText={`반려`}
                                    onClick={() => resignRefundHandler(door.id)}
                                  />
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                      {loading && (
                        <TableError errorMessage={`정보를 불러오는 중입니다`} />
                      )}
                      {!loading &&
                        data?.listDoorRefund.doors &&
                        data.listDoorRefund.doors.length < 1 && (
                          <TableError
                            errorMessage={`해당하는 문의 정보가 없습니다`}
                          />
                        )}
                    </div>
                    {!loading &&
                      data?.listDoorRefund.doors &&
                      data.listDoorRefund.doors.length > 0 && (
                        <PaginationComponent
                          page={page}
                          totalPages={Number(data.listDoorRefund.totalPages)}
                          totalResults={Number(
                            data.listDoorRefund.totalResults
                          )}
                          pageHandler={pageHandler}
                        />
                      )}
                  </div>
                  <Footer></Footer>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
