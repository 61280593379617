import React from "react";
import gql from "graphql-tag";
import { ApolloError, useMutation } from "@apollo/client";
import { UpProductIntroInput } from "../__generated__/globalTypes";
import {
  upProductIntro,
  upProductIntroVariables,
} from "../__generated__/upProductIntro";

export interface IUpProducIntroFormProps extends UpProductIntroInput {}

const UP_PRODUCT_INTRO_MUTATION = gql`
  mutation upProductIntro($input: UpProductIntroInput!) {
    upProductIntro(input: $input) {
      ok
      err
    }
  }
`;

export const useUpProductIntro = (
  onCompleted?: (data: upProductIntro) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<upProductIntro, upProductIntroVariables>(
    UP_PRODUCT_INTRO_MUTATION,
    {
      onCompleted,
      onError,
    }
  );
};
