import React from "react";
import gql from "graphql-tag";
import { ApolloError, useMutation } from "@apollo/client";
import {
  cancelRefund,
  cancelRefundVariables,
} from "../__generated__/cancelRefund";

const CANCEL_REFUND_MUTATION = gql`
  mutation cancelRefund($input: CancelRefundInput!) {
    cancelRefund(input: $input) {
      ok
      err
    }
  }
`;

export const useCancelRefund = (
  onCompleted?: (data: cancelRefund) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<cancelRefund, cancelRefundVariables>(
    CANCEL_REFUND_MUTATION,
    {
      onCompleted,
      onError,
    }
  );
};
