import offline from "../../../../../assets/icon/off.svg";
import React, { useEffect, useState } from "react";
import {
  DoorStatus,
  DoorType,
  OrderStatus,
} from "../../../../../__generated__/globalTypes";
import { comma } from "../../../../../apollo";
import moment from "moment";
import { useListBuyMineOffline } from "../../../../../hooks/useListBuyMineOffline";

export const OffBuy = () => {
  const [listBuyMineOffline, { data, loading }] = useListBuyMineOffline();
  const LinkToReceipt = (id: any) => {
    window.location.href = `/payment/completed/${id}`;
  };
  useEffect(() => {
    listBuyMineOffline({ variables: { input: { page: 1 } } });
  }, []);

  return (
    <div className="offline bg-white p-5 lg:p-10 rounded-lg">
      <p className="pl-2 text-xl font-bold text-main leading-normal pb-3">
        <span className="align-middle">구매한 오프라인 강의</span>
        <img src={offline} alt="오프라인" className="inline-block w-10 ml-3" />
      </p>
      <table className="w-full overflow-hidden">
        <thead>
          <tr className="bg-main  border-gray-300">
            <th className="text-white text-md py-1 sm:table-cell hidden">
              No.
            </th>
            <th className="text-white text-md py-1 ">강의명</th>
            <th className="text-white text-md py-1 min-w-[90px]">가격</th>
            <th className="text-white text-md py-1 min-w-[90px]">결제상태</th>
            <th className="text-white text-md py-1 sm:table-cell hidden">
              구매일
            </th>
          </tr>
        </thead>
        <tbody>
          {!loading &&
            data?.listBuyMineOffline.buys &&
            data.listBuyMineOffline.buys.map((buy, idx) => (
              <tr
                key={`buy-${buy.id}`}
                className="border-b-2 border-gray-300 hover:bg-gray-100 cursor-pointer"
                onClick={() => {
                  LinkToReceipt(buy.id);
                }}
              >
                <td className="test-sm text-center py-1 sm:table-cell hidden">
                  {idx + 1}
                </td>
                <td className="test-sm text-center py-1 ">
                  {buy.product?.productInfo?.title}
                </td>
                <td className={`text-sm text-center py-1`}>
                  {comma(Number(buy.product?.productInfo?.price))}원
                </td>
                <td className={`text-sm text-center py-1`}>
                  {buy.status === `paid` && "결제완료"}
                  {buy.status === `ready` && "결제대기"}
                  {buy.status === `refunded` && "환불완료"}
                  {buy.status !== `paid` &&
                    buy.status !== `ready` &&
                    buy.status !== `refunded` &&
                    "만료"}
                </td>
                <td className="test-sm text-center py-1 sm:table-cell hidden">
                  <p className="w-full ellipsis1 h-[20px] overflow-hidden">
                    {moment(buy.createdAt).format("yy년 M월 D일 HH:mm")}
                  </p>
                </td>
              </tr>
            ))}
          {!loading &&
            data?.listBuyMineOffline.buys?.length !== undefined &&
            data.listBuyMineOffline.buys.length === 0 && (
              <tr>
                <td colSpan={4} className={`text-center py-1`}>
                  신청한 강의가 없습니다
                </td>
              </tr>
            )}
          {loading && (
            <tr>
              <td colSpan={4} className={`text-center py-1`}>
                강의를 불러오는 중입니다
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};
