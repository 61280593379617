import { useState } from "react";
import { useMe } from "../../../../hooks/useMe";
export const Edit_Dataform_ct = () => {
  const { data } = useMe();

  return (
    <div className="w-full h-full ">
      <div className="bg-white p-5 rounded-2xl ">
        <div className=" max-w-xl space-y-10">
          <div className="space-y-2">
            <p className="font-bold text-main">이름</p>
            <input
              type="text"
              disabled
              defaultValue={data?.me.name}
              className="border border-grays rounded-xl py-1 px-2 w-full"
            />
          </div>
          {/* <div className="space-y-2">
            <p className="font-bold text-main">생년월일</p>
            <div className="grid grid-cols-3 gap-x-3">
              <input
                type="text"
                className="border border-grays rounded-xl py-1 px-2 w-full"
                placeholder="년(4자)"
                maxLength={4}
              />
              <select
                defaultValue={"null"}
                className="border border-grays py-1 px-2 w-full rounded-xl"
              >
                <option value="null">월</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
              </select>
              <input
                type="text"
                className="border border-grays rounded-xl py-1 px-2 w-full"
                maxLength={2}
              />
            </div>
          </div> */}
          <div className="space-y-2">
            <p className="font-bold text-main">휴대전화</p>
            <input
              type="text"
              defaultValue={data?.me.phone}
              className="border border-grays rounded-xl py-1 px-2 w-full"
              placeholder="-없이 11자리로 작성해주세요"
              maxLength={11}
            />
          </div>
          <div className="space-y-2">
            <p className="font-bold text-main">이메일</p>
            <div className="grid grid-cols-[2.5fr_1fr] gap-x-3">
              <input
                type="email"
                defaultValue={data?.me.email}
                className="border border-grays rounded-xl py-1 px-2 w-full"
              />
              <button className="hover:opacity-80 text-sm block w-full h-full border border-grays rounded-xl">
                인증번호발송
              </button>
            </div>
            <input
              type="number"
              className="border border-grays rounded-xl py-1 px-2 w-full"
              placeholder="인증번호를 입력해주세요"
              disabled
            />
          </div>
          <div className="space-y-2">
            <p className="font-bold text-main">주소</p>
            <div className="grid grid-cols-[2.5fr_1fr] gap-x-3">
              <input
                type="text"
                defaultValue={data?.me.address}
                className="border border-grays rounded-xl py-1 px-2 w-full"
                disabled
              />
              <button className="hover:opacity-80 text-sm block w-full h-full border border-grays rounded-xl">
                주소찾기
              </button>
            </div>
            <input
              type="text"
              defaultValue={data?.me.subAddress}
              className="border border-grays rounded-xl py-1 px-2 w-full"
            />
          </div>
          <div className="text-center space-x-5 pt-8">
            <button className="w-40 py-1 border border-grays rounded-md hover:opacity-60">
              취소
            </button>
            <button className="bg-main w-40 py-1 text-white rounded-md hover:opacity-60">
              수정하기
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
