import React from "react";
import gql from "graphql-tag";
import { ApolloError, useMutation } from "@apollo/client";
import {
  changeReceiptOrderPending,
  changeReceiptOrderPendingVariables,
} from "../__generated__/changeReceiptOrderPending";

const CHANGE_RECEIPT_ORDER_PENDING_MUTATION = gql`
  mutation changeReceiptOrderPending($input: ChangeReceiptOrderPendingInput!) {
    changeReceiptOrderPending(input: $input) {
      ok
      err
    }
  }
`;

export const useChangeReceiptOrderPending = (
  onCompleted?: (data: changeReceiptOrderPending) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<
    changeReceiptOrderPending,
    changeReceiptOrderPendingVariables
  >(CHANGE_RECEIPT_ORDER_PENDING_MUTATION, {
    onCompleted,
    onError,
  });
};
