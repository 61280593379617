import { useEffect, useState } from "react";
import usePagination from "@mui/material/usePagination";
import { styled } from "@mui/material/styles";
import { pageIndicator } from "../apollo";
import searchImg from "../assets/icon/search.png";
import { AsideAdminComponent } from "../layout/aside-admin";
import { Footer } from "../layout/footer";
import { Link, useLocation } from "react-router-dom";
import { createBrowserHistory } from "history";
import { DelDoorInput, ListDoorInput } from "../__generated__/globalTypes";
import { TableError } from "../components/table-error";
import { TextButton } from "../components/button";
import { useForm } from "react-hook-form";
import { AdminTop } from "../layout/adminTop";
import { ISearchFormProps } from "./admin-list-magazine";
import { PaginationComponent } from "../components/pagination";
import { useListDoor } from "../hooks/useListDoor";
import { useDelDoor } from "../hooks/useDelDoor";
import { delDoor } from "../__generated__/delDoor";
import { ComponentTabLink, ITabLink } from "../components/component-tab";

export const AdminListDoor = () => {
  const history = createBrowserHistory();
  const location = useLocation();
  const [page, setPage] = useState<number>(1);
  const [search, setSearch] = useState<string>("");

  const tabList: ITabLink[] = [
    { text: "목록", to: "/admin/user" },
    { text: "1:1문의", to: "/admin/user/door", selected: true },
    { text: "환불문의", to: "/admin/user/refund" },
  ];

  const pageHandler = (page: number) => {
    setPage(page);
    history.push({
      pathname: "/admin/door/door",
      search: `?page=${page}${search ? `&search=${search}` : ""}`,
    });
    listDoorHandler(page, search);
  };

  const { register, getValues, handleSubmit } = useForm<ISearchFormProps>({
    mode: "all",
  });

  const onSearch = async () => {
    const { search } = getValues();
    setSearch(search);
    setPage(1);
    history.push({
      pathname: "/admin/user/door",
      search: `${search ? `?search=${search}` : ""}`,
    });
    listDoorHandler(page, search);
  };

  useEffect(() => {
    pageIndicator("user");
  }, []);

  const [listDoor, { data, loading, refetch }] = useListDoor();
  const listDoorHandler = (page: number | null, search: string | null) => {
    const input: ListDoorInput = {
      ...(page && { page: +page }),
      ...(search && { search: decodeURI(search) }),
    };
    listDoor({ variables: { input } });
  };

  const onDelete = async (data: delDoor) => {
    const {
      delDoor: { ok, err },
    } = data;
    if (ok) {
      alert("문의 정보를 삭제하였습니다");
      refetch();
    }
    if (!ok) {
      alert(err);
    }
  };
  const [delDoor, { loading: delDoorLoading }] = useDelDoor(onDelete);
  const delDoorHandler = (doorIdToDel: number) => {
    if (!delDoorLoading) {
      const input: DelDoorInput = {
        doorIdToDel,
      };
      delDoor({ variables: { input } });
    }
  };

  useEffect(() => {
    setPage(
      location.search.split("page=").length > 1
        ? +location.search.split("page=")[1].split("&")[0]
        : 1
    );
    setSearch(
      location.search.split("search=").length > 1
        ? location.search.split("search=")[1].split("&")[0]
        : ""
    );
    listDoorHandler(page, search);
  }, []);

  return (
    <div className="bg-main bg-opacity-50 w-full h-full min-h-screen">
      <div className="w-[1440px] mx-auto h-full min-h-screen  bg-white">
        <AdminTop title={"top"} children={"관리자모드"} />
        <main id="main">
          <div className="grid grid-cols-[300px_1fr]">
            <AsideAdminComponent />
            <div className="mypages">
              <div className="lectureWrap">
                <ComponentTabLink tabs={tabList} />
                <div className=" bg-gray-100 h-fix-content relative">
                  <div className={`w-full h-full px-10 py-12`}>
                    <div className="text-right pb-8">
                      <form onSubmit={handleSubmit(onSearch)}>
                        <p className="inline-block max-w-md w-full text-right relative">
                          <input
                            {...register(`search`)}
                            type="text"
                            className="w-full py-1 px-4 bg-white rounded-full"
                            placeholder="문의 명으로 검색"
                          />
                          <button className="absolute right-3 top-1">
                            <img src={searchImg} alt="찾기" className="w-5" />
                          </button>
                        </p>
                      </form>
                    </div>
                    {/* 여기 */}
                    <div className="bg-white p-5 rounded-2xl shadow-lg">
                      <p className="text-main font-bold pb-3">문의 목록</p>
                      <table className="w-full">
                        <colgroup>
                          <col className="w-2/12" />
                          <col className="w-4/12" />
                          <col className="w-1/12" />
                          <col className="w-1/12" />
                          <col className="w-2/12" />
                          <col className="w-2/12" />
                        </colgroup>
                        <thead>
                          <tr className="bg-main  border-gray-300">
                            <th className="text-white ">번호</th>
                            <th className="text-white ">제목</th>
                            <th className="text-white ">문의자</th>
                            <th className="text-white ">상태</th>
                            <th className="text-white ">연락처</th>
                            <th className="text-white ">기능</th>
                          </tr>
                        </thead>
                        <tbody>
                          {!loading &&
                            data?.listDoor.doors &&
                            data.listDoor.doors.map((door) => (
                              <tr
                                key={`place-${door?.id}`}
                                className="border-b-2 border-gray-300 h-4"
                              >
                                <td className="text-sm text-center py-1 px-1 h-full overflow-hidden">
                                  {door?.uuid && door.uuid}
                                </td>
                                <td className="text-sm text-center h-full relative">
                                  <Link
                                    to={`/admin/user/door/${door.id}`}
                                    className={`cursor-pointer hover:underline `}
                                  >
                                    {door.title}
                                  </Link>
                                </td>
                                <td className="text-sm text-center  relative">
                                  {door.author.name}
                                </td>
                                <td className="text-sm text-center  relative">
                                  {door.status}
                                </td>
                                <td className="text-sm text-center py-1 px-1 h-full overflow-hidden">
                                  {door.author.phone}
                                </td>
                                <td className="text-sm text-center py-1 px-1 h-full w-1/6 overflow-hidden">
                                  <TextButton
                                    canClick={!delDoorLoading}
                                    actionText={`삭제`}
                                    onClick={() => delDoorHandler(door.id)}
                                  />
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                      {loading && (
                        <TableError errorMessage={`정보를 불러오는 중입니다`} />
                      )}
                      {!loading &&
                        data?.listDoor.doors &&
                        data.listDoor.doors.length < 1 && (
                          <TableError
                            errorMessage={`해당하는 문의 정보가 없습니다`}
                          />
                        )}
                    </div>
                    {!loading &&
                      data?.listDoor.doors &&
                      data.listDoor.doors.length > 0 && (
                        <PaginationComponent
                          page={page}
                          totalPages={Number(data.listDoor.totalPages)}
                          totalResults={Number(data.listDoor.totalResults)}
                          pageHandler={pageHandler}
                        />
                      )}
                  </div>
                  <Footer></Footer>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
