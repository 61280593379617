import { LayoutTop } from "../layout/top";
import thumb from "../assets/images/magazine_first.png";

import first from "../assets/images/magazine/first.png";
import second from "../assets/images/magazine/second.png";
import third from "../assets/images/magazine/third.png";
import fourth from "../assets/images/magazine/fourth.png";
import fifth from "../assets/images/magazine/fifth.png";
import sixth from "../assets/images/magazine/sixth.png";
import { Footer } from "../layout/footer";
import { magazine_test } from "../routes/home/parts/data";
import { ToTheTop } from "../components/to-the-top";
import { useParams } from "react-router";
import { useEffect } from "react";
import {
  GetProductInput,
  CanBuyInput,
  ListProductViewInput,
} from "../__generated__/globalTypes";
import {
  listProductView,
  listProductView_listProductView_productViews,
} from "../__generated__/listProductView";
import { getProduct } from "../__generated__/getProduct";

import { useGetProduct } from "../hooks/useGetProduct";
import { useListProductView } from "../hooks/useListProductView";
import { useCanBuy } from "../hooks/useCanBuy";
import { ProductThemeCurrForm } from "../routes/inst/mypage/enroll/parts/currParts/currUpForm";
import ReactQuill from "react-quill";
import moment from "moment";
export const SubCommonDetailMagazine = () => {
  const params = useParams();
  const onCompleted = (data: getProduct) => {};

  const onListProductViewCompleted = (data: listProductView) => {};

  const [getProduct, { data, loading }] = useGetProduct(onCompleted);

  const [
    listProductView,
    { data: listProductViewData, loading: listProductViewLoading },
  ] = useListProductView(onListProductViewCompleted);
  useEffect(() => {
    if (params.key) {
      const listProductViewInput: ListProductViewInput = {
        productId: +params.key,
      };
      listProductView({ variables: { input: listProductViewInput } });
      const getProductInput: GetProductInput = {
        productId: +params.key,
      };
      getProduct({ variables: { input: getProductInput } });
      const canBuyInput: CanBuyInput = {
        productId: +params.key,
      };
    }
  }, []);
  return (
    <>
      <LayoutTop title={"nothing"} />
      <main id="main">
        <ToTheTop posi={`bottom-5 sm:bottom-10 right-5 sm:bottom-10`} />
        {!loading && data?.getProduct.product && (
          <div className="wrapper">
            <div className="max-w-screen-yl mx-auto px-4 md:px-0 md:pt-8 relative">
              <div className="max-w-[736px] mx-auto">
                <div className="Topper">
                  <div className="relative">
                    <div className="w-full relative overflow-hidden">
                      {data?.getProduct.product.productViews &&
                        data?.getProduct.product.productViews
                          .filter((productView) => productView.is_rep)
                          .map((productView) => (
                            <img
                              key={`class-view-${productView.id}`}
                              src={
                                productView?.view?.url && productView.view.url
                              }
                              alt={`${data?.getProduct?.product?.productInfo?.title} 썸네일`}
                              className="w-full aspect-detailMain object-cover"
                            />
                          ))}
                    </div>
                  </div>
                  <div className="py-20 border-b border-grays">
                    <p className="text-center text-2xl gangwon ">
                      <span className="uppercase">
                        {data.getProduct.product.productInfo?.title}
                      </span>
                    </p>
                    <p className="pt-10 text-text whitespace-pre-wrap">
                      {data.getProduct.product.productInfo?.summary}
                    </p>
                    <p className="pt-10 text-right">
                      {data.getProduct.product.inst.user?.name} |{" "}
                      {moment(data.getProduct.product.createdAt).format(
                        "YYYY. M. D."
                      )}
                    </p>
                  </div>
                </div>
                <div className="pt-10">
                  {data?.getProduct.product?.productPage?.page && (
                    <div id={`class_intro`}>
                      <ReactQuill
                        value={data?.getProduct.product?.productPage?.page}
                        readOnly={true}
                        theme={`bubble`}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </main>
      <Footer></Footer>
    </>
  );
};
