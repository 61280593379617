import { gql } from "@apollo/client";
import React from "react";

export const DOOR_LIST_FRAGMENT = gql`
  fragment DoorListParts on Door {
    id
    title
    note
    status
    type
    createdAt
    updatedAt
    uuid
    error
    author {
      id
      name
      phone
    }
    receipt {
      id
      paid_amount
      paid_at
      orderStatus
      pgOption {
        pay_method
        name
      }
    }
  }
`;
