import React, { useEffect, useState } from "react";
import usePagination from "@mui/material/usePagination";
import { createBrowserHistory } from "history";
import { pageIndicator } from "../apollo";
import { useForm } from "react-hook-form";
import { GetProductInput, UpMagazineInput } from "../__generated__/globalTypes";
import { FormError2 } from "../components/form-error";
import { CategoryOption, CategoryOptionType } from "../constants/enum";
import { Button } from "../components/button";
import { IUpMagazineFormProps, useUpMagazine } from "../hooks/useUpMagazine";
import { upMagazine } from "../__generated__/upMagazine";
import { useParams } from "react-router";
import { getProduct } from "../__generated__/getProduct";
import { useGetProduct } from "../hooks/useGetProduct";
import { Link } from "react-router-dom";

export const SubAdminUpMagazineInfo: React.FC = () => {
  const params = useParams();

  useEffect(() => {
    pageIndicator("magazine");
  }, []);

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    setFocus,
    formState: { errors, isValid },
  } = useForm<IUpMagazineFormProps>({ mode: "all" });

  const onGetComplete = (data: getProduct) => {
    const {
      getProduct: { ok, err },
    } = data;
    if (!ok) {
      alert("잘못된 접근입니다");
      window.location.href = "/admin/magazine";
      return;
    }
    setValue("title", `${data.getProduct.product?.productInfo?.title}`);
    setValue("summary", `${data.getProduct.product?.productInfo?.summary}`);
    setValue("category", `${data.getProduct.product?.productInfo?.category}`);
    setValue("place", `${data.getProduct.product?.productInfo?.place}`);
    setFocus("title");
  };
  const [getMagazine, { data, loading }] = useGetProduct(onGetComplete);
  const getMagazineHandler = (productId: number) => {
    const getProductInput: GetProductInput = {
      productId,
    };
    getMagazine({ variables: { input: getProductInput } });
  };

  const onUpComplete = (data: upMagazine) => {
    const {
      upMagazine: { ok, err },
    } = data;
    if (ok) {
      alert("저장되었습니다");
    } else {
      alert(err);
    }
  };
  const [upMagazine, { loading: upMagazineLoading }] =
    useUpMagazine(onUpComplete);
  const onSubmit = () => {
    if (!params.productId) return;
    if (!upMagazineLoading) {
      const { title, summary, category, place } = getValues();
      const upMagazineInput: UpMagazineInput = {
        ...(data?.getProduct.product?.productInfo?.title !== title && {
          title,
        }),
        summary,
        category,
        place,
        productIdToUp: +params.productId,
      };
      upMagazine({ variables: { input: upMagazineInput } });
    }
  };

  useEffect(() => {
    if (!params.productId) return;
    const productId = +params.productId;
    getMagazineHandler(productId);
  }, []);

  return (
    <div className="bg-white p-5 rounded-2xl shadow-lg">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className=" p-5 lg:p-10 space-y-5 lg:space-y-10 max-w-xl">
          <div className="space-y-3">
            <p className="font-bold text-main lg:text-base text-sm">제목</p>
            <input
              {...register("title", {
                required: `제목을 입력해주세요`,
              })}
              type="text"
              className="border border-grays rounded-xl py-1 px-2 w-full text-sm lg:text-base"
            />
            {errors.title?.message && (
              <FormError2 errorMessage={errors.title.message} />
            )}
          </div>

          <div className="space-y-3">
            <p className="font-bold text-main lg:text-base text-sm">
              매거진 카테고리
            </p>
            <select
              {...register(`category`, {
                required: "카테고리를 선택해주세요",
              })}
              className="border border-grays rounded-xl py-1 px-2 w-full text-sm lg:text-base"
            >
              {Object(CategoryOption).map(
                (category: CategoryOptionType, index: number) => (
                  <option
                    key={`product-category-option-${index}`}
                    value={category.value}
                  >
                    {category.label}
                  </option>
                )
              )}
            </select>
            {errors.category?.message && (
              <FormError2 errorMessage={errors.category.message} />
            )}
          </div>

          <div className="space-y-3">
            <p className="font-bold text-main lg:text-base text-sm">진행장소</p>
            <input
              {...register("place", {
                required: `진행장소를 입력해주세요`,
              })}
              type="text"
              className="border border-grays rounded-xl py-1 px-2 w-full text-sm lg:text-base"
            />
            {errors.place?.message && (
              <FormError2 errorMessage={errors.place.message} />
            )}
          </div>

          <div className="space-y-3 relative">
            <p className="font-bold text-main lg:text-base text-sm">요약</p>
            <textarea
              {...register("summary", {
                required: `타이틀을 입력해주세요`,
              })}
              className="border border-grays rounded-xl py-1 px-2 w-full text-sm lg:text-base min-h-[300px]"
            ></textarea>
            {errors.summary?.message && (
              <FormError2 errorMessage={errors.summary.message} />
            )}
          </div>

          <div className="text-center py-10 space-x-5">
            <Link
              to={"/admin/magazine"}
              className="inline-block rounded-md border border-grays w-2/5 sm:w-[150px] py-1 hover:opacity-60 text-sm lg:text-base"
            >
              취소
            </Link>
            <Button
              className={`rounded-md text-white w-2/5 sm:w-[150px] py-1 hover:opacity-60 text-sm lg:text-base bg-main border border-main`}
              canClick={isValid}
              actionText={`저장`}
              loading={loading}
              color={"text-white"}
            />
          </div>
        </div>
      </form>
    </div>
  );
};
