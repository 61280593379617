import { useState } from "react";
export const Edit_Password_ct = () => {
  const [passErr, setPassErr] = useState<boolean>(false);
  const [crr, setCrr] = useState(0);
  return (
    <div className="bg-white  rounded-2xl shadow-lg ">
      <div className="max-w-xl p-10">
        <div className="space-y-2">
          <p className="font-bold text-main">현재 비밀번호</p>
          <input
            type="password"
            id="oldPass"
            className="border border-grays rounded-xl py-1 px-2 w-full"
          />
        </div>
        <div className="space-y-2 pt-8">
          <p className="font-bold text-main">변경 할 비밀번호</p>
          <input
            type="password"
            id="newPass"
            className="border border-grays rounded-xl py-1 px-2 w-full"
          />
        </div>
        <div className="space-y-2 pt-3">
          <p className="font-bold text-main">비밀번호 확인</p>
          <input
            type="password"
            onKeyUp={(e) => {
              let chk = (document.getElementById("newPass") as HTMLInputElement)
                .value;
              setPassErr(true);
              if (chk === (e.target as HTMLInputElement).value) {
                setCrr(1);
              } else {
                setCrr(0);
              }
            }}
            className="border border-grays rounded-xl py-1 px-2 w-full"
          />
          {crr === 0 && (
            <p
              className={`text-sm text-reds px-3 ${
                passErr ? "block" : "hidden"
              }`}
            >
              비밀번호가 일치하지 않습니다.
            </p>
          )}
          {crr === 1 && (
            <p
              className={`text-sm text-main px-3 ${
                passErr ? "block" : "hidden"
              }`}
            >
              비밀번호가 일치합니다.
            </p>
          )}
        </div>
        <div className="space-y-2 pt-10">
          <p className="font-bold text-main">이메일</p>
          <div className="grid grid-cols-[2.5fr_1fr] gap-x-3">
            <input
              type="email"
              className="border border-grays rounded-xl py-1 px-2 w-full"
            />
            <button className="hover:opacity-80 text-sm block w-full h-full border border-grays rounded-xl">
              인증번호발송
            </button>
          </div>
          <input
            type="number"
            className="border border-grays rounded-xl py-1 px-2 w-full"
            placeholder="인증번호를 입력해주세요"
            disabled
          />
        </div>
        <div className="text-center space-x-5 pt-10">
          <button className="w-40 py-1 border border-grays rounded-md hover:opacity-60">
            취소
          </button>
          <button className="bg-main w-40 py-1 text-white rounded-md hover:opacity-60">
            수정하기
          </button>
        </div>
      </div>
    </div>
  );
};
