import { SubTop } from "../../../../layout/subTop";
import { useEffect, useState } from "react";
import { GetReady } from "./getReady";
import online from "../../../../assets/icon/on.svg";
import offline from "../../../../assets/icon/off.svg";
import React from "react";
import Modal from "@mui/material/Modal";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Footer } from "../../../../layout/footer";
import { Aside } from "../aside";
import { useListProductMineActive } from "../../../../hooks/useListProductMineActive";
import { ProductType } from "../../../../__generated__/globalTypes";
import { useMe } from "../../../../hooks/useMe";
import { ToTheTop } from "../../../../components/to-the-top";
import { pageIndicator } from "../../../../apollo";
import { ModalListPayUsers } from "../../../../components/modal-list-pay-users";

export const Lecture_st = () => {
  useEffect(() => {
    pageIndicator("마이페이지");
  }, []);
  const [tabs, setTabs] = useState(0);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [lecture, setLecture] = useState<number | null>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const MenuBody = {
    boxShadow: "0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)",
  };
  const MenuStyle = {
    fontSize: "0.875rem",
  };
  const pages = 0;
  const { data, loading, refetch } = useListProductMineActive();
  const { data: me } = useMe();
  useEffect(() => {
    refetch();
  }, []);

  return (
    <div className="bg-main bg-opacity-50 w-full h-full min-h-screen">
      <div className="max-w-screen-yl mx-auto w-full h-full min-h-screen  bg-white">
        <SubTop title={"nothing"} children={`${me?.me.name} 님`} />
        <main id="main">
          <div className="lg:grid lg:grid-cols-[300px_1fr]">
            <Aside page={pages} />
            <ToTheTop posi={`bottom-32 right-4 lg:bottom-16`} white={true} />

            <div className="mypages">
              <div className="lectureWrap">
                <div className="h-12 lg:h-20">
                  <div className="subtitle h-full flex flex-wrap content-end lg:px-10 px-3 sm:px-5 lg:gap-x-1">
                    <div
                      className={`inline-block  w-1/3 lg:w-44 ${
                        tabs === 0 && "border-main border-b-2"
                      }`}
                    >
                      <button
                        className={`text-xs sm:text-sm lg:text-base leading-normal font-bold hover:opacity-60 w-full py-1  ${
                          tabs === 0 ? "text-main" : "text-gray-400"
                        }`}
                        onClick={() =>
                          setTabs(() => {
                            return 0;
                          })
                        }
                      >
                        판매중인 강의
                      </button>
                    </div>
                    <div
                      className={`inline-block  w-1/3 lg:w-44 ${
                        tabs === 1 && "border-main border-b-2"
                      }`}
                    >
                      <button
                        className={`text-xs sm:text-sm lg:text-base leading-normal font-bold hover:opacity-60 w-full py-1  ${
                          tabs === 1 ? "text-main" : "text-gray-400"
                        }`}
                        onClick={() =>
                          setTabs(() => {
                            return 1;
                          })
                        }
                      >
                        준비중인 강의
                      </button>
                    </div>
                  </div>
                </div>
                <div className=" bg-gray-100 h-fix-content relative pb-16 lg:pb-0 grid grid-rows-1">
                  <div className="w-full h-full px-4 lg:px-10 py-8 lg:py-12 space-y-10 ">
                    {tabs === 0 && (
                      <>
                        <div className="offline bg-white p-4 lg:p-10 rounded-lg">
                          <p className="pl-2 text-xl font-bold text-main leading-normal pb-3">
                            <span className="align-middle">오프라인 강의</span>
                            <img
                              src={offline}
                              alt="오프라인"
                              className="inline-block w-10 ml-3"
                            />
                          </p>
                          <div className="flex flex-wrap gap-5">
                            {!loading &&
                              data?.listProductMineActive.products &&
                              data?.listProductMineActive.products
                                .filter(
                                  (product) =>
                                    product.productType === ProductType.Offline
                                )
                                .map((product) => (
                                  <div
                                    key={`offline-class-${product.id}`}
                                    className="cards w-full min-w-[200px] max-w-[300px]  lg:w-[300px] lg:max-w-none p-4 inline-block bg-gray-200 rounded-md"
                                  >
                                    <div className="MypageRatio relative">
                                      {product.productViews &&
                                        product.productViews
                                          .filter(
                                            (productView) => productView.is_rep
                                          )
                                          .map((productView) => (
                                            <img
                                              key={`view-${productView.id}`}
                                              src={productView.view?.url}
                                              alt={
                                                product?.productInfo
                                                  ? `${product.productInfo.title}의 썸네일 그림`
                                                  : ``
                                              }
                                              className="absolute left-0 top-0 w-full h-full"
                                            />
                                          ))}
                                      <div
                                        aria-controls={`Menu${product.id}`}
                                        aria-haspopup="true"
                                        aria-expanded={
                                          open ? "true" : undefined
                                        }
                                        onClick={handleClick}
                                        className="inline-flex flex-wrap content-center justify-center absolute right-0 top-0 w-8 h-8 rounded-full cursor-pointer "
                                      >
                                        <i className="fas fa-ellipsis-v text-xl"></i>
                                      </div>
                                      <Menu
                                        id={`Menu${product.id}`}
                                        anchorEl={anchorEl}
                                        open={
                                          anchorEl?.getAttribute(
                                            "aria-controls"
                                          ) === `Menu${product.id}`
                                        }
                                        onClose={handleClose}
                                        MenuListProps={{
                                          "aria-labelledby": "basic-button",
                                        }}
                                        style={MenuBody}
                                      >
                                        <MenuItem
                                          onClick={() => {
                                            handleClose();
                                            setLecture(product.id);
                                          }}
                                          style={MenuStyle}
                                        >
                                          강의 수강생
                                        </MenuItem>
                                      </Menu>
                                    </div>
                                    <p className="font-bold text-xs flex flex-wrap justify-between pt-2">
                                      {product.inst.user?.name && (
                                        <span>{product.inst.user.name}</span>
                                      )}
                                    </p>
                                    <p className="py-2 text-sm leading-tight ">
                                      {product?.productInfo &&
                                        product?.productInfo.title}
                                    </p>
                                  </div>
                                ))}
                          </div>
                          {!loading &&
                            data?.listProductMineActive?.products &&
                            data.listProductMineActive.products.filter(
                              (product) =>
                                product.productType === ProductType.Offline
                            ).length < 1 && (
                              <div>
                                <p className="text-lg text-black text-center">
                                  현재 판매중인 강의가 없습니다.
                                </p>
                              </div>
                            )}
                          {loading && (
                            <div>
                              <p className="text-lg text-black text-center">
                                강의를 불러오는 중입니다.
                              </p>
                            </div>
                          )}
                        </div>
                        <div className="online bg-white p-4 lg:p-10 rounded-lg">
                          <p className="pl-2 text-xl font-bold text-main leading-normal pb-3">
                            <span className="align-middle">온라인 강의</span>
                            <img
                              src={online}
                              alt="온라인"
                              className="inline-block w-10 ml-3"
                            />
                          </p>

                          <div className="flex flex-wrap gap-5">
                            {!loading &&
                              data?.listProductMineActive.products &&
                              data?.listProductMineActive.products
                                .filter(
                                  (product) =>
                                    product.productType === ProductType.Online
                                )
                                .map((product) => (
                                  <div
                                    key={`online-class-${product.id}`}
                                    className="cards w-full min-w-[200px] max-w-[300px]  lg:w-[300px] lg:max-w-none p-4 inline-block bg-gray-200 rounded-md"
                                  >
                                    <div className="MypageRatio relative">
                                      {product.productViews &&
                                        product.productViews
                                          .filter(
                                            (productView) => productView.is_rep
                                          )
                                          .map((productView) => (
                                            <img
                                              key={`view-${productView.id}`}
                                              src={productView.view?.url}
                                              alt={
                                                product?.productInfo
                                                  ? `${product.productInfo.title} 의 썸네일`
                                                  : ``
                                              }
                                              className="absolute left-0 top-0 w-full h-full"
                                            />
                                          ))}
                                      <div
                                        aria-controls={`Menu${product.id}`}
                                        aria-haspopup="true"
                                        aria-expanded={
                                          open ? "true" : undefined
                                        }
                                        onClick={handleClick}
                                        className="inline-flex flex-wrap content-center justify-center absolute right-0 top-0 w-8 h-8 rounded-full bg-grays bg-opacity-30 cursor-pointer hover:bg-opacity-60"
                                      >
                                        <i className="fas fa-ellipsis-v text-xl"></i>
                                      </div>
                                      <Menu
                                        id={`Menu${product.id}`}
                                        anchorEl={anchorEl}
                                        open={
                                          anchorEl?.getAttribute(
                                            "aria-controls"
                                          ) === `Menu${product.id}`
                                        }
                                        onClose={handleClose}
                                        MenuListProps={{
                                          "aria-labelledby": "basic-button",
                                        }}
                                        style={MenuBody}
                                      >
                                        <MenuItem
                                          onClick={() => {
                                            handleClose();
                                            setLecture(product.id);
                                          }}
                                          style={MenuStyle}
                                        >
                                          수강생 정보
                                        </MenuItem>
                                      </Menu>
                                    </div>
                                    <p className="font-bold text-xs flex flex-wrap justify-between pt-2">
                                      {product.inst.user?.name && (
                                        <span>{product.inst.user.name}</span>
                                      )}
                                      {/* <img
                                    src={product.star}
                                    alt={
                                      "리뷰점수 별점" +
                                      product.review_point +
                                      "점"
                                    }
                                    className="h-4"
                                  /> */}
                                    </p>
                                    <p className="py-2 text-sm leading-tight">
                                      {product.productInfo &&
                                        product.productInfo.title}
                                    </p>
                                  </div>
                                ))}
                          </div>
                          {!loading &&
                            data?.listProductMineActive?.products &&
                            data.listProductMineActive.products.filter(
                              (product) =>
                                product.productType === ProductType.Online
                            ).length < 1 && (
                              <div>
                                <p className="text-lg text-black text-center">
                                  현재 판매중인 강의가 없습니다.
                                </p>
                              </div>
                            )}
                          {loading && (
                            <div>
                              <p className="text-lg text-black text-center">
                                강의를 불러오는 중입니다.
                              </p>
                            </div>
                          )}
                        </div>
                      </>
                    )}
                    {tabs === 1 && <GetReady />}
                  </div>
                  <Footer></Footer>
                </div>
                {lecture !== null && (
                  <Modal
                    open={Boolean(lecture)}
                    onClose={() => {
                      setLecture(null);
                    }}
                  >
                    <ModalListPayUsers productId={lecture} />
                  </Modal>
                )}
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
