import { ApolloError, gql, useMutation } from "@apollo/client";
import React from "react";
import {
  crConsultReply,
  crConsultReplyVariables,
} from "../__generated__/crConsultReply";
import { CrConsultReplyInput } from "../__generated__/globalTypes";

export interface ICrConsultReplyFormProps extends CrConsultReplyInput {}

const CR_CONSULT_REPLY_MUTATION = gql`
  mutation crConsultReply($input: CrConsultReplyInput!) {
    crConsultReply(input: $input) {
      ok
      err
    }
  }
`;

export const useCrConsultReply = (
  onCompleted?: (data: crConsultReply) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<crConsultReply, crConsultReplyVariables>(
    CR_CONSULT_REPLY_MUTATION,
    {
      onCompleted,
      onError,
    }
  );
};
