import { ApolloError, gql, useMutation } from "@apollo/client";
import React from "react";
import { crPlace, crPlaceVariables } from "../__generated__/crPlace";
import { CrPlaceInput } from "../__generated__/globalTypes";

export interface ICrPlaceFormProps extends CrPlaceInput {}

const CR_PLACE_MUTATION = gql`
  mutation crPlace($input: CrPlaceInput!) {
    crPlace(input: $input) {
      ok
      err
      productId
    }
  }
`;

export const useCrPlace = (
  onCompleted?: (data: crPlace) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<crPlace, crPlaceVariables>(CR_PLACE_MUTATION, {
    onCompleted,
    onError,
  });
};
