import { gql, useQuery } from "@apollo/client";
import { me } from "../__generated__/me";

const ME_QUERY = gql`
  query me {
    me {
      id
      name
      email
      role
      phone
      verified
      address
      subAddress
      inst {
        id
        billOption {
          CEOName
          MemberName
          ID
          PWD
          status
        }
        curator {
          id
          name
        }
        businessName
        businessFile {
          url
        }
        businessItem
        businessType
        businessNumber
      }
      charged {
        id
        user {
          id
          name
        }
      }
    }
  }
`;

export const useMe = (onCompleted?: (data: me) => void) => {
  return useQuery<me>(ME_QUERY, { onCompleted });
};
