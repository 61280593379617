import { useEffect, useState } from "react";
import logo from "../assets/icon/localpick_logo.svg";
import { Link, useParams } from "react-router-dom";
import { userRule1, userRule2, userRule3, personalInfo } from "../layout/text";
import ruleCellCome from "../assets/images/useRule_come.png";
import ruleCellGo from "../assets/images/useRule_go.png";
import { useVerifySignupEmail } from "../hooks/useVerifySignupEmail";
import { verifySignupEmail } from "../__generated__/verifySignupEmail";
import {
  VerifyEmailInput,
  VerifySignupEmailInput,
} from "../__generated__/globalTypes";
import { useVerifyEmail } from "../hooks/useVerifyEmail";
import { verifyEmail } from "../__generated__/verifyEmail";
export const CommonVerifyGeneral = () => {
  const params = useParams();

  const [verifyEmail, { loading, data }] = useVerifyEmail();
  const verifyEmailHandler = (code: string) => {
    const input: VerifyEmailInput = {
      code,
    };
    verifyEmail({ variables: { input } });
  };

  const goHome = () => {
    window.location.href = "/";
  };

  useEffect(() => {
    if (params.code && !loading) {
      verifyEmailHandler(params.code);
    }
  }, []);
  return (
    <div className="bg-main bg-opacity-50 w-full h-full min-h-screen">
      <div className="max-w-screen-yl mx-auto w-full h-full min-h-screen bg-white flex flex-wrap justify-center pt-10">
        <div className="sm:max-w-3xl w-full px-5 mt-30">
          <div className="w-[200px] mx-auto">
            <a href="/">
              <img src={logo} alt="로로 로고" className="w-full" />
            </a>
          </div>
          <div className="py-10">
            <div className="flex flex-col items-center w-full">
              {data?.verifyEmail.ok && (
                <div className="text-xl font-bold">
                  회원인증을 완료하였습니다
                </div>
              )}
              {!data?.verifyEmail.ok && (
                <div className="text-xl font-bold">
                  이미 만료된 인증정보입니다
                </div>
              )}
              <button
                className="mt-10 py-1 px-5 bg-main rounded text-white cursor-pointer"
                onClick={goHome}
              >
                홈으로 돌아가기
              </button>
            </div>
            <div className="flex flex-col items-center w-full"></div>
          </div>
        </div>
      </div>
    </div>
  );
};
