import { useEffect, useState } from "react";
import { pageIndicator } from "../apollo";
import { AsideAdminComponent } from "../layout/aside-admin";
import { Footer } from "../layout/footer";
import { Link } from "react-router-dom";
import { createBrowserHistory } from "history";
import { useForm } from "react-hook-form";
import { FormError2 } from "../components/form-error";
import { Button } from "../components/button";
import { CrPromotionInput } from "../__generated__/globalTypes";
import { crPromotion } from "../__generated__/crPromotion";
import { ICrPromotionFormProps, useCrPromotion } from "../hooks/useCrPromotion";
import { getValue } from "@testing-library/user-event/dist/utils";
import { AdminTop } from "../layout/adminTop";

export const AdminCrPromotion = () => {
  const history = createBrowserHistory();
  const [text, setText] = useState("");
  useEffect(() => {
    pageIndicator("promotion");
  }, []);

  const {
    getValues,
    register,
    handleSubmit,
    setFocus,
    formState: { errors, isValid },
  } = useForm<ICrPromotionFormProps>({ mode: "all" });

  const onComplete = (data: crPromotion) => {
    const {
      crPromotion: { ok, err },
    } = data;
    if (ok) {
      alert("저장되었습니다");
      window.location.href = "/admin/promotion";
    } else {
      alert(err);
    }
  };
  const [crPromotion, { loading }] = useCrPromotion(onComplete);
  const onSubmit = () => {
    if (!loading) {
      const { title, badge, desc, discountRate, startAt, endAt, badgeColor } =
        getValues();
      const input: CrPromotionInput = {
        title,
        badge,
        badgeColor,
        desc,
        discountRate: discountRate ? +discountRate : 0,
        startAt,
        endAt,
      };
      crPromotion({ variables: { input } });
    }
  };

  useEffect(() => {
    setFocus("title");
  }, []);

  return (
    <div className="bg-main bg-opacity-50 w-full h-full min-h-screen">
      <div className="w-[1440px] mx-auto h-full min-h-screen  bg-white">
        <AdminTop title={"top"} children={"관리자모드"} />
        <main id="main">
          <div className="grid grid-cols-[300px_1fr]">
            <AsideAdminComponent />
            <div className="mypages ">
              <div className="lectureWrap">
                <div className="h-20">
                  <div className="h-full flex-wrap flex justify-between px-10 content-center">
                    <div className="subtitle h-full flex flex-wrap content-end gap-x-3">
                      <div className={`tab-unselected`}>
                        <Link
                          className={`tab-text-unselected`}
                          to={`/admin/promotion`}
                        >
                          목록
                        </Link>
                      </div>
                      <div className={`tab-selected`}>
                        <Link
                          className={`tab-text-selected`}
                          to={`/admin/promotion/create`}
                        >
                          프로모션 생성
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className=" bg-gray-100 h-fix-content relative">
                  <div className={`w-full h-full px-10 py-10`}>
                    {/* 여기 */}
                    <div className="bg-white p-5 rounded-2xl shadow-lg">
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <div className=" p-5 lg:p-10 space-y-5 lg:space-y-10 max-w-xl">
                          <div className="space-y-3">
                            <p className="font-bold text-main lg:text-base text-sm">
                              제목
                            </p>
                            <input
                              {...register("title", {
                                required: `제목을 입력해주세요`,
                              })}
                              type="text"
                              className="border border-grays rounded-xl py-1 px-2 w-full text-sm lg:text-base"
                            />
                            {errors.title?.message && (
                              <FormError2 errorMessage={errors.title.message} />
                            )}
                          </div>

                          <div className="space-y-3">
                            <p className="font-bold text-main lg:text-base text-sm">
                              뱃지
                            </p>
                            <input
                              {...register("badge", {
                                required: `뱃지를 입력해주세요`,
                              })}
                              type="text"
                              onKeyUp={(e) => {
                                setText(e.currentTarget.value);
                              }}
                              className="border border-grays rounded-xl py-1 px-2 w-full text-sm lg:text-base"
                            />
                            {errors.badge?.message && (
                              <FormError2 errorMessage={errors.badge.message} />
                            )}
                          </div>

                          <div className="space-y-3">
                            <p className="font-bold text-main lg:text-base text-sm">
                              뱃지색
                            </p>
                            <input
                              {...register("badgeColor", {
                                required: `뱃지색을 정해주세요`,
                              })}
                              type="color"
                              onChange={(e) => {
                                (
                                  document.getElementById(
                                    "badge"
                                  ) as HTMLElement
                                ).style.background = e.currentTarget.value;
                              }}
                              className="border border-grays rounded-xl py-1 px-2 w-[60px] text-sm lg:text-base cursor-pointer"
                            />
                            <div className="inline-block ml-2">
                              <span
                                className={`inline-block p-1 px-3 rounded-lg opacity-80 text-white`}
                                id="badge"
                              >
                                {text}
                              </span>
                            </div>
                            {errors.badgeColor?.message && (
                              <FormError2
                                errorMessage={errors.badgeColor?.message}
                              />
                            )}
                          </div>

                          <div className="space-y-3">
                            <p className="font-bold text-main lg:text-base text-sm">
                              할인율
                            </p>
                            <input
                              {...register("discountRate", {
                                required: "할인율은 필수 정보입니다",
                              })}
                              type="number"
                              defaultValue={0}
                              className="border border-grays rounded-xl py-1 px-2 w-full text-sm lg:text-base"
                            />
                          </div>
                          {errors.discountRate?.message && (
                            <FormError2
                              errorMessage={errors.discountRate.message}
                            />
                          )}

                          <div className="space-y-3">
                            <p className="font-bold text-main lg:text-base text-sm">
                              시작일
                            </p>
                            <input
                              {...register("startAt", {
                                required: "시작일은 필수정보입니다",
                              })}
                              type="date"
                              max={`2999-12-31`}
                              className="border border-grays rounded-xl py-1 px-2 w-full text-sm lg:text-base"
                            />
                          </div>
                          {errors.startAt?.message && (
                            <FormError2 errorMessage={errors.startAt.message} />
                          )}

                          <div className="space-y-3">
                            <p className="font-bold text-main lg:text-base text-sm">
                              종료일
                            </p>
                            <input
                              {...register("endAt", {
                                required: "종료일은 필수정보입니다",
                              })}
                              type="date"
                              max={`2999-12-31`}
                              className="border border-grays rounded-xl py-1 px-2 w-full text-sm lg:text-base"
                            />
                          </div>
                          {errors.endAt?.message && (
                            <FormError2 errorMessage={errors.endAt.message} />
                          )}

                          <div className="space-y-3 relative">
                            <p className="font-bold text-main lg:text-base text-sm">
                              설명
                            </p>
                            <textarea
                              {...register("desc", {
                                required: `설명을 입력해주세요`,
                              })}
                              className="border border-grays rounded-xl py-1 px-2 w-full text-sm lg:text-base min-h-[300px]"
                            ></textarea>
                            {errors.desc?.message && (
                              <FormError2 errorMessage={errors.desc.message} />
                            )}
                          </div>

                          <div className="text-center py-10 space-x-5">
                            <button className="rounded-md border border-grays w-2/5 sm:w-[150px] py-1 hover:opacity-60 text-sm lg:text-base ">
                              취소
                            </button>
                            <Button
                              className={`rounded-md text-white w-2/5 sm:w-[150px] py-1 hover:opacity-60 text-sm lg:text-base bg-main border border-main`}
                              canClick={isValid}
                              actionText={`저장`}
                              loading={loading}
                              color={"text-white"}
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <Footer></Footer>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
