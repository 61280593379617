import { ApolloError, gql, useMutation } from "@apollo/client";
import React from "react";
import { delDoor, delDoorVariables } from "../__generated__/delDoor";

const DEL_DOOR_MUTATION = gql`
  mutation delDoor($input: DelDoorInput!) {
    delDoor(input: $input) {
      ok
      err
    }
  }
`;

export const useDelDoor = (
  onCompleted?: (data: delDoor) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<delDoor, delDoorVariables>(DEL_DOOR_MUTATION, {
    onCompleted,
    onError,
  });
};
