import React, { useState } from "react";
import { Link } from "react-router-dom";

export interface ITabLink {
  text: string;
  to: string;
  selected?: boolean;
}

export interface IComponentTabLinkProps {
  tabs: ITabLink[];
}

export const ComponentTabLink: React.FC<IComponentTabLinkProps> = ({
  tabs,
}) => {
  return (
    <div className="h-20">
      <div className="h-full flex-wrap flex justify-between px-10 content-center">
        <div className="subtitle h-full flex flex-wrap content-end gap-x-3">
          {tabs.map(({ selected, text, to }, index) => (
            <div
              key={`tab-${index}`}
              className={`inline-block w-44 text-center ${
                selected ? `border-main border-b-2` : ``
              }`}
            >
              <Link
                className={`block leading-normal font-bold hover:opacity-60 w-full py-1 ${
                  selected ? `text-main` : `text-gray-400`
                }`}
                to={to}
              >
                {`${text}`}
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export interface ITab {
  text: string;
}

export interface IComponentTabProps {
  tabs: ITab[];
  tab: number;
  setTabHandler: (tab: number) => void;
}

export const ComponentTab: React.FC<IComponentTabProps> = ({
  tabs,
  tab,
  setTabHandler,
}) => {
  return (
    <div>
      <div className="h-12 lg:h-20 overflow-auto removeScroll">
        <div className=" w-[200%] sm:w-full subtitle h-full flex flex-wrap content-end lg:px-10 pl-3 pr-12 sm:px-5 lg:gap-x-1 ">
          {tabs.map(({ text }, index) => (
            <div
              key={`tab-${index}`}
              className={`inline-block  w-1/6 sm:w-1/3 lg:w-40 text-center ${
                index === tab ? `border-main border-b-2` : ``
              }`}
            >
              <button
                className={`text-xs sm:text-sm lg:text-base leading-normal font-bold hover:opacity-60 w-full py-1 ${
                  index === tab ? `text-main` : `text-gray-400`
                }`}
                onClick={() => setTabHandler(index)}
              >
                {`${text}`}
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
