import { useEffect, useState } from "react";
import { Footer } from "../../../../layout/footer";
import { ASIDE } from "../aside";
import { SubTop } from "../../../../layout/subTop";
import { SubUserMypageCrQuestion } from "../../../../sub-routes/sub-user-mypage-cr-question";
import { useListDoorMine } from "../../../../hooks/useListDoorMine";
import moment from "moment";
import { GeneralDetailDoor } from "../../../../sub-routes/sub-general-detail-door";
import { ListDoorRefundInput } from "../../../../__generated__/globalTypes";
import { ComponentTab, ITab } from "../../../../components/component-tab";
import { useListDoorRefund } from "../../../../hooks/useListDoorRefund";
import { SubGeneralListDoor } from "../../../../sub-routes/sub-general-list-door";
import { SubGeneralListDoorRefund } from "../../../../sub-routes/sub-general-list-door-refund";
export const Question = () => {
  const [tab, setTab] = useState(0);
  const [doorId, setDoorId] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const setTabHandler = (tab: number) => {
    setDoorId(0);
    setTab(tab);
  };
  const setDoorHandler = (doorId: number) => {
    setTabHandler(3);
    setDoorId(doorId);
  };
  const tabList: ITab[] = [
    { text: "나의 문의" },
    { text: "환불 문의" },
    { text: "문의글 작성" },
  ];

  const { data, loading, refetch } = useListDoorMine();
  const [
    listDoorRefund,
    {
      data: listDoorRefundData,
      loading: listDoorRefundLoading,
      refetch: listDoorRefundRefetch,
    },
  ] = useListDoorRefund();
  const listDoorRefundHandler = (page: number) => {
    const input: ListDoorRefundInput = {
      page,
    };
    listDoorRefund({ variables: { input } });
  };

  useEffect(() => {
    listDoorRefundHandler(page);
  }, []);

  return (
    <div className="bg-main bg-opacity-50 w-full h-full min-h-screen">
      <div className="max-w-screen-yl mx-auto w-full h-full min-h-screen  bg-white">
        <SubTop title={"nothing"} />
        <main id="main">
          <div className="lg:grid lg:grid-cols-[300px_1fr]">
            <ASIDE page={3} />
            <div className="lectureWrap">
              <div className="h-12 lg:h-20">
                <ComponentTab
                  tabs={tabList}
                  tab={tab}
                  setTabHandler={setTabHandler}
                />
              </div>
              <div className="bg-gray-100 h-fix-content relative grid grid-rows-1 pb-28 sm:pb-0">
                <div className="w-full h-full px-3 lg:px-10 py-8 lg:py-12 space-y-10">
                  {tab === 0 && (
                    <SubGeneralListDoor
                      data={data}
                      loading={loading}
                      refetch={refetch}
                      setDoorHandler={setDoorHandler}
                    />
                  )}
                  {tab === 1 && (
                    <SubGeneralListDoorRefund
                      data={listDoorRefundData}
                      loading={listDoorRefundLoading}
                      refetch={listDoorRefundRefetch}
                      setDoorHandler={setDoorHandler}
                    />
                  )}
                  {tab === 2 && (
                    <SubUserMypageCrQuestion
                      refetch={refetch}
                      refundRefetch={listDoorRefundRefetch}
                    />
                  )}
                  {tab === 3 && (
                    <GeneralDetailDoor
                      doorId={doorId}
                      setTabHandler={setTabHandler}
                    />
                  )}
                </div>
                <Footer></Footer>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
