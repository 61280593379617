import React, { useEffect, useState } from "react";
import {
  CrDoorReplyInput,
  DelDoorReplyInput,
  UpDoorReplyInput,
} from "../__generated__/globalTypes";
import { useForm } from "react-hook-form";
import { TextareaAutosize } from "@mui/material";
import { ReplyButton } from "./button";
import { IUpDoorReplyFormProps, useUpDoorReply } from "../hooks/useUpDoorReply";
import { upDoorReply } from "../__generated__/upDoorReply";
import { listDoorReply_listDoorReply_doorReplies } from "../__generated__/listDoorReply";
import moment from "moment";
import { useMe } from "../hooks/useMe";
import { useDelDoorReply } from "../hooks/useDelDoorReply";
import { delDoorReply } from "../__generated__/delDoorReply";

interface IComponentUpDoorReplyForm {
  doorReply: listDoorReply_listDoorReply_doorReplies;
  listRefetch: () => void;
}

export const ComponentUpDoorReply: React.FC<IComponentUpDoorReplyForm> = ({
  doorReply,
  listRefetch,
}) => {
  const [edit, setEdit] = useState<boolean>(false);
  const setEditHandler = () => {
    setEdit(!edit);
    if (edit) {
      setFocus("note");
    }
  };

  const { data: me } = useMe();

  const {
    register,
    getValues,
    setValue,
    handleSubmit,
    setFocus,
    formState: { errors, isValid },
  } = useForm<IUpDoorReplyFormProps>({ mode: "all" });

  const onUpComplete = async (data: upDoorReply) => {
    const {
      upDoorReply: { ok, err },
    } = data;
    if (ok) {
      alert("수정하였습니다");
      setEditHandler();
      listRefetch();
    }
    if (!ok) {
      alert(err);
    }
  };
  const [upDoorReply, { loading: upDoorReplyLoading }] =
    useUpDoorReply(onUpComplete);
  const onSubmit = () => {
    if (!upDoorReplyLoading) {
      const { note } = getValues();
      const input: UpDoorReplyInput = {
        doorReplyIdToUp: doorReply.id,
        note,
      };
      upDoorReply({ variables: { input } });
    }
  };

  const onDelete = async (data: delDoorReply) => {
    const {
      delDoorReply: { ok, err },
    } = data;
    if (ok) {
      alert("문의 정보를 삭제하였습니다");
      listRefetch();
    }
    if (!ok) {
      alert(err);
    }
  };
  const [delDoorReply, { loading: delDoorReplyLoading }] =
    useDelDoorReply(onDelete);
  const delDoorReplyHandler = (replyIdToDel: number) => {
    if (!delDoorReplyLoading) {
      const input: DelDoorReplyInput = {
        replyIdToDel,
      };
      delDoorReply({ variables: { input } });
    }
  };

  useEffect(() => {
    setValue("note", doorReply.note);
  }, []);

  return (
    <div className="w-full block py-4 mt-1 border-b border-[#e2e2e2]">
      <form onSubmit={handleSubmit(onSubmit)}>
        <p>
          {`${doorReply.author.name}님, ${moment(doorReply.createdAt).format(
            "YYYY-M-D, HH:mm"
          )}`}
          {doorReply.author.id === me?.me.id && !edit && (
            <span className="float-right">
              <span
                className="cursor-pointer"
                onClick={() => delDoorReplyHandler(doorReply.id)}
              >
                삭제
              </span>
              <span>{` / `}</span>
              <span className="cursor-pointer" onClick={setEditHandler}>
                수정
              </span>
            </span>
          )}
          {doorReply.author.id === me?.me.id && edit && (
            <span
              className="float-right cursor-pointer"
              onClick={setEditHandler}
            >
              취소
            </span>
          )}
        </p>
        <div className="grid grid-cols-7">
          <TextareaAutosize
            {...register(`note`, {
              required: "필수 정보입니다",
            })}
            minRows="2"
            disabled={!edit}
            placeholder="답변을 입력해주세요"
            className={`w-full py-1 px-4 bg-white resize-none rounded-sm border-gray-300 focus:border-gray-500 outline-none ${
              edit ? `col-span-6 border-2` : `col-span-7 border-none`
            } h-auto min-h-fit`}
          />
          {edit && (
            <ReplyButton
              canClick={isValid}
              color={`bg-main text-white`}
              loading={upDoorReplyLoading}
              actionText={`저장`}
            />
          )}
        </div>
      </form>
    </div>
  );
};
