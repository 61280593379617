import React from "react";
import { gql, useLazyQuery, useQuery } from "@apollo/client";
import { listProductMineActive } from "../__generated__/listProductMineActive";
import {
  listReceiptMineOffline,
  listReceiptMineOfflineVariables,
} from "../__generated__/listReceiptMineOffline";

const LIST_RECEIPT_MINE_OFFLINE_QUERY = gql`
  query listReceiptMineOffline($input: ListReceiptMineOfflineInput!) {
    listReceiptMineOffline(input: $input) {
      ok
      err
      receipts {
        id
        scheduledAt
        orderStatus
        createdAt
        pgOption {
          name
          pay_method
        }
        product {
          productInfo {
            scheduledAt
            title
            price
          }
          inst {
            id
            atelier {
              name
            }
            user {
              name
            }
          }
          createdAt
          productStatus
          productType
          productKit {
            id
            view {
              url
            }
            desc
          }
          productViews {
            index
            is_rep
            id
            view {
              url
            }
          }
        }
      }
    }
  }
`;

export const useListReceiptMineOffline = (
  onCompleted?: (data: listReceiptMineOffline) => void
) => {
  return useLazyQuery<listReceiptMineOffline, listReceiptMineOfflineVariables>(
    LIST_RECEIPT_MINE_OFFLINE_QUERY,
    {
      onCompleted,
      fetchPolicy: `network-only`,
    }
  );
};
