import React from "react";
import gql from "graphql-tag";
import { ApolloError, useMutation } from "@apollo/client";
import {
  approveCurator,
  approveCuratorVariables,
} from "../__generated__/approveCurator";

const APPROVE_CURATOR_MUTATION = gql`
  mutation approveCurator($input: ApproveCuratorInput!) {
    approveCurator(input: $input) {
      ok
      err
    }
  }
`;

export const useApproveCurator = (
  onCompleted?: (data: approveCurator) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<approveCurator, approveCuratorVariables>(
    APPROVE_CURATOR_MUTATION,
    {
      onCompleted,
      onError,
    }
  );
};
