import { useEffect, useState } from "react";
import { pageIndicator } from "../apollo";
import { SubTop } from "../layout/subTop";
import { AsideAdminComponent } from "../layout/aside-admin";
import { Footer } from "../layout/footer";
import { TabProps, Tabs } from "../components/tab";
import { SubAdminUpBanner } from "../sub-routes/sub-admin-up-banner";
import { SubAdminUpBannerMain } from "../sub-routes/sub-admin-up-banner-main";
import { SubAdminUpBannerSub } from "../sub-routes/sub-admin-up-banner-sub";
import { SubAdminUpBannerClass } from "../sub-routes/sub-admin-up-banner-class";
import { SubAdminUpBannerMagazine } from "../sub-routes/sub-admin-up-banner-magazine";
import { SubAdminUpBannerPlace } from "../sub-routes/sub-admin-up-banner-place";
import { useGetBanner } from "../hooks/useGetBanner";
import { useGetBannerOptionActive } from "../hooks/useGetBannerOptionActive";

export const AdminUpBanner = () => {
  const [tab, setTab] = useState(0);
  const tabs: TabProps[] = [
    { index: 0, tabText: "배너 정보" },
    { index: 1, tabText: "메인" },
    { index: 2, tabText: "서브" },
    { index: 3, tabText: "온오프라인" },
    { index: 4, tabText: "매거진" },
    { index: 5, tabText: "플레이스" },
  ];
  const setTabHandler = (index: number) => {
    setTab(index);
  };

  useEffect(() => {
    pageIndicator("banner");
  }, []);

  return (
    <div className="bg-main bg-opacity-50 w-full h-full min-h-screen">
      <div className="w-[1440px] mx-auto h-full min-h-screen  bg-white">
        <SubTop title={"top"} children={"관리자모드"} />
        <main id="main">
          <div className="grid grid-cols-[300px_1fr]">
            <AsideAdminComponent />
            <div className="mypages">
              <div className="lectureWrap">
                <div className="h-20">
                  <div className="h-full flex-wrap flex justify-between px-10 content-center">
                    <Tabs index={tab} tabs={tabs} setTabs={setTabHandler} />
                  </div>
                </div>
                <div className=" bg-gray-100 h-fix-content relative">
                  <div className={`w-full h-full px-10 py-10`}>
                    {/* 여기 */}
                    {tab === 0 && <SubAdminUpBanner />}
                    {tab === 1 && <SubAdminUpBannerMain />}
                    {tab === 2 && <SubAdminUpBannerSub />}
                    {tab === 3 && <SubAdminUpBannerClass />}
                    {tab === 4 && <SubAdminUpBannerMagazine />}
                    {tab === 5 && <SubAdminUpBannerPlace />}
                    <Footer></Footer>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
