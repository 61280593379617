import { useEffect, useState } from "react";
import { pageIndicator } from "../apollo";
import searchImg from "../assets/icon/search.png";
import { AsideAdminComponent } from "../layout/aside-admin";
import { Footer } from "../layout/footer";
import { Link, useLocation } from "react-router-dom";
import { createBrowserHistory } from "history";
import { DelUserInput, ListPlaceInput } from "../__generated__/globalTypes";
import { TableError } from "../components/table-error";
import { TextButton } from "../components/button";
import { useForm } from "react-hook-form";
import { AdminTop } from "../layout/adminTop";
import { ISearchFormProps } from "./admin-list-magazine";
import { useListUserGeneral } from "../hooks/useListUserGeneral";
import { delUser } from "../__generated__/delUser";
import { useDelUser } from "../hooks/useDelUser";
import { PaginationComponent } from "../components/pagination";
import { ComponentTabLink, ITabLink } from "../components/component-tab";

export const AdminListGeneral = () => {
  const history = createBrowserHistory();
  const location = useLocation();
  const [page, setPage] = useState<number>(1);
  const [search, setSearch] = useState<string>("");

  const tabList: ITabLink[] = [
    { text: "목록", to: "/admin/user", selected: true },
    { text: "1:1문의", to: "/admin/user/door" },
    { text: "환불문의", to: "/admin/user/refund" },
  ];

  const pageHandler = (page: number) => {
    setPage(page);
    history.push({
      pathname: "/admin/user",
      search: `?page=${page}${search ? `&search=${search}` : ""}`,
    });
    listUserGeneralHandler(page, search);
  };

  const { register, getValues, handleSubmit } = useForm<ISearchFormProps>({
    mode: "all",
  });

  const onSearch = async () => {
    const { search } = getValues();
    setSearch(search);
    setPage(1);
    history.push({
      pathname: "/admin/user",
      search: `${search ? `?search=${search}` : ""}`,
    });
    listUserGeneralHandler(page, search);
  };

  useEffect(() => {
    pageIndicator("user");
  }, []);

  const [listUserGeneral, { data, loading, refetch }] = useListUserGeneral();
  const listUserGeneralHandler = (
    page: number | null,
    search: string | null
  ) => {
    const input: ListPlaceInput = {
      ...(page && { page: +page }),
      ...(search && { search: decodeURI(search) }),
    };
    listUserGeneral({ variables: { input } });
  };

  const onDelete = async (data: delUser) => {
    const {
      delUser: { ok, err },
    } = data;
    if (ok) {
      alert("사용자 정보를 삭제하였습니다");
      refetch();
    }
    if (!ok) {
      alert(err);
    }
  };
  const [delUser, { loading: delUserLoading }] = useDelUser(onDelete);
  const delUserHandler = (userIdToDel: number) => {
    if (!delUserLoading) {
      const input: DelUserInput = {
        userIdToDel,
      };
      delUser({ variables: { input } });
    }
  };

  useEffect(() => {
    setPage(
      location.search.split("page=").length > 1
        ? +location.search.split("page=")[1].split("&")[0]
        : 1
    );
    setSearch(
      location.search.split("search=").length > 1
        ? location.search.split("search=")[1].split("&")[0]
        : ""
    );
    listUserGeneralHandler(page, search);
  }, []);

  return (
    <div className="bg-main bg-opacity-50 w-full h-full min-h-screen">
      <div className="w-[1440px] mx-auto h-full min-h-screen  bg-white">
        <AdminTop title={"top"} children={"관리자모드"} />
        <main id="main">
          <div className="grid grid-cols-[300px_1fr]">
            <AsideAdminComponent />
            <div className="mypages">
              <div className="lectureWrap">
                <ComponentTabLink tabs={tabList} />
                <div className=" bg-gray-100 h-fix-content relative">
                  <div className={`w-full h-full px-10 py-12`}>
                    <div className="text-right pb-8">
                      <form onSubmit={handleSubmit(onSearch)}>
                        <p className="inline-block max-w-md w-full text-right relative">
                          <input
                            {...register(`search`)}
                            type="text"
                            className="w-full py-1 px-4 bg-white rounded-full"
                            placeholder="사용자 이름으로 검색"
                          />
                          <button className="absolute right-3 top-1">
                            <img src={searchImg} alt="찾기" className="w-5" />
                          </button>
                        </p>
                      </form>
                    </div>
                    {/* 여기 */}
                    <div className="bg-white p-5 rounded-2xl shadow-lg">
                      <p className="text-main font-bold pb-3">회원 목록</p>
                      <table className="w-full">
                        <colgroup>
                          <col className="w-2/12" />
                          <col className="w-2/12" />
                          <col className="w-3/12" />
                          <col className="w-3/12" />
                          <col className="w-2/12" />
                        </colgroup>
                        <thead>
                          <tr className="bg-main  border-gray-300">
                            <th className="text-white ">회원번호</th>
                            <th className="text-white ">이름</th>
                            <th className="text-white ">아이디</th>
                            <th className="text-white ">연락처</th>
                            <th className="text-white ">기능</th>
                          </tr>
                        </thead>
                        <tbody>
                          {!loading &&
                            data?.listUserGeneral.users &&
                            data.listUserGeneral.users.map((user) => (
                              <tr
                                key={`place-${user?.id}`}
                                className="border-b-2 border-gray-300 h-4"
                              >
                                <td className="text-sm text-center py-1 px-1 h-full overflow-hidden">
                                  {user?.uuid && user.uuid}
                                </td>
                                <td className="text-sm text-center h-full relative">
                                  <Link
                                    to={`/admin/user/detail/${user.id}`}
                                    className={`cursor-pointer`}
                                  >
                                    {user.name}
                                  </Link>
                                </td>
                                <td className="text-sm text-center  relative">
                                  {user.email}
                                </td>
                                <td className="text-sm text-center py-1 px-1 h-full overflow-hidden">
                                  {user.phone}
                                </td>
                                <td className="text-sm text-center py-1 px-1 h-full w-1/6 overflow-hidden">
                                  <TextButton
                                    canClick={!delUserLoading}
                                    actionText={`탈퇴`}
                                    onClick={() => delUserHandler(user.id)}
                                  />
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                      {loading && (
                        <TableError errorMessage={`정보를 불러오는 중입니다`} />
                      )}
                      {!loading &&
                        data?.listUserGeneral.users &&
                        data.listUserGeneral.users.length < 1 && (
                          <TableError
                            errorMessage={`해당하는 사용자 정보가 없습니다`}
                          />
                        )}
                    </div>
                    {!loading &&
                      data?.listUserGeneral.users &&
                      data.listUserGeneral.users.length > 0 && (
                        <PaginationComponent
                          page={page}
                          totalPages={Number(data.listUserGeneral.totalPages)}
                          totalResults={Number(
                            data.listUserGeneral.totalResults
                          )}
                          pageHandler={pageHandler}
                        />
                      )}
                  </div>
                  <Footer></Footer>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
