import { ApolloError, gql, useMutation } from "@apollo/client";
import { adminLogin, adminLoginVariables } from "../__generated__/adminLogin";
import { login, loginVariables } from "../__generated__/login";

const ADMIN_LOGIN_MUTATION = gql`
  mutation adminLogin($loginInput: LoginInput!) {
    adminLogin(input: $loginInput) {
      ok
      token
      err
    }
  }
`;

export const useAdminSignIn = (
  onCompleted?: (data: adminLogin) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<adminLogin, adminLoginVariables>(ADMIN_LOGIN_MUTATION, {
    onCompleted,
    onError,
  });
};
