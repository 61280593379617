import moment from "moment";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import { Button } from "../../../../../components/button";
import { FormError } from "../../../../../components/form-error";
import {
  LocalCategoryOption,
  LocalCategoryOptionType,
  ProductCategoryOption,
  ProductCategoryOptionType,
} from "../../../../../constants/enum";
import { useGetProduct } from "../../../../../hooks/useGetProduct";
import {
  IUpProductFormProps,
  useUpProduct,
} from "../../../../../hooks/useUpProduct";
import {
  getProduct,
  getProduct_getProduct_product,
} from "../../../../../__generated__/getProduct";
import {
  GetProductInput,
  ProductType,
  UpProductInput,
} from "../../../../../__generated__/globalTypes";
import { upProduct } from "../../../../../__generated__/upProduct";

export const EditInfo = () => {
  const [onoff, setOnOff] = useState(0);
  const [called, setCalled] = useState<boolean>(false);
  const params: any = useParams();

  const onGetCompleted = (data: getProduct) => {
    if (called) {
      setValue("title", data.getProduct.product?.productInfo?.title);
      setValue("price", data.getProduct.product?.productInfo?.price);
      setValue(
        "discountRate",
        data.getProduct.product?.productInfo?.discountRate
      );
      setValue("productType", data.getProduct.product?.productType);
      if (data.getProduct.product?.productType === ProductType.Offline)
        setOnOff(1);
      setValue("materials", data.getProduct.product?.productInfo?.materials);
      setValue("summary", data.getProduct.product?.productInfo?.summary);
      setValue(
        "productCategory",
        data.getProduct.product?.productInfo?.productCategory
      );
      setValue("place", data.getProduct.product?.productInfo?.place);
      setValue("max", data.getProduct.product?.productInfo?.max);
      setValue("playTime", data.getProduct.product?.productInfo?.playTime);
      setValue(
        "localCategory",
        data.getProduct.product?.productInfo?.localCategory
      );
      if (data.getProduct.product?.productInfo?.scheduledAt)
        setValue(
          "scheduledAt",
          moment(data.getProduct.product?.productInfo?.scheduledAt).format(
            "YYYY-MM-DD"
          )
        );
      setFocus("title");
    }
  };

  const onCompleted = async (data: upProduct) => {
    const {
      upProduct: { ok, err },
    } = data;
    if (ok) {
      await getProductRefetch();
      alert("강의정보가 저장되었습니다");
    } else {
      console.error(err);
    }
  };

  const [
    getProduct,
    {
      data: getProductData,
      loading: getProductLoading,
      refetch: getProductRefetch,
    },
  ] = useGetProduct(onGetCompleted);

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    setFocus,
    formState: { errors, isValid },
  } = useForm<IUpProductFormProps>({
    mode: "all",
  });

  const [upProduct, { data: upProductResult, loading }] =
    useUpProduct(onCompleted);

  const onSubmit = () => {
    if (!loading) {
      const {
        title,
        price,
        discountRate,
        scheduledAt,
        productType,
        summary,
        productCategory,
        place,
        max,
        playTime,
        materials,
        localCategory,
      } = getValues();
      const originalProduct = getProductData?.getProduct.product;
      if (originalProduct === null) {
        alert("기존 정보를 불러올 수 없습니다");
        return;
      }
      if (originalProduct?.id !== +params.key) {
        alert("일시적인 장애가 발생했습니다. 다시 입력해주세요");
        return;
      }
      const productIdToUp: number = originalProduct?.id
        ? originalProduct.id
        : 0;
      if (productIdToUp === 0) {
        alert("강의정보를 수정할 수 없습니다.");
        return;
      }
      const upProductInput: UpProductInput = {
        ...(title &&
          originalProduct?.productInfo?.title !== title && { title }),
        ...(productType &&
          originalProduct?.productType !== productType && {
            productType,
          }),
        ...(discountRate &&
          originalProduct?.productInfo?.discountRate !== discountRate && {
            discountRate: +discountRate,
          }),
        ...(price &&
          originalProduct?.productInfo?.price !== price && { price: +price }),
        ...(scheduledAt &&
          originalProduct?.productInfo?.scheduledAt !== scheduledAt && {
            scheduledAt,
          }),
        ...(summary &&
          originalProduct?.productInfo?.summary !== summary && {
            summary,
          }),
        ...(productCategory &&
          originalProduct?.productInfo?.productCategory !== productCategory && {
            productCategory,
          }),
        ...(place &&
          originalProduct?.productInfo?.place !== place && {
            place,
          }),
        ...(max &&
          originalProduct?.productInfo?.max !== max && {
            max,
          }),
        ...(playTime &&
          originalProduct?.productInfo?.playTime !== playTime && {
            playTime,
          }),
        ...(materials &&
          originalProduct?.productInfo?.materials !== materials && {
            materials,
          }),
        productIdToUp,
        localCategory,
      };
      upProduct({
        variables: {
          input: upProductInput,
        },
      });
      setCalled(true);
    }
  };

  const onInvalid = () => {};

  useEffect(() => {
    setCalled(true);
    const getProductInput: GetProductInput = {
      productId: params.key ? +params.key : 0,
    };
    getProduct({ variables: { input: getProductInput } });
  }, []);

  return (
    <div className="bg-white">
      {!getProductLoading && (
        <form onSubmit={handleSubmit(onSubmit, onInvalid)}>
          <div className=" p-5 lg:p-10 space-y-5 lg:space-y-10 max-w-xl">
            <div className="space-y-3">
              <p className="font-bold text-main lg:text-base text-sm">강의명</p>
              <input
                {...register("title", { required: `타이틀을 입력해주세요` })}
                type="text"
                className="border border-grays rounded-xl py-1 px-2 w-full text-sm lg:text-base"
              />
              {errors.title?.message && (
                <FormError errorMessage={errors.title.message} />
              )}
            </div>

            <div className="space-y-3">
              <p className="font-bold text-main lg:text-base text-sm">가격</p>
              <input
                {...register("price", { required: `가격을 입력해주세요` })}
                type="number"
                className="border border-grays rounded-xl py-1 px-2 w-full text-sm lg:text-base"
              />
              {errors.price?.message && (
                <FormError errorMessage={errors.price.message} />
              )}
            </div>

            <div className="space-y-3">
              <p className="font-bold text-main lg:text-base text-sm">할인율</p>
              <input
                {...register("discountRate", {
                  required: `할인율을 입력해주세요`,
                  max: { value: 100, message: `최댓값은 100입니다` },
                  min: { value: 0, message: `최소값은 0입니다` },
                })}
                type="number"
                className="border border-grays rounded-xl py-1 px-2 w-full text-sm lg:text-base"
                defaultValue={1}
              />
              {errors.discountRate?.message && (
                <FormError errorMessage={errors.discountRate.message} />
              )}
            </div>

            <div className="space-y-3">
              <p className="font-bold text-main lg:text-base text-sm">
                수업 카테고리
              </p>
              <select
                {...register(`productCategory`)}
                className="border border-grays rounded-xl py-2 px-2 w-full text-sm lg:text-base"
              >
                {Object(ProductCategoryOption).map(
                  (
                    productCategory: ProductCategoryOptionType,
                    index: number
                  ) => (
                    <option
                      key={`product-category-option-${index}`}
                      value={productCategory.value}
                    >
                      {productCategory.label}
                    </option>
                  )
                )}
              </select>
              {errors.productCategory?.message && (
                <FormError errorMessage={errors.productCategory.message} />
              )}
            </div>

            <div className="space-y-3">
              <p className="font-bold text-main lg:text-base text-sm">준비물</p>
              <input
                type="text"
                {...register("materials", {
                  required: `준비물은 필수 정보입니다.`,
                })}
                className="border border-grays rounded-xl py-1 px-2 w-full text-sm lg:text-base"
              />
              {errors.materials?.message && (
                <FormError errorMessage={errors.materials.message} />
              )}
            </div>

            <div className="space-y-3">
              <p className="font-bold text-main lg:text-base text-sm">지역</p>
              <select
                {...register("localCategory")}
                className="inline-block py-1 rounded-none border-b border-[#F1F2EF] focus:outline-none pl-1 leading-normal w-full text-xs lg:text-base"
              >
                {Object(LocalCategoryOption).map(
                  (localCategory: LocalCategoryOptionType, index: number) => (
                    <option
                      key={`local-category-option-${index}`}
                      value={localCategory.value}
                    >
                      {localCategory.label}
                    </option>
                  )
                )}
              </select>
            </div>

            <div className="space-y-3">
              <p className="font-bold text-main lg:text-base text-sm">
                수업방식
              </p>
              <div>
                <label className="inline-block align-middle w-1/2">
                  <div
                    className={`inline-flex flex-wrap content-center justify-center align-middle w-5 h-5 border-2 border-grays rounded-full ${
                      onoff === 0 && "border-main"
                    }`}
                  >
                    {onoff === 0 && (
                      <span className="block w-3 h-3 rounded-full bg-main"></span>
                    )}
                  </div>
                  <input
                    type="radio"
                    {...register("productType")}
                    className="hidden"
                    defaultValue={ProductType.Online}
                    onChange={() => setOnOff(0)}
                  />
                  <span className="ml-1 text-sm font-bold">온라인</span>
                </label>
                <label className="inline-block align-middle w-1/2">
                  <div
                    className={`inline-flex flex-wrap content-center justify-center align-middle w-5 h-5 border-2 border-grays rounded-full ${
                      onoff === 1 && "border-main"
                    }`}
                  >
                    {onoff === 1 && (
                      <span className="block w-3 h-3 rounded-full bg-main"></span>
                    )}
                  </div>
                  <input
                    type="radio"
                    {...register("productType")}
                    className="hidden"
                    defaultValue={ProductType.Offline}
                    onChange={() => setOnOff(1)}
                  />
                  <span className="ml-1 text-sm font-bold">오프라인</span>
                </label>
              </div>
            </div>

            {onoff === 1 && (
              <div className="space-y-3">
                <p className="font-bold text-main lg:text-base text-sm">
                  진행일자
                </p>
                <input
                  type="date"
                  {...register("scheduledAt")}
                  max="9999-12-31"
                  className="border border-grays rounded-xl py-1 px-2 w-full text-sm lg:text-base"
                />
              </div>
            )}

            {onoff === 1 && (
              <div className="space-y-3">
                <p className="font-bold text-main lg:text-base text-sm">
                  진행장소
                </p>
                <input
                  {...register("place", { required: `타이틀을 입력해주세요` })}
                  type="text"
                  className="border border-grays rounded-xl py-1 px-2 w-full text-sm lg:text-base"
                />
                {errors.place?.message && (
                  <FormError errorMessage={errors.place.message} />
                )}
              </div>
            )}

            {onoff === 1 && (
              <div className="space-y-3">
                <p className="font-bold text-main lg:text-base text-sm">
                  초대인원
                </p>
                <input
                  {...register("max", {
                    required: `최대 참석 인원을 입력해주세요`,
                  })}
                  type="number"
                  className="border border-grays rounded-xl py-1 px-2 w-full text-sm lg:text-base"
                  defaultValue={0}
                />
                {errors.max?.message && (
                  <FormError errorMessage={errors.max.message} />
                )}
              </div>
            )}

            {onoff === 1 && (
              <div className="space-y-3">
                <p className="font-bold text-main lg:text-base text-sm">
                  진행시간
                </p>
                <input
                  type="text"
                  {...register("playTime", {
                    required: `진행시간은 필수 정보입니다.`,
                  })}
                  className="border border-grays rounded-xl py-1 px-2 w-full text-sm lg:text-base"
                />
                {errors.playTime?.message && (
                  <FormError errorMessage={errors.playTime.message} />
                )}
              </div>
            )}

            <div className="space-y-3">
              <p className="font-bold text-main lg:text-base text-sm">요약</p>
              <textarea
                {...register("summary", { required: `타이틀을 입력해주세요` })}
                className="border border-grays rounded-xl py-1 px-2 w-full text-sm lg:text-base min-h-[200px]"
              ></textarea>
              {errors.summary?.message && (
                <FormError errorMessage={errors.summary.message} />
              )}
            </div>

            <div className="text-center py-10 space-x-5">
              <button className="rounded-md border border-grays w-2/5 sm:w-[150px] py-1 hover:opacity-60 text-sm lg:text-base ">
                취소
              </button>
              <Button
                className={`rounded-md text-white w-2/5 sm:w-[150px] py-1 hover:opacity-60 text-sm lg:text-base bg-main border border-main`}
                canClick={isValid}
                actionText={`저장`}
                loading={loading}
              />
            </div>
          </div>
        </form>
      )}
    </div>
  );
};
