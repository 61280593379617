import React from "react";
import { gql, useLazyQuery } from "@apollo/client";
import { listDoor, listDoorVariables } from "../__generated__/listDoor";
import { DOOR_LIST_FRAGMENT } from "../fragment-door";
import {
  listDoorRefund,
  listDoorRefundVariables,
} from "../__generated__/listDoorRefund";

const LIST_DOOR_REFUND_QUERY = gql`
  query listDoorRefund($input: ListDoorRefundInput!) {
    listDoorRefund(input: $input) {
      ok
      err
      totalPages
      totalResults
      row
      doors {
        ...DoorListParts
      }
    }
  }
  ${DOOR_LIST_FRAGMENT}
`;

export const useListDoorRefund = (
  onCompleted?: (data: listDoorRefund) => void
) => {
  return useLazyQuery<listDoorRefund, listDoorRefundVariables>(
    LIST_DOOR_REFUND_QUERY,
    {
      onCompleted,
      fetchPolicy: "network-only",
    }
  );
};
