import React, { useEffect, useState } from "react";
import { createBrowserHistory } from "history";
import { useParams } from "react-router";
import {
  DelPromotionProductInput,
  ListPromotionProductInput,
} from "../__generated__/globalTypes";
import { useListPromotionProduct } from "../hooks/useListPromotionProduct";
import { TableError } from "../components/table-error";
import { PaginationComponent } from "../components/pagination";
import { UpDiscountRateForm } from "../components/up-discount-rate-form";
import { delPromotionProduct } from "../__generated__/delPromotionProduct";
import { useDelPromotionProduct } from "../hooks/useDelPromotionProduct";
import { TextButton } from "../components/button";
import { Modal } from "@mui/material";
import { ModalListClass } from "../components/modal-list-class";

export const SubAdminUpPromotionProduct: React.FC = () => {
  const history = createBrowserHistory();
  const params = useParams();
  const [page, setPage] = useState<number>(1);

  const word = "클래스";

  const pageHandler = (page: number) => {
    setPage(page);
    if (params.promotionId)
      listProductPromotionHandler(+params.promotionId, page);
  };

  const [listProductPromotion, { data, loading, refetch }] =
    useListPromotionProduct();
  const listProductPromotionHandler = (promotionId: number, page: number) => {
    const input: ListPromotionProductInput = {
      promotionId,
      page,
    };
    listProductPromotion({ variables: { input } });
  };

  const onDelete = async (data: delPromotionProduct) => {
    const {
      delPromotionProduct: { ok, err },
    } = data;
    if (ok) {
      alert("제거되었습니다");
      await refetch();
    } else {
      alert(err);
    }
  };
  const [delPromotionProduct, { loading: delPromotionProductLoading }] =
    useDelPromotionProduct(onDelete);
  const delPromotionProductHandler = (promotionProductIdToDel: number) => {
    const input: DelPromotionProductInput = {
      promotionProductIdToDel,
    };
    delPromotionProduct({ variables: { input } });
  };

  useEffect(() => {
    if (!params.promotionId) return;
    listProductPromotionHandler(+params.promotionId, page);
  }, []);

  const [modal, setModal] = useState<boolean>(false);
  const setModalHandler = () => {
    setModal(!modal);
  };
  const refetchHandler = () => {
    refetch();
    setModalHandler();
  };

  return !loading ? (
    <>
      <div className="bg-white p-5 rounded-2xl shadow-lg">
        <p className="text-main font-bold pb-3">프로모션 클래스 목록</p>
        <p
          className={`float-right py-2 cursor-pointer hover:underline`}
          onClick={setModalHandler}
        >
          추가하기
        </p>
        <table className="w-full">
          <colgroup>
            <col className="w-1/12" />
            <col className="w-3/12" />
            <col className="w-1/12" />
            <col className="w-1/12" />
            <col className="w-2/12" />
            <col className="w-2/12" />
            <col className="w-2/12" />
          </colgroup>
          <thead>
            <tr className="bg-main  border-gray-300">
              <th className="text-white ">번호</th>
              <th className="text-white ">클래스</th>
              <th className="text-white ">작가</th>
              <th className="text-white ">정가</th>
              <th className="text-white ">할인율</th>
              <th className="text-white ">할인가</th>
              <th className="text-white ">기능</th>
            </tr>
          </thead>
          <tbody>
            {!loading &&
              data?.listPromotionProduct.promotionProducts &&
              data.listPromotionProduct.promotionProducts.map(
                (promotionProduct) => (
                  <tr
                    key={`magazine-${promotionProduct?.id}`}
                    className="border-b-2 border-gray-300 h-4"
                  >
                    <td className="text-sm text-center py-1 px-1 h-full overflow-hidden">
                      {promotionProduct?.index && promotionProduct.index}
                    </td>
                    <td className="text-sm text-center py-1 px-1 h-full overflow-hidden">
                      {promotionProduct?.product?.productInfo &&
                        promotionProduct.product.productInfo.title}
                    </td>
                    <td className="text-sm text-center py-1 px-1 h-full overflow-hidden">
                      {promotionProduct?.product?.inst.user?.name &&
                        promotionProduct.product.inst.user.name}
                    </td>
                    <td className="text-sm text-center py-1 px-1 h-full overflow-hidden">
                      {promotionProduct?.product?.productInfo &&
                        promotionProduct.product.productInfo.price?.toLocaleString()}
                    </td>
                    <td className="text-sm text-center h-full relative">
                      <UpDiscountRateForm
                        promotionProductIdToUp={promotionProduct.id}
                        defaultDiscountRate={promotionProduct.discountRate}
                        refetch={refetch}
                      />
                    </td>
                    <td className="text-sm text-center py-1 px-1 h-full overflow-hidden">
                      {promotionProduct?.product?.productInfo?.price &&
                        (
                          (promotionProduct.product.productInfo.price *
                            (100 - promotionProduct.discountRate)) /
                          100
                        ).toLocaleString()}
                    </td>
                    <td className="text-sm text-center h-full relative">
                      <TextButton
                        canClick={!delPromotionProductLoading}
                        actionText={`제거`}
                        onClick={() =>
                          delPromotionProductHandler(promotionProduct.id)
                        }
                      />
                    </td>
                  </tr>
                )
              )}
          </tbody>
        </table>
        {loading && <TableError errorMessage={`정보를 불러오는 중입니다`} />}
        {!loading &&
          data?.listPromotionProduct.promotionProducts &&
          data.listPromotionProduct.promotionProducts.length < 1 && (
            <TableError errorMessage={`해당하는 ${word} 정보가 없습니다`} />
          )}
        {!loading &&
          data?.listPromotionProduct.promotionProducts &&
          data.listPromotionProduct.promotionProducts.length > 0 && (
            <PaginationComponent
              page={page}
              totalPages={Number(data.listPromotionProduct.totalPages)}
              totalResults={Number(data.listPromotionProduct.totalResults)}
              pageHandler={pageHandler}
            />
          )}
      </div>
      <Modal open={modal} onClose={setModalHandler}>
        <div className={`modal`}>
          <ModalListClass
            promotionId={params && Number(params.promotionId)}
            onClose={refetchHandler}
          />
        </div>
      </Modal>
    </>
  ) : (
    <div className="bg-white p-5 rounded-2xl shadow-lg text-center">{`데이터를 불러오는 중입니다`}</div>
  );
};
