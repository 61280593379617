import { ApolloError, gql, useMutation } from "@apollo/client";
import React from "react";
import { delConsult, delConsultVariables } from "../__generated__/delConsult";

const DEL_CONSULT_MUTATION = gql`
  mutation delConsult($input: DelConsultInput!) {
    delConsult(input: $input) {
      ok
      err
    }
  }
`;

export const useDelConsult = (
  onCompleted?: (data: delConsult) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<delConsult, delConsultVariables>(DEL_CONSULT_MUTATION, {
    onCompleted,
    onError,
  });
};
