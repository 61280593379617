import { faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal } from "@mui/material";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { upload } from "../functions/uploads";
import { useUpBannerOption } from "../hooks/useUpBannerOption";
import { ProductType, UpBannerOptionInput } from "../__generated__/globalTypes";
import { upBannerOption } from "../__generated__/upBannerOption";
import { Button } from "./button";
import { ModalListMagazine } from "./modal-list-magazine";
import { ModalListPlace } from "./modal-list-place";

interface IUpBannerOptionFormProps {
  bannerOptionId: number;
  link?: string;
  productType: ProductType | null;
  productId?: number;
  productTitle?: string;
  instName?: string;
  posterUrl?: string;
  bannerTitle: string;
  resolution?: string;
  refetch: () => void;
}

export const UpBannerOptionForm2_1: React.FC<IUpBannerOptionFormProps> = ({
  bannerOptionId,
  link,
  bannerTitle,
  resolution,
  productType,
  posterUrl,
  productId,
  productTitle,
  instName,
  refetch,
}: IUpBannerOptionFormProps) => {
  const [uploading, setUploading] = useState<boolean>(false);
  const [modal, setModal] = useState<boolean>(false);

  const setModalHandler = () => {
    if (modal) {
      setModal(false);
      return;
    }
    if (!modal) {
      setModal(true);
      return;
    }
  };

  const {
    getValues,
    handleSubmit,
    register,
    formState: { isValid, errors },
  } = useForm<IUpBannerOptionFormProps>();

  const onUpCompleted = async (data: upBannerOption) => {
    const {
      upBannerOption: { ok },
    } = data;
    if (ok) {
      refetch();
      setModal(false);
      alert("저장되었습니다");
    }
    setUploading(false);
  };
  const [upBannerOption, { loading }] = useUpBannerOption(onUpCompleted);
  const onSubmit = () => {
    const { link } = getValues();
    const input: UpBannerOptionInput = {
      bannerOptionIdToUp: bannerOptionId,
      link,
    };
    upBannerOption({ variables: { input } });
  };
  const upBannerOptionProductHandler = (productId: number) => {
    const input: UpBannerOptionInput = {
      bannerOptionIdToUp: bannerOptionId,
      productId,
    };
    upBannerOption({ variables: { input } });
  };
  const upBannerOptionPosterHandler = (
    bannerOptionIdToUp: number,
    viewUuid: string
  ) => {
    const input: UpBannerOptionInput = {
      bannerOptionIdToUp,
      viewUuid,
    };
    upBannerOption({ variables: { input } });
  };
  const uploadBannerOptionHandler = async (
    event: any,
    bannerOptionIdToUp: number
  ) => {
    if (uploading) {
      alert("파일을 업로드 중입니다");
      return;
    }
    if (!loading) {
      setUploading(true);
      const result = await upload(event);

      if (!bannerOptionIdToUp) {
        alert("포스터 정보가 잘못되었습니다");
        return;
      }
      if (result.data.ok) {
        upBannerOptionPosterHandler(bannerOptionIdToUp, result.data.file.uuid);
      } else {
        alert(
          `잘못된 파일이거나 사용할 수 없는 파일입니다. 문제가 반복해서 발생할 시 업체에 문의해주세요`
        );
      }
    }
  };
  const upBannerOptionSubPosterHandler = (
    bannerOptionIdToUp: number,
    subViewUuid: string
  ) => {
    const input: UpBannerOptionInput = {
      bannerOptionIdToUp,
      subViewUuid,
    };
    upBannerOption({ variables: { input } });
  };
  const uploadSubBannerOptionHandler = async (
    event: any,
    bannerOptionIdToUp: number
  ) => {
    if (uploading) {
      alert("파일을 업로드 중입니다");
      return;
    }
    if (!loading) {
      setUploading(true);
      const result = await upload(event);

      if (!bannerOptionIdToUp) {
        alert("포스터 정보가 잘못되었습니다");
        return;
      }
      if (result.data.ok) {
        upBannerOptionSubPosterHandler(
          bannerOptionIdToUp,
          result.data.file.uuid
        );
      } else {
        alert(
          `잘못된 파일이거나 사용할 수 없는 파일입니다. 문제가 반복해서 발생할 시 업체에 문의해주세요`
        );
      }
    }
  };

  const delBannerOptionPosterHandler = (bannerOptionIdToUp: number) => {
    const input: UpBannerOptionInput = {
      bannerOptionIdToUp,
      viewUuid: "null",
    };
    upBannerOption({ variables: { input } });
  };

  const delBannerOptionProductHandler = (bannerOptionIdToUp: number) => {
    const input: UpBannerOptionInput = {
      bannerOptionIdToUp,
      productId: 0,
    };
    upBannerOption({ variables: { input } });
  };

  return (
    <>
      <div>
        <p className="font-bold text-main text-lg">
          {`${bannerTitle}`}{" "}
          <span className="ml-5 text-xs text-black">{`${resolution}`}</span>
        </p>
        {productType && (
          <div className={`w-full grid grid-cols-12 py-2 items-center`}>
            <div className="col-span-2 text-center">{`${
              Boolean(productId) ? `${productId}` : ""
            }`}</div>
            <div className="col-span-5">{`${
              Boolean(productTitle)
                ? `${productTitle}`
                : "선택된 정보가 없습니다"
            }`}</div>
            <div className="col-span-2 text-center">{`${
              Boolean(instName) ? `${instName}` : ""
            }`}</div>
            <div
              className="col-span-1 text-center"
              onClick={() => delBannerOptionProductHandler(bannerOptionId)}
            >
              <FontAwesomeIcon
                className={`${
                  Boolean(productId) ? `cursor-pointer` : `pointer-events-none`
                } `}
                icon={faX}
              />
            </div>
            <div className="text-center col-span-2">
              <button
                className={`rounded-md text-white w-2/5 sm:w-[150px] py-1 hover:opacity-60 text-sm lg:text-base bg-main border border-main`}
                onClick={setModalHandler}
              >
                선택하기
              </button>
            </div>
          </div>
        )}
        {!productType && (
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="w-full grid grid-cols-12 py-2">
              <div className={`col-span-2 place-self-center text-center`}>
                <label>링크</label>
              </div>
              <div className="col-span-8">
                <input
                  {...register("link")}
                  type="text"
                  defaultValue={link !== "null" ? link : ""}
                  className="border border-grays rounded-md py-1 px-2 w-full text-sm lg:text-base"
                />
              </div>
              <div className={`col-span-2 place-self-center text-center`}>
                <Button
                  className={`rounded-md text-white w-2/5 sm:w-[150px] py-1 hover:opacity-60 text-sm lg:text-base bg-main border border-main`}
                  canClick={isValid}
                  actionText={`저장`}
                  loading={loading}
                  color={"text-white"}
                />
              </div>
            </div>
          </form>
        )}
        <div
          key={`banner-poster-${bannerOptionId}`}
          className={`w-full relative mb-10 aspect-[2/1]`}
        >
          <button
            className={`absolute top-0 right-0`}
            onClick={() => delBannerOptionPosterHandler(bannerOptionId)}
          >
            <FontAwesomeIcon icon={faX} />
          </button>
          <label
            htmlFor={`poster-${bannerOptionId}`}
            className={`flex flex-wrap content-center justify-center hover:opacity-60 absolute left-0 top-0 w-full h-full ${
              productType ? `pointer-events-none` : `cursor-pointer`
            } bg-gray-300 border border-grays mt-8`}
          >
            <i className="fas fa-plus text-5xl text-white"></i>
            <img
              src={`${posterUrl ? posterUrl : ""}`}
              alt={`포스터${bannerOptionId}`}
              className={`absolute left-0 top-0 w-full h-full ${
                !Boolean(posterUrl) ? "hidden" : ""
              } `}
            />
          </label>
          <input
            type="file"
            className="hidden"
            accept="image/png, image/jpeg, image/jpg"
            id={`poster-${bannerOptionId}`}
            onChange={(event: any) =>
              uploadBannerOptionHandler(event, bannerOptionId)
            }
          />
        </div>
      </div>
      {productType && productType === ProductType.Magazine && (
        <Modal open={modal} onClose={setModalHandler}>
          <div className={`modal`}>
            <ModalListMagazine onSelect={upBannerOptionProductHandler} />
          </div>
        </Modal>
      )}
      {productType && productType === ProductType.Place && (
        <Modal open={modal} onClose={setModalHandler}>
          <div className={`modal`}>
            <ModalListPlace onSelect={upBannerOptionProductHandler} />
          </div>
        </Modal>
      )}
    </>
  );
};
