import React, { useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import {
  GetProductPageInput,
  UpProductPageInput,
} from "../__generated__/globalTypes";
import { upload } from "../functions/uploads";
import { getProductPage } from "../__generated__/getProductPage";
import { useGetProductPage } from "../hooks/useGetProductPage";
import {
  IUpProductPageFormProps,
  useUpProductPage,
} from "../hooks/useUpProductPage";
import ReactQuill from "react-quill";
import { upProductPage } from "../__generated__/upProductPage";
import { useParams } from "react-router";

export const SubAdminUpMagazinePage: React.FC = () => {
  const params: any = useParams();
  const [called, setCalled] = useState<boolean>(false);
  const onGetCompleted = (data: getProductPage) => {
    if (called) {
      setValue("page", data.getProductPage.productPage?.page);
      setCalled(false);
    }
  };

  const onUpCompleted = async (data: upProductPage) => {
    const {
      upProductPage: { ok, err },
    } = data;
    if (ok) {
      await refetch();
      alert("상세정보가 저장되었습니다");
    } else {
      alert(err);
    }
  };

  const [
    getProductPage,
    { data: getProductPageData, loading: getProductPageLoading, refetch },
  ] = useGetProductPage(onGetCompleted);

  const { getValues, setValue, watch } = useForm<IUpProductPageFormProps>({
    mode: "all",
  });

  const [upProductPage, { data: upProductPageResult, loading }] =
    useUpProductPage(onUpCompleted);

  const onSubmit = () => {
    let { page } = getValues();
    const productPageIdToUp: number = getProductPageData?.getProductPage
      .productPage?.id
      ? getProductPageData?.getProductPage.productPage?.id
      : 0;
    if (productPageIdToUp === 0) {
      alert("강의정보를 수정할 수 없습니다.");
      return;
    }
    const upProductPageInput: UpProductPageInput = {
      ...(page &&
        getProductPageData?.getProductPage.productPage?.page !== page && {
          page,
        }),
      productPageIdToUp,
    };
    upProductPage({
      variables: {
        input: upProductPageInput,
      },
    });
    setCalled(true);
  };

  const editorContent = watch("page");

  const editorStateHandler = (editorState: any) => {
    setValue("page", editorState);
  };

  const QuillRef = useRef<ReactQuill>();

  const imageHandler = () => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async (event: any) => {
      const file = input.files;
      if (!Boolean(file)) return;

      const result = await upload(event);
      if (result.data.ok) {
        const url = result.data.file.url;

        const range = QuillRef?.current?.getEditor().getSelection()?.index;
        if (range !== null && range !== undefined) {
          let quill = QuillRef.current?.getEditor();
          quill?.setSelection(range, 1);
          quill?.clipboard.dangerouslyPasteHTML(
            range,
            `<img src=${url} alt="이미지" />`
          );
        }
      } else {
        alert(
          `잘못된 파일이거나 사용할 수 없는 파일입니다. 문제가 반복해서 발생할 시 업체에 문의해주세요`
        );
      }
    };
  };

  useEffect(() => {
    if (params.productId) {
      const getProductPageInput: GetProductPageInput = {
        productId: +params.productId,
      };
      getProductPage({ variables: { input: getProductPageInput } });
      refetch();
      setCalled(true);
    }
  }, []);

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          ["bold", "italic", "underline", "strike"], // toggled buttons
          ["blockquote"],
          [{ size: ["small", false, "large", "huge"] }],
          ["code-block"],
          [{ list: "ordered" }, { list: "bullet" }],
          [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          [{ color: [] }, { background: [] }], // dropdown with defaults from theme
          [{ font: [] }],
          [{ align: [] }],
          ["link", "image", "video"],
          ["clean"], // remove formatting button
        ],
        handlers: {
          image: imageHandler,
        },
      },
    }),
    []
  );

  useEffect(() => {
    if (params.key) {
      const getProductPageInput: GetProductPageInput = {
        productId: +params.key,
      };
      getProductPage({ variables: { input: getProductPageInput } });
      refetch();
      setCalled(true);
    }
  }, []);

  return (
    <div className="bg-white p-5 rounded-2xl shadow-lg">
      <div className="p-3 pt-0 text-main font-bold text-xl">상세페이지</div>
      <div className="w-full">
        {!getProductPageLoading && (
          <>
            <ReactQuill
              placeholder="상세페이지를 작성해주세요"
              className="w-full h-[450px] "
              ref={(element) => {
                if (element !== null) {
                  QuillRef.current = element;
                }
              }}
              modules={modules}
              value={editorContent ? editorContent : ""}
              onChange={editorStateHandler}
              defaultValue={
                getProductPageData?.getProductPage.productPage?.page
                  ? getProductPageData.getProductPage.productPage.page
                  : ""
              }
            ></ReactQuill>
            <div className="text-center pt-28 space-x-5">
              <button className="rounded-md border border-grays w-[150px] py-1 hover:opacity-60">
                취소
              </button>
              <button
                onClick={onSubmit}
                className="rounded-md text-white w-[150px] py-1 hover:opacity-60 bg-main border border-main"
              >
                저장
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
