import React, { useEffect } from "react";
import payment from "../assets/icon/payment.svg";
import instructor from "../assets/icon/instructor.svg";
import lecture from "../assets/icon/lecture.svg";
import curator from "../assets/icon/consultant.svg";
import promotion from "../assets/icon/promotion.svg";
import dashboard from "../assets/icon/dashboard.svg";
import user from "../assets/icon/user.svg";
import change from "../assets/icon/change.svg";
import { Link } from "react-router-dom";
import { pageIndicator } from "../apollo";
import { useReactiveVar } from "@apollo/client";
import { useMe } from "../hooks/useMe";
import { UserRole } from "../__generated__/globalTypes";

export const AsideAdminComponent: React.FC = () => {
  const { data: me, loading } = useMe();

  const usePageIndicator = useReactiveVar(pageIndicator);
  return (
    <div className="pt-20">
      {!loading && (
        <div className="consoleBox border-t-2 border-[#C9CACA]">
          <div>
            <ul>
              {/* {me?.me.role === UserRole.Admin && (
                <li className="border-b-2 border-[#C9CACA]">
                  <Link
                    className={`py-2 w-full h-full text-left hover:bg-gray-100 px-16 block relative ${
                      usePageIndicator === "dashboard" && "bg-gray-100"
                    }`}
                    to={`/admin`}
                  >
                    <img
                      src={dashboard}
                      alt="대시보드"
                      className="inline-block mr-2 w-6"
                    />
                    대시보드
                  </Link>
                </li>
              )} */}
              {me?.me.role === UserRole.Curator && (
                <li className="border-b-2 border-[#C9CACA]">
                  <Link
                    className={`py-2 w-full h-full text-left hover:bg-gray-100 px-16 block relative ${
                      usePageIndicator === "curator" && "bg-gray-100"
                    }`}
                    to={`/curator`}
                  >
                    <img
                      src={dashboard}
                      alt="컨설팅"
                      className="inline-block mr-2 w-6"
                    />
                    컨설팅목록
                  </Link>
                </li>
              )}
              {me?.me.role === UserRole.Admin && (
                <li className="border-b-2 border-[#C9CACA]">
                  <Link
                    className={`py-2 w-full h-full text-left hover:bg-gray-100 px-16 block ${
                      usePageIndicator === `banner` && "bg-gray-100"
                    }
                    ${usePageIndicator === `dashboard` && "bg-gray-100"}
                    `}
                    to={`/admin/banner`}
                  >
                    <img
                      src={user}
                      alt="회원 아이콘"
                      className="inline-block mr-2 w-6"
                    />
                    배너 관리
                  </Link>
                </li>
              )}
              {me?.me.role === UserRole.Admin && (
                <li className="border-b-2 border-[#C9CACA]">
                  <Link
                    className={`py-2 w-full h-full text-left hover:bg-gray-100 px-16 block ${
                      usePageIndicator === `promotion` && "bg-gray-100"
                    }`}
                    to={`/admin/promotion`}
                  >
                    <img
                      src={promotion}
                      alt="프로모션 관리"
                      className="inline-block mr-2 w-6"
                    />
                    프로모션 관리
                  </Link>
                </li>
              )}
              {me?.me.role === UserRole.Admin && (
                <li className="border-b-2 border-[#C9CACA]">
                  <Link
                    className={`py-2 w-full h-full text-left hover:bg-gray-100 px-16 block ${
                      usePageIndicator === `curator` && "bg-gray-100"
                    }`}
                    to={`/admin/curators`}
                  >
                    <img
                      src={curator}
                      alt="매니저 아이콘"
                      className="inline-block mr-2 w-6"
                    />
                    큐레이터 관리
                  </Link>
                </li>
              )}

              <li className="border-b-2 border-[#C9CACA]">
                <Link
                  className={`py-2 w-full h-full text-left hover:bg-gray-100 px-16 block ${
                    usePageIndicator === `insts` && "bg-gray-100"
                  }`}
                  to={`/admin/insts?page=1`}
                >
                  <img
                    src={instructor}
                    alt="작가 관리"
                    className="inline-block mr-2 w-6"
                  />
                  작가 관리
                </Link>
              </li>

              <li className="border-b-2 border-[#C9CACA]">
                <Link
                  className={`py-2 w-full h-full text-left hover:bg-gray-100 px-16 block ${
                    usePageIndicator === `lectures` && "bg-gray-100"
                  }`}
                  to={`/admin/lectures`}
                >
                  <img
                    src={lecture}
                    alt="강의 관리"
                    className="inline-block mr-2 w-6"
                  />
                  강의 관리
                </Link>
              </li>
              {me?.me.role === UserRole.Admin && (
                <li className="border-b-2 border-[#C9CACA]">
                  <Link
                    className={`py-2 w-full h-full text-left hover:bg-gray-100 px-16 block ${
                      usePageIndicator === `magazine` && "bg-gray-100"
                    }`}
                    to={`/admin/magazine`}
                  >
                    <img
                      src={lecture}
                      alt="매거진 관리"
                      className="inline-block mr-2 w-6"
                    />
                    매거진
                  </Link>
                </li>
              )}
              {me?.me.role === UserRole.Admin && (
                <li className="border-b-2 border-[#C9CACA]">
                  <Link
                    className={`py-2 w-full h-full text-left hover:bg-gray-100 px-16 block ${
                      usePageIndicator === `place` && "bg-gray-100"
                    }`}
                    to={`/admin/place`}
                  >
                    <img
                      src={lecture}
                      alt="플레이스 관리"
                      className="inline-block mr-2 w-6"
                    />
                    플레이스
                  </Link>
                </li>
              )}

              {me?.me.role === UserRole.Admin && (
                <li className="border-b-2 border-[#C9CACA]">
                  <Link
                    className={`py-2 w-full h-full text-left hover:bg-gray-100 px-16 block ${
                      usePageIndicator === `user` && "bg-gray-100"
                    }`}
                    to={`/admin/user`}
                  >
                    <img
                      src={user}
                      alt="회원 아이콘"
                      className="inline-block mr-2 w-6"
                    />
                    회원 관리
                  </Link>
                </li>
              )}
              {me?.me.role === UserRole.Admin && (
                <li className="border-b-2 border-[#C9CACA]">
                  <Link
                    className={`py-2 w-full h-full text-left hover:bg-gray-100 px-16 block ${
                      usePageIndicator === `payments` && "bg-gray-100"
                    }`}
                    to={`/admin/payments`}
                  >
                    <img
                      src={payment}
                      alt="결제관리 아이콘"
                      className="inline-block mr-2 w-6"
                    />
                    결제 관리
                  </Link>
                </li>
              )}

              {me?.me.role === UserRole.Admin && (
                <li className="border-b-2 border-[#C9CACA]">
                  <Link
                    className={`py-2 w-full h-full text-left hover:bg-gray-100 px-16 block ${
                      usePageIndicator === `bill` && "bg-gray-100"
                    }`}
                    to={`/admin/bill`}
                  >
                    <img
                      src={payment}
                      alt="계산서관리 아이콘"
                      className="inline-block mr-2 w-6"
                    />
                    계산서 관리
                  </Link>
                </li>
              )}

              <li className="border-b-2 border-[#C9CACA]">
                <Link
                  className={`py-2 w-full h-full text-left hover:bg-gray-100 px-16 block ${
                    usePageIndicator === `profile` && "bg-gray-100"
                  }`}
                  to={`/admin/profile`}
                >
                  <img
                    src={change}
                    alt="정보 아이콘"
                    className="inline-block mr-2 w-6"
                  />
                  개인정보 수정
                </Link>
              </li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};
