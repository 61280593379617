import React, { useEffect, useState } from "react";
import { LayoutTopProps } from "../constants/props";
import logo from "../assets/icon/localpick_logo.svg";
import { useMe } from "../hooks/useMe";
import { signOut } from "../constants/token";

import logout from "../assets/icon/logout.svg";
export const AdminTop = ({ children }: LayoutTopProps) => {
  const { data } = useMe();
  const [nameTag, setNameTag] = useState<string>("");
  // adminTop name tag
  useEffect(() => {
    if (data?.me.role === "Admin") {
      setNameTag("관리자");
    } else {
      setNameTag(String(data?.me.name));
    }
  }, []);
  return (
    <>
      <div className="headerWrap h-24 w-full bg-transparent">
        <header
          id="header"
          className="w-[1440px] mx-auto py-3 h-full px-20 bg-white border-b border-[#e2e2e2]"
        >
          <a
            href="#main"
            className="absolute -left-full"
            title="본문으로 바로가기"
          >
            본문으로 바로가기
          </a>
          <div className="innerHeader flex flex-wrap content-center justify-between h-full relative">
            <div className="flex flexw-wrap justify-center gap-x-8">
              <h1 className="flex flex-wrap content-center">
                <a href="/admin">
                  <img
                    src={logo}
                    alt="로로, 로컬투 로컬 로고"
                    className="w-32"
                  />
                </a>
              </h1>
            </div>
            <div className="flex gap-x-8 flex-wrap justify-center content-center relative h-full ">
              <p className="text-black flex flex-wrap content-center pt-6">
                {nameTag}님
              </p>
              <a
                href="/admin"
                onClick={signOut}
                className=" text-top text-[15px] pt-6 font-bold block"
              >
                <img
                  src={logout}
                  alt="로그아웃"
                  className="inline-block w-3 mr-2"
                />
                Logout
              </a>
            </div>
          </div>
        </header>
      </div>
    </>
  );
};
