import { ApolloError, gql, useMutation } from "@apollo/client";
import React from "react";
import { UpPromotionInput } from "../__generated__/globalTypes";
import {
  upPromotion,
  upPromotionVariables,
} from "../__generated__/upPromotion";

export interface IUpPromotionFormProps extends UpPromotionInput {}

const UP_PROMOTION_MUTATION = gql`
  mutation upPromotion($input: UpPromotionInput!) {
    upPromotion(input: $input) {
      ok
      err
    }
  }
`;

export const useUpPromotion = (
  onCompleted?: (data: upPromotion) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<upPromotion, upPromotionVariables>(UP_PROMOTION_MUTATION, {
    onCompleted,
    onError,
  });
};
