import { useEffect, useState } from "react";
import { pageIndicator } from "../apollo";
import { AsideAdminComponent } from "../layout/aside-admin";
import { Footer } from "../layout/footer";
import { useForm } from "react-hook-form";
import { FormError2 } from "../components/form-error";
import {
  CategoryOption,
  CategoryOptionType,
  LocalCategoryOption,
  LocalCategoryOptionType,
} from "../constants/enum";
import { Button } from "../components/button";
import { ICrPlaceFormProps, useCrPlace } from "../hooks/useCrPlace";
import { crPlace } from "../__generated__/crPlace";
import { CrPlaceInput } from "../__generated__/globalTypes";
import { AdminTop } from "../layout/adminTop";
import { ITabLink, ComponentTabLink } from "../components/component-tab";
import { useNavigate } from "react-router-dom";

export const AdminCrPlace = () => {
  const history = useNavigate();
  useEffect(() => {
    pageIndicator("place");
  }, []);

  const tabList: ITabLink[] = [
    { text: "목록", to: "/admin/place" },
    { text: "글쓰기", to: "/admin/place/create", selected: true },
  ];

  const {
    getValues,
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<ICrPlaceFormProps>({ mode: "all" });

  const onComplete = async (data: crPlace) => {
    const {
      crPlace: { ok, err, productId },
    } = data;
    if (ok) {
      alert("저장되었습니다");
      window.location.href = `/admin/place/update/${productId}`;
    } else {
      alert(err);
    }
  };
  const [crPlace, { loading }] = useCrPlace(onComplete);
  const onSubmit = () => {
    if (!loading) {
      const { title, summary, category, place, playTime, fee, localCategory } =
        getValues();
      const crPlaceInput: CrPlaceInput = {
        title,
        summary,
        category,
        place,
        playTime,
        fee,
        localCategory,
      };
      crPlace({ variables: { input: crPlaceInput } });
    }
  };

  return (
    <div className="bg-main bg-opacity-50 w-full h-full min-h-screen">
      <div className="w-[1440px] mx-auto h-full min-h-screen  bg-white">
        <AdminTop title={"top"} children={"관리자모드"} />
        <main id="main">
          <div className="grid grid-cols-[300px_1fr]">
            <AsideAdminComponent />
            <div className="mypages ">
              <div className="lectureWrap">
                <ComponentTabLink tabs={tabList} />
                <div className=" bg-gray-100 h-fix-content relative">
                  <div className={`w-full h-full px-10 py-10`}>
                    {/* 여기 */}
                    <div className="bg-white p-5 rounded-2xl shadow-lg">
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <div className=" p-5 lg:p-10 space-y-5 lg:space-y-10 max-w-xl">
                          <div className="space-y-3">
                            <p className="font-bold text-main lg:text-base text-sm">
                              제목
                            </p>
                            <input
                              {...register("title", {
                                required: `제목을 입력해주세요`,
                              })}
                              type="text"
                              className="border border-grays rounded-xl py-1 px-2 w-full text-sm lg:text-base"
                            />
                            {errors.title?.message && (
                              <FormError2 errorMessage={errors.title.message} />
                            )}
                          </div>

                          <div className="space-y-3">
                            <p className="font-bold text-main lg:text-base text-sm">
                              플레이스 카테고리
                            </p>
                            <select
                              {...register(`category`, {
                                required: "카테고리를 선택해주세요",
                              })}
                              className="border border-grays rounded-xl py-1 px-2 w-full text-sm lg:text-base"
                            >
                              {Object(CategoryOption).map(
                                (
                                  category: CategoryOptionType,
                                  index: number
                                ) => (
                                  <option
                                    key={`product-category-option-${index}`}
                                    value={category.value}
                                  >
                                    {category.label}
                                  </option>
                                )
                              )}
                            </select>
                            {errors.category?.message && (
                              <FormError2
                                errorMessage={errors.category.message}
                              />
                            )}
                          </div>

                          <div className="space-y-3">
                            <p className="font-bold text-main lg:text-base text-sm">
                              진행장소
                            </p>
                            <input
                              {...register("place", {
                                required: `진행장소를 입력해주세요`,
                              })}
                              type="text"
                              className="border border-grays rounded-xl py-1 px-2 w-full text-sm lg:text-base"
                            />
                            {errors.place?.message && (
                              <FormError2 errorMessage={errors.place.message} />
                            )}
                          </div>

                          <div className="space-y-3">
                            <p className="font-bold text-main lg:text-base text-sm">
                              진행시간
                            </p>
                            <input
                              {...register("playTime", {})}
                              type="text"
                              className="border border-grays rounded-xl py-1 px-2 w-full text-sm lg:text-base"
                            />
                          </div>

                          <div className="space-y-3">
                            <p className="font-bold text-main lg:text-base text-sm">
                              이용료
                            </p>
                            <input
                              {...register("fee", {})}
                              type="text"
                              className="border border-grays rounded-xl py-1 px-2 w-full text-sm lg:text-base"
                            />
                          </div>

                          <div className="space-y-3">
                            <p className="font-bold text-main lg:text-base text-sm">
                              지역
                            </p>
                            <select
                              {...register("localCategory")}
                              className="inline-block py-1 rounded-none border-b border-[#F1F2EF] focus:outline-none pl-1 leading-normal w-full text-xs lg:text-base"
                            >
                              {Object(LocalCategoryOption).map(
                                (
                                  localCategory: LocalCategoryOptionType,
                                  index: number
                                ) => (
                                  <option
                                    key={`local-category-option-${index}`}
                                    value={localCategory.value}
                                  >
                                    {localCategory.label}
                                  </option>
                                )
                              )}
                            </select>
                          </div>

                          <div className="space-y-3 relative">
                            <p className="font-bold text-main lg:text-base text-sm">
                              요약
                            </p>
                            <textarea
                              {...register("summary", {
                                required: `타이틀을 입력해주세요`,
                              })}
                              className="border border-grays rounded-xl py-1 px-2 w-full text-sm lg:text-base min-h-[300px]"
                            ></textarea>
                            {errors.summary?.message && (
                              <FormError2
                                errorMessage={errors.summary.message}
                              />
                            )}
                          </div>

                          <div className="text-center py-10 space-x-5">
                            <button className="rounded-md border border-grays w-2/5 sm:w-[150px] py-1 hover:opacity-60 text-sm lg:text-base ">
                              취소
                            </button>
                            <Button
                              className={`rounded-md text-white w-2/5 sm:w-[150px] py-1 hover:opacity-60 text-sm lg:text-base bg-main border border-main`}
                              canClick={isValid}
                              actionText={`저장`}
                              loading={loading}
                              color={"text-white"}
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                    <Footer></Footer>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
