import { useState, useEffect } from "react";
import { Fade, Slide } from "@mui/material";
import { Edit_Password_ad } from "./parts/password";
import { Footer } from "../../../layout/footer";
import { Edit_Dataform_ad } from "./parts/dataform";
import { pageIndicator } from "../../../apollo";
import { AsideAdminComponent } from "../../../layout/aside-admin";
import { SubTop } from "../../../layout/subTop";
import { AdminTop } from "../../../layout/adminTop";
export const Change_ad = () => {
  const [tabs, setTabs] = useState(0);
  // reload
  useEffect(() => {
    pageIndicator("profile");
  }, []);
  const alertUser = (e: any) => {
    e.preventDefault();
    e.returnValue = "";
  };
  return (
    <div className="bg-main bg-opacity-50 w-full h-full min-h-screen">
      <div className="w-[1440px] mx-auto h-full min-h-screen  bg-white">
        <AdminTop title={"top"} />
        <main id="main">
          <div className="grid grid-cols-[300px_1fr]">
            <AsideAdminComponent />
            <div className="mypages ">
              <div className="lectureWrap">
                <div className="h-20">
                  <div className="subtitle h-full flex flex-wrap content-end px-10 gap-x-3">
                    <div
                      className={`inline-block  w-44 ${
                        tabs === 0 && "border-main border-b-2"
                      }`}
                    >
                      <button
                        className={`leading-normal font-bold hover:opacity-60 w-full py-1  ${
                          tabs === 0 ? "text-main" : "text-gray-400"
                        }`}
                        onClick={() =>
                          setTabs(() => {
                            return 0;
                          })
                        }
                      >
                        정보 수정
                      </button>
                    </div>
                    <div
                      className={`inline-block  w-44 ${
                        tabs === 1 && "border-main border-b-2"
                      }`}
                    >
                      <button
                        className={`leading-normal font-bold hover:opacity-60 w-full py-1  ${
                          tabs === 1 ? "text-main" : "text-gray-400"
                        }`}
                        onClick={() =>
                          setTabs(() => {
                            return 1;
                          })
                        }
                      >
                        비밀번호 변경
                      </button>
                    </div>
                  </div>
                </div>
                <div className=" bg-gray-100 h-fix-content relative overflow-hidden grid grid-rows-1">
                  <Fade in={tabs === 0}>
                    <div
                      className={`w-full h-full px-10 py-12  ${
                        tabs === 0 ? "static" : "absolute left-0 top-0"
                      })`}
                    >
                      <Edit_Dataform_ad />
                    </div>
                  </Fade>
                  <Fade in={tabs === 1}>
                    <div
                      className={`w-full h-full px-10 py-12 space-y-10 ${
                        tabs === 1 ? "static" : "absolute left-0 top-0"
                      })`}
                    >
                      <Edit_Password_ad />
                    </div>
                  </Fade>
                  <Footer></Footer>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
