import { Footer } from "../layout/footer";
import { LayoutTop } from "../layout/top";
import desk from "../assets/images/curator_desktop.png";
import mobile from "../assets/images/curator_mobile.png";

export const CuratorCollectJoin = () => {
  return (
    <>
      <LayoutTop title="nothing" />
      <main id="main">
        <div>
          <img
            src={desk}
            alt="로컬픽이란"
            className="max-w-[1920px] mx-auto w-full hidden sm:block"
          />
          <img
            src={mobile}
            alt="로컬픽이란"
            className="w-full block sm:hidden"
          />
        </div>
      </main>
      <Footer></Footer>
    </>
  );
};
