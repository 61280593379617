import { ApolloError, gql, useMutation } from "@apollo/client";
import React from "react";
import { checkEmail, checkEmailVariables } from "../__generated__/checkEmail";

const CHECK_EMAIL_MUTATION = gql`
  mutation checkEmail($input: CheckEmailInput!) {
    checkEmail(input: $input) {
      ok
      err
    }
  }
`;

export const useCheckEmail = (
  onCompleted?: (data: checkEmail) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<checkEmail, checkEmailVariables>(CHECK_EMAIL_MUTATION, {
    onCompleted,
    onError,
    fetchPolicy: "network-only",
  });
};
