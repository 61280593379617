import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import { upload } from "../../../../../functions/uploads";
import { useGetProductKit } from "../../../../../hooks/useGetProductKit";
import { useUpProductKit } from "../../../../../hooks/useUpProductKit";
import { getProductKit } from "../../../../../__generated__/getProductKit";
import {
  GetProductKitInput,
  UpProductKitInput,
} from "../../../../../__generated__/globalTypes";
import { upProductKit } from "../../../../../__generated__/upProductKit";

export interface IUpProductKitFormProps extends UpProductKitInput {}

export const Kit = () => {
  const params = useParams();

  const [called, setCalled] = useState<boolean>(false);
  const [originalProductKit, setOriginalProductKit] = useState<getProductKit>();
  const [imgSource, setImgSource] = useState<string | undefined | null>();

  const { register, handleSubmit, getValues, setValue, setFocus } =
    useForm<IUpProductKitFormProps>({ mode: "all" });

  const onCompleted = (data: getProductKit) => {
    if (params.key && called) {
      setFocus("desc");
      setOriginalProductKit(data);
      setCalled(false);
    }
  };

  const onUpCompleted = async (data: upProductKit) => {
    const {
      upProductKit: { ok },
    } = data;
    if (ok) {
      await refetch();
      alert(`저장되었습니다`);
    }
  };

  const onSubmit = () => {
    const originalProductKit = getProductKitData;
    if (
      !upProductKitLoading &&
      originalProductKit?.getProductKit.productKit?.id
    ) {
      let { desc, viewUuid } = getValues();
      const upProductKitInput: UpProductKitInput = {
        productKitIdToUp: originalProductKit?.getProductKit.productKit?.id,
        ...(originalProductKit?.getProductKit.productKit?.desc !== desc && {
          desc,
        }),
        ...(originalProductKit?.getProductKit.productKit?.view?.uuid !==
          viewUuid && { viewUuid }),
      };
      upProductKit({ variables: { input: upProductKitInput } });
    } else {
      alert(`수정할 수 없습니다`);
    }
  };

  const [upProductKit, { loading: upProductKitLoading }] =
    useUpProductKit(onUpCompleted);

  const [
    getProductKit,
    { data: getProductKitData, loading: getProductKitLoading, refetch },
  ] = useGetProductKit(onCompleted);

  const uploadProductKitViewHandler = async (event: any) => {
    const result = await upload(event);
    if (result.data.ok) {
      setImgSource(result.data.file.url);
      setValue("viewUuid", result.data.file.uuid);
    } else {
      alert(
        `잘못된 파일이거나 사용할 수 없는 파일입니다. 문제가 반복해서 발생할 시 업체에 문의해주세요`
      );
    }
  };

  useEffect(() => {
    if (params.key) {
      const getProductKitInput: GetProductKitInput = {
        productId: +params.key,
      };
      getProductKit({ variables: { input: getProductKitInput } });
      setCalled(true);
    }
  }, []);

  return (
    <div className="bg-white">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="p-10 space-y-10">
          <div className="pr-3">
            <p className="font-bold text-main">키트 관리</p>
            <div className="flex flex-wrap gap-x-3 gap-y-3 justify-center lg:justify-start">
              <div className="max-w-[300px] w-full inline-block">
                <div className="w-full cardImgRatio relative">
                  <label
                    htmlFor="kit1"
                    className="flex flex-wrap content-center justify-center hover:opacity-60 absolute left-0 top-0 w-full h-full cursor-pointer bg-gray-300 border border-grays overflow-hidden"
                  >
                    <i className="fas fa-plus text-5xl text-white"></i>
                    {imgSource ? (
                      <img
                        src={imgSource}
                        alt={`키트사진`}
                        className={`absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 h-full kit1 ${
                          imgSource ? "" : `hidden`
                        }`}
                      />
                    ) : (
                      <img
                        src={
                          getProductKitData?.getProductKit.productKit?.view?.url
                            ? getProductKitData?.getProductKit.productKit?.view
                                ?.url
                            : `#`
                        }
                        alt="키트사진1"
                        className={`absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 h-full kit1 ${
                          getProductKitData?.getProductKit.productKit?.view?.url
                            ? ""
                            : `hidden`
                        }`}
                      />
                    )}
                  </label>
                  <input
                    type="file"
                    className="hidden"
                    accept="image/png, image/jpeg, image/jpg"
                    id="kit1"
                    onChange={uploadProductKitViewHandler}
                  />
                </div>
              </div>
              <textarea
                {...register("desc")}
                placeholder="간단한 키트 설명을 넣어주세요!"
                className="block w-full max-w-[300px] max-h-[300px] h-32 sm:h-[300px] border resize-none p-2"
                defaultValue={
                  getProductKitData?.getProductKit.productKit?.desc
                    ? getProductKitData.getProductKit.productKit.desc
                    : ""
                }
              ></textarea>
            </div>
          </div>
          <div className="sm:bg-transparent bg-white w-full left-0 bottom-16 fixed sm:static  py-4 sm:pt-28 text-center sm:py-10 space-x-5">
            <button
              type={`button`}
              className="rounded-md border border-grays w-2/5 sm:w-[150px] py-1 hover:opacity-60 text-sm lg:text-base"
            >
              뒤로
            </button>
            <button className="rounded-md text-white w-2/5 sm:w-[150px] py-1 hover:opacity-60 text-sm lg:text-base bg-main border border-main">
              저장
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};
