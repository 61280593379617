import React from "react";
import { gql, useLazyQuery } from "@apollo/client";
import {
  listProductReview,
  listProductReviewVariables,
} from "../__generated__/listProductReview";

const LIST_PRODUCT_REVIEW_QUERY = gql`
  query listProductReview($input: ListProductReviewInput!) {
    listProductReview(input: $input) {
      ok
      err
      totalPages
      totalResults
      row
      productReviews {
        id
        createdAt
        is_private
        text
        author {
          id
          name
        }
        product {
          id
        }
        productRereviews {
          id
          is_private
          text
          author {
            id
            name
          }
        }
      }
    }
  }
`;

export const useListProductReview = (
  onCompleted?: (data: listProductReview) => void
) => {
  return useLazyQuery<listProductReview, listProductReviewVariables>(
    LIST_PRODUCT_REVIEW_QUERY,
    {
      onCompleted,
      fetchPolicy: `network-only`,
    }
  );
};
