import { gql } from "@apollo/client";
import React from "react";

export const GET_RECEIPT_FRAGMENT = gql`
  fragment GetReceiptParts on Receipt {
    id
    paid_amount
    paid_at
    status
    receiver
    phone
    address
    message
    createdAt
    pgOption {
      merchant_uid
      name
      pay_method
      receipt_url
      vbank_date
      vbank_holder
      vbank_name
      vbank_num
    }
    product {
      id
      inst {
        user {
          name
        }
      }
      productKit {
        desc
      }
    }
  }
`;

export const LIST_RECEIPT_FRAGMENT = gql`
  fragment ListReceiptParts on Receipt {
    id
    scheduledAt
    orderStatus
    createdAt
    status
    doors {
      status
      type
    }
    pgOption {
      name
    }
    product {
      id
      productInfo {
        scheduledAt
        title
        price
      }
      inst {
        id
        atelier {
          name
        }
        user {
          name
        }
      }
      createdAt
      productStatus
      productType
      productKit {
        id
        view {
          url
        }
        desc
      }
      productViews {
        index
        is_rep
        id
        view {
          url
        }
      }
    }
  }
`;
