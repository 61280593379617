import moment from "moment";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button } from "../components/button";
import { ComponentCrConsultReply } from "../components/component-cr-consult-reply";
import { ComponentUpConsultReply } from "../components/component-up-consult-reply";
import { TableError } from "../components/table-error";
import { useDelConsult } from "../hooks/useDelConsult";
import { useGetConsult } from "../hooks/useGetConsult";
import { useListConsultReply } from "../hooks/useListConsultReply";
import { useMe } from "../hooks/useMe";
import { IUpConsultFormProps, useUpConsult } from "../hooks/useUpConsult";
import { delConsult } from "../__generated__/delConsult";
import { getConsult } from "../__generated__/getConsult";
import {
  DelConsultInput,
  GetConsultInput,
  ListConsultReplyInput,
  UpConsultInput,
} from "../__generated__/globalTypes";
import { upConsult } from "../__generated__/upConsult";

interface IInstDetailConsultProps {
  consultId: number;
  setTabHandler: (tab: number) => void;
}

export const InstDetailConsult: React.FC<IInstDetailConsultProps> = ({
  consultId,
  setTabHandler,
}) => {
  const [edit, setEdit] = useState<boolean>(false);
  const setEditHandler = () => {
    setTimeout(() => setEdit(!edit), 5);
  };
  const {
    register,
    getValues,
    setValue,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm<IUpConsultFormProps>({ mode: "all" });

  const { data: me } = useMe();

  const onGetComplete = (data: getConsult) => {
    const {
      getConsult: { ok, err },
    } = data;
    if (ok) {
      setValue("note", data.getConsult.consult?.note);
      setValue("status", data.getConsult.consult?.status);
      setValue("type", data.getConsult.consult?.type);
    }
  };
  const [getConsult, { data, loading, refetch }] = useGetConsult(onGetComplete);
  const getConsultHandler = (consultId: number) => {
    const input: GetConsultInput = {
      consultId,
    };
    getConsult({ variables: { input } });
  };

  const [
    listConsultReply,
    {
      data: listConsultReplyData,
      loading: listConsultReplyLoading,
      refetch: listConsultReplyRefetch,
    },
  ] = useListConsultReply();
  const listConsultReplyHandler = (consultId: number) => {
    const input: ListConsultReplyInput = {
      consultId,
    };
    listConsultReply({ variables: { input } });
  };

  const onUpComplete = async (data: upConsult) => {
    const {
      upConsult: { ok, err },
    } = data;
    if (ok) {
      alert("수정하였습니다");
      refetch();
    }
    if (!ok) {
      alert(err);
    }
  };
  const [upConsult, { loading: upConsultLoading }] = useUpConsult(onUpComplete);
  const upConsultHandler = () => {
    if (!upConsultLoading) {
      const { note, title, type, status } = getValues();
      const input: UpConsultInput = {
        consultIdToUp: consultId,
        ...(note !== data?.getConsult.consult?.note && { note }),
        type,
        status,
      };
      upConsult({ variables: { input } });
    }
  };

  const onDelete = async (data: delConsult) => {
    const {
      delConsult: { ok, err },
    } = data;
    if (ok) {
      alert("문의 정보를 삭제하였습니다");
      refetch();
    }
    if (!ok) {
      alert(err);
    }
  };
  const [delConsult, { loading: delConsultLoading }] = useDelConsult(onDelete);
  const delConsultHandler = (consultIdToDel: number) => {
    if (!delConsultLoading) {
      const input: DelConsultInput = {
        consultIdToDel,
      };
      delConsult({ variables: { input } });
    }
  };
  //  up consult
  const onCompleted = async (data: upConsult) => {
    const {
      upConsult: { ok, err },
    } = data;
    if (ok) {
      await refetch();
      alert("내용이 수정되었습니다.");
    } else {
      console.error(err);
    }
  };
  useEffect(() => {
    getConsultHandler(consultId);
    listConsultReplyHandler(consultId);
  }, []);

  return (
    <div className=" bg-gray-100 h-fix-content relative box-border">
      {!loading && data?.getConsult.ok && (
        <div className="bg-white p-4 rounded-2xl shadow-lg relative">
          <div className="border-b ">
            <form onSubmit={handleSubmit(upConsultHandler)}>
              <div className="qinfo">
                <p className="p-1 text-left text-text border-b border-[#e2e2e2 text-sm">
                  <span>작성일:</span>{" "}
                  {moment(data?.getConsult.consult?.createdAt).format(
                    "yyyy년 M월 D일 HH:mm"
                  )}
                </p>
                <p className="bg-[#e2e2e2] py-1 pl-3 border-b border-[#e2e2e2 font-bold">
                  {data?.getConsult.consult?.title}
                </p>
                <p className="py-1 pl-3 border-b border-[#e2e2e2]">
                  <span className="mr-3">상태:</span>{" "}
                  <span className="font-bold">
                    {data?.getConsult.consult?.status}
                  </span>
                </p>
                <p className="py-1 pl-3 border-b border-[#e2e2e2]">
                  <span className="mr-3">분류:</span>{" "}
                  <span className="font-bold">
                    {data?.getConsult.consult?.type}
                  </span>
                </p>
                <p className="py-1 pl-3 border-b border-[#e2e2e2]">
                  <span className="mr-3">글쓴이:</span>
                  <span className="font-bold">
                    {data?.getConsult.consult?.author?.name}
                  </span>
                </p>
              </div>
              <div className="p-4">
                <textarea
                  {...register("note", { required: true })}
                  disabled={!edit}
                  className="text-sm w-full resize-none h-48 whitespace-pre-wrap"
                ></textarea>
              </div>
              <div className="btnBox pt-4 text-center space-x-4 py-5">
                {!edit ? (
                  <>
                    <Button
                      type="button"
                      canClick={true}
                      actionText="뒤로가기"
                      loading={false}
                      onClick={() => setTabHandler(0)}
                    />
                    {data?.getConsult.consult?.author?.id === me?.me.id && (
                      <Button
                        type="button"
                        className="bg-main text-white"
                        canClick={true}
                        actionText="수정"
                        loading={false}
                        onClick={() => setEditHandler()}
                      />
                    )}
                  </>
                ) : (
                  <>
                    <Button
                      type="button"
                      canClick={true}
                      actionText="취소"
                      loading={false}
                      onClick={() => setEditHandler()}
                    />
                    {data?.getConsult.consult?.author?.id === me?.me.id && (
                      <Button
                        className="bg-main text-white"
                        canClick={isValid}
                        actionText="수정완료"
                        loading={upConsultLoading}
                      />
                    )}
                  </>
                )}
              </div>
            </form>
            {consultId && (
              <ComponentCrConsultReply
                consultId={consultId}
                listRefetch={listConsultReplyRefetch}
                getRefetch={refetch}
              />
            )}
            {!listConsultReplyLoading &&
              listConsultReplyData?.listConsultReply.consultReplies?.map(
                (consultReply) => (
                  <ComponentUpConsultReply
                    key={`consult-reply-${consultReply.id}`}
                    consultReply={consultReply}
                    listRefetch={listConsultReplyRefetch}
                  />
                )
              )}
          </div>
        </div>
      )}
      {loading && (
        <div className="w-full p-5 text-center box-border rounded">
          <div className="bg-white w-full">
            <TableError errorMessage={`정보를 불러오는 중입니다`} />
          </div>
        </div>
      )}
    </div>
  );
};
