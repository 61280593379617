import React from "react";
import { gql, useLazyQuery } from "@apollo/client";
import {
  listDoorReply,
  listDoorReplyVariables,
} from "../__generated__/listDoorReply";

const LIST_DOOR_REPLY_QUERY = gql`
  query listDoorReply($input: ListDoorReplyInput!) {
    listDoorReply(input: $input) {
      ok
      err
      doorReplies {
        id
        note
        author {
          id
          name
        }
        updatedAt
        createdAt
      }
    }
  }
`;

export const useListDoorReply = (
  onCompleted?: (data: listDoorReply) => void
) => {
  return useLazyQuery<listDoorReply, listDoorReplyVariables>(
    LIST_DOOR_REPLY_QUERY,
    {
      onCompleted,
      fetchPolicy: "network-only",
      refetchWritePolicy: "merge",
    }
  );
};
