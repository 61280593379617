import { LayoutTop } from "../layout/top";
import searchButton from "../assets/icon/search.svg";
import offline from "../assets/icon/off.svg";
import online from "../assets/icon/on.svg";
import { useForm } from "react-hook-form";
import { useSearchNaver } from "../hooks/useSearchNaver";
import { searchNaver } from "../__generated__/searchNaver";
import { useSearchProductAddress } from "../hooks/useSearchProductWAddress";
import {
  ListPlaceActiveInput,
  SearchNaverInput,
  SearchProductWAddressInput,
} from "../__generated__/globalTypes";
import { useEffect, useState } from "react";
import { Fade } from "@mui/material";
import { getMap } from "../constants/get-map";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router";
import { calculate } from "../apollo";
import { Footer } from "../layout/footer";
import { useListPlaceActive } from "../hooks/useListPlaceActive";
import { useGetBannerOptionActive } from "../hooks/useGetBannerOptionActive";
import {
  LocalCategoryKROption,
  LocalCategoryOption,
  LocalCategoryOptionType,
} from "../constants/enum";
interface ISearchNaverFormProps extends SearchNaverInput {}
export const SubCommonListPlace = () => {
  const history = useNavigate();
  const location = useLocation();
  const { register, getValues, handleSubmit } = useForm<ISearchNaverFormProps>({
    mode: "onChange",
  });
  // remove  HTML Tags
  var tagBody = "(?:[^\"'>]|\"[^\"]*\"|'[^']*')*";
  var tagOrComment = new RegExp(
    "<(?:" +
      // Comment body.
      "!--(?:(?:-*[^->])*--+|-?)" +
      // Special "raw text" elements whose content should be elided.
      "|script\\b" +
      tagBody +
      ">[\\s\\S]*?</script\\s*" +
      "|style\\b" +
      tagBody +
      ">[\\s\\S]*?</style\\s*" +
      // Regular name
      "|/?[a-z]" +
      tagBody +
      ")>",
    "gi"
  );
  function removeTags(html: any) {
    var oldHtml;
    do {
      oldHtml = html;
      html = html.replace(tagOrComment, "");
    } while (html !== oldHtml);
    return html.replace(/</g, "&lt;");
  }

  const [searchPlace, setsearchPlace] = useState(false);
  const [titleName, setTitleName] = useState("");
  // input keyword and focus on
  const keywordHandler = (str: string) => {
    let input = document.getElementById("placeSearch") as HTMLInputElement;
    input.focus();
    input.value = str;
  };
  // 지역명 뽑기
  const regionNameGet = (str: string) => {
    let title = str.split(" ")[0] + " " + str.split(" ")[1];
    return title;
  };
  // handler
  const showClassHandler = (str: string) => {
    setTitleName(str);
  };
  // 계산

  const onCompleted = (rst: searchNaver) => {
    var items = rst.searchNaver.items;
  };

  // 배너
  const { data: getBannerOptionActiveData } = useGetBannerOptionActive();
  // ListPlace
  const [page, setPage] = useState<number>(1);
  const [search, setSearch] = useState<string>("");
  const [local, setLocal] = useState<string>("");
  const [
    listPlaceActive,
    { data: listPlacActiveData, loading: listPlaceActiveLoading, refetch },
  ] = useListPlaceActive();

  const listPlaceActiveHandler = (
    page: number,
    search: string,
    local: string
  ) => {
    const localCategory = local
      ? Object(LocalCategoryOption).filter(
          (localCategoryOption: LocalCategoryOptionType) =>
            localCategoryOption.string === local
        )[0]
      : local;
    const input: ListPlaceActiveInput = {
      page,
      search,
      ...(localCategory && { localCategory: localCategory.value }),
    };
    listPlaceActive({ variables: { input } });
  };

  const localCategoryHandler = (local: string) => {
    setLocal(local);
    const query = `${local || page || search ? `?` : ``}${
      local ? `local=${local}` : ``
    }${local && page ? `&` : ``}${page ? `page=${page}` : ``}${
      (local || page) && search ? `&` : ``
    }${search ? `search=${search}` : ``}`;
    history({ pathname: "/place", search: query });
    listPlaceActiveHandler(page, search, local);
  };

  // naver
  const [
    searchNaver,
    { data: searchNaverResult, loading: searchNaverLoading },
  ] = useSearchNaver(onCompleted);

  const onSubmit = () => {
    const { search } = getValues();
    setTitleName("");
    setsearchPlace(true);
    if (!searchNaverLoading) {
      const searchNaverInput: SearchNaverInput = {
        search,
      };
      searchNaver({
        variables: {
          input: searchNaverInput,
        },
      });
    }
  };

  const [
    searchProductWAddress,
    { data: searchProductWAddressData, loading, called },
  ] = useSearchProductAddress();

  const listProductInPlaceHandler = (address: string) => {
    const searchProductWAddressInput: SearchProductWAddressInput = {
      address,
    };
    searchProductWAddress({
      variables: {
        input: searchProductWAddressInput,
      },
    });
  };

  useEffect(() => {
    setLocal(
      location.search.split("local=").length > 1
        ? location.search.split("local=")[1].split("&")[0]
        : ""
    );
    setPage(
      location.search.split("page=").length > 1
        ? Number(location.search.split("page=")[1].split("&")[0])
        : 1
    );
    setSearch(
      location.search.split("search=").length > 1
        ? decodeURI(location.search.split("search=")[1].split("&")[0])
        : ""
    );
    listPlaceActiveHandler(page, search, local);
  }, []);

  return (
    <>
      <LayoutTop title={"nothing"} />
      <div className="max-w-screen-yl mx-auto pt-0 lg:pt-10">
        <div id="placeBanner" className="px-4 yl:px-0 hidden sm:block ">
          {getBannerOptionActiveData?.getBannerOptionActive.bannerOptions && (
            <a
              href={`${getBannerOptionActiveData.getBannerOptionActive.bannerOptions[8].link}`}
            >
              <img
                src={`${
                  getBannerOptionActiveData.getBannerOptionActive
                    .bannerOptions[8].view?.url
                    ? getBannerOptionActiveData.getBannerOptionActive
                        .bannerOptions[8].view.url
                    : `#`
                }`}
                alt="배너이미지"
                className="w-full aspect-[328/216] sm:aspect-[16/9] lg:aspect-auto object-cover"
              />
            </a>
          )}
        </div>
        <div className="classGoCatrgory text-center md:text-right py-4 sm:pt-5 px-4 yl:px-0 sm:bg-transparent bg-[#F1ECE8]">
          <select
            value={local}
            className="text-xs sm:text-sm lg:text-base bg-transparent pr-3 text-center cursor-pointer text-main sm:text-gray-600"
            onChange={(e) => localCategoryHandler(e.target.value)}
          >
            {LocalCategoryOption.map((localCategory) => (
              <option
                key={`local-category-${localCategory.value}`}
                value={localCategory.string}
              >
                {localCategory.label}
              </option>
            ))}
          </select>
        </div>
        <div className="naverSearch sm:pt-10 px-4">
          <p className="text-center hidden sm:block">
            전국 어디든 힙한 곳을 검색해 보세요!
          </p>
          <div className="w-2/3 lg:w-1/2 min-w-[280px] mx-auto pt-5">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="border-main border-4 rounded-sm relative">
                <input
                  {...register("search")}
                  id="placeSearch"
                  type="text"
                  className="w-full lg:text-base py-1 px-2 focus:outline-none"
                />
                <button
                  type="submit"
                  className="absolute right-0 top-0 px-2 h-full bg-main"
                >
                  <img src={searchButton} alt="검색" className="w-6" />
                </button>
              </div>
            </form>
            <p className="pt-5 space-x-5 text-text">
              <button onClick={() => keywordHandler("복합문화공간")}>
                #복합문화공간
              </button>
              <button onClick={() => keywordHandler("미술관")}>#미술관</button>
              <button onClick={() => keywordHandler("캠핑")}>#캠핑</button>
              <button onClick={() => keywordHandler("야경")}>#야경</button>
            </p>
          </div>
          <Fade in={searchPlace}>
            <div className={`result pt-5 ${searchPlace ? "block" : "hidden"}`}>
              <p className="py-3">
                네이버 검색 장소{" "}
                <span
                  onClick={() => setsearchPlace(false)}
                  className="text-xs ml-10 text-text cursor-pointer hover:underline"
                >
                  닫기
                </span>
              </p>
              <div className="inline-grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 rounded-md ">
                {!searchNaverLoading &&
                  searchNaverResult?.searchNaver.items &&
                  searchNaverResult?.searchNaver?.items.map((item, index) => (
                    <div
                      key={`places-${index}`}
                      className=" card max-w-[360px] px-3 py-5 bg-white rounded grid grid-cols-[1fr_100px] border-b"
                    >
                      <div className="pr-2">
                        <p className="title">
                          {item.link ? (
                            <a
                              href={item.link}
                              target="_blank"
                              title={removeTags(item.title) + " 사이트로 가기"}
                              className="text-blue-500  hover:underline text-sm lg:text-base leading-tight"
                            >
                              {removeTags(item.title)}
                            </a>
                          ) : (
                            <span className="text-blue-500  hover:underline text-sm lg:text-base leading-tight">
                              {removeTags(item.title)}
                            </span>
                          )}
                        </p>
                        <p className="category text-xs lg:text-sm text-text">
                          {item.category}
                        </p>
                        <p className="address pt-2 h-13 leading-tight ellipsis2 overflow-hidden text-sm lg:text-base">
                          {item.address}
                        </p>
                        <p className="pt-2">
                          <button
                            onClick={() => {
                              showClassHandler(removeTags(item.title));
                              listProductInPlaceHandler(
                                regionNameGet(item.address)
                              );
                            }}
                            className="text-xs lg:text-sm border border-grays rounded px-2 py-1 shadow hover:opacity-60 focus:bg-gray-100"
                          >
                            근처 공방보기
                          </button>
                        </p>
                      </div>
                      <div>
                        <a
                          href={`https://map.naver.com/v5/search/${removeTags(
                            item.title
                          )}`}
                          target="_blank"
                        >
                          <img
                            src={getMap("300", "300", +item.mapx, +item.mapy)}
                            alt="지도"
                            title="지도검색"
                            className="w-full aspect-square hover:outline hover:outline-1 hover:outline-grays"
                          />
                        </a>
                      </div>
                    </div>
                  ))}
              </div>
              {searchNaverResult?.searchNaver.items?.length == 0 && (
                <div className="w-full py-10 text-xl font-bold">
                  검색된 장소가 없습니다.
                </div>
              )}
              <Fade in={Boolean(titleName)}>
                <div
                  className={`classes pt-5 ${titleName ? "block" : "hidden"}`}
                >
                  <p className="py-3">
                    근처 공방 리스트{" "}
                    <span>{titleName && `- ${titleName}`}</span>
                  </p>
                  <div className="grid grid-cols-2 md:grid-cols-4 gap-3 md:gap-5 pt-5">
                    {searchProductWAddressData?.searchProductWAddress
                      .products &&
                      searchProductWAddressData.searchProductWAddress.products.map(
                        (product: any) => (
                          <div
                            key={`withaddress-${product.id}`}
                            className="card"
                          >
                            <div className="cardImgRatio relative overflow-hidden">
                              <a href={`/class/${product.id}`}>
                                <img
                                  src={product.productViews[0].view?.url}
                                  alt={product.productViews[0].view?.filename}
                                  className="absolute left-0 top-0 w-full"
                                />
                              </a>
                              {product.productType === "Online" ? (
                                <img
                                  src={online}
                                  alt={"수업형태" + product.productType}
                                  className="absolute right-2 top-2 w-1/4"
                                />
                              ) : (
                                <img
                                  src={offline}
                                  alt={"수업형태" + product.productType}
                                  className="absolute right-2 top-2 w-1/4"
                                />
                              )}
                            </div>
                            <div className="text pt-1 md:pt-3">
                              <p className="tracking-tight a15 text-xs py-1">
                                {product.inst.atelier.name}
                              </p>
                              <p className="tracking-tight font-bold text-sm md:text-base h-10 md:h-[48px] py-1 hover:underline cursor-pointer overflow-hidden ellipsis2">
                                <Link to={`/class/${product.id}`}>
                                  {product.title}
                                </Link>
                              </p>
                              <div className="price pt-2">
                                {product.discount === 0 ? (
                                  <span className="text-sm md:text-base font-bold">
                                    {product.price}원
                                  </span>
                                ) : (
                                  <>
                                    <span className="tracking-tight text-reds text-sm md:text-base font-bold hidden sm:inline-block">
                                      {product.discount}%
                                    </span>
                                    <p className="tracking-tight inline-block ml-3 space-x-2 font-bold">
                                      <span className="text-[10px] md:text-xs line-through text-text">
                                        {product.price.toLocaleString()}원
                                      </span>
                                      <span className="text-sm md:text-base">
                                        {
                                          calculate(
                                            product.price,
                                            product.discountRate
                                          ).toLocaleString
                                        }
                                        원
                                      </span>
                                    </p>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        )
                      )}
                  </div>
                  {searchProductWAddressData?.searchProductWAddress.products &&
                    searchProductWAddressData?.searchProductWAddress.products
                      .length === 0 && (
                      <div className="py-10 text-bold text-2xl">
                        근처의 공방이 없습니다.
                      </div>
                    )}
                </div>
              </Fade>
            </div>
          </Fade>
        </div>
        <div className="py-10 lg:py-20 px-4 yl:px-0 ">
          <p className="inline-block tracking-tight title text-2xl sm:text-3xl text-main gangwon">
            <span className="align-middle">로컬픽플레이스</span>
            <span className="align-middle text-sm ml-3 a13">
              <i className="fas fa-chevron-right"></i>
            </span>
          </p>
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-5 gap-y-10 pt-10">
            {!listPlaceActiveLoading &&
              listPlacActiveData?.listPlaceActive?.products?.length !== 0 &&
              listPlacActiveData?.listPlaceActive?.products?.map((product) => (
                <div
                  key={`${
                    product.productInfo &&
                    product?.productInfo?.title + product.id
                  }`}
                  className="card relative"
                >
                  <a href={`/place/${product.id}`}>
                    {product?.productViews &&
                      product.productViews
                        .filter((productView) => productView.is_rep)
                        .map((productView) => (
                          <img
                            key={`class-view-${productView.id}`}
                            src={productView?.view?.url && productView.view.url}
                            alt={`${product?.productInfo?.title} 썸네일`}
                            className="aspect-class object-cover"
                          />
                        ))}
                  </a>
                  <div className="text px-3 py-4 bg-[#F1F2EF]">
                    <p className="tracking-tight text-sm sm:text-base lg:text-2xl py-1 font-bold text-main ellipsis2 h-[48px] sm:h-[56px] lg:h-[72px] overflow-hidden">
                      {product?.productInfo?.title}
                    </p>
                    <p className="text-sm lg:text-base">
                      위치: {product.productInfo?.place}
                    </p>
                    <p className="text-sm lg:text-base">
                      기간: {product.productInfo?.playTime}
                    </p>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
};
