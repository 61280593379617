import logo from "../../assets/icon/localpick_logo.svg";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useEffect, useState } from "react";
import {
  ISendChangePasswordEmailForm,
  useSendChangePasswordEmail,
} from "../../hooks/useSendChangePasswordEmail";
import { sendChangePasswordEmail } from "../../__generated__/sendChangePasswordEmail";
import { useForm } from "react-hook-form";
import { SendChangePasswordEmailInput } from "../../__generated__/globalTypes";
export const Find_pwd = () => {
  const {
    register,
    handleSubmit,
    getValues,
    setFocus,
    formState: { errors },
  } = useForm<ISendChangePasswordEmailForm>({ mode: `all` });

  const onCompleted = (data: sendChangePasswordEmail) => {
    const {
      sendChangePasswordEmail: { ok, err },
    } = data;
    if (ok) {
      alert(`비밀번호 변경 링크를 발송하였습니다. 이메일을 확인해주세요`);
      window.location.reload();
    } else {
      alert(err);
    }
  };

  const [sendChangePasswordEmail, { loading, error }] =
    useSendChangePasswordEmail(onCompleted);

  const onSubmit = () => {
    if (!loading) {
      const { email } = getValues();

      const sendChangePasswordEmailInput: SendChangePasswordEmailInput = {
        email,
      };

      sendChangePasswordEmail({
        variables: { input: sendChangePasswordEmailInput },
      });
    }
  };

  useEffect(() => {
    setFocus(`email`);
  }, []);

  return (
    <div className="bg-main bg-opacity-50 w-full h-full min-h-screen">
      <Helmet title="LoLo - 아이디찾기" />
      <div className="max-w-screen-yl mx-auto w-full h-full min-h-screen bg-white overflow-hidden relative">
        <div className="absolute w-full text-center top-1/2 -translate-y-1/2">
          <div className="logBox w-full text-center">
            <div className="w-[200px] mx-auto">
              <a href="/">
                <img src={logo} alt="로로 로고" className="w-full" />
              </a>
            </div>
            <p className="text-center pt-5">
              회원정보에 등록된 이메일을 기입해주세요
            </p>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="p-4 mx-auto w-full max-w-md"
            >
              <label className="py-10 flex">
                <span className="inline-block w-full max-w-[90px] text-main uppercase font-bold">
                  이메일
                </span>
                <input
                  {...register("email", { required: `이메일을 입력해주세요` })}
                  type="email"
                  className="inline-block border-b rounded-none border-main focus:outline-none pl-1 leading-normal flex-1"
                />
              </label>
              <div className="pt-10 relative">
                <div className="pt-10 w-full">
                  <Link
                    to="/signin"
                    className="inline-block w-2/5 py-1 px-2 border-grays border rounded mr-3"
                  >
                    취소
                  </Link>
                  <button className="w-2/5 py-1 px-2 text-bold bg-main border border-main rounded text-white">
                    확인
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
