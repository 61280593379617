import { useEffect, useState } from "react";
import { pageIndicator } from "../apollo";
import { AsideAdminComponent } from "../layout/aside-admin";
import { Footer } from "../layout/footer";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { FormError2 } from "../components/form-error";
import { Button } from "../components/button";
import { CrBannerInput } from "../__generated__/globalTypes";
import { ICrBannerFormProps, useCrBanner } from "../hooks/useCrBanner";
import { crBanner } from "../__generated__/crBanner";
import { AdminTop } from "../layout/adminTop";

export const AdminCrBanner = () => {
  const history = useNavigate();
  useEffect(() => {
    pageIndicator("banner");
  }, []);
  const {
    getValues,
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<ICrBannerFormProps>({ mode: "all" });

  const onComplete = (data: crBanner) => {
    const {
      crBanner: { ok, err },
    } = data;
    if (ok) {
      alert("저장되었습니다");
      window.location.href = "/admin/banner";
    } else {
      alert(err);
    }
  };
  const [crBanner, { loading }] = useCrBanner(onComplete);
  const onSubmit = () => {
    if (!loading) {
      const { title } = getValues();
      const input: CrBannerInput = {
        title,
      };
      crBanner({ variables: { input } });
    }
  };

  return (
    <div className="bg-main bg-opacity-50 w-full h-full min-h-screen">
      <div className="w-[1440px] mx-auto h-full min-h-screen  bg-white">
        <AdminTop title={"top"} children={"관리자모드"} />
        <main id="main">
          <div className="grid grid-cols-[300px_1fr]">
            <AsideAdminComponent />
            <div className="mypages ">
              <div className="lectureWrap">
                <div className="h-20">
                  <div className="h-full flex-wrap flex justify-between px-10 content-center">
                    <div className="subtitle h-full flex flex-wrap content-end gap-x-3">
                      <div className={`inline-block w-44 text-center`}>
                        <Link
                          className={`inline-block leading-normal font-bold hover:opacity-60 w-full py-1 text-gray-400`}
                          to={`/admin/banner`}
                        >
                          목록
                        </Link>
                      </div>
                      <div
                        className={`inline-block w-44 text-center border-main border-b-2`}
                      >
                        <Link
                          className={`inline-block leading-normal font-bold hover:opacity-60 w-full py-1 text-main`}
                          to={`/admin/banner/create`}
                        >
                          배너 생성
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className=" bg-gray-100 h-fix-content relative">
                  <div className={`w-full h-full px-10 py-10`}>
                    {/* 여기 */}
                    <div className="bg-white p-5 rounded-2xl shadow-lg">
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <div className=" p-5 lg:p-10 space-y-5 lg:space-y-10 max-w-xl">
                          <div className="space-y-3">
                            <p className="font-bold text-main lg:text-base text-sm">
                              제목
                            </p>
                            <input
                              {...register("title", {
                                required: `제목을 입력해주세요`,
                              })}
                              type="text"
                              className="border border-grays rounded-xl py-1 px-2 w-full text-sm lg:text-base"
                            />
                            {errors.title?.message && (
                              <FormError2 errorMessage={errors.title.message} />
                            )}
                          </div>

                          <div className="text-center py-10 space-x-5">
                            <button className="rounded-md border border-grays w-2/5 sm:w-[150px] py-1 hover:opacity-60 text-sm lg:text-base ">
                              취소
                            </button>
                            <Button
                              className={`rounded-md text-white w-2/5 sm:w-[150px] py-1 hover:opacity-60 text-sm lg:text-base bg-main border border-main`}
                              canClick={isValid}
                              actionText={`저장`}
                              loading={loading}
                              color={"text-white"}
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                    <Footer></Footer>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
