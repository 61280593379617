import { ApolloError, useMutation } from "@apollo/client";
import gql from "graphql-tag";
import React from "react";
import { upInst, upInstVariables } from "../__generated__/upInst";

const UP_INST_MUTATION = gql`
  mutation upInst($input: UpInstInput!) {
    upInst(input: $input) {
      ok
      err
    }
  }
`;

export const useUpInst = (
  onCompleted?: (data: upInst) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<upInst, upInstVariables>(UP_INST_MUTATION, {
    onCompleted,
    onError,
  });
};
