import React, { useEffect, useState } from "react";
import { createBrowserHistory } from "history";
import { useForm } from "react-hook-form";
import { FormError2 } from "../components/form-error";
import { Button } from "../components/button";
import { useParams } from "react-router";
import { useGetBanner } from "../hooks/useGetBanner";
import { IUpBannerFormProps, useUpBanner } from "../hooks/useUpBanner";
import { GetBannerInput, UpBannerInput } from "../__generated__/globalTypes";
import { getBanner } from "../__generated__/getBanner";
import { upBanner } from "../__generated__/upBanner";

export const SubAdminUpBanner: React.FC = () => {
  const history = createBrowserHistory();
  const params = useParams();

  const {
    getValues,
    register,
    handleSubmit,
    setValue,
    setFocus,
    formState: { errors, isValid },
  } = useForm<IUpBannerFormProps>({ mode: "all" });

  const onGetComplete = (data: getBanner) => {
    const {
      getBanner: { ok, err },
    } = data;
    if (!ok) {
      alert("잘못된 접근입니다");
      window.location.href = "/admin/banner";
      return;
    }
    setValue("title", `${data.getBanner.banner?.title}`);
    setFocus("title");
  };
  const [getBanner, { loading: getBannerLoading }] =
    useGetBanner(onGetComplete);
  const getBannerHandler = (bannerId: number) => {
    if (getBannerLoading) return;
    const input: GetBannerInput = {
      bannerId,
    };
    getBanner({ variables: { input } });
  };

  const onComplete = (data: upBanner) => {
    const {
      upBanner: { ok, err },
    } = data;
    if (ok) {
      alert("저장되었습니다");
      history.push("/admin/banner");
    } else {
      alert(err);
    }
  };
  const [upBanner, { loading }] = useUpBanner(onComplete);
  const onSubmit = () => {
    if (!params.bannerId) {
      alert("배너 정보가 유효하지 않습니다");
      return;
    }
    if (!loading) {
      const { title } = getValues();
      const input: UpBannerInput = {
        bannerIdToUp: +params.bannerId,
        title,
      };
      upBanner({ variables: { input } });
    }
  };

  useEffect(() => {
    if (!params.bannerId) return;
    getBannerHandler(+params.bannerId);
  }, []);

  return !getBannerLoading ? (
    <div className="bg-white p-5 rounded-2xl shadow-lg">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className=" p-5 lg:p-10 space-y-5 lg:space-y-10 max-w-xl">
          <div className="space-y-3">
            <p className="font-bold text-main lg:text-base text-sm">제목</p>
            <input
              {...register("title", {
                required: `제목을 입력해주세요`,
              })}
              type="text"
              className="border border-grays rounded-xl py-1 px-2 w-full text-sm lg:text-base"
            />
            {errors.title?.message && (
              <FormError2 errorMessage={errors.title.message} />
            )}
          </div>

          <div className="text-center py-10 space-x-5">
            <button className="rounded-md border border-grays w-2/5 sm:w-[150px] py-1 hover:opacity-60 text-sm lg:text-base ">
              취소
            </button>
            <Button
              className={`rounded-md text-white w-2/5 sm:w-[150px] py-1 hover:opacity-60 text-sm lg:text-base bg-main border border-main`}
              canClick={isValid}
              actionText={`저장`}
              loading={loading}
              color={"text-white"}
            />
          </div>
        </div>
      </form>
    </div>
  ) : (
    <div className="bg-white p-5 rounded-2xl shadow-lg text-center">{`데이터를 불러오는 중입니다`}</div>
  );
};
