import React from "react";
import gql from "graphql-tag";
import { ApolloError, useMutation } from "@apollo/client";
import {
  approveRefund,
  approveRefundVariables,
} from "../__generated__/approveRefund";

const APPROVE_REFUND_MUTATION = gql`
  mutation approveRefund($input: ApproveRefundInput!) {
    approveRefund(input: $input) {
      ok
      err
    }
  }
`;

export const useApproveRefund = (
  onCompleted?: (data: approveRefund) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<approveRefund, approveRefundVariables>(
    APPROVE_REFUND_MUTATION,
    {
      onCompleted,
      onError,
    }
  );
};
