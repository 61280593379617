import logo from "../assets/icon/localpick_logo_footer.svg";
import { Modal } from "@mui/material";
import ruleCellCome from "../assets/images/useRule_come.png";
import ruleCellGo from "../assets/images/useRule_go.png";
import {
  userRule1,
  userRule2,
  userRule3,
  personalInfo,
  delrefund,
} from "./text";
import React, { useEffect } from "react";
import insta from "../assets/icon/insta.svg";
import facebook from "../assets/icon/facebook.svg";
import youtube from "../assets/icon/youtube.svg";
export const Footer = () => {
  const [use, setUse] = React.useState(false);
  const [personal, setPersonal] = React.useState(false);
  const [refund, setRefund] = React.useState(false);
  return (
    <div className="footerWrap bg-white">
      <footer
        id="footer"
        className="max-w-screen-yl mx-auto px-5 block lg:flex py-6 lg:py-10 space-y-3 "
      >
        <div className="px-0  lg:pr-10 flex flex-wrap content-center justify-center lg:text-left border-0 lg:border-r border-[#b6b5b5]">
          <img src={logo} alt="로로 로고" className="w-32 inline-block" />
        </div>
        <div className="snb flex-1 text-center lg:text-left pt-5 lg:pt-0 lg:px-10">
          <ul className="text-gray-400">
            <li className="inline pr-2 lg:pr-5 border-r-[1px] border-[#b6b5b5]  text-base lg:text-sm leading-none">
              <button
                onClick={() => {
                  setUse(true);
                }}
                className="hover:opacity-60"
              >
                이용약관
              </button>
            </li>
            <li className="inline px-2 lg:px-5 border-r-[1px] border-[#b6b5b5]  text-base lg:text-sm leading-none">
              <button
                onClick={() => {
                  setPersonal(true);
                }}
                className="hover:opacity-60"
              >
                개인정보처리방침
              </button>
            </li>
            <li className="inline pl-2 lg:pl-5  text-base lg:text-sm leading-none">
              <button
                onClick={() => {
                  setRefund(true);
                }}
                className="hover:opacity-60"
              >
                배송/환불 정책
              </button>
            </li>
          </ul>
          <address className="not-italic text-gray-400 pt-2 leading-[1.1] break-all">
            <span className="pr-2 lg:pr-3 my-1 border-r border-[#b6b5b5] text-xs">
              ㈜어블러
            </span>
            <span className="px-2 lg:px-3 my-1 border-r border-[#b6b5b5] text-xs">
              대표: 허은애
            </span>
            <span className="px-2 lg:px-3 my-1 border-r border-[#b6b5b5] text-xs">
              주소: 울산광역시 중구 종가로 406-21, 838호 (복산동)
            </span>
            <span className="px-2 lg:px-3 my-1 border-r border-[#b6b5b5] text-xs">
              메일: eunae512@naver.com
            </span>

            <span className="px-2 lg:px-3 my-1 border-r border-[#b6b5b5] text-xs">
              사업자 등록번호: 745-88-00998
            </span>
            <span className="px-2 lg:px-3 my-1 border-r border-[#b6b5b5] text-xs">
              통신판매번호: 2020-울산남구-0059
            </span>
            <span className="px-2 lg:px-3 my-1 border-r border-[#b6b5b5] text-xs">
              연락처: 052-227-8765
            </span>
          </address>
        </div>
        <div className="sns max-w-[240px] mx-auto w-full lg:w-1/5 flex flex-wrap content-center justify-center space-x-5">
          <a
            href="https://www.instagram.com/class_awesome/?hl=ko"
            className="inline-block w-[50px]"
            target="_blank"
          >
            <img src={insta} alt="인스타그램" />
          </a>
          {/* <a href="/" className="inline-block w-[50px]">
            <img src={facebook} alt="페이스북" />
          </a> */}
          <a
            href="https://www.youtube.com/channel/UCAZX8BHObZCI8H93xbHPiog"
            target="_blank"
            className="inline-block w-[50px]"
          >
            <img src={youtube} alt="유튜브" />
          </a>
        </div>
        <Modal
          open={use}
          onClose={() => {
            setUse(false);
          }}
        >
          <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 p-10 max-h-[500px] overflow-y-scroll bg-white rounded-md shadow-lg max-w-[1200px] w-10/12">
            <p className="text-bold text-lg pb-5">LOLO 이용약관</p>
            <div
              id="userRule"
              className="text-sm px-1 leading-normal whitespace-pre-wrap keep-all"
            >
              {userRule1}
              <img
                src={ruleCellCome}
                alt="클래스 콘텐츠만 구매시, 결제 완료일부터 7일 이내 환불요청 가능 , 환불금액은 chapter1을 초과하여 수강한 경우 결제한 금액의 50%. 패키지 구매 시 (클래스 콘텐츠 + 키트) 배송 완료일부터 7일 이내 교환/환불 요청 가능 Chapter1을 초과하여 수강한 경우 결제한 금액에서 수강권 정가의 50%를 차감한 금액. 패키지 구매 후 클래스 콘텐츠만 환불 불가"
              />
              {userRule2}
              <img
                src={ruleCellGo}
                alt="예정 수강일로부터 3일 이내 교환/환불 요청 시, 금액은 결제한 금액 / 예정 수강일로부터 2일이내 교환/환불 시, 환불금액은 수강권 결제액의 30%를 차감한 금액 , 예정 수강일 당일 교환/환불 요청은 불가능"
              />
              {userRule3}
            </div>
          </div>
        </Modal>
        <Modal
          open={personal}
          onClose={() => {
            setPersonal(false);
          }}
        >
          <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 p-10 max-h-[500px] overflow-y-scroll bg-white rounded-md shadow-lg max-w-[1200px] w-10/12">
            <p className="text-bold text-lg pb-5">개인정보처리방침</p>
            <div
              id="personalInfo"
              className="text-sm px-1 leading-normal whitespace-pre-wrap keep-all"
            >
              {personalInfo}
            </div>
          </div>
        </Modal>
        <Modal
          open={refund}
          onClose={() => {
            setRefund(false);
          }}
        >
          <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 p-10 max-h-[500px] overflow-y-scroll bg-white rounded-md shadow-lg max-w-[1200px] w-10/12">
            <p className="text-bold text-lg pb-5">배송/환불 정책</p>
            <div
              id="refund"
              className="text-sm px-1 leading-normal whitespace-pre-wrap keep-all"
            >
              {delrefund}
            </div>
          </div>
        </Modal>
      </footer>
    </div>
  );
};
