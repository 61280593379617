import { ApolloError, gql, useMutation } from "@apollo/client";
import React from "react";
import { delUser, delUserVariables } from "../__generated__/delUser";

const DEL_USER_MUTATION = gql`
  mutation delUser($input: DelUserInput!) {
    delUser(input: $input) {
      ok
      err
    }
  }
`;

export const useDelUser = (
  onCompleted?: (data: delUser) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<delUser, delUserVariables>(DEL_USER_MUTATION, {
    onCompleted,
    onError,
  });
};
