import { ApolloError, gql, useMutation } from "@apollo/client";
import React from "react";
import { delProduct, delProductVariables } from "../__generated__/delProduct";
import { DelProductInput } from "../__generated__/globalTypes";

export interface IDelProductInput extends DelProductInput {}

const DEL_PRODUCT_MUTATION = gql`
  mutation delProduct($input: DelProductInput!) {
    delProduct(input: $input) {
      ok
      err
    }
  }
`;

export const useDelProduct = (
  onCompleted?: (data: delProduct) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<delProduct, delProductVariables>(DEL_PRODUCT_MUTATION, {
    onCompleted,
    onError,
  });
};
