import { gql } from "@apollo/client";
import React from "react";

export const CLASS_LIST_WIDGET_FRAGMENT = gql`
  fragment ClassListWidgetParts on Product {
    id
    like_count
    productInfo {
      scheduledAt
      title
      price
      discountRate
      max
    }
    inst {
      id
      atelier {
        name
      }
      user {
        name
      }
    }
    createdAt
    productStatus
    productType
    promotionProducts {
      discountRate
      promotion {
        id
        badge
        badgeColor
      }
    }
    productKit {
      id
      view {
        url
      }
      desc
    }
    productViews {
      index
      is_rep
      id
      view {
        url
      }
    }
  }
`;
export const DETAIL_CLASS_FRAGMENT = gql`
  fragment DetailClassParts on Product {
    id
    like_count
    productInfo {
      scheduledAt
      title
      price
      discountRate
      summary
      productCategory
      place
      max
      playTime
      materials
    }
    productStatus
    productType
    productPage {
      page
    }
    productIntro {
      intro
    }
    productKit {
      view {
        filename
        url
      }
    }
  }
`;
