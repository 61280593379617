import React from "react";
import { gql, useLazyQuery, useQuery } from "@apollo/client";
import { getProduct, getProductVariables } from "../__generated__/getProduct";
import { DETAIL_CLASS_FRAGMENT } from "../fragments";
import { getReceipt, getReceiptVariables } from "../__generated__/getReceipt";
import { GET_RECEIPT_FRAGMENT } from "../fragment-receipt";

const GET_RECEIPT_QUERY = gql`
  query getReceipt($input: GetReceiptInput!) {
    getReceipt(input: $input) {
      ok
      err
      receipt {
        ...GetReceiptParts
      }
    }
  }
  ${GET_RECEIPT_FRAGMENT}
`;

export const useGetReceipt = (onCompleted?: (data: getReceipt) => void) => {
  return useLazyQuery<getReceipt, getReceiptVariables>(GET_RECEIPT_QUERY, {
    onCompleted,
    fetchPolicy: `network-only`,
  });
};
