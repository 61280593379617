import { ApolloError, gql, useLazyQuery } from "@apollo/client";
import React from "react";
import { canBuy, canBuyVariables } from "../__generated__/canBuy";
import { CrProductInput } from "../__generated__/globalTypes";

export interface ICrProductFormProps extends CrProductInput {}

const CAN_BUY_QUERY = gql`
  query canBuy($input: CanBuyInput!) {
    canBuy(input: $input) {
      ok
      err
      receipt {
        is_expired
      }
    }
  }
`;

export const useCanBuy = (
  onCompleted?: (data: canBuy) => void,
  onError?: (error: ApolloError) => void
) => {
  return useLazyQuery<canBuy, canBuyVariables>(CAN_BUY_QUERY, {
    onCompleted,
    onError,
    fetchPolicy: "network-only",
  });
};
