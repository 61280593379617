import classComeBanner from "../assets/images/banners/classComeBanner.png";
import { LayoutTop } from "../layout/top";
import { cate_region, cate_class } from "../routes/home/parts/data";
import { useEffect, useState } from "react";
import online from "../assets/icon/on.svg";
import { Link, useLocation } from "react-router-dom";
import good from "../assets/icon/heart.svg";
import good_white from "../assets/icon/heart_white.svg";

import { Footer } from "../layout/footer";
import { createBrowserHistory } from "history";

import {
  LocalCategoryOption,
  LocalCategoryOptionType,
  OrderOption,
  ProductCategoryOption,
  ProductCategoryOptionType,
} from "../constants/enum";
import { useGetBannerOptionActive } from "../hooks/useGetBannerOptionActive";
import { calculate } from "../apollo";
import { useListProductSub } from "../hooks/useListProductSub";
import { ListProductSubInput, ProductType } from "../__generated__/globalTypes";
import { IListProductSub } from "./sub-common-list-offline-class";
import { ComponentProductWidget } from "../components/component-product";
export const SubCommonListOnlineClass = () => {
  const { data: getBannerOptionActiveData, refetch } =
    useGetBannerOptionActive();
  // data
  const history = createBrowserHistory();
  const location = useLocation();
  const [local, setLocal] = useState<string>("");
  const [product, setProduct] = useState<string>("");
  const [order, setOrder] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [search, setSearch] = useState<string>("");

  const [listProductSub, { data, refetch: ProductRefetch }] =
    useListProductSub();
  const listProductSubHandler = ({
    local: _local,
    product: _product,
    order: _order,
    page: _page,
    search: _search,
  }: IListProductSub) => {
    const localCategory = _local
      ? Object(LocalCategoryOption).filter(
          (localCategoryOption: LocalCategoryOptionType) =>
            localCategoryOption.string === _local
        )[0]
      : local;
    const productCategory = _product
      ? Object(ProductCategoryOption).filter(
          (productCategoryOption: ProductCategoryOptionType) =>
            productCategoryOption.string === _product
        )[0]
      : product;

    const input: ListProductSubInput = {
      ...(localCategory && { localCategory: localCategory.value }),
      ...(productCategory && { productCategory: productCategory.value }),
      type: ProductType.Online,
      order: _order ? _order : order,
      page: _page ? _page : page,
      search: _search ? _search : search,
    };
    listProductSub({ variables: { input } });
  };

  const orderCategoryHandler = (order: string) => {
    setOrder(order);
    const query = `${local || product || order || page || search ? `?` : ``}${
      local ? `local=${local}` : ``
    }${local && product ? `&` : ``}${product ? `product=${product}` : ``}${
      (local || product) && order ? `&` : ``
    }${order ? `order=${order}` : ``}${
      (local || product || order) && page ? `&` : ``
    }${page ? `page=${page}` : ``}${
      (local || product || order || page) && search ? `&` : ``
    }${search ? `search=${search}` : ``}`;
    history.push({ pathname: "/classGoList", search: query });
    listProductSubHandler({ order });
  };

  const localCategoryHandler = (local: string) => {
    setLocal(local);
    const query = `${local || product || order || page || search ? `?` : ``}${
      local ? `local=${local}` : ``
    }${local && product ? `&` : ``}${product ? `product=${product}` : ``}${
      (local || product) && order ? `&` : ``
    }${order ? `order=${order}` : ``}${
      (local || product || order) && page ? `&` : ``
    }${page ? `page=${page}` : ``}${
      (local || product || order || page) && search ? `&` : ``
    }${search ? `search=${search}` : ``}`;
    history.push({ pathname: "/classGoList", search: query });
    listProductSubHandler({ local });
  };

  const productCategoryHandler = (product: string) => {
    setProduct(product);
    const query = `${local || product || order || page || search ? `?` : ``}${
      local ? `local=${local}` : ``
    }${local && product ? `&` : ``}${product ? `product=${product}` : ``}${
      (local || product) && order ? `&` : ``
    }${order ? `order=${order}` : ``}${
      (local || product || order) && page ? `&` : ``
    }${page ? `page=${page}` : ``}${
      (local || product || order || page) && search ? `&` : ``
    }${search ? `search=${search}` : ``}`;
    history.push({ pathname: "/classGoList", search: query });
    listProductSubHandler({ product });
  };

  useEffect(() => {
    setLocal(
      location.search.split("local=").length > 1
        ? location.search.split("local=")[1].split("&")[0]
        : ""
    );
    setProduct(
      location.search.split("product=").length > 1
        ? location.search.split("product=")[1].split("&")[0]
        : ""
    );
    setOrder(
      location.search.split("order=").length > 1
        ? decodeURI(location.search.split("order=")[1].split("&")[0])
        : "최신순"
    );
    setPage(
      location.search.split("page=").length > 1
        ? Number(location.search.split("page=")[1].split("&")[0])
        : 1
    );
    setSearch(
      location.search.split("search=").length > 1
        ? decodeURI(location.search.split("search=")[1].split("&")[0])
        : ""
    );
    listProductSubHandler({ local, product, order, page });
  }, []);
  return (
    <>
      <LayoutTop title={"집으로 오다"} />
      <main id="main">
        <div className="max-w-screen-yl mx-auto pt-0 lg:pt-10">
          <p className="p-4 block sm:hidden">
            <span className="inline-block text-[#99AC69] gangwon text-2xl sm:text-3xl">
              <span className="align-middle">집으로 오다</span>
            </span>
          </p>
          <div id="classGoBanner" className="hidden sm:block">
            {getBannerOptionActiveData?.getBannerOptionActive.bannerOptions &&
              getBannerOptionActiveData.getBannerOptionActive.bannerOptions[7]
                .view && (
                <img
                  src={
                    getBannerOptionActiveData.getBannerOptionActive
                      .bannerOptions[7].view?.url
                  }
                  alt="배너"
                  className="mx-auto"
                />
              )}
          </div>
          <div className="classComeCatrgory text-center px-4 yl:px-0 md:text-right space-x-2 lg:space-x-5 sm:pt-5 py-3 bg-[#F1ECE8] sm:bg-transparent">
            <select
              value={order}
              onChange={(e) => orderCategoryHandler(e.target.value)}
              className="text-xs sm:text-sm lg:text-base bg-transparent pr-3 text-center cursor-pointer text-main sm:text-gray-600"
            >
              {OrderOption.map((order, index) => (
                <option key={`order-option-${index}`} value={order.value}>
                  {order.label}
                </option>
              ))}
            </select>
            <select
              value={product}
              className="text-xs sm:text-sm lg:text-base bg-transparent pr-3 text-center cursor-pointer text-main sm:text-gray-600"
              onChange={(e) => productCategoryHandler(e.target.value)}
            >
              {ProductCategoryOption.map((productCategory) => (
                <option
                  key={`product-category-${productCategory.value}`}
                  value={productCategory.string}
                >
                  {productCategory.label}
                </option>
              ))}
            </select>
            <select
              value={local}
              className="text-xs sm:text-sm lg:text-base bg-transparent pr-3 text-center cursor-pointer text-main sm:text-gray-600"
              onChange={(e) => localCategoryHandler(e.target.value)}
            >
              {LocalCategoryOption.map((localCategory) => (
                <option
                  key={`local-category-${localCategory.value}`}
                  value={localCategory.string}
                >
                  {localCategory.label}
                </option>
              ))}
            </select>
          </div>
          <div className="classList sm:pt-8 px-4 yl:px-0 lg:pt-20">
            <p className="lg:py-5 hidden sm:block">
              <span className="inline-block text-main gangwon text-2xl sm:text-3xl">
                <span className="align-middle">집으로 오다</span>
                <i className="lg:text-base fas fa-chevron-right align-middle text-sm ml-3"></i>
              </span>
              <img
                src={online}
                alt="online"
                className="inline-block align-middle w-10 sm:w-16 ml-3 sm:ml-5"
              />
              <span className="pt-4 lg:pt-0 block lg:inline ml-0 lg:ml-5 text-sm lg:text-lg text-[#6D6C6C]">
                <span className="font-bold">집으로오다</span>는{" "}
                <span className="font-bold">온라인</span>으로 클래스를 배우는
                곳입니다.
              </span>
            </p>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 sm:gap-4 sm:gap-y-10 sm:pt-5 pb-8 lg:pb-20">
              {data?.listProductSub.products &&
                data.listProductSub.products.map((product) => (
                  <div key={`online-class-sub-${product.id}`}>
                    <ComponentProductWidget
                      product={product}
                      refetch={ProductRefetch}
                    />
                  </div>
                ))}
            </div>
          </div>
        </div>
      </main>
      <Footer></Footer>
    </>
  );
};
