import { ApolloError, gql, useMutation } from "@apollo/client";
import React from "react";
import {
  inactivatePromotion,
  inactivatePromotionVariables,
} from "../__generated__/inactivatePromotion";

const INACTIVATE_PROMOTION_MUTATION = gql`
  mutation inactivatePromotion($input: InactivatePromotionInput!) {
    inactivatePromotion(input: $input) {
      ok
      err
    }
  }
`;

export const useInactivatePromotion = (
  onCompleted?: (data: inactivatePromotion) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<inactivatePromotion, inactivatePromotionVariables>(
    INACTIVATE_PROMOTION_MUTATION,
    {
      onCompleted,
      onError,
    }
  );
};
