import React from "react";
import { gql, useLazyQuery, useQuery } from "@apollo/client";
import {
  listMagazine,
  listMagazineVariables,
} from "../__generated__/listMagazine";
import {
  listMagazineActive,
  listMagazineActiveVariables,
} from "../__generated__/listMagazineActive";

const LIST_MAGAZINE_ACTIVE_QUERY = gql`
  query listMagazineActive($input: ListMagazineActiveInput!) {
    listMagazineActive(input: $input) {
      ok
      err
      products {
        uuid
        id
        productInfo {
          title
          place
          summary
          playTime
          price
          category
        }
        createdAt
        productType
        productStatus
        inst {
          id
          user {
            name
          }
        }
        productViews {
          index
          id
          is_rep
          view {
            url
          }
        }
      }
      totalPages
      totalResults
      row
    }
  }
`;

export const useListMagazineActive = (
  onCompleted?: (data: listMagazineActive) => void
) => {
  return useLazyQuery<listMagazineActive, listMagazineActiveVariables>(
    LIST_MAGAZINE_ACTIVE_QUERY,
    {
      onCompleted,
      fetchPolicy: "network-only",
    }
  );
};
