import { ApolloError, gql, useMutation } from "@apollo/client";
import React from "react";
import {
  checkCERTIsValid,
  checkCERTIsValidVariables,
} from "../__generated__/checkCERTIsValid";

const CHECK_CERT_ISVALID_MUTATION = gql`
  mutation checkCERTIsValid($input: CheckCERTIsValidInput!) {
    checkCERTIsValid(input: $input) {
      ok
      err
    }
  }
`;

export const useCheckCERTIsValid = (
  onCompleted?: (data: checkCERTIsValid) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<checkCERTIsValid, checkCERTIsValidVariables>(
    CHECK_CERT_ISVALID_MUTATION,
    {
      onCompleted,
      onError,
    }
  );
};
