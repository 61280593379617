import { useState } from "react";
import { Footer } from "../../../../layout/footer";
import { ASIDE } from "../aside";
import { SubTop } from "../../../../layout/subTop";
import { OnBuy } from "./part/online";
import { OffBuy } from "./part/offline";
export const Buy = () => {
  const [tabs, setTabs] = useState(0);

  return (
    <div className="bg-main bg-opacity-50 w-full h-full min-h-screen">
      <div className="max-w-screen-yl mx-auto w-full h-full min-h-screen  bg-white">
        <SubTop title={"nothing"} />
        <main id="main">
          <div className="lg:grid lg:grid-cols-[300px_1fr]">
            <ASIDE page={2} />
            <div className="lectureWrap">
              <div className="h-12 lg:h-20">
                <div className="subtitle h-full flex flex-wrap content-end lg:px-10 px-3 sm:px-5 lg:gap-x-1">
                  <div
                    className={`inline-block  w-1/3 lg:w-44 ${
                      tabs === 0 && "border-main border-b-2"
                    }`}
                  >
                    <button
                      className={`text-xs sm:text-sm lg:text-base leading-normal font-bold hover:opacity-60 w-full py-1  ${
                        tabs === 0 ? "text-main" : "text-gray-400"
                      }`}
                      onClick={() => setTabs(0)}
                    >
                      구매한 모든 강의
                    </button>
                  </div>
                  <div
                    className={`inline-block  w-1/3 lg:w-44 ${
                      tabs === 1 && "border-main border-b-2"
                    }`}
                  >
                    <button
                      className={`text-xs sm:text-sm lg:text-base leading-normal font-bold hover:opacity-60 w-full py-1  ${
                        tabs === 1 ? "text-main" : "text-gray-400"
                      }`}
                      onClick={() => setTabs(1)}
                    >
                      온라인 구매 강의
                    </button>
                  </div>
                  <div
                    className={`inline-block  w-1/3 lg:w-44 ${
                      tabs === 2 && "border-main border-b-2"
                    }`}
                  >
                    <button
                      className={`text-xs sm:text-sm lg:text-base leading-normal font-bold hover:opacity-60 w-full py-1  ${
                        tabs === 2 ? "text-main" : "text-gray-400"
                      }`}
                      onClick={() => setTabs(2)}
                    >
                      오프라인 구매 강의
                    </button>
                  </div>
                </div>
              </div>
              <div className=" bg-gray-100 h-fix-content relative pb-16 lg:pb-0 grid grid-rows-1">
                <div className="w-full h-full px-3 lg:px-10 py-8 lg:py-12 space-y-10">
                  {tabs !== 2 && <OnBuy />}
                  {tabs !== 1 && <OffBuy />}
                </div>
                <Footer></Footer>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
