import React from "react";
import { Link } from "react-router-dom";
import offline from "../assets/icon/off.svg";
import { listProductMain_listProductMain_offlineClasses } from "../__generated__/listProductMain";
import { useGetBannerOptionActive } from "../hooks/useGetBannerOptionActive";
import { ComponentProductWidget } from "../components/component-product";

interface IMainClassListProps {
  data?: listProductMain_listProductMain_offlineClasses[] | null;
  refetch: () => void;
}

export const SubCommonMainOfflineClass: React.FC<IMainClassListProps> = ({
  data,
  refetch,
}) => {
  const { data: getBannerOptionActiveData } = useGetBannerOptionActive();
  return (
    <div
      id="class_go"
      className={`max-w-screen-yl mx-auto px-4 sm:px-5 pb-8 lg:pb-20`}
    >
      <div className="tracking-tight title flex flex-wrap justify-between">
        <p className="inline-flex flex-wrap content-center text-[#8CBDCB] sm:text-main gangwon text-2xl sm:text-3xl">
          <Link
            to="/classGoList"
            className="hover:underline inline-flex flex-wrap content-center
          "
          >
            <span className="align-middle">지역으로 가다</span>
          </Link>
          <span className="hidden sm:inline-flex flex-wrap content-center align-middle text-sm ml-3">
            <i className="fas fa-chevron-right"></i>
          </span>
          <img
            src={offline}
            alt="오프라인"
            className="hidden sm:inline-block w-10 sm:w-16 ml-3 sm:ml-5"
          />
        </p>
        <button className="sm:hidden flex pb-2 md:pb-0 hover:underline text-text text-xs md:text-base flex-wrap content-end">
          <Link to="/classGoList">more</Link>
        </button>
      </div>
      <div className="sm:flex flex-wrap justify-between hidden">
        <p className="text-text pt-3 text-sm sm:text-base">
          내가 원하는 지역으로 가서 오프라인으로 생생하게 배우세요!
        </p>
        <button className="hidden sm:flex pb-2 md:pb-0 hover:underline text-text text-xs md:text-base flex-wrap content-end">
          <Link to="/classGoList">more</Link>
        </button>
      </div>
      {data && data.length > 0 ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 sm:gap-4 sm:pt-5">
          {data &&
            data.map((product, index) => {
              if (index < 4)
                return (
                  <div key={`offline-class-${product.id}`}>
                    <ComponentProductWidget
                      product={product}
                      refetch={refetch}
                    />
                  </div>
                );
            })}
        </div>
      ) : (
        <div className="py-10 text-center text-bold text-lg">
          조건에 맞는 강의가 없습니다.
        </div>
      )}
    </div>
  );
};
