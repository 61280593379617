import { ApolloError, gql, useMutation } from "@apollo/client";
import React from "react";
import {
  delPromotion,
  delPromotionVariables,
} from "../__generated__/delPromotion";

const DEL_PROMOTION_MUTATION = gql`
  mutation delPromotion($input: DelPromotionInput!) {
    delPromotion(input: $input) {
      ok
      err
    }
  }
`;

export const useDelPromotion = (
  onCompleted?: (data: delPromotion) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<delPromotion, delPromotionVariables>(
    DEL_PROMOTION_MUTATION,
    {
      onCompleted,
      onError,
    }
  );
};
