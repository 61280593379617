import React, { useEffect, useState } from "react";
import { CrConsultReplyInput } from "../__generated__/globalTypes";
import { useForm } from "react-hook-form";
import {
  ICrConsultReplyFormProps,
  useCrConsultReply,
} from "../hooks/useCrConsultReply";
import { crConsultReply } from "../__generated__/crConsultReply";
import { TextareaAutosize } from "@mui/material";
import { ReplyButton } from "./button";

interface IComponentCrConsultReplyForm {
  consultId: number;
  getRefetch: () => void;
  listRefetch: () => void;
}

export const ComponentCrConsultReply: React.FC<
  IComponentCrConsultReplyForm
> = ({ consultId, getRefetch, listRefetch }) => {
  const {
    register,
    getValues,
    setValue,
    handleSubmit,
    setFocus,
    formState: { errors, isValid },
  } = useForm<ICrConsultReplyFormProps>({ mode: "all" });

  const onCrComplete = async (data: crConsultReply) => {
    const {
      crConsultReply: { ok, err },
    } = data;
    if (ok) {
      alert("답변을 완료하였습니다");
      getRefetch();
      listRefetch();
      setValue("note", "");
    }
    if (!ok) {
      alert(err);
    }
  };
  const [crConsultReply, { loading: crConsultReplyLoading }] =
    useCrConsultReply(onCrComplete);
  const onSubmit = () => {
    if (!crConsultReplyLoading) {
      const { note } = getValues();
      const input: CrConsultReplyInput = {
        consultId,
        note,
      };
      crConsultReply({ variables: { input } });
    }
  };

  useEffect(() => {
    setFocus("note");
  }, []);

  return (
    <div className="w-full block">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-7">
          <TextareaAutosize
            {...register(`note`, {
              required: "필수 정보입니다",
            })}
            minRows="5"
            placeholder="답변을 입력해주세요"
            onKeyPress={(e) => {
              if (e.code === "Enter" && e.ctrlKey === true) {
                onSubmit();
              }
            }}
            className="w-full py-1 px-4 bg-white resize-none rounded-sm border-gray-300 focus:border-gray-500 outline-none border-2 col-span-6 h-auto min-h-fit"
          />
          <ReplyButton
            canClick={isValid}
            color={`bg-main text-white`}
            loading={crConsultReplyLoading}
            actionText={`저장`}
          />
        </div>
      </form>
    </div>
  );
};
