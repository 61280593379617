import React, { useEffect, useState } from "react";
import {
  ChangeReceiptOrderInput,
  ChangeReceiptOrderPendingInput,
  ListMagazineInput,
  ListReceiptProductInput,
  OrderStatus,
} from "../__generated__/globalTypes";
import searchImg from "../assets/icon/search.png";
import { ISearchFormProps } from "../routes/admin-list-magazine";
import { useForm } from "react-hook-form";
import { TextButton } from "./button";
import { TableError } from "./table-error";
import { PaginationComponent } from "./pagination";
import { useListReceiptProduct } from "../hooks/useListReceiptProduct";
import { useChangeReceiptOrder } from "../hooks/useChangeReceiptOrder";
import { changeReceiptOrder } from "../__generated__/changeReceiptOrder";
import { changeReceiptOrderPending } from "../__generated__/changeReceiptOrderPending";
import { useChangeReceiptOrderPending } from "../hooks/useChangeReceiptOrderPending";

interface IModalListPayUsers {
  productId: number;
}

export const ModalListPayUsers: React.FC<IModalListPayUsers> = ({
  productId,
}) => {
  const [page, setPage] = useState<number>(1);
  const [search, setSearch] = useState<string>("");
  const word = "수강생";

  const pageHandler = (page: number) => {
    setPage(page);
    listReceiptProductHandler(page, search);
  };

  const { register, getValues, handleSubmit } = useForm<ISearchFormProps>();

  const onSearch = async () => {
    const { search } = getValues();
    if (search) setSearch(search);
    listReceiptProductHandler(page, search);
  };

  const [listReceiptProduct, { data, loading, refetch }] =
    useListReceiptProduct();
  const listReceiptProductHandler = (page: number, search: string) => {
    if (!productId) return;
    const input: ListReceiptProductInput = {
      productId,
      page,
      search,
    };
    listReceiptProduct({ variables: { input } });
  };

  const onChangeOrder = (data: changeReceiptOrder) => {
    const {
      changeReceiptOrder: { ok },
    } = data;
    if (ok) {
      alert("배송 상태를 변경하였습니다");
      refetch();
    }
  };
  const [changeReceiptOrder, { loading: changeReceiptOrderLoading }] =
    useChangeReceiptOrder(onChangeOrder);
  const changeReceiptOrderHandler = (receiptIdToChange: number) => {
    if (changeReceiptOrderLoading) {
      alert("상태를 변경중입니다 잠시후에 시도해주세요");
      return;
    }
    const input: ChangeReceiptOrderInput = {
      receiptIdToChange,
    };
    changeReceiptOrder({ variables: { input } });
  };

  const onChangeOrderPending = (data: changeReceiptOrderPending) => {
    const {
      changeReceiptOrderPending: { ok },
    } = data;
    if (ok) {
      alert("배송 상태를 변경하였습니다");
      refetch();
    }
  };
  const [
    changeReceiptOrderPending,
    { loading: changeReceiptOrderPendingLoading },
  ] = useChangeReceiptOrderPending(onChangeOrderPending);
  const changeReceiptOrderPendingHandler = (receiptIdToChange: number) => {
    if (changeReceiptOrderPendingLoading) {
      alert("상태를 변경중입니다 잠시후에 시도해주세요");
      return;
    }
    const input: ChangeReceiptOrderPendingInput = {
      receiptIdToChange,
    };
    changeReceiptOrderPending({ variables: { input } });
  };

  useEffect(() => {
    listReceiptProductHandler(page, search);
  }, []);

  return (
    <div className="modal max-w-[90%]">
      <div className="text-right pb-8">
        <form onSubmit={handleSubmit(onSearch)}>
          <p className="inline-block max-w-lg text-right relative shadow-md">
            <input
              {...register(`search`)}
              type="text"
              className="w-full py-1 px-4 bg-white rounded-full"
              placeholder="수강생 이름으로 검색"
            />
            <button className="absolute right-3 top-1">
              <img src={searchImg} alt="찾기" className="w-5" />
            </button>
          </p>
        </form>
      </div>
      {/* 여기 */}
      <div className="bg-white sm:px-5 pb-5 rounded-md min-w-max">
        <p className="text-main font-bold pb-3">수강생 목록</p>
        <table className="w-full">
          <thead>
            <tr className="bg-main  border-gray-300">
              <th className="text-white md:table-cell hidden p-1">No.</th>
              <th className="text-white ">이름</th>
              <th className="text-white ">주소</th>
              <th className="text-white md:table-cell hidden">전화번호</th>
              <th className="text-white ">상태</th>
              <th className="text-white ">기능</th>
            </tr>
          </thead>
          <tbody>
            {!loading &&
              data?.listReceiptProduct.receipts &&
              data.listReceiptProduct.receipts.map((receipt, index) => (
                <>
                  {receipt.address ? (
                    <tr
                      key={`receipt-${productId}-${receipt?.id}`}
                      className="border-b-2 border-gray-300 h-4"
                    >
                      <td className="text-xs lg:text-sm text-center h-full p-1 relative lg:min-w-[100px] md:table-cell hidden">
                        {data.listReceiptProduct.totalResults &&
                          data.listReceiptProduct.row &&
                          `${
                            data.listReceiptProduct.totalResults -
                            data.listReceiptProduct.row * (page - 1) -
                            index
                          }`}
                      </td>
                      <td className="text-xs lg:text-sm text-center h-full p-1 relative lg:min-w-[100px]">
                        {receipt?.receiver && `${receipt.receiver}`}
                      </td>
                      <td className="text-xs lg:text-sm text-center py-1 px-1 max-w-[100px] h-full overflow-hidden lg:min-w-[350px]">
                        <p className="h-full ellipsis1 overflow-hidden">
                          {receipt?.address && `${receipt.address}`}
                        </p>
                      </td>
                      <td className="text-xs lg:text-sm text-center py-1 px-1 h-full overflow-hidden lg:min-w-[150px] md:table-cell hidden">
                        {receipt?.phone && `${receipt.phone}`}
                      </td>
                      {receipt.orderStatus === OrderStatus.Pending && (
                        <td className="text-xs lg:text-sm text-center py-1 px-1 h-full overflow-hidden lg:min-w-[80px]">
                          배송대기
                        </td>
                      )}
                      {receipt.orderStatus === OrderStatus.HandOut && (
                        <td className="text-xs lg:text-sm text-center py-1 px-1 h-full overflow-hidden lg:min-w-[80px]">
                          배송중
                        </td>
                      )}
                      {receipt.orderStatus === OrderStatus.Delivered && (
                        <td className="text-xs lg:text-sm text-center py-1 px-1 h-full overflow-hidden lg:min-w-[80px]">
                          배송완료
                        </td>
                      )}
                      <td className="text-xs lg:text-sm text-center py-1 px-1 h-full overflow-hidden lg:min-w-[80px]">
                        {receipt.orderStatus === OrderStatus.Pending ? (
                          <TextButton
                            canClick={!changeReceiptOrderLoading}
                            actionText={`배송시작`}
                            onClick={() =>
                              changeReceiptOrderHandler(receipt.id)
                            }
                          />
                        ) : (
                          <TextButton
                            canClick={!changeReceiptOrderPendingLoading}
                            actionText={`되돌리기`}
                            onClick={() => {
                              if (receipt.status === OrderStatus.Delivered) {
                                if (
                                  !window.confirm(
                                    "배송완료된 상품입니다 되돌리시겠습니까?"
                                  )
                                )
                                  return;
                              }
                              changeReceiptOrderPendingHandler(receipt.id);
                            }}
                          />
                        )}
                      </td>
                    </tr>
                  ) : (
                    <tr
                      key={`receipt-${productId}-${receipt?.id}`}
                      className="border-b-2 border-gray-300 h-4"
                    >
                      <td className="text-sm text-center h-full relative min-w-[100px]">
                        {data.listReceiptProduct.totalResults &&
                          data.listReceiptProduct.row &&
                          `${
                            data.listReceiptProduct.totalResults -
                            data.listReceiptProduct.row * (page - 1) -
                            index
                          }`}
                      </td>

                      <td className="text-sm text-center py-1 px-1 h-full overflow-hidden min-w-[100px]">
                        {receipt?.user?.name && `${receipt.user.name}`}
                      </td>
                      <td className="text-sm text-center py-1 px-1 h-full overflow-hidden min-w-[350px]">
                        {receipt?.user?.address && `${receipt.user.address}`}
                      </td>
                      <td className="text-sm text-center h-full relative min-w-[150px]">
                        {receipt?.user?.phone && `${receipt.user.phone}`}
                      </td>
                      <td className="text-sm text-center py-1 px-1 h-full w-1/6 overflow-hidden"></td>
                      <td></td>
                    </tr>
                  )}
                </>
              ))}
          </tbody>
        </table>
        {loading && <TableError errorMessage={`정보를 불러오는 중입니다`} />}
        {!loading &&
          data?.listReceiptProduct.receipts &&
          data.listReceiptProduct.receipts.length < 1 && (
            <TableError errorMessage={`${word} 정보가 없습니다`} />
          )}
        {!loading &&
          data?.listReceiptProduct.receipts &&
          data.listReceiptProduct.receipts.length > 0 && (
            <PaginationComponent
              page={page}
              totalPages={Number(data.listReceiptProduct.totalPages)}
              totalResults={Number(data.listReceiptProduct.totalResults)}
              pageHandler={pageHandler}
            />
          )}
      </div>
    </div>
  );
};
