import React from "react";
import { gql, useLazyQuery, useQuery } from "@apollo/client";
import { listPlace, listPlaceVariables } from "../__generated__/listPlace";

const LIST_PLACE_QUERY = gql`
  query listPlace($input: ListPlaceInput!) {
    listPlace(input: $input) {
      ok
      err
      products {
        uuid
        id
        productInfo {
          title
          place
          summary
          playTime
          price
        }
        createdAt
        productStatus
        productType
        inst {
          id
          user {
            name
          }
        }
        productViews {
          index
          id
          is_rep
          view {
            url
          }
        }
      }
      totalPages
      totalResults
      row
    }
  }
`;

export const useListPlace = (onCompleted?: (data: listPlace) => void) => {
  return useLazyQuery<listPlace, listPlaceVariables>(LIST_PLACE_QUERY, {
    onCompleted,
    fetchPolicy: "network-only",
  });
};
