import React, { useEffect, useState } from "react";
import { UpPromotionProductInput } from "../__generated__/globalTypes";
import { useForm } from "react-hook-form";
import {
  IUpPromotionProductFormProps,
  useUpPromotionProduct,
} from "../hooks/useUpPromotionProduct";
import { upPromotionProduct } from "../__generated__/upPromotionProduct";

interface IUpDiscountRateForm {
  promotionProductIdToUp: number;
  defaultDiscountRate: number;
  refetch: () => void;
}

export const UpDiscountRateForm: React.FC<IUpDiscountRateForm> = ({
  promotionProductIdToUp,
  defaultDiscountRate,
  refetch,
}) => {
  const { register, getValues, handleSubmit } =
    useForm<IUpPromotionProductFormProps>();

  const onComplete = (data: upPromotionProduct) => {
    const {
      upPromotionProduct: { ok, err },
    } = data;
    if (ok) {
      alert("저장되었습니다");
      refetch();
    } else {
      alert(err);
    }
  };
  const [upPromotionProduct, { loading, error }] =
    useUpPromotionProduct(onComplete);
  const onSubmit = () => {
    if (!loading) {
      const { discountRate } = getValues();
      const input: UpPromotionProductInput = {
        promotionProductIdToUp,
        discountRate: discountRate ? +discountRate : 1,
      };
      upPromotionProduct({ variables: { input } });
    }
  };

  return (
    <div className="w-full block">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-6">
          <input
            {...register(`discountRate`, {
              required: "필수 정보입니다",
              min: { value: 1, message: "최솟값은 1입니다" },
              max: { value: 100, message: "최댓값은 100입니다" },
            })}
            type="number"
            defaultValue={defaultDiscountRate}
            className="w-full py-1 px-4 bg-white rounded-sm border-gray-500 border-2 col-span-4 text-right"
          />
          <button className="col-span-2">저장</button>
        </div>
      </form>
    </div>
  );
};
