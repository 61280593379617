import { createBrowserHistory } from "history";
import moment from "moment";
import { useEffect } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { useGetReceipt } from "../hooks/useGetReceipt";
import { Footer } from "../layout/footer";
import { LayoutTop } from "../layout/top";
import { getReceipt } from "../__generated__/getReceipt";
import { GetReceiptInput } from "../__generated__/globalTypes";

export const GeneralPaymentComplete = () => {
  const history = createBrowserHistory();
  const params = useParams();

  const onCompleted = (data: getReceipt) => {};

  const [getReceipt, { data, loading }] = useGetReceipt(onCompleted);
  useEffect(() => {
    if (params.receiptId !== undefined) {
      const getReceiptInput: GetReceiptInput = {
        receiptId: +params.receiptId,
      };
      getReceipt({ variables: { input: getReceiptInput } });
    }
  }, []);

  return (
    <>
      <LayoutTop title="nothting"></LayoutTop>
      <main id="main">
        <div className="mx-auto max-w-[720px] py-[5%]">
          <div className="text-left border-4 border-main rounded-lg p-10">
            <p className="text-2xl sm:text-4xl font-bold gangwon text-main">
              주문이 정상적으로 완료되었습니다.
            </p>
            <div className="flex flex-wrap flex-col gap-y-5 pt-20 space-y-6">
              <div className="flex flex-wrap flex-col sm:flex-row  gap-x-5">
                <div className="w-28 font-bold pb-2 sm:pb-0">주문번호</div>
                <div className="flex-1 text-main font-bold">
                  {data?.getReceipt.receipt?.pgOption?.merchant_uid}
                </div>
              </div>
              <div className="flex flex-wrap flex-col sm:flex-row  gap-x-5">
                <div className="w-28 font-bold pb-2 sm:pb-0">상품명</div>
                <a
                  href="/class/5"
                  className="flex-1 text-blue-500 hover:underline"
                >
                  {data?.getReceipt.receipt?.pgOption?.name}
                </a>
              </div>
              {data?.getReceipt.receipt?.product?.productKit?.desc && (
                <div className="flex flex-wrap flex-col sm:flex-row  gap-x-5">
                  <div className="w-28 font-bold pb-2 sm:pb-0">배송지 정보</div>
                  <div className="flex-1 space-y-1">
                    <p>{data?.getReceipt.receipt?.receiver}</p>
                    <p>{data?.getReceipt.receipt?.phone}</p>
                    <p>{data?.getReceipt.receipt?.address}</p>
                    <p>
                      배송 메시지 :{" "}
                      <span className="text-sm noto">
                        {data?.getReceipt.receipt?.message}
                      </span>{" "}
                    </p>
                  </div>
                </div>
              )}
              <div className="flex flex-wrap flex-col sm:flex-row  gap-x-5">
                <div className="w-28 font-bold pb-2 sm:pb-0">결제정보</div>
                <div className="flex-1 ">
                  <p>
                    {data?.getReceipt.receipt?.paid_amount.toLocaleString()}원
                  </p>

                  {data?.getReceipt.receipt?.paid_at && (
                    <p>
                      결제일자:{" "}
                      {moment(Number(data?.getReceipt.receipt?.paid_at)).format(
                        `YYYY-M-D`
                      )}
                    </p>
                  )}

                  {data?.getReceipt.receipt?.status === `paid` ? (
                    <p>승인완료</p>
                  ) : (
                    <p>
                      {data?.getReceipt.receipt?.status === `ready` &&
                        "대기 중"}
                    </p>
                  )}
                  {data?.getReceipt.receipt?.pgOption?.pay_method === `vbank` &&
                    data?.getReceipt.receipt?.status === `ready` && (
                      <>
                        <p>
                          주문자명:{" "}
                          {data.getReceipt.receipt.pgOption.vbank_holder}
                        </p>
                        <p>
                          입금기한:{" "}
                          {data?.getReceipt.receipt?.pgOption.vbank_date}
                        </p>
                        <p>
                          입금계좌:{" "}
                          {data.getReceipt.receipt.pgOption.vbank_name}{" "}
                          {data.getReceipt.receipt.pgOption.vbank_num}
                        </p>
                      </>
                    )}
                </div>
              </div>
              <div className="flex flex-wrap gap-x-5">
                {data?.getReceipt.receipt?.status === "paid" && (
                  <a
                    href={
                      data?.getReceipt.receipt?.pgOption?.receipt_url
                        ? data.getReceipt.receipt.pgOption.receipt_url
                        : ""
                    }
                    className="w-28 font-bold block flex-1 text-blue-500 hover:underline"
                  >
                    영수증 확인
                  </a>
                )}
              </div>
            </div>
          </div>
          <div className="py-10 text-center">
            <button className="w-full max-w-[300px] bg-main text-white rounded-md">
              <Link className="block h-full py-2" to="/">
                메인으로 가기
              </Link>
            </button>
          </div>
        </div>
      </main>
      <Footer></Footer>
    </>
  );
};
