import React from "react";
import { gql, useLazyQuery } from "@apollo/client";
import {
  listPromotion,
  listPromotionVariables,
} from "../__generated__/listPromotion";

const LIST_PROMOTION_QUERY = gql`
  query listPromotion($input: ListPromotionInput!) {
    listPromotion(input: $input) {
      ok
      err
      totalPages
      totalResults
      row
      promotions {
        id
        title
        badge
        desc
        discountRate
        is_active
        startAt
        endAt
        author {
          name
        }
        createdAt
        updatedAt
        promotionProducts {
          id
        }
      }
    }
  }
`;

export const useListPromotion = (
  onCompleted?: (data: listPromotion) => void
) => {
  return useLazyQuery<listPromotion, listPromotionVariables>(
    LIST_PROMOTION_QUERY,
    {
      onCompleted,
      fetchPolicy: `network-only`,
    }
  );
};
