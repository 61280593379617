import { ApolloError, gql, useMutation } from "@apollo/client";
import React from "react";
import { UpProductInput } from "../__generated__/globalTypes";
import { upProduct, upProductVariables } from "../__generated__/upProduct";

export interface IUpProductFormProps extends UpProductInput {}

const UP_PRODUCT_MUTATION = gql`
  mutation upProduct($input: UpProductInput!) {
    upProduct(input: $input) {
      ok
      err
    }
  }
`;

export const useUpProduct = (
  onCompleted?: (data: upProduct) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<upProduct, upProductVariables>(UP_PRODUCT_MUTATION, {
    onCompleted,
    onError,
  });
};
