import React, { useEffect, useState } from "react";
import { createBrowserHistory } from "history";
import { useParams } from "react-router";
import { ListBannerOptionInput } from "../__generated__/globalTypes";
import { UpBannerOptionForm4_1 } from "../components/up-banner-option-form-4-1";
import { useListBannerOption } from "../hooks/useListBannerOption";
import { listBannerOption } from "../__generated__/listBannerOption";
import { UpBannerOptionForm4_1_5_4 } from "../components/up-banner-option-form-4-1-5-4";

export const SubAdminUpBannerClass: React.FC = () => {
  const history = createBrowserHistory();
  const params = useParams();

  const onListCompleted = (data: listBannerOption) => {};
  const [listBannerOption, { data, loading, refetch }] =
    useListBannerOption(onListCompleted);
  const listBannerOptionHandler = (bannerId: number) => {
    const listBannerOptionInput: ListBannerOptionInput = {
      bannerId,
    };
    listBannerOption({ variables: { input: listBannerOptionInput } });
  };

  useEffect(() => {
    if (params.bannerId) {
      listBannerOptionHandler(+params.bannerId);
    }
  }, []);

  return (
    <div className="bg-white p-5 rounded-2xl shadow-lg">
      <div className="p-5 space-y-5 lg:space-y-10">
        <div className="space-y-3">
          <div className="w-full grid grid-cols-1 gap-3">
            {loading && <div>정보를 불러오는 중입니다</div>}
            {!loading && (
              <>
                <UpBannerOptionForm4_1_5_4
                  productType={null}
                  bannerOptionId={
                    data?.listBannerOption.bannerOptions
                      ? Number(data?.listBannerOption.bannerOptions[6].id)
                      : 0
                  }
                  link={`${
                    data?.listBannerOption.bannerOptions &&
                    data?.listBannerOption.bannerOptions[6].link
                  }`}
                  bannerTitle={`지역으로 가다 배너`}
                  posterUrl={
                    data?.listBannerOption.bannerOptions
                      ? data.listBannerOption.bannerOptions[6].view?.url
                      : ``
                  }
                  subPosterUrl={
                    data?.listBannerOption.bannerOptions
                      ? data.listBannerOption.bannerOptions[6].subView?.url
                      : ``
                  }
                  refetch={refetch}
                />
                <UpBannerOptionForm4_1_5_4
                  productType={null}
                  bannerOptionId={
                    data?.listBannerOption.bannerOptions
                      ? Number(data?.listBannerOption.bannerOptions[7].id)
                      : 0
                  }
                  link={`${
                    data?.listBannerOption.bannerOptions &&
                    data?.listBannerOption.bannerOptions[7].link
                  }`}
                  bannerTitle={`집으로 오다 배너`}
                  posterUrl={
                    data?.listBannerOption.bannerOptions
                      ? data.listBannerOption.bannerOptions[7].view?.url
                      : ``
                  }
                  subPosterUrl={
                    data?.listBannerOption.bannerOptions
                      ? data.listBannerOption.bannerOptions[7].subView?.url
                      : ``
                  }
                  refetch={refetch}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
