import { useEffect, useState } from "react";
import { Footer } from "../../../layout/footer";
import { instructors } from "../data";
import search from "../../../assets/icon/search.png";
import usePagination from "@mui/material/usePagination";
import { styled } from "@mui/material/styles";
import { SubTop } from "../../../layout/subTop";
import { ASIDE_Q } from "../aside";
import { pageIndicator } from "../../../apollo";
export const Inst_Q = () => {
  useEffect(() => {
    pageIndicator("insts");
  }, []);
  const List = styled("ul")({
    listStyle: "none",
    paddingTop: 20,
    textAlign: "center",
    display: "block",
  });
  const { items } = usePagination({
    count: 10,
  });
  const [tabs, setTabs] = useState(0);
  return (
    <div className="bg-main bg-opacity-50 w-full h-full min-h-screen">
      <div className="w-[1440px] mx-auto h-full min-h-screen  bg-white">
        <SubTop title={"top"} children={"큐레이터"} />
        <main id="main">
          <div className="grid grid-cols-[300px_1fr]">
            <ASIDE_Q />
            <div className="mypages ">
              <div className="lectureWrap">
                <div className="h-20">
                  <div className="h-full flex-wrap flex justify-between px-10 content-center">
                    <div className="subtitle h-full flex flex-wrap content-end  gap-x-3">
                      <div
                        className={`inline-block  w-44 ${
                          tabs === 0 && "border-main border-b-2"
                        }`}
                      >
                        <button
                          className={`text-xl leading-normal font-bold hover:opacity-60 w-full py-1  ${
                            tabs === 0 ? "text-main" : "text-gray-400"
                          }`}
                          onClick={() =>
                            setTabs(() => {
                              return 0;
                            })
                          }
                        >
                          작가목록
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className=" bg-gray-100 h-fix-content relative">
                  <div className={`w-full h-full px-10 py-12`}>
                    {/* 여기 */}
                    <div className="bg-white p-5 rounded-2xl shadow-lg">
                      <p className="text-main font-bold pb-3">작가 리스트</p>
                      <table className="w-full">
                        <thead>
                          <tr className="bg-main  border-gray-300">
                            <th className="text-white ">작가번호</th>
                            <th className="text-white ">작가</th>
                            <th className="text-white ">연락처</th>
                            <th className="text-white ">평점</th>
                            <th className="text-white ">강의 수</th>
                            <th className="text-white ">요청</th>
                          </tr>
                        </thead>
                        <tbody>
                          {instructors.map((obj, index) => (
                            <tr
                              key={obj.numbering + index}
                              className="border-b-2 border-gray-300 h-4"
                            >
                              <td className="text-sm text-center py-1 px-1 h-full overflow-hidden">
                                {obj.numbering}
                              </td>
                              <td className="text-sm text-center h-full relative">
                                {obj.con_name}
                              </td>
                              <td className="text-sm text-center  relative">
                                {obj.phone}
                              </td>
                              <td className="text-sm text-center py-1 px-1 h-full overflow-hidden">
                                {obj.point}
                              </td>
                              <td className="text-sm text-center py-1 px-1 h-full overflow-hidden ">
                                {obj.lectures}
                              </td>
                              <td className="text-sm text-center py-1 px-1 h-full overflow-hidden ">
                                {obj.req_num}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <List>
                        {items.map(
                          ({ page, type, selected, ...item }, index) => {
                            let children = null;

                            if (
                              type === "start-ellipsis" ||
                              type === "end-ellipsis"
                            ) {
                              children = "…";
                            } else if (type === "page") {
                              children = (
                                <button
                                  type="button"
                                  style={{
                                    fontWeight: selected ? "bold" : undefined,
                                  }}
                                  {...item}
                                >
                                  {page}
                                </button>
                              );
                            } else if (type === "previous") {
                              children = (
                                <button type="button" {...item}>
                                  <i className="fas fa-angle-left"></i>
                                </button>
                              );
                            } else if (type === "next") {
                              children = (
                                <button type="button" {...item}>
                                  <i className="fas fa-angle-right"></i>
                                </button>
                              );
                            }

                            return (
                              <li
                                key={index + type}
                                className={`text-xs px-2  border-grays inline-block ${
                                  (type === "page" &&
                                    page !== 10 &&
                                    "border-r") ||
                                  (type === "end-ellipsis" && "border-r") ||
                                  (type === "start-ellipsis" && "border-r")
                                }`}
                              >
                                {children}
                              </li>
                            );
                          }
                        )}
                      </List>
                    </div>
                  </div>
                  <Footer></Footer>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
