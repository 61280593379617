import online from "../../../../../assets/icon/on.svg";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { comma } from "../../../../../apollo";
import { useListBuyMineOnline } from "../../../../../hooks/useListBuyMineOnline";
import { DoorStatus, DoorType } from "../../../../../__generated__/globalTypes";
export const OnBuy = () => {
  const [listBuyMineOnline, { data, loading }] = useListBuyMineOnline();
  const LinkToReceipt = (id: any) => {
    window.location.href = `/payment/completed/${id}`;
  };

  useEffect(() => {
    listBuyMineOnline({ variables: { input: { page: 1 } } });
  }, []);

  return (
    <div className="online bg-white p-5 lg:p-10 rounded-lg">
      <div>
        <p className="pl-2 text-xl text-main leading-normal pb-3 ">
          <span className="align-middle font-bold">구매한 온라인 강의</span>
          <img src={online} alt="온라인" className="inline-block w-10 ml-3" />
        </p>
        <table className="w-full overflow-hidden">
          <thead>
            <tr className="bg-main  border-gray-300">
              <th className="text-white text-md py-1 sm:table-cell hidden">
                No.
              </th>
              <th className="text-white text-md py-1">강의명</th>
              <th className="text-white text-md py-1 min-w-[90px]">가격</th>
              <th className="text-white text-md py-1 min-w-[90px]">결제상태</th>
              <th className="text-white text-md py-1 hidden sm:table-cell">
                구매일
              </th>
            </tr>
          </thead>
          <tbody>
            {!loading &&
              data?.listBuyMineOnline.buys &&
              data.listBuyMineOnline.buys.map((buy, idx) => (
                <tr
                  key={`buy-${buy.id}`}
                  className="border-b-2 border-gray-300 hover:bg-gray-100 cursor-pointer"
                  onClick={() => {
                    LinkToReceipt(buy.id);
                  }}
                >
                  <td className="test-sm text-center py-1 sm:table-cell hidden">
                    {idx + 1}
                  </td>
                  <td className="test-sm text-center py-1 ">
                    <p className="w-full ellipsis1 h-[20px] overflow-hidden">
                      {buy.product?.productInfo?.title}
                    </p>
                  </td>
                  <td className={`text-sm text-center py-1`}>
                    {comma(Number(buy.product?.productInfo?.price))}원
                  </td>
                  <td className={`text-sm text-center py-1`}>
                    {buy.status === `paid` && "결제완료"}
                    {buy.status === `ready` && "결제대기"}
                    {buy.status === `refunded` && "환불완료"}
                    {buy.status !== `paid` &&
                      buy.status !== `ready` &&
                      buy.status !== `refunded` &&
                      "만료"}
                  </td>
                  <td className="test-sm text-center py-1 sm:table-cell hidden">
                    <p className="w-full ellipsis1 h-[20px] overflow-hidden">
                      {moment(buy.createdAt).format("yy년 M월 D일 HH:mm")}
                    </p>
                  </td>
                </tr>
              ))}
            {!loading &&
              data?.listBuyMineOnline.buys?.length !== undefined &&
              data.listBuyMineOnline.buys.length === 0 && (
                <tr>
                  <td colSpan={4} className={`text-center py-1`}>
                    신청한 강의가 없습니다
                  </td>
                </tr>
              )}
            {loading && (
              <tr>
                <td colSpan={4} className={`text-center py-1`}>
                  강의를 불러오는 중입니다
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};
