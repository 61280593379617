import React from "react";
import { gql, useLazyQuery, useQuery } from "@apollo/client";
import { listDoorMine } from "../__generated__/listDoorMine";

const LIST_DOOR_MINE_QUERY = gql`
  query listDoorMine {
    listDoorMine {
      ok
      err
      doors {
        id
        title
        note
        status
        type
        createdAt
        updatedAt
        uuid
      }
    }
  }
`;

export const useListDoorMine = (onCompleted?: (data: listDoorMine) => void) => {
  return useQuery<listDoorMine>(LIST_DOOR_MINE_QUERY, {
    onCompleted,
    fetchPolicy: "network-only",
  });
};
