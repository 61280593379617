import { useEffect, useState } from "react";
import { pageIndicator } from "../apollo";
import searchImg from "../assets/icon/search.png";
import { AsideAdminComponent } from "../layout/aside-admin";
import { Footer } from "../layout/footer";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router";
import {
  DelProductInput,
  ListPlaceInput,
  ProductStatus,
  UpProductStatusInput,
} from "../__generated__/globalTypes";
import { useListPlace } from "../hooks/useListPlace";
import { TableError } from "../components/table-error";
import { TextButton } from "../components/button";
import { useUpProductStatus } from "../hooks/useUpProductStatus";
import { upProductStatus } from "../__generated__/upProductStatus";
import { useForm } from "react-hook-form";
import { AdminTop } from "../layout/adminTop";
import { ISearchFormProps } from "./admin-list-magazine";
import { PaginationComponent } from "../components/pagination";
import { ComponentTabLink, ITabLink } from "../components/component-tab";
import { delProduct } from "../__generated__/delProduct";
import { useDelProduct } from "../hooks/useDelProduct";

export const AdminListPlace = () => {
  const history = useNavigate();
  const location = useLocation();
  const [page, setPage] = useState<number>(1);
  const [search, setSearch] = useState<string>("");

  const tabList: ITabLink[] = [
    { text: "목록", to: "/admin/place", selected: true },
    { text: "글쓰기", to: "/admin/place/create" },
  ];

  const word = "플레이스";

  const pageHandler = (page: number) => {
    setPage(page);
    history({
      pathname: "/admin/place",
      search: `?page=${page}${search ? `&search=${search}` : ""}`,
    });
    listPlaceHandler(page, search);
  };

  const { register, getValues, handleSubmit } = useForm<ISearchFormProps>({
    mode: "all",
  });

  const onSearch = async () => {
    const { search } = getValues();
    setSearch(search);
    history({
      pathname: "/admin/place",
      search: `${search ? `?search=${search}` : ""}`,
    });
    listPlaceHandler(1, search);
  };

  useEffect(() => {
    pageIndicator("place");
  }, []);

  const onUpCompleted = async (data: upProductStatus) => {
    const {
      upProductStatus: { ok, err },
    } = data;
    if (ok) {
      alert(`플레이스 상태가 수정되었습니다`);
      await refetch();
    } else {
      alert(err);
    }
  };
  const [upProductStatus, { loading: upProductStatusLoading }] =
    useUpProductStatus(onUpCompleted);
  const upProductStatusHandler = (
    productIdToUp: number,
    productStatus: ProductStatus
  ) => {
    if (!upProductStatusLoading) {
      const upProductStatusInput: UpProductStatusInput = {
        productIdToUp,
        productStatus,
      };
      upProductStatus({ variables: { input: upProductStatusInput } });
    }
  };

  const onDelCompleted = async (data: delProduct) => {
    const {
      delProduct: { ok, err },
    } = data;
    if (ok) {
      alert(`플레이스를 삭제하였습니다`);
      await refetch();
    } else {
      alert(err);
    }
  };
  const [delProduct, { loading: delProductLoading }] =
    useDelProduct(onDelCompleted);
  const delProductHandler = (productIdToDel: number) => {
    if (delProductLoading) return;
    if (
      window.confirm(
        "삭제한 자료는 복구할 수 없습니다, 정말로 삭제하시겠습니까?"
      )
    ) {
      const input: DelProductInput = {
        productIdToDel,
      };
      delProduct({ variables: { input } });
    }
  };
  const [listPlace, { data, loading, refetch }] = useListPlace();
  const listPlaceHandler = (page: number | null, search: string | null) => {
    const listPlaceInput: ListPlaceInput = {
      ...(page && { page: +page }),
      ...(search && { search: decodeURI(search) }),
    };
    listPlace({ variables: { input: listPlaceInput } });
  };

  useEffect(() => {
    setPage(
      location.search.split("page=").length > 1
        ? +location.search.split("page=")[1].split("&")[0]
        : 1
    );
    setSearch(
      location.search.split("search=").length > 1
        ? location.search.split("search=")[1].split("&")[0]
        : ""
    );
    listPlaceHandler(page, search);
  }, []);

  return (
    <div className="bg-main bg-opacity-50 w-full h-full min-h-screen">
      <div className="w-[1440px] mx-auto h-full min-h-screen  bg-white">
        <AdminTop title={"top"} children={"관리자모드"} />
        <main id="main">
          <div className="grid grid-cols-[300px_1fr]">
            <AsideAdminComponent />
            <div className="mypages ">
              <div className="lectureWrap">
                <ComponentTabLink tabs={tabList} />
                <div className=" bg-gray-100 h-fix-content relative">
                  <div className={`w-full h-full px-10 py-12`}>
                    <div className="text-right pb-8">
                      <form onSubmit={handleSubmit(onSearch)}>
                        <p className="inline-block max-w-md w-full text-right relative">
                          <input
                            {...register(`search`)}
                            type="text"
                            className="w-full py-1 px-4 bg-white rounded-full"
                            placeholder="플레이스 이름으로 검색"
                          />
                          <button className="absolute right-3 top-1">
                            <img src={searchImg} alt="찾기" className="w-5" />
                          </button>
                        </p>
                      </form>
                    </div>
                    {/* 여기 */}
                    <div className="bg-white p-5 rounded-2xl shadow-lg">
                      <p className="text-main font-bold pb-3">플레이스 목록</p>
                      <table className="w-full">
                        <colgroup>
                          <col className="w-2/12" />
                          <col className="w-4/12" />
                          <col className="w-3/12" />
                          <col className="w-1/12" />
                          <col className="w-2/12" />
                        </colgroup>
                        <thead>
                          <tr className="bg-main  border-gray-300">
                            <th className="text-white ">번호</th>
                            <th className="text-white ">제목</th>
                            <th className="text-white ">주소</th>
                            <th className="text-white ">상태</th>
                            <th className="text-white ">기능</th>
                          </tr>
                        </thead>
                        <tbody>
                          {!loading &&
                            data?.listPlace.products &&
                            data.listPlace.products.map((place) => (
                              <tr
                                key={`place-${place?.id}`}
                                className="border-b-2 border-gray-300 h-4"
                              >
                                <td className="text-sm text-center py-1 px-1 h-full overflow-hidden">
                                  {place?.uuid && place.uuid}
                                </td>
                                <td className="text-sm text-center h-full relative">
                                  <Link
                                    to={`/admin/place/update/${place.id}`}
                                    className={`cursor-pointer hover:underline`}
                                  >
                                    {place?.productInfo?.title &&
                                      place.productInfo.title}
                                  </Link>
                                </td>

                                <td className="text-sm text-center py-1 px-1 h-full overflow-hidden">
                                  {place?.productInfo?.place &&
                                    place.productInfo.place}
                                </td>
                                <td className="text-sm text-center py-1 px-1 h-full overflow-hidden ">
                                  {place?.productStatus && place.productStatus}
                                </td>
                                <td className="text-sm text-center py-1 px-1 h-full w-1/6 overflow-hidden">
                                  <TextButton
                                    canClick={
                                      !loading &&
                                      place.productStatus ===
                                        ProductStatus.Ready
                                    }
                                    actionText={`활성화`}
                                    onClick={() =>
                                      upProductStatusHandler(
                                        place.id,
                                        ProductStatus.On
                                      )
                                    }
                                  />
                                  {` / `}
                                  <TextButton
                                    canClick={
                                      !loading &&
                                      place.productStatus === ProductStatus.On
                                    }
                                    actionText={`비활성화`}
                                    onClick={() =>
                                      upProductStatusHandler(
                                        place.id,
                                        ProductStatus.Ready
                                      )
                                    }
                                  />
                                  {` / `}
                                  <TextButton
                                    canClick={true}
                                    actionText={`삭제`}
                                    onClick={() => delProductHandler(place.id)}
                                  />
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                      {loading && (
                        <TableError errorMessage={`정보를 불러오는 중입니다`} />
                      )}
                      {!loading &&
                        data?.listPlace.products &&
                        data.listPlace.products.length < 1 && (
                          <TableError
                            errorMessage={`해당하는 ${word} 정보가 없습니다`}
                          />
                        )}
                      {!loading &&
                        data?.listPlace.products &&
                        data.listPlace.products.length > 0 && (
                          <PaginationComponent
                            page={page}
                            totalPages={Number(data.listPlace.totalPages)}
                            totalResults={Number(data.listPlace.totalResults)}
                            pageHandler={pageHandler}
                          />
                        )}
                    </div>
                  </div>
                  <Footer></Footer>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
