import { useEffect, useState } from "react";
import { Footer } from "../../../layout/footer";
import usePagination from "@mui/material/usePagination";
import { styled } from "@mui/material/styles";
import { AsideAdminComponent } from "../../../layout/aside-admin";
import { SubTop } from "../../../layout/subTop";
import { pageIndicator } from "../../../apollo";
import { SubAdminListVerifiedInst } from "../../../sub-routes/sub-admin-list-verified-inst";
import { SubAdminListUnverifiedInst } from "../../../sub-routes/sub-admin-list-unverified-inst";
import { AdminTop } from "../../../layout/adminTop";

export const Inst_ad = () => {
  const List = styled("ul")({
    listStyle: "none",
    paddingTop: 20,
    textAlign: "center",
    display: "block",
  });
  const { items } = usePagination({
    count: 10,
  });
  const [tabs, setTabs] = useState(0);

  useEffect(() => {
    pageIndicator("insts");
  }, []);

  return (
    <div className="bg-main bg-opacity-50 w-full h-full min-h-screen">
      <div className="w-[1440px] mx-auto h-full min-h-screen  bg-white">
        <AdminTop title={"top"} children={"관리자모드"} />
        <main id="main">
          <div className="grid grid-cols-[300px_1fr]">
            <AsideAdminComponent />
            <div className="mypages ">
              <div className="lectureWrap">
                <div className="h-20">
                  <div className="h-full flex-wrap flex justify-between px-10 content-center">
                    <div className="subtitle h-full flex flex-wrap content-end  gap-x-3">
                      <div
                        className={`inline-block  w-44 ${
                          tabs === 0 && "border-main border-b-2"
                        }`}
                      >
                        <button
                          className={`text-base leading-normal font-bold hover:opacity-60 w-full py-1  ${
                            tabs === 0 ? "text-main" : "text-gray-400"
                          }`}
                          onClick={() =>
                            setTabs(() => {
                              return 0;
                            })
                          }
                        >
                          작가목록
                        </button>
                      </div>
                      <div
                        className={`inline-block  w-44 ${
                          tabs === 1 && "border-main border-b-2"
                        }`}
                      >
                        <button
                          className={`text-base leading-normal font-bold hover:opacity-60 w-full py-1  ${
                            tabs === 1 ? "text-main" : "text-gray-400"
                          }`}
                          onClick={() =>
                            setTabs(() => {
                              return 1;
                            })
                          }
                        >
                          승인되지 않은 작가
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className=" bg-gray-100 h-fix-content relative grid grid-rows1">
                  {tabs === 0 && <SubAdminListVerifiedInst />}
                  {tabs === 1 && <SubAdminListUnverifiedInst />}
                  <Footer></Footer>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
