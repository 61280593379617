import React from "react";
import gql from "graphql-tag";
import { ApolloError, useMutation } from "@apollo/client";
import {
  approveInst,
  approveInstVariables,
} from "../__generated__/approveInst";

const APPROVE_INST_MUTATION = gql`
  mutation approveInst($input: ApproveInstInput!) {
    approveInst(input: $input) {
      ok
      err
    }
  }
`;

export const useApproveInst = (
  onCompleted?: (data: approveInst) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<approveInst, approveInstVariables>(APPROVE_INST_MUTATION, {
    onCompleted,
    onError,
  });
};
