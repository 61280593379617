import logo from "../../assets/icon/localpick_logo.svg";
import { Link } from "react-router-dom";
import { Slide } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useForm } from "react-hook-form";
import { GetUserEmailInput } from "../../__generated__/globalTypes";
import { getUserEmail } from "../../__generated__/getUserEmail";
import { useGetUserEmail } from "../../hooks/useGetUserEmail";
import { Button } from "../../components/button";
export interface IFindEmailForm extends GetUserEmailInput {}

export const Find_id = () => {
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, isValid },
  } = useForm<IFindEmailForm>({ mode: "onChange" });
  const onCompleted = (data: getUserEmail) => {
    const {
      getUserEmail: { ok, user, err },
    } = data;
    if (ok) {
      alert(`회원님의 이메일은 ${user?.email} 입니다.`);
    } else {
      alert(err);
    }
  };
  const [getUserEmail, { data: getUserEmailData, loading }] =
    useGetUserEmail(onCompleted);
  const onSubmit = () => {
    if (!loading) {
      const { name, phone } = getValues();
      const getUserEmailInput: GetUserEmailInput = { name, phone };
      getUserEmail({
        variables: {
          input: getUserEmailInput,
        },
      });
    }
  };
  const onInvalid = () => {};

  return (
    <div className="bg-main bg-opacity-50 w-full h-full min-h-screen">
      <Helmet title="LoLo - 아이디찾기" />
      <div className="max-w-screen-yl mx-auto w-full h-full min-h-screen bg-white flex flex-wrap justify-center content-center overflow-hidden">
        <Slide direction="left" in={true}>
          <div className="logBox w-full text-center">
            <Link to="/" className="inline-block w-[200px] mx-auto">
              <img src={logo} alt="로로 로고" className="w-full" />
            </Link>
            <p className="text-center pt-2">
              회원정보에 등록한 이름과 번호를 입력해주세요
            </p>
            <form
              onSubmit={handleSubmit(onSubmit, onInvalid)}
              className="p-4 mx-auto w-full max-w-md"
            >
              <label className="py-10 flex">
                <span className="inline-block w-full max-w-[90px] text-main uppercase font-bold">
                  이름
                </span>
                <input
                  {...register("name")}
                  type="text"
                  className="inline-block border-b rounded-none border-main
                   focus:outline-none pl-1 leading-normal flex-1"
                />
              </label>
              <label className="flex pt-10 pb-12">
                <span className="inline-block w-full max-w-[90px] text-main uppercase font-bold">
                  휴대전화
                </span>
                <input
                  {...register("phone")}
                  type="number"
                  placeholder="-을 뺀 11자리로 입력해주세요"
                  className="inline-block border-b rounded-none border-main
                   focus:outline-none pl-1 leading-normal flex-1"
                />
              </label>
              <div className="pt-10 text-center">
                <Link
                  to="/signin"
                  className="inline-block w-32 py-1 px-2 border-grays border rounded mr-3"
                >
                  취소
                </Link>
                <Button
                  className={`inline-block w-32 py-1 px-2 text-bold bg-main border border-main
                   rounded text-white text-base`}
                  canClick={isValid}
                  actionText={"확인"}
                  loading={loading}
                  color={`text-white`}
                ></Button>
              </div>
            </form>
          </div>
        </Slide>
      </div>
    </div>
  );
};
