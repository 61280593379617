import React, { useEffect, useState } from "react";
import {
  CrConsultReplyInput,
  DelConsultReplyInput,
  UpConsultReplyInput,
} from "../__generated__/globalTypes";
import { useForm } from "react-hook-form";
import { TextareaAutosize } from "@mui/material";
import { ReplyButton } from "./button";
import {
  IUpConsultReplyFormProps,
  useUpConsultReply,
} from "../hooks/useUpConsultReply";
import { upConsultReply } from "../__generated__/upConsultReply";
import moment from "moment";
import { useMe } from "../hooks/useMe";
import { listConsultReply_listConsultReply_consultReplies } from "../__generated__/listConsultReply";
import { useDelConsultReply } from "../hooks/useDelConsultReply";
import { delConsultReply } from "../__generated__/delConsultReply";

interface IComponentUpConsultReplyForm {
  consultReply: listConsultReply_listConsultReply_consultReplies;
  listRefetch: () => void;
}

export const ComponentUpConsultReply: React.FC<
  IComponentUpConsultReplyForm
> = ({ consultReply, listRefetch }) => {
  const [edit, setEdit] = useState<boolean>(false);
  const editHandler = () => {
    setEdit(!edit);
    if (edit) {
      setFocus("note");
    }
  };

  const { data: me } = useMe();

  const {
    register,
    getValues,
    setValue,
    handleSubmit,
    setFocus,
    formState: { errors, isValid },
  } = useForm<IUpConsultReplyFormProps>({ mode: "all" });

  const onUpComplete = async (data: upConsultReply) => {
    const {
      upConsultReply: { ok, err },
    } = data;
    if (ok) {
      alert("수정하였습니다");
      editHandler();
      listRefetch();
    }
    if (!ok) {
      alert(err);
    }
  };
  const [upConsultReply, { loading: upConsultReplyLoading }] =
    useUpConsultReply(onUpComplete);
  const onSubmit = () => {
    if (!upConsultReplyLoading) {
      const { note } = getValues();
      const input: UpConsultReplyInput = {
        consultReplyIdToUp: consultReply.id,
        note,
      };
      upConsultReply({ variables: { input } });
    }
  };

  const onDelete = async (data: delConsultReply) => {
    const {
      delConsultReply: { ok, err },
    } = data;
    if (ok) {
      alert("정보를 삭제하였습니다");
      listRefetch();
    }
    if (!ok) {
      alert(err);
    }
  };
  const [delConsultReply, { loading: delConsultReplyLoading }] =
    useDelConsultReply(onDelete);
  const delConsultReplyHandler = (consultReplyIdToDel: number) => {
    if (!delConsultReplyLoading && window.confirm("정말로 삭제하시겠습니까?")) {
      const input: DelConsultReplyInput = {
        consultReplyIdToDel,
      };
      delConsultReply({ variables: { input } });
    }
  };

  useEffect(() => {
    setValue("note", consultReply.note);
  }, []);

  return (
    <div className="w-full block py-4 mt-1 border-b border-[#e2e2e2]">
      <form onSubmit={handleSubmit(onSubmit)}>
        <p>
          {`${consultReply.author.name}님, ${moment(
            consultReply.createdAt
          ).format("YYYY-M-D, HH:mm")}`}
          {consultReply.author.id === me?.me.id && !edit && (
            <span className="float-right">
              <span
                className="cursor-pointer"
                onClick={() => delConsultReplyHandler(consultReply.id)}
              >
                삭제
              </span>
              <span>{` / `}</span>
              <span className="cursor-pointer" onClick={editHandler}>
                수정
              </span>
            </span>
          )}
          {consultReply.author.id === me?.me.id && edit && (
            <span className="float-right cursor-pointer" onClick={editHandler}>
              취소
            </span>
          )}
        </p>
        <div className="grid grid-cols-7">
          <TextareaAutosize
            {...register(`note`, {
              required: "필수 정보입니다",
            })}
            minRows="2"
            disabled={!edit}
            placeholder="답변을 입력해주세요"
            className={`w-full py-1 px-4 bg-white resize-none rounded-sm border-gray-300 focus:border-gray-500 outline-none ${
              edit ? `col-span-6 border-2` : `col-span-7 border-none`
            } h-auto min-h-fit`}
          />
          {edit && (
            <ReplyButton
              canClick={isValid}
              color={`bg-main text-white`}
              loading={upConsultReplyLoading}
              actionText={`저장`}
            />
          )}
        </div>
      </form>
    </div>
  );
};
