import moment from "moment";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { Button } from "../components/button";
import { ComponentCrDoorReply } from "../components/component-cr-door-reply";
import { ComponentUpDoorReply } from "../components/component-up-door-reply";
import { TableError } from "../components/table-error";
import { useDelDoor } from "../hooks/useDelDoor";
import { useGetDoor } from "../hooks/useGetDoor";
import { useListDoorReply } from "../hooks/useListDoorReply";
import { useMe } from "../hooks/useMe";
import { IUpDoorFormProps, useUpDoor } from "../hooks/useUpDoor";
import { delDoor } from "../__generated__/delDoor";
import { getDoor } from "../__generated__/getDoor";
import {
  DelDoorInput,
  GetDoorInput,
  ListDoorReplyInput,
  UpDoorInput,
} from "../__generated__/globalTypes";
import { upDoor } from "../__generated__/upDoor";

interface IGeneralDetailDoorProps {
  doorId: number;
  setTabHandler: (tab: number) => void;
}

export const GeneralDetailDoor: React.FC<IGeneralDetailDoorProps> = ({
  doorId,
  setTabHandler,
}) => {
  const [edit, setEdit] = useState<boolean>(false);
  const setEditHandler = () => {
    setTimeout(() => setEdit(!edit), 5);
  };

  const { data: me } = useMe();
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors, isValid },
  } = useForm<IUpDoorFormProps>({
    mode: "all",
  });

  const onGetComplete = (data: getDoor) => {
    const {
      getDoor: { ok, err },
    } = data;
    if (ok) {
      setValue("note", data.getDoor.door?.note);
      setValue("type", data.getDoor.door?.type);
    }
  };
  const [getDoor, { data, loading, refetch }] = useGetDoor(onGetComplete);
  const getDoorHandler = (doorId: number) => {
    const input: GetDoorInput = {
      doorId,
    };
    getDoor({ variables: { input } });
  };

  const [
    listDoorReply,
    {
      data: listDoorReplyData,
      loading: listDoorReplyLoading,
      refetch: listDoorReplyRefetch,
    },
  ] = useListDoorReply();
  const listDoorReplyHandler = (doorId: number) => {
    const input: ListDoorReplyInput = {
      doorId,
    };
    listDoorReply({ variables: { input } });
  };

  const onUpComplete = async (data: upDoor) => {
    const {
      upDoor: { ok, err },
    } = data;
    if (ok) {
      alert("수정하였습니다");

      refetch();
    }
    if (!ok) {
      alert(err);
    }
  };
  const [upDoor, { loading: upDoorLoading }] = useUpDoor(onUpComplete);
  const upDoorHandler = () => {
    if (!upDoorLoading) {
      const { note, title, type } = getValues();
      const input: UpDoorInput = {
        doorIdToUp: doorId,
        ...(note !== data?.getDoor.door?.note && { note }),
        type,
      };
      upDoor({ variables: { input } });
    }
  };

  const onDelete = async (data: delDoor) => {
    const {
      delDoor: { ok, err },
    } = data;
    if (ok) {
      alert("문의 정보를 삭제하였습니다");
      refetch();
    }
    if (!ok) {
      alert(err);
    }
  };
  const [delDoor, { loading: delDoorLoading }] = useDelDoor(onDelete);
  const delDoorHandler = (doorIdToDel: number) => {
    if (!delDoorLoading) {
      const input: DelDoorInput = {
        doorIdToDel,
      };
      delDoor({ variables: { input } });
    }
  };
  //  up door
  useEffect(() => {
    getDoorHandler(doorId);
    listDoorReplyHandler(doorId);
  }, []);

  return (
    <div className=" bg-gray-100 h-fix-content relative box-border">
      {!loading && data?.getDoor.ok && (
        <div className="bg-white p-4 rounded-2xl shadow-lg relative">
          <div className="border-b ">
            <form onSubmit={handleSubmit(upDoorHandler)}>
              <div className="qinfo">
                <p className="p-1 text-left text-text border-b border-[#e2e2e2 text-sm">
                  <span>작성일:</span>{" "}
                  {moment(data?.getDoor.door?.createdAt).format(
                    "yyyy년 M월 D일 HH:mm"
                  )}
                </p>
                <p className="bg-[#e2e2e2] py-1 pl-3 border-b border-[#e2e2e2 font-bold">
                  {data?.getDoor.door?.title}
                </p>
                <p className="py-1 pl-3 border-b border-[#e2e2e2]">
                  <span className="mr-3">상태:</span>{" "}
                  <span className="font-bold">
                    {data?.getDoor.door?.status}
                  </span>
                </p>
                <p className="py-1 pl-3 border-b border-[#e2e2e2]">
                  <span className="mr-3">분류:</span>{" "}
                  <span className="font-bold">{data?.getDoor.door?.type}</span>
                </p>
                <p className="py-1 pl-3 border-b border-[#e2e2e2]">
                  <span className="mr-3">글쓴이:</span>
                  <span className="font-bold">
                    {data?.getDoor.door?.author?.name}
                  </span>
                </p>
              </div>
              <div className="p-4">
                <textarea
                  {...register("note", { required: true })}
                  disabled={!edit}
                  className="text-sm w-full resize-none h-48 whitespace-pre-wrap"
                ></textarea>
              </div>
              <div className="btnBox pt-4 text-center space-x-4 py-5">
                {!edit ? (
                  <>
                    <Button
                      type="button"
                      canClick={true}
                      actionText="뒤로가기"
                      loading={false}
                      onClick={() => setTabHandler(0)}
                    />
                    {data?.getDoor.door?.author?.id === me?.me.id && (
                      <Button
                        type="button"
                        className="bg-main text-white"
                        canClick={true}
                        actionText="수정"
                        loading={false}
                        onClick={() => setEditHandler()}
                      />
                    )}
                  </>
                ) : (
                  <>
                    <Button
                      type="button"
                      canClick={true}
                      actionText="취소"
                      loading={false}
                      onClick={() => setEditHandler()}
                    />
                    {data?.getDoor.door?.author?.id === me?.me.id && (
                      <Button
                        className="bg-main text-white"
                        canClick={isValid}
                        actionText="수정완료"
                        onClick={() => setEditHandler()}
                        loading={upDoorLoading}
                      />
                    )}
                  </>
                )}
              </div>
            </form>

            {doorId && (
              <ComponentCrDoorReply
                doorId={doorId}
                listRefetch={listDoorReplyRefetch}
                getRefetch={refetch}
              />
            )}
            {!listDoorReplyLoading &&
              listDoorReplyData?.listDoorReply.doorReplies?.map((doorReply) => (
                <ComponentUpDoorReply
                  key={`door-reply-${doorReply.id}`}
                  doorReply={doorReply}
                  listRefetch={listDoorReplyRefetch}
                />
              ))}
          </div>
        </div>
      )}
      {loading && (
        <div className="w-full p-5 text-center box-border rounded">
          <div className="bg-white w-full">
            <TableError errorMessage={`정보를 불러오는 중입니다`} />
          </div>
        </div>
      )}
    </div>
  );
};
