import { useEffect } from "react";
import { useParams } from "react-router";
import { upload } from "../../../../../functions/uploads";
import { useCrProductView } from "../../../../../hooks/useCrProductView";
import { useListProductView } from "../../../../../hooks/useListProductView";
import { useUpProductView } from "../../../../../hooks/useUpProductView.tsx";
import { crProductView } from "../../../../../__generated__/crProductView";
import {
  CrProductViewInput,
  DelProductViewInput,
  ListProductViewInput,
  UpProductViewInput,
} from "../../../../../__generated__/globalTypes";
import {
  listProductView,
  listProductView_listProductView_productViews,
} from "../../../../../__generated__/listProductView";
import { upProductView } from "../../../../../__generated__/upProductView";
import { faPlus, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import photo from "../../../../../assets/icon/photo.svg";
import { useDelProductView } from "../../../../../hooks/useDelProductView";
import { delProductView } from "../../../../../__generated__/delProductView";
export const Thumb = () => {
  const params: any = useParams();

  // get list views, set attribute,

  const onListCompleted = (data: listProductView) => {};

  const [listProductView, { data: listProductViewData, loading, refetch }] =
    useListProductView(onListCompleted);

  const onUpCompleted = async (data: upProductView) => {
    const {
      upProductView: { ok },
    } = data;
    if (ok) {
      await refetch();
    }
  };

  const onCrCompleted = async (data: crProductView) => {
    const {
      crProductView: { ok, err },
    } = data;
    if (ok) {
      await refetch();
    } else {
      alert(err);
    }
  };
  const onDelCompleted = async (data: delProductView) => {
    const {
      delProductView: { ok, err },
    } = data;
    if (ok) {
      await refetch();
    } else {
      alert(err);
    }
  };
  const [upProductView, { loading: upProductViewLoading }] =
    useUpProductView(onUpCompleted);

  const [crProductView, { loading: crProductViewLoading }] =
    useCrProductView(onCrCompleted);

  const [delProductView, { loading: delProductViewLoading }] =
    useDelProductView(onDelCompleted);

  const uploadProductViewHandler = async (
    event: any,
    productViewIdToUp: number
  ) => {
    if (!upProductViewLoading) {
      const result = await upload(event);
      if (result.data.ok) {
        const upProductViewInput: UpProductViewInput = {
          productViewIdToUp,
          viewUuid: result.data.file.uuid,
        };
        upProductView({ variables: { input: upProductViewInput } });
        alert("저장되었습니다.");
      } else {
        alert(
          `잘못된 파일이거나 사용할 수 없는 파일입니다. 문제가 반복해서 발생할 시 업체에 문의해주세요`
        );
      }
    }
  };

  const crProductViewHandler = () => {
    if (!crProductViewLoading) {
      const crProductViewInput: CrProductViewInput = {
        productId: params.key ? +params.key : 0,
      };
      crProductView({ variables: { input: crProductViewInput } });
    }
  };
  const delProductViewHandler = (viewId: any) => {
    if (!delProductViewLoading) {
      const delProductViewInput: DelProductViewInput = {
        productViewIdToDel: viewId ? viewId : 0,
      };
      delProductView({ variables: { input: delProductViewInput } });
    }
  };
  useEffect(() => {
    if (params.key) {
      const listProductViewInput: ListProductViewInput = {
        productId: +params.key,
      };
      listProductView({ variables: { input: listProductViewInput } });
    }
  }, []);
  return (
    <div className="bg-white rounded-md">
      <div className="p-4 lg:p-10 space-y-10">
        <div className="space-y-3">
          <p className="font-bold text-main">
            썸네일 이미지
            {/* <button className="ml-3" onClick={crProductViewHandler}>
              <FontAwesomeIcon icon={faPlus} />
            </button> */}
            <span className="ml-5 text-xs text-black">(860x584)</span>
          </p>
          <div className="w-full grid grid-cols-3 gap-3">
            <div
              onClick={crProductViewHandler}
              className="cursor-pointer flex flex-col flex-wrap content-center justify-center w-full aspect-[100/78] relative border border-line rounded-md"
            >
              <img
                src={photo}
                alt="사진 추가"
                className="w-1/4 max-w-[100px] mx-auto"
              />
              <p className="text-center w-full text-sm sm:text-base pt-1 sm:pt-2">
                {listProductViewData?.listProductView.productViews?.length}/3
              </p>
            </div>
            {listProductViewData?.listProductView.productViews &&
              listProductViewData.listProductView.productViews.map(
                (
                  productView: listProductView_listProductView_productViews,
                  index
                ) => (
                  <div
                    key={`thumbnail-${productView.id}`}
                    className="w-full pt-[78%] relative "
                  >
                    <button
                      onClick={() => delProductViewHandler(productView.id)}
                      className="flex flex-wrap content-center justify-center w-4 h-4 sm:w-8 sm:h-8 absolute -right-1 -top-1 sm:-right-2 sm:-top-2 bg-line text-white rounded-full z-10"
                    >
                      <FontAwesomeIcon
                        icon={faXmark}
                        className="text-sm sm:text-xl"
                      />
                    </button>
                    <label
                      htmlFor={`thumb${index + 1}`}
                      className="flex flex-wrap content-center justify-center hover:opacity-60 absolute left-0 top-0 w-full h-full cursor-pointer bg-gray-300 border border-grays rounded-md"
                    >
                      <i className="fas fa-plus text-3xl sm:text-5xl text-white"></i>
                      <img
                        src={productView.view?.url}
                        alt={`썸네일${productView.id}`}
                        className={`absolute left-0 rounded-md top-0 w-full h-full thumb1 ${
                          !Boolean(productView.view) ? "hidden" : ""
                        } `}
                      />
                    </label>
                    <input
                      type="file"
                      className="hidden"
                      accept="image/png, image/jpeg, image/jpg"
                      id={`thumb${index + 1}`}
                      onChange={(event: any) =>
                        uploadProductViewHandler(event, productView.id)
                      }
                    />
                  </div>
                )
              )}
            {/* <div className="lg:grid lg:grid-rows-[1.2fr_2fr] lg:gap-4 space-y-2 lg:space-y-0">
              {listProductViewData?.listProductView.productViews &&
                listProductViewData.listProductView.productViews.map(
                  (
                    productView: listProductView_listProductView_productViews
                  ) => (
                    <>
                      {productView.index !== 1 && (
                        <div
                          key={`thumbnail-${productView.id}`}
                          className="relative pt-[78%] lg:pt-0"
                        >
                          <label
                            htmlFor={`thumb${productView.index}`}
                            className="flex flex-wrap content-center justify-center hover:opacity-60 absolute left-0 top-0 w-full h-full cursor-pointer bg-gray-300 border border-grays"
                          >
                            <i className="fas fa-plus text-5xl text-white"></i>
                            <img
                              src={productView.view?.url}
                              alt={`thumb${productView.index}`}
                              className={`absolute left-0 top-0 w-full h-full thumb1 ${
                                !Boolean(productView.view) ? "hidden" : ""
                              } `}
                            />
                          </label>
                          <input
                            type="file"
                            className="hidden"
                            accept="image/png, image/jpeg, image/jpg"
                            id={`thumb${productView.index}`}
                            onChange={(event: any) =>
                              uploadProductViewHandler(event, productView.id)
                            }
                          />
                        </div>
                      )}
                    </>
                  )
                )}
            </div> */}
          </div>
        </div>
        {/* <div className="text-center py-10 space-x-5">
          <button
            // onClick={}
            className="rounded-md border border-grays w-2/5 sm:w-[150px] py-1 hover:opacity-60 text-sm lg:text-base"
          >
            모두 제거
          </button>
          <button className="rounded-md text-white w-2/5 sm:w-[150px] py-1 hover:opacity-60 text-sm lg:text-base bg-main border border-main">
            저장
          </button>
        </div> */}
      </div>
    </div>
  );
};
