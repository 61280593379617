import { useEffect, useState } from "react";
import { pageIndicator } from "../apollo";
import searchImg from "../assets/icon/search.png";
import { AsideAdminComponent } from "../layout/aside-admin";
import { Footer } from "../layout/footer";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router";
import { TableError } from "../components/table-error";
import { TextButton } from "../components/button";
import { useForm } from "react-hook-form";
import { AdminTop } from "../layout/adminTop";
import { ISearchFormProps } from "./admin-list-magazine";
import { PaginationComponent } from "../components/pagination";
import { ComponentTabLink, ITabLink } from "../components/component-tab";
import { useListBill } from "../hooks/useListBill";
import { GetBillURLInput, ListBillInput } from "../__generated__/globalTypes";
import { useGetBillURL } from "../hooks/useGetBillURL";
import { getBillURL } from "../__generated__/getBillURL";

export const AdminListBill = () => {
  const history = useNavigate();
  const location = useLocation();
  const [page, setPage] = useState<number>(1);
  const [search, setSearch] = useState<string>("");

  const tabList: ITabLink[] = [
    { text: "목록", to: "/admin/bill", selected: true },
  ];

  const word = "계산서";

  const pageHandler = (page: number) => {
    setPage(page);
    history({
      pathname: "/admin/bill",
      search: `?page=${page}${search ? `&search=${search}` : ""}`,
    });
    listBillHandler(page, search);
  };

  const { register, getValues, handleSubmit } = useForm<ISearchFormProps>({
    mode: "all",
  });

  const onSearch = async () => {
    const { search } = getValues();
    setSearch(search);
    history({
      pathname: "/admin/bill",
      search: `${search ? `?search=${search}` : ""}`,
    });
    listBillHandler(1, search);
  };

  const [listBill, { data, loading, refetch }] = useListBill();
  const listBillHandler = (page: number | null, search: string | null) => {
    const input: ListBillInput = {
      ...(page && { page: +page }),
      ...(search && { search: decodeURI(search) }),
    };
    listBill({ variables: { input } });
  };

  const onGetBillURL = (data: getBillURL) => {
    const {
      getBillURL: { ok, err, url },
    } = data;
    if (!ok) {
      console.log(err);
    }
    if (url) {
      window.open(url);
    }
  };
  const [getBillURL, { loading: getBillURLLoading }] =
    useGetBillURL(onGetBillURL);
  const getBillURLHandler = (billIdToGetURL: number) => {
    const input: GetBillURLInput = {
      billIdToGetURL,
    };
    getBillURL({ variables: { input } });
  };

  useEffect(() => {
    pageIndicator("bill");

    setPage(
      location.search.split("page=").length > 1
        ? +location.search.split("page=")[1].split("&")[0]
        : 1
    );
    setSearch(
      location.search.split("search=").length > 1
        ? location.search.split("search=")[1].split("&")[0]
        : ""
    );
    listBillHandler(page, search);
  }, []);

  return (
    <div className="bg-main bg-opacity-50 w-full h-full min-h-screen">
      <div className="w-[1440px] mx-auto h-full min-h-screen  bg-white">
        <AdminTop title={"top"} children={"관리자모드"} />
        <main id="main">
          <div className="grid grid-cols-[300px_1fr]">
            <AsideAdminComponent />
            <div className="mypages ">
              <div className="lectureWrap">
                <ComponentTabLink tabs={tabList} />
                <div className=" bg-gray-100 h-fix-content relative">
                  <div className={`w-full h-full px-10 py-12`}>
                    <div className="text-right pb-8">
                      <form onSubmit={handleSubmit(onSearch)}>
                        <p className="inline-block max-w-md w-full text-right relative">
                          <input
                            {...register(`search`)}
                            type="text"
                            className="w-full py-1 px-4 bg-white rounded-full"
                            placeholder="플레이스 이름으로 검색"
                          />
                          <button className="absolute right-3 top-1">
                            <img src={searchImg} alt="찾기" className="w-5" />
                          </button>
                        </p>
                      </form>
                    </div>
                    {/* 여기 */}
                    <div className="bg-white p-5 rounded-2xl shadow-lg">
                      <p className="text-main font-bold pb-3">플레이스 목록</p>
                      <table className="w-full">
                        <colgroup>
                          <col className="w-1/12" />
                          <col className="w-2/12" />
                          <col className="w-2/12" />
                          <col className="w-1/12" />
                          <col className="w-2/12" />
                          <col className="w-1/12" />
                          <col className="w-2/12" />
                          <col className="w-1/12" />
                        </colgroup>
                        <thead>
                          <tr className="bg-main  border-gray-300">
                            <th className="text-white ">번호</th>
                            <th className="text-white ">업체명</th>
                            <th className="text-white ">비고</th>
                            <th className="text-white ">상태</th>
                            <th className="text-white ">공급가액</th>
                            <th className="text-white ">세액</th>
                            <th className="text-white ">합계금액</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {!loading &&
                            data?.listBill.bills &&
                            data.listBill.bills.map((bill) => (
                              <tr
                                key={`bill-${bill?.id}`}
                                className="border-b-2 border-gray-300 h-4"
                              >
                                <td className="text-sm text-center py-1 px-1 h-full overflow-hidden">
                                  {bill?.id && bill.id}
                                </td>
                                <td className="text-sm text-center h-full relative">
                                  <Link
                                    to={`/admin/bill/update/${bill.id}`}
                                    className={`cursor-pointer hover:underline`}
                                  >
                                    {bill?.invoicerCorpName &&
                                      bill.invoicerCorpName}
                                  </Link>
                                </td>

                                <td className="text-sm text-center py-1 px-1 h-full overflow-hidden">
                                  {bill?.note && bill.note}
                                </td>

                                <td className="text-sm text-center py-1 px-1 h-full overflow-hidden">
                                  {bill?.status && bill.status}
                                </td>

                                <td className="text-sm text-center py-1 px-1 h-full overflow-hidden">
                                  {bill?.amountTotal && bill.amountTotal}
                                </td>

                                <td className="text-sm text-center py-1 px-1 h-full overflow-hidden">
                                  {bill?.taxTotal && bill.taxTotal}
                                </td>

                                <td className="text-sm text-center py-1 px-1 h-full overflow-hidden">
                                  {bill?.totalAmount && bill.totalAmount}
                                </td>
                                <td className="text-sm text-center py-1 px-1 h-full w-1/6 overflow-hidden">
                                  <TextButton
                                    canClick={!getBillURLLoading}
                                    actionText={`계산서`}
                                    onClick={() => getBillURLHandler(bill.id)}
                                  />
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                      {loading && (
                        <TableError errorMessage={`정보를 불러오는 중입니다`} />
                      )}
                      {!loading &&
                        data?.listBill.bills &&
                        data.listBill.bills.length < 1 && (
                          <TableError
                            errorMessage={`해당하는 ${word} 정보가 없습니다`}
                          />
                        )}
                      {!loading &&
                        data?.listBill.bills &&
                        data.listBill.bills.length > 0 && (
                          <PaginationComponent
                            page={page}
                            totalPages={Number(data.listBill.totalPages)}
                            totalResults={Number(data.listBill.totalResults)}
                            pageHandler={pageHandler}
                          />
                        )}
                    </div>
                  </div>
                  <Footer></Footer>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
