import React from "react";
import { gql, useLazyQuery } from "@apollo/client";
import {
  listConsultMine,
  listConsultMineVariables,
} from "../__generated__/listConsultMine";

const LIST_CONSULT_MINE_QUERY = gql`
  query listConsultMine($input: ListConsultMineInput!) {
    listConsultMine(input: $input) {
      ok
      err
      totalResults
      totalPages
      unverifiedConsult
      completedConsult
      consults {
        id
        title
        note
        status
        type
        createdAt
        updatedAt
        uuid
        curator {
          name
        }
        author {
          inst {
            atelier {
              name
            }
          }
        }
        product {
          productInfo {
            title
          }
        }
      }
    }
  }
`;

export const useListConsultMine = (
  onCompleted?: (data: listConsultMine) => void
) => {
  return useLazyQuery<listConsultMine, listConsultMineVariables>(
    LIST_CONSULT_MINE_QUERY,
    {
      onCompleted,
      fetchPolicy: "network-only",
    }
  );
};
