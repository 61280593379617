import { useEffect, useState } from "react";
import { Footer } from "../../../layout/footer";
import Modal from "@mui/material/Modal";
import { AsideAdminComponent } from "../../../layout/aside-admin";
import { SubAdminListUnverifiedCurator } from "../../../sub-routes/sub-admin-list-unverified-curator";
import { pageIndicator } from "../../../apollo";
import { SubAdminListVerifiedCurator } from "../../../sub-routes/sub-admin-list-verified-curator";
import { AdminTop } from "../../../layout/adminTop";
import { useSendSignupEmail } from "../../../hooks/useSendSignupEmail";
import { sendSignupEmail } from "../../../__generated__/sendSignupEmail";
import { SendSignupEmailInput } from "../../../__generated__/globalTypes";

export const Curator_ad = () => {
  const [tabs, setTabs] = useState(0);
  const [open, setOpen] = useState(false);
  const [mail, setMail] = useState("");

  const onComplete = (data: sendSignupEmail) => {
    const {
      sendSignupEmail: { ok, err },
    } = data;
    if (ok) {
      alert("메일을 발송했습니다");
      setOpen(false);
    } else {
      alert(err);
    }
  };
  const [sendSignupEmail, { loading }] = useSendSignupEmail(onComplete);
  const sendSignupEmailHandler = (email: string) => {
    if (!loading) {
      const input: SendSignupEmailInput = { email };
      sendSignupEmail({ variables: { input } });
    }
  };

  useEffect(() => {
    pageIndicator("curator");
  }, []);

  return (
    <div className="bg-main bg-opacity-50 w-full h-full min-h-screen">
      <div className="w-[1440px] mx-auto h-full min-h-screen  bg-white">
        <AdminTop title={"top"} children={"관리자모드"} />
        <main id="main">
          <div className="grid grid-cols-[300px_1fr]">
            <AsideAdminComponent />
            <div className="mypages ">
              <div className="lectureWrap">
                <div className="h-20">
                  <div className="h-full flex-wrap flex justify-between px-10 content-center">
                    <div className="subtitle h-full flex flex-wrap content-end  gap-x-3">
                      <div
                        className={`inline-block  w-44 ${
                          tabs === 0 && "border-main border-b-2"
                        }`}
                      >
                        <button
                          className={`leading-normal font-bold hover:opacity-60 w-full py-1  ${
                            tabs === 0 ? "text-main" : "text-gray-400"
                          }`}
                          onClick={() =>
                            setTabs(() => {
                              return 0;
                            })
                          }
                        >
                          큐레이터 목록
                        </button>
                      </div>
                      <div
                        className={`inline-block  min-w-44 px-5 ${
                          tabs === 1 && "border-main border-b-2"
                        }`}
                      >
                        <button
                          className={`leading-normal font-bold hover:opacity-60 w-full py-1  ${
                            tabs === 1 ? "text-main" : "text-gray-400"
                          }`}
                          onClick={() =>
                            setTabs(() => {
                              return 1;
                            })
                          }
                        >
                          승인대기중인 큐레이터
                        </button>
                      </div>
                    </div>
                    <div className="flex flex-wrap content-end pb-3">
                      <button
                        onClick={() => setOpen(true)}
                        className="text-white bg-main rounded-lg hover:opacity-60  px-10 py-1"
                      >
                        가입링크 전송
                      </button>
                    </div>
                  </div>
                </div>
                <div className=" bg-gray-100 h-fix-content relative">
                  {tabs === 0 && <SubAdminListVerifiedCurator />}
                  {tabs === 1 && <SubAdminListUnverifiedCurator />}
                  <Footer></Footer>
                  <Modal
                    open={open}
                    onClose={() => {
                      setOpen(false);
                    }}
                  >
                    <div className="max-w-lg w-full absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 p-1 bg-gray-200 rounded-lg">
                      <div className="bg-white p-1 w-full">
                        <div className="bg-gray-200 flex flex-wrap content-center justify-center">
                          <div className="w-4/5 py-10">
                            <p className="text-center py-5">
                              가입링크 받을 이메일을 입력해주세요
                            </p>
                            <input
                              type="text"
                              className="bg-white rounded-full py-1 px-3 w-full"
                              onChange={(e) => setMail(e.target.value)}
                            />

                            <div className="pt-10 space-x-3 text-center">
                              <button
                                onClick={() => setOpen(false)}
                                className="rounded-md w-36 py-1 border border-grays bg-white"
                              >
                                취소
                              </button>
                              <button
                                onClick={() => {
                                  if (
                                    window.confirm(
                                      mail + " 로 전송하시겠습니까?"
                                    )
                                  ) {
                                    sendSignupEmailHandler(mail);
                                  }
                                }}
                                className="rounded-md w-36 py-1 border border-main bg-main text-white"
                              >
                                보내기
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
