import delivery from "../../../../../assets/images/delivery.png";
import online from "../../../../../assets/icon/on.svg";
import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useListReceiptMineOnline } from "../../../../../hooks/useListReceiptMineOnline";
import moment from "moment";
import { SubUserModalRequestRefund } from "../../../../../sub-routes/sub-user-modal-request-refund";
import { OrderStatus } from "../../../../../__generated__/globalTypes";
import { SubUserModalVideo } from "../../../../../sub-routes/sub-user-modal-video";
export const OnLec = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [kitopen, setKitOpen] = useState(false);
  const [deliopen, setDeliOpen] = useState(false);
  const [videoOpen, setVideoOpen] = useState(false);
  const [cancelopen, setCancelOpen] = useState(false);
  const [orderStatus, setOrderStatus] = useState<OrderStatus>();
  const [kitUrl, setKitUrl] = useState<string | null | undefined>(``);
  const [kitDesc, setKitDesc] = useState<string | null | undefined>(``);
  const [receiptId, setReceiptId] = useState<number>(0);
  const [productId, setProductId] = useState<number>(0);
  const [videoTitle, setVideoTitle] = useState<string>("");
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [listReceiptMineOnline, { data, loading }] = useListReceiptMineOnline();

  useEffect(() => {
    listReceiptMineOnline({ variables: { input: { page: 1 } } });
  }, []);

  const MenuBody = {
    boxShadow: "0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)",
  };
  const MenuStyle = {
    fontSize: "0.875rem",
  };
  const MenuStyle2 = {
    fontSize: "0.875rem",
    color: "red",
  };

  const closeHandler = () => {
    setCancelOpen(false);
  };

  const closeVideoHandler = () => {
    setVideoOpen(false);
    setProductId(0);
  };

  return (
    <div className="online bg-white p-5 lg:p-10 rounded-lg">
      <div>
        <p className="pl-2 text-xl text-main leading-normal pb-3 ">
          <span className="align-middle font-bold">신청 온라인 강의</span>
          <img
            src={online}
            alt="온라인"
            className="hidden sm:inline-block w-10 ml-3"
          />
        </p>
        {!loading &&
          data?.listReceiptMineOnline.receipts?.length !== undefined &&
          data.listReceiptMineOnline.receipts.length > 0 && (
            <div className="flex flex-wrap gap-5">
              {data?.listReceiptMineOnline.receipts &&
                data.listReceiptMineOnline.receipts.map((receipt) => (
                  <div
                    key={`online-class-${receipt.id}`}
                    className="cards w-full min-w-[200px] max-w-[300px]  lg:w-[300px] lg:max-w-none p-4 inline-block bg-gray-200 rounded-md"
                  >
                    <div className="MypageRatio relative">
                      {receipt.product?.productViews
                        ?.filter((productView) => productView.is_rep)
                        .map((productView) => (
                          <img
                            key={productView.id}
                            src={
                              productView.view?.url ? productView.view.url : `#`
                            }
                            alt={receipt.pgOption?.name + "의 썸네일 그림"}
                            className="absolute left-0 top-0 w-full h-full"
                          />
                        ))}
                      <div
                        aria-controls={`Menu${receipt.id}`}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        onClick={handleClick}
                        className="inline-flex flex-wrap content-center justify-center absolute right-0 top-0 w-8 h-8 rounded-full bg-opacity-30 cursor-pointer hover:bg-opacity-60"
                      >
                        <i className="fas fa-ellipsis-v text-xl"></i>
                      </div>
                      <Menu
                        id={`Menu${receipt.id}`}
                        anchorEl={anchorEl}
                        open={
                          anchorEl?.getAttribute("aria-controls") ===
                          `Menu${receipt.id}`
                        }
                        onClose={handleClose}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                        style={MenuBody}
                      >
                        <MenuItem
                          onClick={() => {
                            handleClose();
                            setVideoOpen(true);
                            setProductId(
                              receipt.product?.id ? receipt.product.id : 0
                              // receipt.product?.id ? receipt.product.id : 0
                            );
                            setVideoTitle(
                              receipt.pgOption?.name
                                ? receipt.pgOption.name
                                : ""
                            );
                          }}
                          style={MenuStyle}
                        >
                          강의 시청하기
                        </MenuItem>
                        {receipt.product?.productKit?.desc && (
                          <MenuItem
                            onClick={() => {
                              handleClose();
                              setKitOpen(true);
                              setKitUrl(
                                receipt.product?.productKit?.view?.url
                                  ? receipt.product?.productKit.view.url
                                  : ``
                              );
                              setKitDesc(receipt.product?.productKit?.desc);
                            }}
                            style={MenuStyle}
                          >
                            키트정보
                          </MenuItem>
                        )}

                        <MenuItem
                          onClick={() => {
                            handleClose();
                            setDeliOpen(true);
                            setOrderStatus(receipt.orderStatus);
                          }}
                          style={MenuStyle}
                        >
                          배송정보
                        </MenuItem>
                        {receipt.createdAt &&
                          moment().diff(moment(receipt.createdAt)) > 7 && (
                            <MenuItem
                              onClick={() => {
                                handleClose();
                                if (receipt.pgOption?.pay_method === `vbank`) {
                                  alert(
                                    "가상계좌로 결제한 주문은 환불이 불가합니다."
                                  );
                                } else {
                                  setCancelOpen(true);
                                  setReceiptId(receipt.id);
                                }
                              }}
                              style={MenuStyle2}
                            >
                              강의취소하기
                            </MenuItem>
                          )}
                      </Menu>
                    </div>
                    <p className="font-bold text-xs flex flex-wrap justify-between pt-2">
                      {receipt.product?.inst.atelier?.name && (
                        <span>{receipt.product.inst.atelier?.name}</span>
                      )}
                    </p>
                    <p className="py-2 text-sm leading-tight">
                      {receipt.pgOption?.name}
                    </p>
                  </div>
                ))}
              <Modal
                open={deliopen}
                onClose={() => {
                  setDeliOpen(false);
                }}
              >
                <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 p-10 bg-white">
                  <div className="w-[200px]">
                    <img
                      src={delivery}
                      alt="배송그림"
                      className="w-[150px] mx-auto"
                    />
                    <p className="text-base text-center pb-2">
                      고객님의 소중한 DIY Kit는
                    </p>
                    <p className="font-bold text-2xl text-center py-1 w-full border border-[#79b9c5] rounded-md">
                      {orderStatus === OrderStatus.Pending && `배송 전`}
                      {orderStatus === OrderStatus.HandOut && `배송 중`}
                      {orderStatus === OrderStatus.Delivered && `배송 완료`}
                    </p>
                    <p className="text-base text-center py-2">상태입니다.</p>
                  </div>
                </div>
              </Modal>
              <Modal
                open={kitopen}
                onClose={() => {
                  setKitOpen(false);
                }}
              >
                <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 p-10 bg-white max-w-[500px]">
                  {kitUrl && (
                    <img
                      src={kitUrl}
                      alt="키트그림"
                      className="w-full aspect-square object-cover "
                    />
                  )}
                  <p className={`py-4 font-bold `}>DIY Kit</p>
                  <p className={`whitespace-pre-wrap`}>{kitDesc}</p>
                </div>
              </Modal>
              <Modal
                open={cancelopen}
                onClose={() => {
                  setCancelOpen(false);
                }}
              >
                <div>
                  <SubUserModalRequestRefund
                    receiptId={receiptId}
                    closeHandler={closeHandler}
                  />
                </div>
              </Modal>
              {/* <Modal open={videoOpen} onClose={closeVideoHandler}> */}
              <Modal open={videoOpen}>
                <div>
                  <SubUserModalVideo
                    title={videoTitle}
                    productId={productId}
                    closeHandler={closeVideoHandler}
                  />
                </div>
              </Modal>
            </div>
          )}
        {!loading &&
          data?.listReceiptMineOnline.receipts?.length !== undefined &&
          data.listReceiptMineOnline.receipts.length === 0 && (
            <div className={`text-center`}>신청한 강의가 없습니다</div>
          )}
        {loading && (
          <div className={`text-center`}>강의를 불러오는 중입니다</div>
        )}
      </div>
    </div>
  );
};
