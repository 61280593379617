import { useState } from "react";
import { Link } from "react-router-dom";
import { isLoggedInVar } from "../apollo";
import close from "../assets/icon/close.svg";
import mypage from "../assets/icon/mypage.svg";
import { signOut } from "../constants/token";
import { useMe } from "../hooks/useMe";
import { UserRole } from "../__generated__/globalTypes";
export const MobileMenu = (props: any) => {
  const { data } = useMe();
  return (
    <div className="w-full">
      <div className="px-4 top h-16 md:h-24 rounded-tr-[24px] flex flex-wrap content-center border-b border-black">
        <button
          onClick={props.handler}
          className="flex flex-wrap content-center"
        >
          <img src={close} alt="메뉴닫기 버튼" className="p-2" />
        </button>
      </div>
      <div className="accountCtl h-10 border-b border-black px-4">
        {isLoggedInVar() ? (
          <>
            {data?.me.role === UserRole.Instructor ? (
              <>
                <Link
                  to={`/instPage/`}
                  className="inline-block h-full w-1/2 text-sm font-bold border-r border-black"
                >
                  <button className="h-full text-sm font-bold">
                    <img
                      src={mypage}
                      alt="마이페이지 아이콘"
                      className="inline-block mr-2"
                    />
                    <span className="text-sm font-bold">마이페이지</span>
                  </button>
                </Link>
                <a
                  className="inline-block h-full w-1/2 text-sm font-bold"
                  onClick={signOut}
                  href="/"
                >
                  <button className="h-full w-full text-sm font-bold">
                    로그아웃
                  </button>
                </a>
              </>
            ) : (
              <>
                <Link
                  to={`/mypage/`}
                  className="inline-block h-full w-1/2 text-sm font-bold border-r border-black"
                >
                  <button className="h-full">
                    <img
                      src={mypage}
                      alt="마이페이지 아이콘"
                      className="inline-block mr-2"
                    />
                    <span className="text-sm font-bold">마이페이지</span>
                  </button>
                </Link>
                <a
                  className="inline-block h-full w-1/2 text-sm font-bold"
                  onClick={signOut}
                  href="/"
                >
                  <button className="h-full w-full text-sm font-bold">
                    로그아웃
                  </button>
                </a>
              </>
            )}
          </>
        ) : (
          <>
            <Link
              className="inline-block h-full w-1/2 text-sm font-bold border-r border-black"
              to="/signin"
            >
              <button className="h-full w-full text-sm font-bold">
                로그인
              </button>
            </Link>
            <Link
              className="inline-block h-full w-1/2 text-sm font-bold"
              to="/join/agree"
            >
              <button className="h-full w-full text-sm font-bold">
                회원가입
              </button>
            </Link>
          </>
        )}
      </div>
      <div className="lists py-2 space-y-2 px-4 border-b border-black">
        <h2 className="h-10">
          <button className="h-full w-full text-left text-sm text-[#6D6C6C] ">
            <Link className="block" to="/classGoList">
              지역으로 가다
            </Link>
          </button>
        </h2>
        <h2 className="h-10">
          <button className="h-full w-full text-left text-sm text-[#6D6C6C] ">
            <Link className="block" to="/classComeList">
              집으로 오다
            </Link>
          </button>
        </h2>
        <h2 className="h-10">
          <button className="h-full w-full text-left text-sm text-[#6D6C6C] ">
            <Link className="block" to="/magazine">
              PX4 매거진
            </Link>
          </button>
        </h2>
        <h2 className="h-10">
          <button className="h-full w-full text-left text-sm text-[#6D6C6C] ">
            <Link className="block" to="/place">
              로컬픽 플레이스
            </Link>
          </button>
        </h2>
      </div>
      <div className="pages py-2 space-y-2 px-4 border-b border-black">
        <h2 className="h-10">
          <button className="h-full w-full text-left text-sm text-[##6D6C6C] ">
            <Link className="block" to="/whatislocalpick">
              로컬픽이란?
            </Link>
          </button>
        </h2>
        <h2 className="h-10">
          <button className="h-full w-full text-left text-sm text-[##6D6C6C] ">
            <Link className="block" to="/collectInst">
              작가모집
            </Link>
          </button>
        </h2>
        <h2 className="h-10">
          <button className="h-full w-full text-left text-sm text-[##6D6C6C] ">
            <Link className="block" to="/collectCurator">
              큐레이터모집
            </Link>
          </button>
        </h2>
      </div>
      <div className="qna py-2 space-y-2 px-4">
        <div className="h-10">
          <p className="h-full w-full text-left text-[##6D6C6C] text-sm">
            고객지원
          </p>
        </div>
        <div className="h-10 pl-4">
          {isLoggedInVar() ? (
            <Link
              to={
                data?.me.role === "General"
                  ? `/mypage/question`
                  : `/instPage/consult`
              }
              className="inline-block h-full w-full text-left text-[##6D6C6C] text-sm"
            >
              {data?.me.role === "General" ? "1:1 문의" : "1:1 컨설팅"}
            </Link>
          ) : (
            <a
              href="/signin"
              onClick={() => {
                alert("로그인이 필요한 서비스 입니다.");
              }}
              className="inline-block h-full w-full text-left text-[##6D6C6C] text-sm"
            >
              1:1문의
            </a>
          )}
        </div>
      </div>
    </div>
  );
};
