import { LayoutTop } from "../../layout/top";
import { Footer } from "../../layout/footer";
import { Modal } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper";
import { styled } from "@mui/material/styles";
import usePagination from "@mui/material/usePagination";
import "swiper/css/pagination";
import "swiper/css";
import { delrefund } from "../../layout/text";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import who from "../../assets/icon/who.png";
import { getProduct } from "../../__generated__/getProduct";
import {
  listProductView,
  listProductView_listProductView_productViews,
} from "../../__generated__/listProductView";
import { useGetProduct } from "../../hooks/useGetProduct";
import { useListProductView } from "../../hooks/useListProductView";
import {
  CanBuyInput,
  GetProductInput,
  ListProductReviewInput,
  ListProductViewInput,
  UserRole,
} from "../../__generated__/globalTypes";
import ReactQuill from "react-quill";
import { useMe } from "../../hooks/useMe";
import { useCanBuy } from "../../hooks/useCanBuy";
import moment from "moment";
import { ToTheTop } from "../../components/to-the-top";
import { useListProductReview } from "../../hooks/useListProductReview";
import { ComponentCrProductReview } from "../../components/component-cr-product-review";
import { PaginationComponent } from "../../components/pagination";
import { ComponentUpProductReview } from "../../components/component-up-product-review";
import { promotionProductsDiscountRate } from "../../constants";
import { calculate } from "../../apollo";
export const Detail = () => {
  const params = useParams();
  const [refund, setRefund] = useState(false);
  const [page, setPage] = useState<number>(1);
  const setPageHandler = (page: number) => {
    if (params.key) {
      setPage(page);
      listProductReviewHandler(+params.key, page);
    }
  };

  const [scroll, setScroll] = useState<number>(0);

  const handleScroll = () => {
    setScroll(document.documentElement.scrollTop);
  };

  const List = styled("ul")({
    listStyle: "none",
    paddingTop: 20,
    textAlign: "center",
    display: "block",
  });
  const { items } = usePagination({
    count: 1,
  });

  const { data: me } = useMe();

  const onCompleted = (data: getProduct) => {};
  const onListProductViewCompleted = (data: listProductView) => {};
  const [getProduct, { data, loading }] = useGetProduct(onCompleted);
  const [
    listProductView,
    { data: listProductViewData, loading: listProductViewLoading },
  ] = useListProductView(onListProductViewCompleted);
  const [
    listProductReview,
    {
      data: listProductReviewData,
      loading: listProductReviewLoading,
      refetch: listProductReviewRefetch,
    },
  ] = useListProductReview();
  const listProductReviewHandler = (productId: number, page: number) => {
    const input: ListProductReviewInput = {
      productId,
      page,
    };
    listProductReview({ variables: { input } });
  };
  const [canBuy, { data: canBuyData, loading: canBuyLoading }] = useCanBuy();

  useEffect(() => {
    if (params.key) {
      const listProductViewInput: ListProductViewInput = {
        productId: +params.key,
      };
      listProductView({ variables: { input: listProductViewInput } });
      const getProductInput: GetProductInput = {
        productId: +params.key,
      };
      getProduct({ variables: { input: getProductInput } });
      const canBuyInput: CanBuyInput = {
        productId: +params.key,
      };
      listProductReviewHandler(+params.key, page);
      if (me) {
        canBuy({ variables: { input: canBuyInput } });
      }
    }
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className="contentWrap">
      <LayoutTop title={"nothing"} />
      <ToTheTop posi={` bottom-24 sm:bottom-5 sm:right-10 right-5`} />
      <main id="main">
        {!loading && data?.getProduct?.product && (
          <div className="wrapper pb-20">
            <div className="max-w-screen-yl mx-auto flex flex-wrap justify-center pt-8 relative">
              <div className="hidden lg:block flex-1 pl-[5%] relative">
                <ul
                  className={`space-y-10 text-right pr-16 ${
                    scroll < 170
                      ? `absolute right-0 top-[220px]`
                      : `sticky top-[170px]`
                  }`}
                >
                  <li>
                    <a href="#intro">클래스소개</a>
                  </li>
                  <li>
                    <a href="#curr">커리큘럼</a>
                  </li>
                  <li>
                    <a href="#kit">DIY Kit</a>
                  </li>
                  <li>
                    <a href="#FAQ">FAQ</a>
                  </li>
                  <li>
                    <a href="#refund">환불정책</a>
                  </li>
                  <li>
                    <a href="#reply">후기</a>
                  </li>
                </ul>
              </div>
              <div className="max-w-[860px] lg:px-0 px-4 w-full">
                <div className="Topper">
                  <Swiper
                    modules={[Navigation, Autoplay, Pagination]}
                    slidesPerView={1}
                    pagination={{
                      clickable: true,
                      el: ".swiper-pagination",
                      type: "bullets",
                    }}
                    autoplay={{ delay: 5000 }}
                    className="relative"
                  >
                    <div className="swiper-pagination"></div>
                    {listProductViewData?.listProductView.productViews
                      ?.filter((productView) => productView.view)
                      .map(
                        (
                          productView: listProductView_listProductView_productViews
                        ) => (
                          <SwiperSlide
                            key={`swiper-key-${productView.view?.url}`}
                            className="w-full relative overflow-hidden"
                          >
                            <img
                              id={`view-${productView.index}`}
                              src={productView.view?.url}
                              alt={`썸네일`}
                              draggable="false"
                              className={`w-full aspect-detailMain object-cover`}
                            />
                          </SwiperSlide>
                        )
                      )}
                  </Swiper>
                  <div className="py-8 md:py-20 border-b border-grays px-4 lg:px-0">
                    <p className="pb-2 font-bold text-left sm:text-center">
                      {data.getProduct.product.inst.user?.name}
                    </p>
                    <p className="text-left sm:text-center text-xl lg:text-2xl gangwon ">
                      {data.getProduct.product.productInfo?.title}
                    </p>
                  </div>
                  <div className="py-4 sm:py-10 lg:py-20 px-4 grid grid-cols-1 sm:grid-cols-2 gap-5 border-b border-grays lg:border-0">
                    {data.getProduct.product.productType === "Offline" && (
                      <>
                        <p className="flex flex-wrap justify-between sm:justify-start ">
                          <span className="mr-5 text-text text-lg sm:text-sm">
                            진행일자
                          </span>
                          {data.getProduct.product.productInfo?.scheduledAt && (
                            <span className="font-bold sm:font-normal text-black text-xl sm:text-base">
                              {moment(
                                data.getProduct.product.productInfo.scheduledAt
                              ).format("YYYY년 M월 D일")}
                            </span>
                          )}
                        </p>
                        <p className="flex flex-wrap justify-between sm:justify-start ">
                          <span className="mr-5 text-text text-lg sm:text-sm">
                            진행장소
                          </span>
                          {data.getProduct.product.productInfo?.place && (
                            <span className="flex-1 sm:flex-none text-right sm:text-left font-bold sm:font-normal text-black text-xl sm:text-base">
                              {data.getProduct.product.productInfo.place}
                            </span>
                          )}
                        </p>
                        <p className="flex flex-wrap justify-between sm:justify-start ">
                          <span className="mr-5 text-text text-lg sm:text-sm">
                            초대인원
                          </span>
                          {data.getProduct.product.productInfo?.max && (
                            <span className="font-bold sm:font-normal text-black text-xl sm:text-base">
                              {data.getProduct.product.productInfo?.max}명
                            </span>
                          )}
                        </p>
                      </>
                    )}

                    <div className="flex flex-wrap justify-between sm:justify-start">
                      <span className="sm:mr-5 text-text text-lg sm:text-sm">
                        강의료
                      </span>
                      <p>
                        <span
                          className={`${
                            data.getProduct.product.productInfo &&
                            data.getProduct.product.productInfo.discountRate +
                              promotionProductsDiscountRate(
                                data.getProduct.product.promotionProducts
                              ) >
                              0
                              ? `text-text line-through`
                              : ``
                          } text-base font-bold sm:font-bold sm:text-sm align-middle`}
                        >
                          {`${data.getProduct.product.productInfo?.price?.toLocaleString()}`}
                          원
                        </span>
                        {data.getProduct.product.productInfo &&
                          data.getProduct.product.productInfo?.discountRate +
                            promotionProductsDiscountRate(
                              data.getProduct.product.promotionProducts
                            ) >
                            0 && (
                            <>
                              <span className="mr-2 text-xl sm:text-base font-bold sm:font-normal text-black">
                                {` ${
                                  data.getProduct.product.productInfo
                                    .discountRate +
                                  promotionProductsDiscountRate(
                                    data.getProduct.product.promotionProducts
                                  )
                                }%`}
                              </span>
                              <span className="text-xl sm:text-base font-bold sm:font-normal text-black">
                                {data.getProduct?.product.productInfo?.price &&
                                  calculate(
                                    Number(
                                      data.getProduct.product?.productInfo
                                        ?.price
                                    ),
                                    data.getProduct.product?.productInfo
                                      ?.discountRate +
                                      promotionProductsDiscountRate(
                                        data.getProduct.product
                                          .promotionProducts
                                      )
                                  ).toLocaleString()}
                                원
                              </span>
                            </>
                          )}
                      </p>
                    </div>
                    <p className="flex flex-wrap justify-between sm:justify-start ">
                      <span className="mr-5 text-text text-lg sm:text-sm">
                        진행시간
                      </span>
                      {data.getProduct.product.productInfo?.playTime && (
                        <span className="font-bold sm:font-normal text-black text-xl sm:text-base">
                          {data.getProduct.product.productInfo.playTime}
                        </span>
                      )}
                    </p>
                    <p className="flex flex-wrap justify-between sm:justify-start ">
                      <span className="mr-5 text-text text-lg sm:text-sm">
                        준비물
                      </span>
                      {data.getProduct.product.productInfo?.materials && (
                        <span className="font-bold sm:font-normal text-black text-xl sm:text-base">
                          {data.getProduct.product.productInfo.materials}
                        </span>
                      )}
                    </p>
                  </div>
                  <div className="block lg:hidden pt-4 pb-8 text-text">
                    <div className="w-full grid grid-cols-3">
                      <a href="#intro" className="block h-10">
                        <button className="w-full h-full">클래스소개</button>
                      </a>
                      <a href="#curr" className="block h-10">
                        <button className="w-full h-full">커리큘럼</button>
                      </a>
                      <a href="#kit" className="block h-10">
                        <button className="w-full h-full">DIY Kit</button>
                      </a>
                      <a href="#FAQ" className="block h-10">
                        <button className="w-full h-full">FAQ</button>
                      </a>
                      <a href="#refund" className="block h-10">
                        <button className="w-full h-full">환불정책</button>
                      </a>
                      <a href="#reply" className="block h-10">
                        <button className="w-full h-full">후기</button>
                      </a>
                    </div>
                  </div>
                  <div className="px-4 lg:px-0">
                    <div className="relative w-full p-4 text-center bg-[#F1F2EF] whitespace-pre-wrap flex flex-wrap flex-col sm:flex-row content-center">
                      {data.getProduct.product.productInfo?.summary && (
                        <p className="flex-1 px-4 flex flex-wrap content-center justify-center pt-6 sm:pt-0">
                          {data.getProduct.product.productInfo?.summary}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="fixed sm:static bottom-0 left-0 w-full sm:mt-10 text-center z-10 p-4 sm:p-0 bg-white sm:bg-transparent">
                    <a
                      href={
                        me?.me.role !== UserRole.General
                          ? `/signin`
                          : `/payment/${data.getProduct.product.id}`
                      }
                      className={`${
                        data.getProduct.product.productType === "Offline"
                          ? "bg-[#8CBDCB]"
                          : "bg-[#99AC69]"
                      } text-center py-3 w-[280px]  sm:w-[328px] rounded-xl text-white text-sm inline-block ${
                        me && me.me.role !== UserRole.General
                          ? `pointer-events-none `
                          : ``
                      }${
                        me &&
                        me.me.role === UserRole.General &&
                        !canBuyData?.canBuy.ok
                          ? `pointer-events-none`
                          : ``
                      }`}
                    >
                      {me &&
                        me.me.role !== UserRole.General &&
                        `강의는 일반 회원만 구매할 수 있습니다`}
                      {me &&
                        me.me.role === UserRole.General &&
                        canBuyData?.canBuy.ok &&
                        `신청하기`}
                      {me &&
                        me.me.role === UserRole.General &&
                        !canBuyData?.canBuy.ok &&
                        `이미 수강중인 강의입니다`}
                      {!me && `강의를 신청하려면 로그인을 해주세요`}
                    </a>
                  </div>
                </div>
                <div>
                  <div id="intro" className="pt-8 lg:pt-24">
                    <p className="text-4xl gangwon pb-4">클래스소개</p>
                    {data?.getProduct.product?.productIntro?.intro && (
                      <div id={`class_intro`}>
                        <ReactQuill
                          value={data?.getProduct.product?.productIntro?.intro}
                          readOnly={true}
                          theme={`bubble`}
                        />
                      </div>
                    )}
                  </div>
                  <div id="curr" className="pt-8 lg:pt-24">
                    <p className="text-4xl gangwon pb-4">커리큘럼</p>
                    {data?.getProduct.product?.productPage?.page && (
                      <div id={`class_blog`}>
                        <ReactQuill
                          value={data?.getProduct.product?.productPage?.page}
                          readOnly={true}
                          theme={`bubble`}
                        />
                      </div>
                    )}
                  </div>
                  <div id="kit" className="yl:px-0 pt-8 lg:pt-24">
                    <p className="text-4xl gangwon pb-4">DIY Kit</p>
                    {data.getProduct.product.productKit?.view?.url && (
                      <img
                        src={data.getProduct.product.productKit.view.url}
                        alt={`DIY Kit 이미지`}
                        className="pt-8 w-full"
                      />
                    )}
                    {data.getProduct.product.productKit?.desc ? (
                      <div
                        className={`relative w-full py-10 text-center whitespace-pre-wrap`}
                      >
                        {data.getProduct.product.productKit.desc}
                      </div>
                    ) : (
                      <p className="text-center">준비할 키트는 없습니다.</p>
                    )}
                  </div>
                  <div id="FAQ" className="pt-[40px]">
                    <p className="text-4xl gangwon pb-5">FAQ</p>
                    <p className="text-center ">준비중입니다.</p>
                    {/* <img src={faq} alt="18" /> */}
                  </div>
                  <div id="refund" className="pt-[40px]">
                    <p className="text-4xl gangwon">환불정책</p>
                    <div className="p-2 bg-[#F1F2EF] mt-5">
                      <p>
                        환불 정책에 따라 배송 완료일로부터 7일 이내 환불 요청
                        가능합니다.
                      </p>
                      <p className="pt-5 text-text">
                        <button
                          onClick={() => {
                            setRefund(true);
                          }}
                        >
                          전체 환불 정책 보기 &#62;
                        </button>
                      </p>
                    </div>
                  </div>
                  <div id="reply" className="lg:pt-24 pt-[40px]">
                    <p className="text-4xl gangwon">후기</p>
                    {params.key &&
                      !listProductReviewLoading &&
                      listProductReviewData?.listProductReview &&
                      listProductReviewData.listProductReview
                        .productReviews && (
                        <div className="py-10 relative">
                          {listProductReviewData?.listProductReview
                            .productReviews?.length > 0 &&
                            listProductReviewData.listProductReview.productReviews.map(
                              (productReview) => (
                                <div
                                  key={`product-review-${productReview.id}`}
                                  className="my-5 p-2 sm:p-4 max-h-full h-full  max-w-2xl shadow-out mx-auto"
                                >
                                  <ComponentUpProductReview
                                    productReview={productReview}
                                    productId={Number(params.key)}
                                    listRefetch={listProductReviewRefetch}
                                  />
                                </div>
                              )
                            )}
                          {listProductReviewData.listProductReview
                            .productReviews.length === 0 && (
                            <div className="text-center">후기를 남겨주세요</div>
                          )}
                          {listProductReviewData?.listProductReview
                            .productReviews?.length > 0 && (
                            <PaginationComponent
                              page={page}
                              pageHandler={setPageHandler}
                              totalPages={
                                listProductReviewData.listProductReview
                                  .totalPages
                                  ? listProductReviewData.listProductReview
                                      .totalPages
                                  : 0
                              }
                              totalResults={
                                listProductReviewData.listProductReview
                                  .totalResults
                                  ? listProductReviewData.listProductReview
                                      .totalResults
                                  : 0
                              }
                            />
                          )}
                        </div>
                      )}
                    {params.key && (
                      <div className="pt-8" id="class_review">
                        <ComponentCrProductReview
                          productId={+params.key}
                          productType={data.getProduct.product.productType}
                          listRefetch={listProductReviewRefetch}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="hidden lg:block flex-1"></div>
            </div>
          </div>
        )}
      </main>
      <Modal
        open={refund}
        onClose={() => {
          setRefund(false);
        }}
      >
        <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 p-10 max-h-[500px] overflow-y-scroll bg-white rounded-md shadow-lg max-w-[1200px] w-10/12">
          <p className="text-bold text-lg pb-5">배송/환불 정책</p>
          <div
            id="refund"
            className="text-sm px-1 leading-normal whitespace-pre-wrap"
          >
            {delrefund}
          </div>
        </div>
      </Modal>
      <Footer />
    </div>
  );
};
