import React from "react";
import gql from "graphql-tag";
import { ApolloError, useMutation } from "@apollo/client";
import {
  resignRefund,
  resignRefundVariables,
} from "../__generated__/resignRefund";

const RESIGN_REFUND_MUTATION = gql`
  mutation resignRefund($input: ResignRefundInput!) {
    resignRefund(input: $input) {
      ok
      err
    }
  }
`;

export const useResignRefund = (
  onCompleted?: (data: resignRefund) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<resignRefund, resignRefundVariables>(
    RESIGN_REFUND_MUTATION,
    {
      onCompleted,
      onError,
    }
  );
};
