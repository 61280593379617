import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import usePagination from "@mui/material/usePagination";
import { createBrowserHistory } from "history";
import searchImg from "../assets/icon/search.png";
import { useLocation } from "react-router";
import { listUnverifiedCurator } from "../__generated__/listUnverifiedCurator";
import { useListUnverifiedCurator } from "../hooks/useListUnverifiedCurator";
import {
  ApproveCuratorInput,
  ListUnverifiedCuratorInput,
} from "../__generated__/globalTypes";
import { approveCurator } from "../__generated__/approveCurator";
import { useApproveCurator } from "../hooks/useApproveCurator";
import { TableError } from "../components/table-error";
import { useForm } from "react-hook-form";
import { ISearchFormProps } from "../routes/admin-list-magazine";

export const SubAdminListUnverifiedCurator: React.FC = () => {
  const history = createBrowserHistory();
  const location = useLocation();
  const pageHandler = (page: number) => {
    const search =
      location.search.split("search=").length > 1
        ? location.search.split("search=")[1].split("&")[0]
        : null;
    history.push({
      pathname: "/admin/insts",
      search: `?page=${page}${search ? `&search=${search}` : ""}`,
    });
    listUnverifiedCuratorHandler(page, search ? search : undefined);
  };

  const onSearch = async () => {
    const { search } = getValues();
    history.push({
      pathname: "/admin/curators",
      search: `${search ? `?search=${search}` : ""}`,
    });
    listUnverifiedCuratorHandler(1, search);
  };

  const { register, getValues, handleSubmit } = useForm<ISearchFormProps>({
    mode: "all",
  });

  const onCompleted = (data: listUnverifiedCurator) => {};

  const [
    listUnverifiedCurator,
    { data: listUnverifiedCuratorData, loading, error, refetch },
  ] = useListUnverifiedCurator(onCompleted);

  const listUnverifiedCuratorHandler = (page: number, search?: string) => {
    const listVerifiedCuratorInput: ListUnverifiedCuratorInput = {
      ...(page && { page: +page }),
      ...(search && { search }),
    };
    listUnverifiedCurator({ variables: { input: listVerifiedCuratorInput } });
  };

  const onApproveCompleted = (data: approveCurator) => {
    const {
      approveCurator: { ok },
    } = data;
    if (ok) {
      refetch();
    } else {
      alert(data.approveCurator.err);
    }
  };
  const [approveCurator, { loading: approveCuratorLoading }] =
    useApproveCurator(onApproveCompleted);
  const approveCuratorHandler = (curatorIdToApprove: number) => {
    const approveCuratorInput: ApproveCuratorInput = {
      curatorIdToApprove,
    };
    if (!approveCuratorLoading) {
      approveCurator({
        variables: {
          input: approveCuratorInput,
        },
      });
    }
  };

  const { items } = usePagination({
    count: listUnverifiedCuratorData?.listUnverifiedCurator.totalPages
      ? listUnverifiedCuratorData.listUnverifiedCurator.totalPages
      : 1,
  });

  useEffect(() => {
    listUnverifiedCuratorHandler(1);
  }, []);

  const List = styled("ul")({
    listStyle: "none",
    paddingTop: 20,
    textAlign: "center",
    display: "block",
  });

  return (
    <div className={`w-full h-full px-10 py-12`}>
      <div className="text-right pb-8">
        <form onSubmit={handleSubmit(onSearch)}>
          <p className="inline-block max-w-md w-full text-right relative">
            <input
              {...register(`search`)}
              type="text"
              className="w-full py-1 px-4 bg-white rounded-full"
              placeholder="작가명으로 검색"
            />
            <button className="absolute right-3 top-1">
              <img src={searchImg} alt="찾기" className="w-5" />
            </button>
          </p>
        </form>
      </div>
      {/* 여기 */}
      <div className="bg-white p-5 rounded-2xl shadow-lg">
        <p className="text-main font-bold py-2">승인대기 큐레이터</p>
        <table className="w-full">
          <thead>
            <tr className="bg-main  border-gray-300">
              <th className="text-white ">번호</th>
              <th className="text-white ">이름</th>
              <th className="text-white ">아이디</th>
              <th className="text-white ">연락처</th>
              <th className="text-white ">기능</th>
            </tr>
          </thead>
          <tbody>
            {!loading &&
              listUnverifiedCuratorData?.listUnverifiedCurator.curators &&
              listUnverifiedCuratorData.listUnverifiedCurator.curators.map(
                (curator, index) => (
                  <tr
                    key={`unverified-curator-${curator.id}`}
                    className="border-b-2 border-gray-300 hover:bg-gray-100 cursor-pointer h-4"
                  >
                    <td className="text-sm text-center py-1 px-1 h-full overflow-hidden">
                      {curator.id}
                    </td>
                    <td className="text-sm text-center h-full relative">
                      {curator.name}
                    </td>
                    <td className="text-sm text-center  relative">
                      {curator.email}
                    </td>
                    <td className="text-sm text-center py-1 px-1 h-full overflow-hidden">
                      {curator.phone}
                    </td>
                    <td className="text-sm text-center py-1 px-1 h-full overflow-hidden">
                      <button
                        className="hover:underline text-main"
                        onClick={() => approveCuratorHandler(curator.id)}
                      >
                        승인
                      </button>
                    </td>
                  </tr>
                )
              )}
          </tbody>
        </table>
        {loading && <TableError errorMessage={`정보를 불러오는 중입니다`} />}
        {!loading &&
          listUnverifiedCuratorData?.listUnverifiedCurator.curators &&
          listUnverifiedCuratorData.listUnverifiedCurator.curators.length <
            1 && (
            <TableError errorMessage={`해당하는 큐레이터 정보가 없습니다`} />
          )}
        <List>
          {items.map(({ page, type, selected, ...item }, index) => {
            let children = null;

            if (type === "start-ellipsis" || type === "end-ellipsis") {
              children = "…";
            } else if (type === "page") {
              children = (
                <button
                  type="button"
                  style={{
                    fontWeight: selected ? "bold" : undefined,
                  }}
                  {...item}
                  onClick={() => pageHandler(page)}
                >
                  {page}
                </button>
              );
            } else if (type === "previous") {
              children = (
                <button
                  type="button"
                  {...item}
                  onClick={() => pageHandler(page - 1)}
                >
                  <i className="fas fa-angle-left"></i>
                </button>
              );
            } else if (type === "next") {
              children = (
                <button type="button" {...item}>
                  <i className="fas fa-angle-right"></i>
                </button>
              );
            }
            return (
              <li
                key={index + type}
                className={`text-xs px-2  border-grays inline-block ${
                  (type === "page" &&
                    page !== 10 &&
                    items.length % 10 > 3 &&
                    "border-r") ||
                  (type === "end-ellipsis" && "border-r") ||
                  (type === "start-ellipsis" && "border-r")
                }`}
              >
                {children}
              </li>
            );
          })}
        </List>
      </div>
    </div>
  );
};
