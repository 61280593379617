import React from "react";
import { gql, useQuery } from "@apollo/client";
import { listProductMineActive } from "../__generated__/listProductMineActive";

const LIST_PRODUCT_MINE_ACTIVE_QUERY = gql`
  query listProductMineActive {
    listProductMineActive {
      ok
      err
      products {
        id
        productInfo {
          scheduledAt
          title
        }
        inst {
          id
          atelier {
            name
          }
          user {
            name
          }
        }
        createdAt
        productStatus
        productType
        productKit {
          id
          view {
            url
          }
          desc
        }
        productViews {
          index
          is_rep
          id
          view {
            url
          }
        }
      }
    }
  }
`;

export const useListProductMineActive = (
  onCompleted?: (data: listProductMineActive) => void
) => {
  return useQuery<listProductMineActive>(LIST_PRODUCT_MINE_ACTIVE_QUERY, {
    onCompleted,
    fetchPolicy: "network-only",
  });
};
