import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  makeVar,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { getToken } from "./constants/token";

const token = getToken();

export const pageIndicator = makeVar("");
export const isLoggedInVar = makeVar(Boolean(token));
export const authTokenVar = makeVar(token);
//uri
export const UPLOAD_URI = "https://backend.hilocalpick.com/uploads";
export const UPLOAD_BUSINESS_URI =
  "https://backend.hilocalpick.com/uploads/business";
export const UPLOAD_VIDEO_URI = "https://backend.hilocalpick.com/uploads/video";
const uri = "https://backend.hilocalpick.com/graphql";

const httpLink = createHttpLink({ uri });

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      "lo-jwt": authTokenVar() || "",
    },
  };
});

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  uri,
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          isLoggedInVar: {
            read() {
              return isLoggedInVar();
            },
          },
          token: {
            read() {
              return authTokenVar();
            },
          },
        },
      },
    },
  }),
});

// comma
export const comma = (num: number) => {
  let str = num + "";
  var point = str.length % 3;
  var len = str.length;

  let thousand = str.substring(0, point);
  while (point < len) {
    if (thousand !== "") thousand += ",";
    thousand += str.substring(point, point + 3);
    point += 3;
  }
  return thousand;
};
//  calculate
export const calculate = (pr: number, per: number) => {
  if (per === 0) {
    return pr;
  } else {
    var result = pr * (1 - per / 100);
    return result;
  }
};
