import React from "react";
import gql from "graphql-tag";
import { ApolloError, useMutation } from "@apollo/client";
import {
  upProductThemeCurr,
  upProductThemeCurrVariables,
} from "../__generated__/upProductThemeCurr";
import {
  upProductKit,
  upProductKitVariables,
} from "../__generated__/upProductKit";

const UP_PRODUCT_KIT_MUTATION = gql`
  mutation upProductKit($input: UpProductKitInput!) {
    upProductKit(input: $input) {
      ok
      err
    }
  }
`;

export const useUpProductKit = (
  onCompleted?: (data: upProductKit) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<upProductKit, upProductKitVariables>(
    UP_PRODUCT_KIT_MUTATION,
    {
      onCompleted,
      onError,
    }
  );
};
