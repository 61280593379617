import { Link } from "react-router-dom";
import bannerLeft from "../../../assets/images/banners/banner3.jpg";
import bannerRight from "../../../assets/images/banners/banner4.jpg";
import banner from "../../../assets/images/banners/banner5.jpg";
import { useGetBannerOptionActive } from "../../../hooks/useGetBannerOptionActive";
export const Magazine = () => {
  const { data } = useGetBannerOptionActive();

  return (
    <>
      <div
        id="magazine"
        className={`max-w-screen-yl mx-auto px-4 py-8 lg:py-20`}
      >
        <div className="flex flex-wrap justify-between">
          <p className="inline-block text-main gangwon text-2xl sm:text-3xl">
            <a
              href="/magazine"
              className="hover:underline inline-flex flex-wrap content-center"
            >
              <span className="align-middle">PX4매거진</span>
            </a>
            <span className="hidden sm:inline align-middle text-sm ml-3 a13">
              <i className="fas fa-chevron-right"></i>
            </span>
          </p>
          <button className="sm:hidden flex pb-2 md:pb-0 hover:underline text-text text-xs md:text-base flex-wrap content-end">
            <a href="/magazine">more</a>
          </button>
        </div>
        <div className="hidden sm:flex flex-wrap justify-between">
          <p className="text-text pt-3 text-sm sm:text-base">
            트렌디한 지역 분위기를 감은 힙한 공간을 로컬픽의 관점으로
            큐레이션합니다.
          </p>
          <button className="hidden sm:flex pb-2 md:pb-0 hover:underline text-text text-xs md:text-base flex-wrap content-end">
            <a href="/magazine">more</a>
          </button>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-3 md:gap-5 pt-2 lg:pt-5">
          {data?.getBannerOptionActive.bannerOptions &&
            data.getBannerOptionActive.bannerOptions
              .filter(
                (bannerOption) =>
                  bannerOption.index == 14 || bannerOption.index == 15
              )
              .map((bannerOption, index) => (
                <a
                  key={`magazine-banner-${index}-${bannerOption.id}`}
                  href={`/magazine/${bannerOption.product?.id}`}
                  className="block relative aspect-[340/171] overflow-hidden"
                >
                  {bannerOption.product?.productViews && (
                    <img
                      src={`${
                        bannerOption.product?.productViews?.filter(
                          (productView) => productView.is_rep
                        )[0].view?.url
                      }`}
                      alt={`매거진 배너 ${bannerOption.index}`}
                      className="absolute left-0 top-0 w-full h-full object-cover transform hover:scale-125 scale-100 transition-transform duration-300"
                    />
                  )}
                </a>
              ))}
        </div>
      </div>
      <div className="w-full relative">
        {data?.getBannerOptionActive.bannerOptions && (
          <a href={`${data.getBannerOptionActive.bannerOptions[5].link}`}>
            <img
              src={`${
                data.getBannerOptionActive.bannerOptions[5].view?.url
                  ? data.getBannerOptionActive.bannerOptions[5].view.url
                  : `#`
              }`}
              alt="배너이미지"
              className="w-full max-w-screen-yl mx-auto relative sm:block hidden"
            />
            <img
              src={`${
                data.getBannerOptionActive.bannerOptions[5].subView?.url
                  ? data.getBannerOptionActive.bannerOptions[5].subView?.url
                  : `#`
              }`}
              alt="배너이미지"
              className="w-full max-w-screen-yl mx-auto relative sm:hidden block"
            />
          </a>
        )}
      </div>
    </>
  );
};
