import { SubTop } from "../../../layout/subTop";
import profile from "../../../assets/icon/profile.svg";
import list from "../../../assets/icon/dashboard.svg";
import buy from "../../../assets/icon/buying.svg";
import question from "../../../assets/icon/question.svg";
import change from "../../../assets/icon/change.svg";
import Collapse from "@kunukn/react-collapse";
import { useMe } from "../../../hooks/useMe";
import { Link } from "react-router-dom";
import main from "../../../assets/icon/main.svg";
export const ASIDE = (props: any) => {
  const { data, refetch } = useMe();
  const pages = props.page;
  return (
    <div className="fixed bottom-0 left-0 lg:static lg:pt-20 z-10 lg:z-0 w-full bg-white lg:bg-transparent">
      <div className="consoleBox lg:pt-3 lg:border-t border-[#e2e2e2]">
        <div className="profile text-center lg:block hidden">
          <img src={profile} alt="프로필 사진" className="w-16 inline-block" />
          <p className="name pt-2">{data?.me.name}</p>
        </div>
        <div className="pt-0 lg:pt-5">
          <ul className="w-full border-t border-[#e2e2e2]">
            <li className="lg:hidden lg:w-full w-1/5 h-16 lg:h-auto inline-block align-middle lg:border-b lg:border-[#e2e2e2]">
              <Link
                to={`/`}
                className={`pt-2 inline-block w-full h-full text-left hover:bg-gray-100  lg:px-16 lg:py-2  relative ${
                  pages === 0 && "bg-gray-100"
                }`}
              >
                <img
                  src={main}
                  alt="main 아이콘"
                  className="block mx-auto lg:inline-block lg:mr-2 h-[24px]"
                />
                <span className="text-main lg:text-black lg:inline block pt-1 lg:pt-0 text-sm lg:text-base text-center ">
                  메인
                </span>
              </Link>
            </li>
            <li className="lg:block lg:w-full w-1/5 h-16 lg:h-auto inline-block align-middle lg:border-b lg:border-[#e2e2e2]">
              <Link
                to={`/mypage/`}
                className={`pt-2 inline-block w-full h-full text-left hover:bg-gray-100  lg:px-16 lg:py-2  relative ${
                  pages === 1 && "bg-gray-100"
                }`}
              >
                <img
                  src={list}
                  alt="list 아이콘"
                  className="block mx-auto lg:inline-block lg:mr-2 h-[24px]"
                />
                <span className="text-main lg:text-black lg:inline block pt-1 lg:pt-0 text-sm lg:text-base text-center ">
                  강의목록
                </span>
              </Link>
            </li>
            <li className="lg:block lg:w-full w-1/5 h-16 lg:h-auto inline-block align-middle lg:border-b lg:border-[#e2e2e2]">
              <Link
                to={`/mypage/buylist`}
                className={` pt-2 inline-block  w-full h-full text-left hover:bg-gray-100  lg:px-16 lg:py-2  ${
                  pages === 2 && "bg-gray-100"
                }`}
              >
                <img
                  src={buy}
                  alt="구매내역 아이콘"
                  className="block mx-auto lg:inline-block lg:mr-2 h-[24px]"
                />
                <span className="text-main lg:text-black lg:inline block pt-1 lg:pt-0 text-sm lg:text-base text-center ">
                  구매내역
                </span>
              </Link>
            </li>
            <li className="lg:block lg:w-full w-1/5 h-16 lg:h-auto inline-block align-middle lg:border-b lg:border-[#e2e2e2]">
              <Link
                to={`/mypage/question`}
                className={`pt-2 inline-block w-full h-full text-left hover:bg-gray-100  lg:px-16 lg:py-2  ${
                  pages === 3 && "bg-gray-100"
                }`}
              >
                <img
                  src={question}
                  alt="문의사항 아이콘"
                  className="block mx-auto lg:inline-block lg:mr-2 h-[24px]"
                />
                <span className="text-main lg:text-black lg:inline block pt-1 lg:pt-0 text-sm lg:text-base text-center ">
                  문의사항
                </span>
              </Link>
            </li>
            <li className="lg:block lg:w-full w-1/5 h-16 lg:h-auto inline-block align-middle lg:border-b lg:border-[#e2e2e2]">
              <Link
                to={`/mypage/change`}
                className={`pt-2 inline-block w-full h-full text-left hover:bg-gray-100  lg:px-16 lg:py-2  ${
                  pages === 4 && "bg-gray-100"
                }`}
              >
                <img
                  src={change}
                  alt="개인정보 아이콘"
                  className="block mx-auto lg:inline-block lg:mr-2 h-[24px]"
                />
                <span className="text-main lg:text-black lg:inline block pt-1 lg:pt-0 text-sm lg:text-base text-center ">
                  개인정보
                </span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
