import React from "react";
import { gql, useLazyQuery, useQuery } from "@apollo/client";
import {
  listReceiptMineOnline,
  listReceiptMineOnlineVariables,
} from "../__generated__/listReceiptMineOnline";
import {
  listBuyMineOnline,
  listBuyMineOnlineVariables,
} from "../__generated__/listBuyMineOnline";
import { LIST_RECEIPT_FRAGMENT } from "../fragment-receipt";

const LIST_BUY_MINE_ONLINE_QUERY = gql`
  query listBuyMineOnline($input: ListBuyMineOnlineInput!) {
    listBuyMineOnline(input: $input) {
      ok
      err
      buys {
        ...ListReceiptParts
      }
    }
  }
  ${LIST_RECEIPT_FRAGMENT}
`;

export const useListBuyMineOnline = (
  onCompleted?: (data: listBuyMineOnline) => void
) => {
  return useLazyQuery<listBuyMineOnline, listBuyMineOnlineVariables>(
    LIST_BUY_MINE_ONLINE_QUERY,
    {
      onCompleted,
      fetchPolicy: `network-only`,
    }
  );
};
