import { ApolloError, gql, useMutation } from "@apollo/client";
import React from "react";
import { crReceipt, crReceiptVariables } from "../__generated__/crReceipt";
import { CrProductInput } from "../__generated__/globalTypes";

export interface ICrProductFormProps extends CrProductInput {}

const CR_RECEIPT_MUTATION = gql`
  mutation crReceipt($input: CrReceiptInput!) {
    crReceipt(input: $input) {
      ok
      err
      receipt {
        id
        is_expired
      }
    }
  }
`;

export const useCrReceipt = (
  onCompleted?: (data: crReceipt) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<crReceipt, crReceiptVariables>(CR_RECEIPT_MUTATION, {
    onCompleted,
    onError,
  });
};
