import React from "react";

interface ITableErrorProps {
  errorMessage: string;
}

export const TableError: React.FC<ITableErrorProps> = ({
  errorMessage,
}: ITableErrorProps) => (
  <p className="text-center py-5 text-lg">{errorMessage}</p>
);
