import { Button } from "@mui/material";
import { createBrowserHistory } from "history";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useCrRefund } from "../hooks/useCrRefund";
import { useGetReceipt } from "../hooks/useGetReceipt";
import { crRefund } from "../__generated__/crRefund";
import { CrRefundInput } from "../__generated__/globalTypes";

interface IUserModalRequestRefundProps {
  receiptId?: number;
  closeHandler: () => void;
}

interface ICrRefundFormProps extends CrRefundInput {}

export const SubUserModalRequestRefund: React.FC<
  IUserModalRequestRefundProps
> = ({ receiptId, closeHandler }: IUserModalRequestRefundProps) => {
  const [reason, setReason] = useState("just");

  const { register, getValues, setValue, handleSubmit } =
    useForm<ICrRefundFormProps>({
      mode: `all`,
    });

  const onComplete = (data: crRefund) => {
    const {
      crRefund: { ok, err },
    } = data;
    if (ok) {
      alert("환불 요청이 완료되었습니다");
      closeHandler();
    } else {
      alert(err);
    }
  };

  const [crRefund, { loading }] = useCrRefund(onComplete);
  const [getReceipt, { data }] = useGetReceipt();
  const onSubmit = () => {
    if (!receiptId) {
      alert(`영수증 정보가 유효하지 않습니다. 다시 처음부터 진행해주세요`);
      return;
    }
    if (!loading) {
      const { note } = getValues();
      const crRefundInput: CrRefundInput = {
        note,
        receiptId,
      };
      crRefund({ variables: { input: crRefundInput } });
    }
  };
  useEffect(() => {
    setReason("just");
    setValue("note", "단순변심");
    getReceipt({ variables: { input: { receiptId: Number(receiptId) } } });
  }, []);

  return (
    <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 p-10 bg-white">
      {receiptId && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="w-[400px] space-y-5 px-10">
            <p className="text-main text-2xl text-center py-5">
              취소 이유를 입력해주세요
            </p>
            <div>
              <label
                htmlFor="just"
                className={`align-middle inline-flex flex-wrap content-center justify-center w-5 h-5 rounded-full border-2 cursor-pointer ${
                  reason === "just" ? "border-main" : "border-grays"
                }`}
              >
                {reason === "just" && (
                  <div className="w-3 h-3 bg-main rounded-full"></div>
                )}
              </label>
              <input
                type="radio"
                name={`note`}
                className="hidden"
                id="just"
                checked={reason === "just"}
                onChange={(e) => {
                  if (e.target.checked) {
                    setReason("just");
                    setValue(`note`, `단순변심`);
                  }
                }}
              />
              <span className="ml-3 inline-block align-middle font-bold text-base text-gray-500">
                단순변심
              </span>
            </div>
            <div>
              <label
                htmlFor="rebuy"
                className={`align-middle inline-flex flex-wrap content-center justify-center w-5 h-5 rounded-full border-2 cursor-pointer ${
                  reason === "rebuy" ? "border-main" : "border-grays"
                }`}
              >
                {reason === "rebuy" && (
                  <div className="w-3 h-3 bg-main rounded-full"></div>
                )}
              </label>
              <input
                type="radio"
                name={`note`}
                className="hidden"
                id="rebuy"
                onChange={(e) => {
                  if (e.target.checked) {
                    setReason("rebuy");
                    setValue(`note`, `취소 후 재구매`);
                  }
                }}
              />
              <span className="ml-3 inline-block align-middle font-bold text-base text-gray-500">
                취소 후 재구매
              </span>
            </div>
            <div>
              <label
                htmlFor="other"
                className={`align-middle inline-flex flex-wrap content-center justify-center w-5 h-5 rounded-full border-2 cursor-pointer ${
                  reason === "other" ? "border-main" : "border-grays"
                }`}
              >
                {reason === "other" && (
                  <div className="w-3 h-3 bg-main rounded-full"></div>
                )}
              </label>
              <input
                type="radio"
                name={`note`}
                className="hidden"
                id="other"
                onChange={(e) => {
                  if (e.target.checked) {
                    setReason("other");
                    setValue(`note`, `다른 강의 수강 희망`);
                  }
                }}
              />
              <span className="ml-3 inline-block align-middle font-bold text-base text-gray-500">
                다른 강의 수강 희망
              </span>
            </div>
            <div>
              <label
                htmlFor="etc"
                className={`align-middle inline-flex flex-wrap content-center justify-center w-5 h-5 rounded-full border-2 cursor-pointer ${
                  reason === "etc" ? "border-main" : "border-grays"
                }`}
              >
                {reason === "etc" && (
                  <div className="w-3 h-3 bg-main rounded-full"></div>
                )}
              </label>
              <input
                type="radio"
                name={`note`}
                className="hidden"
                id="etc"
                onChange={(e) => {
                  if (e.target.checked) {
                    setReason("etc");
                    setValue(`note`, `기타`);
                  }
                }}
              />
              <span className="ml-3 inline-block align-middle font-bold text-base text-gray-500">
                기타
              </span>
            </div>

            <p className="text-center pt-4">
              <Button
                type={`button`}
                onClick={closeHandler}
                style={{
                  color: "#8a6a59",
                  backgroundColor: "#fff",
                  fontSize: "1.25rem",
                  width: "150px",
                  padding: "0 4px",
                  marginRight: "8px",
                  fontWeight: "bold",
                  border: "1px solid #8a6a59",
                }}
              >
                닫기
              </Button>
              <Button
                type={`submit`}
                style={{
                  color: "#fff",
                  backgroundColor: "#8a6a59",
                  fontSize: "1.25rem",
                  width: "150px",
                  padding: "0 4px",
                  fontWeight: "bold",
                }}
              >
                요청하기
              </Button>
            </p>
          </div>
        </form>
      )}
    </div>
  );
};
