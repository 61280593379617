import React from "react";
import { gql, useLazyQuery } from "@apollo/client";
import { listProduct } from "../__generated__/listProduct";
import { listProductVariables } from "../__generated__/listProduct";
import { CLASS_LIST_WIDGET_FRAGMENT } from "../fragments";

const LIST_PRODUCT_QUERY = gql`
  query listProduct($input: ListProductInput!) {
    listProduct(input: $input) {
      ok
      err
      totalResults
      totalPages
      onlineClasses {
        ...ClassListWidgetParts
      }
      offlineClasses {
        ...ClassListWidgetParts
      }
    }
  }
  ${CLASS_LIST_WIDGET_FRAGMENT}
`;

export const useListProduct = (onCompleted?: (data: listProduct) => void) => {
  return useLazyQuery<listProduct, listProductVariables>(LIST_PRODUCT_QUERY, {
    onCompleted,
    fetchPolicy: "network-only",
  });
};
