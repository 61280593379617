import profile from "../../../assets/icon/profile.svg";
import list from "../../../assets/icon/dashboard.svg";
import buy from "../../../assets/icon/buying.svg";
import question from "../../../assets/icon/question.svg";
import change from "../../../assets/icon/change.svg";
import enroll from "../../../assets/icon/enroll.svg";
import plus from "../../../assets/icon/plus.svg";
import { Link } from "react-router-dom";
import { useMe } from "../../../hooks/useMe";
import main from "../../../assets/icon/main.svg";

export const Aside = (props: any) => {
  const pages = props.page;
  const { data } = useMe();

  return (
    <div className="fixed bottom-0 left-0 lg:static lg:pt-20 z-10 lg:z-0 w-full bg-white lg:bg-transparent">
      <div className="consoleBox lg:pt-3 lg:border-t border-[#e2e2e2]">
        <div className="profile text-center lg:block hidden">
          <img src={profile} alt="프로필 사진" className="w-16 inline-block" />
          <p className="name pt-2">{data?.me.name}</p>
        </div>
        <div className="pt-0 lg:pt-5">
          <ul className="w-full border-t border-[#e2e2e2]">
            <li className="lg:hidden lg:w-full w-1/5 h-16 lg:h-auto inline-block align-middle lg:border-b lg:border-[#e2e2e2]">
              <Link
                to={`/`}
                className={`pt-2 inline-block w-full h-full text-left hover:bg-gray-100  lg:px-16 lg:py-2  relative `}
              >
                <img
                  src={main}
                  alt="main 아이콘"
                  className="block mx-auto lg:inline-block lg:mr-2 h-[24px]"
                />
                <span className="text-main lg:text-black lg:inline block pt-1 lg:pt-0 text-sm lg:text-base text-center ">
                  메인
                </span>
              </Link>
            </li>
            <li className="lg:block lg:w-full w-1/5 h-16 lg:h-auto inline-block align-middle lg:border-b lg:border-[#e2e2e2]">
              <Link
                to={"/instPage"}
                className={`pt-2 block w-full h-full text-left hover:bg-gray-100  lg:px-16 lg:py-2  relative ${
                  pages === 0 && "bg-gray-100"
                }`}
              >
                <img
                  src={list}
                  alt="list 아이콘"
                  className="block mx-auto lg:inline-block lg:mr-2 h-[24px]"
                />
                <span className="lg:inline block pt-1 lg:pt-0 text-xs lg:text-base text-center text-main sm:text-black">
                  강의목록
                </span>
              </Link>
            </li>
            <li
              className={`bg-main lg:bg-transparent fixed lg:static right-4 bottom-48 lg:block lg:w-full w-12 h-12 lg:h-auto align-middle lg:border-b lg:border-[#e2e2e2] rounded-full lg:rounded-none`}
            >
              <Link
                to={"/instPage/enroll"}
                className={`flex flex-col lg:block flex-wrap content-center justify-center w-full h-full text-left lg:hover:bg-gray-100  lg:px-16 lg:py-2  relative ${
                  pages === 1 && "lg:bg-gray-100"
                }`}
              >
                <img
                  src={enroll}
                  alt="새 강의 등록 아이콘"
                  className="hidden mx-auto lg:inline-block lg:mr-2 h-[24px]"
                />
                <img
                  src={plus}
                  alt="새 강의 등록 아이콘"
                  className="mx-auto block lg:hidden w-3"
                />
                <span className="lg:inline block pt-1 lg:pt-0 text-[10px] lg:text-base text-center text-white lg:text-black">
                  강의등록
                </span>
              </Link>
            </li>
            <li className="lg:block lg:w-full w-1/5 h-16 lg:h-auto inline-block align-middle lg:border-b lg:border-[#e2e2e2]">
              <Link
                to={"/instPage/consult"}
                className={`pt-2 block w-full h-full text-left hover:bg-gray-100  lg:px-16 lg:py-2  relative ${
                  pages === 2 && "bg-gray-100"
                }`}
              >
                <img
                  src={question}
                  alt="컨설팅 요청 아이콘"
                  className="block mx-auto lg:inline-block lg:mr-2 h-[24px]"
                />
                <span className="lg:inline block pt-1 lg:pt-0 text-xs lg:text-base text-center text-main sm:text-black">
                  컨설팅
                </span>
              </Link>
            </li>
            <li className="lg:block lg:w-full w-1/5 h-16 lg:h-auto inline-block align-middle lg:border-b lg:border-[#e2e2e2]">
              <Link
                to={"/instPage/change"}
                className={`pt-2 block w-full h-full text-left hover:bg-gray-100  lg:px-16 lg:py-2  relative ${
                  pages === 3 && "bg-gray-100"
                }`}
              >
                <img
                  src={change}
                  alt="개인정보 아이콘"
                  className="block mx-auto lg:inline-block lg:mr-2 h-[24px]"
                />
                <span className="lg:inline block pt-1 lg:pt-0 text-xs lg:text-base text-center text-main sm:text-black">
                  정보수정
                </span>
              </Link>
            </li>
            <li className=" lg:block lg:w-full w-1/5 h-16 lg:h-auto inline-block align-middle lg:border-b lg:border-[#e2e2e2]">
              <Link
                to={"/instPage/sales"}
                className={`pt-2 block w-full h-full text-left hover:bg-gray-100  lg:px-16 lg:py-2  relative ${
                  pages === 4 && "bg-gray-100"
                }`}
              >
                <img
                  src={buy}
                  alt="구매내역 아이콘"
                  className="block mx-auto lg:inline-block lg:mr-2 h-[24px]"
                />
                <span className="lg:inline block pt-1 lg:pt-0 text-xs lg:text-base text-center text-main sm:text-black">
                  매출관리
                </span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
