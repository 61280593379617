import { ApolloError, gql, useMutation } from "@apollo/client";
import React from "react";
import {
  verifySignupEmail,
  verifySignupEmailVariables,
} from "../__generated__/verifySignupEmail";

const VERIFY_SIGNUP_EMAIL_MUTATION = gql`
  mutation verifySignupEmail($input: VerifySignupEmailInput!) {
    verifySignupEmail(input: $input) {
      ok
      err
    }
  }
`;

export const useVerifySignupEmail = (
  onCompleted?: (data: verifySignupEmail) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<verifySignupEmail, verifySignupEmailVariables>(
    VERIFY_SIGNUP_EMAIL_MUTATION,
    {
      onCompleted,
      onError,
    }
  );
};
