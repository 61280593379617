import { useEffect, useState } from "react";
import usePagination from "@mui/material/usePagination";
import { styled } from "@mui/material/styles";
import { useLocation, useNavigate } from "react-router";
import { ISearchFormProps } from "../../../../admin-list-magazine";
import { useForm } from "react-hook-form";
import { useListReceiptMine } from "../../../../../hooks/useListReceiptMine";
import { ListReceiptMineInput } from "../../../../../__generated__/globalTypes";
import { TableError } from "../../../../../components/table-error";
import { PaginationComponent } from "../../../../../components/pagination";
import moment from "moment";
export const SalesList_st = () => {
  const history = useNavigate();
  const location = useLocation();
  const [page, setPage] = useState<number>(1);
  const [search, setSearch] = useState<string>("");
  const word = "매출";

  const { register, getValues, handleSubmit } = useForm<ISearchFormProps>({
    mode: "all",
  });

  const pageHandler = (page: number) => {
    setPage(page);
    listReceiptMineHandler(page, search);
  };

  const onSearch = async () => {
    const { search } = getValues();
    setSearch(search);
    history({
      pathname: "/admin/receipt",
      search: `${search ? `?search=${search}` : ""}`,
    });
    listReceiptMineHandler(1, search);
  };

  const [listReceiptMine, { data, loading, refetch }] = useListReceiptMine();
  const listReceiptMineHandler = (page: number | null, search: string) => {
    const input: ListReceiptMineInput = {
      ...(page && { page: +page }),
      ...(search && { search: decodeURI(search) }),
    };
    listReceiptMine({ variables: { input } });
  };
  console.log(data);
  useEffect(() => {
    setPage(
      location.search.split("page=").length > 1
        ? +location.search.split("page=")[1].split("&")[0]
        : 1
    );
    setSearch(
      location.search.split("search=").length > 1
        ? location.search.split("search=")[1].split("&")[0]
        : ""
    );
    listReceiptMineHandler(page, search);
  }, []);

  return (
    <div className="bg-white p-4 rounded-2xl shadow-lg">
      <p className="text-main font-bold pb-3 text-sm lg:text-base">매출 내역</p>
      <table className="w-full">
        <thead>
          <tr className="bg-main  border-gray-300">
            <th className="text-white text-sm lg:text-base hidden lg:table-cell">
              No.
            </th>
            <th className="text-white text-sm lg:text-base hidden lg:table-cell">
              결제일
            </th>
            <th className="text-white text-sm lg:text-base">강의명</th>
            <th className="text-white text-sm lg:text-base">결제금액</th>
            <th className="text-white text-sm lg:text-base">수강생</th>
            <th className="text-white text-sm lg:text-base">결제상태</th>
          </tr>
        </thead>
        <tbody>
          {!loading &&
            data?.listReceiptMine.receipts &&
            data.listReceiptMine.receipts.map((receipt, index) => (
              <tr
                key={`receipt-${receipt?.id}`}
                className="border-b-2 border-gray-300 h-4"
              >
                <td className="text-sm text-center h-full relative min-w-[100px] hidden lg:table-cell">
                  {data.listReceiptMine.totalResults &&
                    data.listReceiptMine.row &&
                    `${
                      data.listReceiptMine.totalResults -
                      data.listReceiptMine.row * (page - 1) -
                      index
                    }`}
                </td>
                <td className="text-sm text-center h-full relative hidden lg:table-cell">
                  {receipt?.createdAt &&
                    moment(receipt.createdAt).format("YYYY-MM-DD")}
                </td>

                <td className="text-sm text-center py-1 px-1 h-full overflow-hidden ">
                  {receipt?.pgOption?.name && receipt.pgOption.name}
                </td>
                <td className="text-sm text-center py-1 px-1 h-full overflow-hidden ">
                  {receipt?.paid_amount && receipt.paid_amount}
                </td>
                <td className="text-sm text-center py-1 px-1 h-full overflow-hidden ">
                  {receipt?.user?.name && receipt.user.name}
                </td>
                <td className="text-sm text-center py-1 px-1 h-full w-1/6 overflow-hidden">
                  {receipt.status && receipt.status}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      {loading && <TableError errorMessage={`정보를 불러오는 중입니다`} />}
      {!loading &&
        data?.listReceiptMine.receipts &&
        data.listReceiptMine.receipts.length < 1 && (
          <TableError errorMessage={`해당하는 ${word} 정보가 없습니다`} />
        )}
      {!loading &&
        data?.listReceiptMine.receipts &&
        data.listReceiptMine.receipts.length > 0 && (
          <PaginationComponent
            page={page}
            totalPages={Number(data.listReceiptMine.totalPages)}
            totalResults={Number(data.listReceiptMine.totalResults)}
            pageHandler={pageHandler}
          />
        )}
    </div>
  );
};
