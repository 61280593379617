import React from "react";
import { gql, useLazyQuery, useQuery } from "@apollo/client";
import { listProductMineInactive } from "../__generated__/listProductMineInactive";
import {
  listProductView,
  listProductViewVariables,
} from "../__generated__/listProductView";

const LIST_PRODUCT_VIEW_QUERY = gql`
  query listProductView($input: ListProductViewInput!) {
    listProductView(input: $input) {
      ok
      err
      productViews {
        id
        index
        view {
          url
          uuid
        }
      }
    }
  }
`;

export const useListProductView = (
  onCompleted?: (data: listProductView) => void
) => {
  return useLazyQuery<listProductView, listProductViewVariables>(
    LIST_PRODUCT_VIEW_QUERY,
    {
      onCompleted,
      fetchPolicy: "network-only",
    }
  );
};
