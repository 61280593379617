import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChalkboardUser, faUsers } from "@fortawesome/free-solid-svg-icons";
import join_inst from "../assets/icon/join_inst.svg";
import join_normal from "../assets/icon/join_normal.svg";
export const SubJoinSelectRole = () => {
  return (
    <div className="bg-main bg-opacity-50 w-full h-full min-h-screen">
      <div className="max-w-screen-yl mx-auto w-full h-full min-h-screen bg-white flex flex-wrap content-center justify-center py-10">
        <div className="w-[640px] grid grid-cols-1 md:grid-cols-2 gap-10">
          <a
            href="/join/dataform"
            className="mx-auto w-full max-w-[300px] aspect-square  flex flex-col flex-wrap content-center justify-center gap-y-10 rounded-lg hover:opacity-70"
          >
            <img src={join_normal} alt="일반회원" className="w-[70%]" />
          </a>
          <a
            href="/join/instForm"
            className="mx-auto w-full max-w-[300px] aspect-square  flex flex-col flex-wrap content-center justify-center gap-y-10 rounded-lg hover:opacity-70"
          >
            <img src={join_inst} alt="작가회원" className="w-[70%]" />
          </a>
        </div>
      </div>
    </div>
  );
};
