import thumb from "../../../../assets/images/sample/Sample1/thumb.png";
import DaumPostcode from "react-daum-postcode";
import { Modal } from "@mui/material";
import { useEffect, useState } from "react";
import {
  IMP_ID,
  payParams,
  RequestPayParams,
  RequestPayResponse,
} from "../../../../interfaces/imp";
import { useMe } from "../../../../hooks/useMe";
import moment from "moment";
import { useForm } from "react-hook-form";
import { me } from "../../../../__generated__/me";
import { Footer } from "../../../../layout/footer";
import { getProduct } from "../../../../__generated__/getProduct";
import { useParams } from "react-router";
import { useGetProduct } from "../../../../hooks/useGetProduct";
import {
  CanBuyInput,
  CrReceiptInput,
  GetProductInput,
} from "../../../../__generated__/globalTypes";
import { crReceipt } from "../../../../__generated__/crReceipt";
import { useCrReceipt } from "../../../../hooks/useCrReceipt";
import { Link } from "react-router-dom";
import { useCanBuy } from "../../../../hooks/useCanBuy";
import { LayoutTop } from "../../../../layout/top";
import kitbox from "../../../../assets/icon/kitbox.svg";
import { promotionProductsDiscountRate } from "../../../../constants";
import { calculate } from "../../../../apollo";
interface ICrReceiptFormProps extends CrReceiptInput {
  subAddress: string;
}

export const Payment = () => {
  const params = useParams();
  const [addressAPI, setAddressAPI] = useState(false);
  const [addr, setAddr] = useState<string>("");
  const [pay, setPay] = useState<string>("card");

  const onGetCompleted = (data: getProduct) => {};
  const [getProduct, { data: getProductData, loading: getProductLoading }] =
    useGetProduct(onGetCompleted);

  const onCrCompleted = (data: crReceipt) => {
    const {
      crReceipt: { ok, err, receipt },
    } = data;
    if (ok) {
      window.location.href = `/payment/completed/${receipt?.id}`;
    } else {
      alert(err);
    }
  };
  const [crReceipt, { data: crReceiptData, loading: crReceiptLoading }] =
    useCrReceipt(onCrCompleted);

  const [canBuy, { data: canBuyData, loading: canBuyLoading }] = useCanBuy();

  useEffect(() => {
    if (params.productId) {
      const getProductInput: GetProductInput = {
        productId: +params.productId,
      };
      getProduct({ variables: { input: getProductInput } });
    }
  }, []);
  const { IMP } = window;
  // daum API
  const handlePostCode = (data: any) => {
    let fullAddress = data.address;
    let extraAddress = "";

    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddress += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddress +=
          extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
    }
    setAddr(fullAddress);
    setValue(`address`, fullAddress);

    setAddressAPI(false);
  };
  const payHandler = (str: string) => {
    setPay(() => {
      return str;
    });
  };

  const callback = (response: RequestPayResponse) => {
    if (!getProductData?.getProduct.product?.productInfo) return;
    const {
      success,
      merchant_uid,
      error_msg,
      imp_uid,
      receipt_url,
      status,
      paid_amount,
      paid_at,
      apply_num,
      pay_method,
      pg_tid,
      pg_type,
      name,
      vbank_date,
      vbank_num,
      vbank_holder,
      vbank_name,
    } = response;
    if (!success) {
      alert(`결제에 실패했습니다`);
      return;
    }

    const { receiver, message, phone, address, subAddress } = getValues();
    const crReceiptInput: CrReceiptInput = {
      receiver,
      message,
      phone,
      address: address + subAddress,
      imp_uid,
      merchant_uid,
      receipt_url: receipt_url ? receipt_url : ``,
      status: status ? status : ``,
      paid_amount: paid_amount ? paid_amount : 0,
      paid_at: paid_at ? `${Number(paid_at) * 1000}` : ``,
      apply_num: apply_num ? apply_num : ``,
      pay_method: pay_method ? pay_method : ``,
      pg_tid: pg_tid ? pg_tid : ``,
      pg_type: pg_type ? pg_type : ``,
      name: name
        ? name
        : getProductData?.getProduct.product?.productInfo?.title,
      productId: getProductData.getProduct.product.id,
      scheduledAt: getProductData.getProduct.product.productInfo.scheduledAt,
      vbank_date: vbank_date ? vbank_date : ``,
      vbank_num: vbank_num ? vbank_num : ``,
      vbank_holder: vbank_holder ? vbank_holder : ``,
      vbank_name: vbank_name ? vbank_name : ``,
    };
    crReceipt({ variables: { input: crReceiptInput } });
  };

  const paymentHandler = () => {
    if (!getProductData?.getProduct.product) {
      alert(`잘못된 접근입니다 강의를 신청할 수 없습니다`);
      window.location.href = "/";
      return;
    }

    if (!getProductData?.getProduct.product?.productInfo?.price) return;

    IMP?.init(IMP_ID);
    const amount: number =
      getProductData.getProduct?.product.productInfo?.price &&
      calculate(
        Number(getProductData.getProduct.product?.productInfo?.price),
        getProductData.getProduct.product?.productInfo?.discountRate +
          promotionProductsDiscountRate(
            getProductData.getProduct.product.promotionProducts
          )
      );

    const payData: RequestPayParams = {
      ...payParams,
      name: getProductData?.getProduct.product?.productInfo?.title
        ? getProductData.getProduct.product.productInfo.title
        : `default`,
      pay_method: pay,
      merchant_uid: `RID${moment().format(`YYMMDDHHmmssSSS`)}`,
      amount,
      ...(pay === "vbank" && { biz_num: "7458800998" }),
      buyer_tel: String(data?.me.phone),
      buyer_email: data?.me.email,
      buyer_name: data?.me.name,
      buyer_addr: `${data?.me.address} ${data?.me.subAddress}`,
    };
    IMP?.request_pay(payData, callback);
  };

  const onCompleted = (data: me) => {
    setAddr(data.me.address);
    setValue("address", data.me.address);
  };

  const { register, getValues, setValue, handleSubmit } =
    useForm<ICrReceiptFormProps>({ mode: "all" });

  const { data, loading } = useMe(onCompleted);

  const onSubmit = () => {
    if (params.productId !== undefined && canBuyData?.canBuy.ok) {
      paymentHandler();
      return;
    }
  };

  useEffect(() => {
    if (params.productId !== undefined && !crReceiptLoading && !canBuyLoading) {
      const canBuyInput: CanBuyInput = {
        productId: +params.productId,
      };
      canBuy({ variables: { input: canBuyInput } });
    }
  }, []);

  return (
    <div className="bg-main bg-opacity-50 w-full">
      <div className="max-w-screen-yl mx-auto w-full bg-white">
        <LayoutTop title={"nothing"} />
        <div className="pt-5 lg:pt-8">
          {!loading &&
            !getProductLoading &&
            getProductData?.getProduct?.product && (
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="px-3 lg:px-14">
                  <div className="border-t border-b border-grays py-3 relative flex flex-col gap-5 lg:flex-row">
                    <p className="font-bold text-base sm:text-xl text-right lg:absolute right-0 top-3">
                      {moment().format("YYYY - MM - DD일")}
                    </p>
                    <div className="lg:w-4/12 py-1 lg:py-0">
                      {getProductData.getProduct.product.productViews &&
                        getProductData.getProduct.product.productViews
                          ?.filter((productView) => productView.is_rep)
                          .map((productView) => (
                            <img
                              key={`poster-image-${productView.id}`}
                              src={productView.view?.url}
                              alt="dd"
                              className="inline-block max-w-[300px] w-1/3 lg:w-1/2 object-cover aspect-square h-full"
                            />
                          ))}
                      <div className="pt-1 sm:pt-2 lg:py-0 pl-5 inline-block align-top w-2/3 lg:w-1/2">
                        <p className="h-[56px] overflow-hidden text-sm sm:text-lg leading-tight ellipsis2">
                          {getProductData.getProduct.product.productInfo &&
                            getProductData.getProduct.product.productInfo.title}
                        </p>
                        <p className="pt-2 sm:pt-5 font-bold text-xs sm:text-sm">
                          {getProductData.getProduct.product.inst?.atelier &&
                            getProductData.getProduct.product.inst.atelier.name}
                        </p>
                      </div>
                    </div>
                    {getProductData.getProduct.product.productKit?.desc && (
                      <div className="lg:w-4/12 py-1 lg:py-0 lg:ml-4 ">
                        {getProductData.getProduct.product.productKit?.view
                          ?.url ? (
                          <img
                            src={
                              getProductData.getProduct.product.productKit.view
                                ?.url
                            }
                            alt="dd"
                            className="max-w-[300px] w-1/3 lg:w-1/2 inline-block h-full aspect-square object-cover"
                          />
                        ) : (
                          <div className="max-w-[300px] w-1/3 lg:w-1/2 inline-flex h-full flex-wrap content-center justify-center border aspect-square">
                            <img
                              src={kitbox}
                              alt="키트 이미지"
                              className="w-full h-full object-cover"
                            />
                          </div>
                        )}

                        <div className="pt-1 sm:pt-2 lg:py-0 pl-5 inline-block align-top w-2/3 lg:w-1/2 h-full">
                          <p className="text-sm sm:text-lg">키트 포함</p>
                          <p className="overflow-hidden h-[180px] pt-2 sm:pt-5 font-bold text-xs sm:text-sm whitespace-pre-wrap ellipsis8">
                            {getProductData.getProduct.product.productKit.desc}
                          </p>
                        </div>
                      </div>
                    )}
                    {getProductData.getProduct.product.productInfo &&
                    getProductData.getProduct.product.productInfo.discountRate +
                      promotionProductsDiscountRate(
                        getProductData.getProduct.product.promotionProducts
                      ) >
                      0 ? (
                      <div className="pt-3 lg:absolute right-0 bottom-3">
                        <p className="text-sm font-bold text-right">
                          {getProductData.getProduct.product.productInfo
                            .discountRate +
                            promotionProductsDiscountRate(
                              getProductData.getProduct.product
                                .promotionProducts
                            )}
                          %
                        </p>
                        <p className="leading-normal text-right lg:text-left">
                          <span className="text-xs lg:text-base align-middle text-text line-through pr-3">
                            {getProductData.getProduct.product.productInfo?.price?.toLocaleString()}
                            원
                          </span>
                          <span className="font-bold  text-xl lg:text-3xl">
                            {getProductData.getProduct?.product.productInfo
                              ?.price &&
                              calculate(
                                Number(
                                  getProductData.getProduct.product?.productInfo
                                    ?.price
                                ),
                                getProductData.getProduct.product?.productInfo
                                  ?.discountRate +
                                  promotionProductsDiscountRate(
                                    getProductData.getProduct.product
                                      .promotionProducts
                                  )
                              ).toLocaleString()}
                            원
                          </span>
                        </p>
                      </div>
                    ) : (
                      <div className="pt-3 lg:absolute right-0 bottom-3">
                        <p className="leading-normal text-right lg:text-left">
                          <span className="font-bold  text-xl lg:text-3xl">
                            {getProductData.getProduct.product.productInfo?.price?.toLocaleString()}
                            원
                          </span>
                        </p>
                      </div>
                    )}
                  </div>
                  <div className="pt-8 sm:pt-16">
                    <p className="text-main font-sm font-bold">
                      결제 방법 선택
                    </p>
                    <div className="pl-2 lg:pl-5">
                      <div className="pt-5">
                        <label
                          htmlFor="credit"
                          className={`align-middle inline-flex flex-wrap content-center justify-center w-4 h-4 lg:w-5 lg:h-5 rounded-full border border-grays cursor-pointer ${
                            pay === "card" ? "bg-main" : "bg-white"
                          }`}
                        >
                          {pay === "card" && (
                            <i className="fas fa-check invert text-[10px] lg:text-[13px]"></i>
                          )}
                        </label>
                        <input
                          type="radio"
                          name="pay"
                          className="hidden"
                          id="credit"
                          onChange={(e) => {
                            if (e.target.checked) {
                              payHandler("card");
                            }
                          }}
                        />
                        <span className="ml-3 inline-block w-20 sm:w-28 align-middle font-bold text-sm lg:text-base text-gray-500">
                          신용카드
                        </span>
                      </div>
                      <div className="pt-5">
                        <label
                          htmlFor="vbank"
                          className={`inline-flex align-middle flex-wrap content-center justify-center w-4 h-4 lg:w-5 lg:h-5 rounded-full border border-grays cursor-pointer  ${
                            pay === "vbank" ? "bg-main" : "bg-white"
                          }`}
                        >
                          {pay === "vbank" && (
                            <i className="fas fa-check invert text-[10px] lg:text-[13px]"></i>
                          )}
                        </label>
                        <input
                          type="radio"
                          id="vbank"
                          name="pay"
                          className="hidden"
                          onChange={(e) => {
                            if (e.target.checked) {
                              payHandler("vbank");
                            }
                          }}
                        />
                        <span className="ml-3 inline-block w-20 sm:w-28 align-middle font-bold text-sm lg:text-base text-gray-500">
                          가상계좌
                        </span>
                        {pay === "vbank" && (
                          <p className="py-1 text-sm text-reds pl-4">
                            * 가상계좌로 결제시 환불 이 불가능 합니다.
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  {getProductData.getProduct.product.productKit?.desc && (
                    <>
                      <div className="pt-16">
                        <p className="text-main text-sm lg:text-base font-bold pb-3">
                          이름
                        </p>
                        <input
                          {...register("receiver", { required: true })}
                          type="text"
                          className="max-w-xl w-full border border-grays text-sm lg:text-base leading-normal rounded-lg pl-2 py-2"
                          defaultValue={data?.me.name}
                        />
                      </div>
                      <div className="pt-8">
                        <p className="text-main text-sm lg:text-base font-bold pb-3">
                          휴대전화
                        </p>
                        <input
                          {...register("phone", { required: true })}
                          type="text"
                          className="max-w-xl w-full border border-grays text-sm lg:text-base leading-normal rounded-lg pl-2 py-2"
                          placeholder="- 없이 입력해주세요"
                          defaultValue={data?.me.phone}
                        />
                      </div>
                      <div className="pt-8 max-w-xl">
                        <p className="text-main text-sm lg:text-base font-bold pb-3">
                          배송지 주소
                        </p>
                        <input
                          type="text"
                          value={addr}
                          disabled
                          className="align-middle w-2/3 border border-grays text-sm lg:text-base leading-normal rounded-lg pl-2 py-2"
                        />
                        <div className="align-middle inline-block w-1/3 pl-2">
                          <button
                            type="button"
                            onClick={() => setAddressAPI(true)}
                            className="w-full border border-grays rounded-lg py-2 hover:opacity-60 lg:text-base text-sm"
                          >
                            주소 찾기
                          </button>
                        </div>
                        <input
                          {...register("subAddress", { required: true })}
                          type="text"
                          className="mt-2 block max-w-xl w-full border border-grays text-sm lg:text-base leading-normal rounded-lg pl-2 py-2 "
                          defaultValue={data?.me.subAddress}
                        />
                        <Modal
                          open={addressAPI}
                          onClose={() => {
                            setAddressAPI(false);
                          }}
                        >
                          <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 p-3 bg-white rounded-md shadow-lg">
                            <DaumPostcode onComplete={handlePostCode} />
                          </div>
                        </Modal>
                      </div>
                      <div className="pt-8">
                        <p className="text-main text-sm lg:text-base font-bold pb-3">
                          배송 요청사항
                        </p>
                        <input
                          {...register(`message`, {
                            required: `배송 요청사항을 입력해주세요`,
                          })}
                          type="text"
                          defaultValue={`부재 시 문앞에 놓아주세요`}
                          className="max-w-xl w-full border border-grays text-base leading-normal rounded-lg pl-2 py-2"
                        />
                      </div>
                    </>
                  )}
                  <div className="mt-10">
                    <div className="border border-grays max-w-xl px-4">
                      <p className="font-bold text-base py-2">최종 결제 금액</p>
                      <p className="py-1 px-2 text-sm flex-wrap flex justify-between content-center">
                        <span>상품가격</span>
                        <span>
                          {getProductData.getProduct.product.productInfo?.price}
                          원
                        </span>
                      </p>

                      {getProductData.getProduct.product.productInfo
                        ?.discountRate &&
                      getProductData.getProduct.product.productInfo
                        .discountRate > 0 ? (
                        <p className="py-1 px-2 text-sm flex-wrap flex justify-between content-center">
                          <span>상품 할인율</span>
                          <span>
                            {
                              getProductData.getProduct.product.productInfo
                                ?.discountRate
                            }
                            %
                          </span>
                        </p>
                      ) : (
                        <></>
                      )}

                      {getProductData.getProduct.product.promotionProducts &&
                        getProductData.getProduct.product.promotionProducts
                          .length > 0 &&
                        getProductData.getProduct.product.promotionProducts.map(
                          (promotionProduct) => {
                            return (
                              <p className="py-1 px-2 text-sm flex-wrap flex justify-between content-center">
                                <span>{`${promotionProduct.promotion?.badge}`}</span>
                                <span>{promotionProduct.discountRate}%</span>
                              </p>
                            );
                          }
                        )}

                      <div className="py-1 text-sm flex-wrap flex justify-between content-center">
                        <p className="w-full border border-gray-300"></p>
                      </div>
                      <p className="py-3 pr-2  text-xs flex-wrap flex justify-between content-center">
                        <span className="font-bold">총 결제 금액</span>
                        <span className="text-base font-bold">
                          {getProductData.getProduct?.product.productInfo
                            ?.price &&
                            calculate(
                              Number(
                                getProductData.getProduct.product?.productInfo
                                  ?.price
                              ),
                              getProductData.getProduct.product?.productInfo
                                ?.discountRate +
                                promotionProductsDiscountRate(
                                  getProductData.getProduct.product
                                    .promotionProducts
                                )
                            ).toLocaleString()}
                          원
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="max-w-xl pt-8 sm:pt-16 text-center">
                    <Link
                      type="button"
                      className="inline-block rounded-md w-36 py-1 border"
                      to={`/detail/${params.productId}`}
                    >
                      취소
                    </Link>
                    <button
                      onClick={paymentHandler}
                      className={`bg-main hover:opacity-60 inline-block rounded-md w-36 py-1 border border-main ml-3 text-white mb-20 ${
                        canBuyData?.canBuy.ok ? `` : `pointer-events-none`
                      }`}
                    >
                      {canBuyData?.canBuy.ok ? `결제하기` : `구매불가`}
                    </button>
                  </div>
                </div>
              </form>
            )}
        </div>
        <Footer></Footer>
      </div>
    </div>
  );
};
