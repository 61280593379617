import React, { useEffect, useState } from "react";
import { ListMagazineInput } from "../__generated__/globalTypes";
import searchImg from "../assets/icon/search.png";
import { ISearchFormProps } from "../routes/admin-list-magazine";
import { useForm } from "react-hook-form";
import { useListMagazineActive } from "../hooks/useListMagazineActive";
import { TextButton } from "./button";
import { TableError } from "./table-error";
import { styled } from "@mui/material";
import usePagination from "@mui/material/usePagination";

interface IModalListMagazine {
  onSelect: (productId: number) => void;
}

export const ModalListMagazine: React.FC<IModalListMagazine> = ({
  onSelect,
}) => {
  const [page, setPage] = useState<number>(1);
  const [search, setSearch] = useState<string>("");

  const pageHandler = (page: number) => {
    setPage(page);
    listMagazineActiveHandler(page, search);
  };

  const { register, getValues, handleSubmit } = useForm<ISearchFormProps>();

  const onSearch = async () => {
    const { search } = getValues();
    if (search) setSearch(search);
    listMagazineActiveHandler(page, search);
  };

  const [listMagazineActive, { data, loading, refetch }] =
    useListMagazineActive();
  const listMagazineActiveHandler = (page: number, search: string) => {
    const input: ListMagazineInput = {
      page,
      search,
    };
    listMagazineActive({ variables: { input } });
  };

  const { items } = usePagination({
    count: data?.listMagazineActive.totalPages
      ? data.listMagazineActive.totalPages
      : 1,
  });

  const List = styled("ul")({
    listStyle: "none",
    paddingTop: 20,
    textAlign: "center",
    display: "block",
  });

  useEffect(() => {
    listMagazineActiveHandler(page, search);
  }, []);

  return (
    <div className="modal">
      <div className="text-right pb-8">
        <form onSubmit={handleSubmit(onSearch)}>
          <p className="inline-block max-w-md w-full text-right relative shadow-md">
            <input
              {...register(`search`)}
              type="text"
              className="w-full py-1 px-4 bg-white rounded-full"
              placeholder="매거진 이름으로 검색"
            />
            <button className="absolute right-3 top-1">
              <img src={searchImg} alt="찾기" className="w-5" />
            </button>
          </p>
        </form>
      </div>
      {/* 여기 */}
      <div className="bg-white px-5 pb-5 rounded-md shadow-lg min-w-max">
        <p className="text-main font-bold pb-3">매거진 목록</p>
        <table className="w-full">
          <colgroup>
            <col className="w-2/12" />
            <col className="w-5/12" />
            <col className="w-1/12" />
            <col className="w-2/12" />
            <col className="w-2/12" />
          </colgroup>
          <thead>
            <tr className="bg-main  border-gray-300">
              <th className="text-white ">번호</th>
              <th className="text-white ">제목</th>
              <th className="text-white ">작성자</th>
              <th className="text-white ">주소</th>
              <th className="text-white ">기능</th>
            </tr>
          </thead>
          <tbody>
            {!loading &&
              data?.listMagazineActive.products &&
              data.listMagazineActive.products.map((magazine) => (
                <tr
                  key={`magazine-${magazine?.id}`}
                  className="border-b-2 border-gray-300 h-4"
                >
                  <td className="text-sm text-center py-1 px-1 h-full overflow-hidden">
                    {magazine?.uuid && magazine.uuid}
                  </td>
                  <td className="text-sm text-center h-full relative">
                    {magazine?.productInfo?.title &&
                      `${magazine.productInfo.title}`}
                  </td>
                  <td className="text-sm text-center  relative">
                    {magazine?.inst.user?.name && `${magazine.inst.user.name}`}
                  </td>
                  <td className="text-sm text-center py-1 px-1 h-full overflow-hidden">
                    {magazine?.productInfo?.place &&
                      `${magazine.productInfo.place}`}
                  </td>
                  <td className="text-sm text-center py-1 px-1 h-full w-1/6 overflow-hidden">
                    <TextButton
                      canClick={!loading}
                      actionText={`선택`}
                      onClick={() => onSelect(magazine.id)}
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        {loading && <TableError errorMessage={`정보를 불러오는 중입니다`} />}
        {!loading &&
          data?.listMagazineActive.products &&
          data.listMagazineActive.products.length < 1 && (
            <TableError errorMessage={`해당하는 강의 정보가 없습니다`} />
          )}
        {!loading &&
          data?.listMagazineActive.products &&
          data.listMagazineActive.products.length > 0 && (
            <List>
              {items.map(({ page, type, selected, ...item }, index) => {
                let children = null;

                if (type === "start-ellipsis" || type === "end-ellipsis") {
                  children = "…";
                } else if (type === "page") {
                  children = (
                    <button
                      type="button"
                      style={{
                        fontWeight: selected ? "bold" : undefined,
                      }}
                      {...item}
                      onClick={() => pageHandler(page)}
                    >
                      {page}
                    </button>
                  );
                } else if (type === "previous") {
                  children = (
                    <button type="button" {...item}>
                      <i className="fas fa-angle-left"></i>
                    </button>
                  );
                } else if (type === "next") {
                  children = (
                    <button type="button" {...item}>
                      <i className="fas fa-angle-right"></i>
                    </button>
                  );
                }
                return (
                  <li
                    key={index + type}
                    className={`text-xs px-2  border-grays inline-block ${
                      (type === "page" &&
                        page !== data.listMagazineActive.products?.length &&
                        items.length %
                          Number(data.listMagazineActive.products?.length) >
                          3 &&
                        "border-r") ||
                      (type === "end-ellipsis" && "border-r") ||
                      (type === "start-ellipsis" && "border-r")
                    }`}
                  >
                    {children}
                  </li>
                );
              })}
            </List>
          )}
      </div>
    </div>
  );
};
