import React from "react";
import gql from "graphql-tag";
import { ApolloError, useMutation } from "@apollo/client";
import { resignInst, resignInstVariables } from "../__generated__/resignInst";

const RESIGN_INST_MUTATION = gql`
  mutation resignInst($input: ResignInstInput!) {
    resignInst(input: $input) {
      ok
      err
    }
  }
`;

export const useResignInst = (
  onCompleted?: (data: resignInst) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<resignInst, resignInstVariables>(RESIGN_INST_MUTATION, {
    onCompleted,
    onError,
  });
};
