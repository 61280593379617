import React from "react";
import { gql, useMutation } from "@apollo/client";
import {
  delProductReview,
  delProductReviewVariables,
} from "../__generated__/delProductReview";

const DEL_PRODUCT_REVIEW_MUTATION = gql`
  mutation delProductReview($input: DelProductReviewInput!) {
    delProductReview(input: $input) {
      ok
      err
    }
  }
`;

export const useDelProductReview = (
  onCompleted?: (data: delProductReview) => void
) => {
  return useMutation<delProductReview, delProductReviewVariables>(
    DEL_PRODUCT_REVIEW_MUTATION,
    {
      onCompleted,
      fetchPolicy: `network-only`,
    }
  );
};
