import React from "react";
import { gql, useMutation } from "@apollo/client";
import {
  crProductReview,
  crProductReviewVariables,
} from "../__generated__/crProductReview";
import { CrProductReviewInput } from "../__generated__/globalTypes";

export interface ICrProductReviewFormProps extends CrProductReviewInput {}

const CR_PRODUCT_REVIEW_MUTATION = gql`
  mutation crProductReview($input: CrProductReviewInput!) {
    crProductReview(input: $input) {
      ok
      err
    }
  }
`;

export const useCrProductReview = (
  onCompleted?: (data: crProductReview) => void
) => {
  return useMutation<crProductReview, crProductReviewVariables>(
    CR_PRODUCT_REVIEW_MUTATION,
    {
      onCompleted,
      fetchPolicy: `network-only`,
    }
  );
};
