import { pageIndicator } from "../../../apollo";
import { useEffect, useState } from "react";
import { Footer } from "../../../layout/footer";
import { AdminTop } from "../../../layout/adminTop";
import { AsideAdminComponent } from "../../../layout/aside-admin";
import { useListConsultMine } from "../../../hooks/useListConsultMine";
import {
  ConsultStatus,
  ListConsultMineInput,
  UpConsultInput,
} from "../../../__generated__/globalTypes";
import { useMe } from "../../../hooks/useMe";
import { TextButton } from "../../../components/button";
import { Link } from "react-router-dom";
import { useUpConsult } from "../../../hooks/useUpConsult";
import { upConsult } from "../../../__generated__/upConsult";
import moment from "moment";

export const AdminListConsult = () => {
  useEffect(() => {
    pageIndicator("curator");
  }, []);
  const [page, setPage] = useState<number>(1);
  const [search, setSearch] = useState<string>("");

  const { data: me } = useMe();

  const [listConsultMine, { data, loading, refetch }] = useListConsultMine();
  const listConsultMineHandler = (page: number, search: string) => {
    const input: ListConsultMineInput = {
      page,
      search,
    };
    listConsultMine({ variables: { input } });
  };

  const onUpComplete = (data: upConsult) => {
    const {
      upConsult: { ok, err },
    } = data;
    if (ok) {
      alert("정보를 수정하였습니다");
      refetch();
    }
    if (!ok) {
      alert(err);
    }
  };
  const [upConsult, { loading: upConsultLoading }] = useUpConsult(onUpComplete);
  const upConsultHandler = (consultIdToUp: number, status: ConsultStatus) => {
    if (!upConsultLoading) {
      const input: UpConsultInput = {
        consultIdToUp,
        status,
      };
      upConsult({ variables: { input } });
    }
  };

  useEffect(() => {
    listConsultMineHandler(page, search);
  }, []);

  return (
    <div className="bg-main bg-opacity-50 w-full h-full min-h-screen">
      <div className="w-[1440px]  mx-auto h-full min-h-screen  bg-white">
        <AdminTop title={"top"} children={"큐레이터"} />
        <main id="main">
          <div className="grid grid-cols-[300px_1fr]">
            <AsideAdminComponent />
            <div className="mypages ">
              <div className="lectureWrap">
                <div className="h-20"></div>
                <div className=" bg-gray-100 h-fix-content relative grid grid-rows-1">
                  {!loading && data?.listConsultMine.ok && (
                    <div className="w-full h-full px-10 py-12 space-y-6">
                      <div className="w-full p-7 bg-white rounded-lg shadow-lg">
                        <div className="w-full grid grid-cols-3 gap-x-5">
                          <div>
                            <p className=" py-2  text-center ">
                              <span>미확인 컨설팅 요청건</span>
                              <span className="ml-3 align-middle text-2xl">
                                {`${data.listConsultMine.unverifiedConsult}`}
                              </span>
                            </p>
                          </div>
                          <div>
                            <p className=" py-2  text-center ">
                              <span>완료된 컨설팅</span>
                              <span className="ml-3 align-middle text-2xl">
                                {`${data.listConsultMine.completedConsult}`}
                              </span>
                            </p>
                          </div>
                          <div>
                            <p className=" py-2  text-center ">
                              <span>배정된 작가</span>
                              <span className="ml-3 align-middle text-2xl">
                                {`${me?.me.charged?.length}`}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="p-7 bg-white rounded-lg shadow-lg">
                        <p className="text-main pb-2">진행중인 컨설팅</p>
                        <table className="w-full text-center ">
                          <colgroup>
                            <col className="w-2/12" />
                            <col className="w-2/12" />
                            <col className="w-3/12" />
                            <col className="w-1/12" />
                            <col className="w-1/12" />
                            <col className="w-1/12" />
                            <col className="w-2/12" />
                          </colgroup>
                          <thead>
                            <tr className="text-white bg-main font-bold py-1 ">
                              <th>No.</th>
                              <th>작가</th>
                              <th>제목</th>
                              <th>타입</th>
                              <th>상태</th>
                              <th>작성일</th>
                              <th>기능</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.listConsultMine.consults?.map((consult) => (
                              <tr
                                key={`consult-${consult.id}`}
                                className="text-sm text-center py-1 border-b border-grays "
                              >
                                <td>{consult.uuid}</td>
                                <td>{consult.author?.inst?.atelier?.name}</td>
                                <td>
                                  <Link
                                    to={`/admin/consult/update/${consult.id}`}
                                    className={`cursor-pointer hover:underline`}
                                  >
                                    {consult.title}
                                  </Link>
                                </td>
                                <td>{consult.type}</td>
                                <td>{consult.status}</td>
                                <td>
                                  {moment(consult.createdAt).format(
                                    "YYYY-MM-DD"
                                  )}
                                </td>
                                <td>
                                  <TextButton
                                    actionText="종료"
                                    canClick={
                                      consult.status !==
                                        ConsultStatus.Completed &&
                                      consult.status !== ConsultStatus.Canceled
                                    }
                                    onClick={() => {
                                      upConsultHandler(
                                        consult.id,
                                        ConsultStatus.Completed
                                      );
                                    }}
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
                  <Footer></Footer>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
