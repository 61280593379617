import React, { useEffect, useState } from "react";
import {
  CanWriteProductReviewInput,
  DelProductReviewInput,
  UpProductReviewInput,
} from "../__generated__/globalTypes";
import { useForm } from "react-hook-form";
import { TextareaAutosize } from "@mui/material";
import {
  IUpProductReviewFormProps,
  useUpProductReview,
} from "../hooks/useUpProductReview";
import who from "../assets/icon/who.png";
import { useMe } from "../hooks/useMe";
import { useCanWriteProductReview } from "../hooks/useCanWriteProductReview";
import { useParams } from "react-router";
import { upProductReview } from "../__generated__/upProductReview";
import { listProductReview_listProductReview_productReviews } from "../__generated__/listProductReview";
import moment from "moment";
import { ReplyButton } from "./button";
import { delProductReview } from "../__generated__/delProductReview";
import { useDelProductReview } from "../hooks/useDelProductReview";

interface IComponentUpProductReviewProps {
  productReview: listProductReview_listProductReview_productReviews;
  productId: number;
  listRefetch: () => void;
}

export const ComponentUpProductReview: React.FC<
  IComponentUpProductReviewProps
> = ({ productId, listRefetch, productReview }) => {
  const [edit, setEdit] = useState<boolean>(false);
  const setEditHandler = () => {
    setEdit(!edit);
  };

  const {
    register,
    getValues,
    setValue,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<IUpProductReviewFormProps>({ mode: "all" });

  const { data: me } = useMe();

  const onUpComplete = async (data: upProductReview) => {
    const {
      upProductReview: { ok, err },
    } = data;
    if (ok) {
      listRefetch();
      setEditHandler();
    }
    if (!ok) {
      alert(err);
    }
  };
  const [upProductReview, { loading: upProductReviewLoading }] =
    useUpProductReview(onUpComplete);
  const onSubmit = () => {
    if (!upProductReviewLoading) {
      const { text } = getValues();
      const input: UpProductReviewInput = {
        productReviewIdToUp: productReview.id,
        is_private: false,
        text,
      };
      upProductReview({ variables: { input } });
    }
  };

  const onDelComplete = async (data: delProductReview) => {
    const {
      delProductReview: { ok, err },
    } = data;
    if (ok) {
      alert("삭제하였습니다");
      listRefetch();
    }
    if (!ok) {
      alert(err);
    }
  };
  const [delProductReview, { loading: delProductReviewLoading }] =
    useDelProductReview(onDelComplete);
  const delProductReviewHandler = (productReviewIdToDel: number) => {
    if (!delProductReviewLoading) {
      const input: DelProductReviewInput = {
        productReviewIdToDel,
      };
      delProductReview({ variables: { input } });
    }
  };

  useEffect(() => {
    setValue("text", productReview.text);
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="w-full flex pb-4">
        <img
          src={who}
          alt={`${productReview.author.name}` + `사진`}
          className="w-9 h-9 inline-block"
        />
        <div className="flex flex-wrap content-center pl-3 flex-1">
          <p>
            <span className="align-top text-sm sm:text-base">
              {`${productReview.author.name}`}
            </span>
            <span className="text-text text-[11px] ml-2">
              {`${moment(productReview.createdAt).format("YYYY-MM-dd")}`}
            </span>
          </p>
        </div>
        {me?.me.id === productReview.author.id && (
          <p className="inline-block float-right">
            {!edit && (
              <>
                <span
                  className={
                    !delProductReviewLoading
                      ? `cursor-pointer`
                      : `pointer-events-none`
                  }
                  onClick={() => delProductReviewHandler(productReview.id)}
                >
                  삭제
                </span>
                <span>{` / `}</span>
                <span className="cursor-pointer" onClick={setEditHandler}>
                  수정
                </span>
              </>
            )}
            {edit && (
              <>
                <span className="cursor-pointer" onClick={setEditHandler}>
                  취소
                </span>
              </>
            )}
          </p>
        )}
      </div>
      <div className="grid grid-cols-7">
        <TextareaAutosize
          {...register(`text`, {
            required: "필수 정보입니다",
          })}
          minRows="3"
          disabled={!edit}
          placeholder="답변을 입력해주세요"
          className={`w-full py-1 px-7 ellipsis4 resize-none outline-none disabled:bg-white ${
            edit ? `col-span-6` : `col-span-7`
          } h-auto min-h-fit`}
        />
        {edit && (
          <ReplyButton
            canClick={isValid}
            color={`bg-main text-white`}
            loading={upProductReviewLoading}
            actionText={`저장`}
          />
        )}
      </div>
    </form>
  );
};
