import { ApolloError, gql, useMutation } from "@apollo/client";
import React from "react";
import { UpConsultReplyInput } from "../__generated__/globalTypes";
import {
  upConsultReply,
  upConsultReplyVariables,
} from "../__generated__/upConsultReply";

export interface IUpConsultReplyFormProps extends UpConsultReplyInput {}

const UP_CONSULT_REPLY_MUTATION = gql`
  mutation upConsultReply($input: UpConsultReplyInput!) {
    upConsultReply(input: $input) {
      ok
      err
    }
  }
`;

export const useUpConsultReply = (
  onCompleted?: (data: upConsultReply) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<upConsultReply, upConsultReplyVariables>(
    UP_CONSULT_REPLY_MUTATION,
    {
      onCompleted,
      onError,
    }
  );
};
