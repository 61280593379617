import React from "react";
import { gql, useLazyQuery, useQuery } from "@apollo/client";
import { getProductKit } from "../__generated__/getProductKit";

const GET_PRODUCT_KIT_QUERY = gql`
  query getProductKit($input: GetProductKitInput!) {
    getProductKit(input: $input) {
      ok
      err
      productKit {
        id
        desc
        view {
          id
          uuid
          url
        }
      }
    }
  }
`;

export const useGetProductKit = (
  onCompleted?: (data: getProductKit) => void
) => {
  return useLazyQuery<getProductKit>(GET_PRODUCT_KIT_QUERY, {
    onCompleted,
    fetchPolicy: "network-only",
  });
};
