import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import usePagination from "@mui/material/usePagination";
import { createBrowserHistory } from "history";
import { useLocation, useParams } from "react-router";
import { pageIndicator } from "../apollo";
import { ICrMagazineFormProps, useCrMagazine } from "../hooks/useCrMagazine";
import { crMagazine } from "../__generated__/crMagazine";
import { useForm } from "react-hook-form";
import {
  CrMagazineInput,
  CrProductViewInput,
  DelProductViewInput,
  ListProductViewInput,
  UpProductViewInput,
} from "../__generated__/globalTypes";
import { FormError2 } from "../components/form-error";
import { CategoryOption, CategoryOptionType } from "../constants/enum";
import { Button } from "../components/button";
import { useListProductView } from "../hooks/useListProductView";
import { listProductView } from "../__generated__/listProductView";
import { upProductView } from "../__generated__/upProductView";
import { useUpProductView } from "../hooks/useUpProductView.tsx";
import { useCrProductView } from "../hooks/useCrProductView";
import { crProductView } from "../__generated__/crProductView";
import { upload } from "../functions/uploads";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faX } from "@fortawesome/free-solid-svg-icons";
import { delProductView } from "../__generated__/delProductView";
import { useDelProductView } from "../hooks/useDelProductView";

export const SubAdminUpPlacePoster: React.FC = () => {
  const history = createBrowserHistory();
  const params = useParams();
  const [uploading, setUploading] = useState<boolean>(false);
  const [viewId, setViewId] = useState<number>(0);

  const onListCompleted = (data: listProductView) => {};
  const [listProductView, { data: listProductViewData, loading, refetch }] =
    useListProductView(onListCompleted);

  const onUpCompleted = async (data: upProductView) => {
    const {
      upProductView: { ok },
    } = data;
    if (ok) {
      await refetch();
    }
    setUploading(false);
  };
  const [upProductView, { loading: upProductViewLoading }] =
    useUpProductView(onUpCompleted);
  const upProductViewHandler = (
    productViewIdToUp: number,
    viewUuid: string
  ) => {
    const upProductViewInput: UpProductViewInput = {
      productViewIdToUp,
      viewUuid,
    };
    upProductView({ variables: { input: upProductViewInput } });
  };
  const uploadProductViewHandler = async (
    event: any,
    productViewIdToUp: number
  ) => {
    if (uploading) {
      alert("파일을 업로드 중입니다");
      return;
    }
    if (!upProductViewLoading) {
      setUploading(true);
      const result = await upload(event);

      if (!productViewIdToUp) {
        alert("포스터 정보가 잘못되었습니다");
        return;
      }
      if (result.data.ok) {
        upProductViewHandler(productViewIdToUp, result.data.file.uuid);
        alert("저장되었습니다.");
      } else {
        alert(
          `잘못된 파일이거나 사용할 수 없는 파일입니다. 문제가 반복해서 발생할 시 업체에 문의해주세요`
        );
      }
    }
  };

  const onCrCompleted = async (data: crProductView) => {
    const {
      crProductView: { ok, err },
    } = data;
    if (ok) {
      await refetch();
    } else {
      alert(err);
    }
  };
  const [crProductView, { loading: crProductViewLoading }] =
    useCrProductView(onCrCompleted);
  const crProductViewHandler = () => {
    if (!crProductViewLoading) {
      const crProductViewInput: CrProductViewInput = {
        productId: params.productId ? +params.productId : 0,
      };
      crProductView({ variables: { input: crProductViewInput } });
    }
  };

  const onDelCompleted = async (data: delProductView) => {
    const {
      delProductView: { ok, err },
    } = data;
    if (ok) {
      await refetch();
    } else {
      alert(err);
    }
  };
  const [delProductView, { loading: delProductViewLoading }] =
    useDelProductView(onDelCompleted);
  const delProductViewHandler = (productViewIdToDel: number) => {
    if (!delProductViewLoading && productViewIdToDel) {
      const delProductViewInput: DelProductViewInput = {
        productViewIdToDel,
      };
      delProductView({ variables: { input: delProductViewInput } });
    }
  };

  useEffect(() => {
    if (params.productId) {
      const listProductViewInput: ListProductViewInput = {
        productId: +params.productId,
      };
      listProductView({ variables: { input: listProductViewInput } });
    }
  }, []);

  return (
    <div className="bg-white p-5 rounded-2xl shadow-lg">
      <div className="p-5 space-y-5 lg:space-y-10">
        <div className="space-y-3">
          <p className="font-bold text-main">
            썸네일 이미지{" "}
            <button className="ml-3" onClick={crProductViewHandler}>
              <FontAwesomeIcon icon={faPlus} />
            </button>
            <span className="ml-5 text-xs text-black">(736x500)</span>
          </p>
          <div className="w-full grid grid-cols-3 gap-3 pt-10">
            {loading && <div>메인 이미지를 불러오는 중입니다</div>}
            {!loading &&
              listProductViewData?.listProductView.productViews &&
              listProductViewData.listProductView.productViews.map(
                (productView) => (
                  <div
                    key={`thumbnail-${productView.id}`}
                    className="w-full pt-[78%] relative mb-10"
                  >
                    <button
                      className={`absolute top-0 right-0`}
                      onClick={() => delProductViewHandler(productView.id)}
                    >
                      <FontAwesomeIcon icon={faX} />
                    </button>
                    <label
                      htmlFor={`poster${productView.id}`}
                      className="flex flex-wrap content-center justify-center hover:opacity-60 absolute left-0 top-0 w-full h-full cursor-pointer bg-gray-300 border border-grays mt-8"
                    >
                      <i className="fas fa-plus text-5xl text-white"></i>
                      <img
                        src={productView.view?.url}
                        alt="썸네일1"
                        className={`absolute left-0 top-0 w-full h-full ${
                          !Boolean(productView.view) ? "hidden" : ""
                        } `}
                      />
                    </label>
                    <input
                      type="file"
                      className="hidden"
                      accept="image/png, image/jpeg, image/jpg"
                      id={`poster${productView.id}`}
                      onChange={(event: any) =>
                        uploadProductViewHandler(event, productView.id)
                      }
                    />
                  </div>
                )
              )}
            {!loading &&
              listProductViewData?.listProductView.productViews &&
              listProductViewData?.listProductView.productViews.length < 1 && (
                <div>이미지가 없습니다</div>
              )}
          </div>
        </div>
      </div>
    </div>
  );
};
