import { ApolloError, gql, useMutation } from "@apollo/client";
import {
  upProductLike,
  upProductLikeVariables,
} from "../__generated__/upProductLike";

const UP_PRODUCT_LIKE_MUTATION = gql`
  mutation upProductLike($input: UpProductLikeInput!) {
    upProductLike(input: $input) {
      ok
      err
    }
  }
`;

export const useUpProductLike = (
  onCompleted?: (data: upProductLike) => void,
  onError?: (data: ApolloError) => void
) => {
  return useMutation<upProductLike, upProductLikeVariables>(
    UP_PRODUCT_LIKE_MUTATION,
    {
      onCompleted,
      onError,
    }
  );
};
