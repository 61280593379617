import {
  ConsultType,
  DoorType,
  LocalCategory,
  ProductCategory,
} from "../__generated__/globalTypes";

export interface ProductCategoryOptionType {
  value: ProductCategory | string;
  string: string;
  label: string;
}

export interface LocalCategoryOptionType {
  value: LocalCategory | string;
  string: string;
  label: string;
}

export interface DoorTypeOptionType {
  value: DoorType | string;
  string: string;
  label: string;
}

export interface ConsultTypeOptionType {
  value: ConsultType | string;
  string: string;
  label: string;
}

export interface CategoryOptionType {
  value: string;
  string: string;
  label: string;
}

export interface OrderOptionType {
  value: string;
  string: string;
  label: string;
}

export const ProductCategoryOption: ProductCategoryOptionType[] = [
  { value: ``, string: ``, label: `클래스 카테고리` },
  { value: ProductCategory.Drawing, string: `Drawing`, label: `드로잉` },
  {
    value: ProductCategory.FlowerGardening,
    string: `FlowerGardening`,
    label: `플라워/가드닝`,
  },
  {
    value: ProductCategory.RattanWood,
    string: `RattanWood`,
    label: `라탄/우드`,
  },
  {
    value: ProductCategory.LeatherGlassCeramic,
    string: `LeatherGlassCeramic`,
    label: `가죽/유리/도자기`,
  },
  {
    value: ProductCategory.KnittingWeaving,
    string: `KnittingWeaving`,
    label: `뜨개질/위빙`,
  },
  {
    value: ProductCategory.MacrameWoolFelt,
    string: `MacrameWoolFelt`,
    label: `마크라메/양모펠트`,
  },
  {
    value: ProductCategory.Caligraphy,
    string: `Caligraphy`,
    label: `캘리그라피`,
  },
  {
    value: ProductCategory.SoapCandleIncense,
    string: `SoapCandleIncense`,
    label: `비누/캔들/향`,
  },
  { value: ProductCategory.Pet, string: `Pet`, label: `펫` },
];

export const LocalCategoryOption: LocalCategoryOptionType[] = [
  { value: ``, string: ``, label: `로컬 카테고리` },
  {
    value: LocalCategory.Choongcheong,
    string: `Choongcheong`,
    label: `충청도`,
  },
  { value: LocalCategory.Gangwon, string: `Gangwon`, label: `강원도` },
  { value: LocalCategory.Gyeonggi, string: `Gyeonggi`, label: `경기도` },
  { value: LocalCategory.Gyeongsang, string: `Gyeongsang`, label: `경상도` },
  { value: LocalCategory.Jeonra, string: `Jeonra`, label: `전라도` },
  { value: LocalCategory.Jeju, string: `Jeju`, label: `제주도` },
];

export const LocalCategoryKROption: LocalCategoryOptionType[] = [
  { value: ``, string: ``, label: `로컬 카테고리` },
  { value: "충청도", string: `충청도`, label: `충청도` },
  { value: "강원도", string: `강원도`, label: `강원도` },
  { value: "경기도", string: `경기도`, label: `경기도` },
  { value: "경상도", string: `경상도`, label: `경상도` },
  { value: "전라도", string: `전라도`, label: `전라도` },
  { value: "제주도", string: `제주도`, label: `제주도` },
];

export const DoorTypeOption: DoorTypeOptionType[] = [
  { value: ``, string: ``, label: `문의 선택` },
  { value: DoorType.Question, string: `Question`, label: `문의` },
  { value: DoorType.System, string: `System`, label: `시스템` },
];

export const ConsultTypeOption: ConsultTypeOptionType[] = [
  { value: ``, string: ``, label: `문의 선택` },
  { value: ConsultType.Cost, string: `Cost`, label: `비용` },
  { value: ConsultType.Design, string: `Design`, label: `디자인` },
  { value: ConsultType.Service, string: `Service`, label: `서비스` },
];

export const CategoryOption: CategoryOptionType[] = [
  { value: ``, string: ``, label: `카테고리 선택` },
  { value: `Pick`, string: `Pick`, label: `Pick` },
  { value: `Place`, string: `Place`, label: `Place` },
  { value: `People`, string: `People`, label: `People` },
  { value: `Project`, string: `Project`, label: `Project` },
];

export const OrderOption: OrderOptionType[] = [
  { value: `최신순`, string: `최신순`, label: `최신순` },
  { value: `가격높은순`, string: `가격높은순`, label: `가격높은순` },
  { value: `가격낮은순`, string: `가격낮은순`, label: `가격낮은순` },
  { value: `인기순`, string: `인기순`, label: `인기순` },
];
