import React from "react";
import gql from "graphql-tag";
import { ApolloError, useMutation } from "@apollo/client";
import {
  crProductView,
  crProductViewVariables,
} from "../__generated__/crProductView";

const CR_PRODUCT_VIEW_MUTATION = gql`
  mutation crProductView($input: CrProductViewInput!) {
    crProductView(input: $input) {
      ok
      err
    }
  }
`;

export const useCrProductView = (
  onCompleted?: (data: crProductView) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<crProductView, crProductViewVariables>(
    CR_PRODUCT_VIEW_MUTATION,
    {
      onCompleted,
      onError,
    }
  );
};
