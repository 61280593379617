import { useEffect, useRef, useState } from "react";
import logo from "../../../assets/icon/localpick_logo.svg";
import DaumPostcode from "react-daum-postcode";
import { Modal } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import {
  CheckEmailInput,
  CrUserCuratorInput,
  CrUserInput,
  UserRole,
  VerifySignupEmailInput,
} from "../../../__generated__/globalTypes";
import { useForm } from "react-hook-form";
import { crUserCurator } from "../../../__generated__/crUserCurator";
import { FormError3 } from "../../../components/form-error";
import { Button } from "../../../components/button";
import { useCheckEmail } from "../../../hooks/useCheckEmail";
import { checkEmail } from "../../../__generated__/checkEmail";
import { ApolloError } from "@apollo/client";
import {
  ICrUserCuratorFormProps,
  useCrUserCurator,
} from "../../../hooks/useCrUserCurator";
import { verifySignupEmail } from "../../../__generated__/verifySignupEmail";
import { useVerifySignupEmail } from "../../../hooks/useVerifySignupEmail";

export const JoinDataformCurator = () => {
  const params = useParams();

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: { errors, isValid },
  } = useForm<ICrUserCuratorFormProps>({
    mode: "onChange",
  });

  const onComplete = async (data: crUserCurator) => {
    const {
      crUserCurator: { ok },
    } = data;
    if (ok) {
      window.location.href = "/join/complete";
    }
  };

  const onCheckCompleted = async (checking: checkEmail) => {
    const {
      checkEmail: { ok },
    } = checking;
    if (ok) {
    }
  };
  const onCheckError = (error: ApolloError) => {
    alert(error);
  };

  const [crUserCurator, { data: crUserCuratorData, loading }] =
    useCrUserCurator(onComplete);

  const [checkEmail, { data: checkEmailResult, loading: checkingLoading }] =
    useCheckEmail(onCheckCompleted, onCheckError);

  const [addressAPI, setAddressAPI] = useState(false);
  // daum API
  const handlePostCode = (data: any) => {
    let fullAddress = data.address;
    let extraAddress = "";

    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddress += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddress +=
          extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
    }
    setValue(`address`, fullAddress);
    setAddressAPI(false);
  };

  const checkEmailHandler = () => {
    if (!checkingLoading) {
      const { email } = getValues();
      const checkEmailInput: CheckEmailInput = {
        email,
      };
      checkEmail({
        variables: {
          input: checkEmailInput,
        },
      });
    }
  };

  const onSubmit = () => {
    if (!loading && params.code) {
      const { email, name, password, phone, address, subAddress } = getValues();
      if (!Boolean(address)) {
        alert("주소를 입력해주세요");
        return;
      }
      const input: CrUserCuratorInput = {
        email,
        password,
        name,
        phone,
        address,
        subAddress,
        code: params.code,
      };
      crUserCurator({
        variables: {
          input: input,
        },
      });
    }
  };
  const onInvalid = () => {};

  const onVerifyComplete = (data: verifySignupEmail) => {
    const {
      verifySignupEmail: { ok, err },
    } = data;
    if (!ok) {
      alert(err);
      window.location.href = "/admin/login";
    }
  };
  const [verifySignupEmail, { loading: verifySignupEmailLoading }] =
    useVerifySignupEmail(onVerifyComplete);
  const verifySignupEmailHandler = (code: string) => {
    const input: VerifySignupEmailInput = { code };
    verifySignupEmail({ variables: { input } });
  };

  useEffect(() => {
    if (!verifySignupEmailLoading && params.code) {
      verifySignupEmailHandler(params.code);
    }
  }, []);

  return (
    <div className="bg-main bg-opacity-50 w-full h-full min-h-screen">
      <div className="max-w-screen-yl mx-auto w-full h-full min-h-screen bg-white flex flex-wrap justify-center content-center">
        <div className="sm:max-w-2xl w-full yl:px-0 px-4 pb-10">
          <div className="w-[200px] mx-auto py-10">
            <a href="/">
              <img src={logo} alt="로로 로고" className="w-full" />
            </a>
          </div>
          <form onSubmit={handleSubmit(onSubmit, onInvalid)}>
            <div className="w-11/12 sm:w-8/12 mx-auto block py-5 sm:py-7 relative text-center">
              <span className="sm:absolute right-full top-1/2 sm:-translate-y-1/2 block w-fit pr-3 py-1 sm:py-0 text-main font-bold text-sm sm:text-base">
                이메일
              </span>
              <input
                {...register("email", {
                  required: "이메일은 필수 입력 정보입니다.",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "이메일 형식에 맞게 입력해주세요",
                  },
                })}
                type="text"
                className="rounded-none border-b border-[##F1F2EF] focus:outline-none py-1 pl-1 leading-normal w-10/12 sm:w-full text-xs lg:text-base"
              />
              {errors.email?.message && (
                <FormError3 errorMessage={errors.email.message} />
              )}
              {checkEmailResult?.checkEmail.err && (
                <FormError3 errorMessage={checkEmailResult.checkEmail.err} />
              )}
              {checkEmailResult?.checkEmail.ok && (
                <FormError3 errorMessage={`사용할 수 있는 아이디입니다.`} />
              )}
              <button
                type="button"
                className="inline-block sm:absolute left-full w-2/12 sm:w-fit top-1/2 sm:-translate-y-1/2 sm:py-2 sm:pl-4 text-[10px] lg:text-xs text-main font-bold hover:opacity-60"
                onClick={checkEmailHandler}
              >
                중복확인
              </button>
            </div>
            <div className="w-11/12 sm:w-8/12 mx-auto block py-5 sm:py-7 relative text-center">
              <span className="sm:absolute right-full top-1/2 sm:-translate-y-1/2 block w-fit pr-3 py-1 sm:py-0 text-main font-bold text-sm sm:text-base">
                비밀번호
              </span>
              <input
                {...register("password", {
                  required: `패스워드는 필수 입력 정보입니다.`,
                  minLength: 8,
                  maxLength: 20,
                })}
                type="password"
                className="inline-block py-1 rounded-none border-b border-[##F1F2EF] focus:outline-none pl-1 leading-normal w-full text-xs lg:text-base"
              />
              {errors.password?.message && (
                <FormError3 errorMessage={errors.password.message} />
              )}
              {errors.password?.type === "maxLength" && (
                <FormError3
                  errorMessage={"비밀번호 등록가능 최대 글자수는 20자입니다."}
                />
              )}
              {errors.password?.type === "minLength" && (
                <FormError3
                  errorMessage={"비밀번호 등록가능 최소 글자수는 8자입니다."}
                />
              )}
            </div>
            <div className="w-11/12 sm:w-8/12 mx-auto block py-5 sm:py-7 relative text-center">
              <span className="sm:absolute right-full top-1/2 sm:-translate-y-1/2 block w-fit pr-3 py-1 sm:py-0 text-main font-bold text-sm sm:text-base">
                비밀번호 확인
              </span>
              <input
                {...register("confirmPassword", {
                  validate: (value) =>
                    value === watch("password") ||
                    "비밀번호가 일치하지 않습니다",
                })}
                type="password"
                className="inline-block py-1 rounded-none border-b border-[#F1F2EF] focus:outline-none pl-1 leading-normal w-full text-xs lg:text-base"
              />
              {errors.confirmPassword?.message && (
                <FormError3 errorMessage={errors.confirmPassword.message} />
              )}
            </div>
            <div className="w-11/12 sm:w-8/12 mx-auto block py-5 sm:py-7 relative text-center">
              <span className="sm:absolute right-full top-1/2 sm:-translate-y-1/2 block w-fit pr-3 py-1 sm:py-0 text-main font-bold text-sm sm:text-base">
                이름
              </span>
              <input
                {...register(`name`, {
                  required: "이름은 필수 입력 정보입니다.",
                })}
                type="text"
                className="inline-block py-1 rounded-none border-b border-[#F1F2EF] focus:outline-none pl-1 leading-normal w-full text-xs lg:text-base"
              />
              {errors.name?.message && (
                <FormError3 errorMessage={errors.name.message} />
              )}
            </div>
            <div className="w-11/12 sm:w-8/12 mx-auto block py-5 sm:py-7 relative text-center">
              <span className="sm:absolute right-full top-1/2 -translate-y-1/2 block w-fit pr-3 text-main font-bold text-sm sm:text-base">
                연락처
              </span>
              <input
                {...register("phone", {
                  pattern: {
                    value: /^[0-9]+$/,
                    message: "숫자만 입력해주세요",
                  },
                  required: "휴대전화번호는 필수 입력 정보입니다.",
                })}
                type="text"
                className="holder_main py-1 inline-block rounded-none border-b border-[##F1F2EF] focus:outline-none pl-1 leading-normal w-full text-xs lg:text-base"
                placeholder="- 없이 붙여서 써주세요"
                onKeyUp={(e) =>
                  (e.currentTarget.value = e.currentTarget.value.replace(
                    /[^0-9]/g,
                    ""
                  ))
                }
                maxLength={11}
              />
              {errors.name?.message && (
                <FormError3 errorMessage={errors.name.message} />
              )}
            </div>
            <div className="w-11/12 sm:w-8/12 mx-auto block py-5 sm:py-7 relative text-center">
              <div className="relative">
                <span className="sm:absolute right-full top-1/2 -translate-y-1/2 block w-fit pr-3 text-main font-bold text-sm sm:text-base">
                  주소
                </span>
                <div className="w-full inline-block text-left">
                  <input
                    {...register(`address`)}
                    type="text"
                    className="inline-block py-1 rounded-none border-b border-[##F1F2EF] focus:outline-none pl-1 leading-normal w-8/12 text-xs lg:text-base"
                    disabled
                  />
                  <div className="w-4/12 flex-wrap inline-flex align-middle">
                    <div className="w-1/3"></div>
                    <button
                      id="find_address"
                      className="w-2/3 py-1 border-[##F1F2EF] border text-sm hover:opacity-60"
                      type="button"
                      onClick={() => setAddressAPI(true)}
                    >
                      주소찾기
                    </button>
                  </div>
                </div>
              </div>
              <div className="pt-6 relative">
                <input
                  {...register("subAddress", { required: true })}
                  type="text"
                  className="holder_main inline-block py-1 rounded-none border-b border-[##F1F2EF] focus:outline-none pl-1 leading-normal w-full text-xs lg:text-base"
                  placeholder="상세주소입력"
                />
              </div>
            </div>
            <Modal
              open={addressAPI}
              onClose={() => {
                setAddressAPI(false);
              }}
            >
              <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 p-3 bg-white rounded-md shadow-lg">
                <DaumPostcode onComplete={handlePostCode} />
              </div>
            </Modal>
            <div className={`pt-7 text-center space-x-3.5`}>
              <Link
                to={`/signin`}
                className={`inline-block hover:opacity-60 w-32 py-1 border text-sm font-bold rounded-md`}
              >
                취소
              </Link>
              <Button
                canClick={isValid}
                actionText={`가입하기`}
                loading={loading}
                color={`bg-main text-white`}
              />
            </div>
            {crUserCuratorData?.crUserCurator.err && (
              <FormError3 errorMessage={crUserCuratorData.crUserCurator.err} />
            )}
          </form>
        </div>
      </div>
    </div>
  );
};
