import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import usePagination from "@mui/material/usePagination";
import { createBrowserHistory } from "history";
import searchImg from "../assets/icon/search.png";
import { useLocation } from "react-router";
import { useListVerifiedInst } from "../hooks/useListVerifiedInst";
import {
  ApproveInstInput,
  ListVerifiedInstInput,
  ResignInstInput,
  UserRole,
} from "../__generated__/globalTypes";
import { listVerifiedInst_listVerifiedInst_insts } from "../__generated__/listVerifiedInst";
import { useResignInst } from "../hooks/useResignInst";
import { resignInst } from "../__generated__/resignInst";
import { TableError } from "../components/table-error";
import { TextButton } from "../components/button";
import { Modal } from "@mui/material";
import { ModalListVerifiedCurator } from "../components/modal-list-verified-curator";
import { approveInst } from "../__generated__/approveInst";
import { useApproveInst } from "../hooks/useApproveInst";
import { useForm } from "react-hook-form";
import { ISearchFormProps } from "../routes/admin-list-magazine";
import { useMe } from "../hooks/useMe";
import { IApproveInstProps } from "./sub-admin-list-unverified-inst";

export const SubAdminListVerifiedInst: React.FC = () => {
  const [instId, setInstId] = useState<number>(0);
  const [modal, setModal] = useState<boolean>(false);
  const history = createBrowserHistory();
  const location = useLocation();
  const { data: me } = useMe();
  const pageHandler = (page: number) => {
    const search =
      location.search.split("search=").length > 1
        ? location.search.split("search=")[1].split("&")[0]
        : null;
    history.push({
      pathname: "/admin/insts",
      search: `?page=${page}${search ? `&search=${search}` : ""}`,
    });
    listVerifiedInstHandler(page, search ? search : undefined);
  };

  const onSearch = async () => {
    const { search } = getValues();
    history.push({
      pathname: "/admin/inst",
      search: `${search ? `?search=${search}` : ""}`,
    });
    listVerifiedInstHandler(1, search);
  };

  const { register, getValues, handleSubmit } = useForm<ISearchFormProps>({
    mode: "all",
  });

  const [listVerifiedInst, { data: listVerifiedInstData, loading, refetch }] =
    useListVerifiedInst();

  const listVerifiedInstHandler = (page: number, search?: string) => {
    const listVerifiedInstInput: ListVerifiedInstInput = {
      ...(page && { page: +page }),
      ...(search && { search }),
    };
    listVerifiedInst({ variables: { input: listVerifiedInstInput } });
  };

  const onResignCompleted = (data: resignInst) => {
    const {
      resignInst: { ok },
    } = data;
    if (ok) {
      refetch();
    } else {
      alert(data.resignInst.err);
    }
  };
  const [resignInst, { loading: resignInstLoading }] =
    useResignInst(onResignCompleted);
  const resignInstHandler = (instIdToResign: number) => {
    if (!resignInstLoading) {
      const resignInstInput: ResignInstInput = {
        instIdToResign,
      };
      resignInst({
        variables: {
          input: resignInstInput,
        },
      });
    }
  };

  const { items } = usePagination({
    count: listVerifiedInstData?.listVerifiedInst.totalPages
      ? listVerifiedInstData.listVerifiedInst.totalPages
      : 1,
  });

  const onApproveCompleted = (data: approveInst) => {
    const {
      approveInst: { ok },
    } = data;
    if (ok) {
      refetch();
      setModalHandler();
    } else {
      alert(data.approveInst.err);
    }
  };
  const [approveInst, { loading: approveInstLoading }] =
    useApproveInst(onApproveCompleted);
  const approveInstHandler = ({ curatorId, instId }: IApproveInstProps) => {
    if (!instId) {
      alert(`잘못된 접근입니다`);
      return;
    }
    if (!approveInstLoading) {
      const approveInstInput: ApproveInstInput = {
        instIdToApprove: instId,
        curatorId,
      };
      approveInst({
        variables: {
          input: approveInstInput,
        },
      });
    }
  };

  const openModalHandler = (instId: number) => {
    setInstId(instId);
    setModal(true);
    return;
  };

  const setModalHandler = () => {
    if (modal) {
      setModal(false);
      return;
    }
    if (!modal) {
      setModal(true);
      return;
    }
  };

  const openBusinessFileHandler = (url?: string) => {
    if (!url) return;
    window.open(url, "businessFileName", "width=900,height=900");
  };

  useEffect(() => {
    listVerifiedInstHandler(1);
  }, []);

  const List = styled("ul")({
    listStyle: "none",
    paddingTop: 20,
    textAlign: "center",
    display: "block",
  });

  return (
    <>
      <div className={`w-full h-full px-10 py-12`}>
        <div className="text-right pb-8">
          <form onSubmit={handleSubmit(onSearch)}>
            <p className="inline-block max-w-md w-full text-right relative">
              <input
                {...register(`search`)}
                type="text"
                className="w-full py-1 px-4 bg-white rounded-full"
                placeholder="작가명으로 검색"
              />
              <button className="absolute right-3 top-1">
                <img src={searchImg} alt="찾기" className="w-5" />
              </button>
            </p>
          </form>
        </div>
        {/* 여기 */}
        <div className="bg-white p-5 rounded-2xl shadow-lg">
          <p className="text-main font-bold pb-3">작가 리스트</p>
          <table className="w-full">
            <colgroup>
              <col className="w-1/12" />
              <col
                className={me?.me.role === UserRole.Admin ? `w-2/12` : `w-3/12`}
              />
              <col className="w-2/12" />
              {me?.me.role === UserRole.Admin && <col className="w-1/12" />}
              <col className="w-2/12" />
              <col className="w-2/12" />
              <col className="w-2/12" />
            </colgroup>
            <thead>
              <tr className="bg-main  border-gray-300">
                <th className="text-white ">번호</th>
                <th className="text-white ">작가</th>
                <th className="text-white ">연락처</th>
                {me?.me.role === UserRole.Admin && (
                  <th className="text-white ">큐레이터</th>
                )}
                <th className="text-white ">사업자등록번호</th>
                <th className="text-white "></th>
                <th className="text-white ">기능</th>
              </tr>
            </thead>
            <tbody>
              {!loading &&
                listVerifiedInstData?.listVerifiedInst.insts &&
                listVerifiedInstData.listVerifiedInst.insts.map(
                  (inst: listVerifiedInst_listVerifiedInst_insts) => (
                    <tr
                      key={`insts-${inst?.id}`}
                      className="border-b-2 border-gray-300 h-4"
                    >
                      <td className="text-sm text-center py-1 px-1 h-full overflow-hidden">
                        {inst?.id && inst.id}
                      </td>
                      <td className="text-sm text-center h-full relative">
                        {inst?.user?.name && inst.user.name}
                      </td>
                      <td className="text-sm text-center  relative">
                        {inst?.user?.phone && inst.user.phone}
                      </td>
                      {/* <td className="text-sm text-center py-1 px-1 h-full overflow-hidden ">
                        {inst?.atelier?.name && inst.atelier.name}
                      </td> */}

                      {me?.me.role === UserRole.Admin && (
                        <td className="text-sm text-center py-1 px-1 h-full overflow-hidden ">
                          {inst?.curator?.name && inst.curator.name}
                        </td>
                      )}
                      <td className="text-sm text-center py-1 px-1 h-full overflow-hidden ">
                        {inst.businessNumber && inst.businessNumber}
                        {` / `}
                        {inst.businessFile ? (
                          <TextButton
                            canClick={Boolean(inst.businessFile)}
                            actionText={`확인`}
                            onClick={() =>
                              openBusinessFileHandler(inst.businessFile?.url)
                            }
                          />
                        ) : (
                          <TextButton canClick={false} actionText={`미등록`} />
                        )}
                      </td>
                      <td className="text-sm text-center py-1 px-1 h-full overflow-hidden "></td>
                      <td className="text-sm text-center py-1 px-1 h-full overflow-hidden">
                        {/* <button className="hover:underline">승인</button> /{" "} */}

                        {me?.me.role === UserRole.Admin && (
                          <>
                            <TextButton
                              canClick={!approveInstLoading}
                              actionText={`큐레이터 변경`}
                              onClick={() => openModalHandler(inst.id)}
                            />
                            {` / `}
                          </>
                        )}
                        <TextButton
                          canClick={!loading}
                          actionText={`중지`}
                          onClick={() => resignInstHandler(inst.id)}
                        />
                        {/* <button className="hover:underline">탈퇴</button> */}
                      </td>
                    </tr>
                  )
                )}
            </tbody>
          </table>
          {loading && <TableError errorMessage={`정보를 불러오는 중입니다`} />}
          {!loading &&
            listVerifiedInstData?.listVerifiedInst.insts &&
            listVerifiedInstData.listVerifiedInst.insts.length < 1 && (
              <TableError errorMessage={`해당하는 작가 정보가 없습니다`} />
            )}
          <List>
            {items.map(({ page, type, selected, ...item }, index) => {
              let children = null;

              if (type === "start-ellipsis" || type === "end-ellipsis") {
                children = "…";
              } else if (type === "page") {
                children = (
                  <button
                    type="button"
                    style={{
                      fontWeight: selected ? "bold" : undefined,
                    }}
                    {...item}
                    onClick={() => pageHandler(page)}
                  >
                    {page}
                  </button>
                );
              } else if (type === "previous") {
                children = (
                  <button type="button" {...item}>
                    <i className="fas fa-angle-left"></i>
                  </button>
                );
              } else if (type === "next") {
                children = (
                  <button type="button" {...item}>
                    <i className="fas fa-angle-right"></i>
                  </button>
                );
              }
              return (
                <li
                  key={index + type}
                  className={`text-xs px-2  border-grays inline-block ${
                    (type === "page" &&
                      page !== 10 &&
                      items.length % 10 > 3 &&
                      "border-r") ||
                    (type === "end-ellipsis" && "border-r") ||
                    (type === "start-ellipsis" && "border-r")
                  }`}
                >
                  {children}
                </li>
              );
            })}
          </List>
        </div>
      </div>
      {modal && (
        <Modal open={modal} onClose={setModalHandler}>
          <div className={`modal`}>
            <ModalListVerifiedCurator
              instId={instId}
              onSelect={approveInstHandler}
            />
          </div>
        </Modal>
      )}
    </>
  );
};
