import React, { useEffect } from "react";
import logo from "../assets/icon/localpick_logo.svg";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import {
  IChangePasswordForm,
  useChangePassword,
} from "../hooks/useChangePassword";
import { useForm } from "react-hook-form";
import { changePassword } from "../__generated__/changePassword";
import { useParams } from "react-router";
import { ChangePasswordInput } from "../__generated__/globalTypes";
import { FormError2 } from "../components/form-error";

export const SubChangePassword = () => {
  const params = useParams();

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    setFocus,
    watch,
    formState: { errors },
  } = useForm<IChangePasswordForm>({ mode: `all` });

  const onCompleted = (data: changePassword) => {
    const {
      changePassword: { ok, err },
    } = data;
    if (ok) {
      alert(`비밀번호가 변경되었습니다`);
      window.location.href = `/signin`;
    } else {
      alert(err);
    }
  };

  const [changePassword, { loading, error }] = useChangePassword(onCompleted);

  const onSubmit = () => {
    if (!loading) {
      const { password, code } = getValues();

      const changePasswordInput: ChangePasswordInput = {
        password,
        code,
      };

      changePassword({
        variables: {
          input: changePasswordInput,
        },
      });
    }
  };

  useEffect(() => {
    setValue("code", params.code ? params.code : ``);
    setFocus(`password`);
  }, []);

  return (
    <div className="bg-main bg-opacity-50 w-full h-full min-h-screen">
      <Helmet title="Local Pick - 비밀번호 변경" />
      <div className="max-w-screen-yl mx-auto w-full h-full min-h-screen bg-white overflow-hidden relative">
        <div className="absolute w-full text-center top-1/2 -translate-y-1/2">
          <div className="logBox w-full text-center">
            <Link to="/" className="inline-block w-[200px] mx-auto">
              <img src={logo} alt="로로 로고" className="w-full" />
            </Link>
            <p className="text-center">비밀번호를 변경해주세요</p>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="py-10 px-4 mx-auto w-full max-w-md"
            >
              <label className="flex py-5 ">
                <span className="inline-block w-full max-w-[100px] text-main uppercase font-bold">
                  새 비밀번호
                </span>
                <input
                  {...register("password", {
                    required: `패스워드를 입력해주세요`,
                    minLength: {
                      value: 8,
                      message: `비밀번호는 8 ~ 20자리 이어야합니다`,
                    },
                    maxLength: {
                      value: 20,
                      message: `비밀번호는 8 ~ 20자리 이어야합니다`,
                    },
                  })}
                  type="password"
                  className="inline-block border-b border-main focus:outline-none pl-1 leading-normal flex-1"
                />
              </label>
              {errors.password?.message && (
                <FormError2 errorMessage={errors.password.message} />
              )}
              <label className="flex pt-12 pb-5">
                <span className="inline-block w-full max-w-[100px] text-main uppercase font-bold">
                  비밀번호 확인{" "}
                </span>
                <input
                  {...register(`confirm_password`, {
                    validate: (value) =>
                      value === watch(`password`) ||
                      `패스워드가 일치하지 않습니다`,
                  })}
                  type="password"
                  className="inline-block border-b border-main focus:outline-none pl-1 leading-normal flex-1"
                />
              </label>
              {errors.confirm_password?.message && (
                <FormError2 errorMessage={errors.confirm_password.message} />
              )}
              <div className="pt-12">
                <Link
                  to="/signin"
                  className="inline-block w-2/5 py-1 px-2 border-grays border rounded mr-3"
                >
                  취소
                </Link>
                <button className="w-2/5 py-1 px-2 text-bold bg-main border border-main rounded text-white">
                  변경완료
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
