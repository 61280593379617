import { ApolloError, gql, useMutation } from "@apollo/client";
import React from "react";
import { crMagazine, crMagazineVariables } from "../__generated__/crMagazine";
import { CrMagazineInput } from "../__generated__/globalTypes";

export interface ICrMagazineFormProps extends CrMagazineInput {}

const CR_MAGAZINE_MUTATION = gql`
  mutation crMagazine($input: CrMagazineInput!) {
    crMagazine(input: $input) {
      ok
      err
      productId
    }
  }
`;

export const useCrMagazine = (
  onCompleted?: (data: crMagazine) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<crMagazine, crMagazineVariables>(CR_MAGAZINE_MUTATION, {
    onCompleted,
    onError,
  });
};
