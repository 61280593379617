import { ApolloError, gql, useMutation } from "@apollo/client";
import React from "react";
import {
  changePassword,
  changePasswordVariables,
} from "../__generated__/changePassword";
import { ChangePasswordInput } from "../__generated__/globalTypes";

export interface IChangePasswordForm extends ChangePasswordInput {
  confirm_password: string;
}

const CHANGE_PASSWORD = gql`
  mutation changePassword($input: ChangePasswordInput!) {
    changePassword(input: $input) {
      ok
      err
    }
  }
`;

export const useChangePassword = (
  onCompleted?: (data: changePassword) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<changePassword, changePasswordVariables>(CHANGE_PASSWORD, {
    onCompleted,
    onError,
  });
};
