import React from "react";
import { gql, useLazyQuery } from "@apollo/client";
import { listBanner, listBannerVariables } from "../__generated__/listBanner";

const LIST_BANNER_QUERY = gql`
  query listBanner($input: ListBannerInput!) {
    listBanner(input: $input) {
      ok
      err
      totalPages
      totalResults
      row
      banners {
        id
        title
        is_active
        author {
          name
        }
        updatedAt
        createdAt
      }
    }
  }
`;

export const useListBanner = (onCompleted?: (data: listBanner) => void) => {
  return useLazyQuery<listBanner, listBannerVariables>(LIST_BANNER_QUERY, {
    onCompleted,
    fetchPolicy: `network-only`,
  });
};
