import React from "react";
import { gql, useLazyQuery } from "@apollo/client";
import {
  listClassActive,
  listClassActiveVariables,
} from "../__generated__/listClassActive";
import { listClass, listClassVariables } from "../__generated__/listClass";

const LIST_CLASS_QUERY = gql`
  query listClass($input: ListClassInput!) {
    listClass(input: $input) {
      ok
      err
      totalPages
      totalResults
      row
      products {
        id
        productInfo {
          scheduledAt
          title
          price
        }
        inst {
          id
          atelier {
            name
          }
          user {
            name
          }
        }
        createdAt
        productStatus
        productType
        promotionProducts {
          promotion {
            id
            title
            badge
          }
        }
      }
    }
  }
`;

export const useListClass = (onCompleted?: (data: listClass) => void) => {
  return useLazyQuery<listClass, listClassVariables>(LIST_CLASS_QUERY, {
    onCompleted,
    fetchPolicy: `network-only`,
  });
};
