import { ApolloError, gql, useMutation } from "@apollo/client";
import React from "react";
import {
  crPromotion,
  crPromotionVariables,
} from "../__generated__/crPromotion";
import { CrPromotionInput } from "../__generated__/globalTypes";

export interface ICrPromotionFormProps extends CrPromotionInput {}

const CR_PROMOTION_MUTATION = gql`
  mutation crPromotion($input: CrPromotionInput!) {
    crPromotion(input: $input) {
      ok
      err
    }
  }
`;

export const useCrPromotion = (
  onCompleted?: (data: crPromotion) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<crPromotion, crPromotionVariables>(CR_PROMOTION_MUTATION, {
    onCompleted,
    onError,
  });
};
