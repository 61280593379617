import React from "react";
import gql from "graphql-tag";
import { ApolloError, useMutation } from "@apollo/client";
import {
  upProductPage,
  upProductPageVariables,
} from "../__generated__/upProductPage";
import { UpProductPageInput } from "../__generated__/globalTypes";

export interface IUpProductPageFormProps extends UpProductPageInput {}

const UP_PRODUCT_PAGE_MUTATION = gql`
  mutation upProductPage($input: UpProductPageInput!) {
    upProductPage(input: $input) {
      ok
      err
    }
  }
`;

export const useUpProductPage = (
  onCompleted?: (data: upProductPage) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<upProductPage, upProductPageVariables>(
    UP_PRODUCT_PAGE_MUTATION,
    {
      onCompleted,
      onError,
    }
  );
};
