import React from "react";
import { useForm } from "react-hook-form";
import { uploadVideo } from "../../../../../../functions/uploads";
import { useDelProductThemeCurr } from "../../../../../../hooks/useDelProductThemeCurr";
import { useUpProductThemeCurr } from "../../../../../../hooks/useUpProductThemeCurr";
import {
  DelProductThemeCurrInput,
  UpProductThemeCurrInput,
} from "../../../../../../__generated__/globalTypes";

interface IProductThemeCurrFormProps {
  index: number;
  title: string;
  currId: number;
  filename?: string | undefined | null;
  videoUuid?: string | undefined | null;
  onUpCompleted?: (data: any) => void;
  onDelCompleted?: (data: any) => void;
}

interface IUpProductThemeCurrFormProps extends UpProductThemeCurrInput {}

export const ProductThemeCurrForm: React.FC<IProductThemeCurrFormProps> = ({
  index,
  title: originalTitle,
  currId,
  filename,
  videoUuid,
  onUpCompleted,
  onDelCompleted,
}: IProductThemeCurrFormProps) => {
  const { register, handleSubmit, getValues, setValue } =
    useForm<IUpProductThemeCurrFormProps>({ mode: "all" });

  const [upProductThemeCurr, { loading: upProductThemeCurrLoading }] =
    useUpProductThemeCurr(onUpCompleted);
  const [delProductThemeCurr, { loading: delProductThemeCurrLoading }] =
    useDelProductThemeCurr(onDelCompleted);

  const delProductThemeCurrHandler = () => {
    if (!delProductThemeCurrLoading) {
      const delProductThemeCurrInput: DelProductThemeCurrInput = {
        currIdToDel: currId,
      };
      delProductThemeCurr({ variables: { input: delProductThemeCurrInput } });
    }
  };

  const onSubmit = () => {
    if (!upProductThemeCurrLoading) {
      let { title } = getValues();
      if (originalTitle === title) return;
      const upProductThemeCurrInput: UpProductThemeCurrInput = {
        currIdToUp: currId,
        title,
      };
      upProductThemeCurr({ variables: { input: upProductThemeCurrInput } });
    }
  };

  const uploadProductThemeCurrHandler = async (
    event: any,
    currIdToUp: number
  ) => {
    const result = await uploadVideo(event);
    if (result.data.ok) {
      const upProductThemeCurrInput: UpProductThemeCurrInput = {
        currIdToUp,
        ...(videoUuid !== result.data.file.uuid && {
          videoUuid: result.data.file.uuid,
        }),
      };
      upProductThemeCurr({ variables: { input: upProductThemeCurrInput } });
    } else {
      alert(
        `잘못된 파일이거나 사용할 수 없는 파일입니다. 문제가 반복해서 발생할 시 업체에 문의해주세요`
      );
    }
  };

  return (
    <tr>
      <td className="w-[5%] text-center">
        <button onClick={delProductThemeCurrHandler}>
          <i className="fas fa-times text-xs"></i>
        </button>
      </td>
      <td className="border-b border-grays text-center text-sm">{index}강</td>
      <td className="border-b border-grays">
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-wrap">
          <input
            {...register("title", { required: true })}
            type="text"
            className="flex-1 broder border-grays bg-transparent w-full text-sm lg:text-base text-center"
            defaultValue={originalTitle}
          />
          <button className="rounded-md border border-line text-xs p-1 font-bold cursor-pointer">
            저장
          </button>
        </form>
      </td>
      <td className="border-b border-grays text-center relative">
        <p
          className={`w-full h-full absolute left-0 top-0 truncate ${`video + ${index}`}`}
        >
          <label
            className={` rounded-md text-line border border-line text-xs p-1 font-bold cursor-pointer ${
              upProductThemeCurrLoading ? "pointer-events-none" : ""
            }`}
          >
            <span>{filename ? filename : `영상등록`}</span>
            <input
              type="file"
              className="hidden"
              accept="video/*"
              id={"video" + index}
              onChange={(event) => uploadProductThemeCurrHandler(event, currId)}
            />
          </label>
        </p>
        {/* <p
          className={`w-full h-full absolute left-0 top-0 bg-white hidden ${`video + ${index}`}`}
        >
          <button
            className="hover:underline w-full h-full truncate"
            onClick={() => {
            }}
          >
            <span className="text-sm"></span>
          </button>
        </p> */}
      </td>
    </tr>
  );
};
