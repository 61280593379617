import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import usePagination from "@mui/material/usePagination";
import { createBrowserHistory } from "history";
import searchImg from "../assets/icon/search.png";
import { useLocation } from "react-router";
import {
  ApproveInstInput,
  ListUnverifiedInstInput,
  UserRole,
} from "../__generated__/globalTypes";
import { useListUnverifiedInst } from "../hooks/useListUnverifiedInst";
import { approveInst } from "../__generated__/approveInst";
import { useApproveInst } from "../hooks/useApproveInst";
import { TableError } from "../components/table-error";
import { Modal } from "@mui/material";
import { ModalListVerifiedCurator } from "../components/modal-list-verified-curator";
import { ISearchFormProps } from "../routes/admin-list-magazine";
import { useForm } from "react-hook-form";
import { useMe } from "../hooks/useMe";

export interface IApproveInstProps {
  instId: number;
  curatorId: number;
}

export const SubAdminListUnverifiedInst: React.FC = () => {
  const [modal, setModal] = useState<boolean>(false);
  const [instId, setInstId] = useState<number>(0);
  const history = createBrowserHistory();
  const location = useLocation();

  const { data: me } = useMe();

  const pageHandler = (page: number) => {
    const search =
      location.search.split("search=").length > 1
        ? location.search.split("search=")[1].split("&")[0]
        : null;
    history.push({
      pathname: "/admin/insts",
      search: `?page=${page}${search ? `&search=${search}` : ""}`,
    });
    listUnverifiedInstHandler(page, search ? search : undefined);
  };

  const { register, getValues, handleSubmit } = useForm<ISearchFormProps>({
    mode: "all",
  });

  const onSearch = async () => {
    const { search } = getValues();
    history.push({
      pathname: "/admin/inst",
      search: `${search ? `?search=${search}` : ""}`,
    });
    listUnverifiedInstHandler(1, search);
  };

  const [
    listUnverifiedInst,
    { data: listUnverifiedInstData, loading, refetch },
  ] = useListUnverifiedInst();

  const listUnverifiedInstHandler = (page: number, search?: string) => {
    const listVerifiedInstInput: ListUnverifiedInstInput = {
      ...(page && { page }),
      ...(search && { search }),
    };
    listUnverifiedInst({ variables: { input: listVerifiedInstInput } });
  };

  const onApproveCompleted = (data: approveInst) => {
    const {
      approveInst: { ok },
    } = data;
    if (ok) {
      refetch();
      if (me?.me.role === UserRole.Admin) {
        setModalHandler();
      }
    } else {
      alert(data.approveInst.err);
    }
  };
  const [approveInst, { loading: approveInstLoading }] =
    useApproveInst(onApproveCompleted);
  const approveInstHandler = ({ curatorId, instId }: IApproveInstProps) => {
    if (!instId) {
      alert(`잘못된 접근입니다`);
      return;
    }
    if (!approveInstLoading && me?.me) {
      const approveInstInput: ApproveInstInput = {
        instIdToApprove: instId,
        curatorId: me?.me.role === UserRole.Admin ? curatorId : me?.me.id,
      };
      approveInst({
        variables: {
          input: approveInstInput,
        },
      });
    }
  };

  const { items } = usePagination({
    count: listUnverifiedInstData?.listUnverifiedInst.totalPages
      ? listUnverifiedInstData.listUnverifiedInst.totalPages
      : 1,
  });

  const openModalHandler = (instId: number) => {
    if (me?.me.role === UserRole.Curator) {
      if (window.confirm("작가를 승인하시겠습니까?")) {
        approveInstHandler({ curatorId: me?.me.id, instId });
      }
    } else {
      setInstId(instId);
      setModal(true);
    }
  };

  const setModalHandler = () => {
    if (modal) {
      setModal(false);
      return;
    }
    if (!modal) {
      setModal(true);
      return;
    }
  };

  useEffect(() => {
    listUnverifiedInstHandler(1);
  }, []);

  const List = styled("ul")({
    listStyle: "none",
    paddingTop: 20,
    textAlign: "center",
    display: "block",
  });

  return (
    <>
      <div className={`w-full h-full px-10 py-12`}>
        <div className="text-right pb-8">
          <form onSubmit={handleSubmit(onSearch)}>
            <p className="inline-block max-w-md w-full text-right relative">
              <input
                {...register(`search`)}
                type="text"
                className="w-full py-1 px-4 bg-white rounded-full"
                placeholder="작가명으로 검색"
              />
              <button className="absolute right-3 top-1">
                <img src={searchImg} alt="찾기" className="w-5" />
              </button>
            </p>
          </form>
        </div>
        {/* 여기 */}
        <div className="bg-white p-5 rounded-2xl shadow-lg">
          <p className="text-main font-bold pb-3">작가 리스트</p>
          <table className="w-full">
            <thead>
              <tr className="bg-main  border-gray-300">
                <th className="text-white ">번호</th>
                <th className="text-white ">작가</th>
                <th className="text-white ">연락처</th>
                {/* <th className="text-white ">등급</th> */}
                {/* <th className="text-white ">공방</th> */}
                {/* <th className="text-white ">상태</th> */}
                <th className="text-white ">사업자등록번호</th>
                <th className="text-white ">기능</th>
              </tr>
            </thead>
            <tbody>
              {!loading &&
                listUnverifiedInstData?.listUnverifiedInst.insts &&
                listUnverifiedInstData.listUnverifiedInst.insts.map((inst) => (
                  <tr
                    key={`insts-${inst?.id}`}
                    className="border-b-2 border-gray-300 h-4"
                  >
                    <td className="text-sm text-center py-1 px-1 h-full overflow-hidden">
                      {inst?.id && inst.id}
                    </td>
                    <td className="text-sm text-center h-full relative">
                      {inst?.user?.name && inst.user.name}
                    </td>
                    <td className="text-sm text-center  relative">
                      {inst?.user?.phone && inst.user.phone}
                    </td>
                    {/* <td className="text-sm text-center py-1 px-1 h-full overflow-hidden">
                      {inst?.level && inst.level}
                    </td>
                    <td className="text-sm text-center py-1 px-1 h-full overflow-hidden ">
                      {inst?.atelier?.name && inst.atelier.name}
                    </td> */}
                    {/* <td className="text-sm text-center py-1 px-1 h-full overflow-hidden ">
                      {inst.verified && "승인"}
                      {!inst.verified && "대기"}
                    </td> */}
                    <td className="text-sm text-center py-1 px-1 h-full overflow-hidden ">
                      {inst.businessNumber && inst.businessNumber}
                    </td>
                    <td className="text-sm text-center py-1 px-1 h-full overflow-hidden">
                      <button
                        className="hover:underline"
                        onClick={() => openModalHandler(inst.id)}
                      >
                        승인
                      </button>{" "}
                      /{" "}
                      {/* <button className="hover:underline">중지</button> /{" "} */}
                      <button className="hover:underline">탈퇴</button>
                    </td>
                  </tr>
                ))}
              {!loading && !listUnverifiedInstData?.listUnverifiedInst.insts && (
                <tr>
                  <td
                    colSpan={7}
                    className="text-sm text-center py-1 px-1 h-full overflow-hidden"
                  >
                    승인 대기중인 작가가 없습니다.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {loading && <TableError errorMessage={`정보를 불러오는 중입니다`} />}
          {!loading &&
            listUnverifiedInstData?.listUnverifiedInst.insts &&
            listUnverifiedInstData.listUnverifiedInst.insts.length < 1 && (
              <TableError errorMessage={`해당하는 작가 정보가 없습니다`} />
            )}
          <List>
            {items.map(({ page, type, selected, ...item }, index) => {
              let children = null;

              if (type === "start-ellipsis" || type === "end-ellipsis") {
                children = "…";
              } else if (type === "page") {
                children = (
                  <button
                    type="button"
                    style={{
                      fontWeight: selected ? "bold" : undefined,
                    }}
                    {...item}
                    onClick={() => pageHandler(page)}
                  >
                    {page}
                  </button>
                );
              } else if (type === "previous") {
                children = (
                  <button
                    type="button"
                    {...item}
                    onClick={() => pageHandler(page - 1)}
                  >
                    <i className="fas fa-angle-left"></i>
                  </button>
                );
              } else if (type === "next") {
                children = (
                  <button type="button" {...item}>
                    <i className="fas fa-angle-right"></i>
                  </button>
                );
              }
              return (
                <li
                  key={index + type}
                  className={`text-xs px-2  border-grays inline-block ${
                    (type === "page" &&
                      page !== 10 &&
                      items.length % 10 > 3 &&
                      "border-r") ||
                    (type === "end-ellipsis" && "border-r") ||
                    (type === "start-ellipsis" && "border-r")
                  }`}
                >
                  {children}
                </li>
              );
            })}
          </List>
        </div>
      </div>
      {modal && (
        <Modal open={modal} onClose={setModalHandler}>
          <div className={`modal`}>
            <ModalListVerifiedCurator
              instId={instId}
              onSelect={approveInstHandler}
            />
          </div>
        </Modal>
      )}
    </>
  );
};
