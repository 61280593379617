import { useEffect, useState } from "react";
import usePagination from "@mui/material/usePagination";
import { styled } from "@mui/material/styles";
import { pageIndicator } from "../apollo";
import { SubTop } from "../layout/subTop";
import searchImg from "../assets/icon/search.png";
import { AsideAdminComponent } from "../layout/aside-admin";
import { Footer } from "../layout/footer";
import { Link } from "react-router-dom";
import { createBrowserHistory } from "history";
import { useLocation } from "react-router";
import {
  DelProductInput,
  ListMagazineInput,
  ProductStatus,
  UpProductStatusInput,
} from "../__generated__/globalTypes";
import { useListMagazine } from "../hooks/useListMagazine";
import { TableError } from "../components/table-error";
import { TextButton } from "../components/button";
import { useUpProductStatus } from "../hooks/useUpProductStatus";
import { upProductStatus } from "../__generated__/upProductStatus";
import { useForm } from "react-hook-form";
import { AdminTop } from "../layout/adminTop";
import { PaginationComponent } from "../components/pagination";
import { delProduct } from "../__generated__/delProduct";
import { useDelProduct } from "../hooks/useDelProduct";

export interface ISearchFormProps {
  search: string;
}

export const AdminListMagazine = () => {
  const history = createBrowserHistory();
  const location = useLocation();
  const [page, setPage] = useState<number>(1);
  const [search, setSearch] = useState<string>("");

  const word = "매거진";

  const pageHandler = (page: number) => {
    const search =
      location.search.split("search=").length > 1
        ? location.search.split("search=")[1].split("&")[0]
        : null;
    history.push({
      pathname: "/admin/magazine",
      search: `?page=${page}${search ? `&search=${search}` : ""}`,
    });
    listMagazineHandler(page, search);
  };

  const { register, getValues, handleSubmit } = useForm<ISearchFormProps>({
    mode: "all",
  });

  const onSearch = async () => {
    const { search } = getValues();
    history.push({
      pathname: "/admin/magazine",
      search: `${search ? `?search=${search}` : ""}`,
    });
    listMagazineHandler(1, search);
  };

  useEffect(() => {
    pageIndicator("magazine");
  }, []);

  const onUpCompleted = async (data: upProductStatus) => {
    const {
      upProductStatus: { ok, err },
    } = data;
    if (ok) {
      alert(`클래스 상태가 수정되었습니다`);
      await refetch();
    } else {
      alert(err);
    }
  };
  const [upProductStatus, { loading: upProductStatusLoading }] =
    useUpProductStatus(onUpCompleted);
  const upProductStatusHandler = (
    productIdToUp: number,
    productStatus: ProductStatus
  ) => {
    if (!upProductStatusLoading) {
      const upProductStatusInput: UpProductStatusInput = {
        productIdToUp,
        productStatus,
      };
      upProductStatus({ variables: { input: upProductStatusInput } });
    }
  };

  const onDelCompleted = async (data: delProduct) => {
    const {
      delProduct: { ok, err },
    } = data;
    if (ok) {
      alert(`강의를 삭제하였습니다`);
      await refetch();
    } else {
      alert(err);
    }
  };
  const [delProduct, { loading: delProductLoading }] =
    useDelProduct(onDelCompleted);
  const delProductHandler = (productIdToDel: number) => {
    if (delProductLoading) return;
    if (
      window.confirm(
        "삭제한 자료는 복구할 수 없습니다, 정말로 삭제하시겠습니까?"
      )
    ) {
      const input: DelProductInput = {
        productIdToDel,
      };
      delProduct({ variables: { input } });
    }
  };

  const [listMagazine, { data, loading, refetch }] = useListMagazine();
  const listMagazineHandler = (page: number | null, search: string | null) => {
    const listMagazineInput: ListMagazineInput = {
      ...(page && { page: +page }),
      ...(search && { search: decodeURI(search) }),
    };
    listMagazine({ variables: { input: listMagazineInput } });
  };

  useEffect(() => {
    setPage(
      location.search.split("page=").length > 1
        ? +location.search.split("page=")[1].split("&")[0]
        : 1
    );
    setSearch(
      location.search.split("search=").length > 1
        ? location.search.split("search=")[1].split("&")[0]
        : ""
    );
    listMagazineHandler(page, search);
  }, []);

  return (
    <div className="bg-main bg-opacity-50 w-full h-full min-h-screen">
      <div className="w-[1440px] mx-auto h-full min-h-screen  bg-white">
        <AdminTop title={"top"} children={"관리자모드"} />
        <main id="main">
          <div className="grid grid-cols-[300px_1fr]">
            <AsideAdminComponent />
            <div className="mypages ">
              <div className="lectureWrap">
                <div className="h-20">
                  <div className="h-full flex-wrap flex justify-between px-10 content-center">
                    <div className="subtitle h-full flex flex-wrap content-end gap-x-3">
                      <div
                        className={`inline-block w-44 text-center border-main border-b-2`}
                      >
                        <Link
                          className={`block text-base leading-normal font-bold hover:opacity-60 w-full py-1 text-main`}
                          to={`/admin/magazine`}
                        >
                          목록
                        </Link>
                      </div>
                      <div className={`inline-block w-44 text-center`}>
                        <Link
                          className={`block text-base leading-normal font-bold hover:opacity-60 w-full py-1 text-gray-400`}
                          to={`/admin/magazine/create`}
                        >
                          글쓰기
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className=" bg-gray-100 h-fix-content relative">
                  <div className={`w-full h-full px-10 py-12`}>
                    <div className="text-right pb-8">
                      <form onSubmit={handleSubmit(onSearch)}>
                        <p className="inline-block max-w-md w-full text-right relative">
                          <input
                            {...register(`search`)}
                            type="text"
                            className="w-full py-1 px-4 bg-white rounded-full"
                            placeholder="매거진 이름으로 검색"
                          />
                          <button className="absolute right-3 top-1">
                            <img src={searchImg} alt="찾기" className="w-5" />
                          </button>
                        </p>
                      </form>
                    </div>
                    {/* 여기 */}
                    <div className="bg-white p-5 rounded-2xl shadow-lg">
                      <p className="text-main font-bold pb-3">매거진 목록</p>
                      <table className="w-full">
                        <colgroup>
                          <col className="w-2/12" />
                          <col className="w-4/12" />
                          <col className="w-3/12" />
                          <col className="w-1/12" />
                          <col className="w-2/12" />
                        </colgroup>
                        <thead>
                          <tr className="bg-main  border-gray-300">
                            <th className="text-white ">번호</th>
                            <th className="text-white ">제목</th>
                            <th className="text-white ">주소</th>
                            <th className="text-white ">상태</th>
                            <th className="text-white ">기능</th>
                          </tr>
                        </thead>
                        <tbody>
                          {!loading &&
                            data?.listMagazine.products &&
                            data.listMagazine.products.map((magazine) => (
                              <tr
                                key={`magazine-${magazine?.id}`}
                                className="border-b-2 border-gray-300 h-4"
                              >
                                <td className="text-sm text-center py-1 px-1 h-full overflow-hidden">
                                  {magazine?.uuid && magazine.uuid}
                                </td>
                                <td className="text-sm text-center h-full relative">
                                  <Link
                                    to={`/admin/magazine/update/${magazine.id}`}
                                    className={`cursor-pointer`}
                                  >
                                    {magazine?.productInfo?.title &&
                                      magazine.productInfo.title}
                                  </Link>
                                </td>

                                <td className="text-sm text-center py-1 px-1 h-full overflow-hidden">
                                  {magazine?.productInfo?.place &&
                                    magazine.productInfo.place}
                                </td>
                                <td className="text-sm text-center py-1 px-1 h-full overflow-hidden ">
                                  {magazine?.productStatus &&
                                    magazine.productStatus}
                                </td>
                                <td className="text-sm text-center py-1 px-1 h-full w-1/6 overflow-hidden">
                                  <TextButton
                                    canClick={
                                      !loading &&
                                      magazine.productStatus ===
                                        ProductStatus.Ready
                                    }
                                    actionText={`활성화`}
                                    onClick={() =>
                                      upProductStatusHandler(
                                        magazine.id,
                                        ProductStatus.On
                                      )
                                    }
                                  />
                                  {` / `}
                                  <TextButton
                                    canClick={
                                      !loading &&
                                      magazine.productStatus ===
                                        ProductStatus.On
                                    }
                                    actionText={`비활성화`}
                                    onClick={() =>
                                      upProductStatusHandler(
                                        magazine.id,
                                        ProductStatus.Ready
                                      )
                                    }
                                  />
                                  {` / `}
                                  <TextButton
                                    canClick={true}
                                    actionText={`삭제`}
                                    onClick={() =>
                                      delProductHandler(magazine.id)
                                    }
                                  />
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                      {loading && (
                        <TableError errorMessage={`정보를 불러오는 중입니다`} />
                      )}
                      {!loading &&
                        data?.listMagazine.products &&
                        data.listMagazine.products.length < 1 && (
                          <TableError
                            errorMessage={`해당하는 ${word} 정보가 없습니다`}
                          />
                        )}
                      {!loading &&
                        data?.listMagazine.products &&
                        data.listMagazine.products.length > 0 && (
                          <PaginationComponent
                            page={page}
                            totalPages={Number(data.listMagazine.totalPages)}
                            totalResults={Number(
                              data.listMagazine.totalResults
                            )}
                            pageHandler={pageHandler}
                          />
                        )}
                    </div>
                  </div>
                  <Footer></Footer>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
