import React, { useEffect, useState } from "react";
import { LayoutTopProps } from "../constants/props";
import logo from "../assets/icon/localpick_logo.svg";
import search from "../assets/icon/search.png";
import { Link } from "react-router-dom";
import { isLoggedInVar } from "../apollo";
import { useMe } from "../hooks/useMe";
import { signOut } from "../constants/token";
import { UserRole } from "../__generated__/globalTypes";
import { Fade, Slide } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import menu from "../assets/icon/menu.svg";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { MobileMenu } from "./mobile-menu";
import logout from "../assets/icon/logout.svg";
export const LayoutTop = ({ title, children }: LayoutTopProps) => {
  const { data } = useMe();

  const [mobileMenu, setMobileMenu] = useState(false);
  const [searchOpen, setSearchOpen] = useState<boolean>(false);

  const mobileMenuHandler = () => {
    setMobileMenu(!mobileMenu);
  };
  window.addEventListener("resize", () => {
    if (window.innerWidth >= 1024) {
      setMobileMenu(false);
    }
  });

  return (
    <>
      <div className="pt-16 md:pt-24"></div>
      <div className="headerWrap h-16 md:h-24 w-full fixed left-0 top-0 z-20 shadow bg-white">
        <header
          id="header"
          className="max-w-screen-yl mx-auto py-3 h-full px-4 "
        >
          <a
            href="#main"
            className="absolute -left-full"
            title="본문으로 바로가기"
          >
            본문으로 바로가기
          </a>
          <div className="innerHeader flex flex-wrap content-center justify-between h-full relative">
            <div
              onClick={mobileMenuHandler}
              className="flex lg:hidden flex-wrap content-center cursor-pointer"
            >
              <img src={menu} alt="모바일메뉴" />
            </div>
            <SwipeableDrawer
              open={mobileMenu}
              onOpen={() => {}}
              onClose={mobileMenuHandler}
              disableDiscovery={true}
            >
              <MobileMenu menuClose={mobileMenu} handler={mobileMenuHandler} />
            </SwipeableDrawer>
            <div className="flex flexw-wrap justify-center gap-x-8">
              <h1 className={`flex flex-wrap content-center`}>
                <a href="/">
                  <img
                    src={logo}
                    alt="로로, 로컬투 로컬 로고"
                    className="w-32"
                  />
                </a>
              </h1>
              {/* {title !== "nothing" && (
                <h1 className="text-lg flex flex-wrap content-center sm:hidden font-bold text-[#6D6C6C]">
                  {title}
                </h1>
              )} */}

              <Link
                className="text-top hidden lg:flex flex-wrap content-center pt-6 font-bold"
                to={"/whatislocalpick"}
              >
                로컬픽이란?
              </Link>
              <Link
                className="text-top hidden lg:flex flex-wrap content-center pt-6 font-bold"
                to={"/collectInst"}
              >
                작가모집
              </Link>
              <Link
                className="text-top hidden lg:flex flex-wrap content-center pt-6 font-bold"
                to={"/collectCurator"}
              >
                큐레이터모집
              </Link>
            </div>

            <div className="flex gap-x-8 flex-wrap justify-center content-center relative h-full ">
              {isLoggedInVar() ? (
                <>
                  <a
                    href="#"
                    onClick={signOut}
                    className=" text-top text-[15px] pt-6 font-bold hidden lg:block"
                  >
                    <img
                      src={logout}
                      alt="로그아웃"
                      className="inline-block w-3 mr-2"
                    />
                    Logout
                  </a>
                  {data?.me.role === UserRole.Instructor ? (
                    <Link
                      to={`/instPage`}
                      className=" text-top text-[15px] pt-6 font-bold hidden lg:inline-block"
                    >
                      MyPage
                    </Link>
                  ) : (
                    <Link
                      to={`/mypage`}
                      className=" text-top text-[15px] pt-6 font-bold hidden lg:inline-block"
                    >
                      MyPage
                    </Link>
                  )}
                </>
              ) : (
                <>
                  <Link
                    to="/signin"
                    className=" text-top text-[15px] pt-6 font-bold hidden lg:inline-block"
                  >
                    Login
                  </Link>
                  <Link
                    to="/join/agree"
                    className=" text-top text-[15px] pt-6 font-bold hidden lg:inline-block"
                  >
                    Join
                  </Link>
                </>
              )}
              <Link
                className="w-[18px] flex flex-wrap content-center lg:content-start lg:pt-6"
                to="/search"
              >
                <img src={search} alt="검색하기" />
              </Link>
            </div>
          </div>
        </header>
      </div>
    </>
  );
};
