import React from "react";
import { gql, useQuery } from "@apollo/client";
import { listProductMineInactive } from "../__generated__/listProductMineInactive";

const LIST_PRODUCT_MINE_INACTIVE_QUERY = gql`
  query listProductMineInactive {
    listProductMineInactive {
      ok
      err
      products {
        id
        productInfo {
          title
          scheduledAt
        }
        inst {
          id
          atelier {
            name
          }
          user {
            name
          }
        }
        createdAt
        productStatus
        productType
      }
    }
  }
`;

export const useListProductMineInactive = (
  onCompleted?: (data: listProductMineInactive) => void
) => {
  return useQuery<listProductMineInactive>(LIST_PRODUCT_MINE_INACTIVE_QUERY, {
    onCompleted,
    fetchPolicy: "network-only",
  });
};
