import React from "react";
import { gql, useLazyQuery, useQuery } from "@apollo/client";
import { getProductPage } from "../__generated__/getProductPage";

const GET_PRODUCT_PAGE_QUERY = gql`
  query getProductPage($input: GetProductPageInput!) {
    getProductPage(input: $input) {
      ok
      err
      productPage {
        id
        page
      }
    }
  }
`;

export const useGetProductPage = (
  onCompleted?: (data: getProductPage) => void
) => {
  return useLazyQuery<getProductPage>(GET_PRODUCT_PAGE_QUERY, {
    onCompleted,
    fetchPolicy: "network-only",
  });
};
