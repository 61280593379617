import { ApolloError, gql, useMutation } from "@apollo/client";
import React from "react";
import { crDoor, crDoorVariables } from "../__generated__/crDoor";
import { CrDoorInput } from "../__generated__/globalTypes";

export interface ICrDoorFormProps extends CrDoorInput {}

const CR_DOOR_MUTATION = gql`
  mutation crDoor($input: CrDoorInput!) {
    crDoor(input: $input) {
      ok
      err
    }
  }
`;

export const useCrDoor = (
  onCompleted?: (data: crDoor) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<crDoor, crDoorVariables>(CR_DOOR_MUTATION, {
    onCompleted,
    onError,
  });
};
