import React, { useEffect, useState } from "react";
import { pageListLength } from "../constants";

export interface PaginationComponentProps {
  page: number;
  row?: number;
  totalResults: number;
  totalPages: number;
  pageHandler: (page: number) => void;
}

export const PaginationComponent: React.FC<PaginationComponentProps> = ({
  page,
  totalPages,
  totalResults,
  pageHandler,
  row,
}) => {
  const [pageList, setPageList] = useState<number[]>([]);
  const length = 4;

  useEffect(() => {
    setPageList([]);
    const max = page + pageListLength;
    for (
      let index = page - pageListLength;
      index <= (totalPages < max ? totalPages : max);
      index++
    ) {
      if (index > 0) setPageList((current) => [...current, index]);
    }
  }, []);

  return (
    <ul className={`block text-center pt-3`}>
      <li className={`paginator`}>
        <button
          type="button"
          className={`${page < 2 ? `pointer-events-none` : ``} px-2`}
          onClick={() => pageHandler(page - 1)}
        >
          <i className="fas fa-angle-left"></i>
        </button>
      </li>
      {pageList.map((_page) => {
        return (
          <li className={`paginator`} key={`pagination-${_page}`}>
            <button
              type="button"
              className={`${page === _page ? `font-bold` : ``}`}
              onClick={() => pageHandler(_page)}
            >
              {_page}
            </button>
          </li>
        );
      })}
      <li className={`paginator`}>
        <button
          type="button"
          className={`${page >= totalPages ? `pointer-events-none` : ``} px-2`}
          onClick={() => pageHandler(page + 1)}
        >
          <i className="fas fa-angle-right"></i>
        </button>
      </li>
    </ul>
  );
};
