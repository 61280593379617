import { ApolloError, gql, useQuery } from "@apollo/client";
import React from "react";
import { getBannerOptionActive } from "../__generated__/getBannerOptionActive";

const GET_BANNER_OPTION_ACTIVE_QUERY = gql`
  query getBannerOptionActive {
    getBannerOptionActive {
      ok
      err
      bannerOptions {
        id
        index
        link
        bannerOptionType
        view {
          uuid
          url
        }
        subView {
          uuid
          url
        }
        product {
          id
          uuid
          productType
          productInfo {
            title
          }
          productViews {
            id
            is_rep
            view {
              url
              uuid
            }
          }
          inst {
            id
            user {
              name
            }
          }
        }
      }
    }
  }
`;

export const useGetBannerOptionActive = (
  onCompleted?: (data: getBannerOptionActive) => void,
  onError?: (error: ApolloError) => void
) => {
  return useQuery<getBannerOptionActive>(GET_BANNER_OPTION_ACTIVE_QUERY, {
    onCompleted,
    onError,
  });
};
