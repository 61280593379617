import React from "react";
import { gql, useLazyQuery } from "@apollo/client";
import { listDoor, listDoorVariables } from "../__generated__/listDoor";
import { DOOR_LIST_FRAGMENT } from "../fragment-door";

const LIST_DOOR_QUERY = gql`
  query listDoor($input: ListDoorInput!) {
    listDoor(input: $input) {
      ok
      err
      totalPages
      totalResults
      row
      doors {
        ...DoorListParts
      }
    }
  }
  ${DOOR_LIST_FRAGMENT}
`;

export const useListDoor = (onCompleted?: (data: listDoor) => void) => {
  return useLazyQuery<listDoor, listDoorVariables>(LIST_DOOR_QUERY, {
    onCompleted,
    fetchPolicy: "network-only",
  });
};
