export const consult_ing = [
  {
    inst: "최영은",
    req_date: "2021-11-01",
    state: 1,
    lectureName: "손재주로 먹고사는 법",
    desc: "강의 상세 페이지 구성을 하고 싶은데 어떤 포맷으로 하면 좋을지 알려주십시오",
  },
  {
    inst: "서진야",
    req_date: "2021-11-01",
    state: 1,
    lectureName: "우리집 인테리어를 바꿀 나의 그림 기술 원터치 클래스",

    desc: "강의 커리큘럼 구성을 위한 문의",
  },
];
export const consult_yet = [
  {
    inst: "김재우",
    req_date: "2021-11-01",
    lectureName: "꽃꽃이를 프로페셔널하게 배워 집인테리어를 바꾸는 기술",

    state: 0,
    desc: "강의 폐지 문의",
  },
  {
    inst: "황순디",
    req_date: "2021-11-01",
    lectureName: "모든 것은 내 안에서 벌어진 일이다.",
    state: 1,
    desc: "강의 이전 문의",
  },
];
export const instructors = [
  {
    numbering: "IN0004",
    con_name: "최영은",
    phone: "010-1557-9825",
    point: 4,
    req_num: 3,
    lectures: "3/1",
  },
  {
    numbering: "IN0005",
    con_name: "서진야",
    phone: "010-4389-3618",
    point: 4,
    state: 0,
    req_num: 5,
    lectures: "3/3",
  },
  {
    numbering: "IN0005",
    con_name: "김재우",
    phone: "010-5238-2765",
    point: 0,
    state: 0,
    lectures: "2/0",
    req_num: 3,
  },

  {
    numbering: "IN0005",
    con_name: "황순디",
    phone: "010-9867-6893",
    point: 4,
    state: 0,
    lectures: "10/0",
    req_num: 13,
  },
];
