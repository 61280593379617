import React from "react";
import { gql, useLazyQuery } from "@apollo/client";
import {
  listUnverifiedCurator,
  listUnverifiedCuratorVariables,
} from "../__generated__/listUnverifiedCurator";

const LIST_UNVERIFIED_CURATOR_QUERY = gql`
  query listUnverifiedCurator($input: ListUnverifiedCuratorInput!) {
    listUnverifiedCurator(input: $input) {
      ok
      err
      totalResults
      totalPages
      row
      curators {
        id
        name
        email
        phone
        verified
        charged {
          id
        }
      }
    }
  }
`;

export const useListUnverifiedCurator = (
  onCompleted?: (data: listUnverifiedCurator) => void
) => {
  return useLazyQuery<listUnverifiedCurator, listUnverifiedCuratorVariables>(
    LIST_UNVERIFIED_CURATOR_QUERY,
    {
      onCompleted,
      fetchPolicy: "network-only",
    }
  );
};
