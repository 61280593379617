import proj4 from "proj4";
import React from "react";

interface MapConstants {
  crs: string;
  level: number;
  "X-NCP-APIGW-API-KEY-ID": string;
}

interface MapVariables extends MapConstants {
  w: string;
  h: string;
  center: string;
  marker: string;
}

const mapConstants: MapConstants = {
  crs: "NHN:128",
  level: 16,
  "X-NCP-APIGW-API-KEY-ID": "8702cymzw1",
};

const PROJ_VARIABLES = {
  KATEC:
    "+proj=tmerc +lat_0=38 +lon_0=128 +k=0.9999 +x_0=400000 +y_0=600010 +ellps=bessel +units=m +no_defs +towgs84=-115.80,474.99,674.11,1.16,-2.31,-1.63,6.43",
  EPSG: "+proj=tmerc +lat_0=38 +lon_0=127.5 +k=0.9996 +x_0=400000 +y_0=600000 +ellps=GRS80 +units=m +no_defs",
  WGS84:
    "+title=WGS 84 (long/lat) +proj=longlat +ellps=WGS84 +datum=WGS84 +units=degrees",
};

const mapUrl: string = `https://naveropenapi.apigw.ntruss.com/map-static/v2/raster-cors`;

export const getMap = (w: string, h: string, mapx: number, mapy: number) => {
  const loc = proj4(PROJ_VARIABLES.KATEC, PROJ_VARIABLES.WGS84, [mapx, mapy]);
  const mapVar: MapVariables = {
    ...mapConstants,
    w,
    h,
    center: `${loc[0]},${loc[1]}`,
    marker: `${loc[0]} ${loc[1]}`,
  };

  const url = `${mapUrl}?w=${mapVar.w}&h=${mapVar.h}&center=${mapVar.center}&level=${mapVar.level}&X-NCP-APIGW-API-KEY-ID=${mapVar["X-NCP-APIGW-API-KEY-ID"]}&markers=type:d|size:tiny|pos:${mapVar.marker}`;
  return url;
};
